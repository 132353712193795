import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Colors, Layouts } from '../../../defines/Theme';
import { GetText, GetTextSido } from '../../../defines/Texts';

import AlarmContext from '../../../context/AlarmContext';

import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import * as JsUtil from '../../../utils/JsUtil';

import GAPI, { TAPI } from '../../../api/RestAPI';

export default function RouteMapDirectionSelector({ route, onStartDirPress, turnStNm, lastStNm, onTurnDirPress }) {
    const context = useContext(AlarmContext)

    const [startStationRegion, setStartStationRegion] = useState('...');
    const [endStationRegion, setEndStationRegion] = useState('...');

    // 기종점 지역 추출
    useEffect(() => {
        if (route) {
            // ~ 방면(지역) 으로 표시되므로, 종점정류소를 조회해야 함
            GAPI.doRequest('getStationDetail', JsUtil.GText(route, "endStationId"))
                .then((res) => {
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code == 0) {
                        setStartStationRegion(GetTextSido(JsUtil.GText(res.response.msgBody.busStationInfo, "regionName")))
                    }
                    else {
                        context.msgHandler?.toast(GetText("pageComment", "NOT_FOUND_LAST_STATION"))
                    }
                })
                .catch(err => {
                    context.msgHandler?.toast(GetText("pageComment", "NOT_FOUND_LAST_STATION"))
                })
            // ~ 방면(지역) 으로 표시되므로, 기점정류소를 조회해야 함
            GAPI.doRequest('getStationDetail', JsUtil.GText(route, "startStationId"))
                .then((res) => {
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code == 0) {
                        setEndStationRegion(GetTextSido(JsUtil.GText(res.response.msgBody.busStationInfo, "regionName")))
                    }
                    else {
                        context.msgHandler?.toast(GetText("pageComment", "NOT_FOUND_START_STATION"))
                    }
                })
                .catch(err => {
                    context.msgHandler?.toast(GetText("pageComment", "NOT_FOUND_START_STATION"))
                })
        }
    }, [route]);

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.rightView}
                onPress={() => {
                    onStartDirPress?.()
                }}
            >
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", {
                    color: Colors.white,
                    fontSize: 15,
                    lineHeight: 17,
                    marginBottom: 5,
                    textAlign: "center"
                })}>
                    {/* {route ? JsUtil.GText(route, "endStationName") : '회차정류소'} 방면 */}
                    {GetText("pageWord","ROUTE_DEST_NAME").replace("{routeDestName}", (turnStNm ? turnStNm : (route ? JsUtil.GText(route, "endStationName") :'회차정류소')))}
                </Text>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", {
                    color: Colors.white,
                    fontSize: 15,
                    lineHeight: 17,
                    paddingTop: 3,
                    marginBottom: 3,
                    textAlign: "center"
                })}>({startStationRegion})</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.leftView}
                onPress={() => {
                    onTurnDirPress?.()
                }}
            >
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", {
                    color: Colors.white,
                    fontSize: 15,
                    lineHeight: 17,
                    marginBottom: 5,
                    textAlign: "center"
                })}>
                    {/*  */}
                    {GetText("pageWord","ROUTE_DEST_NAME").replace("{routeDestName}", (lastStNm ? lastStNm : (route ? JsUtil.GText(route, "startStationName") : '종점정류소')))}
                </Text>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", {
                    color: Colors.white,
                    fontSize: 15,
                    lineHeight: 17,
                    paddingTop: 3,
                    marginBottom: 3,
                    textAlign: "center"
                })}>({endStationRegion})</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = {
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: Colors.borderColor,

        borderBottomWidth: 0.5,
        padding: 10,
        width: Layouts.windowWidth,
        // shadowColor: "#000",
        // shadowOffset: {
        //     width: 0,
        //     height: 3,
        // },
        // shadowOpacity: 0.29,
        // shadowRadius: 4.65,
        // elevation: 1,
        backgroundColor: Colors.appBackgroundColor,
        //backgroundColor: "#f0f",
    },
    rightView: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRightWidth: 1,
        borderColor: 'transparent',
        paddingVertical: 5,
        paddingHorizontal: 20,
        width: Layouts.windowWidth / 2 - 10,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        backgroundColor: 'rgba(0, 0, 255, 0.7)', //Colors.blue
        backgroundColor: Colors.ggBlue, //Colors.blue
    },
    leftView: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        paddingHorizontal: 20,
        width: Layouts.windowWidth / 2 - 10,
        borderLeftWidth: 1,
        borderColor: 'transparent',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: 'rgba(255, 0, 0, 0.7)', //Colors.blue
        //backgroundColor: Colors.redRoute
    }
}