import React, { Component } from 'react'; 
import { StyleSheet, Text, View, TouchableOpacity, Image, Alert } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { WebView } from 'react-native-webview';
// context
import AlarmContext from '../../context/AlarmContext';
// config, lib
import MapStyle from '../../styles/MapStyle';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import { Colors, Layouts } from '../../defines/Theme';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import Texts, {GetText,GetTextSido} from '../../defines/Texts';
import AppSetting from '../../defines/AppSetting';
import * as GBus from '../../defines/GBus';
import * as JsUtil from '../../utils/JsUtil';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

import GetLocation from '../../gps/GetLocation';

import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';

// import Html from '../Tmap/TmapAround';
let UserScriptApi = require('../../utils/UserScriptApi');

function MyLocation({ selectedStation, onPress }) {
    return (
        <>
        {
            selectedStation
              ? <TouchableOpacity style={ MapStyle("myLocation") }
                    onPress={ onPress }>
                    <FIcon type="fad"name="location" size={55} color={Colors.ggBlue} />
                </TouchableOpacity>
              : <TouchableOpacity style={ MapStyle("myLocation", { bottom: Layouts.bottomBarHeight + 60 }) }
                    onPress={ onPress }>
                    <FIcon type="fad"name="location" size={55} color={Colors.ggBlue} />
                </TouchableOpacity>
        }
        </>
    );
}

export function RenderSelectedStation({ stationVisible, selectedStation, onPress }) {
    return (
        <View style={ MapStyle("selStationBoxBg") }>
            {
                stationVisible && selectedStation 
                  ? <TouchableOpacity style={ MapStyle("selStationBox") }
                        onPress={() => {
                            if (onPress) {
                                onPress()
                            }
                        }}
                    >
                        <View style={ SectionStyle("rowLeftIconArea") }>
                            <LoadImage name="map_station_red" width={35} height={35} />
                            {/* <Image source={require(assetsRoute + 'marker.png')} 
                                style={{ width: 35, height: 35, resizeMode: 'contain' }} /> */}
                        </View>
                        <View style={ SectionStyle("rowFillStretch") }>
                            <Text numberOfLines={1} style={TextStyle("rowTitleDesc", { paddingVertical: 10 })}>
                                {selectedStation.stationName}
                            </Text>
                            <Text numberOfLines={1} style={TextStyle("rowDetail", { marginBottom: 5 })}>
                                {/* 여기서는 거리 표시 안함 */}
                                {/* { selectedStation.distance } | */}
                                {GBus.GetStationMobileNo(selectedStation, " | ")}
                                {GBus.GetStationCenterName(selectedStation, " | ")}
                                {GetTextSido(selectedStation.regionName)}
                            </Text>
                        </View>
                        <View style={ SectionStyle("rowRightButtonsArea") }>
                            <FIcon type="fad" name="chevron-right" size={20} color={Colors.ggBlue} />
                        </View>
                    </TouchableOpacity>    
                  : <View style={ MapStyle("selStationBoxEmpty") }>
                        <Text style={ CommonStyle("commentLabel") }>
                            {/* { stationVisible ? "내 주변 500m 이내 정류소를 표시합니다." : "적절히 지도를 확대하면 정류소가 표시됩니다." } */}
                            { stationVisible ? GetText("around","GUIDE") : GetText("around","PLZ_ZOOM_IN")}
                        </Text>
                    </View>
            }
        </View>
    )
}

export default class StationMap extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            stationVisible: true,
            selectedStation: null,
            stationsFromMap: null,
            position: null,         // { coords: { longitude, latitude }} :
        }
        
    }

    componentDidMount() {
        GetLocation(this.context.msgHandler, (p) => {
            this.setState({ position: p })
        })
        UserScriptApi.apiConnect('stationMap')
    }

    render() {
        return (
            <SafeAreaView style={ CommonStyle('bg') }>
                <ScreenHeader
                    // title="정류소"
                    title={GetText("pageTitle", "STATION_MAP")}
                />
                <WebView
                    ref={(ref) => this.webRef = ref}
                    originWhitelist={['*']}
                    style={ MapStyle("mapView") }
                    source={{ uri: AppSetting.MOBILE_WEB.ROOT + "assets/Tmap/TmapAround.html", method: 'get' }}
                    // source={{ html: Html.content }}
                    onLoadStart={event => {
                        showPageLoader(true)
                    }}
                    onLoadEnd={event => {
                        showPageLoader(false)
                    }}
                    onMessage={(event) => {
                        if (!event.nativeEvent.data || typeof event.nativeEvent.data != "string" || !event.nativeEvent.data.startsWith("{")) {
                            //console.log("===> Unsupported webview event", event);
                            return;
                        }
                        let data = JSON.parse(event.nativeEvent.data.toString())
                        if (data.event == "stations") {
                            this.setState({
                                stationsFromMap: data.data 
                            })
                        }
                        else if (data.event == "state") {
                            console.log(`[TMap] state changed. stationVisible: ${data.stationVisible}, ` + data.data)
                            this.setState({ stationVisible: data.stationVisible, selectedStation: data.data })
                        }
                        else if (data.event == "log") {
                            console.log("[TMap] " + data.data);
                        }
                        else if (data.event == "error") {
                            console.log("[TMap] error:");
                            console.log(data.data);
                        }
                    }}
                    javaScriptEnabled={true}
                    injectedJavaScript={
                        `startStationAround(
                            ${JsUtil.GText(this.props.route.params.data, "x")}, 
                            ${JsUtil.GText(this.props.route.params.data, "y")},
                            ${JsUtil.GText(this.props.route.params.data, "stationId")},
                            '${global.foreignLanguage}'
                         )` 
                    }
                />
                <MyLocation 
                    selectedStation={this.state.selectedStation}
                    onPress={() => {
                        this.state.position != 'unknown' ?
                        this.webRef.injectJavaScript(`setMapCenter(${this.state.position.coords.longitude}, ${this.state.position.coords.latitude})`) :
                        this.webRef.injectJavaScript(`setMapCenter(${AppSetting.GPS.MAP_DEF_X}, ${AppSetting.GPS.MAP_DEF_Y})`)
                    }}
                />
                <RenderSelectedStation
                    stationVisible={this.state.stationVisible} 
                    selectedStation={this.state.selectedStation} 
                    onPress={()=>{
                        if(this.state.selectedStation) {
                            this.props.navigation.navigate('stationInfo', {
                                data: {
                                    stationId: { _text: this.state.selectedStation.stationId },
                                    stationName: { _text: this.state.selectedStation.stationName },
                                    x: { _text: this.state.selectedStation.x },
                                    y: { _text: this.state.selectedStation.y }
                                }                                
                            })
                        }
                    }}
                /> 
                <BottomRightTabs navigation={this.props.navigation} />
            </SafeAreaView>
        )
    }
}
