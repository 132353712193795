import React, { Component } from 'react';
import { StyleSheet,  Text, Linking, View, TouchableOpacity, ScrollView } from 'react-native';
import { Dimensions, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// lib, config
import { Colors, Layouts } from '../../defines/Theme';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';

import * as DateUtil from '../../utils/DateUtil';

import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerH from '../components/common/SpacerH';
import CommonStyle from '../../styles/CommonStyle';
import TextStyle from '../../styles/TextStyle';
import SectionStyle from '../../styles/SectionStyle';

import SpacerMiniV from '../components/common/SpacerMiniV';
import UrlPreview from '../components/common/UrlPreview';



export default class MessageContent extends Component { 

    constructor(props) {
        super(props)
    }

    renderLink = (board) => {
         if (!board.linkUrl) {
             return <></>
         }
         // board.linkUrl = "https://www.gg.go.kr/bbs/boardView.do?bsIdx=464&bIdx=14845232&menuId=1535";
         return (<>
             <UrlPreview 
                text={board.linkUrl}
                containerStyle={{
                    borderColor: Colors.borderLightColor,
                    borderWidth: 0.7,
                    backgroundColor: "rgba(239, 239, 244,0.62)",
                    alignItems: "center",
                    padding: 5,
                    margin: 10,
                    borderRadius: 10,
                }}
                /> 
             <SpacerH/>
             <SpacerH/>
         </>)                        
     }    

    renderImage = (board) => {
        if (!board.imgUrl) {
            return <></>
        }
        return (<>
            <View style={CommonStyle("box", { flex: 1, margin: 20 })}>
                <TouchableOpacity style={CommonStyle("boardImageBg", {paddingVertical: 5 })}
                    activeOpacity={board.linkUrl ? 0.5 : 1}
                    onPress={() => {
                        if (board.linkUrl) {
                            Linking.openURL(board.linkUrl)
                        }                            
                }}> 
                    <Image
                        width={Dimensions.get('window').width - 100} // height will be calculated automatically
                        height={(Dimensions.get('window').width - 100) * 0.7} // height will be calculated automatically
                        //height={(Dimensions.get('window').width - 100) * 0.7} // height will be calculated automatically
                        //height={200} // height will be calculated automatically
                        source={{uri: board.imgUrl}}
                    />
                </TouchableOpacity>
            </View>
            <SpacerH/>
            <SpacerH/>
        </>)                        
    }

    render() {
        const { board, isPopup } = this.props;
        // console.log(this.props.route.params.data);
        // let board = this.props.route.params.data;
        let icon = "info-circle"
        let typeLabel = "공지사항"
        if (board.popTp == 1) {
            icon = "file-alt";
            typeLabel = "외부알림"
        }
        else if (board.popTp == 2) {
            icon = "car-crash";
            typeLabel = "사고소식"
        }       
        return (
            <View style={CommonStyle("boxFill")}>
                {/* title */}
                <View style={SectionStyle("rowForRow", { 
                    alignItems: "center", 
                    padding: 10, 
                    backgroundColor: Colors.gsYellow
                }) }>
                    <FIcon type="fad" name={icon} size={30} color={Colors.whiteLight} />
                    <Text style={TextStyle("rowTitle", isPopup ? "large" : "largest", { 
                        flex: 1, lineHeight: 25, paddingLeft: 10, color: Colors.white })}>
                        {board.popTitle}
                    </Text>
                </View>
                {/* from - to <i class="fad fa-calendar-alt"></i>*/}
                <View style={SectionStyle("rowForRow", { 
                    alignItems: "center", 
                    paddingHorizontal: 20, 
                    paddingVertical: 10, 
                    backgroundColor: Colors.silver
                }) }>
                    <FIcon type="fad" name="calendar-alt" size={24} color={Colors.ggBlue} />
                    <Text style={TextStyle("rowDetail", "small", { lineHeight: 30, paddingLeft: 10 })}>
                        {DateUtil.format(board.startTime, DateUtil._DT_FORMAT_TO_MIN)} ~ {DateUtil.format(board.endTime, DateUtil._DT_FORMAT_TO_MIN)}
                    </Text>
                </View>
                {/* scroll contents + image */}
                <ScrollView style={CommonStyle("boxFill")}>
                    {/* contents */}
                    <View style={CommonStyle("box", { 
                        alignItems: "center", 
                        padding: 15, 
                        
                    }) }>
                        <Text style={TextStyle("rowTitleDesc", isPopup ? "medium" : "large", { 
                            alignSelf: "stretch",
                            lineHeight: 20, 
                            paddingLeft: 10 
                        })}>
                            {board.popContents.replace(/<br>/gi, '\n')}
                        </Text>
                    </View>
                    {/* image */}
                    { this.renderImage(board) }
                    { this.renderLink(board) }
                    <SpacerMiniV />
                    <SpacerMiniV />
                </ScrollView>
            </View>
        )
    }
}
