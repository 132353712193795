import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import FrStyle from '../../../styles/FrStyle';

export default function FrEndRow({beforeColor, item}) {
    return(
        <View style={FrStyle("abbrEndRowBg")}>
            <View style={FrStyle("abbrLeftIconBg")}>
                <View style={FrStyle("abbrTopLine", { backgroundColor: beforeColor})} />
                <View style={FrStyle("abbrEndIconBg", { borderColor: beforeColor})} >
                    <Text numberOfLines={1} style={TextStyle("rowDetail", "smallest")}>하차</Text>
                </View>
            </View>
            <View style={FrStyle("abbrRightBg")}>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "medium")}>{item}</Text>
            </View>
        </View>
    )    
}
