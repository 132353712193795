import * as GBus from '../defines/GBus';
import * as Theme from '../defines/Theme';

const Colors = Theme.Colors;

// 노선유형별 색상값 변환 함수
export function ConvColors(str, rgbType) {
    return GBus.GetRouteTypeColor(str);
}

// 혼잡도별 색상값 변환 함수
export function ConvDensityColor(str) {
    return GBus.GetDensityColor(str);
}

export default Colors;