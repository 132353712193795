import React from 'react'; 
import { Text, View, TouchableOpacity } from 'react-native';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';


/**
 * tab = { id, label }
 */
export const TabSelectItem = ({id, label, onPress, isActive, useFullRow, style}) => {
    return (
        <TouchableOpacity
            onPress={() => { onPress?.(id) }}
            style={CommonStyle(useFullRow ? "tabSelectItemFullRowBg" : "tabSelectItemBg")}
        >
            <View style={CommonStyle("tabSelectLabelBg")} >
                <Text style={[TextStyle("tabSelectLabel", "small", 
                isActive ? { color: Colors.ggBlue } : null), style]}>{label}</Text>
            </View>
            <View style={CommonStyle(isActive ? "tabSelectIndicatorActive" : "tabSelectIndicator")} />
        </TouchableOpacity>
    )
}

/**
 * tabs={
        night: "심야버스",
        low: "저상버스",
        out: "시외버스",
        air: "공항버스"
    }
    suffixes={
        night: "(000)",
        low: "(000)",
    }
 */
const TabSelectBar = ({tabs, suffixes, onPress, activeId, useFullRow, style, textStyle, rightComponent }) => {
    return (
    <View style={CommonStyle("boxForRow")}>
        <View style={ CommonStyle(useFullRow? "tabSelectFullRowBg" : "tabSelectBg", style) }>
            { 
                Object.keys(tabs).map((id, index) => {
                    return (
                        <TabSelectItem 
                            useFullRow={useFullRow}
                            id={id} 
                            label={tabs[id] + (suffixes && suffixes[id] ? suffixes[id] : "")}
                            onPress={onPress}
                            isActive={id == activeId}
                            key={index}
                            style={textStyle}
                        />
                    )
                })            
            }            
        </View>
        <View style={ CommonStyle("tabSelectRightAreaBg") }>
        { rightComponent }
        </View>
    </View>)
}


export default TabSelectBar;