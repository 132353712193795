import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Colors, Layouts } from '../../../defines/Theme';
import FIcon from '../../../defines/FIcon';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';


/**
 * tab = { id, label }
 */
export const TabSelectItem = ({ icon, id, label, onPress, isActive, useFullRow, style }) => {
    return (
        <TouchableOpacity
            onPress={() => { onPress?.(id) }}
            style={CommonStyle(useFullRow ? "tabSelectItemFullRowBg" : "tabSelectItemBg")}
        >
            <View style={CommonStyle("tabSelectLabelBg", { 
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-end",                
                alignItems: "center",                
                //backgroundColor: "#0ff"
                })} >
                {
                    icon ? <FIcon
                        type={icon.type} name={icon.name} size={25}
                        color={isActive ? Colors.ggBlue : Colors.fontCommentColor}
                        style={{marginRight: 5}} /> : null
                }
                <Text style={[TextStyle("tabSelectLabel", "medium",
                    isActive ? { color: Colors.ggBlue } : null), style, { minWidth: 80 }]}>{label}</Text>
            </View>
            <View style={CommonStyle(isActive ? "tabSelectIndicatorActive" : "tabSelectIndicator")} />
        </TouchableOpacity>
    )
}

/**
 * tabs={ tab_id: name, tab_id: name ... }
   suffixes={ tab_id: suffix, tab_id: suffix }
 */
const TabSelectBarEx = ({ tabs, icons, suffixes, onPress, activeId, useFullRow, style, textStyle, rightComponent }) => {
    return (
        <View style={CommonStyle("boxForRow")}>
            <View style={CommonStyle(useFullRow ? "tabSelectFullRowBg" : "tabSelectBg", style)}>
                {
                    Object.keys(tabs).map((id, index) => {
                        return (
                            <TabSelectItem
                                icon={icons && icons[id] ? icons[id] : null}
                                useFullRow={useFullRow}
                                id={id}
                                label={tabs[id] + (suffixes && suffixes[id] ? suffixes[id] : "")}
                                onPress={onPress}
                                isActive={id == activeId}
                                key={index}
                                style={textStyle}
                            />
                        )
                    })
                }
            </View>
            <View style={ CommonStyle("tabSelectRightAreaBg", { 
                justifyContent: icons ? "center": "flex-end",
                paddingBottom: icons ? 0: 7
            }) }>
                {rightComponent}
            </View>
        </View>)
}

export default TabSelectBarEx;