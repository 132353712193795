import React, { useContext, useEffect, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// Global Components
// import Report from './GlobalPages/Report';
// import StationInfo from './GlobalPages/StationInfo';
// import RouteInfo from './GlobalPages/RouteInfo';
// import RouteInfoDetail from './GlobalPages/RouteInfoDetail';

// import { LoadGlobalAppVars } from '../utils/StorUtil';
// import { GenerateFontStyles } from '../defines/Fonts';

import AlarmContext from '../context/AlarmContext';
import Text, { GetText, GetTextSido } from '../defines/Texts';
import * as JsUtil from '../utils/JsUtil';

// Stack Components
import TestPage from './test/TestPage';
import HomeDarwNav from './home/HomeDrawNav';
import SearchMain from './search/SearchMain';
import SearchPoiMap from './search/SearchPoiMap';
import Around from './around/Around';
import AroundList from './around/AroundList';
import StationInfo from './station/StationInfo';
import stationMap from './station/StationMap';
import RouteInfo from './route/RouteInfo';
import RouteInfoDetail from './route/RouteInfoDetail';
import RouteMap from './route/RouteMap';

import ShowAllRoutes from './others/ShowAllRoutes';
import BusEval from './others/BusEval';
import Help from './others/Help';
import DeviceInfo from './others/DeviceInfo';

import Message from './board/Message';
import MessageDetail from './board/MessageDetail';
import FrMain from './pathfind/FrMain';
import FrStartEnd from './pathfind/FrStartEnd';
import FrSelMap from './pathfind/FrSelMap';
import FrDetailPage from './pathfind/FrDetailPage';

import RideSupport from './ridesupport/RideSupport';
import Foreigner from './others/Foreigner';
import QrInitLanguage from './home/QrInitLanguage';

const WEB_TITLE = "경기버스정보";

const MainTabStack = createStackNavigator();

// initState = {isLoading, fontSize}
export default function MainTabStackNav({ initState }) {
    const context = useContext(AlarmContext);

    useEffect(() => {
        context.setInitState(initState);
    }, [initState])

    let prefixes = [];
    if (window && window.location) {
        console.log("Service origin detected. " + window.location.origin);
        prefixes.push(window.location.origin)
    }

    const linking = {
        prefixes: prefixes,
        config: {
            screens: {
                home: "*", 
                //     screens: {
                //         homeFavor: 'homeFavor' 
                //     }
                // },
                // 메인 DRAW 메뉴: 공지, 도움말, 모든노선보기
                message: 'message',
                help: 'help',
                showAllRoutes: 'ShowAllRoutes',
                deviceInfo: 'DeviceInfo',
                // 주변정류소
                around: 'around',
                aroundList: {
                    path: 'aroundList/:data',
                    parse: {
                        data: (data) => ""
                    },
                    stringify: {
                        data: (data) => ""
                    },
                },
                // 검색
                searchMain: 'searchMain',
                searchPoiMap: {
                    path: 'searchPoiMap/:positionInfo',
                    parse: {
                        positionInfo: (data) => ""
                    },
                    stringify: {
                        positionInfo: (data) => ""
                    },
                },
                // 노선정보,상세,맵
                routeInfo: {
                    path: 'routeInfo/:data',
                    parse: {
                        data: (data) => {
                            let arr = atob(decodeURIComponent(data)).split("-");
                            return {
                                stationId: arr[0],
                                stationSeq: arr[1],
                                routeId: arr[2],
                            }
                        }
                    },
                    stringify: {
                        data: (data) => {
                            return btoa(`${JsUtil.GText(data, "stationId", "")}-${JsUtil.GText(data, "stationSeq", "")}-${JsUtil.GText(data, "routeId", "")}`);
                        }
                    },
                },
                routeInfoDetail: {
                    path: 'routeInfoDetail/:data',
                    parse: {
                        data: (data) => ""
                    },
                    stringify: {
                        data: (data) => ""
                    },
                },
                routeMap: {
                    path: 'routeMap/:data',
                    parse: {
                        data: (data) => ""
                    },
                    stringify: {
                        data: (data) => ""
                    },
                },
                // 정류소정보,맵
                stationInfo: {
                    path: 'stationInfo/:data',
                    parse: {
                        data: (data) => {
                            let arr = atob(decodeURIComponent(data)).split("-");
                            return {
                                stationId: arr[0],
                                x: arr[1],
                                y: arr[2],
                            }
                        }
                    },
                    stringify: {
                        data: (data) => {
                            return btoa(`${JsUtil.GText(data, "stationId", "")}-${JsUtil.GText(data, "x", "")}-${JsUtil.GText(data, "y", "")}`);
                        }
                    },
                },
                stationMap: {
                    path: 'stationMap/:data',
                    parse: {
                        data: (data) => ""
                    },
                    stringify: {
                        data: (data) => ""
                    },
                },
                // 정류소+노선 도착정보
                rideSupport: {
                    path: 'rideSupport/:data/:stationId/:staOrder/:turnSeq/:routeId/:navCallback',
                    parse: {
                        data: (data) => "",
                        stationId: (data) => "",
                        staOrder: (data) => "",
                        turnSeq: (data) => "",
                        routeId: (data) => "",
                        navCallback: (navCallback) => "",
                    },
                    stringify: {
                        data: (data) => "",
                        stationId: (data) => "",
                        staOrder: (data) => "",
                        turnSeq: (data) => "",
                        routeId: (data) => "",
                        navCallback: (navCallback) => "",
                    },
                },
                // 길찾기
                findRoad: 'findRoad',
                frDetailPage: {
                    path: 'frDetailPage/:data',
                    parse: {
                        data: (data) => ""
                    },
                    stringify: {
                        data: (data) => ""
                    },
                },
                frSelMap: {
                    path: 'frSelMap/:title/:x/:y',
                    parse: {
                        title: (data) => "",
                        x: (data) => "",
                        y: (data) => "",
                    },
                    stringify: {
                        title: (data) => "",
                        x: (data) => "",
                        y: (data) => "",
                    },
                },                
                frStartEnd: {
                    path: 'frStartEnd/:title',
                    parse: {
                        title: (data) => "",
                    },
                    stringify: {
                        title: (data) => "",
                    },
                },
                qrInitLanguage: 'qrInitLanguage',

                // QrInitLanguage: {

                // }
            }
        },
    };

    // console.log(`[MainTabStackNav] rendering. global.fontSize: ${global.fontSize} initState:`, initState)    
    return (
        !initState || initState.isLoading ? null :
            <NavigationContainer linking={linking}>
                <MainTabStack.Navigator
                    initialRouteName={
                        "home"
                    }
                    headerMode="none"
                    screenOptions={{ animationEnabled: false }} >
                    {/* 테스트 페이지 */}
                    <MainTabStack.Screen name="test" component={TestPage} options={{ title: WEB_TITLE }} />

                    {/* 인트로 페이지 */}
                    {/* <MainTabStack.Screen name="intro1" component={Intro1} />
                    <MainTabStack.Screen name="intro2" component={Intro2} />
                    <MainTabStack.Screen name="intro3" component={Intro3} /> */}
                    {/* 홈 페이지 */}
                    <MainTabStack.Screen name="home" component={HomeDarwNav} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="showAllRoutes" component={ShowAllRoutes} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="deviceInfo" component={DeviceInfo} options={{ title: WEB_TITLE }} />
                    {/* <MainTabStack.Screen name="option" component={Settings} />
                    <MainTabStack.Screen name="widgetOption" component={WidgetSettings} /> */}
                    <MainTabStack.Screen name="foreigner" component={Foreigner} />
                    <MainTabStack.Screen name="qrInitLanguage" component={QrInitLanguage} />

                    {/* 통합검색 페이지 */}
                    <MainTabStack.Screen name="searchMain" component={SearchMain} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="searchPoiMap" component={SearchPoiMap} />

                    {/* 주변정류소 페이지 */}
                    <MainTabStack.Screen name="around" component={Around} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="aroundList" component={AroundList} options={{ title: WEB_TITLE }} />
                    {/* 길찾기 페이지 */}
                    <MainTabStack.Screen name="findRoad" component={FrMain} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="frStartEnd" component={FrStartEnd} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="frSelMap" component={FrSelMap} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="frDetailPage" component={FrDetailPage} options={{ title: WEB_TITLE }} />
                    {/* 노선, 정류소 페이지 */}
                    <MainTabStack.Screen name="stationInfo" component={StationInfo} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="stationMap" component={stationMap} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="routeInfo" component={RouteInfo} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="routeInfoDetail" component={RouteInfoDetail} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="routeMap" component={RouteMap} options={{ title: WEB_TITLE }} />
                    {/* 알림 페이지 */}
                    <MainTabStack.Screen name="message" component={Message} options={{ title: WEB_TITLE }}  />
                    <MainTabStack.Screen name="messageDetail" component={MessageDetail} options={{ title: WEB_TITLE }} />
                    {/* 버스 리뷰 */}
                    <MainTabStack.Screen name="help" component={Help} options={{ title: WEB_TITLE }} />
                    <MainTabStack.Screen name="busEval" component={BusEval} options={{ title: WEB_TITLE }} />
                    {/* 승차지원 */}
                    <MainTabStack.Screen name="rideSupport" component={RideSupport} options={{ title: WEB_TITLE }}  />
                    {/* <MainTabStack.Screen name="rideSupportStation" component={RideSupportStation} /> */}
                    {/* <MainTabStack.Screen name="offBusSupport" component={OffBusSupport} /> */}
                    {/* <MainTabStack.Screen name="busNotifyHistory" component={BusNotifyHistory} /> */}
                </MainTabStack.Navigator>
            </NavigationContainer>
    )
}
