import React, { Component } from 'react';
import { Alert, Linking } from 'react-native';
import AlarmContext from './AlarmContext';
import AlarmTaskTimer from '../alarm/AlarmTimer';
// 
import AppSetting, { _UPDATABLE_APPSETTING_PROPS } from '../defines/AppSetting';
import * as GBus from '../defines/GBus';
import GetLocation from '../gps/GetLocation';
import GAPI from '../api/RestAPI';
import Texts, { GetText, GetTextSido } from '../defines/Texts';
import * as Fonts from '../defines/Fonts';
import * as FavorUtil from '../utils/FavorUtil';
import * as AlarmUtil from '../utils/AlarmUtil';
import * as DateUtil from '../utils/DateUtil';
import * as StorUtil from '../utils/StorUtil';
import * as JsUtil from '../utils/JsUtil';

import MsgHandler from '../screens/components/common/MsgHandler';

import * as PushNotifier from '../alarm/PushNotifier';

const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_CONTEXT_LOG) {
        if (o) {
            console.log(`[AlarmContext] ${m}`, o)
        }
        else {
            console.log(`[AlarmContext] ${m}`)
        }
    }
}

const warn = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_CONTEXT_WARN) {
        console.log(`[WARN][AlarmContext] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][AlarmContext] ${m}`, e)
}

// 알람 실패코드. Texts 파일에 정의된 ID 값과 일치해야 메시지 출력됩니다.
const FAILS = {
    none: 0,
    gps: "gps",
    api: "api",
    // on alarm
    userEscapedFromOnStation: "user_escaped_from_station",
    busNotFoundOnServer: "bus_not_found",                       // server fails
    notifyTransferFailed: "notify_transfer_failed",
    // off alarm
    rideBusNotDetected: "off_ride_bus_not_detected",
    firstStationNotDetected: "off_start_station_not_detected",
    userEscapedRoutePath: "off_user_escaped_route_path",        // 사용자가 노선의 이동 경로를 벗어남
    //
    // 
    totalTimeout: "total_timeout",
    logicError: "internal_error",
}

// 알람 설정시 필수로 전달되어야 하는 properties
const _ALARM_PROPS = {
    notify: ["stationId", "stationName", "stationSeq", "x", "y", "staOrder",
        "routeTypeCd", "routeId", "routeName", "vehId", "plateNo", "prevNth", "reqId", "reqTime"],
    onAlarm: ["stationId", "stationName", "stationSeq", "x", "y", "staOrder",
        "routeTypeCd", "routeId", "routeName", "vehId", "plateNo", "prevNth"],
    offAlarm: [
        "startStationId", "startStationName", "startStationSeq",
        "offStationId", "offStationName", "offStationSeq",
        "routeTypeCd", "routeId", "routeName"]
}


/** 공지 데이터 구조 ::: 주의!!!! JSON 포맷 !!!!!!!! ._text 필드
 * request: serviceKey
 * response: msgBody.noticeList [{ noticeTp, popTp, popTitle, popContent, startTime(yyyymmddhh), imgUrl, linkUrl, offerAgent}]
 */

/** 이벤트 데이터 구조
/* request: serviceKey, routeId, stationId, version(=2)
/* msgBody.eventList [{ subject, routeId, startDt(yyyymmddhhnnss), endDt, content}]
*/

class AlarmContextProvider extends Component {
    static taskTimer = null;

    constructor(props) {
        super(props);
        this.state = {
            devel: {},            // { enable }
            userLocation: null,   // { coords { longitude, latitude }} 제한적으로 사용됨
            initState: {
                isLoading: true,
                fontSize: null,
                contextInitialized: false,
            },
            // board, events
            popLoadTime: null,
            popShownBoards: null, // 이미 팝업한 목록 [{ noticeTp, popTitle, startTime }]
            popBoards: [],        // 팝업해야하는 목록 [{board}]
            boards: [],           // 전체 목록 [{board}]
            boardTime: null,
            // 보여준. 노선 이벤트. 런타임에만 유지
            routeEventShownList: [], // item = {stationId, routeId, time}
            routeEvents: [],
            // alarm
            wantedNthPrev: [1, 2, 3],
            fails: {
                code: FAILS.none,
                startTime: null,
                count: 0,
            },
            // 최근 수행한 무정차 신고.
            recentNonStopDeclare: null,
            // 마지막 수행한 승차대기 요청. 정상 종료된 경우에만 10분 유지
            recentBusNotify: null,
            busNotifyTransferBusState: {},
            // on || notify setting
            onAlarm: {
                type: "",           // should be one of "notify|onAlarm"
                stationId: "",
                stationSeq: "",
                stationName: "",
                routeId: "",
                routeName: "",
                vehId: "",
                plateNo: "",
                x: 0,
                y: 0,
                time: null,
            },
            // 승차하려는 버스위치
            onBusCurrLocation: {
                stationId: "",
                nthPrev: "",
                notified: false,
                time: null
            },
            // off alarm setting
            offAlarm: {
                stationId: "",
                routeId: "",
                stationSeq: 0,
                time: null,
            },
            offRideBus: null,
            // prepare station list of off alarm route
            offRouteStations: [],          // 선택된 노선의 정류소 목록
            offCurrLocation: {
                location: null,             // busLocation
                time: null,
                notified: false,
            },
            tickTime: null,
            // test
            test: {
                recent_noti_time: null
            }
        };
        this.msgHandler = React.createRef();
        this.runAlarmTask = this.runAlarmTask.bind(this);
        this.setBusNotify = this.setBusNotify.bind(this);
        this.setBusOnAlarm = this.setBusOnAlarm.bind(this);
        this.setBusOffAlarm = this.setBusOffAlarm.bind(this);
        this.linkBusNotifyToOnAlarm = this.linkBusNotifyToOnAlarm.bind(this);

        this.cancelAlarm = this.cancelAlarm.bind(this);
        this.getActiveAlarm = this.getActiveAlarm.bind(this);
        this.declareNonStopRun = this.declareNonStopRun.bind(this);
        this.declareNonStopRunAny = this.declareNonStopRunAny.bind(this);

        this.saveWantedNthPrev = this.saveWantedNthPrev.bind(this);


        this.refreshPopups = this.refreshPopups.bind(this);
        this.saveShownPopBoards = this.saveShownPopBoards.bind(this);
        this.saveAllPopBoards = this.saveAllPopBoards.bind(this);
        this.refreshBoards = this.refreshBoards.bind(this);

        this.refreshRouteEvents = this.refreshRouteEvents.bind(this);
        this.saveRouteEventsNoMoreShowTime = this.saveRouteEventsNoMoreShowTime.bind(this);        

        //
        this.setInitState = this.setInitState.bind(this);
        this.setFontSize = this.setFontSize.bind(this);
        this.setForeignLanguage = this.setForeignLanguage.bind(this);
        //
        this.saveDevelSetting = this.saveDevelSetting.bind(this);
        this.loadDevelSetting = this.loadDevelSetting.bind(this);
        //
        this.queryServerAppSetting = this.queryServerAppSetting.bind(this);
    }

    //--- get app loading state from App.js => MainTabStackNav
    setInitState(value) {
        log(`update initState: `, value)
        if (Number.isInteger(value.fontSize) && this.state.initState.fontSize !== value.fontSize) {
            // 저장
            FavorUtil.SaveGlobalFontSize(value.fontSize);
            // 폰트 스타일 변경
            Fonts.GenerateFontStyles(value.fontSize);
        }
        this.setState({
            initState: value
        }, () => {
            if (!this.state.initState.isLoading && Number.isInteger(this.state.initState.fontSize)) {
                log(`Initializing context since initState is ready.`, this.state.initState)
                this._initContext();
            }
        })
    }

    //--- initialize app
    _initContext() {
        if (!this.state.contextInitialized) {
            this.setState({ initState: { ...this.state.initState, contextInitialized: true } }, () => {
            });
            // webfix
            // // load saved settings
            // this.loadUserAlarmNths();
            // // 마지막으로 수행한 승차벨, 무정차신고
            // this.loadSavedRecentBusNotify();
            // this.loadRecentNonStopDeclare();
            // this.loadDevelSetting(() => {
            //     // override appSetting from server => start background timer
            //     this.queryServerAppSetting(() => {
            //         this.setState({ initState: { ...this.state.initState, contextInitialized: true } }, () => {
            //             this._startBackgroundTimer();
            //         })
            //     });
            // })
        }
    }

    setFontSize(fontSize, callback) {
        if (this.state.initState.fontSize !== fontSize) {
            log(`updating fontSize to ${fontSize}`)
            // 저장
            FavorUtil.SaveGlobalFontSize(fontSize);
            // 폰트 스타일 변경
            Fonts.GenerateFontStyles(fontSize);
            // 
            this.setState({
                initState: {
                    fontSize: fontSize
                }
            })
        }
        callback?.()
    }

    setForeignLanguage(foreignlanguage, callback) {
        if (this.state.foreignlanguage !== foreignlanguage) {
            log(`updating foreignlanguage from ${this.state.foreignlanguage} to ${foreignlanguage}`);            
            // 저장
            FavorUtil.SaveForeignLanguage(foreignlanguage);
            // 폰트 스타일 변경
            // Fonts.GenerateFontStyles(fontSize);
            //
            this.setState({                
                foreignLanguage: foreignlanguage,
            });
        }
        callback?.();
    }
    
    _startBackgroundTimer() {
        // webfix
        // if (!this.constructor.taskTimer) {
        //     this.constructor.taskTimer = new AlarmTaskTimer();
        //     this.constructor.taskTimer.start(AppSetting.ALARM.TASK_INTERVAL, this.runAlarmTask)
        // }
    }

    //--- Local notification !!
    _fire(title, msg) {
        PushNotifier.fire(title, msg)
    }

    //-----------------------------------------------------------------
    // 최근 사용한 승차벨
    //-----------------------------------------------------------------
    loadSavedRecentBusNotify() {
        StorUtil.GetJSON("recentBusNotify", null, (value) => {
            if (value && (!this.state.recentBusNotify || Object.entries(this.state.recentBusNotify).length < 1)) {
                log(`저장된 최근 승차대기 요청 로딩.`, value);
                this.setState({
                    recentBusNotify: value
                }, () => {
                    this._expireRecentBusNotify();
                })
            }
        })
    }

    _expireRecentBusNotify() {
        if (this.state.recentBusNotify) {
            if (DateUtil.GetElapsedSecs(this.state.recentBusNotify.finishTime) > AppSetting.RIDE_SUPPORT.BUSNOTIFY_RECENT_SHOW_SEC) {
                log(`최근 수행한 승차대기 클리어. ${DateUtil.GetElapsedSecs(this.state.recentBusNotify.finishTime)} 초 경과`);
                this.setState({
                    recentBusNotify: null
                })
            }
        }
    }


    //-----------------------------------------------------------------
    // 사용자 설정 승차벨 위치
    //-----------------------------------------------------------------
    saveWantedNthPrev(values) {
        StorUtil.Save("wantNthPrevAlarms", values, "", () => {
            log(`알람 설정 N'th 정류소 저장.`, values);
            this.setState({
                wantedNthPrev: values
            })
        })
    }

    loadUserAlarmNths() {
        StorUtil.GetJSON("wantNthPrevAlarms", [1, 2, 3], (value) => {
            let arr = value.filter(elem => [1, 2, 3].includes(elem))
            log(`알람 설정 N'th 정류소 로딩.`, arr);
            this.setState({
                wantedNthPrev: arr
            })
        })
    }

    //-----------------------------------------------------------------
    // 모든 알람 소거
    //-----------------------------------------------------------------
    _resetAlarm() {
        log(`알람 클리어!!!!!!!!!`);
        this.setState({
            fails: {
                code: FAILS.none,
                count: 0,
            },
            busNotifyTransferBusState: {},
            onAlarm: null,
            onBusCurrLocation: {},
            offAlarm: null,
        })
    }

    _alarmExist(alarm) {
        return (alarm && alarm.time && alarm.type)
    }

    //-----------------------------------------------------------------
    // 메인 알람 작업 
    //-----------------------------------------------------------------
    runAlarmTask(counter) {
        if (this.state.initState.isLoading || !Number.isInteger(this.state.initState.fontSize)) {
            return;
        }

        // console.log(`[${this.constructor.name}] run alarm check. coutner: ${counter}, elapsed: ${DateUtil.GetElapsedSecs(this.state.tickTime)}`);
        if (DateUtil.GetElapsedSecs(this.state.tickTime) < AppSetting.ALARM.TASK_INTERVAL / 1000) {
            return;
        }
        this.setState({ tickTime: DateUtil.now() })
        // expire recent busNotify
        if (this.state.recentBusNotify) {
            this._expireRecentBusNotify();
        }
        // on alarm
        // console.log("++++++++++++++++++++++++")
        if (this._alarmExist(this.state.onAlarm)) {
            log("승차벨알림 task 시작")
            //--- 버스통보여부 확인
            if (this.state.busNotifyTransferBusState.sendTerminalYn !== "Y") {
                this._onAlarm_proveNotifyTransferedToBus((done) => {
                    if (done) {
                        this._onAlarm_postTransferToBus();
                        this._onAlarm_proveNearBus();
                    }
                    else {
                        if (AppSetting.RIDE_SUPPORT.BUSNOTIFY_FORCE_STOP_WHEN_TRANSFER_FAIL) {
                            this._onAlarm_decideFailStop();
                        }
                        else {
                            this._onAlarm_proveNearBus();
                        }
                    }
                })
            }
            //--- 버스위치 판독
            else {
                this._onAlarm_proveNearBus()
            }
        }
        // off alarm
        else if (this._alarmExist(this.state.offAlarm)) {
            log("하차알림 task 시작")
            this._offAlarm_proveRideOnBus()
        }
        // no alarm, do other task
        // else {
        //     if (AppSetting.POP_NOTICE.REFRESH_INTERVAL_MIN && 
        //         DateUtil.GetElapsedMins(this.state.popLoadTime) > AppSetting.POP_NOTICE.REFRESH_INTERVAL_MIN) {
        //         this.refreshPopups()                
        //     }        
        // }
    }

    //-----------------------------------------------------------------
    // 메인 알람 작업중 발생하는 오류 누적 업데이트
    //-----------------------------------------------------------------
    _updateFails(failCode, callback) {
        let count = this.state.fails.code === failCode ? this.state.fails.count + 1 : 1;
        let time = this.state.fails.code === failCode ? this.state.fails.startTime : DateUtil.now();
        this.setState({
            fails: {
                code: failCode,
                count: count,
                startTime: time
            }
        },
            () => {
                callback?.()
            })
    }

    //-----------------------------------------------------------------
    // 승차벨
    //-----------------------------------------------------------------
    //--- 승차벨이, 실제 버스에 통보되었는지 조회. 이 조회에 실패해도 알람이 연결되어 있으면 수행
    _onAlarm_proveNotifyTransferedToBus(callback) {
        if (this.state.onAlarm && this.state.onAlarm.type === "notify" && this.state.onAlarm.reqId) {
            // 정해진 시간내에서만 합니다.
            if (DateUtil.GetElapsedSecs(this.state.onAlarm.time) < AppSetting.RIDE_SUPPORT.BUSNOTIFY_TRANSFER_MAX_SEC) {
                GAPI.doRequest('getBusNotifyTransferdToBus', this.state.onAlarm.reqId, this.state.onAlarm.handiCd)
                    .then((res) => {
                        let { code, msg } = GAPI.parseResponseCode(res);
                        if (code === 0 && JsUtil.GText(res.response.msgBody.busWaitingItem, "sendTerminalYn") === "Y") {
                            log("승차벨 버스통보 확인됨")
                            this.setState({
                                busNotifyTransferBusState: {
                                    sendTerminalYn: "Y",
                                    time: DateUtil.now(),
                                }
                            }, () => {
                                callback?.(true)
                            })
                        }
                        else {
                            log("승차벨 버스통보 안됨", res)
                            this.setState({
                                busNotifyTransferBusState: {
                                    sendTerminalYn: "N",
                                    time: DateUtil.now(),
                                }
                            }, () => {
                                callback?.(false)
                            })
                        }
                    })
                    .catch(err => {
                        error("승차벨 버스통보 확인 오류", err)
                        callback?.(false)
                    })
                    .finally(() => {
                    })
            }
            else {
                callback?.(false)
            }
        }
        else {
            callback?.(false)
        }
    }

    _onAlarm_postTransferToBus() {
        this._fire("승차벨", GetText("busalarm", "NOTIFY_TRANSFERED"))
    }

    //--- 근접 2대 버스에서 승차벨 버스 탐색
    _onAlarm_proveNearBus() {
        GAPI.doRequest('getStationRouteArrival', this.state.onAlarm.routeId, this.state.onAlarm.stationId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    let routeArrival = res.response.msgBody.busArrivalItem;
                    log("승차벨. 근접버스 조회됨")
                    // console.log(routeArrival)
                    if (routeArrival.vehId1._text === this.state.onAlarm.vehId) {
                        log("승차벨. 근접버스#1 = 대상버스")
                        this._onAlarm_decideAlarm(routeArrival, "1");
                    }
                    else if (routeArrival.vehId2._text === this.state.onAlarm.vehId) {
                        log("승차벨. 근접버스#2 = 대상버스")
                        this._onAlarm_decideAlarm(routeArrival, "2");
                    }
                    else {
                        log("승차벨. 근접버스에 대상버스 없음")
                        this._onAlarm_proveFarBus();
                    }
                }
                else {
                    warn(`승차벨. 근접버스 조회 실패.`, res)
                    this._onAlarm_updateFails(FAILS.api)
                }
            })
            .catch(err => {
                error("승차벨. 근접버스 조회 실패", err)
                this._onAlarm_updateFails(FAILS.api)
            })
    }

    //--- 근접 2대 버스에서 없는 경우, 해당 노선 전체 버스에서 탐색
    _onAlarm_proveFarBus() {
        GAPI.doRequest('getRunningBusOfRoute', this.state.onAlarm.routeId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    let runningBusList = null;
                    if (Array.isArray(res.response.msgBody.busLocationList)) {
                        runningBusList = res.response.msgBody.busLocationList;
                    }
                    else {
                        runningBusList = [res.response.msgBody.busLocationList];
                    }
                    let runningBus = runningBusList.find(elem => elem.vehId._text === this.state.onAlarm.vehId)
                    if (runningBus) {
                        this._onAlarm_decideAlarm(runningBus);
                    }
                    else {
                        this._onAlarm_updateFails(FAILS.busNotFoundOnServer)
                    }
                }
                else {
                    warn(`승차벨. 노선의 운행버스위치 조회 실패.`, res)
                    this._onAlarm_updateFails(FAILS.api)
                }
            })
            .catch(err => {
                warn(`승차벨. 노선의 운행버스위치 조회 실패.`, err)
                this._onAlarm_updateFails(FAILS.api)
            })
    }

    //--- 승차벨 처리중 발생하는 오류 누적
    _onAlarm_updateFails(failCode) {
        this._updateFails(failCode, () => {
            this._onAlarm_decideFailStop();
        })
    }

    //--- 승차벨 처리중 누적된 오류, 시간초과등을 검사해서 비정상이면, 종료 처리
    _onAlarm_decideFailStop() {
        // gps, api 오류 반복
        if (this.state.fails.code !== FAILS.none && this.state.fails.count > AppSetting.ALARM.FAIL_ALLOWED_COUNT) {
            warn(`승차벨. 오류반복으로 취소. ${this.state.fails.code}`)
            this._onAlarm_fire(null, this.state.fails.code);
            this._resetAlarm();
        }
        // timeout
        try {
            // notify transfer (to bus) timeout
            if (this.state.busNotifyTransferBusState.sendTerminalYn !== "Y" &&
                AppSetting.RIDE_SUPPORT.BUSNOTIFY_FORCE_STOP_WHEN_TRANSFER_FAIL &&
                DateUtil.GetElapsedSecs(this.state.onAlarm.time) > AppSetting.RIDE_SUPPORT.BUSNOTIFY_TRANSFER_MAX_SEC) {
                warn(`승차벨. 버스통보시간초과로 취소. ${DateUtil.GetElapsedSecs(this.state.onAlarm.time)} 초 경과`)
                this._onAlarm_fire(null, FAILS.notifyTransferFailed);
                this._resetAlarm();
            }
            // total timeout
            if (DateUtil.GetElapsedSecs(this.state.onAlarm.time) > AppSetting.RIDE_SUPPORT.BUSNOTIFY_RUN_MAX_SEC) {
                warn(`승차벨. 시간초과로 취소. ${DateUtil.GetElapsedSecs(this.state.onAlarm.time)} 초 경과`)
                this._onAlarm_fire(null, FAILS.totalTimeout);
                this._resetAlarm();
            }
        }
        catch (e) {
            error(`승차벨. 초과시간확인 실패로 취소`, e)
            this._resetAlarm();
        }
    }

    //--- 승차벨 처리중 Notification 처리여부 판단 return { should_noti, finished }
    _onAlarm_ShouldNotify(nthPrev) {
        let noti = false;
        let done = false;
        if (this.state.onAlarm.type === "notify" && !this.state.onAlarm.alarmLink) {
            log(`승차벨. 알림연결이 설정되지 않아, 알림 건너뜀`)
            // 승차대기는 알람 안하고, 일정 거리내 버스가 오면 종료함
            return { noti: false, done: parseInt(nthPrev) < 2 }
        }

        // 기존에 발견된 정류소가 없거나, 버스의 정류소 위치가 변경된 경우에 noti 수행함
        if (!this.state.onBusCurrLocation ||
            Object.entries(this.state.onBusCurrLocation).length < 1 ||
            this.state.onBusCurrLocation.nthPrev !== nthPrev) {

            (this.state.wantedNthPrev.length > 0) &&
                this.state.wantedNthPrev[0] >= parseInt(nthPrev)

            noti = this.state.wantedNthPrev.includes(parseInt(nthPrev)) ||
                // 이미 마지막 알람을 설정한 nthPrev 정류소를 지나친 경우
                ((this.state.wantedNthPrev.length > 0) &&
                    this.state.wantedNthPrev[0] >= parseInt(nthPrev) &&
                    parseInt(nthPrev) > 0)
        }

        // 1번째 전에 도착 또는 승차알림 설정의 마지막 값이면, 알람 종료
        done = parseInt(nthPrev) < 2 ||
            (noti && this.state.wantedNthPrev.indexOf(parseInt(nthPrev)) === 0) ||
            (this.state.wantedNthPrev.length == 0) ||
            (this.state.wantedNthPrev[0] >= parseInt(nthPrev))
        log(`승차벨. 알림 판독. 알림: ${noti}, 완료: ${done}, 번째전: ${nthPrev}`)
        return { noti: noti, done: done }
    }

    //--- 승차벨, 버스 위치 판독후 Notification 수행 및 상태 저장
    _onAlarm_decideAlarm(busLocationOrArrival, busSeq = null) {
        let nthPrev = 999;
        let stationId = "";
        if (busSeq) {
            nthPrev = parseInt(busLocationOrArrival["locationNo" + busSeq]._text);
        }
        else {
            nthPrev = parseInt(this.state.onAlarm.stationSeq) - parseInt(busLocationOrArrival.stationSeq._text);
            stationId = busLocationOrArrival.stationId._text;
        }

        let { noti, done } = this._onAlarm_ShouldNotify(nthPrev);

        if (noti) {
            let currLocation = {
                stationId: stationId,
                nthPrev: nthPrev,
                time: DateUtil.now(),
                notified: noti
            }
            this._onAlarm_fire(nthPrev)
            this.setState({
                fails: {
                    code: FAILS.none,
                    time: DateUtil.now(),
                },
                onBusCurrLocation: currLocation,
            })
            // console.log(`[${this.constructor.name}] on alarm fired. nthPrev: ${nthPrev}`)
        }
        if (done) {
            this._onAlarm_finish();
        }
        else {
            this._onAlarm_decideFailStop();
        }
    }

    //--- Fire notification
    _onAlarm_fire(nthPrev, failCode) {
        let title, msg = "";
        if (failCode) {
            title = "승차알림실패";
            msg = GetText("busAlarm", failCode);
        }
        else {
            title = "승차알림";
            msg = GetText("busAlarm", "ON_ALARM")
                .replace("{n}", nthPrev)
                .replace("{station}", this.state.onAlarm.stationName)
                .replace("{route}", this.state.onAlarm.routeName);
        }
        this._fire(title, msg);
        warn(`승차벨. 알림수행. "${msg}"`)
    }

    //--- 승차벨 종료
    _onAlarm_finish() {
        if (this.state.onAlarm.type === "notify") {
            let notify = Object.assign({}, this.state.onAlarm);
            notify.finishTime = DateUtil.nowString();
            AlarmUtil.SaveRecentBusNotify(notify);
            this.setState({
                recentBusNotify: notify
            })
        }
        this._resetAlarm();
    }



    //-----------------------------------------------------------------
    // 하차알림
    //-----------------------------------------------------------------
    //--- 현재 사용자 위치 파악: deprecated
    _offAlarm_proveUserLocation(callback) {
        // step 1. 사용자 위치 파악
        GetLocation(null,
            (p) => {
                log(`하차벨. 사용자 위치 판독됨`)
                // update state: position of user
                this.setState({ userLocation: { position: p, time: DateUtil.now() } },
                    () => {
                        callback?.()
                    })
            },
            (err) => {
                // 앱이 백그라운드로 가면 위치정보 취득을 위해 별도 권한 필요.
                // off alarm 시작할때, 이미 초기값을 받으므로 업데이트 실패해도 초기값 사용
                warn(`하차벨. 사용자 위치 판독 실패`, err)
                // update user location
                callback?.()
            }
        )
    }

    //--- 사용자가 탑승한 버스 판독.
    // Step 1. 초기 정류소에서 제일 근접한 버스를 탐색해서 허용거리 이내면, 탑승한 버스로 간주하고 기록
    // Step 2. 탑승 버스위치를 조회해서 알람
    _offAlarm_proveRideOnBus() {
        GAPI.doRequest('getRunningBusOfRoute', this.state.offAlarm.routeId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    let runningBusList = null;
                    if (Array.isArray(res.response.msgBody.busLocationList)) {
                        runningBusList = res.response.msgBody.busLocationList;
                    }
                    else {
                        runningBusList = [res.response.msgBody.busLocationList];
                    }
                    // 탑승한 버스가 감지되어 있음
                    if (this.state.offRideBus) {
                        let rideBus = runningBusList.find(elem =>
                            elem.vehId._text === this.state.offRideBus.vehId._text
                        )
                        if (rideBus) {
                            log(`하차알림. 승차 버스 검지됨: stationSeq: ${rideBus.stationId._text}`)
                            this._offAlarm_decideAlarm(rideBus);
                        }
                        else {
                            warn(`하차알림. 승차 버스 검지안됨`)
                            this._offAlarm_updateFails(FAILS.busNotFoundOnServer)
                        }
                    }
                    // 탑승한 버스가 아직 감지되지 않음
                    else {
                        // 시작한 정류소 또는 다음 정류소에 위치한 버스를 탐색해서 승차 버스로 간주. 
                        let rideBus = runningBusList.find(elem =>
                            JsUtil.GInt(elem, "stationSeq") === JsUtil.GInt(this.state.offAlarm, "startStationSeq") ||
                            JsUtil.GInt(elem, "stationSeq") === JsUtil.GInt(this.state.offAlarm, "startStationSeq") + 1)
                        if (!rideBus) {
                            warn(`하차알림. (최초)승차 버스 검지안됨`)
                            this._offAlarm_updateFails(FAILS.rideBusNotDetected)
                        }
                        else {
                            log(`하차알림. (최초)승차 버스 검지됨: stationSeq: ${rideBus.stationId._text}`)
                            this.setState({
                                offRideBus: rideBus
                            }, () => {
                                this._offAlarm_decideAlarm(rideBus);
                            })
                        }
                    }
                }
                else {
                    error(`하차알림. 노선운행버스 조회 실패`, res)
                    this._offAlarm_updateFails(FAILS.api)
                }
            })
            .catch(err => {
                error(`하차알림. 노선운행버스 조회 실패`, err)
                this._offAlarm_updateFails(FAILS.api)
            })
    }

    //--- 하차알림에서 Notification 수행해야 하는지 결정. return { should_noti, finished }
    _offAlarm_ShouldNotify(nthPrev, busLocation) {
        let noti = false;
        let done = false;
        // 이전 정류소와 일치
        if (this.state.offCurrLocation &&
            this.state.offCurrLocation.location &&
            this.state.offCurrLocation.location.stationId._text === busLocation.stationId._text) {
            log(`하차알림. 버스위치 변화 없음`)
            noti = false;
        }
        else {
            log(`하차알림. 버스위치 변경. nthPrev=${nthPrev}`)
            noti = this.state.wantedNthPrev.includes(parseInt(nthPrev))
        }
        done = parseInt(nthPrev) < 2 || (noti && this.state.wantedNthPrev.indexOf(parseInt(nthPrev)) === 0)

        return { noti: noti, done: done }
    }

    //--- 사용자가 탑승한 버스 검지 후 하차 Notification 수행 및 상태 업데이트
    _offAlarm_decideAlarm(busLocation) {
        let nthPrev = 999;
        nthPrev = parseInt(this.state.offAlarm.offStationSeq) - parseInt(busLocation.stationSeq._text);

        let { noti, done } = this._offAlarm_ShouldNotify(nthPrev, busLocation);
        let currLocation = {
            location: busLocation,
            notified: noti,
            time: DateUtil.now()
        }

        log(`하차알림. 알림 검사완료. noti=${noti}, done=${done}, nthPrev=${nthPrev}`)
        if (noti) {
            this._offAlarm_fire(nthPrev)
        }
        if (done) {
            this._resetAlarm();
            return;
        }
        this.setState({
            fails: {
                code: FAILS.none,
                time: DateUtil.now()
            },
            offCurrLocation: currLocation,
        }, () => {
            this._offAlarm_decideFailStop();
        })
    }

    _offAlarm_updateFails(failCode) {
        this._updateFails(failCode, () => {
            this._offAlarm_decideFailStop();
        })
    }

    _offAlarm_decideFailStop() {
        // gps, api 오류 반복
        if (this.state.fails.code !== FAILS.none && this.state.fails.count > AppSetting.ALARM.FAIL_ALLOWED_COUNT) {
            warn(`하차알림. 오류반복으로 취소. ${this.state.fails.code}`)
            this._offAlarm_fire(0, this.state.fails.code);
            this._resetAlarm();
        }
        // 최초 사용자 정류소 위치 파악 불가
        if ((!this.state.offCurrLocation || !this.state.offCurrLocation.location) &&
            DateUtil.GetElapsedSecs(this.state.offAlarm.time) > AppSetting.RIDE_SUPPORT.OFFALARM_DETECT_START_STATION_MAX_SEC) {
            warn(`하차알림. ${DateUtil.GetElapsedSecs(this.state.offAlarm.time)}초 동안 최초승차버스 감지 안됨으로 취소`)
            this._offAlarm_fire(0, FAILS.firstStationNotDetected);
            this._resetAlarm();
        }
        try {
            let t = this.state.offAlarm.time;
            // total timeout
            if (DateUtil.GetElapsedSecs(t) > AppSetting.RIDE_SUPPORT.OFFALARM_RUN_MAX_SEC) {
                warn(`하차알림. 시간초과로 취소. ${DateUtil.GetElapsedSecs(t)} 초 경과`)
                this._offAlarm_fire(0, FAILS.totalTimeout);
                this._resetAlarm();
            }
        }
        catch (e) {
            error(`하차알림. 초과시간확인 실패로 취소`, e)
            this._resetAlarm();
        }
    }

    _offAlarm_fire(nthPrev, failCode) {
        let title, msg = "";
        if (failCode) {
            title = "하차알림실패";
            msg = GetText("busAlarm", failCode);
        }
        else {
            title = "하차알림";
            msg = GetText("busAlarm", "OFF_ALARM").replace("{n}", nthPrev).replace("{offStation}", this.state.offAlarm.offStationName);
        }
        this._fire(title, msg);
        warn(`하차알림. 알림수행. "${msg}"`)
    }
    //--- end of off alarm -------------------------------------------------------------------



    getActiveAlarm() {
        if (this._alarmExist(this.state.onAlarm)) {
            return this.state.onAlarm
        }
        if (this._alarmExist(this.state.offAlarm)) {
            return this.state.offAlarm
        }
        return null;
    }

    _validateAlarmProps(type, params) {
        _ALARM_PROPS[type].forEach(elem => {
            if (!params.hasOwnProperty(elem)) {
                error(`[Alarm.${type}] paramter ${elem} not found`, params)
                return false;
            }
        })
        return true;
    }

    //----------------------------------------------------------------------------------------
    // alarm { reqId, reqTime, stationId, stationName, x, y, routeId, routeName, vehId, plateNo, prevNth, alarmLink }
    // notify {...alarm, type: "notifty", alarmLink: true|false }
    //----------------------------------------------------------------------------------------
    setBusNotify(notify) {
        if (!this._validateAlarmProps("notify", notify)) {
            this.msgHandler?.dlg(GetText("pageWord","ALERT"), GetText("ridesupport", "ERROR"));
            return;
        }
        if (typeof notify === "object") {
            log("승차알림 설정", notify)
            notify.type = "notify";
            notify.time = DateUtil.nowString();
            // notify.alarmLink = true;
            // if (notify.hasOwnProperty("alarmLink")) {
            //     notify.alarmLink = false;
            // }
            this.setState({
                fails: {
                    code: FAILS.none,
                    count: 0,
                },
                busNotifyTransferBusState: {
                    sendTerminalYn: JsUtil.GText(notify, "sendTerminalYn"),
                    time: DateUtil.now()
                },
                // reset other alarms forcely
                onAlarm: notify,
                onBusCurrLocation: {},
                offAlarm: null,
                // userLocation: {
                //     position: null,
                //     time: null
                // },                
                recentBusNotify: null,
            })
            // this._onAlarm_finish();
            // AlarmUtil.SaveBusNotify(notify);
        }
        else {
            // 취소된 승차대기 요청은 저장하지 않음.
            if (this.state.onAlarm) {
                this.setState({
                    onAlarm: null
                })
            }
        }
    }

    linkBusNotifyToOnAlarm() {
        if (this.state.onAlarm && this.state.onAlarm.type === "notify") {
            log("승차벨 -> 알림으로 연결")
            this.setState({
                onAlarm: { ...this.state.onAlarm, alarmLink: true }
            })
        }
    }


    cancelAlarm(callback) {
        if (this.state.onAlarm && this.state.onAlarm.type === "notify" && this.state.onAlarm.reqId) {
            //console.log(`[${this.constructor.name}] cancelling bus notify. reqId: ${this.state.onAlarm.reqId}, handiCd: ${this.state.onAlarm.handiCd}`);
            GAPI.doRequest('cancelBusNotify', this.state.onAlarm.reqId, this.state.onAlarm.handiCd)
                .then((res) => {
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code !== 0 && code !== 1) {
                        error("승차벨 취소 실패", res)
                    }
                    else {
                        log("승차벨 취소됨", this.state.onAlarm)
                    }
                })
                .catch(err => {
                    error("승차벨 취소 실패", err)
                })
                .finally(() => {
                    this._resetAlarm();
                    callback?.()
                })
        }
        else {
            this._resetAlarm();
            callback?.()
        }
    }

    //------------------------------------------------------------------------------
    // on alarm { stationId, stationName, x, y, routeId, routeName, vehId, plateNo, prevNth }
    //------------------------------------------------------------------------------
    setBusOnAlarm(onAlarm) {
        this._validateAlarmProps("onAlarm", onAlarm);
        // reset other alarms forcely
        if (onAlarm) {
            onAlarm.type = "onAlarm";
            onAlarm.time = DateUtil.nowString();
            this.setState({
                fails: {
                    code: FAILS.none,
                    count: 0,
                },
                onAlarm: onAlarm,
                onBusCurrLocation: {},
                offAlarm: null,
                // userLocation: {
                //     position: null,
                //     time: null
                // },                
            })
        }
        else {
            if (this.state.onAlarm) {
                this.setState({
                    onAlarm: null,
                })
            }
        }
    }

    //------------------------------------------------------------------------------
    // off alarm {
    //    "startStationId", "startStationName", "startStationSeq", 
    //    "offStationId", "offStationName", "offStationSeq", 
    //    "routeTypeCd", "routeId", "routeName" }
    //------------------------------------------------------------------------------
    setBusOffAlarm(offAlarm, rideBus, userPosition, afterStations) {
        if (!this._validateAlarmProps("offAlarm", offAlarm)) {
            this.msgHandler?.dlg(GetText("pageWord","ALERT"), GetText("ridesupport", "ERROR"));
            return;
        }
        console.log("======================", rideBus)
        // reset other alarms forcely
        if (offAlarm) {
            log("하차알림 설정", offAlarm)
            offAlarm.type = "offAlarm";
            offAlarm.time = DateUtil.nowString();
            offAlarm.stationId = offAlarm.startStationId;
            this.setState({
                fails: {
                    code: FAILS.none,
                    count: 0,
                },
                userLocation: userPosition,
                onAlarm: null,
                offAlarm: offAlarm,
                offRideBus: rideBus,
                offRouteStations: afterStations,    // 선택된 노선의 정류소 목록
                offCurrLocation: null,              // 버스상태로 파악된 사용자의 현재 위치 정류소
            })
            this._fire("하차알림", GetText("ridesupport", "OFFALARM_STARTED"))
        }
        else {
            this._resetAlarm();
        }
    }

    //-----------------------------------------------------------------
    // 무정차 신고 기능
    //-----------------------------------------------------------------
    loadDevelSetting(callback) {
        StorUtil.GetJSON("devel", null, (value) => {
            if (value) {
                log(`개발자용 설정 로딩`, value);
                this.setState({
                    devel: value
                }, () => {
                    callback?.()
                })
            }
            else {
                callback?.()
            }
        })
    }

    //-----------------------------------------------------------------
    // 무정차 신고 기능
    //-----------------------------------------------------------------
    loadRecentNonStopDeclare() {
        StorUtil.GetJSON("recentNonStopDeclare", null, (value) => {
            if (value && DateUtil.GetElapsedMins(JsUtil.GText(value, "time")) < AppSetting.NONSTOP_DECLARE.PREVENT_FUNNY_TRIAL_MINS) {
                log(`저장된 최근 무정차신고내역 로딩.`, value);
                this.setState({
                    recentNonStopDeclare: value
                })
            }
        })
    }

    saveRecentNonStopDeclare(stationId, routeId, vehId, time) {
        this.setState({
            recentNonStopDeclare: {
                stationId,
                routeId,
                vehId,
                time
            }
        }, () => {
            StorUtil.Save("recentNonStopDeclare", this.state.recentNonStopDeclare, "", () => {
                log(`무정차 신고내역 저장.`, this.state.recentNonStopDeclare);
            })
        })
    }

    // restapi: declareNonStopRun: (stationId, staOrder, routeId, vehId, applicantTime) => {
    declareNonStopRun(success, fail) {
        if (this.state.recentBusNotify && this.state.recentBusNotify.type === "notify") {
            // console.log(`[${this.constructor.name}] declaring non-stop run. reqId: ${this.state.recentBusNotify.reqId}`);
            // let url = `/passReport/passReportLogin.do?` +
            //     `serviceKey=${AppSetting.SERVICE_KEY}` +
            //     `&stationId=${this.state.recentBusNotify.stationId}` +
            //     `&staOrder=${this.state.recentBusNotify.stationSeq}` +
            //     `&routeId=${this.state.recentBusNotify.routeId}` +
            //     `&vehId=${this.state.recentBusNotify.vehId}` +
            //     `&applicantTime=${this.state.recentBusNotify.reqTime}`;
            GAPI.doRequest('declareNonStopRun',
                this.state.recentBusNotify.stationId,
                this.state.recentBusNotify.stationSeq,
                this.state.recentBusNotify.routeId,
                this.state.recentBusNotify.vehId,
                this.state.recentBusNotify.reqTime)
                .then((res) => {
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code === 0) {
                        log("무정차 신고 수행됨", this.state.recentBusNotify)
                        success?.();
                    }
                    else {
                        error("무정차 신고 수행 실패", res)
                        fail?.();
                    }
                })
                .catch((e) => {
                    error("무정차 신고 수행 실패", e)
                })
                .finally(() => {
                    this.setState({
                        recentBusNotify: null,
                    })
                })
        }
    }

    // restapi: declareNonStopRun: (stationId, staOrder, routeId, vehId, applicantTime) => {
    declareNonStopRunAny(station, route, bus, success, fail) {
        if (station && route && JsUtil.GText(bus, "vehId")) {
            GAPI.doRequest('declareNonStopRun',
                JsUtil.GText(station, "stationId"),
                JsUtil.GText(bus, "staOrder"),
                JsUtil.GText(route, "routeId"),
                JsUtil.GText(bus, "vehId"),
                DateUtil.format(DateUtil._DT_FORMAT_COMPACT))
                .then((res) => {
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code === 0) {
                        log("무정차 신고 수행됨", bus)
                        this.saveRecentNonStopDeclare(
                            JsUtil.GText(station, "stationId"),
                            JsUtil.GText(station, "routeId"),
                            JsUtil.GText(station, "vehId"),
                            DateUtil.format(DateUtil._DT_FORMAT_COMPACT))
                        success?.();
                    }
                    else {
                        error("무정차 신고 수행 실패", res)
                        fail?.();
                    }
                })
                .catch((e) => {
                    error("무정차 신고 수행 실패", e)
                })
                .finally(() => {
                })
        }
    }
    //--- 무정차 신고 기능 end 

    componentDidMount() {
        console.log("[AlarmContextProvider] mounted")
    }

    componentWillUnmount() {

    }




    //-----------------------------------------------------------------
    // 팝업공지 조회
    //-----------------------------------------------------------------
    refreshPopups(callback) {
        // do nothing
        if (this.state.initState.isLoading || !Number.isInteger(global.fontSize)) {
            return;
        }
        if (this.state.popLoadTime) {
            // if (DateUtil.GetElapsedMins(this.state.popLoadTime) < AppSetting.POP_NOTICE.REFRESH_INTERVAL_MIN) {
            return;
        }
        this.setState({
            popLoadTime: DateUtil.now()
        })
        if (!this.state.popShownBoards) {
            StorUtil.GetJSON("popShownBoards", [], (value) => {
                let arr = []
                if (value && Array.isArray(value)) {
                    // 1개월 유지
                    arr = value.filter(elem => {
                        return DateUtil.GetElapsedDays(elem.time) <= AppSetting.POP_NOTICE.NO_SHOW_DAYS;
                    })
                    log(`shown pop boards loaded:`, arr);
                }
                this.setState({
                    popShownBoards: arr
                }, () => {
                    this.refreshBoards(() => {
                        this._updatePopBoards(this.state.boards, callback)
                    })
                })
            })
        }
        else {
            this.refreshBoards(() => {
                this._updatePopBoards(this.state.boards, callback)
            })
        }
    }

    saveShownPopBoards(indexes) {
        log(`보여준 팝업 목록 저장 요청.`, indexes);
        let arr = this.state.popShownBoards;
        if (!arr) arr = [];
        indexes.forEach(idx => {
            if (idx < this.state.popBoards.length) {
                if (!arr.find(shownElem =>
                    shownElem.popTitle == this.state.popBoards[idx].popTitle &&
                    shownElem.startTime == this.state.popBoards[idx].startTime &&
                    shownElem.endTime == this.state.popBoards[idx].endTime &&
                    shownElem.noticeTp == this.state.popBoards[idx].noticeTp)) {
                    arr.push({
                        noticeTp: this.state.popBoards[idx].noticeTp,
                        popTitle: this.state.popBoards[idx].popTitle,
                        startTime: this.state.popBoards[idx].startTime,
                        endTime: this.state.popBoards[idx].endTime,
                        time: DateUtil.format()
                    })
                }
            }
        })
        this.setState({
            popShownBoards: arr,
            popBoards: this.state.popBoards.filter((elem, index) => !indexes.includes(index))
        })
        StorUtil.Save("popShownBoards", arr)
        log(`보여준 팝업 목록 저장.`, arr);
    }

    // 공지사항 페이지에 사용자가 진입하면. 전체 보여준 것으로 간주
    saveAllPopBoards() {
        let arr = this.state.popShownBoards;
        if (!arr) arr = [];
        this.state.popBoards.forEach(elem => {
            if (!arr.find(shownElem =>
                shownElem.popTitle == elem.popTitle &&
                shownElem.startTime == elem.startTime &&
                shownElem.endTime == elem.endTime &&
                shownElem.noticeTp == elem.noticeTp)) {
                arr.push({
                    noticeTp: elem.noticeTp,
                    popTitle: elem.popTitle,
                    startTime: elem.startTime,
                    endTime: elem.endTime,
                    time: DateUtil.format()
                })
            }
        })
        this.setState({
            popShownBoards: arr
        }, () => {
            StorUtil.Save("popShownBoards", arr)
            log(`전체 팝업공지를 보여준 목록에 저장.`, arr);
        })
    }

    _updatePopBoards(boards, callback) {
        let news = boards.filter(elem => {
            let isNew = elem.noticeTp == 1 &&
                !this.state.popShownBoards.find(shownElem =>
                    shownElem.popTitle === elem.popTitle &&
                    shownElem.startTime === elem.startTime &&
                    shownElem.endTime === elem.endTime &&
                    shownElem.noticeTp === elem.noticeTp
                )
            if (!isNew & elem.noticeTp == 1) {
                console.log("==== 보여준 팝업 필터링.", elem)
            }
            return isNew;
        });

        // fixme for test
        // news = boards;
        this.setState({
            popLoadTime: DateUtil.now(),
            popBoards: news,
        }, () => {
            if (news && news.length > 0)
                log(`새로운 팝업공지 로딩.`, news);
            callback?.()
        })
    }

    //-----------------------------------------------------------------
    // 공지 조회
    //-----------------------------------------------------------------
    // [{ popTp, popTitle, popContent, startTime(yyyymmddhh), imgUrl, linkUrl, offerAgent}]
    refreshBoards(success, fail) {
        // get boards
        GAPI.doRequest('getBoards')
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0 || code == 4) {
                    let arr = []
                    if (code === 4) {
                    }
                    else {
                        if (Array.isArray(res.response.msgBody.noticeList)) {
                            arr = res.response.msgBody.noticeList;
                        }
                        else {
                            arr = [res.response.msgBody.noticeList];
                        }
                    }
                    arr.forEach(elem => {
                        elem.startTime = elem.startTime.toString()
                        elem.endTime = elem.endTime.toString()
                    })
                    log("공지사항 조회됨.", arr)
                    this.setState({
                        boards: arr,
                        boardTime: DateUtil.now()
                    }, () => {
                        success?.()
                    })
                }
                else {
                    error(`공지 조회 실패.`, res);
                    this.setState({
                        boards: [],
                        boardTime: DateUtil.now()
                    }, () => {
                        fail?.()
                    })
                }
            })
            .catch(err => {
                error(`공지 조회 실패.`, err);
                this.setState({
                    boards: [],
                    boardTime: DateUtil.now()
                }, () => {
                    fail?.(GetText("error", "unknown"))
                })
            })
    }

    //-----------------------------------------------------------------
    // 노선행사팝업 조회. 정해진 시간동안은 중복 노출 안함.
    //-----------------------------------------------------------------
    //-----------------------------------------------------------------
    // 노선행사팝업 조회. 정해진 시간동안은 중복 노출 안함.
    //-----------------------------------------------------------------
    saveRouteEventsNoMoreShowTime() {
        StorUtil.Save('routeEventsNoMoreShowSetTime', DateUtil.format(moment()), '', () => {
            log(`노선이벤트 NoMoreShow 시각 저장. ${DateUtil.format(moment())}`);
        });
    }

    async loadRouteEventsNoMoreShowTime() {
        return await StorUtil.GetJSON('routeEventsNoMoreShowSetTime', null, null);
    }
    
    async refreshRouteEvents(stationId, routeId, callback) {
        //console.log("------------------------- 1")
        try {
            // 사용자가 7일간 더이상 보지 않기를 선택한 경우, 건너뜀
            const value = await this.loadRouteEventsNoMoreShowTime();
            if (value && DateUtil.GetElapsedDays(value) <= 7) {
                this.setState(
                    {
                        routeEvents: [],
                    },
                    () => {
                        callback?.(true);
                    },
                );
                return;
            }
            
            if (!stationId) stationId = "";
            let shown = this.state.routeEventShownList.find(elem =>
                elem.stationId === stationId &&
                elem.routeId === routeId &&
                DateUtil.GetElapsedMins(elem.time) < AppSetting.POP_NOTICE.NO_SHOW_ROUTE_EVENTS_MIN
            )
            if (shown) {
                this.setState({
                    routeEvents: []
                }, () => {
                    callback?.(true)
                })
                return;
            }
            //console.log("------------------------- 1")       
            let arr = this.state.routeEventShownList.filter(elem =>
                elem.stationId !== stationId &&
                elem.routeId !== routeId);
            arr.push({
                stationId: stationId,
                routeId: routeId,
                time: DateUtil.now()
            })
            this.setState({
                routeEventShownList: arr,
            })
            // get event
            GAPI.doRequest('getEvents', stationId, routeId)
                .then((res) => {
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code === 0 || code === 4) {
                        let arr = []
                        if (code === 4) {
                            arr = []
                        }
                        else {
                            if (Array.isArray(res.response.msgBody.eventList)) {
                                arr = res.response.msgBody.eventList;
                            }
                            else {
                                arr = [res.response.msgBody.eventList];
                            }
                        }
                        arr.forEach(elem => {
                            if (Number.isInteger(elem.startDt))
                                elem.startDt = elem.startDt.toString()
                            if (Number.isInteger(elem.endDt))
                                elem.endDt = elem.endDt.toString()
                        })
                        log("노선이벤트 조회됨.", arr)
                        this.setState({
                            routeEvents: arr
                        }, () => {
                            callback?.(true)
                        })
                    }
                    else {
                        error(`노선이벤트 조회 실패.`, res);
                        this.setState({
                            routeEvents: [],
                        }, () => {
                            callback?.(false)
                        })
                    }
                })
                .catch(err => {
                    error(`노선이벤트 조회 실패.`, err);
                    this.setState({
                        routeEvents: [],
                    }, () => {
                        callback?.(false)
                    })
                })

        }
        catch (e) {
            callback?.(false)
        }
    }


    //-----------------------------------------------------------------
    // 설정 Override 
    //-----------------------------------------------------------------
    _textToArray(_textArr, wantedType) {
        let arr = [];
        _textArr.forEach(elem => {
            let value = wantedType === 'integer' ? JsUtil.GInt(elem, "_text") : JsUtil.GText(elem, "_text");
            if (value === 0 || value) {
                arr.push(value)
            }
        })
        return arr;
    }

    _textToValue(obj, prop, currValue) {
        if (!JsUtil.isProp(obj, prop)) {
            return undefined;
        }
        if (JsUtil.isBoolean(currValue)) {
            let value = JsUtil.GText(obj, prop);
            if (JsUtil.isString(value)) {
                return value.toUpperCase() === "FALSE" ? false : true;
            }
            return undefined;
        }
        if (JsUtil.isInteger(currValue)) {
            let value = JsUtil.GText(obj, prop);
            if (value) {
                return JsUtil.isInteger(value) ? parseInt(value) : undefined;
            }
            return undefined;
        }
        if (JsUtil.isString(currValue)) {
            let value = JsUtil.GText(obj, prop);
            if (value === "" || value) {
                return value;
            }
            return undefined;
        }
    }

    // 2 단계 깊이까지만 수용합니다.
    _overrideServerAppSettings(settings) {
        // return;
        if (_UPDATABLE_APPSETTING_PROPS && _UPDATABLE_APPSETTING_PROPS.length > 0) {
            _UPDATABLE_APPSETTING_PROPS.forEach(prop => {
                if (AppSetting[prop] && settings[prop]) {

                    if (Array.isArray(AppSetting[prop])) {
                        // array => array
                        if (Array.isArray(settings[prop])) {
                            let arr = this._textToArray(settings[prop], JsUtil.getArrayElemType(AppSetting[prop]));
                            if (Array.isArray(arr)) {
                                AppSetting[prop] = arr
                                log(`설정업데이트. AppSetting.${prop}`, AppSetting[prop])
                            }
                            else {
                                error(`can not update AppSetting.${prop} server provides invalid type. array elememt type not supported`)
                            }
                        }
                        // single element => array
                        else if (JsUtil.isObjectProp(settings, prop) && JsUtil.isStringProp(settings[prop], "_text")) {
                            if (JsUtil.getArrayElemType(AppSetting[prop]) === "string") {
                                AppSetting[prop] = [settings[prop]["_text"]]
                                log(`설정업데이트. AppSetting.${prop} => ${AppSetting[prop]}`)
                            }
                            else if (JsUtil.getArrayElemType(AppSetting[prop]) === "integer") {
                                AppSetting[prop] = [parseInt(settings[prop]["_text"])]
                                log(`설정업데이트. AppSetting.${prop} => ${AppSetting[prop]}`)
                            }
                            else {
                                error(`can not update AppSetting.${prop} server provides invalid type. array expected`)
                            }
                        }
                        else {
                            error(`can not update AppSetting.${prop} server provides invalid type. array expected`)
                        }
                    }
                    else if (typeof AppSetting[prop] === "object") {
                        if (typeof settings[prop] === "object") {
                            Object.keys(AppSetting[prop]).forEach(p => {
                                if (settings[prop][p]) {
                                    let v = this._textToValue(settings[prop], p, AppSetting[prop][p])
                                    if (v !== undefined && v !== null) {
                                        log(`설정업데이트. AppSetting.${prop}.${p}: ${AppSetting[prop][p]} -> ${v}`)
                                        AppSetting[prop][p] = v;
                                    }
                                    else {
                                        error(`can not update AppSetting.${prop}.${p} server provides invalid type. primitive value expected`)
                                    }
                                }
                            })
                        }
                        else {
                            error(`can not update AppSetting.${prop} server provides invalid type. object expected`)
                        }
                    }
                    // primitive
                    // else if (Object(AppSetting[prop]) !== AppSetting[prop]) {
                    //     if (Object(settings[prop]) !== settings[prop]) {
                    //         AppSetting[prop] = settings[prop];
                    //         console.log(`[${this.constructor.name}] AppSetting.${prop} updated from server. ${AppSetting[prop]}`)
                    //     }
                    //     else {
                    //         console.log(`[${this.constructor.name}] can not update AppSetting.${prop} server provides invalid type. primitive value expected`)
                    //     }
                    // } 
                }
            })
            // fixme for test
            // AppSetting.RIDE_SUPPORT.BUSNOTIFY_ALLOWED_DISTANCE_FROM_STATION = 100;
            // AppSetting.RIDE_SUPPORT.OFFALARM_DETECT_DISTANCE_FROM_STATION = 100;
        }
    }

    // query app setting from server. then update local app setting
    queryServerAppSetting(callback) {
        GAPI.doRequest('getAppSetting', this.state.devel && this.state.devel.enable)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code === 4) {
                    log(`설정업데이트. 서버제공항목 없음. ${this.state.devel.enable ? "(개발자모드)" : ""}`)
                }
                else if (code === 0) {
                    // if (__DEV__ && !AppSetting.DEBUGS.OVERRIDE_APP_SETTING) {
                    //     log("설정업데이트. 디버깅 모드 설정으로 건너뜀", res.response.msgBody);
                    // }
                    // else {
                    log(`설정업데이트. 서버제공항목${this.state.devel.enable ? "(개발자모드)" : ""}:`, res.response.msgBody);
                    this._overrideServerAppSettings(res.response.msgBody);
                    // 즐겨찾기 + 설정 => 위젯 전달
                    StorUtil.Share(FavorUtil.BuildFavorStationRoutesForWidget(global.historyReview))
                    // }
                }
                else {
                    error(`설정업데이트 조회 실패`, res)
                }
            })
            .catch(err => {
                error(`설정업데이트 조회 실패`, err)
            })
            .finally(() => {
                callback?.()
            })
    }
    saveDevelSetting(enable) {
        StorUtil.Save("devel", { enable: enable }, "", () => {
            log(`개발자용 설정 저장. ${enable ? "enabled" : "disabled"}`);
        })
    }


    render() {
        return (
            <AlarmContext.Provider
                value={{
                    devel: this.state.devel,
                    //
                    initState: this.state.initState,
                    setInitState: this.setInitState,
                    setFontSize: this.setFontSize,
                    setForeignLanguage: this.setForeignLanguage,
                    // msgHandler
                    msgHandler: this.msgHandler,
                    // 공지 팝업 지원
                    popLoadTime: this.state.popLoadTime,
                    popBoards: this.state.popBoards,
                    // 
                    boards: this.state.boards,
                    boardTime: this.state.boardTime,
                    refreshPopups: this.refreshPopups,
                    saveShownPopBoards: this.saveShownPopBoards,
                    saveAllPopBoards: this.saveAllPopBoards,
                    refreshBoards: this.refreshBoards,
                    // 노선 이벤트
                    routeEvents: this.state.routeEvents,
                    routeEventShownList: this.state.routeEventShownList,
                    refreshRouteEvents: this.refreshRouteEvents,
                    saveRouteEventsNoMoreShowTime: this.saveRouteEventsNoMoreShowTime,
                    // 알람
                    onAlarm: this.state.onAlarm,
                    offAlarm: this.state.offAlarm,
                    recentBusNotify: this.state.recentBusNotify,
                    offAlarmCurrLocation: this.state.offCurrLocation,
                    wantedNthPrev: this.state.wantedNthPrev,
                    // export methods
                    setBusNotify: this.setBusNotify,
                    cancelAlarm: this.cancelAlarm,
                    setOnAlarm: this.setBusOnAlarm,
                    setOffAlarm: this.setBusOffAlarm,
                    linkBusNotifyToOnAlarm: this.linkBusNotifyToOnAlarm,
                    // helper
                    getActiveAlarm: this.getActiveAlarm,
                    declareNonStopRun: this.declareNonStopRun,
                    saveWantedNthPrev: this.saveWantedNthPrev,
                    // 무정차 신고
                    recentNonStopDeclare: this.state.recentNonStopDeclare,
                    declareNonStopRunAny: this.declareNonStopRunAny,
                    //
                    saveDevelSetting: this.saveDevelSetting,
                    queryServerAppSetting: this.queryServerAppSetting,
                    //
                }}
            >
                {this.props.children}
                <MsgHandler ref={c => { this.msgHandler = c }} />
            </AlarmContext.Provider>
        );
    }
}

export default AlarmContextProvider;

