import React from 'react';
import { Text } from 'react-native';
import Icon_helpRide from '../assets/mainTab/i_bus-noti.svg';
import Icon_helpRide_active from '../assets/mainTab/i_bus-noti_active.svg';
import Icon_helpRideRunning from '../assets/mainTab/i_bus-noti-running.svg';
import Icon_helpRideRunning_active from '../assets/mainTab/i_bus-noti-running_active.svg';
import Icon_home from '../assets/mainTab/i_home.svg'
import Icon_home_active from '../assets/mainTab/i_home_active.svg';
import Icon_station from '../assets/station/i_bstop.svg';
import Icon_station_light from '../assets/station/i_bstop_silver.svg';
import Icon_station_ggblue from '../assets/station/i_bstop_ggblue.svg';
import Icon_bus_low_ggblue from '../assets/svg/i_bus-low-ggblue.svg';

// station
import I_bstop from '../assets/station/i_bstop.svg'; 
import I_bstop_red from '../assets/station/i_bstop.svg'; 

// bus
import I_bus_gen_gn from '../assets/bus/i_bus-gen_gn.svg'; 
import I_bus_gen_yl from '../assets/bus/i_bus-gen_yl.svg';
import I_bus_gen_pp from '../assets/bus/i_bus-gen_pp.svg';
import I_bus_gen_bl from '../assets/bus/i_bus-gen_bl.svg';
import I_bus_gen_gy from '../assets/bus/i_bus-gen_gy.svg';
import I_bus_gen_rd from '../assets/bus/i_bus-gen_rd.svg';
import I_bus_gen_rd_dup from '../assets/bus/i_bus-gen_rd_dup.svg';

import I_bus_dbl_gn from '../assets/bus/i_bus-dbl_gn.svg';
import I_bus_dbl_yl from '../assets/bus/i_bus-dbl_yl.svg';
import I_bus_dbl_pp from '../assets/bus/i_bus-dbl_pp.svg';
import I_bus_dbl_bl from '../assets/bus/i_bus-dbl_bl.svg';
import I_bus_dbl_gy from '../assets/bus/i_bus-dbl_gy.svg';
import I_bus_dbl_rd from '../assets/bus/i_bus-dbl_rd.svg';
import I_bus_dbl_rd_dup from '../assets/bus/i_bus-dbl_rd_dup.svg';

import I_bus_low_gn from '../assets/bus/i_bus-low_gn.svg';
import I_bus_low_yl from '../assets/bus/i_bus-low_yl.svg';
import I_bus_low_pp from '../assets/bus/i_bus-low_pp.svg';
import I_bus_low_bl from '../assets/bus/i_bus-low_bl.svg';
import I_bus_low_gy from '../assets/bus/i_bus-low_gy.svg';
import I_bus_low_rd from '../assets/bus/i_bus-low_rd.svg';
import I_bus_low_rd_dup from '../assets/bus/i_bus-low_rd_dup.svg';

import I_bus_tro_gn from '../assets/bus/i_bus-tro_gn.svg';
import I_bus_tro_yl from '../assets/bus/i_bus-tro_yl.svg';
import I_bus_tro_pp from '../assets/bus/i_bus-tro_pp.svg';
import I_bus_tro_bl from '../assets/bus/i_bus-tro_bl.svg';
import I_bus_tro_gy from '../assets/bus/i_bus-tro_gy.svg';
import I_bus_tro_rd from '../assets/bus/i_bus-tro_rd.svg';
import I_bus_tro_rd_dup from '../assets/bus/i_bus-tro_rd.svg';

import I_bus_jeonse_gn from '../assets/bus/i_bus-jeonse_gn.svg';
import I_bus_jeonse_yl from '../assets/bus/i_bus-jeonse_yl.svg';
import I_bus_jeonse_pp from '../assets/bus/i_bus-jeonse_pp.svg';
import I_bus_jeonse_bl from '../assets/bus/i_bus-jeonse_bl.svg';
import I_bus_jeonse_gy from '../assets/bus/i_bus-jeonse_gy.svg';
import I_bus_jeonse_rd from '../assets/bus/i_bus-jeonse_rd.svg';
import I_bus_jeonse_rd_dup from '../assets/bus/i_bus-jeonse_rd.svg';

// import I_bus_dbl_bl_tagless from '../assets/bus/i_bus-dbl_bl_tagless.svg';
// import I_bus_dbl_gn_tagless from '../assets/bus/i_bus-dbl_gn_tagless.svg';
// import I_bus_dbl_gy_tagless from '../assets/bus/i_bus-dbl_gy_tagless.svg';
// import I_bus_dbl_pp_tagless from '../assets/bus/i_bus-dbl_pp_tagless.svg';
// import I_bus_dbl_rd_dup_tagless from '../assets/bus/i_bus-dbl_rd_dup_tagless.svg';
// import I_bus_dbl_rd_tagless from '../assets/bus/i_bus-dbl_rd_tagless.svg';
// import I_bus_dbl_tagless from '../assets/bus/i_bus-dbl_tagless.svg';
// import I_bus_dbl_yl_tagless from '../assets/bus/i_bus-dbl_yl_tagless.svg';

import I_bus_gen_gn_tagless from '../assets/bus/i_bus-gen_gn_tagless.svg'; 
import I_bus_gen_yl_tagless from '../assets/bus/i_bus-gen_yl_tagless.svg';
import I_bus_gen_pp_tagless from '../assets/bus/i_bus-gen_pp_tagless.svg';
import I_bus_gen_bl_tagless from '../assets/bus/i_bus-gen_bl_tagless.svg';
import I_bus_gen_gy_tagless from '../assets/bus/i_bus-gen_gy_tagless.svg';
import I_bus_gen_rd_tagless from '../assets/bus/i_bus-gen_rd_tagless.svg';
import I_bus_gen_rd_dup_tagless from '../assets/bus/i_bus-gen_rd_dup_tagless.svg';

import I_bus_dbl_gn_tagless from '../assets/bus/i_bus-dbl_gn_tagless.svg';
import I_bus_dbl_yl_tagless from '../assets/bus/i_bus-dbl_yl_tagless.svg';
import I_bus_dbl_pp_tagless from '../assets/bus/i_bus-dbl_pp_tagless.svg';
import I_bus_dbl_bl_tagless from '../assets/bus/i_bus-dbl_bl_tagless.svg';
import I_bus_dbl_gy_tagless from '../assets/bus/i_bus-dbl_gy_tagless.svg';
import I_bus_dbl_rd_tagless from '../assets/bus/i_bus-dbl_rd_tagless.svg';
import I_bus_dbl_rd_dup_tagless from '../assets/bus/i_bus-dbl_rd_dup_tagless.svg';

import I_bus_low_gn_tagless from '../assets/bus/i_bus-low_gn_tagless.svg';
import I_bus_low_yl_tagless from '../assets/bus/i_bus-low_yl_tagless.svg';
import I_bus_low_pp_tagless from '../assets/bus/i_bus-low_pp_tagless.svg';
import I_bus_low_bl_tagless from '../assets/bus/i_bus-low_bl_tagless.svg';
import I_bus_low_gy_tagless from '../assets/bus/i_bus-low_gy_tagless.svg';
import I_bus_low_rd_tagless from '../assets/bus/i_bus-low_rd_tagless.svg';
import I_bus_low_rd_dup_tagless from '../assets/bus/i_bus-low_rd_dup_tagless.svg';

import I_bus_tro_gn_tagless from '../assets/bus/i_bus-tro_gn_tagless.svg';
import I_bus_tro_yl_tagless from '../assets/bus/i_bus-tro_yl_tagless.svg';
import I_bus_tro_pp_tagless from '../assets/bus/i_bus-tro_pp_tagless.svg';
import I_bus_tro_bl_tagless from '../assets/bus/i_bus-tro_bl_tagless.svg';
import I_bus_tro_gy_tagless from '../assets/bus/i_bus-tro_gy_tagless.svg';
import I_bus_tro_rd_tagless from '../assets/bus/i_bus-tro_rd_tagless.svg';
import I_bus_tro_rd_dup_tagless from '../assets/bus/i_bus-tro_rd_tagless.svg';

import I_bus_jeonse_gn_tagless from '../assets/bus/i_bus-jeonse_gn_tagless.svg';
import I_bus_jeonse_yl_tagless from '../assets/bus/i_bus-jeonse_yl_tagless.svg';
import I_bus_jeonse_pp_tagless from '../assets/bus/i_bus-jeonse_pp_tagless.svg';
import I_bus_jeonse_bl_tagless from '../assets/bus/i_bus-jeonse_bl_tagless.svg';
import I_bus_jeonse_gy_tagless from '../assets/bus/i_bus-jeonse_gy_tagless.svg';
import I_bus_jeonse_rd_tagless from '../assets/bus/i_bus-jeonse_rd_tagless.svg';
import I_bus_jeonse_rd_dup_tagless from '../assets/bus/i_bus-jeonse_rd_tagless.svg';



import Bug from '../utils/Bug';

// render svg icon
const LoadSvg = ({ name, width, height }) => {
    switch (name) {
        case 'helpRide':
            return <img src={Icon_helpRide}  width={width} height={height} />;
        case 'helpRide_active':
            return <img src={Icon_helpRide_active}  width={width} height={height} />;
        case 'helpRideRunning':
            return <img src={Icon_helpRideRunning}  width={width} height={height} />;
        case 'helpRideRunning_active':
            return <img src={Icon_helpRideRunning_active}  width={width} height={height} />;
        case 'helpRide_ongoing':
            return <img src={Icon_helpRideRunning_active}  width={width} height={height} />;
        // station
        case 'station':
            return <img src={I_bstop}  width={width} height={height} />;
            
        // bus
        case 'I_bus_gen_gn':
            return <img src={I_bus_gen_gn}  width={width} height={height} />;
        case 'I_bus_gen_yl':
            return <img src={I_bus_gen_yl}  width={width} height={height} />;
        case 'I_bus_gen_pp':
            return <img src={I_bus_gen_pp}  width={width} height={height} />;
        case 'I_bus_gen_bl': 
            return <img src={I_bus_gen_bl}  width={width} height={height} />;
        case 'I_bus_gen_gy': 
            return <img src={I_bus_gen_gy}  width={width} height={height} />;
        case 'I_bus_gen_rd': 	
            return <img src={I_bus_gen_rd}  width={width} height={height} />;
        
        
        case 'I_bus_dbl_gn':
            return <img src={I_bus_dbl_gn}  width={width} height={height} />;
        case 'I_bus_dbl_yl':
            return <img src={I_bus_dbl_yl}  width={width} height={height} />;
        case 'I_bus_dbl_pp':
            return <img src={I_bus_dbl_pp}  width={width} height={height} />;
        case 'I_bus_dbl_bl': 
            return <img src={I_bus_dbl_bl}  width={width} height={height} />;
        case 'I_bus_dbl_gy': 
            return <img src={I_bus_dbl_gy}  width={width} height={height} />;
        case 'I_bus_dbl_rd': 	
            return <img src={I_bus_dbl_rd}  width={width} height={height} />;
        
        
        case 'I_bus_low_gn':
            return <img src={I_bus_low_gn}  width={width} height={height} />;
        case 'I_bus_low_yl':
            return <img src={I_bus_low_yl}  width={width} height={height} />;
        case 'I_bus_low_pp':
            return <img src={I_bus_low_pp}  width={width} height={height} />;
        case 'I_bus_low_bl': 
            return <img src={I_bus_low_bl}  width={width} height={height} />;
        case 'I_bus_low_gy': 
            return <img src={I_bus_low_gy}  width={width} height={height} />;
        case 'I_bus_low_rd': 	
            return <img src={I_bus_low_rd}  width={width} height={height} />;
        
        
        case 'I_bus_tro_gn':
            return <img src={I_bus_tro_gn}  width={width} height={height} />;
        case 'I_bus_tro_yl':
            return <img src={I_bus_tro_yl}  width={width} height={height} />;
        case 'I_bus_tro_pp':
            return <img src={I_bus_tro_pp}  width={width} height={height} />;
        case 'I_bus_tro_bl': 
            return <img src={I_bus_tro_bl}  width={width} height={height} />;
        case 'I_bus_tro_gy': 
            return <img src={I_bus_tro_gy}  width={width} height={height} />;
        case 'I_bus_tro_rd': 	
            return <img src={I_bus_tro_rd}  width={width} height={height} />;
        case 'I_bus_tro_rd_dup': 	
            return <img src={I_bus_tro_rd_dup}  width={width} height={height} />;            

        case 'I_bus_jeonse_gn':
            return <img src={I_bus_jeonse_gn}  width={width} height={height} />;
        case 'I_bus_jeonse_yl':
            return <img src={I_bus_jeonse_yl}  width={width} height={height} />;
        case 'I_bus_jeonse_pp':
            return <img src={I_bus_jeonse_pp}  width={width} height={height} />;
        case 'I_bus_jeonse_bl': 
            return <img src={I_bus_jeonse_bl}  width={width} height={height} />;
        case 'I_bus_jeonse_gy': 
            return <img src={I_bus_jeonse_gy}  width={width} height={height} />;
        case 'I_bus_jeonse_rd': 	
            return <img src={I_bus_jeonse_rd}  width={width} height={height} />;
        case 'I_bus_jeonse_rd_dup': 	
            return <img src={I_bus_jeonse_rd_dup}  width={width} height={height} />;
            
        case 'I_bus_gen_gn_tagless':
            return <img src={I_bus_gen_gn_tagless}  width={width} height={height} />;
        case 'I_bus_gen_yl_tagless':
            return <img src={I_bus_gen_yl_tagless}  width={width} height={height} />;
        case 'I_bus_gen_pp_tagless':
            return <img src={I_bus_gen_pp_tagless}  width={width} height={height} />;
        case 'I_bus_gen_bl_tagless': 
            return <img src={I_bus_gen_bl_tagless}  width={width} height={height} />;
        case 'I_bus_gen_gy_tagless': 
            return <img src={I_bus_gen_gy_tagless}  width={width} height={height} />;
        case 'I_bus_gen_rd_tagless': 	
            return <img src={I_bus_gen_rd_tagless}  width={width} height={height} />;
        case 'I_bus_gen_rd_dup_tagless': 	
            return <img src={I_bus_gen_rd_dup_tagless}  width={width} height={height} />;
        
        case 'I_bus_dbl_gn_tagless':
            return <img src={I_bus_dbl_gn_tagless}  width={width} height={height} />;
        case 'I_bus_dbl_yl_tagless':
            return <img src={I_bus_dbl_yl_tagless}  width={width} height={height} />;
        case 'I_bus_dbl_pp_tagless':
            return <img src={I_bus_dbl_pp_tagless}  width={width} height={height} />;
        case 'I_bus_dbl_bl_tagless': 
            return <img src={I_bus_dbl_bl_tagless}  width={width} height={height} />;
        case 'I_bus_dbl_gy_tagless': 
            return <img src={I_bus_dbl_gy_tagless}  width={width} height={height} />;
        case 'I_bus_dbl_rd_tagless':    
            return <img src={I_bus_dbl_rd_tagless}  width={width} height={height} />;
        case 'I_bus_dbl_rd_dup_tagless':    
            return <img src={I_bus_dbl_rd_dup_tagless}  width={width} height={height} />;
        
        
        case 'I_bus_low_gn_tagless':
            return <img src={I_bus_low_gn_tagless}  width={width} height={height} />;
        case 'I_bus_low_yl_tagless':
            return <img src={I_bus_low_yl_tagless}  width={width} height={height} />;
        case 'I_bus_low_pp_tagless':
            return <img src={I_bus_low_pp_tagless}  width={width} height={height} />;
        case 'I_bus_low_bl_tagless': 
            return <img src={I_bus_low_bl_tagless}  width={width} height={height} />;
        case 'I_bus_low_gy_tagless': 
            return <img src={I_bus_low_gy_tagless}  width={width} height={height} />;
        case 'I_bus_low_rd_tagless':    
            return <img src={I_bus_low_rd_tagless}  width={width} height={height} />;
        case 'I_bus_low_rd_dup_tagless':    
            return <img src={I_bus_low_rd_dup_tagless}  width={width} height={height} />;
        
        
        case 'I_bus_tro_gn_tagless':
            return <img src={I_bus_tro_gn_tagless}  width={width} height={height} />;
        case 'I_bus_tro_yl_tagless':
            return <img src={I_bus_tro_yl_tagless}  width={width} height={height} />;
        case 'I_bus_tro_pp_tagless':
            return <img src={I_bus_tro_pp_tagless}  width={width} height={height} />;
        case 'I_bus_tro_bl_tagless': 
            return <img src={I_bus_tro_bl_tagless}  width={width} height={height} />;
        case 'I_bus_tro_gy_tagless': 
            return <img src={I_bus_tro_gy_tagless}  width={width} height={height} />;
        case 'I_bus_tro_rd_tagless':    
            return <img src={I_bus_tro_rd_tagless}  width={width} height={height} />;
        case 'I_bus_tro_rd_dup_tagless':    
            return <img src={I_bus_tro_rd_dup_tagless}  width={width} height={height} />;            

        case 'I_bus_jeonse_gn_tagless':
            return <img src={I_bus_jeonse_gn_tagless}  width={width} height={height} />;
        case 'I_bus_jeonse_yl_tagless':
            return <img src={I_bus_jeonse_yl_tagless}  width={width} height={height} />;
        case 'I_bus_jeonse_pp_tagless':
            return <img src={I_bus_jeonse_pp_tagless}  width={width} height={height} />;
        case 'I_bus_jeonse_bl_tagless': 
            return <img src={I_bus_jeonse_bl_tagless}  width={width} height={height} />;
        case 'I_bus_jeonse_gy_tagless': 
            return <img src={I_bus_jeonse_gy_tagless}  width={width} height={height} />;
        case 'I_bus_jeonse_rd_tagless':     
            return <img src={I_bus_jeonse_rd_tagless}  width={width} height={height} />;
        case 'I_bus_jeonse_rd_dup_tagless':     
            return <img src={I_bus_jeonse_rd_dup_tagless}  width={width} height={height} />;   
        
            
    }
    Bug('RenderSvg()', `Svg not defined. ${name}`, 9);
    return <Text style={{ color: '#F00' }}>No Icon</Text>;
}

export default LoadSvg;