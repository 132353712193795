import React, { Component, useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

import { Colors, Layouts } from '../../../defines/Theme';
import FIcon from '../../../defines/FIcon';
import {Fonts} from '../../../defines/Fonts';
import * as GBus from '../../../defines/GBus';
import { GetText, GetTextSido } from '../../../defines/Texts';
import * as JsUtil from '../../../utils/JsUtil';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import RouteTypeBox from '../../components/parts/RouteTypeBox';


const RenderDetailLine = ({ text }) => {
    return (
        <View style={{ paddingVertical: 0}}>
            <Text numberOfLines={1} style={TextStyle('rowDetail', 'smallest', {
                lineHeight: 24,
                padding: 0, 
                paddingLeft: 0, 
                marginHorizontal: 0, 
                marginVertical: 2, 
                width: "100%", 
                color: Colors.fontCommentColor,
                textAlign: "left"
            })}>
                {text}
            </Text>
        </View>
    )
}

export default function RouteRow({ item, navigation }) { 
    if (!item.routeTypeCd) {
        console.log(">>>>>>>>>>>>>> 노선항목에 routeTypeCd 없음.")
        console.log(item);
        return (<>
        </>) 
    }
    return (
        <View style={ SectionStyle('columnBgBottomBorder') }> 
            {/* 행 */}
            <TouchableOpacity style={ SectionStyle('rowForRow') }
                onPress={() => {
                    navigation.navigate('routeInfo', { 
                        data: item 
                    })
                }}
            >
                {/* 노선유형별 아이콘 */}
                <View style={ SectionStyle('rowLeftIconArea', {marginLeft: 7, marginRight: 3})}>
                    <FIcon type="fas" name="bus" size={26} color={GBus.GetRouteTypeColor(JsUtil.GText(item, "routeTypeCd"))} 
                        style={{ marginBottom: 5}} />
                    <RouteTypeBox routeType={JsUtil.GText(item, "routeTypeCd")} showShortLabel={true}/>
                </View>
                
                <View style={ SectionStyle('rowFillStretchAround', {marginLeft: 10}) }>
                    <View style={ SectionStyle('rowForRow', { 
                        alignItems: 'center', 
                        marginRight: 10, 
                       })}>
                        {/* 노선유형, 노선번호, 운행지역 */}
                        <View style={{
                            flex: 1,
                            flexDirection: 'row',
                            backgroundColor: 'transparent', // Colors.appBackground,
                        }}>                           
                            <Text numberOfLines={1} style={{ 
                                color: Colors.fontColor,
                                fontSize: Fonts.huge.fontSize,
                                fontFamily: Fonts.huge.fontFamily,
                                marginVertical: 5, 
                                marginRight: 10, 
                                paddingLeft: 0,
                                color: GBus.GetRouteTypeColor(JsUtil.GText(item, "routeTypeCd")) 
                            }}>
                                {JsUtil.GText(item, "routeName")}
                            </Text>
                        </View>
                    </View>
                    {/* 기종점 */}
                    <RenderDetailLine text={JsUtil.GText(item, "stStaNm") + " ~ " + JsUtil.GText(item, "edStaNm")} />
                    {/* 첫막차 */}
                    <RenderDetailLine text={
                        `${GetText("pageWord","ROUTE_ROW_FIRST_BUS")} ${JsUtil.GText(item, "upFirstTime", '-')}, ${GetText("pageWord","ROUTE_ROW_LAST_BUS")} ${JsUtil.GText(item, "upLastTime", '-')}, ${GetText("pageWord","ALLOCATION_TIME")} ${GetText("ARRIVAL","TIME").replace("{n}",JsUtil.GText(item, "npeekAlloc", '-'))}`
                    } />
                    {/* 운행지역 */}
                    <RenderDetailLine text={GetTextSido(JsUtil.GText(item, "regionName"))} />
                </View>
            </TouchableOpacity>
        </View>        
    )
}
