import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native';
// config, lib
import { Colors, Layouts } from '../../defines/Theme';
import * as GBus from '../../defines/GBus';
import {GetText, GetTextSido} from '../../defines/Texts'
import SectionStyle from '../../styles/SectionStyle';
import CommonStyle from '../../styles/CommonStyle';
import TextStyle from '../../styles/TextStyle';

// shared 
import RouteTypeBox from './parts/RouteTypeBox';

const TouchableRouteHeader = ({ onPress, children }) => {
    return (
        <TouchableOpacity style={SectionStyle("routeHeaderBg")}
            onPress={() => {onPress?.()}}>
            {children}
        </TouchableOpacity>
    )
}

const UnTouchableRouteHeader = ({ children }) => {
    return (
        <View style={SectionStyle("routeHeaderBg")} >
            {children}
        </View>
    )
}

const RouteHeaderInside = ({ route, routeArrival }) => {
    // console.log(" ++++++++++++++++++++ ")
    // console.log(route);

    return (<>
        <View style={SectionStyle("routeHeaderDetailsBg", {
            flex: 1, flexDirection: "row", justifyContent: "flex-start", marginLeft: 10 

            })}>        
            <RouteTypeBox routeType={route ? route.routeTypeCd._text : ""} />
        </View>
        <View style={SectionStyle("routeHeaderRouteNoBg", {flex: 1})}>
            <Text numberOfLines={2} style={TextStyle("routeHeaderRouteNoText", 
                   route && route.routeName && route.routeName._text.length < 7 ? "huge" : "large",
                {                    
                    color: GBus.GetRouteTypeColor(route ? route.routeTypeCd._text : "" )
                })}>
                {route && route.routeName ? route.routeName._text : "..."}
            </Text>
        </View>
        <View style={SectionStyle("stationHeaderDetailsBg", 
            {flex: 1, flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", alignSelf: "stretch", 
             marginBottom: 5,
             })}>
            {/* ~방면 또는 운행지역 */}
            <View style={SectionStyle("rowForRow")}>
            {
                routeArrival ?
                    global.foreignLanguage == 'E' || global.foreignLanguage == 'V' ?
                        <>                            
                            <Text numberOfLines={1} style={TextStyle("routeHeaderDetailText", "smallest", {textAlign: "left"})}> {GetText("pageWord","ROUTE_DEST_NAME").replace("{routeDestName}","")}</Text>
                            <Text numberOfLines={1} style={TextStyle("routeHeaderDetailText", "smallest", {textAlign: "left", maxWidth: 100})}>
                                {routeArrival.routeDestName ? GetTextSido(routeArrival.routeDestName._text) : "..."}</Text>
                        </>
                      :
                        <>
                            <Text numberOfLines={1} style={TextStyle("routeHeaderDetailText", "smallest", {textAlign: "left", maxWidth: 100})}>
                                {routeArrival.routeDestName ? GetTextSido(routeArrival.routeDestName._text) : "..."}</Text>
                            <Text numberOfLines={1} style={TextStyle("routeHeaderDetailText", "smallest", {textAlign: "left"})}> {GetText("pageWord","ROUTE_DEST_NAME").replace("{routeDestName}","")}</Text>
                        </>
                :
                <>
                    <Text numberOfLines={1} style={TextStyle("routeHeaderDetailText", "smallest", {textAlign: "left", maxWidth: 85})}>
                            {route && route.regionName ? GetTextSido(route.regionName._text) : "..."}
                    </Text>
                </>
            }
            </View>
        </View> 
    </>) 
}

export default function RouteHeader({ station, route, routeArrival, onPress }) {
    return (
        onPress
        ? <TouchableRouteHeader
            onPress={onPress}>
            <RouteHeaderInside route={route} routeArrival={routeArrival} />
          </TouchableRouteHeader>
        : <UnTouchableRouteHeader>
            <RouteHeaderInside route={route}  routeArrival={routeArrival}/>
          </UnTouchableRouteHeader>
    )
}

