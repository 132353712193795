import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// import { WebView } from 'react-native-webview';
import WebView from 'react-native-web-webview';

import AlarmContext from '../../context/AlarmContext';
import { Colors, Layouts } from '../../defines/Theme';
import * as GBus from '../../defines/GBus';
import FIcon from '../../defines/FIcon';
import GetLocation from '../../gps/GetLocation';
import AppSetting from '../../defines/AppSetting';

import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import MapStyle from '../../styles/MapStyle';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

import LoadImage from '../../defines/Images';
import LoadSvg from '../../defines/Svgs';
import RouteHeader from '../components/RouteHeader';

//import Html from '../Tmap/TmapRealTimeRoute';
import { GetText, GetTextSido } from '../../defines/Texts';

let UserScriptApi = require('../../utils/UserScriptApi');

export function RenderSelectedStation({ selectedStation, direction, navigation }) {

    let stationDesc = "";
    if (selectedStation) {
        stationDesc = GBus.GetStationMobileNo(selectedStation, " | ") +
                    GBus.GetStationCenterName(selectedStation, " | ") +
                    selectedStation.regionName
    }
    return (
        <View style={MapStyle("selStationBoxBg")}>
            {
                selectedStation
                    ? <TouchableOpacity style={MapStyle("selStationBox")}
                        onPress={() => {
                            navigation.navigate('stationInfo', {
                                data: {
                                    stationId: { _text: selectedStation.stationId },
                                    stationName: { _text: selectedStation.stationName },
                                    x: { _text: selectedStation.x },
                                    y: { _text: selectedStation.y }
                                }
                            })
                        }}
                    >
                        <View style={SectionStyle("rowLeftIconArea")}>
                            <LoadImage name={direction == "start" ? "map_station_blue" : "map_station_red"} width={35} height={35} />
                            {/* <LoadImage name="map_station_red" width={35} height={35} /> */}
                        </View>
                        <View style={SectionStyle("rowFillStretch")}>
                            <View>
                                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "largest", {
                                    color: Colors.fontColor,
                                    alignSelf: "stretch",
                                    margin: 0,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    textAlign: "left"
                                })}>
                                    {selectedStation.stationName}
                                </Text>
                            </View>
                            <View>
                                <Text numberOfLines={1} style={TextStyle("rowDetail", "large", {
                                    color: Colors.fontColor,
                                    alignSelf: "stretch",
                                    marginHorizontal: 0,
                                    paddingTop: 0,
                                    paddingBottom: 2,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    textAlign: "left"
                                })}>
                                    {stationDesc}
                                </Text>
                            </View>
                        </View>
                        <View style={SectionStyle("rowRightButtonsArea")}>
                            <FIcon type="fad" name="chevron-right" size={20} color={Colors.ggBlue} />
                        </View>
                    </TouchableOpacity>
                    : null
            }
        </View>
    )
}

function MyLocation({ selectedStation, onPress }) {
    return (
        <>
            {
                selectedStation
                    ? <TouchableOpacity style={MapStyle("myLocation")}
                        onPress={onPress}>
                        <FIcon type="fad" name="location" size={55} color={Colors.ggBlue} />
                    </TouchableOpacity>
                    : <TouchableOpacity style={MapStyle("myLocation", { bottom: Layouts.bottomBarHeight + 60 })}
                        onPress={onPress}>
                        <FIcon type="fad" name="location" size={55} color={Colors.ggBlue} />
                    </TouchableOpacity>
            }
        </>
    );
}

function AddZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("addZoom") }
            onPress={ onPress } accessibilityLabel="줌 확대" accessibilityRole="button">
            <FIcon type="fad" name="plus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}
function RemoveZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("removeZoom") }
            onPress={ onPress } accessibilityLabel="줌 축소" accessibilityRole="button">
            <FIcon type="fad" name="minus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}
export default class RouteMap extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            // visibleLoader: true,
            position: 'unknown',
            selectedStation: null,
            isVisible: false,
            direction: null,
        }
    }

    componentDidMount() {
        UserScriptApi.apiConnect('routeMap')
        GetLocation(this.context.msgHandler, (p) => {
            this.setState({ position: p })
        })
    }

    render() {
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    title={GetText("pageTitle","ROUTE_MAP")}
                />

                <RouteHeader route={this.props.route.params.data} />
                <View style={{ flex: 1, alignSelf: "stretch" }}>
                    <WebView
                        ref={(ref) => this.webRef = ref}
                        originWhitelist={['*']}
                        style={MapStyle("mapView")}
                        source={{ uri: AppSetting.MOBILE_WEB.ROOT + "assets/Tmap/TmapRealTimeRoute.html", method: 'get' }}
                        // source={{ html: Html.content }}
                        onLoadStart={event => {
                            showPageLoader(true)
                        }}
                        onLoadEnd={event => {
                            showPageLoader(false)
                        }}
                        onMessage={(event) => {
                            if (!event.nativeEvent.data || typeof event.nativeEvent.data != "string" || !event.nativeEvent.data.startsWith("{")) {
                                //console.log("===> Unsupported webview event", event);
                                return;
                            }
                            console.log("===> webview event", event);
                            let data = JSON.parse(event.nativeEvent.data.toString())
                            if (data.isError) {
                                this.context.msgHandler?.toast(data.data)
                            }
                            if (data.event == "marker.click") {
                                this.setState({ selectedStation: data.data, direction: data.direction })
                            }
                            else {
                                // console.log("------------sssss ")
                                // this.setState({ selectedStation: data.data, direction: data.direction })
                            }
                        }}
                        javaScriptEnabled={true}
                        domStorageEnabled={true}
                        startInLoadingState={true}
                        scalesPageToFit={true}
                        injectedJavaScript={
                            `getRouteData(${this.props.route.params.data.routeId._text})`
                        }
                    />
                </View>
                <MyLocation
                    selectedStation={this.state.selectedStation}
                    onPress={() => {

                        let msg = {
                            _sender: "GBUS_RN",
                            cmd: "setMapCenter",
                            params: {
                                x: this.state.position?.coords ? this.state.position.coords.longitude : AppSetting.GPS.MAP_DEF_X,
                                y: this.state.position?.coords ? this.state.position.coords.latitude : AppSetting.GPS.MAP_DEF_Y,
                            }
                        }
                        // alert(`MyLocation: ${msg.params.x}, ${msg.params.y}`)
                        this.webRef?.postMessage(msg, "*")

                        // if (!this.state.position) {
                        //     this.webRef.injectJavaScript(`setMapCenter(${AppSetting.GPS.MAP_DEF_X}, ${AppSetting.GPS.MAP_DEF_Y})`)
                        //     this.context.msgHandler?.toast(GetText("gps", "PLZ_ALLOW_PERMISSION"));
                        // }
                        // else {
                        //     this.webRef.injectJavaScript(`fitBounds(${this.state.position.coords.longitude}, ${this.state.position.coords.latitude})`);
                        // }
                    }}
                />
                
                <RenderSelectedStation
                    direction={this.state.direction}
                    selectedStation={this.state.selectedStation}
                    navigation={this.props.navigation}
                />
                <BottomRightTabs navigation={this.props.navigation} />
            </SafeAreaView>
        )
    }
}
