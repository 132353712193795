// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Bug from '../utils/Bug';

const styles = {
    _def: {
        backgroundColor: '#f00',
    },

    dialogModal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: "#f00",
        //backgroundColor: 'transparent',
    },
    dialogBg: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor: 'rgba(0,0,0,0.50)',
        //backgroundColor: '#00f',
        //backgroundColor: 'transparent',
    },
    dialogContents: {
        width: Layouts.windowWidth * 0.8,
        height: Layouts.rowHeight * 3,
        alignItems: "stretch",
        borderRadius: 20,
        overflow: "hidden",
        backgroundColor: Colors.appBackgroundColor,
        //backgroundColor: Colors.ggBlue,
    },
    dialogHeader: {
        height: Layouts.rowHeight, // * 0.8,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: 'white',
        //backgroundColor: '#f00',
        backgroundColor: Colors.ggBlue,
    },
 
    dialogBody: {
        flex: 1,
        justifyContent: "center",
        padding: 15,
//        borderBottomWidth: 0.7,
//        borderColor: Colors.ggBlue,
        backgroundColor: Colors.appBackgroundColor,
        // backgroundColor: '#f0f',
    },
    dialogRemark: {
        alignItems: "stretch",
        height: Layouts.rowHeight,
        justifyContent: "center",
        padding: 15,
//        borderBottomWidth: 0.7,
//        borderColor: Colors.ggBlue, 
        // borderLightColor,
        backgroundColor: Colors.silver,
        // backgroundColor: '#0ff',
    },
    dialogNoMoreRow: {
        flexDirection: "row",
        justifyContent: "center", 
        alignItems: "center",
        height: Layouts.rowHeight,
        height: Layouts.rowHeight * 0.8,
        paddingVertical: 5, 
        backgroundColor: Colors.ggBlueTrans1,
    },    
    dialogFooter: {
        flexDirection: "row",
        alignItems: "stretch",
        height: Layouts.rowHeight,
        backgroundColor: Colors.appBackgroundColor,
        // backgroundColor: '#0ff',
    },
    dialogButton: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: Colors.ggBlue,
        borderTopWidth: 1,
        // backgroundColor: '#c0c',
    },
    dialogButtonL: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: Colors.ggBlue,
        borderTopWidth: 1,
        borderRightWidth: 1,
    },
    dialogButtonR: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        borderColor: Colors.ggBlue,
        borderTopWidth: 1,
        borderLeftWidth: 1,
    },


    rideSupportDlgBg: {
        flex: 1,
        zIndex: 1,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        //      borderWidth: 1,
        //backgroundColor: '#0ff',
        alignItems: 'stretch',
        // borderColor: Colors.ggBlue,
        //justifyContent: 'center'
    },
    rideSupportDlgDragHeader: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        // borderTopWidth: 1,
        // borderLeftWidth: 1,
        // borderRightWidth: 1,
        // borderColor: Colors.ggBlue,
        // justifyContent: "center",
        alignItems: 'stretch',
        padding: 0,
        height: Layouts.rowHeight,
        backgroundColor: Colors.listGroupColor,
        backgroundColor: Colors.gsYellow,
    },
    rideSupportDlgDraggerBg: {
        //flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 7,
    },

    rideSupportDlgDragger: {
        height: 7,
        width: 50,
        backgroundColor: Colors.whiteLight,
        // backgroundColor: Colors.ggBlue,
        borderRadius: 5
    },




    //
    bsBg: {
        flex: 1,
        zIndex: 1,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderWidth: 1,
        //backgroundColor: '#0ff',
        alignItems: 'stretch',
        borderColor: Colors.ggBlue,
        //justifyContent: 'center'
    },
    bsDragHeader: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        justifyContent: "center",
        alignItems: 'stretch',
        padding: 0,
        height: Layouts.rowHeight,
        backgroundColor: Colors.listGroupColor,
    },

    // bottom sheet
    bsContentBg: {
        flex: 1,
        borderColor: Colors.ggBlue,
        //backgroundColor: Colors.appBackgroundColor,
        // backgroundColor: Colors.ggBlue9, 
        //marginHorizontal: 10,
    },
    bsTitleRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 7,
        //backgroundColor: "#f00"
    },
    bsTitleIconBg: {
        marginRight: 10,
    },
    bsTitle: {
        color: Colors.fontLightColor,
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
    },
    bsMsgRow: {
        flex: 1,
        // justifyContent: "flex-start",
        alignItems: "stretch",
        padding: 10,
        paddingBottom: 15
        // backgroundColor: "#0ff"
    },

    // dialog
    buttonRow: {
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    button: {
        paddingHorizontal: 20,
        paddingVertical: 8,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#3385ff",
        marginLeft: 10,

    },
};

const DlgStyle = (name, customStyles = null) => {
    if (typeof customStyles === "string") {
        console.log("DlgStyle.GetStyle() changed. font sized style should go to TextStyle()")
    }
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('DlgStyle()', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default DlgStyle;