import React, { useState, useEffect } from 'react';
import { BottomRightTabs } from './BottomTab';

export default function RouteInfoBottomTab({ navigation, onPressBack, onPressMapBtn, onPressInfoBtn}) {
    return (
        <BottomRightTabs 
            // onBack={onPressBack}
            tabs={[
                { 
                    name: 'showMap', 
                    onPress: () => { onPressMapBtn?.(); },
                    title: 'ROUTE_MAP',
                },                    
                { 
                    name: 'info', 
                    onPress: () => { onPressInfoBtn?.(); },
                    title: 'ROUTE_INFO',                    
                },
            ]}
            navigation={navigation}
        /> 
    )
}