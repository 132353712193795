import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Dash from 'react-native-dash';

import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';

import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import SectionStyle from '../../../styles/SectionStyle';
import FrStyle from '../../../styles/FrStyle';


export default function FrWalkDetailRow({ type, beforeColor, item, beforeItem }) {    
    return (
        <View style={FrStyle("frdWalkRowBg")} accessible={true}>
            {/* <View style={FrStyle("frdWalkLine", { backgroundColor: beforeColor })} /> */}
            <Dash style={FrStyle("frdWalkLine", { top: Layouts.frdWalkRowHeight / 2, 
                //width: 4, 
                // backgroundColor: "#00f" 
                })} dashColor={Colors.gray} dashThickness={3}/>
            {
                type == 'start' 
                ? <View style={FrStyle("frdWalkStartIconBg")}>
                      <FIcon type="fas" name='map-marker-alt' size={30} color={Colors.fontCommentColor} />
                  </View> 
                : <View style={FrStyle("frdWalkEndIconBg", { borderColor: beforeColor })}>
                      <Text style={TextStyle("rowDetail", "smallest")}>하차</Text>
                  </View>
            }
            <View style={SectionStyle("rowForCol")}>
                <Text style={TextStyle("rowTitleDesc", "medium", { marginVertical: 5})}>
                    {type == 'start' ? 
                                '출발 | 출발지' : 
                                '하차 | ' + beforeItem.endStationName + (beforeItem.transitMode==='bus' ? 
                                                                            ' 정류소' : 
                                                                            (beforeItem.endStationName.indexOf('역') === beforeItem.endStationName.length-1 ? 
                                                                                '' : 
                                                                                '역'))}
                </Text>
                <View style={SectionStyle("rowForRow", { alignItems: "center" })}>
                    <FIcon type="fad" name='walking' size={20} color={Colors.fontColor} />
                    <Text style={TextStyle("rowDetail", "smallest", { marginLeft: 5 })}>
                        도보 {TMap.getTimeHMS(item.duration)} | {item.distance}m
                    </Text>
                </View>
            </View>
        </View>
    )
}
