import React, { Component } from "react";
import PropTypes from "prop-types";

import { StyleSheet, View, Easing, Animated, Text } from "react-native";

class Marquee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0,
            childrenChangeCounter: -1,
            animatedTransformY: new Animated.Value(0),
            animatedTransformX: new Animated.Value(0),
            };
        // this.animatedTransformY = new Animated.Value(0);
        // this.animatedTransformX = new Animated.Value(0);
        // // this.animatedTransformX.addListener(({value}) => 
        // {
        //     console.log(`Ani.X: ${value}, width: ${this.state.width}`)
        // }
        // );
        this.aniStartTimer = null;
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.childrenChangeCounter !== this.props.childrenChangeCounter) {
            console.log(`[Marquee] restart by change: ${this.props.childrenChangeCounter}`)
            // console.log(`[Marquee] restart by new`, this.props.children)
            if (this.aniStartTimer) {
                clearTimeout(this.aniStartTimer);
                console.log('Start animation timer cleared')
            }
            this.state.animatedTransformY.stopAnimation();
            this.state.animatedTransformX.setValue(0);
            this.aniStartTimer = setTimeout(() => {
                console.log('Start animation')
                this.runAnimation()
              }, 1000);            
            // this.stopAnimation();
            // this.runAnimation()
        }
        // if (this.props.direction.toUpperCase() == "Y") {
            
        //     if (prevState.height !== this.state.height) {
        //         this.runAnimation();
        //     }
        // }
        // else {
        //     if (prevState.width !== this.state.width) {
        //         console.log("[Marquee] restart ani...")
        //         this.runAnimation();
        //     }
        // }
    }
    stopAnimation() {

        if (this.props.direction.toUpperCase() == "Y") {
            // Animated.timing(this.animatedTransformY).stop();
            // this.animatedTransformY.setValue(0);
        }
        else {
            // Animated.timing(this.animatedTransformX).stop();
            // this.animatedTransformX.setValue(0);
        }
    }    

    runAnimation() {

        if (this.props.direction.toUpperCase() == "Y") {
            // this.animatedTransformY.setValue(0);
            // Animated.timing(this.animatedTransformY, {
            //     useNativeDriver: true,
            //     duration: this.props.duration,
            //     toValue: -this.state.height / 2,
            //     easing: Easing.linear
            // }).start(() => this.runAnimation());
        }
        else {
            this.state.animatedTransformX.setValue(0);
            Animated.timing(this.state.animatedTransformX, {
                useNativeDriver: true,
                duration: this.props.duration,
                // toValue: -this.state.width / 2,
                toValue: -1 * this.state.width,
                easing: Easing.linear
            }).start((o) => {
                if (o.finished) {
                    this.runAnimation()
                }                
            });
        }
    }

    wrapperOnLayout(e) {
        this.stopAnimation();
        this.setState({
            height: Math.round(e.nativeEvent.layout.height),
            width: Math.round(e.nativeEvent.layout.width),
        }, () => {
            console.log(`[marquee] h/w=${this.state.height}/${this.state.width}`)
        });
        
    }

    render() {
        const { children, data } = this.props;

        let contentCom = [];

        if (children) {
            contentCom = children;
        } else {
            contentCom = data.map((item, index) => (
                <View key={`marqueeList${index}`}>
                    <Text>{item}</Text>
                </View>
            ));
        }

        const cloneChildren = React.Children.map(contentCom, contentCom =>
            React.cloneElement(contentCom)
        );
        const reactElementArr = [contentCom, cloneChildren];
        return (
            this.props.direction.toUpperCase() == "Y"
                ? <View style={[styles.container, this.props.style, { height: this.props.height }]}>
                    <Animated.View
                        style={{
                            transform: [{ translateY: this.animatedTransformY }]
                        }}
                    >
                        <View onLayout={event => this.wrapperOnLayout(event)}>
                            {reactElementArr.map(reactEle => reactEle)}
                        </View>
                    </Animated.View>
                </View>
                : <View style={[styles.container, this.props.style, 
                    // { width: this.props.width }
                ]}>
                    <Animated.View
                        style={{
                            // backgroundColor: "#04f", 
                            transform: [{ translateX: this.state.animatedTransformX }]
                        }}
                    >
                        <View onLayout={event => this.wrapperOnLayout(event)}>
                            {reactElementArr.map(reactEle => reactEle)}
                        </View>
                    </Animated.View>
                </View>

        );
    }
}

const styles = StyleSheet.create({
    container: {
        overflow: "hidden"
    }
});

Marquee.propTypes = {
    childrenChangeCounter: PropTypes.number,
    direction: PropTypes.string,
    duration: PropTypes.number,
    height: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

Marquee.defaultProps = {
    childrenChangeCounter: 0,
    direction: "X",
    duration: 10000,
    height: 100,
    width: 100,
};

export default Marquee;
