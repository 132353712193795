import React from 'react';
import { BottomRightTabs } from './BottomTab';

export default function FrStartEndBottom({ navigation, setPoi, setCurrent }) {
    return (
        <BottomRightTabs 
            tabs={[
                { 
                    name: 'myLocation', 
                    onPress: () => { setCurrent?.(); },
                    title: '현재위치',
                    label: '현재위치 ',
                },                    
                { 
                    name: 'showMarkerMap', 
                    onPress: () => { setPoi?.(); },
                    title: '지도에서...',
                    label: '지도에서 선택',
                },
            ]}
            navigation={navigation}
        /> 
    )
}

