import React from 'react';
import { BottomRightTabs } from './BottomTab';

export default function FrSelMapBottomTab({ navigation, title, setStartEnd }) {
    return (
        <BottomRightTabs 
            tabs={[
                { 
                    name: 'setStartEndPos', 
                    onPress: () => {
                        if(setStartEnd) {
                            setStartEnd()
                        }
                    },
                    title: title + '로...'
                }
            ]}
            navigation={navigation}
        />        
    )
}
