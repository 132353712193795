import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import Bug from '../utils/Bug';

const FIcon = ({ type, name, size, color, style }) => {
    return <FontAwesomeIcon icon={[type, name]} size={size} color={color} style={style} /> // size={size} transform: scale(1.5,1);
}

export const FIconStack = ({ type1, name1, size1, color1, style1, 
                             type2, name2, size2, color2, style2 }) => {
    return (
        <>
            <FontAwesomeIcon icon={[type1, name1]} size={size1} color={color1} style={style1} />
            <FontAwesomeIcon icon={[type2, name2]} size={size2} color={color2} style={style2} />
        </>
    )
}

export const ButtonIcon = ({ type, name, size, color, style, onPress, onLongPress }) => {
    return (
        <TouchableOpacity
            style={style}
            onPress={onPress}
            onLongPress={onLongPress}
        >
            <FIcon {... {type, name, size, color }} />
        </TouchableOpacity>
    );
}

export default FIcon;