// javascript
import React, { Component, useContext } from 'react';

const AlarmContext = React.createContext(
    {
        busNotify: null,
        busOn: null,
        busOff: null,
        refreshByUser: false,
    }
);

export default AlarmContext;