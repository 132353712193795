import React, { useContext, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Alert, Linking } from 'react-native';
import AlarmContext from '../../../context/AlarmContext';
import * as GBus from '../../../defines/GBus';
import Texts, {GetText, GetTextSido} from '../../../defines/Texts';
import AppSetting from '../../../defines/AppSetting';
import Colors, {Layouts} from '../../../defines/Theme';
import * as AlarmUtil from '../../../utils/AlarmUtil';
import BottomTabs from './BottomTab';

import {useRoute} from '@react-navigation/native';

export default function MainBottomTab({ selTab, navigation, onHomePress, bottomSheet }) {

    const { getActiveAlarm } = useContext(AlarmContext);
    let activeAlarm = getActiveAlarm();
    let context = useContext(AlarmContext);
    return (

        <BottomTabs 
            selTab={selTab}
            tabs={
                global.foreignLanguage=='K' ?  
                [{ name: 'home', },
                 { name: 'around' },
                 { name: 'findRoad'},
                ]
                :
                [{ name: 'home', },
                 { name: 'around' },
                ]
            }
            navigation={navigation}
        />
    )
}



    // const getActiveOnNotifyAlarm = () => {
    //     let savedBusNotity = AlarmUtil.getValidBusNotify();
    //     if (savedBusNotity) {
    //         return { stationId: savedBusNotity.stationId, routeId: savedBusNotity.routeId,  }
    //     }
    //     let savedOnAlarm = AlarmUtil.getValidOnAlarm();
    //     if (savedOnAlarm) {
    //         return { stationId: savedOnAlarm.stationId, routeId: savedOnAlarm.routeId,  }
    //     }
    //     return {}
    // }    
