import React from 'react';
import { View, Text } from 'react-native';
// config
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import * as JsUtil from '../../../utils/JsUtil';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import SpacerH from '../common/SpacerH';
import { GetText } from '../../../defines/Texts';

//BusPlateBox
const BusArrivalNthBox = ({ bus }) => {
    if (!bus || JsUtil.GInt(bus, "locationNo", -1) < 0) {
        return null;
    }
    return (
        <View style={SectionStyle('arrivalBusPosTextBg', { marginLeft: 0 })}>
            <Text numberOfLines={1} style={TextStyle('arrivalBusPosText', 'medium')}>
                {GetText("ARRIVAL","NPREV").replace("{n}",JsUtil.GInt(bus, "locationNo"))}
            </Text>
        </View>
    )
}

export default BusArrivalNthBox