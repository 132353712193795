import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import {
    StyleSheet, Text, View, TouchableOpacity, TextInput,
    TouchableWithoutFeedback, Keyboard, ScrollView, FlatList, Alert,
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import AlarmContext from '../../context/AlarmContext';

// config, lib
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as ArrayUtil from '../../utils/ArrayUtil';
import * as HistoryUtil from '../../utils/HistoryUtil';
import { Colors, Layouts } from '../../defines/Theme';
import Texts, { GetText } from '../../defines/Texts';
import FIcon from '../../defines/FIcon';
import AppSetting from '../../defines/AppSetting';
import GAPI, { TAPI } from '../../api/RestAPI';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

// shared
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import TabSelectBar from '../components/common/TabSelectBar';
import SearchBottomTab from '../components/bottomTabs/SearchBottomTab';
import ListEmpty from '../components/parts/ListEmpty';
import InputClear from '../components/common/InputClear';

// local
import RouteRow from './include/RouteRow';
import StationRow from './include/StationRow';
import POIRow from './include/POIRow';
import RecentRow from './include/RecentRow';

let UserScriptApi = require('../../utils/UserScriptApi');

export default function SearchMain(props) {
    UserScriptApi.apiConnect('searchMain')

    const context = useContext(AlarmContext)

    const [inputText, setInputText] = useState('');
    const [searchState, setSearchState] = useState({
        page: 1,
        selectedTab: "",
        expandSearch: false
    })
    const [results, setResults] = useState({
        totalRecordCount: 0,
        records: null
    });
    const inputControl = useRef();

    // 화면 오픈시 전달된 param 검사
    useEffect(() => {
        console.log(`[SearchMain] useEffect(props)`)
        console.log(props)
        // 검색어가 전달된 경우, "노선" -> "정류소" 확장 검색 시도
        if (props.route.params && props.route.params.searchText) {
            setInputText(props.route.params.searchText);
            setResults({ records: null, totalRecordCount: 0 });
            setSearchState({ page: 1, selectedTab: "route", expandSearch: true })
        }
        // 전달된 검색어가 없으면 "노선" 탭을 기본으로 활성화
        else {
            console.log(`----- Search #1`);
            setInputText("");
            console.log(`----- Search #2`);
            setSearchState({ page: 1, selectedTab: "route", expandSearch: false })
        }
    }, [props])

    // 사용자가 검색 입력란을 지우면, 검색이력 표시
    useEffect(() => {
        if (inputText.length < 1) {
            setResults({ records: global.historySearchs, totalRecordCount: 0 });
        }
    }, [inputText])

    // 탭변경, 검색 수행 요청
    useEffect(() => {
        if (!searchState.selectedTab) {
            return;
        }
        console.log(`tab changed to ${searchState.selectedTab}. inputText: "${inputText}"`);
        if (inputText.length < 1) {
            setResults({ records: global.historySearchs, totalRecordCount: 0 });
        }
        else {
            runQuery();
        }
    }, [searchState])

    const updateQueryResult = (records, totalRecordCount = 0) => {
        if (searchState.page == 1) {
            if (Array.isArray(records)) {
                setResults({ records: records, totalRecordCount: totalRecordCount });
            }
            else {
                setResults({ records: [records], totalRecordCount: totalRecordCount });
            }
        }
        else {
            if (Array.isArray(records)) {
                setResults({ records: [...results.records, ...records], totalRecordCount: totalRecordCount });
            }
            else {
                setResults({ records: [...results.records, records], totalRecordCount: totalRecordCount });
            }
        }
    }

    const _searchRoute = () => {
        console.log(`[SearchMain] _searchRoute() with ${inputText}`)
        GAPI.doRequest('getRouteList', AppSetting.RECORD_COUNT_PER_QUERY, searchState.page, inputText)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0 && parseInt(res.response.msgBody.routeCount._text) > 0) {
                    updateQueryResult(res.response.msgBody.routeList.busRouteList, res.response.msgBody.routeCount._text);
                    HistoryUtil.InsertSearchHistory(inputText);
                }
                else {
                    if (searchState.expandSearch) {
                        setSearchState({
                            page: 1,
                            selectedTab: "station",
                            expandSearch: false
                        })
                    }
                    else {
                        if (code == 4) {
                            updateQueryResult([], 0);
                            HistoryUtil.InsertSearchHistory(inputText);
                        }
                        else {
                            context.msgHandler?.error("SearchMain", msg, code);
                        }
                    }
                }
            })
            .catch(err => {
                updateQueryResult([], 0);
                console.log("Unknown request error...");
                console.log(err);
                context.msgHandler?.error("SearchMain", GetText("error", "unknown"), err);
            })
    }

    const _searchStation = () => {
        console.log(`[SearchMain] _searchStation() with ${inputText}`)
        GAPI.doRequest('getStationList', AppSetting.RECORD_COUNT_PER_QUERY, searchState.page, inputText)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    updateQueryResult(res.response.msgBody.stationList.busStationList, res.response.msgBody.stationCount._text);
                    HistoryUtil.InsertSearchHistory(inputText);
                }
                else {
                    if (searchState.expandSearch) {
                        setSearchState({
                            page: 1,
                            selectedTab: "route",
                            expandSearch: false
                        })
                    }
                    else {
                        if (code == 4) {
                            updateQueryResult([], 0);
                            HistoryUtil.InsertSearchHistory(inputText);
                        }
                        else {
                            context.msgHandler?.error("SearchMain", msg, code);
                        }
                    }
                }
            })
            .catch(err => {
                updateQueryResult([], 0);
                console.log("Unknown request error...");
                console.log(err);
                context.msgHandler?.error("SearchMain", GetText("error", "unknown"), err);
            })
            .finally(() => {
                // setExpandSearch(false)
            })
    }

    const _searchPoi = () => {
        console.log(`[SearchMain] _searchPoi() with ${inputText}`)
        TAPI.getPoiList(AppSetting.RECORD_COUNT_PER_QUERY, searchState.page, inputText)
            .then((res) => {
                if (!res) {
                    updateQueryResult([]);
                    context.msgHandler?.error("SarchMain", GetText("error", "net_fail"));
                }
                else if (res.hasOwnProperty('error')) {
                    updateQueryResult([]);
                    context.msgHandler?.error("SarchMain", "fail to request TMap.poi()", res.error);
                }
                else if (res.hasOwnProperty('code')) {
                    updateQueryResult([]);
                    context.msgHandler?.error("SarchMain", res.msg, res.code);
                }
                else {
                    updateQueryResult(res.searchPoiInfo.pois.poi);
                    console.log("POI: ");
                    console.log(res.searchPoiInfo.pois.poi);
                    HistoryUtil.InsertSearchHistory(inputText);
                }
            })
            .catch(err => {
                updateQueryResult([]);
                console.log("Unknown request error...");
                console.log(err);
                context.msgHandler?.error("SarchMain", GetText("error", "unknown"), err);
            })
    }

    const runQuery = () => {
        if (!searchState.selectedTab) {
            return;
        }
        console.log(`runQuery with "${inputText}"`)
        if (searchState.selectedTab == 'route') {
            _searchRoute();
        }
        else if (searchState.selectedTab == 'station') {
            _searchStation();
        }
        else {
            _searchPoi();
        }
    }

    const getEmptyText = () => {
        if (!inputText) {
            return "";
        }
        if (inputText && results.records) {
            return results.records.length < 1 ? GetText("search", "no_matched_result") : ""
        }
        return inputText && !results.records ? GetText("general", "LOADING_DATA") : ""
    }

    return (
        <SafeAreaView style={CommonStyle('bg')}>
            <ScreenHeaderEx
                title={GetText("pageTitle","SEARCH_MAIN")}
                left={{ iconType: "fad", iconName: "chevron-left", onPress: () => { props.navigation.goBack() } }}
            // right={{ label: GetText("pageTabLabel", "CANCEL"), onPress: () => { Keyboard.dismiss() } }}
            />
            {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
            <View style={CommonStyle("body")}>
                <View style={SectionStyle("rowForInput")}>
                    <View style={CommonStyle("inputBg")}>
                        <TextInput
                            ref={inputControl}
                            autoFocus={true}
                            style={TextStyle('input')}
                            returnKeyType="search"
                            placeholder={GetText("search", "placeholder", "")}
                            value={inputText}
                            onChangeText={(text) => {
                                setInputText(text.trim());
                            }}
                            onSubmitEditing={() => {
                                if (inputText.length < 1) {
                                    context.msgHandler?.toast(GetText("error", "WRITE_SEARCH_TEXT"))
                                }
                                else {
                                    setResults({ records: null, totalRecordCount: 0 });
                                    setSearchState({
                                        page: 1,
                                        selectedTab: searchState.selectedTab,
                                        expandSearch: searchState.selectedTab === "route" || searchState.selectedTab === "station"
                                    })
                                }
                            }}
                        />
                        <InputClear onPress={() => setInputText('')} color={inputText.length > 0 ? Colors.fontCommentColor : Colors.fontMiscColor} />
                    </View>
                    <View style={SectionStyle('rowRightButtonsArea', {})}>
                        <TouchableOpacity style={SectionStyle('rowButton', {})}
                            onPress={() => {
                                console.log(`Query button press "${inputText}"`);
                                Keyboard.dismiss();
                                if (inputText.length < 1) {
                                    context.msgHandler?.toast(GetText("error", "WRITE_SEARCH_TEXT"))
                                }
                                else {
                                    setResults({ records: null, totalRecordCount: 0 });
                                    setSearchState({
                                        page: 1,
                                        selectedTab: searchState.selectedTab,
                                        expandSearch: searchState.selectedTab === "route" || searchState.selectedTab === "station"
                                    })
                                }
                            }}
                        >
                            <FIcon type="fad" name="search" size={26} color={Colors.ggBlue} />
                        </TouchableOpacity>
                    </View>
                </View>
                <TabSelectBar
//                    tabs={{ route: "노선", station: "정류소", poi: "장소" }} // recent: "최근검색",
                    tabs={{ route: GetText("pageWord","ROUTE_NM"), station: GetText("pageWord","STATION_NM"), poi: GetText("pageTitle","SEARCH_POI_MAP")}} // recent: "최근검색",  
                    activeId={searchState.selectedTab}
                    onPress={(id) => {
                        setResults({ records: null, totalRecordCount: 0 });
                        setSearchState({
                            page: 1,
                            selectedTab: id,
                            expandSearch: false
                        });
                    }}
                    rightComponent={
                        <TouchableOpacity
                            style={{
                                flexDirection: "row",
                                justifyContent: 'flex-end',
                                alignItems: "center",
                                marginBottom: 0,
                                marginRight: 10,
                                padding: 0
                            }}
                            onPress={() => {
                                context.msgHandler?.dialog({
                                    title: GetText("pageWord","DEL"),
                                    message: "검색이력을 삭제하시겠습니까?",
                                    ok: () => {
                                        HistoryUtil.ClearSearchHistory(() => {
                                            setResults({ records: global.historySearchs, totalRecordCount: 0 });
                                        });
                                        context.msgHandler?.toast(GetText("pageComment", "COMPLETE_DEL"))
                                    },
                                    cancel: () => { }
                                })
                            }}
                        >
                            <FIcon type="fas" name="history" size={20} color={Colors.fontCommentColor} />
                            <Text style={TextStyle("tabSelectLabel", "small", { paddingLeft: 5, paddingRight: 10 })}>{GetText("pageWord","DEL")}</Text>
                        </TouchableOpacity>
                    }
                />
                <View style={CommonStyle("listBg", {
                    flex: 1,
                    backgroundColor: Colors.appBackgroundColor
                })}>

                    {/* <View style={{ flex: 1, flexDirection: 'column', backgroundColor: "#f00", padding: 5 }}> */}
                    <FlatList
                        // ref={flatRef}
                        data={results.records}
                        ListHeaderComponent={null}
                        ListEmptyComponent={
                            <ListEmpty text={getEmptyText()} />
                        }
                        renderItem={({ item, index, separators }) => {
                            // recent rows
                            if (item.hasOwnProperty("title") && item.hasOwnProperty("time")) {
                                return <RecentRow item={item} onPress={() => {
                                    setInputText(item.title);
                                    setResults({ records: [], totalRecordCount: 0 });
                                    setSearchState({
                                        page: 1,
                                        selectedTab: searchState.selectedTab,
                                        expandSearch: searchState.selectedTab === "route"
                                    });
                                }} />
                            }
                            else {
                                switch (searchState.selectedTab) {
                                    case 'route':
                                        return <RouteRow item={item} navigation={props.navigation} />
                                    case 'station':
                                        return <StationRow item={item} navigation={props.navigation} />
                                    case 'poi':
                                        return <POIRow item={item} navigation={props.navigation} />
                                }
                            }
                        }}
                        refreshing={false}
                        onEndReachedThreshold={0.5}
                        onEndReached={(offset) => {
                            if (inputText.length < 1) {
                                return;
                            }
                            console.log("onEndReached");
                            if (searchState.selectedTab === 'route' || searchState.selectedTab === 'station') {
                                if (searchState.page < Math.ceil(results.totalRecordCount / AppSetting.RECORD_COUNT_PER_QUERY)) {
                                    setSearchState({ ...searchState, page: searchState.page + 1 })
                                }
                            } if (searchState.selectedTab === "poi") {
                                setSearchState({ ...searchState, page: searchState.page + 1 })
                            }
                        }}
                        keyExtractor={(item, index) => 'key' + index}
                    />
                </View>
            </View>
            <SearchBottomTab navigation={props.navigation} />
        </SafeAreaView>
    )
}
