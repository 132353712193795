import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import BusRow, {  EmptyBusArrivalRow } from './BusRow';  
import {GetText} from '../../../defines/Texts'

export default function BusRows({ routeArrival, onPressBusNotify, activeAlarm }) {
    
    if (!routeArrival || !routeArrival.busStatusList || routeArrival.busStatusList.length < 1) {
        return (
            <EmptyBusArrivalRow text={GetText("pageComment","SEARCHING_BUS_ARRIVAL_INFO")} />
        )
    }
    let busStatusList = routeArrival.busStatusList;
    return (
        <>
            {
                routeArrival && routeArrival.busStatusList && routeArrival.busStatusList.length > 0 ?
                <BusRow 
                    busStatus={busStatusList[0]}
                    routeArrival={routeArrival} 
                    taglessCd={routeArrival.taglessCd1}
                    onPressBusNotify={onPressBusNotify}  
                    activeAlarm={activeAlarm} /> : null
            }
            {
                routeArrival && routeArrival.busStatusList && routeArrival.busStatusList.length > 1 ?
                <BusRow 
                    busStatus={busStatusList[1]}
                    routeArrival={routeArrival} 
                    taglessCd={routeArrival.taglessCd2}
                    onPressBusNotify={onPressBusNotify}  
                    activeAlarm={activeAlarm} /> : null
            }
        </>)
}
