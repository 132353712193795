import React, { Component } from 'react';
import { StyleSheet,  Text, Linking, View, TouchableOpacity, ScrollView } from 'react-native';
import { Dimensions, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
//import Image from 'react-native-scalable-image';

// lib, config
import { Colors, Layouts } from '../../defines/Theme';

import * as DateUtil from '../../utils/DateUtil';
import * as JsUtil from '../../utils/JsUtil';

import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerH from '../components/common/SpacerH';
import CommonStyle from '../../styles/CommonStyle';
import TextStyle from '../../styles/TextStyle';
import SectionStyle from '../../styles/SectionStyle';

import SpacerMiniV from '../components/common/SpacerMiniV';
import FIcon from '../../defines/FIcon';


// subject, content, startDt, endDt, routeId
export default class EventContent extends Component { 

    constructor(props) {
        super(props)
    }

     render() {
        const { event } = this.props;
        // console.log(this.props.route.params.data);
        let icon = "file-alt"
        return (
            <>
                {/* title */}
                <View style={SectionStyle("rowForRow", { 
                    alignItems: "center", 
                    padding: 10, 
                    backgroundColor: Colors.gsYellow
                }) }>
                    <FIcon type="fad" name={icon} size={30} color={Colors.whiteLight} />
                    <Text style={TextStyle("rowTitle", "large", { 
                        flex: 1, lineHeight: 25, paddingLeft: 10, color: Colors.white })}>
                        {JsUtil.GText(event, "subject")}
                    </Text>
                </View>
                {/* from - to <i class="fad fa-calendar-alt"></i>*/}
                <View style={SectionStyle("rowForRow", { 
                    alignItems: "center", 
                    paddingHorizontal: 20, 
                    paddingVertical: 10, 
                    backgroundColor: Colors.silver
                }) }>
                    <FIcon type="fad" name="calendar-alt" size={24} color={Colors.ggBlue} />
                    <Text style={TextStyle("rowDetail", "small", { lineHeight: 30, paddingLeft: 10 })}>
                        {DateUtil.format(JsUtil.GText(event, "startDt"), DateUtil._DT_FORMAT_TO_MIN)} ~ {DateUtil.format(JsUtil.GText(event, "endDt"), DateUtil._DT_FORMAT_TO_MIN)}
                    </Text>
                </View>
                {/* scroll contents + image */}
                <ScrollView style={CommonStyle("boxFill", {})}>
                    {/* contents */}
                    <View style={CommonStyle("box", { 
                        alignItems: "center", 
                        padding: 15, 
                        
                    }) }>
                        <Text style={TextStyle("rowTitleDesc", "medium", { lineHeight: 20, paddingLeft: 10 })}>
                            {JsUtil.GText(event, "content").replace(/<br>/gi, '\n')}
                        </Text>
                    </View>
                    {/* image */}
                </ScrollView>
            </>
        )
    }
}
