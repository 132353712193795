import React, { Component, createContext } from 'react';
import { Dimensions, PixelRatio, Platform, StatusBar, NativeModules } from 'react-native';
import {
    StyleSheet, Text, View, TouchableOpacity, TextInput,
    Image, PermissionsAndroid, TouchableWithoutFeedback, Keyboard, FlatList, Alert
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NavigationContext, Link } from '@react-navigation/native';
import SplashScreen from 'react-native-splash-screen'

// configurations
import { Colors, Layouts } from '../../defines/Theme';
import LoadImage from '../../defines/Images';
import FIcon from '../../defines/FIcon';
import Texts, { GetText, GetTextSido } from '../../defines/Texts';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as DateUtil from '../../utils/DateUtil';
import GetLocation, { requestGpsPermission } from '../../gps/GetLocation';

// context
import AlarmContext from '../../context/AlarmContext';

// shared
import ScreenHeader from '../components/common/ScreenHeader';
import MainBottomTab from '../components/bottomTabs/MainBottomTab';
import Refresh from '../components/common/Refresh';

// local
import FavorEmpty from './include/FavorEmpty';
import AppSetting from '../../defines/AppSetting';

// const SharedStorage = NativeModules.SharedStorage;
// // let UserScriptApi = require('../../utils/UserScriptApi')
export default class HomeFavor extends Component {

    static contextType = AlarmContext;

    constructor(props) {
        super(props);

        this.flatRef = React.createRef();
        this.popShowTimer = null;

        this.state = {
            favors: [],
            userRefreshCount: 0,
            showVoiceModal: false,
        }
        // 모바일 운영체제 글꼴크기 변경 무시
        if (Text.defaultProps == null) Text.defaultProps = {};
        Text.defaultProps.allowFontScaling = false;
        if (TextInput.defaultProps == null) TextInput.defaultProps = {};
        TextInput.defaultProps.allowFontScaling = false;
    }

    componentDidMount() {
        // UserScriptApi.apiConnect('home')
        console.log("Home mounted..");
        this.syncGlobalToState();
        SplashScreen?.hide();
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            console.log("[Home] focused");
            requestGpsPermission((granted) => {
                if (!granted) {
                    // this.context.msgHandler?.toast(GetText("GPS", "PLZ_ALLOW_PERMISSION"))
                }
                else {
                    console.log("[GPS] granted")
                }
            })
            this.syncGlobalToState();
            if (!this.context.popLoadTime) {
                //if (DateUtil.GetElapsedMins(this.state.popShownTime) > AppSetting.POP_NOTICE.REFRESH_INTERVAL_MIN) {
                this.popShowTimer = setInterval(() => {
                    this.runPopupBoards()
                }, 1000);
            }
        });
        this._blur = this.props.navigation.addListener('blur', () => {
            console.log("[Home] blured");
            if (this.popShowTimer) {
                clearInterval(this.popShowTimer);
            }
            this.setState({ showVoiceModal: false });
        });
    }

    componentWillUnmount() {
        console.log("[Home] unmount");
        this._unsubscribe();
        this._blur();
    }

    runPopupBoards() {
        if (!this.context.initState.isLoading &&
            this.context.initState.contextInitialized) {
            //DateUtil.GetElapsedMins(this.context.popLoadTime) > AppSetting.POP_NOTICE.REFRESH_INTERVAL_MIN) {
            clearInterval(this.popShowTimer);
            console.log("[Home] refresh popup boards")
            this.context.refreshPopups(() => {
                if (this.context.popBoards && this.context.popBoards.length > 0) {
                    console.log(`[Home] ${this.context.popBoards.length} new popups found`)
                    this.context.msgHandler.popup({
                        title: GetText("pageTitle","MESSAGE"),
                        contentType: "board",
                        contents: this.context.popBoards,
                        enableNoMore: true,
                        closeCallback: (feedback) => {
                            if (feedback.noMoreShow) {
                                this.context.saveShownPopBoards([...new Set(feedback.shownIndexes)]);
                            }
                        }
                    })
                }
            })
        }
    }

    syncGlobalToState() {
        if (global.historyReview) {
            if (this.state.favors) {
                if (JSON.stringify(this.state.favors) !== JSON.stringify(global.historyReview)) {
                    this.setState({
                        favors: JSON.parse(JSON.stringify(global.historyReview)),
                    }, () => {
                        console.log("[Home] favors updated");
                        try {
                            this.flatRef.current?.scrollToOffset({
                                animated: false,
                                offseet: 0
                            })
                        } catch (ex) { }
                    });
                }
            }
            else {
                this.setState({
                    favors: JSON.parse(JSON.stringify(global.historyReview)),
                }, () => {
                    console.log("[Home] favors updated");
                    try {
                        this.flatRef.current?.scrollToOffset({
                            animated: false,
                            offseet: 0
                        })
                    } catch (ex) { }
                });
            }
        }

    }

    renderEmpty() {
        return <></>
    }

    render() {

        // if (this.state.appIsLoading && !context.initState.isLoading) {
        //     this.setState({appIsLoading: false}, () => {
        //         this.runPopup();
        //     })
        // }

        if (this.context.initState.isLoading) {
            console.log(`[Home] still app loading return nothing...`)
            return <></>;
        }

        return (
            // <TouchableWithoutFeedback>
            <SafeAreaView style={CommonStyle('bg')}>
                <View style={SectionStyle('rowForslogan',
                    { flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 5 })}>
                    <TouchableOpacity style={CommonStyle("headerButtonBg")} />
                    <LoadImage name="slogan" width={225} height={27} />
                    <TouchableOpacity style={CommonStyle("headerButtonBg")}
                        onPress={() => {
                            this.props.navigation.openDrawer()
                        }} >
                        <FIcon type="fad" name="bars" size={23} color={Colors.ggBlue} />
                    </TouchableOpacity>
                </View>
                {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
                <View style={CommonStyle('body')}>
                    <View style={SectionStyle("rowForInput")}>
                        <View style={CommonStyle("inputBg")}>
                            {/* <Link to="/searchMain"> */}
                            <TextInput
                                style={TextStyle('input')}
                                placeholder={GetText("search", "placeholder", "")}
                                onFocus={() => {

                                    this.props.navigation.navigate('searchMain', {
                                        caller: "home"
                                    });
                                }}
                            />
                            {/* </Link> */}
                        </View>
                    </View>
                    <FavorEmpty navigation={this.props.navigation} /> 
                </View>

                {/* </TouchableWithoutFeedback> */}
                {/* <Refresh
                    onPress={() => {
                        this.setState({ userRefreshCount: this.state.userRefreshCount + 1 });
                    }}
                /> */}
                <MainBottomTab
                    navigation={this.props.navigation}
                    bottomSheet={this.bottomSheet}
                />
            </SafeAreaView >
        // </TouchableWithoutFeedback>)
        )
    }
}
