import React from 'react';
import { View, Text } from 'react-native';
import { GetText, GetTextSido } from '../../../defines/Texts';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';

const ListEmpty = ({ textType, textId, text, style, textStyle }) => { 
    let t = text || typeof text === "string" ? text : GetText(textType ? textType : "general", textId ? textId : "NO_ITEM")
    return (
        <View style={CommonStyle('listEmptyBg', style)}>
            <Text style={ TextStyle('listEmptyText', textStyle)}>{t}</Text>
        </View>    
    );
}



export default ListEmpty;