import React from 'react';
import { View, Text } from 'react-native';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import * as JsUtil from '../../../utils/JsUtil';

import SpacerH from '../common/SpacerH';
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';

// busStatus
// //--- 운행상태 기본값
// busStatus.runStatus = BUS_RUNS.OK; 
// busStatus.runStatusReason = "";
// //--- 운행부가상태 기본값
// busStatus.runStatusAux = BUS_RUNS_AUX.NONE;
// busStatus.runStatusAuxReason = "";
// //--- 장애상태 기본값
// busStatus.trouble = BUS_TROUBLES.NONE;
// busStatus.troubleReason = "";

const BusTroubleBox = ({ bus }) => {

    if (JsUtil.GText(bus, "trouble")) {
        
    }
    // 시간정보가 없는 경우
    // if (busArrival.source && busArrival.source._text !== "routeArrival") {
    //     return (<>
    //         <View style={ SectionStyle('arrivalTimeTextBg', { alignItems: "center", paddingVertical: 5} ) }>
    //             <FIcon type="fas" name="hourglass" color={Colors.whiteLight} size={20} />
    //         </View>
    //     </>)
    // }
    let containerStyle = "arrivalTimeTextBg";
    let textStyle = "arrivalTimeText";
    if (bus.runStatus === GBusHelper.BUS_RUNS.W_GARAGE || bus.runStatus === GBusHelper.BUS_RUNS.W_GARAGE_TURN) {
        containerStyle = "arrivalTimeTextBg_garage";
        textStyle = "arrivalTimeText_garage";
    }
    else if (bus.runStatus !== GBusHelper.BUS_RUNS.OK) {
        containerStyle = "arrivalTimeTextBg_noBus";
        textStyle = "arrivalTimeText_noBus";
    }

    return (<>
        {
        <View style={SectionStyle(containerStyle)}>
            <Text numberOfLines={1} style={TextStyle(textStyle, 'medium')}>
                {GBusHelper.getBusArrivalText(bus, true)}
            </Text>
        </View>
        }
    </>)
}


export default BusTroubleBox 