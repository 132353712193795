// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Bug from '../utils/Bug';

export const styles = {    
    _def: {
        backgroundColor: '#f00',
    },
    // bottom tabs container
    bottomTabBg: {
        position: 'absolute',
        bottom: 0, 
        left: 0, 
        right: 0,
        height: Layouts.bottomBarHeight,
        width: "100%", // Layouts.windowWidth,
        backgroundColor: Colors.blue,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        alignItems: 'stretch',
        paddingVertical: 7,
    },
    bottomTabLeftBg: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        alignItems: 'stretch',
        padding: 0,
        margin: 0,
        marginRight: 0,
        //backgroundColor: '#f00',
    },
    bottomTabRightBg: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        alignItems: 'stretch',
        padding: 0,
        margin: 0,
        marginRight: 0,
        //    backgroundColor: '#f0f',
    },
    // tab. 일반. 균등정렬시 사용
    bottomTab: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        // borderWidth: 1,
        // borderColor: "#fff"
        // backgroundColor: "#f00"
    },
    // tab. 고정길이 탭. 우측정렬시 사용
    bottomTabW: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: Layouts.bottomTabMinWidth,
        // borderColor: '#fff',
        // borderWidth: 1,
    },
    bottomTabWRideSupport: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: Layouts.bottomTabMinWidthRideSupport,
        // borderColor: '#fff',
        // borderWidth: 1,
    },
    // tab. 아이콘을 왼쪽으로 붙이는 용도로 사용
    bottomBackTab: {
        //alignItems: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        width: Layouts.bottomTabMinWidth * 0.75,
        //paddingLeft: 10,
        // borderColor: '#fff',
        // borderWidth: 1,
    },
    // inner icon and text
    bottomTabIconBg: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        // height: "70%",
        // backgroundColor: "#f0f"
    },

    iconBadge: {
        position: 'absolute',
        top: -5,
        left: -8,
        width: 22,
        height: 22,
        backgroundColor: Colors.blue,
        borderWidth: 1
    },
    // 
};

const BottomTabStyle = (name, customStyles = null) => {
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('BottomTabStyle.GetStyle', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default BottomTabStyle