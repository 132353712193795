import React, { Component, useState, useEffect, useRef } from 'react'; 
import { Text, View, TouchableOpacity } from 'react-native';
import { Colors, Theme } from '../../../defines/Theme';
import LoadSvg from '../../../defines/Svgs';
import * as GBus from '../../../defines/GBus';
import * as JsUtil from '../../../utils/JsUtil';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

const RenderDetailLine = ({ text }) => {
    return (
        <View style={{ paddingVertical: 0 }}>
            <Text numberOfLines={1} style={TextStyle('rowDetail', 'smallest', {
                lineHeight: 24,
                padding: 0, 
                paddingLeft: 0, 
                marginHorizontal: 0, 
                marginVertical: 0, 
                width: "100%", 
                color: Colors.fontCommentColor,
                textAlign: "left"
            })}>
                {text}
            </Text>
        </View>
    )
}

export default function StationRow({ item, navigation }) {
    return (
        <View style={ SectionStyle('columnBgBottomBorder') }>
            {/* 행 */}
            <TouchableOpacity style={ SectionStyle('rowForRow', {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: "transparent"
            }) }
                onPress={() => {
                    navigation.navigate('stationInfo', { data: item })
                }}
            >
                {/* 아이콘 */}
                <View style={ SectionStyle('rowLeftIconArea') }>
                    <LoadSvg name="station" width={33} height={30} />
                </View>
                {/* 정류소 */}
                <View style={ SectionStyle('rowFillStretchAround', { 
                    justifyContent: "space-between", 
                    paddingVertical: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginLeft: 0,
                    marginTop: 5,
                    marginBottom: 0,
                    backgroundColor: "transparent",
                }) }>
                    <Text numberOfLines={1} style={ TextStyle('rowTitle', 'huge', {
                        color: Colors.fontColor,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginBottom: 5,
                        width: "100%",
                    }) }>
                        {JsUtil.GText(item, "stationName")}
                    </Text>
                    <RenderDetailLine text={GBus.GetStationDetail(item)} />
                </View>
            </TouchableOpacity>
        </View>
    )
}
