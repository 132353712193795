import React, { useState, useEffect, useContext } from 'react';
import { Dimensions, Platform } from 'react-native';

//import AsyncStorage from '@react-native-community/async-storage';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import SplashScreen from 'react-native-splash-screen';

import AlarmContext from './context/AlarmContext';
import AlarmContextProvider from './context/AlarmContextProvider';

import PageLoaderIndicator from './screens/components/common/PageLoaderIndicator';
import MainTabStackNav from './screens/MainTabStackNav';
import LoadingScreen from './screens/LoadingScreen';
import { SWRConfig } from 'swr';
import { apiXmlFetcher } from './api/axios/axiosFetcher';

import * as FavorUtil from './utils/FavorUtil';
import * as StorUtil from './utils/StorUtil';

import * as DateUtil from './utils/DateUtil';

// import your fontawesome library
import './lib/Fontawesome';

// import * as PushNotifier from './src/alarm/PushNotifier';

// PushNotifier.configure();

// main App
export default function App() {
    const context = useContext(AlarmContext)
    const [isShowPageLoader, setIsShowPageLoader] = useState(false);
    const [initState, setInitState] = useState({
        isLoading: true,
        fontSize: null
    });

    global.showPageLoader = (isShow) => {
        setIsShowPageLoader(isShow)
    }

    global.forceUpdate = () => {
        const [value, setValue] = useState(0)
        return () => setValue(value => ++value);
    }

    useEffect(() => {
        console.log(`[App] starting app()`);
        StorUtil.LoadGlobalAppVars((values) => {
            SplashScreen?.hide();
            // 1. 여기에서 global 변수 바로 참조하면 값이 반영되어 있지 않습니다.
            // 2. 여기에서는 Context 에서 제공하는 함수를 사용할 수 없습니다. (App 이 Context.Provider 내부에 있지 않으므로...)
            setInitState({
                isLoading: false, 
                //fontSize: values["fontSize"]
                fontSize: 0
            })
        })         
    }, [])

    if (initState.isLoading) {
        console.log(`[App] showing loading screen...`);
        return <LoadingScreen />
    }
    else {
        //console.log(`[App] showing home...`);
        return (
            <SWRConfig
                value={{
                    fetcher: apiXmlFetcher, // default fetcher = xml
                }}>
                <AlarmContextProvider>
                    <SafeAreaProvider >
                        {
                            <MainTabStackNav initState={initState}/>
                        } 
                        <PageLoaderIndicator isPageLoader={isShowPageLoader}/>
                    </SafeAreaProvider>
                </AlarmContextProvider>
            </SWRConfig>
        )
    }
}




// import React, { useState, useEffect } from 'react';
// import {
//     SafeAreaView,
//     StyleSheet,
//     ScrollView,
//     View,
//     Text,
//     StatusBar,
// } from 'react-native-web';

// //import AsyncStorage from '@react-native-community/async-storage';
// import { SafeAreaProvider } from 'react-native-safe-area-context';
// import SplashScreen from 'react-native-splash-screen';

// import DialogContextProvider from './context/DialogContextProvider';
// import AuthContextProvider from './context/AuthContextProvider';
// import FavorContextProvider from './context/FavorContextProvider';

// import MainTabStackNav from './screens/MainTabStackNav';
// import LoadingScreen from './screens/LoadingScreen';
// import TestPage from './screens/test/TestPage';


// import PageLoaderIndicator from './screens/components/loaders/PageLoaderIndicator';

// import * as StorUtil from './utils/StorUtil';
// import * as DateUtil from './utils/DateUtil';

// // import your fontawesome library
// import './lib/Fontawesome';

// import NavHome from './screens/Nav';


// // /**
// //  * Sample React Native App
// //  * https://github.com/facebook/react-native
// //  *
// //  * @format
// //  * @flow strict-local
// //  */

// // import React from 'react';
// // import {
// //     SafeAreaView,
// //     StyleSheet,
// //     ScrollView,
// //     View,
// //     Text,
// //     StatusBar,
// // } from 'react-native-web';

// // import {
// //   Header,
// //   LearnMoreLinks,
// //   Colors,
// //   DebugInstructions,
// //   ReloadInstructions,
// // } from 'react-native/Libraries/NewAppScreen';

// export default function App() {
//     const [showPageLoader, setShowPageLoader] = useState(false);
//     const [showAppLoader, setShowAppLoader] = useState(false);
//     const [initState, setInitState] = useState({
//         isLoading: false,
//         fontSize: null
//         // isLoading: true,
//         // fontSize: null
//     });

//     global.showPageLoader = (visible) => {
//         setShowPageLoader(visible)
//     }
//     global.showAppLoader = (visible) => {
//         setShowAppLoader(visible)
//     }

//     // return (
//     //     <NavHome />
//     // )

//     return (
//         <SafeAreaProvider >
//             <AuthContextProvider>
//                 <DialogContextProvider>
//                     <FavorContextProvider>
//                         <MainTabStackNav initState={initState} />
//                     </FavorContextProvider>
//                 </DialogContextProvider>
//             </AuthContextProvider>
//         </SafeAreaProvider>
//     )

//     return (
//         // <SafeAreaProvider >
//         <AuthContextProvider>
//             <DialogContextProvider>
//                 <FavorContextProvider>
//                     <TestPage />
//                 </FavorContextProvider>
//             </DialogContextProvider>
//         </AuthContextProvider>
//         // </SafeAreaProvider>
//     )

//     // return (
//     //     <MainTabStackNav initState={initState} />
//     // )

//     return (

//         <AuthContextProvider>
//             <DialogContextProvider>
//                 <FavorContextProvider>

//                     {
//                         <MainTabStackNav initState={initState} />
//                     }
//                     <PageLoaderIndicator visible={showPageLoader} />
//                     <PageLoaderIndicator visible={showAppLoader} type="pulse" />
//                     {/* <FlashMessage position="center" hideOnPress={true} autoHide={true} duration={3000} />  */}
//                 </FavorContextProvider>
//             </DialogContextProvider>
//         </AuthContextProvider>

//     )



//     return (
//         <SafeAreaProvider >
//             <AuthContextProvider>
//                 <DialogContextProvider>
//                     <FavorContextProvider>

//                         {
//                             <MainTabStackNav initState={initState} />
//                         }
//                         <PageLoaderIndicator visible={showPageLoader} />
//                         <PageLoaderIndicator visible={showAppLoader} type="pulse" />
//                         {/* <FlashMessage position="center" hideOnPress={true} autoHide={true} duration={3000} />  */}
//                     </FavorContextProvider>
//                 </DialogContextProvider>
//             </AuthContextProvider>
//         </SafeAreaProvider>
//     )

//     // return (
//     //     <>
//     //         <StatusBar barStyle="dark-content" />
//     //         <SafeAreaView>
//     //             <ScrollView
//     //                 contentInsetAdjustmentBehavior="automatic"
//     //                 style={styles.scrollView}>
//     //                 {/* <Header /> */}
//     //                 {global.HermesInternal == null ? null : (
//     //                     <View style={styles.engine}>
//     //                         <Text style={styles.footer}>Engine: Hermes</Text>
//     //                     </View>
//     //                 )}
//     //                 <View style={styles.body}>
//     //                     <View style={styles.sectionContainer}>
//     //                         <Text style={styles.sectionTitle}>Step One</Text>
//     //                         <Text style={styles.sectionDescription}>
//     //                             Edit <Text style={styles.highlight}>App.js</Text> to change this
//     //             screen and then come back to see your edits.
//     //           </Text>
//     //                     </View>
//     //                     <View style={styles.sectionContainer}>
//     //                         <Text style={styles.sectionTitle}>See Your Changes</Text>
//     //                         {/* <Text style={styles.sectionDescription}>
//     //             <ReloadInstructions />
//     //           </Text> */}
//     //                         <Text style={styles.sectionDescription}>Hello world!</Text>
//     //                     </View>
//     //                     <View style={styles.sectionContainer}>
//     //                         <Text style={styles.sectionTitle}>Debug</Text>
//     //                         {/* <Text style={styles.sectionDescription}>
//     //             <DebugInstructions />
//     //           </Text> */}
//     //                         <Text style={styles.sectionDescription}>Hello world!</Text>
//     //                     </View>
//     //                     <View style={styles.sectionContainer}>
//     //                         <Text style={styles.sectionTitle}>Learn More</Text>
//     //                         <Text style={styles.sectionDescription}>
//     //                             Read the docs to discover what to do next:
//     //           </Text>
//     //                     </View>
//     //                     {/* <LearnMoreLinks /> */}
//     //                 </View>
//     //             </ScrollView>
//     //         </SafeAreaView>
//     //     </>
//     // );
// };

// const styles = StyleSheet.create({
//     scrollView: {
//         //backgroundColor: Colors.lighter,
//     },
//     engine: {
//         position: 'absolute',
//         right: 0,
//     },
//     //   body: {
//     //     backgroundColor: Colors.white,
//     //   },
//     sectionContainer: {
//         marginTop: 32,
//         paddingHorizontal: 24,
//     },
//     sectionTitle: {
//         fontSize: 24,
//         fontWeight: '600',
//         // color: Colors.black,
//     },
//     sectionDescription: {
//         marginTop: 8,
//         fontSize: 18,
//         fontWeight: '400',
//         //color: Colors.dark,
//     },
//     highlight: {
//         fontWeight: '700',
//     },
//     footer: {
//         // color: Colors.dark,
//         fontSize: 12,
//         fontWeight: '600',
//         padding: 4,
//         paddingRight: 12,
//         textAlign: 'right',
//     },
// });


