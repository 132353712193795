import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native';

// config, lib
import * as GBus from '../../defines/GBus';
import { Colors, Layouts } from '../../defines/Theme';
import { GetText, GetTextSido } from '../../defines/Texts';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import CommonStyle from '../../styles/CommonStyle';
import LoadImage from '../../defines/Images';
import * as JsUtil from '../../utils/JsUtil';

const TouchableStationHeader = ({ onPress, children }) => {
    return (
        <TouchableOpacity style={SectionStyle("stationHeaderBg")}
            onPress={() => { onPress?.() }}>
            {children}
        </TouchableOpacity>
    )
}

const UnTouchableStationHeader = ({ children }) => {
    return (
        <View style={SectionStyle("stationHeaderBg")} >
            {children}
        </View>
    )
}

const StationContent = ({ station }) => { 
    return (<>
        <View style={SectionStyle("stationHeaderTextBg")}>
            <Text numberOfLines={1} style={TextStyle("stationHeaderText", "huge", { textAlign: "center" })}>
                {station ? JsUtil.GText(station, "stationName") : '...'}
            </Text>
        </View>
        <View style={SectionStyle("stationHeaderDetailsBg")}>
            <Text numberOfLines={1} style={TextStyle("stationHeaderDetailText", "small", { maxWidth: 300 })}>
                {GBus.GetStationMobileNo(station, " | ")}
                {station ?  GetTextSido(JsUtil.GText(station, "regionName")) : '...'}
            </Text>
        </View>
        {station && station.toiletStYn && station.toiletStYn._text === 'Y' ?
                <View style={CommonStyle("reloadOverlayIconBgToilet", { top: 20, flex:1 } )} accessible={true} accessibilityLabel="화장실 이용 가능">
                    <LoadImage name="icon_toilet" width={30} height={32} />
                </View> 
                : null
        }       
    </>)
}

export default function StationHeader({ station, onPress }) {

    return (
        onPress
            ? <TouchableStationHeader onPress={onPress} >
                <StationContent station={station} />
            </TouchableStationHeader>
            : <UnTouchableStationHeader>
                <StationContent station={station} />
            </UnTouchableStationHeader>
    )
}
