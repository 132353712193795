import React from 'react';
import * as StorUtil from './StorUtil';
import * as JsUtil from './JsUtil';
import AppSetting from '../defines/AppSetting';
import Texts, { GetText } from '../defines/Texts'

const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_FAVOR_LOG) {
        console.log(`[FavorUtil] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][FavorUtil] ${m}`, e)
}

/**
 * station: 
 *   type, data{station object}, memo
 * routeStation
 *   type, stationId, stationInfo(stationObj), data[{ routeId {_text}}], memo
 * route: 
 *   type, routeId{_text}, memo
 * findRoad
 *   type, startPOI { title, address, x, y }, endPOI { title, address, x, y }, memo
 */
export const SaveGlobalFontSize = async (value) => {
    global.fontSize = value
    await StorUtil.Save("fontSize", global.fontSize)
}

export const SaveDeviceId = async (value) => {
    global.deviceId = value
    await StorUtil.Save("deviceId", global.deviceId)
}

export const SaveForeignLanguage = async (value) => {
    global.foreignLanguage = value
    await StorUtil.Save("foreignLanguage", global.foreignLanguage)
}

/**
 *  * 즐겨찾기
 */
export const GetFavorStationIds = () => {
    return []
}

export const IsStationInFavor = (stationId, wantTypes = ["station", "routeStation"]) => {
    return { favorIndex: -1, favorType: '', favorChildRouteCount: 0 };
}

export const IsRouteInFavor = (routeId) => {
    return { favorIndex: -1, favorType: '' };
}

export const IsStationRouteInFavor = (stationId, routeId, staOrder) => {
    return { favorIndex: -1, favorChildRouteIndex: -1, favorType: '', favorChildRouteCount: 0 };
}

// 정류소 추가,삭제
export const AddFavorStation = async (station, callback) => {
    callback(false)
}

// routeStation 으로 등록되어 있는 경우, 하위 노선 모두 삭제합니다.
export const RemoveFavorStation = async (stationId, callback, wantTypes = ["station", "routeStation"]) => {
    return;
}

// 노선 추가,삭제
export const AddFavorRoute = async (routeId, callback) => {
    callback?.(false)
}

export const RemoveFavorRoute = async (routeId, callback) => {
    callback?.(false)
}

export const IsPathFindInFavor = (startPOI, endPOI) => {
    return { favorIndex: -1, favorType: '' };
}

// 길찾기 추가,삭제
export const AddFavorPathFind = async (startPOI, endPOI, callback) => {
    callback?.(false)
}

export const RemoveFavorPathFind = async (startPOI, endPOI, callback) => {
    callback?.(false)
}

/**
 *  정류소+노선 추가,삭제
 * station: 
 *   type, data{station object}, memo
 * routeStation
 *   type, stationId, stationInfo(stationObj), data[{ routeId {_text}}], memo
*/

const _DeleteFavorStation = (stationId) => {
}

export const AddFavorStationRoute = async (station, routeId, staOrder, callback) => {
    callback?.(false)
}

// if routeId == null or empty, remove all routes and station
// 하위 호환성. 기존 즐겨찾기에는 staOrder 가 없음..
export const RemoveFavorStationRoute = async (stationId, routeId, staOrder, callback) => {
    callback?.(false); 
}

export const SaveWidgetRefreshPeriod = async (period, callback) => {
    callback?.(false); 
}

export const BuildFavorStationRoutesForWidget = (favors, includeHideItem) => {
    return {}
}

export const GetVisibleFavorStationRouteCountInWidget = () => {
    return 0;
}

// 
export const SetFavorStationRouteWidgetVisible = async (stationId, routeId, staOrder, hide, callback) => {
    callback?.(false); 
}

// edit memo
export const SetFavorMemo = async (index, memo, callback) => {
    return;
}

// remove by index
export const RemoveFavor = async (index, callback) => {
    callback?.(false)
}

export const UpdateFavorsOrder = async (favors, callback) => {
    callback?.(false)
}