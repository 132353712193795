import { Platform, Linking } from 'react-native';
import DeviceInfo from 'react-native-device-info';

// https://github.com/react-native-device-info/react-native-device-info

/* window.location structure
    location { 
        host: "localhost"
        hostname: "localhost"
        href: "http://localhost/"
        origin: "http://localhost/:3000"
        pathname: "/"
        port: "3000"
        protocol: "http:"
    }
*/


export const openMobileApp = (os, onFail) => {
    const _PACKAGE_NAME = 'kr.or.gbushybrid';
    const _APPLE_APP_ID = '';
    console.log("====> Service platform: " + os)
    try {
        if (os == "android") {
            if (Linking.canOpenURL(`market://details?id=${_PACKAGE_NAME}`)) {
                Linking.openURL(`market://details?id=${_PACKAGE_NAME}`);
            }
            else {
                onFail?.();
            }
        }
        else if (os == "iOS") {
            if (Linking.canOpenURL(`itms-apps://itunes.apple.com/us/app/apple-store/${_APPLE_APP_ID}?mt=8`)) {
                Linking.openURL(`itms-apps://itunes.apple.com/us/app/apple-store/${_APPLE_APP_ID}?mt=8`)
            }
            else {
                onFail?.();
            }
        }
        else {
            Linking.openURL(`https://play.google.com/store/apps/details?id=${_PACKAGE_NAME}`)
            // window.open(`https://play.google.com/store/apps/details?id=${_PACKAGE_NAME}`, '_blank')
            // Linking.openURL(`https://play.google.com/store/apps/details?id=${_PACKAGE_NAME}`);
        }
    }
    catch (e) {
        console.error("openMobileApp error", e)
    }
}
