import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Text, View, TouchableOpacity, Dimensions, StyleSheet, FlatList } from 'react-native';
import _ from 'lodash';
import moment from 'moment';
// configurations
import { Colors } from '../../../defines/Theme';
import { GetText } from '../../../defines/Texts';
import * as DateUtil from '../../../utils/DateUtil';
import * as JsUtil from '../../../utils/JsUtil';
import TextStyle from '../../../styles/TextStyle';
import { usePastArrivals } from '../../../hooks';

// // shared components
import ListEmpty from '../../components/parts/ListEmpty';
import ScreenHeader from '../../components/common/ScreenHeader';
// import { SkeletonList } from '@components/skeletons/SkeletonList';

const itemHeight = 32;

// content = { items, highlight, highlightIndex }
function ArrivalTimeCol({ content, isLoading, error, btnLabel, onBtnPress }) {
    const flatListRef = useRef(null);
    const [scrollIndex, setScrollIndex] = useState(0);
    // const [layout, onLayout] = useComponentLayout();

    useEffect(() => {
        if (content?.highlight) {
            setScrollIndex(p => content.items.indexOf(content.highlight));
        }
    }, [content]);

    const scrollTo = () => {
        if (flatListRef.current && scrollIndex > 0) {
            flatListRef.current?.scrollToIndex({
                animated: false,
                index: scrollIndex,
            });
            setScrollIndex(0);
        }
    };

    return (
        <View style={styles.colContainer}>
            <TouchableOpacity
                style={[styles.colDayBtn, content || isLoading ? styles.colDayBtnRetrieved : null]}
                disabled={content || isLoading}
                onPress={() => {
                    onBtnPress?.();
                }}>
                <Text style={styles.colDayBtnText}>{btnLabel}</Text>
            </TouchableOpacity>
            <View style={styles.itemsContainer}>
                {error && <ListEmpty text="조회 실패" />}
                {/* {isLoading && <SkeletonList width={layout?.width} height={layout?.height} />} */}
                {!isLoading && !error && content && (
                    <FlatList
                        ref={flatListRef}
                        ListEmptyComponent={<ListEmpty text="이력 없음" />}
                        getItemLayout={(data, index) => ({
                            length: itemHeight,
                            offset: itemHeight * index,
                            index,
                        })}
                        onContentSizeChange={(w, h) => {
                            scrollTo();
                        }}
                        removeClippedSubviews={false}
                        initialNumToRender={20}
                        maxToRenderPerBatch={20}
                        legacyImplementation={true}
                        windowSize={21}
                        data={content.items}
                        renderItem={item => {
                            return (
                                <View
                                    style={[
                                        styles.itemRow,
                                        item.index % 2 === 0 ? styles.evenRow : styles.oddRow,
                                        item.item === content.highlight
                                            ? styles.highlightRow
                                            : null,
                                    ]}>
                                    <Text
                                        style={[
                                            styles.itemText,
                                            item.item === content.highlight
                                                ? styles.highlightText
                                                : null,
                                        ]}>
                                        {item.item.value}
                                    </Text>
                                </View>
                            );
                        }}
                        refreshing={false}
                        keyExtractor={(item, i) => `${JsUtil.GText(item, 'RArrivalDate', '')}_${i}`}
                    />
                )}
            </View>
        </View>
    );
}

/*--- settings. */
const dayShift = '04:00:00'; // HH:mm:ss - 이 시간까지 오늘날짜를 shift 함
const highlightRangeMinutes = [-10, 50]; // highlight 로 표시할 범위. 분단위.

export default function PastArrivalsContents(props) {
    const { onClose, station, route, staOrder } = props;
    const [stationId, setStationId] = useState('');
    const [routeId, setRouteId] = useState('');

    /*--- config object. 조회,출력에 필요한 값들 */
    const [config, setConfig] = useState({
        thisTime: null, // 현재시각 <= 시각 데이터만
        shiftedToday: null, // 현재날짜. shift 적용된 날짜
        highlightRange: [null, null], // [from, to] <= 시각 데이터만
        p1Date: null, // shiftedToday 기준
        p2Date: null,
        p7Date: null,
    });

    // 조회 결과. 각각 1일전, 2일전, 7일전 데이터. 일부 가공을 위해 state 에 저장. { items, highlight, highlightIndex }
    const [p1Content, setP1Content] = useState(null);
    const [p2Content, setP2Content] = useState(null);
    const [p7Content, setP7Content] = useState(null);

    const {
        data: p1Data,
        error: p1Error,
        isLoading: p1IsLoading,
    } = usePastArrivals(config.p1Date, routeId, stationId, staOrder);
    const {
        data: p2Data,
        error: p2Error,
        isLoading: p2IsLoading,
    } = usePastArrivals(config.p2Date, routeId, stationId, staOrder);
    const {
        data: p7Data,
        error: p7Error,
        isLoading: p7IsLoading,
    } = usePastArrivals(config.p7Date, routeId, stationId, staOrder);

    // 최초 1회만 계산. moment.set() 은 mutable 함수입니다. 주의
    useEffect(() => {
        const thisTime = DateUtil.timeOnly();
        const shiftedToday = thisTime.isBefore(DateUtil.timeOnly(dayShift))
            ? moment().add(-1, 'd')
            : moment();

        const newConfig = {
            thisTime: thisTime,
            shiftedToday: shiftedToday,
            highlightRange: [
                thisTime.clone().add(highlightRangeMinutes[0], 'm'),
                thisTime.clone().add(highlightRangeMinutes[1], 'm'),
            ],
            p1Date: DateUtil.format(shiftedToday.clone().add(-1, 'd'), DateUtil._D_FORMAT_DEF),
            p2Date: DateUtil.format(shiftedToday.clone().add(-2, 'd'), DateUtil._D_FORMAT_DEF),
            p7Date: DateUtil.format(shiftedToday.clone().add(-7, 'd'), DateUtil._D_FORMAT_DEF),
        };
        setConfig(newConfig);
    }, []);

    useEffect(() => {
        setStationId(p => JsUtil.GText(station, 'stationId'));
        setRouteId(p => JsUtil.GText(route, 'routeId'));
        // const stationId = '201000245';
        // const staOrder = '31';
        // const routeId = '233000311';
    }, [station, route]);

    useEffect(
        () => {
            // if (p1Data) {
            console.log('=== P1Data changed', p1Data?.length);
            setP1Content(prev => calcHighlights(p1Data));
            // }
        },
        /// eslint-disable-next-line react-hooks/exhaustive-deps
        [p1Data],
    );
    useEffect(
        () => {
            // if (p2Data) {
            setP2Content(calcHighlights(p2Data));
            // }
        },
        /// eslint-disable-next-line react-hooks/exhaustive-deps
        [p2Data],
    );
    useEffect(
        () => {
            // if (p7Data) {
            setP7Content(calcHighlights(p7Data));
            // }
        },
        /// eslint-disable-next-line react-hooks/exhaustive-deps
        [p7Data],
    );

    const calcHighlights = useCallback(
        list => {
            const res = { highlight: null, items: [] };
            if (!list || list.length < 1) {
                return res;
            }
            res.items = _.sortBy(
                list
                    .filter(item => item.RArrivalDate)
                    .map((item, idx) => {
                        const t = DateUtil.timeOnly(item.RArrivalDate);
                        return {
                            order: idx,
                            timeOnly: t,
                            value:
                                (idx === 0 ? '첫차:' : list.length - 1 === idx ? '막차:' : '') +
                                DateUtil.format(t, 'HH:mm'),
                            // DateUtil.format(t, 'HH:mm:ss').slice(0, -1) + '0',
                        };
                    }),
                'idx',
            );
            res.highlight = _.head(
                res.items.filter(
                    item =>
                        item.timeOnly.isSameOrAfter(config.thisTime) &&
                        item.timeOnly.isSameOrBefore(config.highlightRange[1]),
                ),
            );
            // console.log('===  하이라이트1', config.thisTime, res.items);
            if (!res.highlight) {
                res.highlight = _.last(
                    res.items.filter(
                        item =>
                            item.timeOnly.isSameOrAfter(config.highlightRange[0]) &&
                            item.timeOnly.isSameOrBefore(config.thisTime),
                    ),
                );
                // console.log('===  하이라이트2', config.thisTime, res.items);
            }
            if (!res.highlight) {
                res.highlight = _.head(
                    res.items.filter(item => item.timeOnly.isSameOrAfter(config.thisTime)),
                );
            }
            return res;
        },
        [config],
    );

    const renderWarn = msg => {
        if (Array.isArray(msg)) {
            return (
                <View
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingVertical: 5,
                    }}>
                    {msg.map((m, i) => {
                        return (
                            <View key={i} style={{ paddingLeft: 5 }}>
                                <Text style={TextStyle('medium', { color: m.color })}>{m.msg}</Text>
                            </View>
                        );
                    })}
                </View>
            );
        } else {
            return <Text style={TextStyle('medium')}>{msg}</Text>;
        }
    };

    return (
        <View style={styles.container}>
            <ScreenHeader
                showSlogan={false}
                title={
                    <Text style={TextStyle('headerTitle', 'largest')}>
                        {GetText('pageTitle', 'PAST_ARRIVALS_HISTORY')}
                    </Text>
                }
                iconType="fas"
                iconName="times-circle"
                iconSize={30}
                onPress={() => {
                    onClose?.();
                }}
                accLabel="메뉴닫기"
            />
            <View style={styles.guideContainer}>
                {renderWarn(GetText('pageComment', 'PAST_ARRIVALS_HISTORY_GUIDE'))}
                {renderWarn(GetText('pageComment', 'PAST_ARRIVALS_HISTORY_GUIDE_WARN'))}
            </View>
            <View style={styles.colsBg}>
                <ArrivalTimeCol
                    content={p1Content}
                    isLoading={p1IsLoading}
                    error={p1Error}
                    btnLabel={GetText('pageWord', 'YESTERDAY')}
                    onBtnPress={() => {
                        if (config.shiftedToday && !config.p1Date) {
                            setConfig({
                                ...config,
                                p1Date: DateUtil.format(
                                    config.shiftedToday.clone().add(-1, 'd'),
                                    DateUtil._D_FORMAT_DEF,
                                ),
                            });
                        }
                    }}
                    skel="list"
                />
                <ArrivalTimeCol
                    skel="sectionList"
                    content={p2Content}
                    isLoading={p2IsLoading}
                    error={p2Error}
                    btnLabel={GetText('pageWord', 'DAY2PREV')}
                    onBtnPress={() => {
                        if (config.shiftedToday && !config.p2Date) {
                            setConfig({
                                ...config,
                                p2Date: DateUtil.format(
                                    config.shiftedToday.clone().add(-2, 'd'),
                                    DateUtil._D_FORMAT_DEF,
                                ),
                            });
                        }
                    }}
                />
                <ArrivalTimeCol
                    content={p7Content}
                    isLoading={p7IsLoading}
                    error={p7Error}
                    btnLabel={GetText('pageWord', 'DAY7PREV')}
                    onBtnPress={() => {
                        if (config.shiftedToday && !config.p7Date) {
                            setConfig({
                                ...config,
                                p7Date: DateUtil.format(
                                    config.shiftedToday.clone().add(-7, 'd'),
                                    DateUtil._D_FORMAT_DEF,
                                ),
                            });
                        }
                    }}
                />
            </View>
        </View>
    );
}

export const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width * 0.9,
        height: Dimensions.get('window').height * 0.9,
        borderRadius: 10,
        padding: 10,
        backgroundColor: Colors.appBackgroundColor,
        justifyContent: 'flex-start',
    },
    guideContainer: {
        padding: 5,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    guideText: {
        fontSize: 16,
        color: Colors.fontCommentColor,
        textAlign: 'center',
    },
    colsBg: {
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    colContainer: {
        width: '31%',
        display: 'flex',
        borderColor: Colors.ggBlue,
        borderWidth: 1,
        borderRadius: 3,
        padding: 1,
        marginBottom: 5,
    },
    colDayBtn: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.ggBlueTrans9,
        borderColor: Colors.ggBlue,
        borderRadius: 3,
        height: 40,
    },
    colDayBtnRetrieved: {
        backgroundColor: Colors.ggBlueTrans7,
    },

    colDayBtnText: {
        fontSize: 18,
        color: Colors.white,
    },

    itemsContainer: {
        height: '100%',
        flex: 1,
        backgroundColor: Colors.appBackgroundColor,
        // paddingVertical: 5,
    },
    itemRow: {
        height: itemHeight,
        justifyContent: 'center',
        alignItems: 'center',
    },
    evenRow: {},
    oddRow: {
        backgroundColor: Colors.ggBlueTrans1,
    },
    highlightRow: {
        backgroundColor: Colors.ggBlueTrans9,
        borderColor: Colors.ggBlue,
        borderWidth: 1,
    },
    itemText: {
        fontSize: 17,
        marginVertical: 3,
    },
    highlightText: {
        color: Colors.white,
        fontSize: 18,
        fontWeight: 'bold',
        // marginVertical: 3,
    },
});
