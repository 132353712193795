import Bug from '../utils/Bug';
import * as DateUtil from '../utils/DateUtil';
import * as JsUtil from '../utils/JsUtil';
import { GetText, GetTextSido } from '../defines/Texts';
import AppSetting from './AppSetting';


export const GBusConst = {

    /** 혼잡도
     * 	<!-- crowded color -->
        <color name="bus_crowded_1">#00d25f</color>
        <color name="bus_crowded_2">#0066ff</color>
        <color name="bus_crowded_3">#ff9933</color>
        <color name="bus_crowded_4">#ff0000</color>
    */
    DENSITY: {
        // _def: { label: '', color: '#f00' },
        // 0: { label: '', color: '#555' },
        // 1: { label: '여유', color: '#00d25f' },
        // 2: { label: '보통', color: '#0066ff' },
        // 3: { label: '혼잡', color: '#ff9933' },
        // 4: { label: '매우혼잡', color: '#ff0000' },
        _def: { label: '', color: '#f00' },
        0: { label: '', color: '#555' },
        1: { label: 'DENSITY1', color: '#00d25f' },
        2: { label: 'DENSITY2', color: '#0066ff' },
        3: { label: 'DENSITY3', color: '#ff9933' },
        4: { label: 'DENSITY4', color: '#ff0000' },
    },


    /** 기존 앱 색상
     * 	<color name="bus_color_white">#FFFFFF</color>
        <color name="bus_color_red">#E51713</color>
        <color name="bus_color_blue">#1E9CDE</color>
        <color name="bus_color_green">#239B12</color>
        <color name="bus_color_purple">#6E00FF</color>
        <color name="bus_color_gray">#6D6D6D</color>
        <color name="bus_color_yellow">#FFAB00</color>
    */

    ROUTE_TYPE: {
        _def: { label: 'NORMAL', shortLabel: 'NORMAL_ST', color: '#239B12', svg_color_name: "gn" },
        0: { label: 'NORMAL', shortLabel: 'NORMAL_ST', color: '#239B12', svg_color_name: "gn" },
        11: { label: 'DIRECT', shortLabel: 'DIRECT_ST', color: '#E51713', svg_color_name: "rd" },
        12: { label: 'SEAT', shortLabel: 'SEAT_ST', color: '#1E9CDE', svg_color_name: "bl" },
        13: { label: 'NORMAL', shortLabel: 'NORMAL_ST', color: '#239B12', svg_color_name: "gn" },
        14: { label: 'DIRECT', shortLabel: 'DIRECT_ST', color: '#E51713', svg_color_name: "rd" },
        15: { label: 'NORMAL', shortLabel: 'NORMAL_ST', color: '#239B12', svg_color_name: "gn" },
        16: { label: 'DIRECT', shortLabel: 'DIRECT_ST', color: '#E51713', svg_color_name: "rd" },
        21: { label: 'DIRECT', shortLabel: 'DIRECT_ST', color: '#E51713', svg_color_name: "rd" },
        22: { label: 'NORMAL', shortLabel: 'NORMAL_ST', color: '#239B12', svg_color_name: "gn" },
        23: { label: 'NORMAL', shortLabel: 'NORMAL_ST', color: '#239B12', svg_color_name: "gn" },
        30: { label: 'TOWN', shortLabel: 'TOWN_ST', color: '#FFAB00', svg_color_name: "yl" },
        41: { label: 'OUT', shortLabel: 'OUT_ST', color: '#6E00FF', svg_color_name: "pp" },
        42: { label: 'OUT', shortLabel: 'OUT_ST', color: '#6E00FF', svg_color_name: "pp" },
        43: { label: 'OUT', shortLabel: 'OUT_ST', color: '#6E00FF', svg_color_name: "pp" },
        51: { label: 'AIRPORT', shortLabel: 'AIRPORT_ST', color: '#6D6D6D', svg_color_name: "gy" },
        52: { label: 'AIRPORT', shortLabel: 'AIRPORT_ST', color: '#6D6D6D', svg_color_name: "gy" },
        53: { label: 'AIRPORT', shortLabel: 'AIRPORT_ST', color: '#6D6D6D', svg_color_name: "gy" },
        // ROUTE_TYPE: {
            // _def: { label: '일반', shortLabel: '일반', color: '#239B12', svg_color_name: "gn" },
            // 0: { label: '일반', shortLabel: '일반', color: '#239B12', svg_color_name: "gn" },
            // 11: { label: '직행좌석', shortLabel: '직좌', color: '#E51713', svg_color_name: "rd" },
            // 12: { label: '좌석', shortLabel: '좌석', color: '#1E9CDE', svg_color_name: "bl" },
            // 13: { label: '일반', shortLabel: '일반', color: '#239B12', svg_color_name: "gn" },
            // 14: { label: '직행좌석', shortLabel: '직좌', color: '#E51713', svg_color_name: "rd" },
            // 15: { label: '일반', shortLabel: '일반', color: '#239B12', svg_color_name: "gn" },
            // 16: { label: '직행좌석', shortLabel: '직좌', color: '#E51713', svg_color_name: "rd" },
            // 21: { label: '직행좌석', shortLabel: '직좌', color: '#E51713', svg_color_name: "rd" },
            // 22: { label: '일반', shortLabel: '일반', color: '#239B12', svg_color_name: "gn" },
            // 23: { label: '일반', shortLabel: '일반', color: '#239B12', svg_color_name: "gn" },
            // 30: { label: '마을', shortLabel: '마을', color: '#FFAB00', svg_color_name: "yl" },
            // 41: { label: '시외', shortLabel: '시외', color: '#6E00FF', svg_color_name: "pp" },
            // 42: { label: '시외', shortLabel: '시외', color: '#6E00FF', svg_color_name: "pp" },
            // 43: { label: '시외', shortLabel: '시외', color: '#6E00FF', svg_color_name: "pp" },
            // 51: { label: '공항', shortLabel: '공항', color: '#6D6D6D', svg_color_name: "gy" },
            // 52: { label: '공항', shortLabel: '공항', color: '#6D6D6D', svg_color_name: "gy" },
            // 53: { label: '공항', shortLabel: '공항', color: '#6D6D6D', svg_color_name: "gy" },
    },

    // 정류소에 표시하는 노선그룹 유형
    ROUTE_GROUPS: [
        { order: 1, title: "NORMAL", routeTypes: ["12", "23"], _isDef: true },
        { order: 2, title: "DIRECT", routeTypes: ["11", "14", "16", "21", "22"] },
        { order: 3, title: "SEAT", routeTypes: ["12"] },
        { order: 4, title: "TOWN", routeTypes: ["30"] },
        { order: 5, title: "OUT", routeTypes: ["41", "42", "43"] },
        { order: 6, title: "AIRPORT", routeTypes: ["51", "52", "53"] },        
        // { order: 1, title: "일반형", routeTypes: ["12", "23"], _isDef: true },
        // { order: 2, title: "직행좌석형", routeTypes: ["11", "14", "16", "21", "22"] },
        // { order: 3, title: "좌석형", routeTypes: ["12"] },
        // { order: 4, title: "마을형", routeTypes: ["30"] },
        // { order: 5, title: "시외형", routeTypes: ["41", "42", "43"] },
        // { order: 6, title: "공항형", routeTypes: ["51", "52", "53"] },        
    ],
    BUS_TYPE: {
        _def: { label: 'NORMAL', svg_name: 'I_bus_gen' },
        0: { label: 'NORMAL', svg_name: 'I_bus_gen' },
        1: { label: 'LOW', svg_name: 'I_bus_low' },
        2: { label: 'TWO', svg_name: 'I_bus_dbl' },
        7: { label: 'TROLY', svg_name: 'I_bus_tro' },
        5: { label: 'JEONSE', svg_name: 'I_bus_jeonse' },
        // _def: { label: '일반', svg_name: 'I_bus_gen' },
        // 0: { label: '일반', svg_name: 'I_bus_gen' },
        // 1: { label: '저상', svg_name: 'I_bus_low' },
        // 2: { label: '2층', svg_name: 'I_bus_dbl' },
        // 7: { label: '트롤리', svg_name: 'I_bus_tro' },
        // 5: { label: '전세', svg_name: 'I_bus_jeonse' },        
    },
};


const _textExist = (value) => {
    return JsUtil.isProp(value, "_text")
}

const GetGBusAttrib = (prop, value, attrib) => {
    let obj = JsUtil.getObjectProp(GBusConst, prop);
    if (obj) {
        if (JsUtil.isProp(obj, value)) {
            if (JsUtil.isProp(obj[value], attrib)) {
                return obj[value][attrib];
            }
            else {
                if (AppSetting.DEBUGS.code)
                    Bug('GetGBusAttrib', `GBus attrib '${prop}.${value} has not "${attrib}" property`, 9);
                return attrib;
            }
        }
        else {
            if (AppSetting.DEBUGS.code)
                Bug('GetGBusAttrib', `GBus attrib '${prop}.${value ? value : "undefined"}' not defined.`, 9);
            if (JsUtil.isProp(obj, '_def')) {
                return obj['_def'][attrib];
            }
            else {
                Bug('GetGBusAttrib', `GBus attrib '${prop}' has not default value.`, 9);
                return value;
            }
        }
    }
    else {
        Bug('GetGBusAttrib', `GBus attrib ${prop} not defined`, 9);
        return value;
    }
}

//--- 노선유형 표시명, 색상
export const GetRouteTypeLabel = (routeType = 0) => GetGBusAttrib('ROUTE_TYPE', routeType, 'label') === '' ? '' : GetText("pageTabLabel", GetGBusAttrib('ROUTE_TYPE', routeType, 'label'));
//  === '' ? '' :GetText("pageTabLabel", GetGBusAttrib('ROUTE_TYPE', routeType, 'label'));
// export const GetRouteTypeShortLabel = (routeType = 0) => GetGBusAttrib('ROUTE_TYPE', routeType, 'shortLabel') ;
export const GetRouteTypeShortLabel = (routeType = 0) => GetGBusAttrib('ROUTE_TYPE', routeType, 'shortLabel') === '' ? '' :GetText("pageTabLabel", GetGBusAttrib('ROUTE_TYPE', routeType, 'shortLabel'));
export const GetRouteTypeColor = (routeType = 0) => GetGBusAttrib('ROUTE_TYPE', routeType, 'color');
//--- 혼잡도 표시명 - [?=0|1|2|3|4]
export const GetDensityLabel = (density = 0) => GetGBusAttrib('DENSITY', density, 'label') === '' ? '' : GetText("PAGEWORD", GetGBusAttrib('DENSITY', density, 'label'));
// export const GetDensityLabel = (density = 0) => GetGBusAttrib('DENSITY', density, 'label');
export const GetDensityColor = (density = 0) => GetGBusAttrib('DENSITY', density, 'color');
//--- 버스유형 표시명
export const GetBusTypeLabel = (busType = 0) => GetGBusAttrib('BUS_TYPE', busType, 'label') === '' ? '' : GetText("pageTabLabel", GetGBusAttrib('BUS_TYPE', busType, 'label'));
//--- 노선의 정류소 이름에서 정차여부를 판독하고, 미정차 정류소 이름을 적절히 변용해서 리턴
export const GetRouteStationStopStatus = (stationName) => {
    if (stationName.includes("(경유)")) {
        return { isStopStation: false, displayName: stationName.replace("(경유)", "(미정차)") }
    }
    else {
        return { isStopStation: true, displayName: stationName }
    }
}

//--- (버스유형별+노선유형) 정해진 svg 아이콘 이름 리턴.  태글리스 추출 로직 추가 (20211208)
export const GetBusSvgName = (routeType, lowPlate, dup, taglessCd) => {
    console.log("[GBus] taglessCd", taglessCd)
    let iconName = GetGBusAttrib('BUS_TYPE', lowPlate, 'svg_name')
    let colorName = GetGBusAttrib('ROUTE_TYPE', routeType, 'svg_color_name')
    // 광역버스(red)만 중복 표시 => 광역버스 예약 지원
    let dupName = dup && colorName == "rd" ? "_dup" : ""
    let taglessNm = taglessCd && taglessCd==='1' ? '_tagless' : '';
    // let taglessNm = taglessCd && tagless==='1' ? '' : '_tagless'; // test
    return `${iconName}_${colorName}${dupName}${taglessNm}`
}

//--- 노선유형별 정해진 svg 아이콘 이름 리턴. 일반버스를 기준으로 구성함
export const GetBusSvgNameColorOnly = (routeType) => {
    let iconName = GetGBusAttrib('BUS_TYPE', 0, 'svg_name')  // 일반
    let colorName = GetGBusAttrib('ROUTE_TYPE', routeType, 'svg_color_name')
    return `${iconName}_${colorName}`
}

//--- 정류소의 mobileNO 를 리턴
export const GetStationMobileNo = (station, suffix = "") => {
    if (station) {        
        let no = JsUtil.getStringProp(station.mobileNoSi, "_text") || JsUtil.getStringProp(station, "mobileNoSi")
        if (no === "0") no = "";
        if (!no) {
            no = JsUtil.getStringProp(station.mobileNo, "_text") || JsUtil.getStringProp(station, "mobileNo")
        }
        if (no === "0") no = "";
        if (no) {
            if (no.length < 5) no = no.padStart(5, '0');
            return no + suffix;
        }
    }
    return "";
}

//--- 정류소 엔터티의 Distance 를 적절히 포맷
export const GetStationDistance = (station, suffix = "") => {
    let d = JsUtil.getNumericProp(station.distance, "_text") || JsUtil.getNumericProp(station, "distance");
    if (JsUtil.isNumeric(d)) {
        d = parseInt(d);
        if (d > 1000) {
            return Number(d / 1000).toFixed(1) + "km" + suffix
        }
        else {
            return d + "m" + suffix
        }
    }
    else {
        return ""
    }
}

//--- 정류소 엔터티의 중앙차로 여부
export const GetStationCenterName = (station, suffix = "") => {
    return (JsUtil.getStringProp(station.centerYn, "_text") || JsUtil.getStringProp(station, "centerYn")) === "Y" ? "중앙차로" + suffix : ""
}

//--- 주변정류소 detail 
export const GetAroundStationDetail = (item) => {
    let res = [];
    // distance
    res[0] = GetStationDistance(item);
    // mobileNO
    res[1] = GetStationMobileNo(item);
    // regionName
    res[2] = GetTextSido(item.regionName);
    // filter non empty items
    return res
        .filter(elem => elem)
        .join(" | ");
}

//--- 정류소 detail 
export const GetStationDetail =(item, userMemo = "") => {
    let res = [];
    res[0] = userMemo;
    res[1] = GetStationMobileNo(item);
    res[2] = GetTextSido(JsUtil.getStringProp(item.regionName, "_text")) || GetTextSido(JsUtil.getStringProp(item, "regionName"));
    return res
        .filter(elem => elem)
        .join(" | ");
}





















export const ParenthesesToCR = (str) => {
    let arr = [];
    let idx = str.indexOf("(");
    if (idx > 0) {
        arr = str.split("(");
        arr = arr.map((elem, index) => index > 0 ? "(" + elem : elem)
        return arr.join("\n");
    }
    return str;
}


/** Cut */
String.prototype.cut = function (len) {
    var str = this;
    var l = 0;
    for (var i = 0; i < str.length; i++) {
        l += (str.charCodeAt(i) > 128) ? 2 : 1;
        if (l > len)
            return str.substring(0, i);
    }
    return str;
}


String.prototype.cutThen = function (len) {
    var str = this;
    var l = 0;
    for (var i = 0; i < str.length; i++) {
        l += (str.charCodeAt(i) > 128) ? 2 : 1;
        if (l > len)
            return str.substring(i);
        //return str.substring(0, i); 
    }
    return "";
}

/** 캐릭터 갯수 구하는 함수 * string.charLen()로 사용 * */
String.prototype.charLen = function () {
    var str = this;
    var len = 0;
    for (var i = 0; i < str.length; i++) {
        len += (str.charCodeAt(i) > 128) ? 2 : 1;
    }
    return len;
}

// return { routeName: , lines}
// 20-3(쌍쏭.대광A) 20-3(당성왕모대) 공영4(삼존2.3리)
export const ParseRouteName = (routeName) => {
    let lines = [];
    let textLen = JsUtil.getTextLength(routeName);
    if (textLen <= 7) {
        return { name: routeName, lines: [routeName], maxLength: textLen}
    }
    else {
        
        // 000(xxxx) -> 두줄로 나눕니다.
        if (routeName.includes("(")) {
            lines = routeName.split("(");
            for(let i=1; i<lines.length; i++) {
                lines[i] = "(" + lines[i];
            }
            return { name: routeName, lines: lines, maxLength: JsUtil.getMaxTextLengthOf(lines)}
        }
        else {
            // 000한글명
            let hangul_idx = JsUtil.getFirstHanCharIndex(routeName);
            if (hangul_idx > 1) {
                lines[0] = routeName.substring(0, hangul_idx)
                lines[1] = routeName.substring(hangul_idx)
                return { name: routeName, lines: lines, maxLength: JsUtil.getMaxTextLengthOf(lines) }
            }
            return { name: routeName, lines: [routeName], maxLength: textLen }
        }
    }
}

export function IsRemainSeatSupportRoute(routeArrival) {
    const excludeRouteIds = "165000215,216000044,200000228,200000229,218000005,219000025,233000129,100100389,100100390,100100391,100100392,100100397,100100398,100100400,100100402,100100406,100100559,100100560,100100577,100100607";
    // 경기도 노선 & 특정 노선 유형 && 하드코딩된 노선 제외
    return JsUtil.GText(routeArrival, "routeId") &&
           JsUtil.GText(routeArrival, "routeId").startsWith("2") && 
           routeArrival.routeTypeCd && ["11", "12", "14", "16", "21"].includes(JsUtil.GText(routeArrival, "routeTypeCd")) &&
           !excludeRouteIds.includes(JsUtil.GText(routeArrival, "routeId"))
}

export function IsDensitySupportRoute(routeArrival) {
    return JsUtil.GText(routeArrival, "routeId") &&
           JsUtil.GText(routeArrival, "routeId").startsWith("2") && 
           routeArrival.routeTypeCd && ["13", "15", "22", "23"].includes(JsUtil.GText(routeArrival, "routeTypeCd"));
}




export function BuildBusArrival(routeArrival, busSeq) {
    // console.log("------------ BuildBusArrival: ")
    // console.log(routeArrival);
    return {
        source: { _text: "routeArrival" },
        flag: { _text: routeArrival.flag._text },
        // 
        stationId: { _text: routeArrival.stationId._text },
        // stationName: { _text: routeArrival.stationName._text },
        routeTypeCd: { _text: routeArrival.routeTypeCd._text },
        routeId: { _text: routeArrival.routeId._text },
        routeName: { _text: routeArrival.routeName._text },
        staOrder: { _text: routeArrival.staOrder._text },
        stationSeq: { _text: routeArrival.staOrder._text },
        //
        locationNo: { _text: routeArrival["locationNo" + busSeq]._text },
        predictTime: { _text: routeArrival["predictTime" + busSeq]._text },
        delayYn: { _text: routeArrival["delayYn" + busSeq]._text },
        lowPlate: { _text: routeArrival["lowPlate" + busSeq]._text },
        plateNo: { _text: routeArrival["plateNo" + busSeq]._text },
        vehId: { _text: routeArrival["vehId" + busSeq]._text },
        remainSeatCnt: { _text: routeArrival["remainSeatCnt" + busSeq]._text },
        density: { _text: routeArrival["density" + busSeq]._text }
    }
}

export function BuildBusArrivals(routeArrival) {
    let arr = []
    if (routeArrival.flag._text !== 'STOP') {
        if (routeArrival.locationNo1 && routeArrival.locationNo1._text) {
            arr.push(BuildBusArrival(routeArrival, "1"))
        }
        if (routeArrival.locationNo2 && routeArrival.locationNo2._text) {
            arr.push(BuildBusArrival(routeArrival, "2"))
        }
    }
    return arr;
}

export function BuildBusArrivalFromRunningBus(routeArrival, runningBus) {
    // console.log("------------ BuildBusArrivalFromRunningBus: ")
    // console.log(routeArrival);
    return {
        source: { _text: "runningBus" },
        flag: { _text: routeArrival.flag._text },
        //
        stationId: { _text: routeArrival.stationId._text },
        // stationName: { _text: routeArrival.stationName._text },
        routeTypeCd: { _text: routeArrival.routeTypeCd._text },
        routeId: { _text: routeArrival.routeId._text },
        routeName: { _text: routeArrival.routeName._text },
        staOrder: { _text: routeArrival.staOrder._text },
        stationSeq: { _text: routeArrival.staOrder._text },
        //
        locationNo: { _text: parseInt(routeArrival.staOrder._text) - parseInt(runningBus.stationSeq._text) },
        predictTime: { _text: "" },
        delayYn: { _text: "" },
        lowPlate: { _text: runningBus.lowPlate._text },
        plateNo: { _text: runningBus.plateNo._text },
        vehId: { _text: runningBus.vehId && runningBus.vehId._text ? runningBus.vehId._text : "" },
        remainSeatCnt: { _text: runningBus.remainSeatCnt._text },
        density: { _text: runningBus.density._text }
    }
}

// 노선 도착예정 버스중 저상버스 No 추출
export function GetLowBusSeqs(routeArrival) {
    let lowBusSeqs = [];
    if (routeArrival.locationNo1 && routeArrival.locationNo1._text && routeArrival.lowPlate1._text === "1") {
        lowBusSeqs.push("1")
    }
    if (routeArrival.locationNo2 && routeArrival.locationNo2._text && routeArrival.lowPlate2._text === "1") {
        lowBusSeqs.push("2")
    }
    return lowBusSeqs;
}

export function GetLowBusCount(arrivals) {
    let count = 0;
    if (Array.isArray(arrivals)) {
        arrivals.forEach(elem => {
            if (elem.lowPlate && elem.lowPlate._text === '1') count++
        })
        return count;
    }
    else {
        return GetLowBusSeqs(arrivals).length;
    }
}

// 운행버스중에서 저상버스 추출
export function GetPriorLowBusList(runnigBus, routeArrival, wantedCount) {
    // 저상 + 이전 정류소 위치
    try {
        let found = 0;
        let arr = runnigBus.filter(elem => {
            // console.log("--------filter:")
            // console.log(elem)
            if (found >= wantedCount) return false;
            let matched = elem.lowPlate._text === "1" &&
                parseInt(elem.stationSeq._text) < parseInt(routeArrival.staOrder._text) &&
                elem.vehId._text !== routeArrival.vehId1._text &&
                elem.vehId._text !== routeArrival.vehId2._text;
            if (matched) {
                found++;
                return matched;
            }
            return false
        });
        // 현재 정류소에 가까운 순으로 정렬
        if (arr.length > 1) {
            arr.sort((a, b) => parseInt(b.stationSeq._text) - parseInt(a.stationSeq._text));
        }
        return arr;
    }
    catch (err) {
        console.log("버스위치정보 오류..");
        console.log(err);
        return [];
    }
}

export function GetAvailableBusArrivals(routeArrival, runningBusList) {
    if (!runningBusList || runningBusList.length < 1) {
        return BuildBusArrivals(routeArrival) 
    }
    let nearlowBusSeqs = GetLowBusSeqs(routeArrival);
    // 근거리 2대가 저상
    if (nearlowBusSeqs.length > 1) {
        return BuildBusArrivals(routeArrival)
    }
    let arrivals = []
    // 원거리 운행중인 버스에서 저상버스 추출
    farLowBusList = GetPriorLowBusList(
        runningBusList,
        routeArrival,
        2 - nearlowBusSeqs.length);

    // 원거리 버스에 저상버스 없음
    if (farLowBusList.length < 1) {
        return BuildBusArrivals(routeArrival)
    }
    // 원거리 버스에 저상버스 2대 존재
    else if (farLowBusList.length > 1) {
        farLowBusList.forEach(elem => {
            arrivals.push(BuildBusArrivalFromRunningBus(routeArrival, elem));
        })
        return arrivals;
    }
    // 원거리 버스에 저상버스 1대 존재. 근거리 버스에는 존재하거나 안할 수 있음.
    else {
        // 근거리버스 중 저상이 있으면, 먼저 채움
        if (nearlowBusSeqs.length > 0) {
            arrivals.push(BuildBusArrival(routeArrival, nearlowBusSeqs[0]))
        }
        // 근거리버스 중 일반버스를 먼저 채움. 먼저 도착예정이므로
        else {
            arrivals.push(BuildBusArrival(routeArrival, '1'))
        }
        arrivals.push(BuildBusArrivalFromRunningBus(routeArrival, farLowBusList[0]));
        return arrivals;
    }
}


export function getDistanceFromLatLonInKm(x1, y1, x2, y2) {
    function deg2rad(deg) {
        return deg * (Math.PI / 180)
    }
    var R = 6371;                   // Radius of the earth in km
    var dLat = deg2rad(y2 - y1);      // deg2rad below
    var dLon = deg2rad(x2 - x1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(y1)) * Math.cos(deg2rad(y2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

export function getDistanceFromStationToMeter(station, x, y, def = 99999999) {
    if (!station || !_textExist(station.x) || !_textExist(station.y)) {
        return def;
    }
    let km = getDistanceFromLatLonInKm(
        parseFloat(station.x._text),
        parseFloat(station.y._text),
        x,
        y);
    return Math.round(km * 1000);
}

export function isBusNotifyAllowedRoute(routeId) {
    if (AppSetting.BUS_NOTIFY_ALLOWED_ROUTES && Array.isArray(AppSetting.BUS_NOTIFY_ALLOWED_ROUTES)) {
        return AppSetting.BUS_NOTIFY_ALLOWED_ROUTES.find(elem => elem === "*" || elem === routeId)
    }
    return false;
}

export function showBusNotifyFunction(routeId) {
    if (routeId) {
        return (AppSetting.RIDE_SUPPORT.VISIBLE.toUpperCase() === "ALL" ||
            (AppSetting.RIDE_SUPPORT.VISIBLE.toUpperCase() === "ALLOWED" && isBusNotifyAllowedRoute(routeId)))
    }
    else {
        return AppSetting.RIDE_SUPPORT.VISIBLE.toUpperCase() !== "NONE"
    }
}

export function isOffAlarmAllowedRoute(routeId) {
    if (AppSetting.OFF_ALARM_ALLOWED_ROUTES && Array.isArray(AppSetting.OFF_ALARM_ALLOWED_ROUTES)) {
        return AppSetting.OFF_ALARM_ALLOWED_ROUTES.find(elem => elem === "*" || elem === routeId)
    }
    return false;
}

export function showOffAlarmFunction(routeId) {
    return (AppSetting.RIDE_SUPPORT.VISIBLE.toUpperCase() === "ALL" ||
           (AppSetting.RIDE_SUPPORT.VISIBLE.toUpperCase() === "ALLOWED" && isOffAlarmAllowedRoute(routeId)))
}

export function canRequestBusNotify(userPosition, station, route, vehId, plateNo, prevNth) {
    if (!isBusNotifyAllowedRoute(JsUtil.GText(route, "routeId"))) {
        return { can: false, msg: GetText("ridesupport", "NOT_ALLOWED_ROUTE") };
    }
    // GPS OK ?
    if (!userPosition) {
        console.log(`[busNotify] canRequestBusNotify(). userPosition is null`)
        return { can: false, msg: GetText("gps", "plz_allow_permission") };
    }
    if (!station) {
        return { can: false, msg: GetText("ridesupport", "LOCAL_BUSY") };
    }
    // 내 위치가 정류소 주변인지 검사
    let m = getDistanceFromStationToMeter(station,
        userPosition.coords.longitude,
        userPosition.coords.latitude)
    if (m > AppSetting.RIDE_SUPPORT.BUSNOTIFY_ALLOWED_DISTANCE_FROM_STATION) {
        console.log(`[busNotify] canRequestBusNotify(). distance=${m}`)
        //return { can: false, msg: `${m}m !!! \n${GetText("ridesupport", "TOO_FAR_FROM_STATION")}`};
        return { can: false, msg: GetText("ridesupport", "TOO_FAR_FROM_STATION")};
    }
    // 해당 버스가 허용 거리 이상인지
    if (prevNth < AppSetting.RIDE_SUPPORT.BUSNOTIFY_ALLOWED_STATION_OFFSET) {
        return { can: false, msg: GetText("ridesupport", "TOO_CLOSE_BUS") };
    }
    return { can: true, msg: "" };;
}

export function canSetOnAlarm(userPosition, station, vehId, prevNth) {
    if (!isBusNotifyAllowedRoute(JsUtil.GText(route, "routeId"))) {
        return { can: false, msg: GetText("ridesupport", "NOT_ALLOWED_ROUTE") };
    }

    // GPS OK ?
    if (!userPosition) {
        console.log(`[onAlarm] canSetOnAlarm(). userPosition is null`)
        return { can: false, msg: GetText("gps", "plz_allow_permission") };
    }
    if (!station) {
        return { can: false, msg: GetText("ridesupport", "LOCAL_BUSY") };
    }
    // 내 위치가 정류소 주변인지 검사
    let m = getDistanceFromStationToMeter(station,
        userPosition.coords.longitude,
        userPosition.coords.latitude)
    if (m > AppSetting.RIDE_SUPPORT.BUSNOTIFY_ALLOWED_DISTANCE_FROM_STATION) {
        console.log(`[onAlarm] canSetOnAlarm(). distance=${m}`)
        return { can: false, msg: GetText("ridesupport", "TOO_FAR_FROM_STATION") };
    }
    // 해당 버스가 허용 거리 이상인지
    if (prevNth < AppSetting.RIDE_SUPPORT.BUSNOTIFY_ALLOWED_STATION_OFFSET) {
        return { can: false, msg: GetText("ridesupport", "TOO_CLOSE_BUS") };
    }
    return { can: true, msg: "" };;
}

export function canSetOffAlarm(userPosition, station, route) {
    if (!isOffAlarmAllowedRoute(JsUtil.GText(route, "routeId"))) {
        return { can: false, msg: GetText("ridesupport", "NOT_ALLOWED_ROUTE") };
    }
    // GPS OK ?
    if (!userPosition) {
        console.log(`[offAlarm] canSetOffAlarm(). userPosition is null`)
        return { can: false, msg: GetText("gps", "plz_allow_permission") };
    }
    if (!station) {
        return { can: false, msg: GetText("ridesupport", "LOCAL_BUSY") };
    }
    // 내 위치가 정류소 주변인지 검사
    let m = getDistanceFromStationToMeter(station,
        userPosition.coords.longitude,
        userPosition.coords.latitude)
    if (m > AppSetting.RIDE_SUPPORT.OFFALARM_DETECT_DISTANCE_FROM_STATION) {
        console.log(`[offAlarm] canSetOffAlarm(). distance=${m}`)
        return { can: false, msg: GetText("ridesupport", "TOO_FAR_FROM_STATION") };
    }
    return { can: true, msg: "" };;
}

export function proveRunningBusForOffAlarm(userPosition, routeStations, runningBusList) {
    // 버스위치 -> 정류소 판독 -> 사용자 거리 계산
    let busDistanceList = runningBusList.map(busLocation => {
        // 버스가 위치한 정류소
        let busSta = routeStations.find(sta => 
            JsUtil.GText(sta.stationId) === JsUtil.GText(busLocation.stationId) &&
            JsUtil.GInt(sta.stationSeq) === JsUtil.GInt(busLocation.stationSeq))                
        if (busSta) {
            busLocation.distanceFromUser = getDistanceFromStationToMeter(busSta,
                userPosition.coords.longitude,
                userPosition.coords.latitude)
        }
        return busLocation;
    })
    return busDistanceList;
}


export function canDeclareNonStopBusInBetaService(routeId, recentNonStopDeclare) { 
    return GBus.isBusNotifyAllowedRoute(routeId) && 
           // 최근 신고로부터 10분 이상 경과
           (recentNonStopDeclare && 
            DateUtil.GetElapsedMins(recentNonStopDeclare.time) > AppSetting.NONSTOP_DECLARE.PREVENT_FUNNY_TRIAL_MINS);
}            


// 지금이 버스 운행시간인지 판독
export function IsInBusRunTime(startTime, endTime) { // 시간 체크해서 운행시간이 아니면 true 운행중 시간이면 false;
    // DateUtil returns moment object
    if (startTime && endTime) {
        let s = DateUtil.toMoment(startTime).set({ year: 0, month: 0, date: 0 });
        let e = DateUtil.now(endTime).set({ year: 0, month: 0, date: 0 });
        let n = DateUtil.now().set({ year: 0, month: 0, date: 0 });
        // 운행종료시간이 시작시간보다 크면, start~end 사이에 있어야 함
        if (e.isAfter(s)) {
            return n.isBetween(s, e)
        }
        // 운행종료시간이 시작시간보다 작으면, end~start 사이에 있지 않아야 함
        else {
            return !n.isBetween(e, s);
        }
    }
    else {
        return true
    }
}

/**
 * 
 * @param {*} route 
 * @param {*} busArrival 
 * 
 * 노선 운행 상태 판독 (개별버스는, 노선운행상태를 판독하고 나서 추가로 판독해야 함)
 * [운행종료|회차지대기중|차고지대기중|운행중인버스 없음
 * 
 */

export function getRouteRunStatus(route, busArrival) {
    // 운행종료
    if (busArrival.flag === "STOP") {

    }



    // 회차지 대기중


}

// 노선의 도착정보(근접 2대)를 기반으로 버스 도착
export function buildRouteBusArrivals(route, routeArrival) {
    // 1. 노선이 운행상태인지 검사
    let routeRunStatus = getRouteRunStatus(route, routeArrival);

    // 1.1 운행종료인 경우. busArrival 회신
    return { busArrivla: "운행종료" }



}

/**
 * 
 * @param {*} route 
 * @param {*} busArrival 
 * 
 * 근접버스 2대의 개별 운행 상태 판독 (노선운행상태를 판독하고 나서 해야 함)
 * [운행종료|회차지대기중|차고지대기중|운행중인버스 없음 * 
 */

export function getBusRunStatus(route, busArrival) {
    // busArrival.flag === "WAIT" ||
    // busArrival.waitFlag === "1" || 
}

// // public static String getBusStatus(String flag, String delayYn, String waitFlag, String startTime, String endTime, int turnSeq, int staOrder, String garageFlag, String garageTurnFlag, int locationNo, boolean isSoonYn){ // 차고지대기중 추가
//     String status = null;
//     if(flag!=null && flag.equals("STOP")) status = "운행 종료되었습니다.";
//     else if( (flag!=null && (flag.equals("WAIT"))) ||
//             (delayYn!=null && delayYn.equals("Y")) ||
//             (waitFlag!=null && waitFlag.equals("1")) ||
//             checkBusTime(startTime, endTime)) {
//         if(staOrder > turnSeq) status = "회차지 대기중입니다.";
//         else status = "차고지 대기중입니다.";
//     }else{
//         if(staOrder-locationNo==1 && garageFlag.equals("Y")){ status = "차고지 대기중입니다."; }
//         else if(staOrder==turnSeq && garageTurnFlag.equals("Y")){ status = "회차지 대기중입니다."; }
//         else status = "운행 중인 버스가 없습니다.";
// //            status = "운행 중인 버스가 없습니다.";
//     }
//     if(isSoonYn){
//         status = "잠시 후 도착";
//     }
//     return status;
// }

// export function getDensitySupplyType(busArrival) {
//     let routeType = "";
//     let remainSeatCnt = -1;
//     if (busArrival && busArrival.routeTypeCd) {
//         if (typeof busArrival.routeTypeCd === "string" || busArrival.routeTypeCd instanceof String) {
//             routeType = busArrival.routeTypeCd;
//             if (isNaN(busArrival.remainSeatCnt)) {
//                 remainSeatCnt = parseInt(busArrival.remainSeatCnt)
//             }            
//         }
//         else if (busArrival.routeTypeCd && busArrival.routeTypeCd._text) {
//             routeType = busArrival.routeTypeCd._text;
//             if (isNaN(busArrival.remainSeatCnt._text)) {
//                 remainSeatCnt = parseInt(busArrival.remainSeatCnt._text)
//             }            
//         }
//     }
//     if (AppSetting.DENSITY.REMAIN_SEAT_SUPPORT_ROUTES.includes(rotueType)) {

//     }
//     DENSITY: {
//         REMAIN_SEAT_SUPPORT_ROUTES: ["11", "12", "14", "16", "21"],
//         DENSITY_SUPPORT_ROUTES: ["13", "15", "22", "23"]
//     }


//     else if ()


//     route.routeTypeCd._text ? route.routeTypeCd._text : "";
//     let remainSeatCnt = rotue && route.routeTypeCd && route.routeTypeCd._text ? route.routeTypeCd._text : "";


// }