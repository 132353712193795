import React from 'react';
import { BottomRightTabs } from './BottomTab';

export default function SearchBottomTab({ navigation, onBack }) {
    return (
        <BottomRightTabs 
            onBack={onBack}
            navigation={navigation}
        /> 
    )    
}

