import React, { useState, useEffect } from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle';
import {HeaderBackground} from '@react-navigation/stack';

export default function Refresh({onPress, bottom}) {
    return (
        <TouchableOpacity
            style={CommonStyle("reloadOverlayIconBg",  
                bottom ? { bottom: Layouts.bottomBarHeight + 15 + bottom } : null
            )}
            onPress={()=>{
                if(onPress) {
                    onPress()
                }
            }}
        >
            <View style={CommonStyle("reloadOverlayIconCircle")} >
                <FIcon type="fal" name="redo" size={26} color={Colors.mainTabColor} />
            </View>
        </TouchableOpacity>
    )
}

Refresh.defaultProps = {

}