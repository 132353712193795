import React from 'react';
import { StyleSheet, Text, View,TouchableOpacity } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

import FrStyle from '../../../styles/FrStyle';

const BusLaneBox = ({ selfColor, laneType }) => {
    return (
        <View style={SectionStyle('routeTypeBox', { borderColor: selfColor })}>
            <Text style={TextStyle('rowDetail', 'small')}>
                {TMap.GetTMapRouteLabel(laneType)}
            </Text>
        </View>
    )
}

export default function FrDetailDragHeader({ data, onPress, navigation, isOpened }) {    
    return (

        <View style={SectionStyle("rowForHCol", { backgroundColor: "transparent" })} >

        <TouchableOpacity accessible={true} 
                onPress={() => {
                    if(onPress) {
                        onPress()
                    }
                }}
                accessibilityLabel={"약 " + TMap.getTimeHMS(data.duration) + ", " + TMap.GetFriendlyDistance(data.distance) + ", " + (data.fare === '요금정보 미표기' ? "-" : "약" + TMap.GetAsMoney(data.fare) + "원") + ", "  + " 상세보기 " + (isOpened ? " 활성화됨 " : " 비활성화됨 ")} 
                accessibilityRole="button">                            
            <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingVertical: 5
            }}>
                <View style={{
                    height: 7, width: 50,
                    backgroundColor: Colors.ggBlue,
                    borderRadius: 5
                }} />
            </View>
            <View style={{
                //flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingHorizontal: 20,
                paddingVertical: 10,
                //backgroundColor: "#f00"
            }}>
                <Text style={TextStyle("rowTitleDesc", "medium")}>
                    약 {TMap.getTimeHMS(data.duration)} | {TMap.GetFriendlyDistance(data.distance)} | {data.fare === '요금정보 미표기' ? "-" : "약 " + TMap.GetAsMoney(data.fare)}
                </Text>
            </View>
        </TouchableOpacity>
        </View>
    )
}