import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TextInput, FlatList, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import * as FavorUtil from '../../utils/FavorUtil';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import FIcon from '../../defines/FIcon';
import { Colors, Layouts } from '../../defines/Theme';
// 
import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import ListEmpty from '../components/parts/ListEmpty';
import Texts, {GetText} from '../../defines/Texts';
//
import StationRow from './include/StationRow';
let UserScriptApi = require('../../utils/UserScriptApi');
export default class AroundList extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            stations: [],
            favorStationIds: []
        }
    }

    componentDidMount() {
        UserScriptApi.apiConnect('around')
        console.log(`[${this.constructor.name}] mount`)
        this.setState({ stations: this.props.route.params.data });
        this.syncGlobalToState();
    }

    syncGlobalToState() {
        this.setState({favorStationIds: FavorUtil.GetFavorStationIds()})
    }    

    render() {
        return (
            <SafeAreaView style={ CommonStyle('bg') }>
                <ScreenHeader
                   title={GetText("pageTitle","AROUND")}
                />
                <View style={CommonStyle('listBg', { backgroundColor: Colors.listGroupColor})}>
                    <FlatList
                        removeClippedSubviews={true}
                        initialNumToRender={20}
                        maxToRenderPerBatch={100}
                        legacyImplementation={true}
                        windowSize={50}
                        // data={this.props.route.params.data}
                        data={this.state.stations}
                        ListEmptyComponent={<ListEmpty text={GetText("pageComment","NONE_AROUND_STATION")} />}  
                        renderItem={({ item, index, separators }) => {
                            return <StationRow
                                index={index}
                                item={item}
                                onPress={()=>{
                                    this.props.navigation.navigate('stationInfo', {
                                        data: {
                                            stationId: { _text: item.stationId },
                                            stationName: { _text: item.stationName },
                                            x: { _text: item.x },
                                            y: { _text: item.y }
                                        }
                                    })
                                }}
                                inFavor={this.state.favorStationIds.includes(item.stationId)}
                                onFavorite={() => {
                                    if (this.state.favorStationIds.includes(item.stationId)) {
                                        FavorUtil.RemoveFavorStation(item.stationId,
                                            (done) => { done && this.syncGlobalToState() }
                                        )
                                    }
                                    else {
                                        FavorUtil.AddFavorStation({
                                            "centerYn": { "_text": item.centerYn },
                                            "districtCd": { "_text": item.districtCd },
                                            "mobileNo": { "_text": item.mobileNo ? item.mobileNo : "" },
                                            "mobileNoSi": { "_text": item.mobileNoSi ? item.mobileNoSi : "" },
                                            "regionName": { "_text": item.regionName },
                                            "stationId": { "_text": item.stationId },
                                            "stationName": { "_text": item.stationName },
                                            "x": { "_text": item.x },
                                            "y": { "_text": item.y },
                                        },
                                        (done) => { done && this.syncGlobalToState() })
                                    }
                                }}
                            />
                        }}
                        refreshing={false}
                        keyExtractor={(item, index) => index.toString()}
                    />
                </View>
                <BottomRightTabs navigation={this.props.navigation} />  
            </SafeAreaView>
        )
    }
}

