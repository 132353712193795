// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Images from '../defines/Images';
import Bug from '../utils/Bug';

export const styles = {
    _def: {
        backgroundColor: Colors.appBackgroundColor,
    },
    vertSpacerMini: {
        height: 5,
    },
    vertSpacer: {
        height: 10,
    },
    vertSpacerListBottom: {
        height: Layouts.rowHeight * 2,
        backgroundColor: Colors.appBackgroundColor,
    },
    horzSpacer: {
        width: 10,
    },
    box: {
        backgroundColor: "transparent",
    },
    boxFill: {
        flex: 1,
        backgroundColor: "transparent",
    },
    boxForRow: {
        flexDirection: "row",
        backgroundColor: "transparent",
    },
    boxBorder: {
        backgroundColor: "transparent",
        borderColor: Colors.borderLightColor,
        borderWidth: Layouts.rowBorderWidth,
    },    
    boxTopBorder: {
        backgroundColor: "transparent",
        borderColor: Colors.borderLightColor,
        borderTopWidth: Layouts.rowBorderWidth,
    },    
    boxBottomBorder: {
        backgroundColor: "transparent",
        borderColor: Colors.borderLightColor,
        borderBottomWidth: Layouts.rowBorderWidth,
    },    
    boxRoundedBorder: {
        backgroundColor: "transparent",
        borderColor: Colors.borderLightColor,
        borderWidth: Layouts.rowBorderWidth,
        borderRadius: 5, 
    },     

    labelRoundedHeaderBg: {
        backgroundColor: "transparent",
        borderColor: Colors.borderLightColor,
        borderWidth: Layouts.rowBorderWidth,
        borderRadius: 10,
//        borderRadius: 10,
        justifyContent: "center",  
        alignItems: "center", 
        paddingVertical: 3,
        paddingHorizontal: 5,
        borderColor: Colors.silver,
        backgroundColor: Colors.appBackgroundColor,
    },
    
    bg: {
        flex: 1,
    },
    bgForCenter: {
        flex: 1,
        justifyContent: "center",
        alignItems: "stretch",
    },
    headerContainer: {
        height: Layouts.headerBarHeight,
        backgroundColor: Colors.appBackgroundColor,
        padding: 0,
        paddingTop: 0,
        alignItems: 'center',
        // paddingLeft: 0,
        // paddingRight: 0
        // backgroundColor: "#0ff",
    },
    headerContainerWithSlogan: {
        height: Layouts.headerBarHeight,
        backgroundColor: Colors.appBackgroundColor,
        margin: 0,
        padding: 0,
        paddingTop: 0,
        alignItems: 'center',
        // paddingLeft: 0,
        // paddingRight: 0
        // backgroundColor: "#0ff",
    },
    headerButtonBg: {
        height: 35,
        width: 35,
        justifyContent: "center",
        alignItems: "center",
    },

    body: {
        flex: 1,
        marginBottom: Layouts.bottomBarHeight, 
        backgroundColor: Colors.appBackgroundColor,
    },
    listBg: {
        // backgroundColor: Colors.listBackgroundColor,
        backgroundColor: Colors.appBackgroundColor,
    },
    inputBg: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        // borderTopWidth: Layouts.rowBorderWidth,
        // borderColor: Colors.borderColor,
        // borderTopWidth: Layouts.rowBorderWidth,
        // borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: 5,
        // backgroundColor: Colors.appBackgroundColor,
        // backgroundColor: Colors.white,
        //        backgroundColor: "#f00",
    },

    // 우측에 버튼을 사용하는 경우


    inputClearButton: {
        position: "absolute",
        right: 15,
        top: 15,
    },

    reloadOverlayIconBg: {
        position: 'absolute',
        bottom: Layouts.bottomBarHeight + 15,
        right: 15,
        alignItems: "stretch",
        width: Layouts.reloadIconSize,
        height: Layouts.reloadIconSize,
    },    
    
    reloadOverlayIconBgToilet: {
        position: 'absolute',
        bottom: Layouts.bottomBarHeight + 15,
        right: 0,
        alignItems: "stretch",
        width: 30,
        height: 32,
        top: 10,
    },

    reloadOverlayIconCircle: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: Layouts.reloadIconSize / 2,
        backgroundColor: Colors.gsYellow, //  appBackgroundColor,
    },
    // alarmOverlayIconBg: {
    //     position: 'absolute',
    //     bottom: Layouts.bottomBarHeight + 15,
    //     right: 15,
    //     alignItems: "stretch",
    //     width: Layouts.reloadIconSize, 
    //     height: Layouts.reloadIconSize, 
    // },    
    // reloadOverlayIconCircle: {
    //     flex: 1,
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     borderRadius: Layouts.reloadIconSize / 2,
    //     backgroundColor: Colors.gsYellow, //  appBackgroundColor,
    // },   

    searchBarBg: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Colors.inputBackgroundColor,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        marginVertical: 2,
        marginHorizontal: 10,
        padding: 3,
        borderRadius: 7,
        height: Layouts.rowInputHeight,
    },
    searchBarContainer: {
        flex: 1,
        flexDirection: "row",
        backgroundColor: "transparent",
        padding: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
    },
    searchBarInputContainer: {
        flex: 1,
        backgroundColor: Colors.inputBackgroundColor,
    },




    // left side "search"
    searchBarLeftIconContainer: {
        marginVertical: 0,
        marginHorizontal: 0,
        paddingHorizontal: 0
    },
    searchBarRightIconContainer: {
        marginVertical: 0,
    },
    searchBarLeftIconBg: {
        width: 35,
        alignItems: "center",
    },





    // inputRightButtonBg: {
    //     //flexDirection: 'row',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     paddingHorizontal: 10,
    // },
    // tab row
    tabSelectBg: {
        flex: 1,
        height: Layouts.tabSelectHeight,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        // paddingTop: 7,
        borderColor: Colors.borderColor,
        borderWidth: 0,
        borderBottomWidth: 0.5,
        //backgroundColor: Colors.appBackgroundColor,
        backgroundColor: Colors.inputBackgroundColor
    },
    tabSelectFullRowBg: {
        flex: 1,
        height: Layouts.tabSelectHeight,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        // paddingTop: 7,
        borderColor: Colors.borderColor,
        borderWidth: 0,
        borderBottomWidth: 0.5,
        //backgroundColor: Colors.appBackgroundColor,
        backgroundColor: Colors.inputBackgroundColor
    },
    tabSelectRightAreaBg: {
        height: Layouts.tabSelectHeight,
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: 7,
        // paddingTop: 7,
        borderColor: Colors.borderColor,
        borderWidth: 0,
        borderBottomWidth: 0.5,
        //backgroundColor: Colors.appBackgroundColor,
        backgroundColor: Colors.inputBackgroundColor
    },    
    tabSelectItemBg: {
        //        justifyContent: "flex-end",
        // alignItems: "stretch",
        minWidth: 70,
        //        paddingVertical: 7,
        //        paddingHorizontal: 15,        
    },
    tabSelectItemFullRowBg: {
        flex: 1,
        //        justifyContent: "flex-end",
        alignItems: "stretch",
        minWidth: 70,
        //        paddingVertical: 7,
        //        paddingHorizontal: 15,        
    },
    tabSelectIndicator: {
        height: Layouts.tabSelectIndicatorHeight,
    },
    tabSelectIndicatorActive: {
        height: Layouts.tabSelectIndicatorHeight,
        backgroundColor: Colors.ggBlue,
        borderRadius: 2
    },
    tabSelectLabelBg: {
        flex: 1,
        paddingHorizontal: 10,
        paddingBottom: 7,
        justifyContent: "flex-end",
        alignItems: "center",
    },

    // full row button
    rowFillButton: {
        padding: 0,
        opacity: 0.5,
        height: "100%",

    },

    // tabContainer: {
    //     flexDirection: 'row',
    //     justifyContent: 'flex-start',
    //     alignItems: 'center',
    //     paddingTop: 5,
    //     borderColor: Colors.borderColor,
    //     borderWidth: 1,
    //     backgroundColor: Colors.white
    // },
    // selTabView: {
    //     borderBottomColor: Colors.blue,
    //     borderBottomWidth: 3,
    //     paddingHorizontal: 15,
    //     paddingVertical: 7
    // },
    // tabView: {
    //     paddingVertical: 7,
    //     paddingHorizontal: 15,
    // },    
    // Comment Label
    // Flat List
    listGroup: {
        flexDirection: "row",
        // justifyContent: "center",
        alignItems: "center",
        paddingVertical: 5,
        paddingHorizontal: 10,
        backgroundColor: Colors.listGroupColor,
    },
    listHeaderBg: {
        flexDirection: "row",
        // justifyContent: "center",
        alignItems: "center",
        paddingVertical: 5,
        paddingHorizontal: 10,
        backgroundColor: Colors.listGroupColor,
        borderBottomWidth: 0.7, 
        borderColor: Colors.borderColor, 
        //backgroundColor: Colors.whiteLight
    },

    sectionHeaderBg: {
        flexDirection: "row",
        // justifyContent: "flex-start",
        alignItems: "center",
        paddingVertical: 5,
        paddingHorizontal: 10,
        backgroundColor: Colors.listGroupColor,
        borderBottomWidth: 0.7, 
        borderColor: Colors.borderColor, 
        // backgroundColor: Colors.whiteLight         
    },

    // Flat List
    boxHeaderBg: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 5,
        paddingHorizontal: 10,
        backgroundColor: Colors.listGroupColor,
    },

    boxContentBg: {
        paddingVertical: 10,
        paddingHorizontal: 20,
    },


    listEmptyBg: {
        flex: 1,
        padding: 15,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.appBackgroundColor
    },
 

    // absolute position background
    favorEmptyBgAbsoluteImage: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0.7,
        resizeMode: 'contain',
        //backgroundColor: "#00f"
    },
    // fill background
    favorEmptyActionsBg: {
        // height: "50%",
        paddingVertical: 20,
        paddingHorizontal: 30,
        alignItems: "center"
        //backgroundColor: "#f00",
    },
    // draw/menu styles
    drawMenuRow: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        borderColor: Colors.ggLightBlue,
        borderBottomWidth: Layouts.rowBorderWidth,
    },
    drawMenuIconBg: {
        ///marginHorizontal: 15,
        width: 50,
        justifyContent: 'center',
        alignItems: 'center',
    },
    drawMenuFiller: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    drawMenuProviderBg: {
        flex: 1,
        justifyContent: 'space-around',
        alignItems: "center",
    },
    drawMenuFooter: {
        justifyContent: 'center',
        height: Layouts.bottomBarHeight * 0.8,
    },
    drawMenuPhoneRow: {
        flex: 1,
        //position: 'absolute', bottom: 0, left: 0, right: 0,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        backgroundColor: Colors.lightBlue,
    },
    //  
    ggMapBg: {
        flex: 1,
    },





    drawItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderColor: Colors.blue,
    },
    line: {
        borderBottomWidth: 0.5,
        borderColor: Colors.blue
    },
    drawImage: {
        width: 25,
        height: 25,
        resizeMode: 'contain',
        marginLeft: 20,
        marginRight: 30,
        marginVertical: 10
    },

    drawFooterImage: {
        flexDirection: 'column',
        alignItems: 'center',
    },

    boardImageBg: {
    //    flex: 1,
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        borderWidth: 1, 
        borderColor: Colors.borderLightColor,
        backgroundColor: Colors.appBackgroundColor

    }
};

const CommonStyle = (name, customStyles = null) => {
    if (typeof customStyles === "string") {
        console.log("CommonStyle.GetStyle() changed. font sized style should go to TextStyle()")
    }
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('CommonStyle()', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}


export const GetStyle = (name, sizeType = null, customStyles = null) => {
    if (styles.hasOwnProperty(name)) {
        if (customStyles) {
            return [OverrideUserFont(styles[name], sizeType), customStyles];
        }
        else {
            return OverrideUserFont(styles[name], sizeType);
        }
    }
    else {
        Bug('CommonStyle.GetStyle', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}
export default CommonStyle;

