import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import FrStyle from '../../../styles/FrStyle';
import * as JsUtil from '../../../utils/JsUtil';

const BusLaneBox = ({selfColor, laneType}) => {
    return (
        <View style={ SectionStyle('routeTypeBox', { borderColor: selfColor } ) }>
            <Text style={ TextStyle('rowDetail', 'small') }>
                { TMap.GetTMapRouteLabel(laneType) }
            </Text>
        </View>
    )
}

export default function FrBusDetailRow({selfColor, beforeColor, item, isTransfer, beforeItem}) {
    return(
        <View style={FrStyle("frdBusRowBg")} accessible={true}>
            <View style={FrStyle("frdBusLine", { backgroundColor: selfColor})} />
            <View style={FrStyle("busIconViewBg", { backgroundColor: selfColor})}>
                <FIcon type="fas" name="bus" size={20} color={Colors.white} />
            </View>
            <View style={SectionStyle("rowFill", { alignSelf: "stretch", justifyContent: "space-between"})}>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "medium", { })}>
                    {isTransfer ? '환승' : '승차'} | {item.startStationName} 정류소
                </Text>                
                <View style={SectionStyle("rowForRow", { alignItems: "center" })}>
                    <BusLaneBox selfColor={selfColor} laneType={JsUtil.GText(item.bus,"typeName")} />
                    <Text style={ TextStyle('rowDetail', 'medium', {color: selfColor, marginLeft: 10}) }>
                        { JsUtil.GText(item.bus,"name") }
                    </Text>
                </View>
                <Text style={ TextStyle('rowDetail', 'smallest', {}) }>
                    {TMap.getTimeHMS(item.duration)}, {item.stations.length}개 정류소 이동
                </Text>
            </View>
        </View>
    )
}
