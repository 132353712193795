// 정류소에서 매 노선에 해당하여 2대 버스의 현재 상황을 알려주기 위한 Component
import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
// config
import FIcon from '../../defines/FIcon';
import LoadSvg from '../../defines/Svgs';
import * as GBus from '../../defines/GBus';
import * as GBusHelper from '../../defines/GBusHelper';
import { Colors, Layouts } from '../../defines/Theme';
import SectionStyle from '../../styles/SectionStyle';
import CommonStyle from '../../styles/CommonStyle';
import TextStyle from '../../styles/TextStyle';
import * as JsUtil from '../../utils/JsUtil';
import Texts, { GetText } from '../../defines/Texts';


// shared
import SpacerH from './common/SpacerH';
import DensityBox from './parts/DensityBox';
import RemainSeatCntBox from './parts/RemainSeatCntBox';
import PlateTypeBox from './parts/PlateTypeBox';
import BusAuxStatusBox from './parts/BusAuxStatusBox';
import BusRowRideSupportButton from './parts/BusRowRideSupportButton';

export const SimpleNoBusRow = ({ text, busStatus }) => {
    return (
        <View style={ SectionStyle('busArrivalRowBg', { backgroundColor: Colors.routeBusArrivalRow }) }>
            <View style={ SectionStyle('rowForHRow', { flex: 1, height: Layouts.rowHeight * 0.75, backgroundColor: 'transparent' }) } >
                <View style={ SectionStyle('rowLeftIconArea', { }) } />
                <View style={ SectionStyle('rowFillStretchForRow', { }) }>  
                    <View style={ SectionStyle('arrivalBusPosTextBg') }>
                        <Text numberOfLines={1} style={ TextStyle('arrivalBusPosText', 'medium', { color: Colors.fontCommentColor }) }>
                            { text? text : GBusHelper.getBusArrivalText(busStatus)}
                        </Text>
                    </View>
                </View>
            </View> 
        </View>
    )
}


// route 
const SimpleBusRow = ({ route, busStatus, onPress, onRightIconPress, taglessCd, leftSpaceWidth }) => {
    let cnt = JsUtil.GInt(busStatus, "remainSeatCnt");
    let text = "";
    let TaglessCdText = taglessCd ? taglessCd._text : '0';
    if (!busStatus) {
        return null
    }
    if (!GBusHelper.VALID_BUS_RUNS.includes(busStatus.runStatus)) {
        return (
            <SimpleNoBusRow busStatus={busStatus} />
        )
    }
    // 태글리스 추출 로직
    let svgName = GBus.GetBusSvgName(JsUtil.GText(route, 'routeTypeCd'), JsUtil.GText(busStatus, 'lowPlate'), null, TaglessCdText)
    console.log("svgName ", svgName)
    return (
        
        <TouchableOpacity style={ SectionStyle('busArrivalRowBg', { backgroundColor: Colors.routeBusArrivalRow}) }
            onPress={() => {onPress?.()}}
        > 
          <View style={ SectionStyle('rowForHRow', 
                { flex: 1, height: Layouts.rowHeight * 0.75, backgroundColor: 'transparent' }) } >
                {leftSpaceWidth ? <View style={{width: leftSpaceWidth}} /> : null}
                <View style={ SectionStyle('rowFillStretchForRow',  { }) }>  
                    {
                        // 태글리스 추출 로직
                        TaglessCdText === '1' ?
                        <LoadSvg name={svgName} width={Layouts.taglessSimpleBusIconSize-10} height={Layouts.taglessSimpleBusIconSize-10} />
                        :
                        <FIcon type="fas" name="bus" size={22} color={ GBus.GetRouteTypeColor(JsUtil.GText(route, "routeTypeCd")) } />
                    }
                    <SpacerH />
                    { global.foreignLanguage=='E' ?
                        <View style={ SectionStyle('arrivalBusPosTextBg') }>                        
                            <Text numberOfLines={1} style={ TextStyle('arrivalBusPosText', 'small') }>Before </Text>
                            <Text numberOfLines={1} style={ TextStyle('arrivalBusPosText', 'small') }>
                                { GBusHelper.getBusArrivalText(busStatus, false).replace('Before ','') }
                            </Text>
                        </View>    
                            : 
                            global.foreignLanguage=='V' ?
                            <View style={ SectionStyle('arrivalBusPosTextBg') }>                        
                                <Text numberOfLines={1} style={ TextStyle('arrivalBusPosText', 'small') }>Trước </Text>
                                <Text numberOfLines={1} style={ TextStyle('arrivalBusPosText', 'small') }>
                                    { GBusHelper.getBusArrivalText(busStatus, false).replace('Trước ','') }
                                </Text>
                            </View> :   
                            <View style={ SectionStyle('arrivalBusPosTextBg') }>
                                <Text numberOfLines={1} style={ TextStyle('arrivalBusPosText', 'medium') }>
                                { GBusHelper.getBusArrivalText(busStatus, false) }                            
                                </Text>
                            </View>}
                    <RemainSeatCntBox bus={busStatus}/>
                    <DensityBox bus={busStatus} />
                    <BusAuxStatusBox bus={busStatus} />
                    <PlateTypeBox bus={busStatus} />
                </View>
            </View>
            {/* <BusRowRideSupportButton bus={busStatus} onPress={onRightIconPress} />  */}
        </TouchableOpacity>
    )
}
export default SimpleBusRow
