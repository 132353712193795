import React from 'react';
import { BottomRightTabs } from './BottomTab';

export default function SearchPoiMapBottomTab({ navigation, onBack, findRoad }) {
    return (
        <BottomRightTabs 
            navigation={navigation}
            onBack={onBack}
            tabs={[
                { 
                    name: 'findRoad', 
                    onPress: () => { findRoad?.(); },
                    title: '바로길찾기',
                },                    
            ]}            
        /> 
    )    
}
