import _ from 'lodash';

/*
input parameters:
1. res: json 으로 변환된 xml response
2. entityPath: dot.string || string[] : 제공하지 않으면, response.msgBody 를 리턴
3. isArrayData: boolean : 리턴하는 데이터를 [] 로 Wrapping 해서 리턴
return { data, error }
1. data: extracted entity
2. error?: { code, msg, detail? }
*/

const ResultCodes = new Map([
    // 서버에서 제공하는 resultCode
    [0, '정상적으로 처리되었습니다.'],
    [1, '시스템 에러가 발생하였습니다.'],
    [2, '필수 요청 Parameter 가 존재하지 않습니다.'],
    [3, '필수 요청 Parameter 가 잘못되었습니다.'],
    [4, '결과가 존재하지 않습니다.'],
    [5, '필수 요청 Parameter(인증키) 가 존재하지 않습니다.'],
    [6, '등록되지 않은 키입니다.'],
    [7, '사용할 수 없는(등록은 되었으나, 일시적으로 사용 중지된) 키입니다.'],
    [8, '요청 제한을 초과하였습니다.'],
    [20, '잘못된 위치로 요청하였습니다. 위경도 좌표값이 정확한지 확인하십시오.'],
    [21, '노선번호는 1자리 이상 입력하세요.'],
    [22, '정류소명/번호는 1자리 이상 입력하세요.'],
    [23, '버스 도착 정보가 존재하지 않습니다.'],
    [31, '존재하지 않는 출발 정류소 아이디(ID)/번호입니다.'],
    [32, '존재하지 않는 도착 정류소 아이디(ID)/번호입니다.'],
    [50, 'comMsgHeader의 에러 메시지를 참조하세요.'],
    [99, 'API 서비스 준비중입니다.'],
    // 로컬에서 처리중에 발생 가능한 오류. mobile local code
    [500, '응답메시지 포맷이 올바르지 않습니다.'], // xml format 오류. response.msgHeader.resultCode not exist
    [501, '처리할 수 없는 응답 코드입니다.'], // xml format 오류. response.msgHeader.resultCode exist. but not defined
    // 서버 응답 오류
    [600, '서버가 정상적으로 응답하지 않습니다.'], // http statusCode 오류
    // 로컬 요청 오류
    [700, '요청을 수행할 수 없습니다.'], // http request 오류
    // 로컬 네트워크 오류.
    [800, '네트워크를 사용할 수 없습니다.'],
    // 루틴, code, 기타 오류
    [900, '앱에서 응답을 처리하는 도중 오류가 발생했습니다.'],
    [999, '알 수 없는 오류가 발생했습니다.'],
]);

// must return { data?, error? }
export const parseResponse = (res, error, entityPath, entityIsArray) => {
    if (error) {
        return { error: parseError(error) };
    }
    const rt = {};
    if (!res) {
        return rt;
    }
    try {
        // Check GG api header
        const rcode = parseInt(
            _.get(res, 'response.msgHeader.resultCode._text') ||
                _.get(res, 'response.msgHeader.resultCode'),
        );
        if (isNaN(rcode)) {
            console.error(
                `서버 응답에 res.response.msgHeader.resultCode 가 존재하지 않습니다.`,
                res,
            );
            rt.error = { code: 500, msg: ResultCodes.get(500) };
        }
        // 정상 처리됨.
        else if (rcode === 0) {
            rt.data = entityPath ? _.get(res, entityPath) : _.get(res, 'response.msgBody');
            if (entityIsArray && !Array.isArray(rt.data)) {
                rt.data = rt.data ? [rt.data] : [];
            }
        }
        // 조회결과 없음 응답
        else if (rcode === 4) {
            rt.data = entityIsArray ? [] : undefined;
        }
        // check resultCode
        else if (ResultCodes.has(rcode)) {
            console.warn(`[API] 오류 응답코드 회신됨. resultCode=${rcode}`, res);
            rt.error = { code: rcode, msg: ResultCodes.get(rcode) };
        } else {
            console.error(`[API] 응답포맷 오류`, res);
            rt.error = { code: 501, msg: `${ResultCodes.get(501)} - ${rcode}` };
        }
    } catch (err) {
        console.error('응답 판독중 오류 발생', err);
        rt.error = { code: 900, msg: ResultCodes.get(900) };
    }
    return rt;
};

// must return { data?, error?, totalCount? }
export const parsePagedResponse = (res, error, entityPath, totalCountPath) => {
    if (error) {
        return { error: parseError(error) };
    }
    const rt = {};
    if (!res) {
        return rt;
    }
    try {
        // Check GG api header
        const rcode = parseInt(
            _.get(res, 'response.msgHeader.resultCode._text') ||
                _.get(res, 'response.msgHeader.resultCode'),
        );
        if (isNaN(rcode)) {
            console.error(
                `서버 응답에 res.response.msgHeader.resultCode 가 존재하지 않습니다.`,
                res,
            );
            rt.error = { code: 500, msg: ResultCodes.get(500) };
        }
        // 정상 처리됨.
        else if (rcode === 0) {
            rt.data = entityPath ? _.get(res, entityPath) : _.get(res, 'response.msgBody');
            if (!Array.isArray(rt.data)) {
                rt.data = rt.data ? [rt.data] : [];
            }
            rt.totalCount = totalCountPath ? parseInt(_.get(res, totalCountPath)) : undefined;
        }
        // 조회결과 없음 응답
        else if (rcode === 4) {
            rt.data = [];
            rt.totalCount = 0;
        }
        // check resultCode
        else if (ResultCodes.has(rcode)) {
            console.warn(`[API] 오류 응답코드 회신됨. resultCode=${rcode}`, res);
            rt.error = { code: rcode, msg: ResultCodes.get(rcode) };
        } else {
            console.error(`[API] 응답포맷 오류`, res);
            rt.error = { code: 501, msg: `${ResultCodes.get(501)} - ${rcode}` };
        }
    } catch (err) {
        console.error('응답 판독중 오류 발생', err);
        rt.error = { code: 900, msg: ResultCodes.get(900) };
    }
    return rt;
};

/* axios.all() 로 수신한 데이터 처리
    응답성공이고 로컬 검증에 성공하면 { data: data[] }
    하나라도 오류면 { error }
*/
export const parseResponseList = (resList, errorList, entityPathList, entityIsArrayList) => {
    if (!resList && !errorList) {
        return {};
    }
    // return { error }
    if (errorList) {
        return { error: parseError(Array.isArray(errorList) ? errorList[0] : errorList) };
    }
    // { data?, error?}[]
    const parsedList = resList?.map((res, idx) =>
        parseResponse(res, null, _.nth(entityPathList, idx), _.nth(entityIsArrayList, idx)),
    );
    // 요청+응답은 성공했지만 로컬 검증에서 오류발생하면 오류로 간주
    if (parsedList?.find(res => res.error)) {
        return { error: parsedList.find(res => res.error).error };
    }
    return {
        data: parsedList?.map(res => res.data),
    };
};

export const parseError = error => {
    if (!error) {
        return undefined;
    }
    // 서버 응답이 존재함
    if (error?.response) {
        console.error('[API] 서버응답오류', error.response);
        return {
            code: 600,
            msg: ResultCodes.get(600),
            detail: `http status: ${error.response.status}, data: ${error.response.data}`,
        };
    }
    // 요청이 수행되었으나, 응답 수신 못함 error: (node)http.ClientRequest or... axios.request
    if (error?.request) {
        console.error('[API] 요청오류', error.request);
        console.error(error.request);
        return {
            code: 700,
            msg: ResultCodes.get(700),
        };
    }
    // custom error
    if (error?.errorCode) {
        console.error('[API] 요청오류', error.request);
        console.error(error.request);
        return {
            code: 700,
            msg: ResultCodes.get(700),
        };
    }
    console.error('[API] 알 수 없는 오류', error);
    return {
        code: 999,
        msg: ResultCodes.get(999),
        detail: error?.message || '',
    };
};
