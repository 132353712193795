import React, { Component, useState, useEffect, useRef } from 'react';
import { Button, StyleSheet, Animated, Text, TouchableOpacity, View } from 'react-native';
import { Header } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { WebView } from 'react-native-web-webview';
import { PanGestureHandler } from 'react-native-gesture-handler';
import SlidingUpPanel from 'rn-sliding-up-panel'; 

//  config, lib
import { Colors, Layouts } from '../../defines/Theme';
import AppSetting from '../../defines/AppSetting';
import FIcon from '../../defines/FIcon';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import DlgStyle from '../../styles/DlgStyle';
import TextStyle from '../../styles/TextStyle';
import MapStyle from '../../styles/MapStyle';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

// shared
import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import BottomSheet from "../components/common/BottomSheet";
import SpacerH from "../components/common/SpacerH";


//import Html from '../Tmap/TmapFindRoad';
import FrDetailDragHeader from './detailRows/FrDetailDragHeader';
import FrDetailPageInner from './FrDetailPageInner';
import { getCorsProxy } from '../../api/RestAPI';

let UserScriptApi = require('../../utils/UserScriptApi');
function AddZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("addZoom") }
            onPress={ onPress } accessibilityLabel="줌 확대" accessibilityRole="button">
            <FIcon type="fad" name="plus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>        
        </>
    );
}
function RemoveZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("removeZoom") }
            onPress={ onPress } accessibilityLabel="줌 축소" accessibilityRole="button">
            <FIcon type="fad" name="minus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}

export default class FrDetailPage extends Component {
    constructor(props) {
        super(props)
        this.topLimit = 0;

        this.state = {            
            bsOpened: false,
        }
        this.optionBS = React.createRef();

        this.bsOptionAnimateValue = new Animated.Value(Layouts.rowHeight);
        this.bsOptionHeight = Layouts.rowHeight * 2 + Layouts.rowHeight * 2;
        this.bsOptionDraggableRange = {
            // top: h - Layouts.bottomBarHeight, // 200, //Layouts.windowHeight - 150,
            // bottom: Layouts.rowHeight,
            top: Layouts.rowHeight * 5 + Layouts.rowHeight * 2,
            bottom: Layouts.rowHeight
        };
        this._onAnimatedValueChange = this._onAnimatedValueChange.bind(this) 
    }


    componentDidMount() {
        UserScriptApi.apiConnect('frDetailPage')
        this.bsOptionAnimateValue.addListener(this._onAnimatedValueChange);
    }

    componentWillUnmount() {
        this.bsOptionAnimateValue.removeListener(this._onAnimatedValueChange);
        showPageLoader(false)
    }


    _onAnimatedValueChange({ value }) {
        const {top, bottom} = this.bsOptionDraggableRange
        if (value === top) {
          this.setState({ bsOpened: true })
        }
        if (value === bottom) {
          this.setState({ bsOpened: false})
        }
      }   

    calcHeight(walkCnt, busSubCnt) {
        return {
            header: 60,
            Paths: walkCnt * Layouts.frdWalkRowHeight + busSubCnt * Layouts.frdBusRowHeight
        }
    }

    render() {

        const { navigation } = this.props
        const { data , start, end } = this.props.route.params;

        const itemArray =  data.steps;
                

        let walkCnt = 1;
        let busSubCnt = 0;
        itemArray.forEach(item => {
            if (item.transitMode === '"WALKING"') walkCnt++;
            if (item.transitMode === 'SUBWAY' || item.transitMode === 'BUS') busSubCnt++;
        })
        let h = busSubCnt * Layouts.frdBusRowHeight + ( walkCnt + 2 ) * Layouts.frdWalkRowHeight +
                Layouts.rowHeight * 1.5; // header
               // Layouts.frdWalkRowHeight; // footer

        // console.log(`----> h: ${h}, busSubCnt: ${busSubCnt}, walkCnt: ${walkCnt}, Layout: ${Layouts.windowHeight * 0.7}`)
        h = Math.min(h, Layouts.windowHeight * 0.7) + Layouts.bottomBarHeight;
        
        if(this.bsOptionDraggableRange){
            this.bsOptionDraggableRange.top = h - Layouts.bottomBarHeight;
        }
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <Header
                    placement="left"
                    containerStyle={CommonStyle('headerContainer')}
                    centerComponent={
                        <View style={SectionStyle('rowForRow', {
                            width: Layouts.windowWidth * 0.85,
                            justifyContent: "center"
                        })} >
                            <View style={SectionStyle('rowForRow')}>
                                <Text numberOfLines={1} style={TextStyle('headerTitle',
                                    { maxWidth: Layouts.windowWidth * 0.4 })}>
                                    {start.title}
                                </Text>
                                <FIcon type="fad" name="angle-double-right" size={16} color={Colors.ggBlue} style={{ marginHorizontal: 5 }} />
                                <Text numberOfLines={1} style={TextStyle('headerTitle',
                                    { maxWidth: Layouts.windowWidth * 0.4 })}>
                                    {end.title}
                                </Text>
                            </View>
                        </View>}
                />
                <View style={CommonStyle("body")}>
                    <View style={{ flex: 1, alignSelf: "stretch" }}>
                        {/* 맵뷰 */}
                        <WebView
                            ref={(ref) => this.webRef = ref}
                            originWhitelist={['*']}
                            style={MapStyle("mapView")}
                            source={{ uri: AppSetting.MOBILE_WEB.ROOT + "assets/Tmap/TmapFindRoad.html", method: 'get' }}
                            onLoadStart={event => {
                                showPageLoader(true)
                            }}
                            onLoadEnd={event => {
                                showPageLoader(false)
                            }}
                            onMessage={(event) => {
                                if (!event.nativeEvent.data || typeof event.nativeEvent.data != "string" || !event.nativeEvent.data.startsWith("{")) {
                                    // console.log("===> Unsupported webview event", event);
                                    return;
                                }
                                let data = JSON.parse(event.nativeEvent.data.toString())
                                console.log("[TMap] event", data);
                            }}
                            javaScriptEnabled={true}
                            domStorageEnabled={true}
                            startInLoadingState={true}
                            scalesPageToFit={true}
                            injectedJavaScript={`getData(${JSON.stringify(itemArray)})`}
                        />
                    </View>
                    <SlidingUpPanel ref={c => (this.optionBS = c)}
                            // draggableRange={{
                            //     top: h - Layouts.bottomBarHeight, // 200, //Layouts.windowHeight - 150,
                            //     bottom: Layouts.rowHeight,
                            // }}
                            draggableRange={this.bsOptionDraggableRange}
                            height={h}  
                            snappingPoints={[Layouts.rowHeight, h-Layouts.bottomBarHeight]}
                            friction={0.1}
                            animatedValue={this.bsOptionAnimateValue}
                            minimumDistanceThreshold={2}
                            showBackdrop={true}
                            backdropOpacity={0.2}
                        >
                        {dragHandler => (
                            <View style={styles.container}>
                                <View style={styles.dragHandler} {...dragHandler}>
                                    {/* <Text>Drag handler</Text> */}
                                    <FrDetailDragHeader data={data} navigation={navigation} onPress={()=>{
                                            if(this.state.bsOpened){
                                                this.optionBS?.hide();
                                                this.setState({bsOpened:false});
                                            }
                                            else {
                                                this.optionBS?.show(h - Layouts.bottomBarHeight - Layouts.rowHeight * 1.5);
                                                this.setState({bsOpened:true});
                                            }
                                        }} isOpened={this.state.bsOpened}/>                                        
                                </View>
                                <FrDetailPageInner navigation={navigation} data={data} sortedPaths={itemArray} bsOpened={this.state.bsOpened} />
                            </View>
                        )}
                    </SlidingUpPanel>
                    {this.state.bsOpened ?
                        null 
                        :
                        <View>
                            
                        </View>
                        
                    }
                </View>
                    <BottomRightTabs showBack={true} navigation={navigation} />
            </SafeAreaView>
        )
    }
}

const styles = {
    container: {
      flex: 1,
      zIndex: 1,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderColor: Colors.ggBlue,
      borderWidth: 1,
      backgroundColor: 'white',
      alignItems: 'stretch',
      justifyContent: 'center'
    },
    dragHandler: {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
          flexDirection: 'column',
        alignItems: 'stretch',
        //height: Layouts.rowHeight,
        backgroundColor: "transparent",
        backgroundColor: Colors.listGroupColor,
        padding: 10

    //     flex: 1,
    //   alignSelf: 'stretch',
    //   height: 100,
    //   alignItems: 'center',
    //   justifyContent: 'center',
    //   backgroundColor: '#ccc'
    }
  }
