
export const Uniquify = (array, key) => {
/** 
 * Array.reduce((accumlate, current, index, array) => { f() }, initialAccumlate)
 * when f() return something, it will be accumulated to "accumulate"
 * */    
    return array.reduce((accum, curr) => {
        return accum.find(elem => elem[key] === curr[key]) ? accum : accum.push(curr) && accum
    }, []);
}


export const UniquifyEx = (array, excludePredict) => {
    /** 
     * Array.reduce((accumlate, current, index, array) => { f() }, initialAccumlate)
     * when f() return something, it will be accumulated to "accumulate"
     * */    
        return array.reduce((accum, curr) => {
            return excludePredict(accum, curr) ? accum : accum.push(curr) && accum
        }, []);
    }
    

// 1차원 배열만 지원
export const FilterPropValueMatched = (array, key, values) => {
    return array.filter((item) => item.hasOwnProperty(key) && values.find(value => value === item[key]));
}

