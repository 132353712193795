import React from 'react';
import { PermissionsAndroid } from 'react-native';
import Geolocation, {PositionError} from 'react-native-geolocation-service';
import { GetText, GetTextSido } from '../defines/Texts';

export const requestGpsPermission = (callback) => {
    try {
        // const granted = await 
        PermissionsAndroid.requestMultiple([
            PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
            PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION])
            .then((result) => {
                console.log(result);
                if (
                    result['android.permission.ACCESS_COARSE_LOCATION'] &&
                    result['android.permission.ACCESS_FINE_LOCATION'] === 'granted'
                ) {
                    callback(true)
                    //return "granted"
                    // this.getLocation();
                    // this.setState({
                    //     permissionsGranted: true,
                    // });
                }
                else {
                    callback(false)
                }
                
        });
    } catch (err) {
        console.warn(err);
        callback(false)
    }
}

export const GetLocation = async (msgHandler, success, fail) => {
    try {

        Geolocation.getCurrentPosition(
            position => {
                success?.(position)
                console.log(`[GPS] user location updated()`)
            },
            error => {
                fail?.(error)
                // msgHandler?.toast(GetText("gps", "plz_allow_permission"))
                console.log('[GPS] error: ', JSON.stringify(error))
            },
            { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
    }
    catch(e) {
        alert("GetLocation() error")
    }
}
export default GetLocation;
