import React, { Component, useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// config, lib
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import RouteNodeMapStyle from '../../styles/RouteNodeMapStyle';
import * as ArrayUtil from '../../utils/ArrayUtil';
import { Colors, Layouts } from '../../defines/Theme';
import LoadSvg from '../../defines/Svgs';
import FIcon from '../../defines/FIcon'; 
import * as GBus from '../../defines/GBus';
import * as JsUtil from '../../utils/JsUtil';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

// shared
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import RouteHeader from '../components/RouteHeader';
import { GetText, GetTextSido } from '../../defines/Texts';

let UserScriptApi = require('../../utils/UserScriptApi');

export default function RouteInfoDetail(props) {
    UserScriptApi.apiConnect('routeInfoDetail')

    const [routeDetail, setRouteDetail] = useState(null)
    const [runStations, setRunStations] = useState('')

    useEffect(() => {
        console.log("[RouteInfoDetail]: props changed.")
        setRouteDetail(props.route.params.data.routeDetail)
        console.log(props.route.params.data.routeDetail);
        let stations = ''
        props.route.params.data.allStations.forEach((item, index) => {
            if (item.stationName && JsUtil.GText(item, "stationName") && (item.mobileNoSi || item.mobileNo)) {
                let korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
                if (!JsUtil.GText(item, "stationName").includes("(경유)") && index%2==1) {
                    // console.log(korean.test(JsUtil.GText(item, "stationName")));
                    if(global.foreignLanguage=='K' || (global.foreignLanguage!='K' && !korean.test(JsUtil.GText(item, "stationName")))){
                        stations += JsUtil.GText(item, "stationName");
                        if (index < props.route.params.data.allStations.length - 1) {
                            stations += "-";
                        }
                    }
                }
            }
        });
        setRunStations(stations)
   
    }, [props])

    return (
        
        <SafeAreaView style={ CommonStyle('bg') }>
            <ScreenHeader
                title={GetText("pageTitle","ROUTE_INFO_DETAIL")}
            />     
            { routeDetail ?    
            <>
            <RouteHeader route={routeDetail} />
            <ScrollView style={CommonStyle("body")}>
                <View style={{
                    flexDirection: "column",
                    alignItems: "stretch",
                    marginRight: 0,
                }}>
                    <View style={CommonStyle("boxHeaderBg")}>
                        <FIcon type="fas" name="route" size={20} color={Colors.fontColor} />
                        <Text style={TextStyle("boxHeaderText", "small")}>{GetText("pageWord","OPERATION_AREA")}</Text>
                    </View>
                    <View style={CommonStyle("boxContentBg", {
                        paddingLeft: 15,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,

                    })}>
                        <Text numberOfLines={1} style={TextStyle("boxContentText", "small", {
                            marginTop: 0,
                            marginBottom: 0
                        })}>
                            {routeDetail.startStationName? JsUtil.GText(routeDetail, "startStationName") : null}
                            {'<->'}
                            {routeDetail.endStationName ? JsUtil.GText(routeDetail, "endStationName") : null}
                        </Text>
                        <Text numberOfLines={1} style={TextStyle("boxContentDetailText", "smallest")}>
                            {   
                                routeDetail ?
                                    // routeDetail.adminName?._text + ' ' + routeDetail.routeTypeName?._text :
                                    GetTextSido(JsUtil.GText(routeDetail, "adminName")) + ' ' + GetTextSido(JsUtil.GText(routeDetail, "routeTypeName")) :
                                    null
                            }
                        </Text>
                        <Text numberOfLines={1} style={TextStyle("boxContentDetailText", "smallest")}>
                            {GetText("pageWord","ROUTE_COMPANY")}: {routeDetail.companyName?._text} ({routeDetail.companyTel?._text})
                        </Text>
                        <Text numberOfLines={1} style={TextStyle("boxContentDetailText", "smallest")}>
                            {GetText("pageWord","ROUTE_GARAGE")}: {routeDetail.garageName?._text} ({routeDetail.garageTel?._text})
                        </Text>
                    </View>
                </View>
                <View>
                    <View style={CommonStyle("boxHeaderBg")}>
                        <FIcon type="far" name="clock" size={20} color={Colors.fontColor} />
                        <Text style={TextStyle("boxHeaderText", "small")}>{GetText("pageWord","OPERATION_TIME")}</Text>
                    </View>
                    <View style={CommonStyle("boxContentBg")}>
                        {
                            global.foreignLanguage == 'E' || global.foreignLanguage == 'V'? 
                            <Text numberOfLines={1} style={TextStyle("boxContentText", "smallest")}>
                            {!routeDetail.upFirstTime && !routeDetail.upLastTime && !routeDetail.weUpFirstTime && !routeDetail.weUpLastTime ? null : GetText('PAGEWORD','START_STATION')}
                            </Text>  
                            : null
                            }
                        <Text numberOfLines={1} style={TextStyle("boxContentText", "smallest")}>
                            {!routeDetail.upFirstTime && !routeDetail.upLastTime ? null : (global.foreignLanguage == 'E' || global.foreignLanguage == 'V' ? '' : GetText('PAGEWORD','START_STATION') + ': ') + GetText('PAGEWORD','WEEKDAY') + ' '}
                              {routeDetail.upFirstTime ? JsUtil.GText(routeDetail,"upFirstTime") : null} 
                              {routeDetail.upFirstTime && routeDetail.upLastTime ? "~" : null}
                              {routeDetail.upLastTime ? JsUtil.GText(routeDetail,"upLastTime") : null}                            
                            {!routeDetail.weUpFirstTime && !routeDetail.weUpLastTime ? null : ', ' + GetText('PAGEWORD','WEEKEND') + ': ' }
                            {routeDetail.weUpFirstTime ? JsUtil.GText(routeDetail,"weUpFirstTime") : null}
                            {routeDetail.weUpFirstTime && routeDetail.weUpLastTime ? "~" : null}
                            {routeDetail.weUpLastTime ? JsUtil.GText(routeDetail,"weUpLastTime") : null}                            
                        </Text>
                        { 
                            global.foreignLanguage === 'E' || global.foreignLanguage == 'V'? 
                            <Text numberOfLines={1} style={TextStyle("boxContentText", "smallest")}>
                            {!routeDetail.downFirstTime && !routeDetail.downLastTime && !routeDetail.weDownFirstTime && !routeDetail.weDownLastTime ? null : GetText('PAGEWORD','LAST_STATION')}
                            </Text>
                            : null
                        }
                        <Text numberOfLines={1} style={TextStyle("boxContentText", "smallest")}>
                            {!routeDetail.downFirstTime && !routeDetail.downLastTime ? null : (global.foreignLanguage == 'E' || global.foreignLanguage == 'V' ? '' : GetText('PAGEWORD','LAST_STATION') + ': ') +GetText('PAGEWORD','WEEKDAY') +' '}
                            {routeDetail.downFirstTime  ? JsUtil.GText(routeDetail,"downFirstTime") : null}
                            {routeDetail.downFirstTime && routeDetail.downLastTime ? '~' : null}
                            {routeDetail.downFirstTime ? JsUtil.GText(routeDetail,"downLastTime") : null}                                                        
                            {!routeDetail.weDownFirstTime && !routeDetail.weDownLastTime ? null : ', ' + GetText('PAGEWORD','WEEKEND') + ': ' }
                            {routeDetail.weDownFirstTime ? JsUtil.GText(routeDetail,"weDownFirstTime") : null}
                            {routeDetail.weDownFirstTime && routeDetail.weDownLastTime ? '~' : null}
                            {routeDetail.weDownLastTime ? JsUtil.GText(routeDetail,"weDownLastTime") : null}
                        </Text>
                    </View>
                </View>
                <View>
                    <View style={CommonStyle("boxHeaderBg")}>
                        <FIcon type="far" name="hourglass" size={20} color={Colors.fontColor} />
                        <Text style={TextStyle("boxHeaderText", "small")}>{GetText('PAGEWORD','BUS_INTERVAL')}</Text>
                    </View>
                    <View style={CommonStyle("boxContentBg")}>
                        <Text numberOfLines={1} style={TextStyle("boxContentText", "smallest")}>
                             {!routeDetail.peekAlloc && !routeDetail.nPeekAlloc ? null : GetText('PAGEWORD','WEEKDAY')+ ': '}
                            {routeDetail.peekAlloc && JsUtil.GText(routeDetail,"peekAlloc") != '0' ? JsUtil.GText(routeDetail,"peekAlloc") : null}
                            {routeDetail.peekAlloc && routeDetail.nPeekAlloc 
                                && JsUtil.GText(routeDetail,"peekAlloc") != '0' && JsUtil.GText(routeDetail,"nPeekAlloc") != '0' ? '~': null}
                            {routeDetail.nPeekAlloc && JsUtil.GText(routeDetail,"nPeekAlloc") != '0' ? JsUtil.GText(routeDetail,"nPeekAlloc") : null}
                            {!routeDetail.peekAlloc && !routeDetail.nPeekAlloc ? null : GetText('ARRIVAL','TIME').replace('{n}','')}
                            {!routeDetail.wePeekAlloc && !routeDetail.weNPeekAlloc ? null : ', ' + GetText('PAGEWORD','WEEKEND') + ': '}
                            {routeDetail.wePeekAlloc && JsUtil.GText(routeDetail,"wePeekAlloc") != '0' ? JsUtil.GText(routeDetail,"wePeekAlloc") : null}
                            {routeDetail.wePeekAlloc && routeDetail.weNPeekAlloc 
                                && JsUtil.GText(routeDetail,"wePeekAlloc") != '0' && JsUtil.GText(routeDetail,"weNPeekAlloc")!='0' ? '~' : null}
                            {routeDetail.weNPeekAlloc && JsUtil.GText(routeDetail,"weNPeekAlloc")!='0' ? JsUtil.GText(routeDetail,"weNPeekAlloc") : null}
                            {!routeDetail.wePeekAlloc && !routeDetail.weNPeekAlloc ? null : GetText('ARRIVAL','TIME').replace('{n}','')}
                        </Text>
                    </View>
                </View>
                <View>
                    <View style={CommonStyle("boxHeaderBg")} >
                        <LoadSvg name="station" width={22} height={22} />
                        <Text style={TextStyle("boxHeaderText", "small")}>{GetText('PAGEWORD','MAIN_STOP_STATION')}</Text> 
                    </View> 
                    <View style={CommonStyle("boxContentBg")}>
                        <Text numberOfLines={10} 
                            lineBreakMode="middle"
                            style={TextStyle("boxContentText", "smallest", { 
                            lineHeight: 26,
                            })}>
                            {runStations}
                        </Text>
                    </View>
                </View> 
            </ScrollView>
            </>
            : null
            }
            <BottomRightTabs navigation={props.navigation} />
        </SafeAreaView>
    )
    //}
}


