import React, { Component } from 'react';
// import { WebView } from 'react-native-webview';
import WebView from 'react-native-web-webview';
import { View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// configurations
import AppSetting from '../../defines/AppSetting';
import { Colors, Layouts } from '../../defines/Theme';
import LoadImage from '../../defines/Images';
import FIcon from '../../defines/FIcon';
import Texts, { GetText } from '../../defines/Texts';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import MapStyle from '../../styles/MapStyle';
import TextStyle from '../../styles/TextStyle';
import * as RestAPI from '../../api/RestAPI';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

let UserScriptApi = require('../../utils/UserScriptApi');
// shared
import MainBottomTab, { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerMiniV from '../components/common/SpacerMiniV';

  
export default function Help({ navigation }) {
    UserScriptApi.apiConnect('help')

    let helpUrl = AppSetting.HELP_URL;
    if(global.foreignLanguage=='E'){
        helpUrl = AppSetting.HELP_URL_EN;
    } else if(global.foreignLanguage=='C'){
        helpUrl = AppSetting.HELP_URL_CN;
    } else if(global.foreignLanguage=='J'){
        helpUrl = AppSetting.HELP_URL_JP;
    } else if (global.foreignLanguage=='V'){
        helpUrl = AppSetting.HELP_URL_VT;
    }

    console.log("help url", AppSetting.HELP_URL);
    return (        
        <SafeAreaView style={CommonStyle('bg')}>
            <ScreenHeader
                title={GetText("pageTitle","HELP")}
            />
            {/* <SpacerMiniV /> */}
            <View style={{ flex: 1, width: "100%", alignSelf: "stretch", backgroundColor: "#f00" }}>
                <WebView
                    originWhitelist={['*']}
                    style={{
                        flex: 1,
                        width: "100%",
                        height: "100%",
                        marginBottom: Layouts.bottomBarHeight,
                        backgroundColor: Colors.appBackgroundColor
                    }}
                    source={{ uri: helpUrl, method: 'get' }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    startInLoadingState={true}
                    scalesPageToFit={true}
                />
            </View>
            <BottomRightTabs navigation={navigation} />
        </SafeAreaView >
    );
}
