import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useRoute } from '@react-navigation/native';
// 
import Bug from '../../../utils/Bug';
import { Colors, Layouts } from '../../../defines/Theme';
import LoadSvg from '../../../defines/Svgs';
import LoadImage from '../../../defines/Images';
import FIcon, { FIconStack } from '../../../defines/FIcon';
import BottomTabStyle from '../../../styles/BottomTabStyle';
import TextStyle from '../../../styles/TextStyle';
import Texts, {GetText} from '../../../defines/Texts';

const TabDefines = {
    _def: {
        iconType: 'far',
        iconName: 'exclamation-triangle',
        iconSize: 32,
        title: 'Undefined'
    },
    // Back, forward
    back: {
        iconType: 'fad',
        iconName: 'chevron-left',
        iconSize: 30,
        title: '',
    },
    forward: {
        iconType: 'fad',
        iconName: 'chevron-right',
        iconSize: 33,
        title: ''
    },
    // prev, next
    prev: {
        iconType: 'fad',
        iconName: 'arrow-to-left',
        iconSize: 33,
        title: ''
    },
    next: {
        iconType: 'fad',
        iconName: 'arrow-to-right',
        iconSize: 33,
        title: ''
    },
    // 홈, 주변정류소, 길찾기, 등등
    home: {
        iconType: 'fad',
        iconName: 'home-lg-alt',
        iconSize: 30,
        // iconType: '_stacked',
        // icon1: {
        //     type: "fas",
        //     name: "home-lg-alt",
        //     size: 30,
        //     //style: { 'transform': [{scale(1, 0.5)}] }
        //     //scale(1, 0.5)
        // },
        // icon2: {
        //     type: "fas",
        //     name: "ellipsis-v",
        //     size: 20,
        //     style: {position: 'absolute', zIndex: 99, left: -15, top: 12}
        // },
        title: 'HOME',
        alias: ["homeFavor"],
    },
    // return <FIconStack type1={tab.icon1.type} name1={tab.icon1.name} size1={tab.icon1.size} color1={color} 
    // type2={tab.icon2.type} name2={tab.icon2.name} size2={tab.icon2.size} color2={color} 
    // style2={tab.icon2.style}
    

    // 백 옆의 홈
    homeLeft: { 
        iconType: 'fad',
        iconName: 'home-lg-alt',
        iconSize: 30,
        title: ''
    },    
    favor: { 
        iconType: 'fas',
        iconName: 'star',
        iconSize: 29,
        title: 'STAR'
    },
    around: {
        iconType: 'fad',
        iconName: 'location',
        iconSize: 30,
        title: 'LOCATION'
    },
    findRoad: {
        iconType: 'fas',
        iconName: 'directions',
        iconSize: 29,
        title: 'DIRECTIONS'
    },
    message: {
        iconType: 'fad',
        iconName: "comment-alt-lines",
        iconSize: 29,
        title: 'COMMENT'
    },
    aroundList: {
        iconType: 'Svg',
        iconName: 'helpRide',
        iconSize: 30,
        title: 'HELP_RIDE',
    },    
    rideSupport: {
        iconType: 'Svg',
        iconName: "helpRide",
        iconSize: 33,
        title: 'HELP_RIDE',
    },
    rideSupportStation: {
        iconType: 'Svg',
        iconName: "helpRide",
        iconSize: 33,
        title: 'HELP_RIDE',
    },
                // 출도착지 지정 버튼
    setStartEndPos: {
        iconType: 'fad',
        iconName: 'map-marker-alt',
        iconSize: 32,
        title: 'MAP_MARKER',
    },   
    // 내 위치
    myLocation: {
        iconType: 'fad',
        iconName: 'map-marker-alt',
        iconSize: 32,
        title: 'MAP_MARKER_MY',
    },        
    // 지도+마커 보기
    showMarkerMap: {
        iconType: 'fad',
        iconName: 'map-marked-alt',
        iconSize: 32,
        title: 'MAPM',
    },        
    // 지도 보기
    showMap: {
        iconType: 'fad',
        iconName: 'map',
        iconSize: 32,
        title: 'MAPM',
    },        
    // info
    info: {
        iconType: 'fad',
        iconName: 'info-circle',
        iconSize: 32,
        title: 'INFO',
    },  
    // alarm, alert
    alarmClock: {
        iconType: 'fad',
        iconName: 'alarm-clock',
        iconSize: 30,
        title: 'ALARM',
    },   
    // alarm, alert
    alarmClock_duo: {
        iconType: 'fad',
        iconName: 'alarm-clock',
        iconSize: 30,
        title: 'ALARM',
    },             
    // alarm, alert
    alarmClock_solid: {
        iconType: 'fas',
        iconName: 'alarm-clock',
        iconSize: 30,
        title: 'ALARM',
    },             
    // alarm, alert
    alarmClock_regular: {
        iconType: 'fal',
        iconName: 'alarm-clock',
        iconSize: 30,
        title: 'ALARM',
    },                 
    alarmAlert: {
        iconType: 'fad',
        iconName: 'alarm-exclamation',
        iconSize: 32,
        title: 'ALARM_EXCLAMATION',
    }, 
    alertTriangle: {
        iconType: 'fad',
        iconName: 'exclamation-triangle',
        iconSize: 32,
        title: 'EXCLAMATION',
    },             
    alertCircle: {
        iconType: 'fad',
        iconName: 'exclamation-circle',
        iconSize: 32,
        title: 'EXCLAMATION',
    },             
    // setting 
    config: {
        iconType: 'fad',
        iconName: 'cog',
        iconSize: 32,
        title: 'COG',
    },      
    setting: {
        iconType: 'fad',
        iconName: 'sliders-h',
        iconSize: 32,
        title: 'COG',
    },      
    // evalBus
    evalBus: {
        iconType: 'fad',
        iconName: 'clipboard-list-check',
        iconSize: 32,
        title: 'CLIPBOARD_LIST_CHECK',
        label: 'CLIPBOARD_LIST_CHECK',

    },   
    // phone
    phone: {
        iconType: 'fas',
        iconName: 'phone-alt',
        iconSize: 28,
        title: 'PHONE',
    },       
    // history
    history: {
        iconType: 'fad',
        iconName: 'history',
        iconSize: 30,
        title: 'HISTORY',
    },       
    // history
    send: {
        iconType: 'fas',
        iconName: 'paper-plane',
        iconSize: 30,
        title: 'PAPER_PLANE',
    },     
    // 버스예약
    reserveBus: {
        iconType: 'fad',
        iconName: 'clipboard-list-check',
        iconSize: 32,
        title: 'RESERVATION',
    },
    // 버스예약조회
    searchReserveBus: {
        iconType: 'fad',
        iconName: 'print-search',
        iconSize: 32,
        title: 'PRINT_SEARCH',
        label: '예약확인, 선택하면 외부 브라우저가 실행됨니다.'
    },
}

// export const FIconStack = ({ type1, name1, size1, color1, style1,
//     type2, name2, size2, color2, style2 }) => {

const TabIcon = ({ tab, isActive, isOnGoing }) => {
    let color = isActive ? Colors.mainTabActiveColor : Colors.mainTabColor;
    switch (tab.iconType) {
        case "_stacked": 
            return <FIconStack type1={tab.icon1.type} name1={tab.icon1.name} size1={tab.icon1.size} color1={color} style1={tab.icon1.style} 
                    type2={tab.icon2.type} name2={tab.icon2.name} size2={tab.icon2.size} color2={color} 
                    style2={tab.icon2.style}
            />

        case "fad":
            return <FIcon type={tab.iconType} name={tab.iconName} size={tab.iconSize} color={color} />
        case "fas":
            return <FIcon type={tab.iconType} name={tab.iconName} size={tab.iconSize} color={color} />
        case "Image":
            return <LoadImage name={ isActive ? tab.iconName + '_active' : tab.iconName} 
                    width={tab.iconSize} height={tab.iconSize} style={{ resizeMode: 'contain', marginTop: 10 }} />;
        case "Svg":
            return <LoadSvg name={ tab.iconName + (isOnGoing ? "_ongoing" : (isActive ? '_active' : ""))} 
                    width={tab.iconSize} height={tab.iconSize} />;
    }
    Bug('GetBottomTabIcon', `Not supported icon type. ${tab.iconType}`, 9);
    return <Text>no icon</Text>;
};

// tab = { name, onPress, title }
const BottomTab = ({ tab, align, navigation }) => {
    let tabDefine = TabDefines.hasOwnProperty(tab.name) ? TabDefines[tab.name] : TabDefines._def;
    // Override defined tab title
    let title = tab.title || tab.title === "" ? tab.title : tabDefine.title;
        title = GetText('pageTabLabel',title ) ? GetText('pageTabLabel',title) : title;
    // Make tab wider if title is long. default 5 charactgers allowed
    let tabWidth = title.length > 4 ? Layouts.bottomTabMaxWidth : 0;
    // Set tab style
    let tabStyle = tab.name === "back" || tab.name === "homeLeft" ? "bottomBackTab" : (align ? 'bottomTabW' : 'bottomTab');
    return (
        <TouchableOpacity style={ BottomTabStyle(tabStyle, tabWidth ? { width: tabWidth } : null ) } accessibilityLabel={tab.label ? tab.label : tabDefine.label} accessibilityRole="button" accessibilityHint={tab.hint}
            onPress={() => { 
                if (typeof tab.onPress === 'function') {
                    tab.onPress();
                }
                // else if (tab.name === 'back') {
                //     navigation.goBack()
                // }
                else {
                    navigation.navigate(tab.onPress ? tab.onPress : tab.name);
                } 
            }}>
            <View style={ BottomTabStyle('bottomTabIconBg') } >
                <TabIcon tab={tabDefine} isActive={tab.isActive} isOnGoing={tab.isOnGoing} />
            </View>
            {
                title && title !== ""
                ? <Text style={ TextStyle('bottomTabText', tab.isActive ? { color: Colors.mainTabActiveColor } : null) }>{title}</Text> 
                : null
            }
        </TouchableOpacity>
    )
};

// tabs = [{ name, onPress, title }]
const BottomTabs = ({ tabs, selTab, navigation }) => {
    const route = useRoute();    
    tabs.forEach(tab => {
        tab.isActive = tab.isActive || route.name == tab.name || (tab.alias && tab.alias.indexOf(route.name) >= 0);
    })
    return (
        <View style={ BottomTabStyle('bottomTabBg') } accessible={true} accessibilityRole="button">
            { 
                tabs.map((tab, index) => {
                    return (
                        <BottomTab 
                            tab={tab} 
                            navigation={navigation}
                            key={index}
                        />
                    )
                })                
            }            
        </View>
    )
}

export default BottomTabs;

// tabs = [{ name, onPress, title }]
export const BottomRightTabs = ({ showBack, onBack, tabs, navigation, showHome }) => {
    const route = useRoute();    
    return (
        <View style={ BottomTabStyle('bottomTabBg') }>
            <View style={ BottomTabStyle('bottomTabLeftBg') }>
            {
                showBack 
                ? <BottomTab 
                    align="left"
                    tab={{ 
                        name: "back",
                        onPress: typeof onBack == 'function' ? onBack : () => { 
                            // console.log("Going to back");
                            // console.log(route);
                            if (navigation.canGoBack()) {
                                navigation.goBack() 
                            }
                            else {
                                navigation.navigate("home")
                            }
                        },
                        label: GetText("pageTabLabel", "BACK")
                    }} 
                    navigation={navigation}
                  />
                : null
            }
            {
                showHome
                ? <BottomTab 
                    align="left"
                    tab={{ 
                        name: "homeLeft",
                        //title: "",
                        onPress: () => {
                            console.log("Home left");
                            // navigation.navigate("homeFavor")
                            navigation.navigate("home")
                        },
                        label: "홈바로가기"
                    }}
                    navigation={navigation}
                  />
                : null
            }
            </View>
            {
                tabs 
                ? <View style={ BottomTabStyle('bottomTabRightBg') }>
                        { 
                            tabs.map((tab, index) => {
                                return (
                                    <BottomTab tab={tab} align="right" navigation={navigation} key={index} />
                                )
                            })                
                        }            
                    </View>
                : null
            }
        </View>
    )
}

BottomRightTabs.defaultProps = {
    showHome: true,
    showBack: true
}
