import { Platform } from 'react-native';
import * as DeviceUtil from '../utils/DeviceUtil';
import { getCorsProxy } from '../api/CorsProxy';

const AppSetting = {
    ///////////////////////////////////////////////////////////////////////////////////////////
    // 런타임 설정 불가
    ///////////////////////////////////////////////////////////////////////////////////////////
    SERVICE_KEY: '1234567890',
    // SERVICE_KEY: (global.foreignLanguage=='K' ? '1234567890' : (global.foreignLanguage=='E' ? '1234567890_EN' : (global.foreignLanguage=='C' ? '1234567890_CN' : (global.foreignLanguage=='J' ? '1234567890_JP' : (global.foreignLanguage=='V' ? '1234567890_VT' : '1234567890' ))))),
    TMAP: {
        APP_KEY: '01ab749a-7332-4a8d-93ee-dc823cbe3de1',
        BIZ_APP_ID: 'f37db52749fe',
        COORD_TYPE: 'WGS84GEO',
    },
    // HELP_URL: 'https://api.gbis.go.kr/bwshelp/apphelp.html',
    // HELP_URL: 'http://localhost/bwshelp/apphelp.html',
    // HELP_URL: 'http://localhost:3000/bwshelp/apphelp.html',
    // HELP_URL: DeviceUtil.getWebServiceHostname() + '/bwshelp/apphelp.html',
    HELP_URL: getCorsProxy() + 'https://m.gbis.go.kr/bwshelp/apphelp.html',
    HELP_URL_EN: getCorsProxy() + 'https://m.gbis.go.kr/bwshelp/apphelpEn.html',
    HELP_URL_CN: getCorsProxy() + 'https://m.gbis.go.kr/bwshelp/apphelpCn.html',
    HELP_URL_JP: getCorsProxy() + 'https://m.gbis.go.kr/bwshelp/apphelpJp.html',
    HELP_URL_VT: getCorsProxy() + 'https://m.gbis.go.kr/bwshelp/apphelpVt.html',
    //
    RECORD_COUNT_PER_QUERY: 20,
    //
    HEADER_BAR_HEIGHT: Platform.OS == 'ios' ? 44 : 50, // 70,
    DEF_BOTTOM_SHEET_HEIGHT: 180,                           // Bottom sheet 기본 높이
    BOTTOM_BAR_HEIGHT: 70,
    // 한글 포함 검사용 정규식
    KO_CHAR: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
    // 개발자용
    DEBUGS: {
        //
        SHOW_API_LOG: false,
        SHOW_API_WARN: true,
        SHOW_API_ERROR: true,
        SHOW_TMAP_API_LOG: false,
        SHOW_TMAP_API_WARN: true,
        SHOW_TMAP_API_ERROR: true,
        //
        SHOW_FAVOR_LOG: true,
        SHOW_STOR_LOG: true,
        SHOW_HISTORY_LOG: false,
        SHOW_DATEUTIL_LOG: false,
        // 
        SHOW_CONTEXT_LOG: true,
        SHOW_CONTEXT_WARN: true,
        //
        SHOW_ERROR_FORCE: false,
    },
    STATION: {
        ARRIVE_SOON_ROTATE_INTERVAL: 7000,
    },

    ///////////////////////////////////////////////////////////////////////////////////////////
    // 설정가능한 값들
    ///////////////////////////////////////////////////////////////////////////////////////////
    GENERAL: {
        AUTO_REFRESH_INTERVAL: 15000,                           // 버스도착정보 갱신 주기
        CERT_MA: false,
        EVAL_BUS_URL: 'http://api.gbis.go.kr',
        // EVAL_BUS_URL: 'http://localhost:3000',
        FOREIGN_SERVICE: 'Y',
    },
    WIDGET: {
        // 위젯 갱신 주기
        REFRESH_INTERVAL: 10000,
        // 위젯 활성화 유지 시간. 로컬 설정이므로, 서버에서는 설정하지 마시오.
        REFRESH_PERIOD_MIN: 5,
        MAX_VISIBLE_ROUTES: 6,
    },
    BUS_ARRIVAL: {
        ALLOWED_SAME_LOCATION_SECS: 120,
        REMAIN_SEAT_EXCLUDE_ROUTES: "165000215,216000044,200000228,200000229,218000005,219000025,233000129,100100389,100100390,100100391,100100392,100100397,100100398,100100400,100100402,100100406,100100559,100100560,100100577,100100607",
    },
    // Voice
    VOICE: {
        MAX_VOICE_DETECT: 10000,                                // 음성감지 최대 시간
        FORCE_FIRST_RESULT: 1,
        MIN_VOICE_SCORE: 0,                                     // deprecated 음성감지 결과에서 사용자에게 표시할 최소 점수
    },
    GPS: {
        MAP_DEF_X: "126.93677",                          // GPS 감지 실패시 사용할 기본 좌표(경기교통정보센터)
        MAP_DEF_Y: "37.26801",                           // GPS 감지 실패시 사용할 기본 좌표
    },
    MSG_HANDLER: {
        IGNORE_SAME_INTERVAL_SEC: 15,                       // 동일 메시지(toast) 무시 시간
    },
    BUS_NOTIFY_ALLOWED_ROUTES: [
        "*",
    ],
    OFF_ALARM_ALLOWED_ROUTES: [
        "*",
    ],
    RIDE_SUPPORT: {
        VISIBLE: "allowed",                                 // [all: 모든 노선에서 보여줌. allowed: 허용된 노선에서만 보여줌, none: 모두 안보여줌]
        BUSNOTIFY_ALLOWED_DISTANCE_FROM_STATION: 100,       // 승차벨 허용 정류소부터의 거리 meter
        BUSNOTIFY_ALLOWED_STATION_OFFSET: 3,                // 승차벨 허용 n 번째 정류소
        BUSNOTIFY_RECENT_SHOW_SEC: 600,                     // 최근 승차벨 표시 시간. 10분
        BUSNOTIFY_RUN_MAX_SEC: 900,                         // 승차벨 최대 처리 시간. 15분
        BUSNOTIFY_TRANSFER_MAX_SEC: 60,                     // 승차벨 접수후, 버스 통보 허용 최대 시간
        BUSNOTIFY_FORCE_STOP_WHEN_TRANSFER_FAIL: true,      // 승차벨 접수후, 버스 통보 실패시, 승차벨 프로세스 종료여부

                // 2021.06.09 하차벨 관련 설정 추가/변경
        OFFALARM_DETECT_DISTANCE_FROM_STATION: 100,         // 하차알림 시작시 승차 정류소를 감지할 사용자<->정류소간 거리 meter
        OFFALARM_DETECT_START_STATION_MAX_SEC: 300,          // 하차알림 최초 정류소 감지 허용시간
        OFFALARM_DETECT_RIDE_BUS_MAX_SEC: 600,              // 하차알림 탑승버스 검지 허용시간    
        OFFALARM_ESCAPE_ALLOWED_MAX_COUNT: 1,               // 경로이탈 허용횟수. 아래 시간과 && 연산
        OFFALARM_ESCAPE_ALLOWED_MAX_SEC: 25,                // 경로이탈 허용시간
        OFFALARM_RUN_MAX_SEC: 5400,                         
        // 하차알림 최대 처리 시간 60분
        OFFALARM_WAIT_BUS_MAX_OFFSET: 100,                  // 하차알림시 탑승할 정류소에 근접할 버스를 표시할 최대 n번째 전 버스
        // 하차벨 목업/테스트용
        OFFALARM_MOCKUP_START_LOCATION: "USER_DEFINED",     // [USER_DEFINED|NEAREST|FARMOST|AUTO|N]
        OFFALARM_MOCKUP_RUNNING_LOCATION: "TRACE",          // [TRACE|N], 경로이탈 시험하려면 5

    },
    NONSTOP_DECLARE: {
        PREVENT_FUNNY_TRIAL_MINS: 60,
    },
    ALARM: {
        TASK_INTERVAL: 10000,                               // 승.하차 알림 버스위치 조회 주기
        FAIL_ALLOWED_COUNT: 10,                             // api 등 알림 수행 작업시 실패 허용 횟수
    },
    DENSITY: {
        REMAIN_SEAT_SUPPORT_ROUTES: ["11", "12", "14", "16", "21"],
        DENSITY_SUPPORT_ROUTES: ["13", "15", "22", "23"]
    },
    POP_NOTICE: {
        REFRESH_INTERVAL_MIN: 10,
        NO_SHOW_ROUTE_EVENTS_MIN: 30,
        ENTER_BOARDS_SAVE_AS_SHOWN_ALL: false,
        NO_SHOW_DAYS: 1,
    },
    MOBILE_WEB: {
        get ROOT() {
            // 개발환경
            console.log("deviceutil getWebserviceHostName ", DeviceUtil.getWebServiceHostname())
            if (DeviceUtil.getWebServiceHostname() == "localhost") {
                
                // return "https://gbus.mutex.co.kr/"
                return "http://localhost:3000/"
            }
            // 운영환경 
            return "/";
        } 
    }
};

// 서버에서 조회해서 업데이트하는 properties
export const _UPDATABLE_APPSETTING_PROPS = [
    "GENERAL",
    "VOICE",
    "GPS",
    "WIDGET",
    "MSG_HANDLER",
    "BUS_NOTIFY_ALLOWED_ROUTES",
    "OFF_ALARM_ALLOWED_ROUTES",
    "RIDE_SUPPORT",
    "ALARM"];

export const GetServiceKey = () => {
    let language = global.foreignLanguage;

    // console.log("AppSetting global.foreignLanguage>>>>", global.foreignLanguage)
    if (language === 'E') {
        return '1234567890_EN';
    } else if (language === 'C') {
        return '1234567890_CN';
    } else if (language === 'J') {
        return '1234567890_JP';
    } else if (language === 'V') {
        return '1234567890_VT';
    } else {
        return AppSetting.SERVICE_KEY; // 1234567890
    }
};

export default AppSetting;
