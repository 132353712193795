import React from 'react';
import { View, Text } from 'react-native';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';

const SectionHeader = ({ text, style, textStyle }) => {
    return (        
        <View style={CommonStyle('sectionHeaderBg', style)}>
            <Text style={ TextStyle('sectionHeaderText', textStyle) }>
                {text}
            </Text>
        </View>    
    );
}

export default SectionHeader