import React from 'react';
import { View, Text } from 'react-native';
// config
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import * as JsUtil from '../../../utils/JsUtil';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import SpacerH from '../common/SpacerH'; 

const PlateNoBox = ({bus, style}) => {

    let label = JsUtil.GText(bus, 'plateNo')
    if (!label) {
        return null;
    }
    label = label.substring(label.length - 4, label.length)
    return (
        <View style={ SectionStyle("plateNoBoxBg", style) }>
            <Text style={ TextStyle('rowDetail', 'medium') }>{label}</Text>       
        </View>
    )
}



export default PlateNoBox