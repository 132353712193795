import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, FlatList, Platform, Alert } from 'react-native';

// config, lib
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import FIcon from '../../../defines/FIcon';
import LoadSvg from '../../../defines/Svgs';
import * as JsUtil from '../../../utils/JsUtil';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import RouteNodeMapStyle from '../../../styles/RouteNodeMapStyle';
import { Colors, Layouts } from '../../../defines/Theme';
import DensityBox from '../../components/parts/DensityBox';
import RemainSeatCntBox from '../../components/parts/RemainSeatCntBox';
import PlateNoBox from '../../components/parts/PlateNoBox';
import BusAuxStatusBox from '../../components/parts/BusAuxStatusBox';
import { GetText }from '../../../defines/Texts';



// 매 정류소 지점 라인 & 아이콘 & 기.회차.종점 렌더
//export const RouteLine = ({ stationSeq, startId, endId, length }) => {
export const RouteLine = ({ stationSeq, startStationSeq, turnStationSeq, stationCount }) => {
    let color = stationSeq == stationCount ? "transparent"
        : (parseInt(stationSeq) < parseInt(turnStationSeq) ? Colors.routeNodeMapLine_Blue : Colors.routeNodeMapLine_Red);
    let icon = stationSeq == 1 || stationSeq == stationCount ? "circle-notch"
        : stationSeq == turnStationSeq ? "redo" : "chevron-down";
    let lastSeq = GetText("PAGEWORD","LAST_STATION").indexOf(" ")>0 ? GetText("PAGEWORD","LAST_STATION").replace(" ", "\n") : GetText("PAGEWORD","LAST_STATION");
    let turnSeq = GetText("PAGEWORD","TURN_SEQ").indexOf(" ")>0 ? GetText("PAGEWORD","TURN_SEQ").replace(" ", "\n") : GetText("PAGEWORD","TURN_SEQ");
    let startSeq = GetText("PAGEWORD","START_STATION").indexOf(" ")>0 ? GetText("PAGEWORD","START_STATION").replace(" ", "\n") : GetText("PAGEWORD","START_STATION");
    let label = stationSeq == stationCount ? lastSeq : stationSeq == turnStationSeq ? turnSeq : (stationSeq == startStationSeq ? startSeq : "");
    // let label = stationSeq == stationCount ? GetText("pageWord","LAST_STATION") : stationSeq == turnStationSeq ? GetText("pageWord","TURN_SEQ") : (stationSeq == startStationSeq ? GetText("pageWord","START_STATION") : "");
    return (<>
        <Text style={RouteNodeMapStyle("stationMarkLabel")}>{label}</Text>
        <View style={RouteNodeMapStyle("routeRunLineColBg")}>
            {/* Vertical Line */}
            <View style={RouteNodeMapStyle("routeRunLineCol", { backgroundColor: color })} />
            {/* Node icon and label */}
            {/* <View style={RouteNodeMapStyle("stationMarkContainer")}> */}
            {/* <Text style={RouteNodeMapStyle("stationMarkLabel")}>{label}</Text> */}
            <View style={RouteNodeMapStyle("stationMarkBg")}>
                <FIcon type="fas" name={icon} size={Layouts.routeNodeMapMarkSize - 3 - (label == "" ? 0 : 3)} color={Colors.borderColor} />
            </View>
            {/* </View> */}
        </View>
    </>)
}


// 운행중인 버스뷰
// , top, typeColor, data, 
export function RunningBus({ routeInfo, routeStations, runningBusList, station }) {
    if (!runningBusList || runningBusList.length < 1 || !routeStations || routeStations.length < 1) {
        return <></>
    }
    let bus = runningBusList.find(elem => JsUtil.GText(elem, "stationId") === JsUtil.GText(station, "stationId") && 
                                          JsUtil.GText(elem, "stationSeq") === JsUtil.GText(station, "stationSeq"));
    // let station = routeStations.find(elem => elem.stationId._text === stationId);
    if (!bus) {
        return <></>
    }

    let busAuxStatus = GBusHelper.getRunBusAuxStatus(JsUtil.GText(bus, "routeOutFlag"), JsUtil.GText(bus, "endBus"))
    if (busAuxStatus && busAuxStatus.aux !== GBusHelper.BUS_RUNS_AUX.ROUTE_OUT) {
        busAuxStatus = null;
    }
    // console.log("=====================> bus", bus)
    if (!JsUtil.isProp(bus, "routeTypeCd")) {
        bus.routeTypeCd = { _text: JsUtil.GText(routeInfo, 'routeTypeCd') }
    }

    console.log("bus tagless", bus)
    let svgName = GBus.GetBusSvgName(JsUtil.GText(routeInfo, 'routeTypeCd'), JsUtil.GText(bus, 'lowPlate'), null, JsUtil.GText(bus, 'taglessCd'))
    console.log(`svgName is ${svgName} `)
    let verticalOffset = JsUtil.GText(bus, 'stateCd') == "0" || JsUtil.GText(bus, 'stateCd') == "2" ? "50%" : "0%";
    let stateTxt = "구역";
    if(JsUtil.GText(bus, 'stateCd') == "1") {
        stateTxt += " 정차";
    }
    else {
        stateTxt += " 출발";
    }
    var styless = JsUtil.GText(bus, 'taglessCd') && JsUtil.GText(bus, 'taglessCd') === '1' ? RouteNodeMapStyle("busContainerTg", { top: verticalOffset, }) : RouteNodeMapStyle("busContainer", { top: verticalOffset, });
    return (
        <View style={RouteNodeMapStyle("busContainer", { top: verticalOffset})}>
            <View style={RouteNodeMapStyle("busStateInfoBg")}>
                <View style={RouteNodeMapStyle("busLeftColBg", {
                    backgroundColor: 'transparent',
                })}>
                    {/* 빈자리, 여유상태 */}
                    <RemainSeatCntBox bus={bus} style={{ backgroundColor: Colors.appBackgroundColor }} />
                    <DensityBox bus={bus} />
                    {/* 노선이탈? or 차량번호 */}
                    {
                        busAuxStatus 
                        ? <BusAuxStatusBox runStatusAux={busAuxStatus.aux} textOnly={true} /> 
                        : <View style={SectionStyle("rowForCol", {marginRight: 3})} >
                            <PlateNoBox bus={bus} />
                        </View>
                    }
                </View>
            </View>
            {/* 버스 아이콘 */}
            <View style={RouteNodeMapStyle("busRightIconCol")}>
                { JsUtil.GText(bus, 'taglessCd') && JsUtil.GText(bus, 'taglessCd') === '1' ? 
                    <LoadSvg name={svgName} width={Layouts.routeNodeMapBusIconSizeUp-3} height={Layouts.routeNodeMapBusIconSize-3} />
                        :
                    <LoadSvg name={svgName} width={Layouts.routeNodeMapBusIconSize} height={Layouts.routeNodeMapBusIconSize} />
                }
            </View>
        </View>
    )
}
