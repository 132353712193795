import { StyleSheet, Dimensions, PixelRatio, Platform, StatusBar } from 'react-native';

export const Colors = {
    black:          '#000000',
    hiddenTap:      'rgba(0, 0, 0, 0.01)',
    blackTrans1:    'rgba(0, 0, 0, 0.1)',
    blackTrans2:    'rgba(0, 0, 0, 0.2)',
    blackTrans3:    'rgba(0, 0, 0, 0.3)',
    blackTrans4:    'rgba(0, 0, 0, 0.4)',
    blackTrans5:    'rgba(0, 0, 0, 0.5)',
    white:          '#FFFFFF',
    whiteLight:     '#fafafa',
    blue:           '#0C479D',
    lightBlue:      '#B5C5DF',
    orange:         '#FF5425',
    green:          '#31BC31',
    red:            '#D40606',
    gray:           '#959595',
    silver:         '#EDEEF0',
    yellow:         '#EFAD32',

    alertColor:     "#EFAD32",
    infoColor:      '#0C479D',

    ggBlue:         '#0C479D',
    ggLightBlue:    '#77A1CD',
    
    ggBlueTrans1:    'rgba(0, 80, 163, 0.1)',
    ggBlueTrans2:    'rgba(0, 80, 163, 0.2)',
    ggBlueTrans3:    'rgba(0, 80, 163, 0.3)',
    ggBlueTrans5:    'rgba(0, 80, 163, 0.5)',
    ggBlueTrans7:    'rgba(0, 80, 163, 0.7)',
    ggBlueTrans9:    'rgba(0, 80, 163, 0.9)',

  
    
    // 경기 슬로건
    gsBlue:         '#0050A3',
    gsYellow:       '#AF841D',
    gsYellowTrans:  'rgba(175, 132, 29, 0.1)',
    gsGray:         '#9FA1A1',
    gsBlack:        '#1E242B',
    gsWhite:        '#FFFFFF',


    // Common colors
    appBackgroundColor:     '#fdfdfd',
    //appBackgroundColorTrans9:   '#fdfdfd',
    appBackgroundColorList:   'rgba(254,244,219,0.5)',
    dataRowColor:           '#fefefe',
    borderColor:            '#BBBBBB', // 레이아웃들의 테두리선, 보더선에서 이용하는 색갈
    borderColor2:            '#A4A4A4', // 레이아웃들의 테두리선, 보더선에서 이용하는 색갈
    borderLightColor:       '#CCCCCC', // 레이아웃들의 테두리선, 보더선에서 이용하는 색갈
    borderDarkColor:        '#A0A0A0',
    listBackgroundColor:    '#f7f7f7',
    // font
    fontColor:              '#353A50', // Default글꼴로 이용하는 색갈
    fontCommentColor:       '#707070',
    fontMiscColor:          '#A0A0A0',
    fontLightColor:         '#fefefe',
    // 
    iconColor:               '#A0A0A0',
    iconDarkColor:           '#353A50',
    //
    listGroupColor:         '#ededed',
    // Specific colors
    mainTabColor:           '#CCCCCC', // 메인바텀탭바에서 선택안되었을때 이용하는 색갈
    mainTabActiveColor:     '#FFFFFF', // 메인바텀탭바에서 선택되었을때 이용하는 색갈
    // /mainTabActiveColor:     '#f00', // 메인바텀탭바에서 선택되었을때 이용하는 색갈
    // 
    //routeBusArrivalRow:     '#ededed',
    routeBusArrivalRow:     "rgba(175, 132, 29, 0.1)",

    //
    stationBackground:     '#CCCCCC', // 정류소, 버스도착정보에 현시되는 정류소헤더부분 색갈
    routeBackground:       '#CCCCCC', // 정류소, 버스도착정보에 현시되는 정류소헤더부분 색갈
    //routeBackground:     '#f7f7f7', // 정류소, 버스도착정보에 현시되는 정류소헤더부분 색갈
    //
    
    background:       '#EDEEF0', // Default배경색으로 이용하는 색갈
    stationInfo:      '#E2E2E2', // 정류소, 버스도착정보에 현시되는 정류소헤더부분 색갈
    //
    routeNodeMapLine_Blue:        '#0C479D',
    routeNodeMapLine_Red:         '#FF0000', // 회차지방향 색갈
    //commentColor:   '#707070',
    //inputBackgroundColor: '#f7f7f7',
    inputBackgroundColor: '#ededed',
    // inputBackgroundColor: '#f7f7f7',
};

export const Layouts = {
    _def: {
        borderWidth: 0.3
    },
    windowWidth: Dimensions.get('window').width,
    windowHeight: Dimensions.get('window').height,
    //headerBarHeight: Platform.OS == 'ios' ? 44 : 50, // 70,
    headerBarHeight: Platform.OS == 'ios' ? 44 : 47, // 70,
    // manual refresh
    reloadIconSize: 45,

    // bottom tabs
    bottomBarHeight: 70,
    bottomTabMinWidth: 70, 
    bottomTabMinWidthRideSupport: 50, 
    bottomTabMaxWidth: 100, 
    // default height. padding 제외한 내부 높이
    rowHeight: 65,
    rowMinHeight: 50,
    rowBorderWidth: 1,
    rowPadding: 5,
    rowVerticalPadding: 5,
    rowHorzontalPadding: 5,
    // for left side icon area
    rowLeftIconAreaWidth: 50,    
    rowLeftIconWidth: 30,
    //
    rowButtonWidth: 35,
    rowButtonHeight: 35,
    //
    rowInputHeight: 40,
    frInputHeight: 36,
    //
    buttonBorderWidth: 0.3,
    //
    stationHeaderHeight: 70,
    routeHeaderHeight: 70,
    //
    routeNodeMapRowHeight: 70,
    routeNodeMapLeftColWidth: 110, 
    routeNodeMapMarkSize: 15,
    routeNodeMapBusIconSize: 35,
    routeNodeMapBusIconSizeUp: 45,
    routeNodeMapLeftWidthRate: 0.25,
    taglessSimpleBusIconSize: 35,
    routeNodeMapLeftWidthRate: 0.25,
    //
    busArrivalTimeBoxWidth: 75, 
    



    routeNameWidth: 75,
    //
    tabSelectHeight: 45,
    tabSelectIndicatorHeight: 3,
    // find path
    frAbbrRowHeight: 50,
    frAbbrLeftAreaWidth: 60,
    // fr detail
    frdHeaderRowHeight: 60,
    frdWalkRowHeight: 65,
    frdBusRowHeight: 95,
    frdSubwayRowHeight: 95,
    frdPathLineLeft: 44,
}

// export const styles = StyleSheet.create({
//     _def: {
//         backgroundColor: Colors.appBackgroundColor,
//     },    
// })



