// use moment always for date time !!!
import moment from 'moment';
import "moment/locale/ko";
import * as JsUtil from './JsUtil';
import AppSetting from '../defines/AppSetting';
import Texts, { GetText } from '../defines/Texts';



// set locale, and start of week
moment.updateLocale('ko',  {week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // The week that contains Jan 4th is the first week of the year.
}});

const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_DATEUTIL_LOG) {
        console.log(`[DateUtil] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][DateUtil] ${m}`, e)
}


//
export const _DT_FORMAT_DEF = "YYYY-MM-DD HH:mm:ss";
export const _DT_FORMAT_COMPACT = "YYYYMMDDHHmmss";
// 
export const _DT_FORMAT_HOUR = "YYYY-MM-DD HH:00:00";
export const _DT_FORMAT_TO_HOUR = "YYYY-MM-DD HH";
export const _DT_FORMAT_MIN = "YYYY-MM-DD HH:mm:00";
export const _DT_FORMAT_TO_MIN = "YYYY-MM-DD HH:mm";
//
export const _D_FORMAT_DEF = "YYYY-MM-DD";
export const _D_FORMAT_COMPACT = "YYYYMMDD";
//
export const _T_FORMAT_DEF = "HH:mm:ss";
export const _T_FORMAT_COMPACT = "HHmmss";
//
export const _HM_FORMAT_DEF = "HH:mm";
export const _HM_FORMAT_FORMAT = "HHmm";


export function isDateTimeString(value) {
    // 1. 숫자로 시작
    // 2. 내부에는 숫자, -, /, : " " 허용
    // 3. 숫자로 끝나야 함
    return (typeof value === "string" || value instanceof String) &&
            value.match(/^\d[\d,-/: ]+\d$/)
}


export function now() {
    return moment();
}

export function nowString(dateFormat) {
    return format(dateFormat)
}

export function nowDate() {
    return moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
}

export function nowDateString(dateFormat) {
    return format(nowDate(), dateFormat ? dateFormat : _D_FORMAT_DEF)
}

export function nowTime() {
    return moment().set({year: 0, month: 0, date: 0 });
}

export function nowTimeString(dateFormat) {
    return format(nowTime(), dateFormat ? dateFormat : _T_FORMAT_DEF)
}




// default YYYYMMDDHHmmss 
export function format(value, dateFormat) {
    try {
        // moment, format
        if (moment.isMoment(value)) {
            return value.format(dateFormat ? dateFormat : _DT_FORMAT_DEF)
        }
        // Date, format
        else if (moment.isDate(value)) {
            return moment(value).format(dateFormat ? dateFormat : _DT_FORMAT_DEF)
        }
        // DateTimeString, format
        else if (isDateTimeString(value)) {
            return toMoment(value).format(dateFormat ? dateFormat : _DT_FORMAT_DEF)
        }
        // first param only and first param is not valid date value. so consider it dateFormat
        else if (value && !dateFormat) {
            return toMoment().format(value)
        }
        else {
            return toMoment().format(_DT_FORMAT_DEF)
        }
    }            
    catch (e) {
        error("format(). Invalid datetime value. ", value)
        return value;
    }
}


export function toMoment(value) {
    if (moment.isMoment(value)) {
        return value
    }
    if (moment.isDate(value)) {
        return moment(value)
    }
    if (isDateTimeString(value)) {
        try {
            switch (value.length) {
                // H:MM
                case 4: 
                    return moment(value, "H:mm") 
                // HH:MM
                case 5: 
                    return moment(value, "HH:mm") 
                // YYMMDD
                case 6: return moment(value, "YYMMDD") //121212
                // YYYYMMDD, HH:mm:ss
                case 8:
                    if (value.includes(":")) {
                        return moment(value, "HH:mm:ss")
                    } 
                    else {
                        return moment(value, "YYYYMMDD")
                    }            
                // YYYY-MM-DD
                case 10:
                    return moment(value, "YYYY-MM-DD")
                // YYYYMMDDHHmms : 경기버스 공지/알림에 데이터 오류로, 이 포맷으로 날짜/시간이 전송됨
                case 13:
                    return moment(value + '0', 'YYYYMMDDHHmmss');
                // YYYYMMDDHHmmss YYYYMMDD HHmm
                case 14:
                    return moment(value, "YYYYMMDDHHmmss")
                // YYYY-MM-DD HH:mm
                case 16: 
                    return moment(value, "YYYY-MM-DD HH:mm")
                // YYYY-MM-DD HH:mm:ss
                case 19:
                    return moment(value, "YYYY-MM-DD HH:mm:ss")
                default: 
                    throw `Invalid datetime string. ${value}`
            }
        }
        catch(e) {
            error("toMoment(). Invalid datetime value. ", value)
            throw e;        
        }  
    }
    else if (!value){
        return moment()
    }
    else {
        error("toMoment(). unsupported value. ", value)
        throw `Unsupported datetime value type`;        
    }
}

export function toMomentDef(value) {
    try {
        if (value) {
            return toMoment(value)
        }
        else {
            return moment(0).utc()
        }
    }
    catch(e) {
        return moment(0).utc();
    }    
}

export function toDateTime(value) {
    if (moment.isDate(value)) {
        return value
    }
    else if (moment.isMoment(value)) {
        return value.toDate()
    }    
    else {
        return toMoment(value).toDate();
    }
}

// default is milliseconds
export function GetElapsed(from, to) {
    return toMoment(to).diff(toMomentDef(from))
}

export function GetElapsedSecs(from, to) {
    // let t = toMoment(to);
    // let f = toMomentDef(from);
    // console.log(`"+++++++++++++++" ${t.toISOString()}, ${f.toISOString()}`)
    // console.log(t)
    // console.log(f)

    return toMoment(to).diff(toMomentDef(from), "seconds")
}

export function GetElapsedMins(from, to) {
    return toMoment(to).diff(toMomentDef(from), "minutes")
}

export function GetElapsedHours(from, to) {
    return toMoment(to).diff(toMomentDef(from), "hours") 
}

export function GetElapsedDays(from, to) {
    return toMoment(to).diff(toMomentDef(from), "days")
}

// 0=올해 시작시각, -1=지난해 시작시각, 1=다음해 시작시각
export function GetNthYearStart(offset, from = null) {
    if (Number.isInteger(offset)) {
        if (offset >= 0) {
            return toMoment(from).clone().add(offset, "years").startOf("year")
        }
        else {
            return toMoment(from).clone().subtract(offset * -1, "years").startOf("year")
        }
    }
    else {
        return toMoment(from).clone().startOf("year")
    }
}


// 0=이번달 시작시각, -1=지난달 시작시각, 1=다음달 시작시각
export function GetNthMonthStart(offset, from = null) {
    if (Number.isInteger(offset)) {
        if (offset >= 0) {
            return toMoment(from).clone().add(offset, "months").startOf("month")
        }
        else {
            return toMoment(from).clone().subtract(offset * -1, "months").startOf("month")
        }
    }
    else {
        return toMoment(from).clone().startOf("month")
    }
}


// 0=이번주 시작시각, -1=지난주 시작시각, 1=다음주 시작시각
export function GetNthWeekStart(offset, from = null) {
    if (Number.isInteger(offset)) {
        if (offset >= 0) {
            return toMoment(from).clone().add(offset, "weeks").startOf("week")
        }
        else {
            return toMoment(from).clone().subtract(offset * -1, "weeks").startOf("week")
        }
    }
    else {
        return toMoment(from).clone().startOf("week")
    }
}


// 0=오늘 시작시각, -1=어제 시작시각, 1=내일 시작시각
export function GetNthDayStart(offset, from = null) {
    if (Number.isInteger(offset)) {
        if (offset >= 0) {
            return toMoment(from).clone().add(offset, "days").startOf("day")
        }
        else {
            return toMoment(from).clone().subtract(offset * -1, "days").startOf("day")
        }
    }
    else {
        return toMoment(from).clone().startOf("day")
    }
}

// [오늘|어제] -> [이번주|지난주] -> [이번달|지난달] -> 날짜
// const _NTH_PREV_NAMES = ["오늘", "어제", "이번주", "지난주", "이번달", "지난달"]
const _NTH_PREV_NAMES = ["TODAY","YESTERDAY","THIS_WEEK","LAST_WEEK","THIS_MONTH","LAST_MONTH",]

export function GetNthPrevName(date, from) {
    let d = toMoment(date);
    let f = toMoment(from);
    if (d.isAfter(f)) {
        return format(d)
    }
    else if (d.isSameOrAfter(GetNthDayStart(0, f))) {
        return GetText("pageWord", _NTH_PREV_NAMES[0])
    }
    else if (d.isSameOrAfter(GetNthDayStart(-1, f))) {
        return GetText("pageWord", _NTH_PREV_NAMES[1])
    }
    else if (d.isSameOrAfter(GetNthWeekStart(0, f))) {
        return GetText("pageWord", _NTH_PREV_NAMES[2])
    }
    else if (d.isSameOrAfter(GetNthWeekStart(-1, f))) {
        return GetText("pageWord", _NTH_PREV_NAMES[3])
    }
    else if (d.isSameOrAfter(GetNthMonthStart(0, f))) {
        return GetText("pageWord", _NTH_PREV_NAMES[4])
    }
    else if (d.isSameOrAfter(GetNthMonthStart(-1, f))) {
        
        return GetText("pageWord", _NTH_PREV_NAMES[5])
    }
    else {
        return format(d)
    }
}

const _WEEKDAY_NAMES_KR  = ["월", "화", "수", "목", "금", "토", "일"]
const _WEEKDAY_NAMES_EN  = ["Mon", "Tue", "Wed", "Thr", "Fr", "Sat", "Sun"]
// lang = "index|EN|KR"
export function GetWeekday(date, lang = "INDEX") {
    if (typeof date === "string" && ["EN", "KR", "INDEX"].includes(date)) {
        lang = date;
        date = null;
    }
    let d = toMoment(date);
    let idx = d.weekday()    
    if (lang.toUpperCase() === "EN") return _WEEKDAY_NAMES_EN[idx];
    if (lang.toUpperCase() === "KR") return _WEEKDAY_NAMES_KR[idx];
    return idx;
}



export function timeOnly(value) {
    const m = toMoment(value);
    return m.set({ year: 0, month: 0, date: 0 });
}
