import React, { Component } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { Header } from 'react-native-elements';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import FIcon from '../../../defines/FIcon';
import LoadImage from '../../../defines/Images';


export const ScreenHeaderWithSlogan = ({ title, iconType, iconName, onGetIconColor, onPress,// right icon
}) => {
    return (
        <Header
            containerStyle={CommonStyle("headerContainerWithSlogan", {
                padding: 0,
                margin: 0,
            })}
            leftComponent={<LoadImage name="slogan_vert_center_small" width={(123 * 40) / 95} height={40} />}
            centerComponent={{
                text: title,
                style: TextStyle('headerTitle')
            }}
            rightComponent={
                iconType && iconName
                    ? <HeaderIcon setting={{ iconType, iconName, onGetIconColor, onPress }} />
                    : null
            }
        />
    )
}


const ScreenHeader = ({ title,
    showSlogan,                                 // left slogan
    iconType, iconName, onGetIconColor, onPress,// right icon
    isLeft = false }) => {
    if (showSlogan) {
        return <ScreenHeaderWithSlogan title={title} iconType={iconType} iconName={iconName} onGetIconColor={onGetIconColor} onPress={onPress} />
    }
    else {
        return (
            <Header
                containerStyle={CommonStyle('headerContainer')}
                centerComponent={{
                    text: title,
                    style: TextStyle('headerTitle')
                }}
                rightComponent={
                    iconType && iconName && !isLeft
                        ? <HeaderIcon setting={{ iconType, iconName, onGetIconColor, onPress }} />
                        : null
                }
                leftComponent={
                    iconType && iconName && isLeft
                        ? <HeaderIcon setting={{ iconType, iconName, onGetIconColor, onPress }} />
                        : null
                }
            />
        )
    }
}

ScreenHeader.defaultProps = {
    showSlogan: true,
}

const HeaderIcon = ({ setting }) => {
    if (!setting) {
        return null;
    }
    return (
        <TouchableOpacity onPress={setting.onPress} style={CommonStyle("headerButtonBg")}>
            {

                setting.label
                    ? <Text style={TextStyle("headerButton", { color: Colors.fontCommentColor })}>{setting.label}</Text>
                    : <FIcon type={setting.iconType} name={setting.iconName} size={23} color={
                        setting.onGetIconColor ? setting.onGetIconColor() : Colors.ggBlue} />
            }
        </TouchableOpacity>
    )
}

// iconType, iconName, title
const TitleWithIcon = ({ setting }) => {
    return (
        <View style={SectionStyle("rowForRow", {
            justifyContent: "center",
            paddingRight: setting.iconType && setting.iconName ? 15 : 0
        })}>
            {
                setting.iconType && setting.iconName ?
                    <FIcon type={setting.iconType} name={setting.iconName} size={21} color={setting.iconColor}
                        style={{ marginHorizontal: 10 }} /> : null
            }
            <Text style={TextStyle("headerTitle")}>{setting.label}</Text>
        </View>
    )
}

//({ title, left, right, showSlogan }) => {
export const ScreenHeaderWithSloganEx = ({ title, right }) => {
    return (
        <Header
            containerStyle={CommonStyle("headerContainerWithSlogan", {
                padding: 0,
                margin: 0,
            })}
            leftComponent={<LoadImage name="slogan_vert_center_small" width={(123 * 40) / 95} height={40} />}
            centerComponent={
                typeof title === "object"
                    ? <TitleWithIcon setting={title} />
                    : {
                        text: title,
                        style: TextStyle('headerTitle')
                    }
            }
            rightComponent={
                <HeaderIcon setting={right} />
            }
        />
    )
}

// 헤더 좌측에 슬로건 삽입으로 left Component 무시함
export const ScreenHeaderEx = ({ title, left, right, showSlogan }) => {
    //iconType, iconName, onGetIconColor = null, onPress = null, isLeft = false }) => {
    if (showSlogan) {
        return <ScreenHeaderWithSloganEx title={title} right={right} />
    }
    else {
        return (
            <Header
                containerStyle={CommonStyle('headerContainer')}
                centerComponent={
                    typeof title === "object"
                        ? <TitleWithIcon setting={title} />
                        : {
                            text: title,
                            style: TextStyle('headerTitle')
                        }
                }
                leftComponent={
                    <HeaderIcon setting={left} />
                }
                rightComponent={
                    <HeaderIcon setting={right} />
                }
            />
        )
    }
}

export default ScreenHeader;

ScreenHeaderEx.defaultProps = {
    showSlogan: true,
}