import React from 'react';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import * as DateUtil from './DateUtil';
import AppSetting from '../defines/AppSetting';
// import { NativeModules } from 'react-native';

var SharedStorage;

if (Platform.OS === "android") {
    SharedStorage = require('../lib/SharedStorage');
}

const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_STOR_LOG) {
        console.log(`[StorUtil] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][StorUtil] ${m}`, e)
}

export const Share = (value) => {
    try {
        if (SharedStorage) {
            SharedStorage.set(
                JSON.stringify(value),
            );
            log(`ShareStorage shared`, value);
        }
    }
    catch(e) {
        error(`fail to share ${name}`, e);
    }    
}

export const Save = async (name, value, shareName, success, fail) => {
    try {
        await AsyncStorage.setItem(name, JSON.stringify(value))
        log(`${name} saved`, value);
        // if (shareName) {
        //     Share(shareName, value)
        // }
        success?.()
    }
    catch(e) {
        error("fail to save global value", e);
        fail?.()
    }    
}

export const Get = async (name, def, callback) => {
    try {
        let value = await AsyncStorage.getItem(name);
        if (value) {
            log(`${name} loaded`, value);
            callback?.(value)
            return value;
        }
        else {
            log(`${name} not found.`);
            callback?.(def)
            return def;
        }
    }
    catch(e) {
        error(`fail to read async storage. key=${name}`, e);
        callback?.(def)
        return def;
    }    
}

export const GetJSON = async (name, def, callback) => {
    try {
        let value = await AsyncStorage.getItem(name);
        if (value) {
            let j = JSON.parse(value);
            log(`${name} loaded`, j);
            callback?.(j)
            return j;
        }
        else {
            log(`${name} not found`);
            callback?.(def)
            return def;
        }
    } 
    catch(e) {
        error(`fail to read async storage. key=${name}`, e);
        callback?.(def)
        return def;
    }    
}

// removeItem
export const Remove = async (name, callback) => {
    try {
        await AsyncStorage.removeItem(name);
        log(`${name} removed`);
        callback?.(true)
    }
    catch(e) {
        error(`fail to remove async storage. key=${name}`, e);
        callback?.(false)
    }    
}

export const LoadGlobalAppVars = async (callback) => {
    log(`Loading global app variables()...`);
    let callbackParams = {}
    let value = await Get("fontSize", null);
    if (value && [0, 1, 2].includes(parseInt(value))) {
        global.fontSize = parseInt(value); 
    }
    else {
        global.fontSize = null;
        log(`Global fontSize not found.`);
    }        
    callbackParams.fontSize = global.fontSize;
    // favors
    value = await GetJSON("historyReview", [])
    if (value) { 
        global.historyReview = value;
    }
    else {
        log(`Global historyReview not found.`);
    }
    callbackParams.historyReview = global.historyReview;
    let m = await Get("widget.refresh.period", "5");
    if (m && parseInt(m) >= 5 && parseInt(m) >= 20) {
        AppSetting.WIDGET.REFRESH_PERIOD_MIN = parseInt(m)
    }
    else {
        AppSetting.WIDGET.REFRESH_PERIOD_MIN = 5
    }        

    // history
    await GetJSON("deviceId", null, (value) => { global.deviceId = value})
    await GetJSON("historyPois", [], (value) => { global.historyPois = value })
    await GetJSON("historyRoads", [], (value) => { global.historyRoads = value })
    await GetJSON("historySearchs", [], (value) => { 
        if (Array.isArray(value)) {
            value.forEach(elem => {
                if (elem.hasOwnProperty("label") && !elem.hasOwnProperty("title")) {
                    elem.title = elem.label;
                }
            })
        }
        global.historySearchs = value 
    })
    await Get("foreignLanguage", 'K', (value) => {
     global.foreignLanguage = value ? value.replace(/\"/gi,"").replace(/\'/gi,"") : 'K'
    });
    callback?.(callbackParams)
}