import React, {Component} from 'react'
import { Button, Text, View, Dimensions, Animated } from 'react-native'



const styles = {
    container: {
        flex: 1,
        backgroundColor: '#f0f',
        alignItems: 'center',
        justifyContent: 'center'
    },
    panel: {
        flex: 1,
        backgroundColor: 'white',
        position: 'relative'
    },
    panelHeader: {
        height: 120,
        backgroundColor: '#b197fc',
        alignItems: 'center',
        justifyContent: 'center'
    },
    favoriteIcon: {
        position: 'absolute',
        top: -24,
        right: 24,
        backgroundColor: '#2b8a3e',
        width: 48,
        height: 48,
        padding: 8,
        borderRadius: 24,
        zIndex: 1
    }
}

class TestPage extends Component {

//
    render() {
          return (
            <View><Text>AAAAAAAAAAAAAAAAAAAA</Text></View>
        )
    }

}

export default TestPage

