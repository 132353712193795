import React, { Component } from 'react';
import { StyleSheet, Text, View, FlatList} from 'react-native';

// lib, config
import { Colors, Layouts} from '../../defines/Theme';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import FrStyle from '../../styles/FrStyle';

import * as TMap from '../../defines/TMap';

import FrWalkDetailRow from './detailRows/FrWalkDetailRow';
import FrEndDetailRow from './detailRows/FrEndDetailRow';
import FrBusDetailRow from './detailRows/FrBusDetailRow';
import FrSubwayDetailRow from './detailRows/FrSubwayDetailRow';
import * as JsUtil from '../../utils/JsUtil';

export default class FrDetailPageInner extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { navigation, data, sortedPaths, bsOpened } = this.props
        //
        console.log("FrDetailPageInner render bsOpened", bsOpened)
        let arr = [];
        sortedPaths.forEach((item) => {
            if(item.transitMode != "WALKING"){
                arr = [...arr, item];                
            }
        })
        const endItem = arr[arr.length-1]

        return (
            <View style={CommonStyle("body", {})}>
                {/* <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    borderColor: Colors.gray,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    borderWidth: 0.5,
                    height: 60,
                    backgroundColor: Colors.borderLightColor,
                    ...styles.shadow
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingVertical: 5
                    }}>
                        <View style={{
                            height: 7, width: 50,
                            backgroundColor: Colors.borderColor,
                            borderRadius: 5
                        }} />
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingHorizontal: 20,
                        paddingVertical: 10
                    }}>
                        <Text style={ConvStyle(global.fontSize == 0 ? 16 : global.fontSize == 1 ? 17 : 18, Colors.fontColor, 150)}>
                            약{data.time._cdata}분 | {data.distance._cdata} m | 약{data.payment._cdata}원
                        </Text>
                    </View>
                </View> */}
                <View style={{ flex: 1, 
                    height: Layouts.windowHeight
                    // backgroundColor: Colors.white,
                    // backgroundColor: Colors.red
                     }}
                     accessible = {bsOpened ? false : false}>
                        
                    { bsOpened ?
                    <FlatList
                        
                        removeClippedSubviews={false}
                        initialNumToRender={20} 
                        maxToRenderPerBatch={100}
                        legacyImplementation={true}
                        windowSize={50}
                        data={sortedPaths}
                        renderItem={({ item, index, separators }) => {                            
                            // console.log("~~~~~~item", item)
                            if(item.transitMode == 'WALKING') {
                                if(index == 0) {
                                    return <FrWalkDetailRow 
                                                type={'start'}
                                                beforeColor={'transparent'}                                                
                                                item={item} 
                                                beforeItem={sortedPaths[index]}/>
                                
                                } else {
                                    return <FrWalkDetailRow 
                                                type={'end'} 
                                                beforeColor={TMap.GetPrevColorForDetail(index, sortedPaths)}
                                                item={item}
                                                beforeItem={sortedPaths[index-1]}/>
                                }
                            } else if(item.transitMode == 'SUBWAY') {
                                return <FrSubwayDetailRow
                                    beforeColor={TMap.GetPrevColorForDetail(index, sortedPaths)}
                                    selfColor={TMap.GetColorFromSubway(JsUtil.GText(item.subway, "name"))}
                                    isTransfer={TMap.IsTransferNode(index, sortedPaths)}
                                    item={item}
                                />
                                
                            } else if(item.transitMode == 'BUS') {
                                return <FrBusDetailRow
                                    beforeColor={TMap.GetPrevColorForDetail(index, sortedPaths)}
                                    selfColor={TMap.GetColorFromBus(JsUtil.GText(item.bus,"typeName"))}
                                    isTransfer={TMap.IsTransferNode(index, sortedPaths)}
                                    item={item}
                                />
                            }
                        }}
                        ListFooterComponent={()=>{
                            return <FrEndDetailRow
                                beforeColor={TMap.GetLastColor(sortedPaths)}    
                                end={endItem.endStationName} />
                        }}
                        refreshing={false}
                        keyExtractor={
                            (item, index) => index.toString()
                        }
                    /> : null }
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    body: {
        flex: 1
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
})