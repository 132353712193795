import Bug from '../utils/Bug';
import {Colors} from './Theme';
import * as JsUtil from '../utils/JsUtil';

/** 
 *  SK TMap 길찾기 API 에서 수신되는 데이터 처리용
 *  지침서와 버스앱 소스의 식별값이 달라, 버스앱 소스를 적용함. 2020.10.10
 **/

const TMap = {
    // TMapApi.laneColor, {label, shortLabel, color} = 앱에서 표출할 이름, 짧은 이름, 색상
    // TMap 에서 회신하는 laneColor 가 코드처럼 정해진 것이 아니라서, 실 데이터에 맞게 업데이트해주어야 함.
    SUBWAYS: new Map([
        // TMap 문서에 정의된 값. 
        ['1호선',            { label: '1호선',           short: '1',   	    color: '#002F95' }],
        ['2호선',            { label: '2호선',           short: '2',   	    color: '#00B71E' }],
        ['3호선',            { label: '3호선',           short: '3',   	    color: '#FF8100' }],
        ['4호선',            { label: '4호선',           short: '4',   	    color: '#0F87CE' }],
        ['5호선',            { label: '5호선',           short: '5',   	    color: '#7A1ABA' }],
        ['6호선',            { label: '6호선',           short: '6',   	    color: '#B47221' }],
        ['7호선',            { label: '7호선',           short: '7',   	    color: '#4E6012' }],
        ['8호선',            { label: '8호선',           short: '8',   	    color: '#E61C86' }],
        ['9호선',            { label: '9호선',           short: '9',   	    color: '#AB8A3B' }],
        ['인천선',                  { label: '인천선',          short: '인천', 	    color: '#6F97BF' }],
        ['분당선',                  { label: '분당선',          short: '분당', 	    color: '#F5C70F' }],
        ['수인분당선',              { label: '수인분당선',      short: '수인\n분당', 	    color: '#F5C70F' }],
        ['공항철도',                { label: '공항선',          short: '공항', 	    color: '#2F64C8' }],
        ['중앙선',                  { label: '중앙선',          short: '중앙', 	    color: '#3FC0CD' }],
        ['경의선',                  { label: '경의선',          short: '경의', 	    color: '#3FC0CD' }],
        ['경춘선',                  { label: '경춘선',          short: '경춘', 	    color: '#3FC0CD' }],
        ['신분당선',                { label: '신분당선',        short: '신분당', 	color: '#D21246' }],
        ['의정부경전철',            { label: '의정부선',        short: '의정부', 	color: '#FF8E01' }],
        ['수인선',                  { label: '수인선',          short: '수인', 	    color: '#F5C70F' }],
        ['부산 1호선',              { label: '1호선',           short: '1',         color: '#FF7019' }],
        ['부산 2호선',              { label: '2호선',           short: '2',         color: '#00B71E' }],
        ['부산 3호선',              { label: '3호선',           short: '3',         color: '#C3A26C' }],
        ['부산 4호선',              { label: '4호선',           short: '4',         color: '#6f8cc0' }],
        ['부산 부산-김해경전철',    { label: '부산-김해경전철', short: '부산-김해', color: '#772E91' }],
        ['대구 1호선',              { label: '1호선', 			short: '1',         color: '#FF3219' }],
        ['대구 2호선',              { label: '2호선',           short: '2',         color: '#00B71E' }],
        ['대전 1호선',              { label: '1호선',           short: '1',         color: '#00B71E' }],
        ['광주 1호선',              { label: '1호선',           short: '1',         color: '#00B71E' }],
        // 추가, 개발중 데이터 발견되어 추가. 2020.10.11
        ['경의중앙선',              { label: '경의중앙선',      short: '경의',      color: '#3FC0CD' }],
        // 기존 소스
		['인천 1호선',              { label: '인천1호선', 	    short: '인천1',     color: '#6F97BF' }],
		['인천 2호선',              { label: '인천2호선',       short: '인천2',     color: '#FCB651' }],
        ['에버라인',                { label: '에버라인선',      short: '에버\n라인',  color: '#24a816' }],
		['경강선',                  { label: '경강선',          short: '경강',      color: '#2675F5' }],
		['우이신설선',              { label: '우이신설선',      short: '우이\n신설',  color: '#BFCB35' }],
        ['자기부상철도',            { label: '자기부상철도',    short: '자기\n부상',  color: '#DC9D0F' }],
        // Unknown
        ['*',                       { label: '전철',            short: '전철',      color: '#0A0A0A' }],
    ]),

    // ROUTE_TYPES: new Map([
    //     ['0',  { label: '기타',     short: '기타',     color: '#24a816' }],
    //     ['1',  { label: '일반',     short: '일반',     color: '#24a816' }],
    //     ['2',  { label: '좌석',     short: '좌석',     color: '#34a7d2' }],
    //     ['3',  { label: '마을',     short: '마을',     color: '#24a816' }],
    //     ['4',  { label: '직행좌석', short: '직행좌석', color: '#FD000C' }],
    //     ['5',  { label: '공항',     short: '공항',     color: '#808080' }],
    //     ['6',  { label: '직행좌석', short: '직행좌석', color: '#ed3015' }],
    //     ['10', { label: '외곽',     short: '외곽',     color: '#31BC31' }],
    //     ['11', { label: '간선',     short: '간선',     color: '#34a7d2' }],
    //     ['12', { label: '지선',     short: '지선',     color: '#31BC31' }],
    //     ['13', { label: '순환',     short: '순환',     color: '#F5C70F' }],
    //     ['14', { label: '광역',     short: '광역',     color: '#ed3015' }],
    //     ['15', { label: '급행',     short: '급행',     color: '#ed3015' }],
    //     ['20', { label: '농어촌',   short: '농어촌',   color: '#31BC31' }],
    //     ['22', { label: '시외',     short: '시외',     color: '#8400ff' }],
    //     ['26', { label: '급행간선', short: '급행간선', color: '#8400ff' }],
    //     // Unknown
    //     ['*',  { label: '일반',     short: '일반',     color: '#24a816' }],
    // ]),
    ROUTE_TYPES: new Map([
        ['기타',  { label: '기타',     short: '기타',     color: '#24a816' }],
        ['일반',  { label: '일반',     short: '일반',     color: '#31BC31' }],
        ['좌석',  { label: '좌석',     short: '좌석',     color: '#34a7d2' }],
        ['마을',  { label: '마을',     short: '마을',     color: '#FEB204' }],
        // ['직행좌석',  { label: '직행좌석', short: '직행좌석', color: '#FD000C' }],
        ['공항',  { label: '공항',     short: '공항',     color: '#808080' }],
        ['직행좌석',  { label: '직행좌석', short: '직행좌석', color: '#ed3015' }],
        ['외곽', { label: '외곽',     short: '외곽',     color: '#31BC31' }],
        ['간선', { label: '간선',     short: '간선',     color: '#34a7d2' }],
        ['간선급행', { label: '간선급행',     short: '간선',     color: '#34a7d2' }],
        ['지선', { label: '지선',     short: '지선',     color: '#31BC31' }],
        ['순환', { label: '순환',     short: '순환',     color: '#F5C70F' }],
        ['광역', { label: '광역',     short: '광역',     color: '#ed3015' }],
        ['급행', { label: '급행',     short: '급행',     color: '#ed3015' }],
        ['농어촌', { label: '농어촌',   short: '농어촌',   color: '#31BC31' }],
        ['시외', { label: '시외',     short: '시외',     color: '#8400ff' }],
        ['경기도 시외형', { label: '시외',     short: '시외',     color: '#8400ff' }],
        ['급행간선', { label: '급행간선', short: '급행간선', color: '#8400ff' }],
        ['광역급행', { label: '광역급행', short: '광역급행', color: '#8400ff' }],
        // Unknown
        ['*',  { label: '일반',     short: '일반',     color: '#24a816' }],
    ]),
};

 
export const GetTMapSubway = (laneAlias) => {
    // console.log("GetTMapSubway", laneAlias)
    if (TMap.SUBWAYS.has(laneAlias)) {
        return TMap.SUBWAYS.get(laneAlias);
    } 
    else {
        Bug('GetTMapSubway', `식별할 수 없는 전철. ${laneAlias}`, 6);
        return { ...TMap.SUBWAYS.get('*'), label: laneAlias };
    }
}

export const GetTMapSubwayLabel = (laneAlias) => GetTMapSubway(laneAlias).label;
export const GetTMapSubwayShort = (laneAlias) => GetTMapSubway(laneAlias).short;
export const GetTMapSubwayColor = (laneAlias) => GetTMapSubway(laneAlias).color;

export const GetTMapSubwayIconType = (str) => {
    if (str == '1호선' || str == '2호선' || str == '3호선' || str == '4호선' || str == '5호선' ||
        str == '6호선' || str == '7호선' || str == '8호선' || str == '9호선' ||
        str == '부산 1호선' || str == '부산 2호선' || str == '부산 3호선' || str == '부산 4호선' ||
        str == '대구 1호선' || str == '대구 2호선' || str == '대전 1호선' || str == '광주 1호선') {
        return 'num'
    } else {
        return 'str'
    }
}


export const GetTMapRoute = (type) => {
    if (TMap.ROUTE_TYPES.has(type)) {
        return TMap.ROUTE_TYPES.get(type)
    } 
    else {
        Bug('GetTMapRoute', `식별할 수 없는 노선유형. ${type}`, 6);
        return { ...TMap.ROUTE_TYPES.get('*'), label: type };
    }
}

export const GetTMapRouteLabel = (type) => GetTMapRoute(type).label;
export const GetTMapRouteShort = (type) => GetTMapRoute(type).short;
export const GetTMapRouteColor = (type) => GetTMapRoute(type).color;

export const GetFriendlyDistance = (m) => {
    let d = parseInt(m);
    if (d > 1000) {
       return Number(d / 1000).toFixed(1) + "km"
    }
    else {
        return m + " m"
    }
}

export const GetAsMoney = (m) => {
    return m.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}


// 길찾기에서 이전 아이템의 색갈을 얻는 함수
export function GetPrevColor(pathIndex, paths) {
    let idx = paths.findIndex(elem => Number(elem.pathIndex._text) == pathIndex);
    if (idx > 0) {
        if (paths[idx-1].moveType._cdata == 'Subway') {
            return GetColorFromSubway(paths[idx-1].laneAlias._cdata)
        } 
        else if (paths[idx-1].moveType._cdata == 'Bus') {
            return GetColorFromBus(paths[idx-1].lane.type._cdata)
        } 
    } 
    return Colors.gray;
}


// 길찾기상세페이지에서 이전 아이템의 색갈을 얻는 데모함수
export function GetPrevColorForDetail(pathIndex, paths) {    
    if( pathIndex > 0) {
        if (paths[pathIndex-1].transitMode == 'SUBWAY') {
            console.log("GetPrevColorForDetail ", JsUtil.GText(paths[pathIndex-1].subway, "name"))
            return GetColorFromSubway(JsUtil.GText(paths[pathIndex-1].subway, "name"))
        } 
        else if (paths[pathIndex-1].transitMode == 'BUS') {
            console.log("GetPrevColorForDetail ", JsUtil.GText(paths[pathIndex-1].bus,"typeName"))
            return GetColorFromBus(JsUtil.GText(paths[pathIndex-1].bus,"typeName"))
        } 
    }

    return Colors.gray;
}

export function GetLastColor(paths) {
    if (paths[paths.length-1].transitMode == 'SUBWAY') {
        console.log("GetLastColor", JsUtil.GText(paths[paths.length-1].subway, "name"))
        return GetColorFromSubway(JsUtil.GText(paths[paths.length-1].subway, "name"))
    }
    else if (paths[paths.length-1].transitMode == 'BUS') {
        console.log("GetLastColor", JsUtil.GText(paths[paths.length-1].bus,"typeName"))
        return GetColorFromBus(JsUtil.GText(paths[paths.length-1].bus,"typeName"))    
    }

    return Colors.gray
}

export function GetColorFromSubway(lane) {
    return GetTMapSubwayColor(lane);
}

export function GetColorFromBus(lane) {
    return GetTMapRouteColor(lane);
}

// 현 지점이 승차점인가, 아니면 환승지점인가를 검사하는 함수
export function IsTransferNode(index, paths) {
    return index > 0 && paths[index-1].transitMode != 'WALKING';
}

// function getFromRegexKeys(key, map) {
//     for (const [re, val] of map.entries()) {
//       const res = re.exec(key); // or `key.match(re)`
//       if (res) return val(res);
//     }
//   }


// export default TEXTS;
export function getTimeHMS(time){
    let seconds = time * 1;        
    let str = "";
    if(parseInt(seconds/3600)>0){
        str = parseInt(seconds/3600) + '시간' 
        if(parseInt((seconds%3600)/60)>0) {
            str = str + ' '    + parseInt((seconds%3600)/60) + '분'
        }
    } else {
        str = parseInt((seconds%3600)/60) + '분'
    }
    return str
}