import React, { Component } from 'react';
import {
    Platform, Dimensions, Text, View, TouchableWithoutFeedback, Keyboard,
    TouchableOpacity, Button, TextInput, KeyboardAvoidingView,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Rating, AirbnbRating } from 'react-native-ratings';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

//context
import AlarmContext from '../../context/AlarmContext';
// lib, config
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import { GetText, GetTextSido } from '../../defines/Texts';
import { Colors, Layouts } from '../../defines/Theme';

import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as DateUtil from '../../utils/DateUtil';
import * as JsUtil from '../../utils/JsUtil';

import GAPI from '../../api/RestAPI';
//
import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerMiniV from '../components/common/SpacerMiniV';
import ListEmpty from '../components/parts/ListEmpty';
import SpacerListBottom from '../components/common/SpacerListBottom';

let UserScriptApi = require('../../utils/UserScriptApi');
export default class BusEval extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            stationId: '',
            routeId: '',
            rating: 2.5,
            comments: ""
        }
        this.ratingCompleted = this.ratingCompleted.bind(this)
    }

    ratingCompleted(r) {
        this.setState({ rating: r })
    }

    componentDidMount() {
        UserScriptApi.apiConnect('busEval')
        this.setState({
            stationId: JsUtil.GText(this.props.route.params, "stationId"),
            routeId: JsUtil.GText(this.props.route.params, "routeId"),
        })
        // console.log(`[BusEval] station, route=${JsUtil.GText(this.props.route.params, "stationId")},${JsUtil.GText(this.props.route.params, "routeId")}`)
        // console.log(this.props.route)
    }
    componentWillUnmount() {
        // this._focus?.();
    }

    sendEval() {
        //console.log(`[BusEval] sendEval().`)
        // get boards
        GAPI.doRequest('sendBusEval',
            this.state.rating,
            this.state.comments,
            this.state.stationId,
            this.state.routeId,
        )
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    console.log(`[BusEval] done`)
                    this.context.msgHandler?.dlg(GetText("pageWord","ALERT"),
                        `버스평가에 응해주셔서 감사합니다.\n보내주신 소중한 의견은\n더 나은 버스 서비스를 위해 사용하겠습니다.`,
                        Layouts.rowHeight * 2)
                    this.props.navigation.navigate("home");
                }
                else {
                    console.log(`[BusEval] fail to get boards. ${code}, ${msg}`)
                    this.context.msgHandler?.toast(GetText("error", "unknown"));
                }
            })
            .catch(err => {
                console.log("Unknown request error...");
                console.log(err);
                this.context.msgHandler?.toast(GetText("error", "unknown"));
            })
            .finally(() => {
            })
    }

    render() {
        return (

            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    showSlogan={false}
                    title={GetText("pageTitle", "BUS_EVAL")}
                />
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={CommonStyle("body")}>
                    <View style={SectionStyle('rowForslogan',
                        {
                            flexDirection: "row", justifyContent: "center", paddingHorizontal: 5,
                            borderColor: Colors.borderColor,
                            borderBottomWidth: 0.7,
                        })}>
                        <LoadImage name="slogan" width={225} height={27} />
                    </View>
                    <View style={CommonStyle('body', {
                        backgroundColor: Colors.appBackgroundColor,
                        // padding: 20,
                        // backgroundColor: "#00f", 
                    })}>
                        <View style={CommonStyle("box", {
                            // borderColor: Colors.borderLightColor, 
                            // borderWidth: 0.7, borderRadius: 5,
                            justifyContent: "center", alignItems: "center",
                            marginTop: 10,
                            //margin: 20,
                            //paddingVertical: 10,
                            height: 200,
                            // backgroundColor: "#ff0"
                        })}>
                            <Text style={TextStyle("rowDetail", "large", { textAlign: "center", lineHeight: 25, })}>
                                {/* {`소중한 의견과 평가를 주시면\n버스 품질 개선에 반영하겠습니다`}. */}
                                {GetText("pageComment","BUS_EVAL_COMMENT")}
                        </Text>
                            <View style={CommonStyle("box", {
                                alignSelf: "stretch",
                                borderColor: Colors.borderLightColor, borderWidth: 0.7, borderRadius: 5,
                                justifyContent: "center", alignItems: "stretch",
                                margin: 10,
                                //backgroundColor: "#00f"
                            })}>

                                <TextInput
                                    // blurOnSubmit
                                    multiline
                                    numberOfLines={4}
                                    // returnKeyType="done"
                                    style={TextStyle('inputCustom')}
                                    placeholder={GetText("pageComment","PLZ_COMMENT")}
                                    onChangeText={(text) => {
                                        this.setState({ comments: text.trim() });
                                    }}
                                />

                            </View>
                        </View>
                        <View style={CommonStyle("box", { height: 20, justifyContent: "flex-end", alignItems: "center" })} >
                            <Text style={TextStyle("rowDetail", "large")}>{GetText("pageWord","BUS_SATISFACTION")}</Text>
                        </View>
                        <View style={CommonStyle("box", {
                            borderColor: Colors.borderLightColor,
                            borderWidth: 0.7, borderRadius: 5,
                            justifyContent: "center", alignItems: "center",
                            margin: 10,
                            paddingVertical: 10,
                            //height: 200,
                            // backgroundColor: "#f0f",

                        })}>

                            <View style={SectionStyle("rowForRow", { alignItems: "center" })} >

                                <View style={CommonStyle("box", { justifyContent: "center" })}>
                                    <Rating
                                        type="star"
                                        ratingCount={5}
                                        fractions={1}
                                        startingValue={this.state.rating}
                                        imageSize={40}
                                        onFinishRating={this.ratingCompleted}
                                        showRating={false}
                                        style={{ paddingVertical: 20 }}
                                    />
                                </View>
                                <View style={CommonStyle("box", { marginLeft: 20 })} >
                                    <Text style={TextStyle("rowDetail", "huge", {
                                        color: Colors.ggBlue
                                    })}>
                                        {this.state.rating} 
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{
                            height: 300,
                            //backgroundColor: "#0ff",
                        }}></View>

                    </View>
                    </View>
                </TouchableWithoutFeedback>
                <BottomRightTabs navigation={this.props.navigation}
                    tabs={[
                        {
                            name: 'send',
                            onPress: () => {
                                this.sendEval()
                            },
                            title: '전송'
                        }
                    ]} />
            </SafeAreaView>
        )
    }
}



