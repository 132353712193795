import React, { Component } from 'react';
import { View, Alert, Text, StyleSheet, Dimensions, TouchableOpacity } from "react-native";
import Toast from "react-native-fast-toast";

import Modal from 'react-native-modal';
import BottomSheet from "./BottomSheet";
import AppSetting from "../../../defines/AppSetting";
import LoadImage from "../../../defines/Images";
import FIcon from "../../../defines/FIcon";
import { Colors, Layouts } from "../../../defines/Theme";
import Texts, { GetText, GetTextSido } from "../../../defines/Texts";
import * as DateUtil from "../../../utils/DateUtil";
import * as DeviceUtil from '../../../utils/DeviceUtil';
import * as LinkUtil from '../../../utils/LinkUtil';
import CommonStyle from "../../../styles/CommonStyle";
import DlgStyle from "../../../styles/DlgStyle";
import TextStyle from "../../../styles/TextStyle";
import MessageContent from '../../board/MessageContent'
import EventContent from '../../board/EventContent'

/**
 * props
animationType	    string	    Background animation ("none", "fade", "slide")	"none"
height	            number	    Height of Bottom Sheet	                        260
minClosingHeight	number	    Minimum height of Bottom Sheet before close	    0
openDuration	    number	    Open Bottom Sheet animation duration	        300 (ms)
closeDuration	    number	    Close Bottom Sheet animation duration	        200 (ms)
closeOnDragDown	    boolean	    Use gesture drag down to close Bottom Sheet	    false
dragFromTopOnly	    boolean	    Drag only the top area of the draggableIcon to close Bottom Sheet instead of the whole content	false
closeOnPressMask	boolean	    Press the area outside to close Bottom Sheet	true
closeOnPressBack	boolean	    Press back android to close Bottom Sheet (Android only)	true
onClose	function	Callback    function when Bottom Sheet has closed	null
onOpen	function	Callback    function when Bottom Sheet has opened	null
customStyles	    object	    Custom style to Bottom Sheet	{}
keyboardAvoidingViewEnabled	    boolean	Enable KeyboardAvoidingView	            true (ios)

 * 
 */
const TitleIcon = ({ icon }) => {
    switch (icon) {
        case "alert": return <FIcon type="fad" name="exclamation-circle" size={22} color={Colors.alertColor} />
        case "info": return <FIcon type="fad" name="info-circle" size={22} />
    }
    return null;
}

// Dialog title. icon + title 
const TitleRow = ({ icon, title }) => {
    return (
        title
            ? <View style={DlgStyle("bsTitleRow", icon ? { paddingRight: 25 } : null)}>
                {
                    icon
                        ? <View style={DlgStyle("bsTitleIconBg")}>
                            <TitleIcon icon={icon} />
                        </View>
                        : null
                }
                <Text style={DlgStyle("bsTitle")}>{title}</Text>
            </View>
            : null
    )
}


const msg_fields = ["msg", "remark", "title", "icon", "height", "shadowBackground", "timeout"];
const toast_fields = ["msg", "byUser", "dur"];
const deviceWidth = Dimensions.get("window").width;
const deviceHeight = Dimensions.get("window").height;

class MsgHandler extends Component {

    constructor(props) {
        super(props)
        this.state = {
            os: "",
            msgs: {},
            lastMsgTime: null,
            toasts: {},
            lastToastTime: null,
            //
            modalVisible: false,
            modalTitle: "",
            modalMessage: "",
            modalRemark: "",
            modalHeight: null,
            modalButtons: {
                close: {
                    visible: true,
                    label: GetText("pageTabLabel", "CLOSE"),
                    callback: null
                },
                cancel: {
                    visible: false,
                    label: GetText("pageTabLabel", "CANCEL"),
                    callback: null
                },
                ok: {
                    visible: false,
                    label: GetText("pageTabLabel", "OK"),
                    callback: null
                }
            },

            popupVisible: false,
            popupTitle: "",
            popupContentType: "",
            popupContents: [],
            popupShowNoMore: true,
            popupContentIndex: 0,
            popupFeedback: {
                shownIndexes: [],
                noMoreShow: false,
            },
            //popupShownIndexes: [],
            popupCloseCallback: null,
            popupButtons: {
                close: {
                    visible: true,
                    label: GetText("pageTabLabel", "CLOSE"),
                    callback: null
                },
                prev: {
                    visible: false,
                    label: "이전",
                    callback: null
                },
                next: {
                    visible: false,
                    label: "다음",
                    callback: null
                }
            },
            // modalButtonClose: null,
            // modalButtonClose_callback: null,
            // modalButtonOK: null,
            // modalButtonOK_callback: null,
        }

        // bottom sheet
        this.bs = React.createRef();
        // bind this
        this._openBottomSheet = this._openBottomSheet.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this)
        this.onClosePopup = this.onClosePopup.bind(this)
    }

    // the first toast || new toast || after 30 seconds from last toast
    _isDuplicatedToast(msgs) {
        if (!this.state.lastToastTime) return false;
        if (msgs.byUser) {
            console.log("msg byUser is true")
            console.log(msgs)
            return false;
        }
        let now = DateUtil.now()
        let isSame = !toast_fields.some(elem => this.state.toasts[elem] !== msgs[elem]);
        if (isSame && (DateUtil.GetElapsedSecs(this.state.lastToastTime) < AppSetting.MSG_HANDLER.IGNORE_SAME_INTERVAL_SEC)) {
            return true;
        }
        return false;
    }

    _showToast(msgs) {
        if (this._isDuplicatedToast(msgs)) {
            console.log(`[MsgHandler] duplicated toast ignored. ${msgs.msg}`);
        }
        else {
            this.setState({
                toasts: msgs,
                lastToastTime: DateUtil.now()
            }, () => {
                toast?.show(this.state.toasts.msg)
            });
        }
    }

    toast(msg, byUser, dur) {
        this._showToast({ msg, byUser, dur });
    }

    /**
     * msgs =
     * {
     *  message:
     *  remark
     *  title
     *  icon
     *  height
     *  shadowBackground
     *  timeout 
     * }
     * 
     */
    // the first message || new message || after 30 seconds from last message
    _isDuplicatedMsg(msgs) {
        if (!this.state.lastMsgTime) return false;
        let now = DateUtil.now()
        let isSame = !msg_fields.some(elem => this.state.toasts[elem] !== msgs[elem]);
        if (isSame && (DateUtil.GetElapsedSecs(this.state.lastMsgTime) < AppSetting.MSG_HANDLER.IGNORE_SAME_INTERVAL_SEC)) {
            return true;
        }
        return false;
    }

    _openBottomSheet() {
        if (!this.bs) {
            console.log(`[MsgHandler] _openBottomSheet().error. bottom sheet was not referenced.`)
        }
        this.bs.open();
        if (this.state.msgs.timeout) {
            if (this.closeTimer) {
                clearInterval(this.closeTimer);
            }
            this.closeTimer = setTimeout(() => {
                this.hide();
            }, this.state.msgs.timeout * 1000);
        }
    }

    show(msgs) {
        if (!this.bs) {
            console.log(`[MsgHandler] error. bottom sheet was not referenced.`)
            return;
        }
        if (this.bs.isVisible()) {
            console.log(`[MsgHandler] prior bottom sheet shown already.`)
            return;
        }
        if (this._isDuplicatedMsg(msgs)) {
            console.log(`[MsgHandler] duplicated message ignored. ${msgs.msg}`);
            return;
        }
        // if (!AppSetting.DEBUGS.showRemarkInMsgBox && msgs.remark) {
        //     msgs.remark = null;
        // }
        this.setState({
            msgs: msgs,
            lastMsgTime: DateUtil.now()
        },
            () => {
                this._openBottomSheet();
            });
    }
    open(msg, remark = "", timeout = 0) {
        // console.log("[xxxxxxxxxxxBottomDlg] -=------------");
        // console.log(this.BS);
        this.show({
            msg,
            remark,
        })
    }

    hide() {
        if (this.closeTimer) {
            clearInterval(this.closeTimer);
        }
        if (!this.bs) {
            console.log(`[MsgHandler] hide().error. bottom sheet was not referenced.`)
            return;
        }
        this.bs.close();
    }

    close() {
        this.hide();
    }

    // sophisticated 
    error(sender, msg, error = null) {
        // let msg = GetText("error", "unknown");
        let remark = "";
        if (error) {
            // remark = JSON.stringify(error)
            try {
                if (typeof error === 'string' || error instanceof String || Number.isInteger(error)) {
                    remark = `(오류코드: ${error})`
                }
                else if (error.hasOwnProperty("message") && (typeof error["message"] === "string" || error["message"] instanceof String)) {
                    remark = error["message"]
                }
                else {
                    remark = JSON.stringify(error)
                }
                // else if (error.hasOwnProperty("toString")) {
                //     remark = error.toString()
                // }
            }
            catch (e) {
                remark = `Fail to prove error`
            }
        }
        console.log(`[${sender}] error. ${msg}`, error)
        if (msg) {
            this.toast(msg)
        }
        if (remark) {
            if (AppSetting.DEBUGS.SHOW_ERROR_FORCE) {
                this.dlg(sender, `${msg}\n${remark}`, Layouts.rowHeight * 4)
            }
        }
    }

    dlg(title, message, messageHeight, callback) {
        let modalButtons = {
            close: { visible: true, label: GetText("pageTabLabel", "CLOSE"), callback: callback },
            ok: { visible: false },
            cancel: { visible: false },
        }
        let h = messageHeight
            ? (Layouts.rowHeight * 2 + messageHeight)
            : (Layouts.rowHeight * 3)
        this.setState({
            modalHeight: h,
            modalVisible: true,
            modalGotoStore: false,
            modalTitle: title,
            modalMessage: message,
            modalRemark: "",
            modalButtons: modalButtons
        })
    }


    dialog({ title, message, remark, ok, cancel, messageHeight }) {
        let modalButtons = {};
        if (ok && cancel) {
            modalButtons = {
                close: { visible: false },
                ok: { visible: true, label: GetText("pageTabLabel", "OK"), callback: ok },
                cancel: { visible: true, label: GetText("pageTabLabel", "CANCEL"), callback: cancel },

            }
        }
        else {
            modalButtons = {
                close: { visible: true, label: GetText("pageTabLabel", "CLOSE"), callback: ok ? ok : null },
                ok: { visible: false },
                cancel: { visible: false },
            }
        }
        let h = messageHeight
            ? (Layouts.rowHeight * 2 + (remark ? Layouts.rowHeight : 0) + messageHeight)
            : (Layouts.rowHeight * 3 + (remark ? Layouts.rowHeight : 0))
        console.log("==== set modal height: " + h);
        this.setState({
            modalHeight: h,
            modalVisible: true,
            modalGotoStore: false,
            modalTitle: title,
            modalMessage: message,
            modalRemark: remark,
            modalButtons: modalButtons
        })
    }

    dialogGotoStore = () => {
        let title = "알림";
        let message = "모바일앱에서만 지원하는 기능입니다.";
        let messageHeight = Layouts.rowHeight * 1.3;
        let os = "web";
        if (!this.state.os) {
            os = DeviceUtil.getWebOSSync();
        }
        else {
            os = this.state.os;
        }
        if (os == "android") {
            message += `\n플레이스토어로 이동하시겠습니까?`;
            messageHeight = Layouts.rowHeight * 2;
        }
        else if (os == "iOS") {
            message += `\n앱스토어로 이동하시겠습니까?`;
            messageHeight = Layouts.rowHeight * 2;
        }
        let modalButtons = {
            close: { visible: os == "web", label: GetText("pageTabLabel", "CLOSE"), callback: null },
            ok: { visible: os !== "web", label: GetText("pageTabLabel", "OK"), callback: () => {
                LinkUtil.openMobileApp(os);
            }},
            cancel: { visible: os !== "web", label: GetText("pageTabLabel", "CLOSE"), callback: null },
        }
        let h = messageHeight ? (Layouts.rowHeight * 2 + messageHeight) : (Layouts.rowHeight * 3);
        this.setState({
            os: os,
            modalHeight: h,
            modalVisible: true,
            modalGotoStore: true,
            modalTitle: title,
            modalMessage: message,
            modalRemark: "",
            modalButtons: modalButtons
        })
    }

    onCloseModal() {
        this.setState({ modalVisible: false });
    }

    onClosePopup() {
        this.setState({ popupVisible: false });
    }


    renderModalButtons() {
        if (this.state.modalButtons.ok.visible && this.state.modalButtons.cancel.visible) {
            return (<>
                <TouchableOpacity
                    style={DlgStyle("dialogButtonL")}
                    onPress={() => {
                        this.setState({ modalVisible: false }, () => {
                            this.state.modalButtons.cancel.callback?.()
                        })
                    }}
                >
                    <Text style={TextStyle("dialogButtonText", "large")}>{this.state.modalButtons.cancel.label}</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={DlgStyle("dialogButtonR")}
                    onPress={() => {
                        this.setState({ modalVisible: false }, () => {
                            this.state.modalButtons.ok.callback?.()
                        })
                    }}
                >
                    <Text style={TextStyle("dialogButtonText", "large")}>{this.state.modalButtons.ok.label}</Text>
                </TouchableOpacity>
            </>);
        }
        else {
            return (
                <TouchableOpacity
                    style={DlgStyle("dialogButton")}
                    onPress={() => {
                        this.setState({ modalVisible: false }, () => {
                            this.state.modalButtons.close.callback?.()
                        })
                    }}
                >
                    <Text style={TextStyle("dialogButtonText", "large")}>{this.state.modalButtons.close.label}</Text>
                </TouchableOpacity>
            )
        }
    }

    renderModalContents() {
        return (<>
            <View style={DlgStyle("dialogBg")}>
                <View style={DlgStyle("dialogContents", {
                    alignItems: "stretch",
                    borderRadius: 20,
                    overflow: "hidden",
                    backgroundColor: Colors.appBackgroundColor,
                    width: Layouts.windowWidth * 0.8,
                    height: this.state.modalHeight,
                })}>
                    <View style={DlgStyle("dialogHeader")}>

                        <Text style={TextStyle("dialogHeaderText", "large")}>{this.state.modalTitle}</Text>
                    </View>
                    <View style={DlgStyle("dialogBody", this.state.modalRemark ? { borderColor: Colors.borderLightColor } : null)}>
                        <Text style={TextStyle("dialogBodyText", "medium")}>{this.state.modalMessage}</Text>
                    </View>
                    {
                        this.state.modalRemark ?
                            <View style={DlgStyle("dialogRemark")}>
                                <Text style={TextStyle("dialogRemarkText", "small")}>{this.state.modalRemark}</Text>
                            </View> : null
                    }
                    <View style={DlgStyle("dialogFooter")}>
                        {this.renderModalButtons()}
                    </View>
                </View>
            </View>
        </>)
    }

    renderGotoStoreContents() {
        return (<>
            <View style={DlgStyle("dialogBg")}>
                <View style={DlgStyle("dialogContents", {
                    alignItems: "stretch",
                    borderRadius: 20,
                    overflow: "hidden",
                    backgroundColor: Colors.appBackgroundColor,
                    width: Layouts.windowWidth * 0.8,
                    height: this.state.modalHeight,
                })}>
                    <View style={DlgStyle("dialogHeader", {
                        flexDirection: "row",
                        justifyContent: "center"
                    })}>
                        {
                            this.state.os == "android"
                            ? <LoadImage name="android" width={35} height={35} style={{ marginHorizontal: 10 }} />
                            : (this.state.os == "iOS" ? <LoadImage name="appstore" width={35} height={35} style={{ marginHorizontal: 10 }} /> : null)
                        }
                        <Text style={TextStyle("dialogHeaderText", "large", { marginLeft: 10})}>{this.state.modalTitle}</Text>
                        {
                            this.state.os != "web" ? <View style={{ width: 60 }} /> : null
                        }
                    </View>
                    <View style={DlgStyle("dialogBody", this.state.modalRemark ? { borderColor: Colors.borderLightColor } : null)}>
                        <Text style={TextStyle("dialogBodyText", "medium")}>{this.state.modalMessage}</Text>
                    </View>
                    <View style={DlgStyle("dialogFooter")}>
                        {this.renderModalButtons()}
                    </View>
                </View>
            </View>
        </>)
    }

    popup({ title, contentType, contents, closeCallback, enableNoMore }) {
        this.setState({
            popupVisible: contents && contents.length > 0,
            popupTitle: title,
            popupContentType: contentType,
            popupContents: contents,
            popupShowNoMore: enableNoMore,
            popupContentIndex: 0,
            popupFeedback: { shownIndexes: [0], noMoreShow: false },
            popupCloseCallback: closeCallback,
        })
    }

    renderPopupNoMoreRow() {
        return (
            <View style={{ backgroundColor: "transparent" }}>
                <TouchableOpacity style={DlgStyle("dialogNoMoreRow")}
                    onPress={() => {
                        this.setState({
                            popupFeedback: {
                                ...this.state.popupFeedback,
                                noMoreShow: !this.state.popupFeedback.noMoreShow,
                            }
                        })
                    }}
                >
                    <Text style={TextStyle("rowDetail", "small", { color: Colors.ggBlue, marginHorizontal: 10 })}>
                        {GetText("board", "NO_MORE_SHOW")}
                    </Text>
                    <FIcon
                        type={this.state.popupFeedback.noMoreShow ? "fas" : "fad"}
                        name="check-circle"
                        color={this.state.popupFeedback.noMoreShow ? Colors.ggBlue : Colors.borderColor}
                        size={20}
                    />
                </TouchableOpacity>
            </View>
        )
    }

    renderPopupButtons() {
        // if (this.state.popupContents.length > 1) {
        return (<>
            { this.state.popupContents.length > 1 ?
                <TouchableOpacity
                    style={DlgStyle("dialogButtonL")}
                    activeOpacity={this.state.popupContentIndex > 0 ? 0.5 : 1}
                    onPress={() => {
                        let idx = Math.max(0, this.state.popupContentIndex - 1);
                        if (idx !== this.state.popupContentIndex) {
                            this.setState({
                                popupContentIndex: idx,
                                popupFeedback: {
                                    ...this.state.popupFeedback,
                                    shownIndexes: [...this.state.popupFeedback.shownIndexes, idx],
                                }
                            })
                        }
                    }}
                >
                    <Text style={TextStyle("dialogButtonText", "large")}>
                        {this.state.popupButtons.prev.label}</Text>
                </TouchableOpacity> : null
            }
            <TouchableOpacity
                style={DlgStyle("dialogButton")}
                onPress={() => {
                    this.setState({ popupVisible: false }, () => {
                        //this.state.popupCloseCallback?.(this.state.popupShownIndexes)
                        this.state.popupCloseCallback?.(this.state.popupFeedback)
                    })
                }}
            >
                <Text style={TextStyle("dialogButtonText", "large")}>{this.state.popupButtons.close.label}</Text>
            </TouchableOpacity>
            { this.state.popupContents.length > 1 ?
                <TouchableOpacity
                    style={DlgStyle("dialogButtonR")}
                    activeOpacity={this.state.popupContentIndex < this.state.popupContents.length - 1 ? 0.5 : 1}
                    onPress={() => {
                        let idx = Math.min(this.state.popupContents.length - 1, this.state.popupContentIndex + 1);
                        if (idx !== this.state.popupContentIndex) {
                            this.setState({
                                popupContentIndex: idx,
                                popupFeedback: {
                                    ...this.state.popupFeedback,
                                    shownIndexes: [...this.state.popupFeedback.shownIndexes, idx],
                                }
                            })
                        }
                    }}
                >
                    <Text style={TextStyle("dialogButtonText", "large")}>{this.state.popupButtons.next.label}</Text>
                </TouchableOpacity> : null
            }
        </>);
        // }
        // else {
        //     return (
        //         <TouchableOpacity
        //             style={DlgStyle("dialogButton")}
        //             onPress={() => {
        //                 this.setState({ popupVisible: false }, () => {
        //                     // this.state.modalButtons.close.callback?.()
        //                 })
        //             }}
        //         >
        //             <Text style={TextStyle("dialogButtonText", "large")}>{this.state.popupButtons.close.label}</Text>
        //         </TouchableOpacity>
        //     )
        // }
    }



    renderPopupContents() {
        return (<>
            <View style={DlgStyle("dialogBg")}>
                <View style={{
                    alignItems: "stretch",
                    borderRadius: 20,
                    overflow: "hidden",
                    backgroundColor: Colors.appBackgroundColor,
                    width: Layouts.windowWidth * 0.8,
                    height: deviceHeight * 0.9, width: deviceWidth * 0.9
                }}>
                    <View style={DlgStyle("dialogHeader")}>
                        <Text style={TextStyle("dialogHeaderText", "large")}>{this.state.popupTitle}</Text>
                    </View>
                    {
                        this.state.popupContentType === "event"
                            ? <EventContent event={this.state.popupContents[this.state.popupContentIndex]} />
                            : <MessageContent board={this.state.popupContents[this.state.popupContentIndex]} isPopup={true} />
                    }
                    {
                        this.state.popupShowNoMore ? this.renderPopupNoMoreRow() : null
                    }
                    <View style={DlgStyle("dialogFooter")}>
                        {this.renderPopupButtons()}
                    </View>
                </View>
            </View>
        </>)
    }

    render() {
        // if (!this.state.msgs || !this.state.msgs.message) {
        //     return <></>
        // }
        const { children, onClose } = this.props;
        const { msg, remark, title, icon, height, shadowBackground } = this.state.msgs;
        let h = height || AppSetting.DEF_BOTTOM_SHEET_HEIGHT;
        let wrapperStyle = shadowBackground ? { backgroundColor: '#00000020' } : null;
        return (<>
            <Toast ref={(ref) => global['toast'] = ref} />
            <Modal
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
                //isVisible Props에 State 값을 물려주어 On/off control
                isVisible={this.state.modalVisible}
                //아이폰에서 모달창 동작시 깜박임이 있었는데, useNativeDriver Props를 True로 주니 해결되었다.
                useNativeDriver={true}
                hideModalContentWhileAnimating={true}
                style={DlgStyle("dialogModal")}
                // open={open} 
                onModalHide={this.onCloseModal}
            >
                {this.state.modalGotoStore
                    ? this.renderGotoStoreContents()
                    : this.renderModalContents()
                }
                {/* {this.renderModalContents(this.state.posts[this.state.selectedPostIndex])}</div> */}
            </Modal>
            {/* 풀팝업 */}
            <Modal
                deviceWidth={deviceWidth}
                deviceHeight={deviceHeight}
                //isVisible Props에 State 값을 물려주어 On/off control
                isVisible={this.state.popupVisible}
                //아이폰에서 모달창 동작시 깜박임이 있었는데, useNativeDriver Props를 True로 주니 해결되었다.
                useNativeDriver={true}
                hideModalContentWhileAnimating={true}
                style={DlgStyle("dialogModal")}
                // open={open} 
                onModalHide={this.onClosePopup}
            >
                {this.renderPopupContents()}
                {/* {this.renderModalContents(this.state.posts[this.state.selectedPostIndex])}</div> */}
            </Modal>
            <BottomSheet
                ref={bs => { this.bs = bs }}
                customStyles={{
                    wrapper: wrapperStyle
                }}
                height={h}
                animationType="slide"
                closeOnDragDown={true}
                closeOnPressMask={true}
                openDuration={150}
                closeDuration={100}
                onClose={onClose}
            >
                <View style={DlgStyle("bsContentBg")}>
                    <TitleRow icon={icon} title={title} />
                    <View style={DlgStyle("bsMsgRow")}>
                        <Text style={TextStyle("bsMsg", "medium")}>{msg}</Text>
                    </View>
                    <Text style={TextStyle("bsMsgRemark")}>{remark}</Text>
                </View>
                {/* <Button title="Close" onPress={() => {this.close()}} /> */}
                {children}
            </BottomSheet>
        </>);
    }
}

export default MsgHandler;

