import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';

import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import SectionStyle from '../../../styles/SectionStyle';
import FrStyle from '../../../styles/FrStyle';
import SpacerV from '../../components/common/SpacerV';

export default function FrEndDetailRow({beforeColor, end}) {
    return (<>
        <View style={FrStyle("frdEndRowBg")} accessible={true}>
            <View style={FrStyle("frdWalkStartIconBg")}>
                <FIcon type="fad" name='map-marker-alt' size={30} color={Colors.fontColor} />
            </View>
            <Text style={TextStyle("rowTitleDesc", "medium", { marginVertical: 5})}>
                도착 | {end}
            </Text>            
        </View>
        <View height={Layouts.rowHeight * 2} />
    </>)
}
