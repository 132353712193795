import React, { Component } from 'react';
// import { WebView } from 'react-native-webview';
import { View, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// configurations
import AppSetting from '../../defines/AppSetting';
import { Colors, Layouts } from '../../defines/Theme';
import LoadImage from '../../defines/Images';
import FIcon from '../../defines/FIcon';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import MapStyle from '../../styles/MapStyle';
import TextStyle from '../../styles/TextStyle';
import * as DeviceUtil from '../../utils/DeviceUtil';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

// shared
import MainBottomTab, { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerMiniV from '../components/common/SpacerMiniV';


export default function DeviceInfo(props) {
    return (
        <SafeAreaView style={CommonStyle('bg')}>
            <ScreenHeader
                title="장치정보"
            />
            <View style={CommonStyle("body")}>
                {/* <View style={{ flex: 1, width: "100%", alignSelf: "stretch" }}>
                    <Text>자동으로 경기버스정보 홈으로 이동합니다...</Text>
                </View> */}
                <DeviceUtil.DeviceInfoView />
            </View>
            <BottomRightTabs navigation={props.navigation} />
        </SafeAreaView >
    );
}
