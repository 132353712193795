import React, { Component } from "react";
import { StyleSheet, Easing } from "react-native";
import PropTypes from "prop-types";
import {
    View,
    KeyboardAvoidingView,
    Modal,
    TouchableOpacity,
    Animated,
    PanResponder,
    Platform
} from "react-native";
// import Elevations from "react-native-elevation";

import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';

const SUPPORTED_ORIENTATIONS = [
    "portrait",
    "portrait-upside-down",
    "landscape",
    "landscape-left",
    "landscape-right"
];

class BottomSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalVisible: props.visible === true ? true : false,
            deltaY: new Animated.Value(props.height),
        }
        this.createPanResponder(props);
    }

    isVisible() {
        return this.state.modalVisible;
    }

    setModalVisible(visible, props) {
        const { height, openDuration, closeDuration, onClose, onOpen } = this.props;
        const { deltaY } = this.state;
        if (visible) {
            this.setState({ 
                modalVisible: visible, 
                delta: new Animated.Value(height),
            });
            onOpen?.(this.props);
            Animated.timing(deltaY, {
                useNativeDriver: true,
                toValue: 0,
                duration: openDuration,
            }).start(() => {
                // console.log("After show. deltaY");
                // console.log(deltaY);
            });
        } else {
            this.setState({
                modalVisible: visible,
            });
            onClose?.(this.props);
            // console.log("Start close. deltaY");
            // console.log(deltaY);

              
            // Animated.timing(deltaY, {
            //     useNativeDriver: true,
            //     toValue: height,
            //     duration: closeDuration
            // }).start(() => {
            //     this.setState({
            //         modalVisible: visible,
            //     });
            //     onClose?.(this.props);
            // });
        }
    }

    createPanResponder(props) {
        const { closeOnDragDown, closeDragRate, height } = props;
        const { deltaY } = this.state;
        this.panResponder = PanResponder.create({
            onStartShouldSetPanResponder: () => closeOnDragDown,
            onPanResponderMove: (e, gestureState) => {
                if (gestureState.dy > 0) {
                    Animated.event([null, { dy: deltaY }], { useNativeDriver: false })(e, gestureState);

                }
            },
            onPanResponderRelease: (e, gestureState) => {
                if (height * closeDragRate - gestureState.dy < 0) {
                    this.setModalVisible(false);
                } else {
                    Animated.spring(deltaY, { toValue: 0, useNativeDriver: true }).start();
                }
            }
        });
    }

    open(props) {
        console.log("Opening bottom sheet");
        this.setModalVisible(true, props);
    }

    close(props) {
        this.setModalVisible(false, props);
    }
    render() {
        const {
            animationType,
            closeOnDragDown,
            dragFromTopOnly,
            closeOnPressMask,
            closeOnPressBack,
            children,
            customStyles,
            keyboardAvoidingViewEnabled,
            headerComponent
        } = this.props;
        const { deltaY, modalVisible } = this.state;
        // console.log("BottomSheet render()");
        // console.log(deltaY);
        const transformStyle = {
            transform: [{ translateY: deltaY }]
        };

        return (
            <Modal
                transparent
                animationType={animationType}
                visible={modalVisible}
                supportedOrientations={SUPPORTED_ORIENTATIONS}
                onRequestClose={() => {
                    if (closeOnPressBack) this.setModalVisible(false);
                }}
            >
                {/* whole background for full screen */}
                {}
                <KeyboardAvoidingView
                    enabled={keyboardAvoidingViewEnabled}
                    behavior="padding"
                    style={[styles.wrapper, customStyles.wrapper]}
                >
                    {/* upper masking area  */}
                    <TouchableOpacity
                        style={styles.mask}
                        activeOpacity={1}
                        onPress={() => (closeOnPressMask ? this.close() : null)}
                    />
                    {/* visible bottom sheet area with panning handler  */}
                    <Animated.View elevation={5}
                        {...(!dragFromTopOnly && this.panResponder.panHandlers)}
                        style={[transformStyle, styles.container, { height: this.props.height }, customStyles.container]}
                    >
                        {/* top draggable bar  */}
                        {/* {closeOnDragDown ? this.renderDraggableHeader() : null} */}
                        {closeOnDragDown && (
                        <View
                            {...(dragFromTopOnly && this.panResponder.panHandlers)}
                            style={[styles.draggableContainer, customStyles.draggableContainer]}
                        >
                            <View style={[styles.draggableIcon, customStyles.draggableIcon]} />
                            {headerComponent}
                        </View>
                        )}                         
                        {children}
                    </Animated.View>
                </KeyboardAvoidingView>
            </Modal>
        );
    }
}

BottomSheet.propTypes = {
    animationType: PropTypes.oneOf(["none", "slide", "fade"]),
    height: PropTypes.number,
    openDuration: PropTypes.number,
    closeDuration: PropTypes.number,
    closeOnDragDown: PropTypes.bool,
    closeDragRate: PropTypes.number,
    closeOnPressMask: PropTypes.bool,
    dragFromTopOnly: PropTypes.bool,
    closeOnPressBack: PropTypes.bool,
    keyboardAvoidingViewEnabled: PropTypes.bool,
    customStyles: PropTypes.objectOf(PropTypes.object),
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    children: PropTypes.node
};

BottomSheet.defaultProps = {
    animationType: "none",
    height: 130,
    openDuration: 300,
    closeDuration: 200,
    closeOnDragDown: false,
    closeDragRate: 0.3,
    dragFromTopOnly: false,
    closeOnPressMask: true,
    closeOnPressBack: true,
    keyboardAvoidingViewEnabled: Platform.OS === "ios",
    customStyles: {},
    headerComponent: null,
    onClose: null,
    onOpen: null,
    children: <View />
};

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        // backgroundColor: "#00000050",
        backgroundColor: "transparent",
        // paddingHorizontal: 0.2,
    },
    mask: {
        flex: 1,
        backgroundColor: "transparent",
    },
    container: {
        width: "100%",
        overflow: "hidden",
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
        backgroundColor: Colors.ggBlue, 
    },
    draggableContainer: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        // backgroundColor: "#0f0",
    },
    draggableIcon: {
        width: 60,
        height: 7,
        borderRadius: 5,
        marginTop: 10,
        marginBottom: 20,
        backgroundColor: Colors.appBackgroundColor, // ggLightBlue,
        elevation: 1,
    },

});

export default BottomSheet;