import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import {
    StyleSheet, Text, View, TouchableOpacity, TextInput,
    TouchableWithoutFeedback, Keyboard, ScrollView, FlatList, Alert,
    SectionList
} from 'react-native';
import { NavigationContext } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

// context
import AlarmContext from '../../context/AlarmContext';

// config, lib
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as ArrayUtil from '../../utils/ArrayUtil';
import * as HistoryUtil from '../../utils/HistoryUtil';
import { Colors, Layouts } from '../../defines/Theme'; 
import Texts, { GetText, GetTextSido } from '../../defines/Texts';
import AppSetting from '../../defines/AppSetting';
import * as GBus from '../../defines/GBus';
import * as JsUtil from "../../utils/JsUtil";
import GAPI, { TAPI } from '../../api/RestAPI';
// import RestAPI from '../../api/RestAPI';



// shared
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import TabSelectBar from '../components/common/TabSelectBar';
import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import SpacerListBottom from '../components/common/SpacerListBottom';
import ListEmpty from '../components/parts/ListEmpty';
import ListHeader from '../components/parts/ListHeader';
import SectionHeader from '../components/parts/SectionHeader';

// include
import RouteRow from './include/RouteRow'

let UserScriptApi = require('../../utils/UserScriptApi');

export function HeaderTitle({ title }) {
    return (
        <View style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: Colors.background,
            paddingHorizontal: 10,
            paddingVertical: 5
        }} >
            <Text style={TextStyle("rowDetail")}>{title}</Text>
        </View>
    )
}

export default function ShowAllRoutes({ route, navigation }) {
    UserScriptApi.apiConnect('showAllRoutes')

    const context = useContext(AlarmContext);
    const flatRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState('night');
    // null -> loading..., [] => no items
    const [records, setRecords] = useState(null);
    const [sectionRecords, setSectionRecords] = useState(null);

    useEffect(() => {
        try {
            refreshRoutes();
        } catch (ex) { }
    }, [selectedTab])

    useEffect(() => {
        try {
            flatRef.current?.scrollToOffset({
                animated: false,
                offset: 0
            })
        } catch (ex) { }
    }, [records])

    const refreshRoutes = () => {
        switch (selectedTab) {
            case "low":
            case "night":
                getRoutesFromXmlFile(selectedTab);
                return;
            case "air":
            case "out":
                getRoutesByBusType(selectedTab === "air" ? "A" : "O");
                return;
        }
    }

    const _buildNightLowBusList = (items) => {
        let busList = items.map(elem => {
            let line = elem._text;
            items = line.split("_");
            if (items.length > 6) {
                return {
                    adminName: { _text: items[0] },
                    routeId: { _text: items[1] },
                    routeName: { _text: items[2] },
                    routeTypeCd: { _text: items[3] },
                    routePath: { _text: items[4] },
                    description: { _text: items[5] },
                    regionName: { _text: items[6] },
                }
            }
            else {
                return null;
            }
        }).filter(elem => elem)

        // sectionList [ {title, data: []} ]
        let sectionList = []
        busList.forEach(elem => {
            let adminElem = GetTextSido(JsUtil.GText(elem,"adminName"))
            let gItem = sectionList.find(groupElem => groupElem.title === adminElem);
            
            if (gItem) {
                gItem.data.push(elem)
            }
            else {
                sectionList.push({ title: adminElem, data: [elem]})
            }
        })
        setSectionRecords(sectionList)
    }


    // ~/array/[low|night].xml
    const getRoutesFromXmlFile = (busType) => {
        setSectionRecords(null);
        showPageLoader(true);
         // 노선 목록 얻기
        GAPI.doRequest('getRoutesFromXml', busType)
             .then((res) => {
                if (res.resources && res.resources["string-array"] && 
                    res.resources["string-array"].item && Array.isArray(res.resources["string-array"].item)) {
                    _buildNightLowBusList(res.resources["string-array"].item)
                }
                else {
                    setSectionRecords([])
                    context.msgHandler?.toast(GetText("general", "no_item"))
                }
             })
             .catch(err => {
                 console.log("Unknown request error...");
                 console.log(err);
                 context.msgHandler?.toast(GetText("error", "unknown"))
             })
             .finally(() => {
                 showPageLoader(false)
             })
     }

    const getRoutesByBusType = (busType) => {
        setRecords(null)
        showPageLoader(true)

        // 노선 목록 얻기
        GAPI.doRequest('getRoutesByBusType', busType)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    setRecords(res.response.msgBody.routeList.busRouteList)
                }
                else if (code == 4) {
                    context.msgHandler?.toast(GetText("general", "no_item"))
                    setRecords([])
                }
                else {
                    console.log("Unknown request error...");
                    console.log(err);
                    context.msgHandler?.toast(GetText("error", "unknown"))
                }
            })
            .catch(err => {
                console.log("Unknown request error...");
                console.log(err);
                context.msgHandler?.toast(GetText("error", "unknown"))
            })
            .finally(() => {
                showPageLoader(false)
            })
    }

    return (
        <SafeAreaView style={CommonStyle('bg')}>
            <ScreenHeader
                title={GetText("pageTitle","SHOW_ALL_ROUTES")}
            />
            <View style={CommonStyle("body")}>
                <TabSelectBar
                    tabs={{
                        night: GetText("pageWord","NIGHT_BUS"),
                        low: GetText("pageWord","LOW_BUS"),
                        out: GetText("pageWord","OUT_BUS"),
                        air: GetText("pageWord","AIR_BUS")                        
                    }}
                    activeId={selectedTab}
                    onPress={(id) => {
                        setRecords(null);
                        setSelectedTab(id);
                    }}
                    useFullRow={true}
                />

                <View style={CommonStyle("listBg", { 
                    flex: 1
                })}>
                { ["air", "out"].includes(selectedTab) ? 
                    <FlatList
                        ref={flatRef}
                        removeClippedSubviews={true}
                        legacyImplementation={true}
                        windowSize={10}
                        initialNumToRender={20} 
                        data={records}
                        stickyHeaderIndices={[0]}
                        ListHeaderComponent={
                            <ListHeader 
                                text={ selectedTab === 'out' ? 
                                        GetText("pageWord","OUT_BUS") + "(" + GetText("pageWord","ARRIVAL_INFO_ROUTE")+ ")"// "시외버스(도착정보제공노선)" 
                                      : GetText("pageWord","AIR_BUS") + "(" + GetText("pageWord","ARRIVAL_INFO_ROUTE")+ ")" // "공항버스(도착정보제공노선)" 
                                     } 
                                style={{ justifyContent: "center", backgroundColor: Colors.whiteLight }}
                            />
                        }
                        ListEmptyComponent={<ListEmpty textId={ records ? "NO_ITEM" : "LOADING_DATA" } />}
                        ListFooterComponent={<SpacerListBottom />}
                        renderItem={({ item, index, separators }) => {
                            return <RouteRow item={item} navigation={navigation} />
                        }}
                        refreshing={false} 
                        keyExtractor={(item, index) => 'key' + index}
                    />
                    :
                    <SectionList
                        sections={sectionRecords}
                        stickySectionHeadersEnabled={true}
                        renderSectionHeader={({ section }) => <SectionHeader text={section.title} />}
                        renderItem={({ item }) => {
                            return <RouteRow item={item} navigation={navigation} />
                        }}
                        ListEmptyComponent={<ListEmpty textId={ sectionRecords ? "NO_ITEM" : "LOADING_DATA" } />} 
                        ListFooterComponent={<SpacerListBottom />}
                        keyExtractor={(item, index) => index}
                    /> 
                }                   
                </View>
            </View>
            <BottomRightTabs navigation={navigation} />
        </SafeAreaView>
    )
}
