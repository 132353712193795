import _ from 'lodash';
import axios from 'axios';
import { GetServiceKey } from '../../defines/AppSetting';
import { parseResponse } from './axiosResponseParser';
var convert = require('xml-js');

// GG API
// const apiUrl = `http://220.64.14.147/`;
const apiUrl = `https://api.gbis.go.kr/`;

export const apiXmlClient = axios.create({
    baseURL: apiUrl,
});

export const apiJsonClient = axios.create({
    baseURL: apiUrl,
});

const appendDefaultParams = (url, params) => {
    // if (url && typeof url !== 'string') {
    //     console.error(` === appendDefaultParams() warn. type of url=${typeof url}`, url);
    // }
    try {
        if (
            url &&
            !_.includes(url, 'serviceKey') &&
            // !url.includes('serviceKey') &&
            (!params || !params.hasOwnProperty('serviceKey'))
        ) {
            return _.defaults({ serviceKey: GetServiceKey() }, params);
        }
    } catch (err) {
        console.error(' === appendDefaultParams() error. url=', url);
    }
    return params;
};

// defalut is xml return
export const apiXmlFetcher = (url, params) => {
    // console.log('=== apiXmlFetcher', url);
    return apiXmlClient
        .get(url, {
            params: appendDefaultParams(url, params),
            responseType: 'text',
        })
        .then(res => {
            return res.data;
        })
        .then(xmlText => {
            if (typeof xmlText === 'string' || xmlText instanceof String) {
                let res = JSON.parse(convert.xml2json(xmlText, { compact: true, spaces: 4 }));
                return res;
            }
            throw new Error(`not supported ressponse data type: ${typeof xmlText}`);
        });
};

/*---
 args = useSwr([url, params], ...) 를 사용하면, [url, params] 가 그대로 인자로 전달됩니다.
 ; 정확히는 [url, params] 는 swr cache key 로 기능할 뿐입니다.
 ; 복수의 request 를 하나의 swr 에 묶어 사용하는 것을 지원하는데, 이때 규칙은
   useSwr([대표URL, [request1, request2, ...], ...) 형태로 사용해야 합니다.
   request1,2 = [url, params] 형태로 주어야 합니다.
 ; args[0] = 대표URL, args[1] = 실제 요청할 [url, params][]
*/
export const apiXmlArrayFetcher = (...args) => {
    // console.log('=== apiXmlArrayFetcher', args);
    if (!Array.isArray(args) || args.length < 2 || !Array.isArray(args[1])) {
        throw new Error(
            `apiXmlArrayFetcher 사용법 오류. args is Arry:${Array.isArray(args)}, args.length=${
                args?.length
            }`,
        );
    }
    return axios.all(
        args[1]
            .map(urlParam => {
                return urlParam[0]
                    ? apiXmlFetcher(urlParam[0], urlParam.length > 1 ? urlParam[1] : null)
                    : null;
            })
            .filter(el => el),
    );
};

export const apiJsonFetcher = (url, params) => {
    // console.log('=== apiJsonFetcher', url);
    return apiJsonClient
        .get(url, {
            params: appendDefaultParams(url, params),
            // responseType: 'json', json is default!
        })
        .then(res => res.data);
};
