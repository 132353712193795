import React from 'react';
import { View, Text } from 'react-native';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import * as JsUtil from '../../../utils/JsUtil';

import SpacerH from '../common/SpacerH';
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import BusTroubleBox from './BusTroubleBox';

// busStatus
// //--- 운행상태 기본값
// busStatus.runStatus = BUS_RUNS.OK; 
// busStatus.runStatusReason = "";
// //--- 운행부가상태 기본값
// busStatus.runStatusAux = BUS_RUNS_AUX.NONE;
// busStatus.runStatusAuxReason = "";
// //--- 장애상태 기본값
// busStatus.trouble = BUS_TROUBLES.NONE;
// busStatus.troubleReason = "";

export const BusAuxStatusMark = ({ bus }) => {
    if (!bus || !bus.runStatusAux || bus.runStatusAux === GBusHelper.BUS_RUNS_AUX.NONE) {
        return null
    }
    let text = GetText("arrival", bus.runStatusAux)
    if (!text) {
        return null;
    }
    return (<>
        <View style={{ alignSelf: "stretch", justifyContent:"center" }}>
            {
                bus.runStatusAux === GBusHelper.BUS_RUNS_AUX.END_BUS 
                ? <FIcon type="fas" name="step-forward" color={Colors.yellow} size={14} />
                : <View style={SectionStyle("busAuxStatusMark", {marginLeft: 3})}></View>
            }
            
        </View>
    </>)
}


// 지체등 일부 비정상인 경우 도착시간 옆에 표시됨
const BusAuxStatusBox = ({ bus, runStatusAux, textOnly }) => {
    let text = "";
    if (!runStatusAux) {
        if (!bus || !bus.runStatusAux || bus.runStatusAux === GBusHelper.BUS_RUNS_AUX.NONE) {
            return null
        }
        text = GetText("arrival", bus.runStatusAux)
    }
    else {
        text = GetText("arrival", runStatusAux)
    }
    if (!text) {
        return null;
    }
    return (<>
        <View style={SectionStyle("busAuxStatusTextBg", textOnly ? { borderWidth: 0 } : null)}>
            <Text numberOfLines={1} style={TextStyle("busAuxStatusText", 'small')}>
                {text}
            </Text>
        </View>
    </>)
}


export default BusAuxStatusBox