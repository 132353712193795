import React from 'react';
import { View, Text } from 'react-native';
import * as JsUtil from '../../../utils/JsUtil';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

import * as GBus from '../../../defines/GBus';
import { Colors, Layouts } from '../../../defines/Theme';
import SpacerH from '../common/SpacerH';


const _GetRouteNameTextSize = (textLen) => {
    if (textLen < 6) return "largest"
    if (textLen < 8) return "large"
    return "small"
}

const _GetSecondRouteNameTextSize = (len) => {
    if (len < 6) return "large"
    return "small"
}

const _GetSecondLeftSpaceWidth = (name, len, baseWidth) => {
    return baseWidth
    console.log(`-------------_GetSecondLeftSpaceWidth(). ${name}. len=${len}`)
    if (len < 6) return baseWidth
    if (len > 11) return baseWidth
    return baseWidth * 0.75
    if (len < 8) return baseWidth * 0.6
    if (len < 10) return baseWidth * 0.5
    return baseWidth * 0.4
}


const RouteNameBox = ({ routeName, routeTypeCd, leftSpaceWidth }) => {

    // 
    if (!routeName || !routeTypeCd) {
        console.log(`===> fixme. routeName, routeTypeCd is not defined. ${routeName}, ${routeTypeCd}`)
    }
    let { name, lines, maxLength } = GBus.ParseRouteName(routeName);

    // 한줄에 표시해야 하는 노선명. 길이가 적절하거나, () 등이 사용되지 않은 노선명
    if (lines.length === 1) {
        return (
            <View style={SectionStyle("rowForRow")} >
                {leftSpaceWidth ? <View style={{width: leftSpaceWidth}} /> : null}
                <Text style={TextStyle('rowTitle', _GetRouteNameTextSize(maxLength), {
                    color: GBus.GetRouteTypeColor(routeTypeCd),
                    width: Layouts.routeNameWidth,
                    //backgroundColor: "#f00"
                })}>
                    {name}
                </Text>
            </View>
        )
    }
    else {
        return (<>
            <View style={SectionStyle("rowForRow")} >
                {leftSpaceWidth ? <View style={{width: leftSpaceWidth}} /> : null}
                <View style={SectionStyle("rowForCol")} >
                    <Text style={TextStyle('rowTitle', _GetRouteNameTextSize(JsUtil.getTextLength(lines[0])), {
                        color: GBus.GetRouteTypeColor(routeTypeCd),
                        width: Layouts.routeNameWidth,
                        //backgroundColor: "#f00"
                    })}>
                        {lines[0]}
                    </Text>
                    <Text style={TextStyle('rowTitle', _GetRouteNameTextSize(JsUtil.getTextLength(lines[1])), {
                        color: GBus.GetRouteTypeColor(routeTypeCd),
                        width: Layouts.routeNameWidth,
                        //backgroundColor: "#f00"
                    })}>
                        {lines[1]}
                    </Text>
                </View>
            </View>
        </>)
    }

}
export default RouteNameBox