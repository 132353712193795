// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Bug from '../utils/Bug';


export const styles = {
    _def: {
        backgroundColor: '#f00',
    },
    // 최외곽 컨테이너, 상하 border default
    columnBg: {
        flexDirection: 'column',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        borderTopWidth: Layouts.rowBorderWidth,
        borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    // 최외곽 컨테이너, 상하 border default
    columnBgTopBorder: {
        flexDirection: 'column',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        borderTopWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    // 최외곽 컨테이너, 상하 border default
    columnBgBottomBorder: {
        flexDirection: 'column',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderLightColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    columnBgNoBorder: {
        flexDirection: 'column',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    rowBg: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        borderTopWidth: Layouts.rowBorderWidth,
        borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    rowBgTopBorder: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        borderTopWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    rowBgBottomBorder: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderLightColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    rowBgNoBorder: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },
    rowForslogan: {
        height: Layouts.headerBarHeight,
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        // paddingTop: 10,
        // paddingBottom: 5, //Vertical: 10,
        paddingVertical: 10,
        //        borderColor: Colors.borderLightColor,
        //borderTopWidth: Layouts._def.borderWidth,
        //        borderBottomWidth: Layouts._def.borderWidth,
        backgroundColor: Colors.appBackgroundColor,
        zIndex: 1
    },

    // 입력행
    rowForInput: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderLightColor,
        // //borderTopWidth: Layouts.rowBorderWidth,
        borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
        marginBottom: 3,
        elevation: 3,
        // backgroundColor: "#f00"
    },
    rowForInputNoBorder: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: Colors.appBackgroundColor,
        //borderColor: Colors.borderLightColor,
        // //borderTopWidth: Layouts.rowBorderWidth,
        // borderBottomWidth: Layouts.rowBorderWidth,
        // paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
        marginBottom: 3,
        //elevation: 3,
        // backgroundColor: "#f00"
    },

    // 
    rowForCol: {
        flexDirection: 'column',
        backgroundColor: "transparent", //  Colors.appBackgroundColor,
    },
    rowForRow: {
        flexDirection: 'row',
        backgroundColor: "transparent", //  Colors.appBackgroundColor,
    },
    // 높이를 가진 컨테이너 레이아웃. 최외곽 컨터이너의 padding 을 고려해서 높이 사용
    rowForHCol: {
        flexDirection: 'column',
        backgroundColor: Colors.appBackgroundColor,
        alignItems: 'center',
        height: Layouts.rowHeight,
    },
    rowForHRow: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        alignItems: 'center',
        height: Layouts.rowHeight,
    },
    // 상위컨테이너를 진행방향(flexDirection)으로 꽉 채우는 행, 
    rowFill: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent', // Colors.appBackground,
    },
    rowFillForRow: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent', // Colors.appBackground,
    },
    rowFillStretch: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: 'transparent', // Colors.appBackground,        
    },
    // 상위컨테이너를 가로/세로로 꽉 채우고, 자식항목은 space-around, 
    rowFillStretchAround: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: 'transparent', //Colors.appBackground,        
        justifyContent: 'space-around',
    },
    rowFillStretchForRow: {
        flex: 1,
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent', // Colors.appBackground,        
    },

    // 
    rowLeftIconArea: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Layouts.rowLeftIconAreaWidth,
        backgroundColor: 'transparent', // Colors.appBackgroundColor,
    },
    //
    rowRightButtonsArea: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginHorizontal: 5,
        //backgroundColor: "#f00"
    },
    rowRightButtonsAreaWithBottomLabel: {
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 5,
        //backgroundColor: "#f00"
    },
    rowButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Layouts.rowButtonWidth,
        height: Layouts.rowButtonHeight,
    },
    rowCircleButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Layouts.rowButtonWidth,
        height: Layouts.rowButtonWidth,
        borderColor: Colors.borderDarkColor,
        borderWidth: Layouts.buttonBorderWidth,
        borderRadius: Layouts.rowButtonWidth / 2,
        marginHorizontal: 5,
        backgroundColor: Colors.appBackgroundColor,
    },
    rowCircleButtonLarge: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Layouts.rowButtonWidth + 5,
        height: Layouts.rowButtonWidth + 5,
        borderColor: Colors.borderDarkColor,
        borderWidth: Layouts.buttonBorderWidth,
        borderRadius: (Layouts.rowButtonWidth + 5) / 2,
        marginHorizontal: 2.5,
        backgroundColor: Colors.appBackgroundColor,
    },
    rowRoundButtonLarge: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Layouts.rowButtonWidth * 2,
        height: Layouts.rowButtonWidth + 15,
        borderColor: Colors.borderDarkColor,
        borderWidth: Layouts.buttonBorderWidth,
        borderRadius: 7,
        marginHorizontal: 2.5,
        backgroundColor: Colors.appBackgroundColor,
    },
    // arrival
    // remove me ------------------------
    arrivalTimeTextBg: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.ggBlue,
        paddingVertical: 5,
        paddingHorizontal: 5,
        borderRadius: 3,
        marginRight: 10
    },
    arrivalTimeTextBg_garage: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.ggBlueTrans5,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 3,
        marginRight: 10,
        //borderColor: Colors.ggBlueTrans2,
        // borderWidth: 2,
    },
    arrivalTimeTextBg_noBus: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.ggBlueTrans5,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 3,
        marginRight: 10,
        //borderColor: Colors.ggBlue,
        //borderWidth: 2,
    },
    // remove me ------------------------
    arrivalBusPosTextBg: {
        marginLeft: 5,
    },

    busAuxStatusMark: {
        backgroundColor: Colors.red,
        width: 10,
        height: 10,
        // paddingVertical: 2,
        // paddingHorizontal: 2,
        borderColor: Colors.whiteLight,
        borderWidth: 1,
        borderRadius: 5,
        // marginLeft: 5
    },


    busAuxStatusTextBg: {
        borderWidth: 1,
        borderRadius: 5,
        borderColor: Colors.red,
        paddingVertical: 3,
        paddingHorizontal: 3,
        marginLeft: 5,
        backgroundColor: Layouts.routeBusArrivalRow,
        backgroundColor: "transparent",
        justifyContent: 'center',
        alignItems: 'center',

        // backgroundColor: "transparent",
        // paddingVertical: 2,
        // paddingHorizontal: 2,
        // borderColor: Colors.red,
        // borderWidth: 1,
        // borderRadius: 3,
        // marginLeft: 5
    },


    plateTypeBoxBg: {
        paddingVertical: 3,
        marginLeft: 10,
        justifyContent: 'center',
    },
    plateNoBoxBg: {
        paddingVertical: 3,
        marginLeft: 10,
        justifyContent: 'center',
    },
    // 혼잡도 표시 박스. background, border 색상은 런타임때 override
    // densityBox: {
    //     borderWidth: 1.5,
    //     borderRadius: 5,
    //     paddingVertical: 3,
    //     paddingHorizontal: 6,
    //     marginHorizontal: 8,
    //     backgroundColor: Layouts.routeBusArrivalRow,
    //     backgroundColor: "transparent",
    // },
    densityBoxBg: {
        borderWidth: 1,
        borderRadius: 5,
        paddingVertical: 3,
        paddingHorizontal: 5,
        marginLeft: 10,
        borderColor: Colors.borderDarkColor,
        backgroundColor: Layouts.routeBusArrivalRow,
        backgroundColor: "transparent",
        justifyContent: 'center',
        alignItems: 'center',
    },
    // 남은좌석 표시 박스. 
    remainSeatCntBox: {
        borderWidth: 1,
        borderRadius: 5,
        paddingVertical: 3,
        paddingHorizontal: 5,
        marginLeft: 10,
        borderColor: Colors.borderDarkColor,
        backgroundColor: Layouts.routeBusArrivalRow,
        backgroundColor: "transparent",
        justifyContent: 'center',
        alignItems: 'center',
    },
    // 노선유형 표시 박스. background 색상은 런타임때 override
    routeTypeBox: {
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: 5,
        paddingVertical: 2,
        paddingHorizontal: 5,
        marginHorizontal: 0,
        justifyContent: 'center',
        alignItems: 'center',
        // justifyContent: 'center',
        // alignItems: 'center',
        // backgroundColor: "#f00"
    },
    // 교통약자
    handiTypeBg: {
        backgroundColor: Colors.ggLightBlue,
        borderRadius: 5,
        paddingVertical: 2,
        paddingHorizontal: 5,
        borderWidth: 0.2,
        borderColor: Colors.whiteLight,

        // backgroundColor: Colors.ggBlue,
        // paddingVertical: 5,
        // paddingHorizontal: 10,
        // borderRadius: 3,
        // marginRight: 10
    },

    stationRouteHeaderBg: {
        alignItems: "stretch",
        backgroundColor: Colors.stationBackground,
        borderColor: Colors.borderColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        elevation: 2,
        marginBottom: 5,
        padding: 5,
    },

    stationSimpleHeaderBg: {
        height: Layouts.stationHeaderHeight * 0.7,
        flexDirection: 'column',
        alignItems: "stretch",
        borderColor: Colors.borderColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        backgroundColor: Colors.stationBackground,
        //elevation: 2,
        //marginBottom: 5,
        padding: 5,
    },

    stationHeaderBg: {
        height: Layouts.stationHeaderHeight,
        flexDirection: 'column',
        alignItems: "stretch",
        borderColor: Colors.borderColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        backgroundColor: Colors.stationBackground,
        elevation: 2,
        marginBottom: 5,
        padding: 5,
    },

    stationHeaderTextBg: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "#f00"
    },
    stationHeaderDetailsBg: {
        flexDirection: "row",
        justifyContent: "center",
        // alignItems: "flex-end",
        paddingHorizontal: 5,
        //backgroundColor: "#f00"
    },
    // stationHeaderDetailBg: {
    //     backgroundColor: "transparent",
    // },


    routeHeaderBg: {
        flexDirection: 'row',
        height: Layouts.routeHeaderHeight,
        borderColor: Colors.borderColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        elevation: 2,
        marginBottom: 5,
        paddingHorizontal: 5,
        alignItems: "center",
        backgroundColor: Colors.routeBackground,
    },
    routeHeaderRouteNoBg: {
        // flex: 1, 
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 5,
        //backgroundColor: "#f0f"
    },
    routeHeaderDetailsBg: {
        flex: 1,
        alignSelf: "stretch",
        paddingVertical: 5,
        justifyContent: "space-around",
        alignItems: "flex-end",
        //backgroundColor: "#ff0",
    },
    routeHeaderDetailTextBg: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
        textAlign: "right",
        maxWidth: 150,
        //backgroundColor: "#f00",
    },
    // stationHeaderDetailBg: {
    //     backgroundColor: "transparent",
    // },


    busArrivalRowBg: {
        flexDirection: 'row',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.borderColor,
        borderBottomWidth: Layouts.rowBorderWidth,
        paddingVertical: Layouts.rowVerticalPadding,
        paddingHorizontal: Layouts.rowHorzontalPadding,
    },

    recentNotifyBusRowBg: {
        width: "90%",
        flexDirection: "row",
        borderWidth: 1,
        //backgroundColor: '#0ff',
        alignItems: 'stretch',
        backgroundColor: Colors.white,
        borderColor: Colors.ggBlue,
        //justifyContent: 'center'
        borderRadius: 10,
        elevation: 3,
    },

    frInputLeftAreaBg: {
        justifyContent: 'space-between',
        marginRight: 10,
        marginVertical: 10,
        paddingHorizontal: 10,
        paddingVertical: 5,
        //paddingTop: 10,
        borderWidth: 0.5,
        borderColor: Colors.borderDarkColor,
        backgroundColor: Colors.appBackgroundColor,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        width: Layouts.windowWidth - 30
    },
    frInputRightIconBg: {
        height: Layouts.frInputHeight,
        width: Layouts.frInputHeight,
        justifyContent: "center",
        alignItems: "center",
    },

    frInputRightAreaBg: {
        flexDirection: "row",
        justifyContent: 'flex-start',
        alignItems: "center",
        marginVertical: 10,
        paddingLeft: 5,
        paddingRight: 15,
        paddingVertical: 5,
        //paddingTop: 10,
        borderWidth: 0.5,
        borderColor: Colors.borderDarkColor,
        backgroundColor: Colors.appBackgroundColor,
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
        width: Layouts.windowWidth - 30
    },

    frAreaSearchIconBg: {
        height: Layouts.frInputHeight * 1.5,
        width: Layouts.frInputHeight * 1.5,
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: Colors.borderColor,
        borderRadius: 5,
        borderWidth: 1,
        paddingHorizontal: 15,
        paddingVertical: 20,
        backgroundColor: Colors.white,
    },

    busArrivalBelowBtn: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.appBackgroundColor,
        borderColor: Colors.ggLightBlue,
        borderWidth: 1,
        borderRadius: 7,
        elevation: 3,
    },
};

const SectionStyle = (name, customStyles = null) => {
    if (typeof customStyles === "string") {
        console.log("SectionStyle.GetStyle() changed. font sized style should go to TextStyle()")
    }
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('SectionStyle.GetStyle', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default SectionStyle;