import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import SectionStyle from '../../../styles/SectionStyle';
import FrStyle from '../../../styles/FrStyle';
import * as JsUtil from '../../../utils/JsUtil';

const SubwayLaneBox = ({selfColor, laneAlias}) => {
    // console.log("SubwayLaneBox", laneAlias)
    if (TMap.GetTMapSubwayIconType(laneAlias) === 'num') {
        return (
            <View style={FrStyle("busIconViewBg", { backgroundColor: selfColor})}>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "medium", { color: "#fff" })}>{TMap.GetTMapSubwayShort(laneAlias)}</Text>
            </View>
        )
    }
    else  {
        return (
            <View style={ FrStyle('subwayBoxIconViewBg', { backgroundColor: selfColor } ) }>
                <Text style={ TextStyle('rowDetail', 'small', { color: "#fff" }) }>
                    {TMap.GetTMapSubwayShort(laneAlias)}
                </Text>
            </View>
        )
    }
}

export default function FrSubwayDetailRow({selfColor, beforeColor, item, isTransfer}) {
    // console.log("FrSubwayDetailRow item", item)
    // console.log("FrSubwayDetailRow isTransfer", isTransfer)
    return(
        <View style={FrStyle("frdBusRowBg")} accessible={true}>
            <View style={FrStyle("frdBusLine", { backgroundColor: selfColor})} />
            <SubwayLaneBox selfColor={selfColor} laneAlias={JsUtil.GText(item.subway, "name")} />
            <View style={SectionStyle("rowFill", { alignSelf: "stretch", justifyContent: "space-between"})}>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "medium", { })}>
                    {isTransfer ? '환승' : '승차'} | {item.startStationName}역 | {JsUtil.GText(item.subway, "name")}
                </Text>                
                <View style={SectionStyle("rowForRow", { alignItems: "center" })}>
                    <Text style={ TextStyle('rowDetail', 'smallest') }>
                        {JsUtil.GText(item.subway, "headSign")}
                    </Text>
                </View>
                <Text style={ TextStyle('rowDetail', 'smallest', {}) }>
                    {TMap.getTimeHMS(item.duration)}, {item.stations.length}개역 이동
                </Text>
            </View>
        </View>
    )
}
