import React from 'react';
import _ from 'lodash';
import Bug from '../utils/Bug';

const TEXT_SIZE_TYPES = new Set([ 'tiny', 'smallest', 'small', 'medium', 'large', 'largest', 'huge']);

// text types
const TT = {
    tiny: {
        fontSize: 12,
        fontFamily: "THEjunggt130",
        sizeStep: 2,
        familyStep: 0.5,  // eg) 0: no change, 0.5: 140->140->150, 1=140->150->160, 2->140->160
    },
    smallest: {
        fontSize: 13,
        fontFamily: "THEjunggt140",
        sizeStep: 2,
        familyStep: 0.5,  // eg) 0: no change, 0.5: 140->140->150, 1=140->150->160, 2->140->160
    },
    small: {
        fontSize: 14,
        fontFamily: "THEjunggt140",
        sizeStep: 2,
        familyStep: 0.5,  // eg) 0: no change, 0.5: 140->140->150, 1=140->150->160, 2->140->160
    },
    medium: {
        fontSize: 15,
        fontFamily: "THEjunggt150",
        sizeStep: 2,
        familyStep: 1, 
    },
    large: {
        fontSize: 16,
        fontFamily: "THEjunggt160",
        sizeStep: 2,
        familyStep: 1, 
    },
    largest: {
        fontSize: 18,
        fontFamily: "THEjunggt160",
        sizeStep: 2,
        familyStep: 1, 
    },
    huge: {
        fontSize: 20,
        fontFamily: "THEjunggt170",
        sizeStep: 2,
        familyStep: 1, 
    },
}

// font family
const FF = ["THEjunggt130", "THEjunggt140", "THEjunggt150", "THEjunggt160", "THEjunggt170"]

export const Fonts = {
    _def: {
        fontSize: 14,
        fontFamily: 'THEjunggt150',
        tiny: { fontSize: 12, fontFamily: "THEjunggt130"},
        smallest: { fontSize: 13, fontFamily: "THEjunggt140"},
        small: { fontSize: 14, fontFamily: "THEjunggt140"},
        medium: { fontSize: 15, fontFamily: "THEjunggt150"},
        large: { fontSize: 16, fontFamily: "THEjunggt160"},
        largest: { fontSize: 17, fontFamily: "THEjunggt160"},
        huge: { fontSize: 18, fontFamily: "THEjunggt170"},


        // header: {
        //     fontSize: 16,
        //     fontFamily: 'THEjunggt150',
        // },
        // headerButton: {
        //     fontSize: 14,
        //     fontFamily: 'THEjunggt150',
        // },        
        // input: {
        //     fontSize: 15,
        //     fontFamily: 'THEjunggt150',
        // },
        // listHeader: {
        //     fontSize: 14,
        //     fontFamily: 'THEjunggt140',
        // },
        // comment: {
        //     fontSize: 14,
        //     fontFamily: 'THEjunggt140',
        // },        
        // tabBar: {
        //     fontSize: 14,
        //     fontFamily: 'THEjunggt140',
        // },
    },
    tiny: { fontSize: 12, fontFamily: "THEjunggt130"},
    smallest: { fontSize: 13, fontFamily: "THEjunggt140"},
    small: { fontSize: 14, fontFamily: "THEjunggt140"},
    medium: { fontSize: 15, fontFamily: "THEjunggt150"},
    large: { fontSize: 16, fontFamily: "THEjunggt160"},
    largest: { fontSize: 17, fontFamily: "THEjunggt160"},
    huge: { fontSize: 18, fontFamily: "THEjunggt170"},
};

/**
 * generate FONTS.tiny{ fontSize: , fontFamily: }, FONTS.smallest{}... FONTS.huge{} ...
 * userFontLevel = [0|1|2]
 * */ 
export const GenerateFontStyles = (userFontLevel) => {
    Object.keys(TT).forEach(textType => {
        let size = TT[textType].fontSize + TT[textType].sizeStep * userFontLevel;
        // let test = Math.max(FF.indexOf(TT[textType].fontFamily) + Math.floor(TT[textType].familyStep * userFontLevel), FF.length-1)
        // console.log(`[Fonts] **************** = ${FF.indexOf(TT[textType].fontFamily) + Math.floor(TT[textType].familyStep * userFontLevel)}`)
        // console.log(`[Fonts] **************** = ${test}`)
        let family = FF[Math.min(FF.indexOf(TT[textType].fontFamily) + Math.floor(TT[textType].familyStep * userFontLevel), FF.length-1)];
        Fonts[textType] = {
            fontSize: size,
            fontFamily: family,
        }
        Fonts[textType] = {
            fontSize: size,
            fontFamily: family,
        }
    })
    console.log(`[Fonts] font style generated. font level = ${userFontLevel}`)
}

export const OverrideUserFont = (style, sizeType) => {
    if (!sizeType) {
        return style;
    }
    if (!Fonts.hasOwnProperty(sizeType)) {
        Bug('Fonts.OverrideUserFont()', `Invalid font size specifier. ${sizeType}`);
        console.log(sizeType)
        return style;
    }
    return _.merge({...style}, Fonts[sizeType]);
}

