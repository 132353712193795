import React, { Component, useState, useEffect, useRef } from 'react';
import {
    StyleSheet, Text, View, TouchableOpacity, TextInput,
    TouchableWithoutFeedback, Keyboard, ScrollView, FlatList, Alert,
    SectionList
} from 'react-native';
import { NavigationContext } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import AlarmContext from '../../context/AlarmContext';
// config, lib
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import RouteNodeMapStyle from '../../styles/RouteNodeMapStyle';
import * as ArrayUtil from '../../utils/ArrayUtil';
import * as HistoryUtil from '../../utils/HistoryUtil';
import { Colors, Layouts } from '../../defines/Theme';
import Texts, { GetText } from '../../defines/Texts';
import AppSetting from '../../defines/AppSetting';
import * as GBus from '../../defines/GBus';
import * as GBusHelper from '../../defines/GBusHelper';
import * as FavorUtil from '../../utils/FavorUtil';
import * as JsUtil from '../../utils/JsUtil';
import GAPI, { TAPI } from '../../api/RestAPI';
import LoadImage from '../../defines/Images';
// import RestAPI from '../../api/RestAPI';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';


// shared
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import TabSelectBar from '../components/common/TabSelectBar';
import RouteInfoBottomTab from '../components/bottomTabs/RouteInfoBottomTab';
import ListEmpty from '../components/parts/ListEmpty';
import InputClear from '../components/common/InputClear';
import StationRouteArrivalRow from '../components/StationRouteArrivalRow';
import Refresh from '../components/common/Refresh';
import RouteHeader from '../components/RouteHeader';


import RouteMapDirectionSelector from './include/RouteMapDirectionSelector';
import * as RouteNodeMap from './include/RouteNodeMapElements';

let UserScriptApi = require('../../utils/UserScriptApi');

export function RenderItem({ item, routeStations, runningBusList, index, onPressItem, routeInfo }) {
    let { isStopStation, displayName } = GBus.GetRouteStationStopStatus(item.stationName._text);
    return (
        <View style={RouteNodeMapStyle("routeNodeMapRowBg", index === 0 ? {borderTopWidth: 0.7} : null)} >
            {/* 좌측 노선 세로 선 */}
            <View style={RouteNodeMapStyle("leftBusColBg", { 
                // backgroundColor: "#f0f", 
                // padding: 1
                })} >
                {/* vertical route running line */}
                <RouteNodeMap.RouteLine
                    stationSeq={item.stationSeq._text}
                    startStationSeq={1}
                    turnStationSeq={item.turnSeq._text}
                    stationCount={routeStations.length}
                />
            </View>
            {/* 우측 정류소 이름. */}
            <TouchableOpacity style={RouteNodeMapStyle("rightStationColBg")}
                onPress={() => {
                    if (onPressItem) {
                        onPressItem()
                    }
                }}
            >
            <View style={RouteNodeMapStyle("rightStationColBg")}>
                <Text activeOpacity={0.5} numberOfLines={1} style={TextStyle("rowTitle", "large", isStopStation ? null : { color: Colors.fontMiscColor })}>
                    {displayName}
                </Text>
                <Text  activeOpacity={0.5} style={TextStyle("rowDetail", "small", isStopStation ? null : { color: Colors.fontMiscColor })}>
                    {
                        GBus.GetStationDetail(item)
                    }
                </Text>
             </View>
                {/* {item.toiletStYn._text !== 'Y' ? */}
                    {item.stationSeq._text === item.turnSeq._text ?
                        <View style={RouteNodeMapStyle("stationRightToilet")}>
                            <LoadImage name="icon_toilet" width={30} height={32} />
                        </View>
                        : <></>
                    }
            </TouchableOpacity>
            {
                !runningBusList ? null :
                    <RouteNodeMap.RunningBus
                        routeInfo={routeInfo}
                        routeStations={routeStations}
                        runningBusList={runningBusList}
                        stationId={item.stationId._text}
                    />
            }
        </View>
    )

}

export default class RouteInfo extends Component {

    static contextType = AlarmContext

    constructor(props) {
        super(props)
        // 변수초기화부분
        this.flatRef = React.createRef();
        this.state = {
            passedStationId: "",
            refStationId: "",
            refStaOrder: "",
            routeId: "",
            routeInFavor: false,
            routeDetail: null,
            routeStations: null,
            runningBusList: null,
            turnSation: null,            
            lastStation: null,                        
        }
        this.refresh = this.refresh.bind(this);
        this.refreshTimer = null;
    }

    navCallback = (params) => {
        this.setState({
            passedStationId: JsUtil.GText(params, "stationId"),
            refStationId: JsUtil.GText(params, "stationId"),
            refStaOrder: JsUtil.GText(params, "staOrder")
        })
        // console.log("--------------- routeInfo. nav callback", params)
    }

    componentDidMount() {
        UserScriptApi.apiConnect('routeInfo')
        console.log(`[RouteInfo] mount`)
        //const navigation = this.context
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            let stationIdParam = JsUtil.GText(this.props.route.params.data, "stationId", "");
            let staOrderParam = JsUtil.GText(this.props.route.params.data, "staOrder", "") || JsUtil.GText(this.props.route.params.data, "stationSeq", "");
            let routeIdParam = JsUtil.GText(this.props.route.params.data, "routeId", "");

            if (this.state.routeId !== routeIdParam ||
                this.state.refStationId !== stationIdParam ||
                this.state.refStaOrder !== staOrderParam) {
                console.log(`[RouteInfo] focused with new params. 
                    ${this.state.refStationId}, ${this.state.refStaOrder}, ${this.state.routeId} : 
                    ${stationIdParam}, ${staOrderParam}, ${routeIdParam}`)
                this.setState({
                    passedStationId: stationIdParam,
                    refStationId: !this.state.refStationId && stationIdParam ? stationIdParam : 
                            !this.state.refStationId ? "_none_" : this.state.refStationId,
                    refStaOrder: !this.state.refStaOrder && staOrderParam ? staOrderParam : this.state.refStaOrder,
                    routeId: routeIdParam,
                    routeDetail: null,
                    routeStations: null,
                    runningBusList: null,
                }, () => {
                    this.syncGlobalToState();
                    this.refresh();
                })
            }
            // else if (JsUtil.GText(this.props.route.params.data, "stationId")) {
            //     console.log(`---------------------- ${JsUtil.GText(this.props.route.params.data, "stationId")}`)
            //     this.setState({...this.state, 
            //         refStationId: JsUtil.GText(this.props.route.params.data, "stationId")
            //     }, () => {
            //         this.refresh();
            //     })
            // } 
            else {
                console.log(`[RouteInfo] focused with old params. ${stationIdParam}, ${staOrderParam}, ${routeIdParam}`)
                this.refresh();    
            }
            this.refreshTimer = setInterval(this.refresh, AppSetting.GENERAL.AUTO_REFRESH_INTERVAL);
        })
        this._blur = this.props.navigation.addListener('blur', () => {
            console.log(`[RouteInfo] blured`)
            if (this.refreshTimer) {
                clearInterval(this.refreshTimer);
            }
        });
                this.getTurnLastStInfo();
    }

    getTurnLastStInfo() {
        if(this.state.routeStations && this.state.routeDetail){
            for(let i=0; i<this.state.routeStations.length; i++){               
                let item = this.state.routeStations[i];
                if(JsUtil.GText(item,"stationName","").substring(JsUtil.GText(item,"stationName").length - 3, JsUtil.GText(item, "stationName").length - 1) != '경유' && JsUtil.GInt(item,"stationSeq") <= JsUtil.GInt(item,"turnSeq")){
                    // console.log("item    ", item);
                    this.state.turnStation =  item;
                    // console.log("turnStation", this.state.turnStation);
                } 
                if (JsUtil.GText(item,"stationName","").substring(JsUtil.GText(item,"stationName").length - 3, JsUtil.GText(item, "stationName").length - 1) != '경유') {
                    this.state.lastStation =  item;
                }
            }
        }   
        // console.log('회차지 정류소명 ', this.state.turnStNm )   
        // console.log('종점 정류소명', this.state.lastStNm)
    }

    componentWillUnmount() {
        console.log("[RouteInfo] unmount");
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
        this._unsubscribe?.();
        this._blur?.();
    }

    runPopupEvents(stationId, routeId) {
        if (routeId) {
            console.log("[RouteInfo] try to query route events");
            this.context.refreshRouteEvents(stationId, routeId, () => {
                if (this.context.routeEvents && this.context.routeEvents.length > 0) {
                    // subject, content, startDt, endDt, routeId
                    console.log(`[RideSupport] ${this.context.routeEvents.length} new route events found`)
                    this.context.msgHandler.popup({
                        title:  GetText("pageTitle", "RUN_POP_EVENT"),
                        contentType: "event",
                        contents: this.context.routeEvents,
                        enableNoMore: true,
                        noMoreMsg: GetText('EVENTS', 'NO_MORE_SHOW_7DAYS'),
                        closeCallback: feedback => {
                            if (feedback.noMoreShow) {
                                this.context.saveRouteEventsNoMoreShowTime();
                            }
                        },
                        // closeCallback: (shownIndexes) => {
                        //     // 
                        // }
                    })
                }
            })
        }
    }

    _initRouteInfo(routeId, byUser, callback) {
        console.log(`[RouteInfo] _initRouteInfo(). R:${routeId}`)
        // get route details
        GAPI.doRequest('getRouteDetail', routeId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    console.log(`[RouteInfo] route detail retrieved`)
                    this.setState({
                        routeDetail: res.response.msgBody.busRouteInfoItem
                    })
                }
                else {
                    console.log(`[RouteInfo] fail to get route detail:`)
                    console.log(res)
                    this.setState({
                        routeDetail: null
                    })
                    this.context.msgHandler?.error("RouteInfo", msg, code);
                }
            })
            .catch(err => {
                this.setState({
                    routeDetail: null
                })
                console.log("Unknown request error...");
                console.log(err);
                this.context.msgHandler?.error("RouteInfo", GetText("error", "unknown"), err);
            })


        // get routeStations
        GAPI.doRequest('getStationsOfRoute', routeId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    console.log(`[RouteInfo] route stations retrieved`)
                    this.setState({
                        routeStations: res.response.msgBody.busRouteStationList
                    }, () => {
                        this.popupLastBusAlert(res.response.msgBody.busRouteStationList);
                    })
                    this.getTurnLastStInfo() 
                    callback?.()
                }
                else {
                    console.log(`[RouteInfo] fail to get routeStations`)
                    this.setState({
                        routeStations: null
                    })
                    this.context.msgHandler?.error("RouteInfo", msg, code);
                }
            })
            .catch(err => {
                this.setState({
                    routeStations: null
                })
                console.log("Unknown request error...");
                console.log(err);
                this.context.msgHandler?.error("RouteInfo", GetText("error", "unknown"), err);
            })
    }

    _refreshRunningBus(routeId, byUser) {
        // console.log(`[RouteInfo] _refreshRunningBus() byUser: ${byUser}`);
        if (byUser) {
            showPageLoader(true)
        }
        // get running bus list
        GAPI.doRequest('getRunningBusOfRoute', routeId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    console.log(`[RouteInfo] running bus list retrieved`)
                    if (Array.isArray(res.response.msgBody.busLocationList)) {
                        this.setState({
                            runningBusList: res.response.msgBody.busLocationList
                        })
                    }
                    else {
                        this.setState({
                            runningBusList: [res.response.msgBody.busLocationList]
                        })
                    }
                }
                else {
                    this.setState({
                        runningBusList: []
                    })
                    console.log(`[RouteInfo] fail to get route runningBusList`)
                    if (byUser) {
                        this.context.msgHandler?.error("RouteInfo", msg, code);
                    }
                }
            })
            .catch(err => {
                this.setState({
                    runningBusList: []
                })
                console.log("Unknown request error...");
                console.log(err);
                if (byUser) {
                    this.context.msgHandler?.error("RouteInfo", GetText("error", "unknown"), err);
                }
            })
            .finally(() => {
                showPageLoader(false)
            })
    }

    refresh(byUser) {
        // console.log(`[RouteInfo] refresh() byUser: ${byUser}`);
        if (!this.state.routeDetail || !this.state.routeStations) {
            this._initRouteInfo(
                this.state.routeId,
                byUser,
                () => {
                    this._refreshRunningBus(this.state.routeId, byUser);
                    this.runPopupEvents("", this.state.routeId);
                // setTimeout(() =>
                //     {
                //         this._refreshRunningBus(this.state.routeId, byUser);
                //         this.runPopupEvents("", this.state.routeId);
                //     }, 1000)
                });            
        }
        else {
            // this._ScrollToRefStation();
            this._refreshRunningBus(this.state.routeId, byUser)
        }
    } 

    // 막차시간: 기점(00:00), 종점(00:00) \n이용에 참고 바랍니다.
    popupLastBusAlert(busRouteStationList) {
        if (busRouteStationList && busRouteStationList.length > 0) {
            let uLast = JsUtil.GText(busRouteStationList[0].upLastTime);
            let dLast = JsUtil.GText(busRouteStationList[0].downLastTime);
            if (!GBusHelper.isInBusRunTime(JsUtil.GText(busRouteStationList[0].upFirstTime), uLast) ||
                !GBusHelper.isInBusRunTime(JsUtil.GText(busRouteStationList[0].downFirstTime), dLast)) {
                let arr = []
                arr.push(uLast ? GetText("pageWord","START_STATION") + `(${uLast})` : "")
                arr.push(dLast ? GetText("pageWord","LAST_STATION") + `(${dLast})` : "")
                let m = GetText("pageWord", "LAST_BUS_TIME") + ": " + arr.filter(elem => elem).join(", ") + '\n' + GetText("pageComment","PLEASE_REFER");
                this.context.msgHandler?.dlg(GetText("pageWord","ALERT"), m, Layouts.rowHeight * 1.3);
            }
        }
    }

    _ScrollTo = (index) => {
        try {
            this.flatRef.current?.scrollToIndex({
                animated: true,
                index: index
            })
        } catch (ex) { }
    }

    _ScrollToRefStation = () => {
         console.log(`[RouteInfo] try to scroll ref station ${this.state.refStationId}, ${this.state.refStaOrder}`)
        // return;
        if (this.state.routeStations && this.state.routeStations.length > 0 && this.state.refStationId) {
            let refStaIndex = 0;
            if (this.state.refStationId !== "_none_") {
                if(this.state.refStaOrder) {
                    refStaIndex = this.state.routeStations.findIndex(sta =>
                        JsUtil.GText(sta, "stationId") === this.state.refStationId &&
                        JsUtil.GText(sta, "stationSeq") === this.state.refStaOrder)
                    
                }
                else {
                    refStaIndex = this.state.routeStations.findIndex(sta =>
                        JsUtil.GText(sta, "stationId") === this.state.refStationId)
                }
                // console.log(`[RouteInfo] try to scroll ref station(1)`, refStaIndex)                
            }
            this.setState({
                refStationId: "",
                refStaOrder: "",
            }, () => {
                if (refStaIndex >= 0) {
                    this._ScrollTo(refStaIndex);
                }
            })
        }
    }

    syncGlobalToState() {
        let { favorIndex } = FavorUtil.IsRouteInFavor(this.state.routeId)
        this.setState({ routeInFavor: favorIndex >= 0 });
    }

    renderItem = ({ item, index, separators }) => {
        let { isStopStation, displayName } = GBus.GetRouteStationStopStatus(JsUtil.GText(item.stationName));
        return (
            <View style={[RouteNodeMapStyle("routeNodeMapRowBg", index === 0 ? { borderTopWidth: 0.7 } : null),
                JsUtil.GText(item.stationId) === this.state.passedStationId ? {
                    backgroundColor: Colors.ggBlueTrans2,
                } : null
            ]} >
                {/* 좌측 노선 세로 선 */}
                <View style={RouteNodeMapStyle("leftBusColBg")} >
                    {/* vertical route running line */}
                    <RouteNodeMap.RouteLine
                        stationSeq={JsUtil.GText(item.stationSeq)}
                        startStationSeq={1}
                        turnStationSeq={JsUtil.GText(item.turnSeq)}
                        stationCount={this.state.routeStations.length}
                    />
                </View>
                {/* 우측 정류소 이름. */}
                <TouchableOpacity style={RouteNodeMapStyle("rightStationColBg2")}
                    onPress={() => {
                        
                        if (JsUtil.GText(item, "stationName", "").substring(JsUtil.GText(item, "stationName").length - 3, JsUtil.GText(item, "stationName").length - 1) == '경유') {
                            console.log("[RideInfo] impossible");
                        } else {
                            clearInterval(this.refreshTimer)
                            this.props.navigation.navigate('rideSupport', {
                                stationId: JsUtil.GText(item, "stationId"),
                                staOrder: JsUtil.GText(item, "staOrder") || JsUtil.GText(item, "stationSeq"),
                                turnSeq: JsUtil.GText(item, "turnSeq"),
                                routeId: this.state.routeId,
                                navCallback: this.navCallback,
                                data: item
                            })
                        }
                    }}
                >
                	<View style={RouteNodeMapStyle("rightStationColBg")}>
                    <Text activeOpacity={0.5} numberOfLines={1} style={TextStyle("rowTitle", "large", {
                        width: "100%",
                        textAlign: "left",
                        marginLeft: 0,
                        marginTop: 5,
                        paddingLeft: 0,
                        lineHeight: 22,
                        color: isStopStation ? Colors.fontColor : Colors.fontMiscColor,
                    })}>
                        {displayName}
                    </Text>
                    <Text activeOpacity={0.5} style={TextStyle("rowDetail", "small", {
                        width: "100%",
                        height: "auto",
                        textAlign: "left",
                        marginLeft: 0,
                        paddingLeft: 0,
                        lineHeight: 20,
                        color: isStopStation ? Colors.fontColor : Colors.fontMiscColor,
                    })}>
                    {/* isStopStation ? null : { color: Colors.fontMiscColor })}> */}
                        {
                            GBus.GetStationDetail(item)
                        }
                    </Text>
                    </View>
                    { JsUtil.GText(item,"toiletStYn") === 'Y' ?                    
                        <View style={RouteNodeMapStyle("stationRightToilet")} accessible={true} accessibilityLabel="화장실 이용 가능">
                            <LoadImage name="icon_toilet" width={30} height={32} />
                        </View>
                        : <></>
                    }
                </TouchableOpacity>
                {
                    this.state.routeDetail && this.state.runningBusList ?
                        <RouteNodeMap.RunningBus
                            routeInfo={this.state.routeDetail}
                            routeStations={this.state.routeStations}
                            runningBusList={this.state.runningBusList}
                            station={item}
                        />
                    : null
                }
            </View>)
    }

    render() {
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    title={GetText("pageTitle","ROUTE_INFO")} 
                    // iconType={this.state.routeInFavor ? "fad" : "fal"}
                    // iconName="star"
                    // onPress={() => {
                    //     // setFavors(data)
                    //     if (!this.state.routeInFavor) {
                    //         FavorUtil.AddFavorRoute(this.state.routeId,
                    //             (done) => { done && this.syncGlobalToState() }
                    //         )
                    //     } else {
                    //         FavorUtil.RemoveFavorRoute(this.state.routeId,
                    //             (done) => { done && this.syncGlobalToState() }
                    //         )
                    //     }
                    // }}
                    // onGetIconColor={() => this.state.routeInFavor ? Colors.yellow : Colors.ggBlue}
                />

                {/* Show route header */}
                <RouteHeader route={this.state.routeDetail} />

                {/* Show route direction selector */}
                <RouteMapDirectionSelector
                    route={this.state.routeDetail}
                    turnStNm={JsUtil.GText(this.state.turnStation,"stationName")}
                    lastStNm={JsUtil.GText(this.state.lastStation,"stationName")}
                    onStartDirPress={() => {
                        this._ScrollTo(0)
                    }}
                    onTurnDirPress={() => {
                        if (this.state.routeStations && this.state.routeStations.length > 0) {
                            let turnStationIndex = this.state.routeStations.findIndex(sta => 
                                JsUtil.GInt(sta, "stationSeq") === JsUtil.GInt(this.state.turnStation, "stationSeq")
                            )
                            if (turnStationIndex >= 0) {
                                this._ScrollTo(turnStationIndex)
                            }
                        }
                    }}
                />

                {/* Show station list and running bus position */}
                <View 
                    style={{
                        flex: 1,
                        backgroundColor: Colors.appBackgroundColor,
                        marginBottom: Layouts.BOTTOM_BAR_HEIGHT,
                    }}>
                    <FlatList
                        ref={this.flatRef}
                        data={this.state.routeStations}
                        removeClippedSubviews={false}
                        initialNumToRender={40}
                        maxToRenderPerBatch={16}
                        // legacyImplementation={true}
                        windowSize={21}
                        getItemLayout={(data, index) => (
                            { length: Layouts.routeNodeMapRowHeight, offset: Layouts.routeNodeMapRowHeight * index, index }
                        )}
                        ListHeaderComponent={
                            <View style={{ height: 5 }} />
                        }
                        ListFooterComponent={
                            <View style={{ height: Layouts.routeNodeMapRowHeight }} />
                        }
                        //initialScrollIndex={this.state.routeStations ? this.state.routeStations / 2 : 0}
                        onContentSizeChange={() => {
                            this._ScrollToRefStation();
                        }}
                        onScrollToIndexFailed={() => {}}
                        renderItem={this.renderItem}
                        refreshing={false}
                        keyExtractor={(item, index) => index.toString()}
                    />
                </View>

                {/* show manual refresh icon */}
                <Refresh onPress={() => {
                    this.refresh(true)
                }} />
                {/* bottom tabs */}
                <RouteInfoBottomTab
                    navigation={this.props.navigation}
                    // onPressBack={() => {
                    //     clearInterval(this.refreshTimer)
                    //     this.props.navigation.goBack()
                    // }}
                    onPressMapBtn={() => {
                        if (this.state.routeDetail) {
                            clearInterval(this.refreshTimer)
                            this.props.navigation.navigate(
                                'routeMap',
                                { data: this.state.routeDetail }
                            )
                        }
                    }}
                    onPressInfoBtn={() => {
                        if (this.state.routeDetail && this.state.routeStations) {
                            clearInterval(this.refreshTimer)
                            this.props.navigation.navigate(
                                'routeInfoDetail',
                                {
                                    data: {
                                        routeDetail: this.state.routeDetail,
                                        allStations: this.state.routeStations
                                    }
                                }
                            )
                        }
                    }}
                />
            </SafeAreaView>
        )
    }
}
