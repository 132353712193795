import React, { useContext, useState, useEffect } from 'react';
import { Platform, StyleSheet, Text, View, TouchableOpacity, Image, Linking, Alert } from 'react-native';
import { createDrawerNavigator, DrawerContentScrollView } from '@react-navigation/drawer';

import AlarmContext from '../../context/AlarmContext';
// configurations
import * as GBus from '../../defines/GBus';
import { Colors, Layouts } from '../../defines/Theme';
import AppSetting from '../../defines/AppSetting';
import { GetText, GetTextSido } from '../../defines/Texts';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import LoadSvg from '../../defines/Svgs';
// shared components
import ScreenHeader from '../components/common/ScreenHeader';
// local screen, component
import HomeFavor from './HomeFavor';


const HomeDrawer = createDrawerNavigator();


const MenuIcon = ({ type, name, size, color, style }) => {
    switch (type) {
        case "fad":
        case "far":
        case "fal":
        case "fas":
            return <FIcon type={type} name={name} size={size} color={color} style={style} />
        case "Image":
            return <LoadImage name={name} width={size} height={size} style={style} />;
        case "Svg":
            return <LoadSvg name={name} width={size} height={size} color={color} />;
    }
    console.log('MenuIcon', `Not supported icon type. ${type}`);
    return <Text>no icon</Text>;
};

const StackedBusIcon = () => {
    return (
        <View style={{ width: 35, height: 35 }}>
            <FIcon
                type="fas" name="bus" size={25} color={Colors.lightBlue} style={{
                    position: 'absolute',
                    left: 4,
                    top: 4
                }} />
            <FIcon type="fas" name="bus" size={25} color={Colors.ggBlue} style={{
                position: 'absolute',
                left: 7,
                top: 7
            }} />
        </View>
    )
}

const DrawMenuRowShowAllRoutes = ({ title, onPress }) => {
    return (
        <TouchableOpacity style={CommonStyle('drawMenuRow', {
            // borderTopWidth: Layouts.rowBorderWidth
        })}
            onPress={() => { onPress?.() }}>
            <View style={CommonStyle("drawMenuIconBg")}>
                <StackedBusIcon />
            </View>
            <Text style={TextStyle('drawMenuTitle')}>{title}</Text>
        </TouchableOpacity>
    )
}

const DrawMenuRow = ({ iconType, iconName, iconSize, title, onPress, topBorder, textStyle }) => {
    return (
        <TouchableOpacity style={CommonStyle('drawMenuRow', topBorder ? { borderTopWidth: Layouts.rowBorderWidth } : null)}
            onPress={() => { onPress?.() }}>
            <View style={CommonStyle("drawMenuIconBg")}>
                <MenuIcon type={iconType} name={iconName} size={iconSize} color={Colors.ggBlue} />
            </View>
            <Text style={TextStyle('drawMenuTitle', textStyle)}>{title}</Text>
        </TouchableOpacity>
    )
}


export function CustomDrawerContent(props) {
    const context = useContext(AlarmContext)

    // console.log("Render home menu drawer...")
    return (
        <DrawerContentScrollView {...props} contentContainerStyle={CommonStyle('bg')}>
            <ScreenHeader
                showSlogan={false}
                title={GetText("pageTitle","HOME_DRAW_NAV")}
                iconType="fas" iconName="times-circle"
                onPress={() => {
                    props.navigation.closeDrawer()
                }}
            />
            <View style={ CommonStyle("bg")}>
                <DrawMenuRow iconType="fal" iconName="comment-alt-lines" iconSize={24} 
                    title={GetText("pageTitle","MESSAGE")}
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('message')} }
                    topBorder={true} />
                { global.foreignLanguage == 'K' ?
                <DrawMenuRowShowAllRoutes
                    title={GetText("pageTitle", "SHOW_ALL_ROUTES" )}
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('showAllRoutes') }} /> : null
                }
                {/* <DrawMenuRow iconType="fad" iconName="tablet-alt" iconSize={24}
                    title="접속장치정보(릴리즈시 제거할 것)"
                    textStyle={{ color: "#f00"}}
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('deviceInfo') }} /> */}
                {/* 
                <DrawMenuRow iconType="fad" iconName="sliders-h" iconSize={24} 
                    title="즐겨찾기 수정" 
                    onPress={() => { 
                        if (!global.historyReview || global.historyReview.length < 1) {
                            context.msgHandler?.toast(GetText("favor", "NO_ITEM"))
                        }
                        else {
                            props.navigation.navigate('homeFavorEdit', { data: global.historyReview })
                        }
                    }}
                />
                <DrawMenuRow iconType="fad" iconName="cog" iconSize={24} 
                    title="앱 설정" 
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('option')} } /> 
                {Platform.OS === "android" 
                ? <DrawMenuRow iconType="fad" iconName="window-maximize" iconSize={24} 
                    title="위젯 설정" 
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('widgetOption')} } />
                : null
                }
                <DrawMenuRow iconType="fal" iconName="clipboard-list-check" iconSize={24} 
                    title="승차벨 요청이력 보기" 
                    onPress={() => { 
                        if (!GBus.showBusNotifyFunction()) {
                            context.msgHandler?.dlg(GetText("pageWord","ALERT"), GetText("OPEN_SOON", "OPEN_SOON"))
                        }
                        else {
                            props.navigation.closeDrawer(); 
                            props.navigation.navigate('busNotifyHistory')
                        }
                    }} />
                */}
                <DrawMenuRow iconType="fal" iconName="question-circle" iconSize={24}
                    title={GetText("pageTitle","HELP" )}
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('help') }} />
                {/* <DrawMenuRowShowAllRoutes 
                    title="버스리뷰" 
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate("busEval")} } /> */}
                {/* {AppSetting.GENERAL.FOREIGN_SERVICE == 'Y' ? */}
                <DrawMenuRow iconType="fal" iconName="globe" iconSize={24} 
                    title={GetText("pageTitle", "CHOICE_LANGUAGE")}
                    onPress={() => { props.navigation.closeDrawer(); props.navigation.navigate('foreigner')} } />
                 {/* : null
                } */}

                <View style={CommonStyle('drawMenuFiller')}>
                    {/* 서비스 공급자, 앱 정보 */}
                    <View style={CommonStyle('drawMenuProviderBg')}>
                        {/* Slogan large. 549*316 */}
                        <LoadImage name="slogan_large" width={165} height={100} />
                        {/* MA Certificate 204*65 */}
                        {
                            AppSetting.GENERAL.CERT_MA ?
                                <LoadImage name="ma_mark" width={100} height={32} /> : null
                        }
                        {/* 경기교통정보센터. 549*316 */}
                        <View style={SectionStyle("rowForCol", { alignItems: "center" })}>
                            <Text style={TextStyle("small", { marginVertical: 10 })}>{GetText("pageWord", "CENTER_NM")}
                            {context.devel && context.devel.enable ? "(D)" : ""}
                            </Text>
                            <Text style={TextStyle("smallest")}>Ver 2.3.9</Text>
                        </View>
                    </View>
                    {/* 고객센터 */}
                    <View style={CommonStyle('drawMenuFooter')}>
                        <TouchableOpacity style={SectionStyle('rowFillStretchForRow', { backgroundColor: Colors.lightBlue })}
                            onPress={() => { Linking.openURL('tel:031-120') }}
                        >
                            <View style={CommonStyle("drawMenuIconBg", { width: 70 })}>
                                <FIcon type="fas" name="phone-alt" size={32} color={Colors.ggBlue} />
                            </View>
                            <View style={SectionStyle("rowFillStretchAround", { paddingVertical: 5 })}>
                                <Text style={TextStyle("large")}>{GetText("pageWord","CALLCENTER")} 031-120</Text>
                                <Text style={TextStyle("tiny")}>{GetText("pageComment", "TEL_BUTTON_CALL")}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </DrawerContentScrollView>
    )
}

export default function HomeDrawNav({ navigation }) {
    const context = useContext(AlarmContext)
    const [initRender, setInitRender] = useState(true)

    useEffect(() => {
        // console.log("HomeDrawNav.InitRender set to false !!!!!!!!!!!!!!!!")
        setInitRender(false)
    }, [initRender])

    // console.log(`[HomeDrawNav] rendering. global.fontSize: ${global.fontSize} initState:`,  context.initState)    

    return (
        <HomeDrawer.Navigator
            screenOptions={{ headerShown: false }}
            drawerStyle={{
                width: initRender ? 0 : "85%",
                borderWidth: 0.5,
                borderColor: Colors.ggBlue,
            }}
            drawerPosition="right"
            drawerContent={
                props => <CustomDrawerContent {...props} />
            }
            //initialRouteName="homeFavor"
            initialRouteName={
                //  Number.isInteger(context.userFontSize) ? "homeFavor" : "intro"
                "homeFavor"
            }
        >
            <HomeDrawer.Screen name="homeFavor" component={HomeFavor} options={{ title: "경기버스정보" }} />
            {/* <HomeDrawer.Screen name="homeFavorEdit" component={HomeFavorEdit} /> */}
        </HomeDrawer.Navigator>
    )
}
