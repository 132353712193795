import React, { Component } from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle'; 
import SectionStyle from '../../../styles/SectionStyle'; 
import FIcon from '../../../defines/FIcon';

const InputClear = ({ onPress, right, top, size, color}) => {
    return(
        <TouchableOpacity
            style={CommonStyle("inputClearButton", { top: top, right: right})}
            onPress={() => onPress?.()}
        >
            <FIcon type="fad" name="times-circle" size={size} color={color}/>
        </TouchableOpacity>                            
    )
}

InputClear.defaultProps = {
    color: Colors.borderColor,
    size: 20,
    right: 15,
    top: 15,
}


export default InputClear;