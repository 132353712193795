import { useMemo } from 'react';
import useSWR from 'swr';
import AppSetting from '../defines/AppSetting';
import { apiJsonFetcher, parseResponse } from '../api/axios';

const immutableOption = {
    revalidateOnFocus: false,
    revalidateIfStale: false,
};


// serviceKey 에 다국어 지원 안함.
const url = (date, routeId, stationId, staOrder) => {
    return date && routeId && stationId && staOrder
        ? `ws/rest/pastarrivalservice/json?serviceKey=${AppSetting.SERVICE_KEY}&sDay=${date}&routeId=${routeId}&stationId=${stationId}&staOrder=${staOrder}`
        : null;
};

export function usePastArrivals(date, routeId, stationId, staOrder) {
    const { data, error, isValidating, mutate } = useSWR(
        url(date, routeId, stationId, staOrder),
        apiJsonFetcher,
        immutableOption,
    );
    const parsed = useMemo(() => {
        return parseResponse(data, error, 'response.msgBody.pastArrivalList', true);
    }, [data, error]);

    return {
        isLoading: isValidating,
        mutate,
        ...parsed,
    };
}
