import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View } from 'react-native';
import { Colors, Layouts } from '../../defines/Theme';
import * as GBus from '../../defines/GBus';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import LoadImage from '../../defines/Images';
import CommonStyle from '../../styles/CommonStyle';
// <View style={SectionStyle("stationRouteHeaderBg", { backgroundColor: "#f00"})} > 
// </View>

export default function StationSimpleHeader({ station, onPress }) {
    return (
            <TouchableOpacity style={SectionStyle("stationSimpleHeaderBg", {
                //marginTop: 20,
                // height: 100,
                // backgroundColor: "#f0f"
                })} 
                onPress={onPress}                
                accessibilityRole="button">
                <View style={SectionStyle("stationHeaderTextBg")}>
                    <Text numberOfLines={1} style={TextStyle("stationHeaderText", "huge", { textAlign: "center" })}>
                        {station ? station.stationName._text : '...'}
                    </Text>
                </View>
                {station && station.toiletStYn && station.toiletStYn._text === 'Y' ?
                    <View style={CommonStyle("reloadOverlayIconBgToilet", { top: 10, flex:1 } )} accessible={true} accessibilityLabel="화장실 이용 가능">
                        <LoadImage name="icon_toilet" width={30} height={32} />
                    </View> 
                    : null
                }
            </TouchableOpacity>
    )
}