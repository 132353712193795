import React, { Component, useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TextInput, Image, TouchableWithoutFeedback, Keyboard, FlatList, ScrollView, Alert } from 'react-native';
import { Colors, Layouts } from '../../../defines/Theme';

// 스타일 정의부분
const FrStyles = {
    frGeneralContainer: {
        justifyContent: 'center',
        marginRight: 10,
        marginVertical: 10,
        paddingLeft: 15,
        paddingRight: 10,
        paddingVertical: 5,
        borderWidth: 0.5,
        borderColor: Colors.borderDarkColor,
        backgroundColor: Colors.appBackgroundColor,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        width: Layouts.windowWidth - 30,
        width: "90vw"
    },
    frAreaContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginVertical: 10,
        borderWidth: 1,
        borderColor: Colors.borderColor,
        backgroundColor: Colors.white,
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
        paddingLeft: 20,
        paddingRight: 10,
        paddingVertical: 5,
        width: Layouts.windowWidth - 30,
        width: "90vw"
    },
    inputRowBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingVertical: 5,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingVertical: 5,
    },
    input: {
        //flex: 1,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: 5,
        paddingVertical: 3,
        paddingHorizontal: 10,
        marginLeft: 10,
        marginRight: 20,
        width: '95%'
    },
    inputFull: {
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: 5,
        paddingVertical: 3,
        paddingHorizontal: 10,
        margin: 15
    },    
    areaSearchIcon: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: Colors.borderColor,
        borderRadius: 5,
        borderWidth: 1,
        paddingHorizontal: 15,
        paddingVertical: 20,
        backgroundColor: Colors.white,
    },
    shadow: {
        // shadowColor: "#000",
        // shadowOffset: {
        //     width: 0,
        //     height: 1,
        // },
        // shadowOpacity: 0.22,
        // shadowRadius: 2.22,
        elevation: 2,
    },
    delView: {
        borderColor: Colors.borderColor,
        borderTopWidth: 1,
        backgroundColor: Colors.listGroupColor,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    listView: {
        flexGrow: 1,
        backgroundColor: Colors.appBackgroundColor,
        zIndex: 999999
    },
    tabView: {
        borderBottomColor: Colors.blue,
        borderBottomWidth: 3,
        padding: 7
    },
    ctrlView: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: Colors.white,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        paddingHorizontal: 20,
        paddingVertical: 10
    }    
}

export default FrStyles