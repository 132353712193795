import React, { Component, useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

import { Colors, Layouts } from '../../../defines/Theme';
import FIcon from '../../../defines/FIcon'; 
import * as GBus from '../../../defines/GBus';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import RouteTypeBox from '../../components/parts/RouteTypeBox';

const RenderDetailLine = ({ text }) => {
    return (
        <View style={SectionStyle('rowForCol', { paddingVertical: 0, })}>
            <Text numberOfLines={1} style={TextStyle('rowDetail', 'smallest', {
                lineHeight: 24,
                padding: 0, 
                paddingLeft: 0, 
                marginHorizontal: 0, 
                width: "100%", 
                textAlign: "left",
                color: Colors.fontCommentColor
            })}>
                {text}
            </Text>
        </View>
    )
}


export default function RouteRow({ item, navigation }) {

    return (
        <View style={SectionStyle('columnBgBottomBorder')}>
            <TouchableOpacity style={SectionStyle('rowForRow', { 
                backgroundColor: "transparent",
                paddingRight: 0,
                marginRight: 0,
                marginBottom: 0 
            })}
                onPress={() => {
                    navigation.navigate('routeInfo', { data: item })
                }}
            >
                {/* 노선유형별 아이콘 */}
                <View style={SectionStyle('rowLeftIconArea', {
                    marginLeft: 7,
                    marginRight: 3
                })}>
                    <FIcon type="fas" name="bus" size={26} color={GBus.GetRouteTypeColor(item.routeTypeCd._text)}
                        style={{ marginBottom: 5 }} />
                    <RouteTypeBox routeType={item.routeTypeCd._text} showShortLabel={true} />
                </View>

                <View style={SectionStyle('rowFillStretchAround', { 
                    marginLeft: 10,
                    paddingTop: 0,
                    paddingBottom: 0 
                    })}>
                    {/* 노선유형, 노선번호 */}
                    <View style={SectionStyle('rowForRow', {
                        alignItems: 'center',
                        marginRight: 10,
                        marginTop: 0,
                        marginBottom: 3,
                        paddingTop: 0,
                        paddingLeft: 0
                    })}>
                        <View style={SectionStyle('rowFillForRow', { alignItems: 'center', marginVertical: 0, paddingVertical: 0 })}>
                            <Text numberOfLines={1} style={TextStyle('rowTitle', 'huge', {
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                width: "100%",
                                marginTop: 0,
                                marginBottom: 0,
                                marginRight: 10,
                                color: GBus.GetRouteTypeColor(item.routeTypeCd._text)
                            })}>
                                {item.routeName._text}
                            </Text>
                        </View>
                    </View>
                    {/* 첫막차 */}
                    <RenderDetailLine text={item.description
                                ? item.description._text
                                : `첫차 ${item.upFirstTime ? item.upFirstTime._text : '-'}, 막차 ${item.upLastTime ? item.upLastTime._text : '-'}, 배차 ${item.npeekAlloc ? item.npeekAlloc._text : '-'}분`
                            } />
                    {/* 기종점 */}
                    <RenderDetailLine text={item.routePath
                                ? item.routePath._text
                                : item.stStaNm._text + " ~ " + item.edStaNm._text} />

                    {/* 운행지역 */}
                    <RenderDetailLine text={item.regionName._text} />
                </View>
            </TouchableOpacity>
        </View>
    )
}


