import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import { TouchableWithoutFeedback, FlatList, Text, View, TouchableOpacity, TextInput, Keyboard } from 'react-native';

// context
import AlarmContext from '../../../context/AlarmContext';

//import Colors from '../../settings/Colors';
//  config, lib
import { Colors, Layouts } from '../../../defines/Theme';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import * as CommonStyle from '../../../styles/CommonStyle';
import * as SectionStyle from '../../../styles/SectionStyle';
import * as FavorUtil from '../../../utils/FavorUtil';
import * as HistoryUtil from '../../../utils/HistoryUtil';
import FIcon from '../../../defines/FIcon';

import ListEmpty from '../../components/parts/ListEmpty';

// deprecated
import styles from './FrStyle';
import Constants, { ConvStyle } from '../../../settings/Constants';


const RenderItem = ({item, onPressHistory}) => {
    if (!item.sTitle || !item.sAddress || !item.sX || !item.sY || !item.eTitle || !item.eAddress || !item.eX || !item.eY) {
        return null;
    }

    return (
        <View style={{
            borderColor: Colors.borderColor,
            borderWidth: 0.5,
            backgroundColor: Colors.white,
            height: Layouts.rowHeight,
        }}>
            <TouchableOpacity style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flex: 1,
                    marginHorizontal: 10,
                }}
                onPress={() => {
                    global.startPOI = {
                        title: item.sTitle,
                        address: item.sAddress,
                        x: item.sX,
                        y: item.sY
                    }
                    global.endPOI = {
                        title: item.eTitle,
                        address: item.eAddress,
                        x: item.eX,
                        y: item.eY
                    }
                    onPressHistory?.()
                }}
            >
                <FIcon type="fas" name="directions" size={30} color={Colors.fontCommentColor} style={{ padding: 10, marginRight: 10 }} />
                <Text
                    numberOfLines={1}
                    style={{ ...ConvStyle(global.fontSize == 0 ? 16 : global.fontSize == 1 ? 17 : 18, Colors.fontColor, 150), 
                        width: "40%" }}
                >
                    {item.sTitle}
                </Text>
                <FIcon type="fad" name="angle-double-right" size={16} color={Colors.ggBlue} style={{marginHorizontal: 5}} />
                <Text
                    numberOfLines={1}
                    style={{ ...ConvStyle(global.fontSize == 0 ? 16 : global.fontSize == 1 ? 17 : 18, Colors.fontColor, 150), 
                        width: "40%" }}
                >
                    {item.eTitle}
                </Text>
            </TouchableOpacity>
        </View>
    )
}

// 일반 및 지역길찾기 출, 도착지 정보입력창
export default function FrHistory({ navigation, onPressHistory, searchState}) {
    const context = useContext(AlarmContext)
    const [frHistory, setFrHistory] = useState([])

    useEffect(() => {
        if (global.historyRoads) {
            setFrHistory(global.historyRoads)
        }
        else {
            setFrHistory([])
        }
    }, [searchState])

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} >
            <View style={{ flex: 1 }}>
                <View style={{ ...styles.delView }}>
                    <Text style={{ ...ConvStyle(15, Colors.fontCommentColor, 150), padding: 7 }}>최근 검색</Text>
                    <TouchableOpacity style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 7
                    }}
                        onPress={() => {
                            context.msgHandler?.dialog({
                                title: "삭제",
                                message: "검색이력을 삭제하시겠습니까?",
                                ok: () => {
                                    HistoryUtil.ClearPathFindHistory(() => {
                                        context.msgHandler?.toast(GetText("pageComment", "COMPLETE_DEL"))
                                        setFrHistory([])
                                    });
                                    
                                },
                                cancel: () => {}
                            })
                        }}
                    >
                        <FIcon type="fas" name="history" size={25} color={Colors.fontMiscColor} style={{ paddingRight: 5, marginRight: 10 }} />
                        <Text style={{ ...ConvStyle(14, Colors.fontCommentColor, 150), marginRight: 10 }}>삭제</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.listView}>
                    <FlatList
                        data={frHistory}
                        ListHeaderComponent={
                            <View style={{ height: 0, backgroundColor: Colors.background }} />
                        }
                        ListEmptyComponent={<ListEmpty text={GetText("findpath", "NO_RECENT_ITEMS")}/>}
                        renderItem={({ item, index, separators }) => {
                            return (
                                <RenderItem key={index} item={item} onPressHistory={onPressHistory} />
                            )
                        }}
                        refreshing={false}
                        keyExtractor={(item, index) => 'key' + index}
                    />
                </View>
            </View>
        </TouchableWithoutFeedback>
    )
}