import React, { Component } from 'react';
import { StyleSheet,  Text, Linking, View, TouchableOpacity, ScrollView } from 'react-native';
import { Dimensions, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
//import Image from 'react-native-scalable-image';

// lib, config
import { Colors, Layouts } from '../../defines/Theme';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import * as DateUtil from '../../utils/DateUtil';

import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerH from '../components/common/SpacerH';
import CommonStyle from '../../styles/CommonStyle';
import TextStyle from '../../styles/TextStyle';
import SectionStyle from '../../styles/SectionStyle';

import SpacerMiniV from '../components/common/SpacerMiniV';
import MessageContent from './MessageContent';

let UserScriptApi = require('../../utils/UserScriptApi');
export default class MessageDetail extends Component {

    constructor(props) {
        super(props)
    }
    componentDidMount() {
        UserScriptApi.apiConnect('Message')
    }
    
    renderImage = (board) => {

       //  board.imgUrl = 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTfBTGAMl6oktVyVjnefobZTzUecbZTiZUZyA&usqp=CAU'        
       // board.imgUrl = 'https://encrypted-tbn0.atic.com/images?q=tbn%3AANd9GcTfBTGAMl6oktVyVjnefobZTzUecbZTiZUZyA&usqp=CAU'        
        //board.linkUrl = "https://google.com"
        if (!board.imgUrl) {
            return <></>
        }
        return (<>
            <View style={CommonStyle("box", { flex: 1, margin: 20 })}>
                <TouchableOpacity style={CommonStyle("boardImageBg", {paddingVertical: 5 })}
                    activeOpacity={board.linkUrl ? 0.5 : 1}
                    onPress={() => {
                        if (board.linkUrl) {
                            Linking.openURL(board.linkUrl)
                        }                            
                }}> 
                    <Image
                        width={Dimensions.get('window').width - 100} // height will be calculated automatically
                        height={(Dimensions.get('window').width - 100) * 0.7} // height will be calculated automatically
                        //height={(Dimensions.get('window').width - 100) * 0.7} // height will be calculated automatically
                        //height={200} // height will be calculated automatically
                        source={{uri: board.imgUrl}}
                    />
                </TouchableOpacity>
            </View>
            <SpacerH/>
            <SpacerH/>
        </>)                        
    }

    render() {
        // console.log(this.props.route.params.data);
        let board = this.props.route.params.data;
        let icon = "info-circle"
        let typeLabel = "공지사항"
        if (board.popTp == 1) {
            icon = "file-alt";
            typeLabel = "외부알림"
        }
        else if (board.popTp == 2) {
            icon = "car-crash";
            typeLabel = "사고소식"
        }       

        return (
            <SafeAreaView style={CommonStyle("bg")}>
                <ScreenHeader
                    title={typeLabel}
                    showSlogan={false}
                />
                {/* <View style={CommonStyle("body", { marginBottom: 0})}> */}
                <View style={CommonStyle("body")}>
                    <View style={SectionStyle('rowForslogan',
                        {
                            flexDirection: "row", justifyContent: "center", paddingHorizontal: 5,
                            borderColor: Colors.borderColor,
                            borderBottomWidth: 1,
                        })}>
                        <LoadImage name="slogan" width={225} height={27} />
                    </View>
                    <SpacerMiniV />
                    <MessageContent board={board} />
                </View>
                <BottomRightTabs navigation={this.props.navigation} />  
            </SafeAreaView>
        )
    }
}



                    // {/* title */}
                    // <View style={SectionStyle("rowForRow", { 
                    //     alignItems: "center", 
                    //     padding: 15, 
                    //     backgroundColor: Colors.gsYellow
                    // }) }>
                    //     <FIcon type="fad" name={icon} size={35} color={Colors.whiteLight} />
                    //     <Text style={TextStyle("rowTitleDesc", "largest", { lineHeight: 30, paddingLeft: 10, color: Colors.white })}>
                    //         {board.popTitle}
                    //     </Text>
                    // </View>
                    // {/* from - to <i class="fad fa-calendar-alt"></i>*/}
                    // <View style={SectionStyle("rowForRow", { 
                    //     alignItems: "center", 
                    //     paddingHorizontal: 20, 
                    //     paddingVertical: 10, 
                    //     backgroundColor: Colors.silver
                    // }) }>
                    //     <FIcon type="fad" name="calendar-alt" size={24} color={Colors.ggBlue} />
                    //     <Text style={TextStyle("rowTitleDesc", "small", { lineHeight: 30, paddingLeft: 10 })}>
                    //         {DateUtil.format(board.startTime, DateUtil._DT_FORMAT_TO_HOUR)} ~ {DateUtil.format(board.endTime, DateUtil._DT_FORMAT_TO_HOUR)}
                    //     </Text>
                    // </View>

                    // {/* scroll contents + image */}
                    // <ScrollView style={CommonStyle("body", {})}>
                    //     {/* contents */}
                    //     <View style={CommonStyle("box", { 
                    //         alignItems: "center", 
                    //         padding: 15, 
                    //         //backgroundColor: "#00f"
                    //     }) }>
                    //         <Text style={TextStyle("rowTitleDesc", "largest", { lineHeight: 30, paddingLeft: 20 })}>
                    //             {board.popContents.replace(/<br>/gi, '\n')}
                    //         </Text>
                    //     </View>
                    //     {/* image */}
                    //     { this.renderImage(board) }
                    // </ScrollView>