import AppSetting from '../defines/AppSetting';

const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_TMAP_API_LOG) {
        console.log(`[TMAP API] ${m}`, o)
    }
}

const warn = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_TMAP_API_WARN) {
        console.log(`[WARN][TMAP API] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][TMAP API] ${m}`, e)
}


const commonParams = `version=1&format=json&appKey=${AppSetting.TMAP.APP_KEY}`;
const baseUrl = (filename) => {
    return `https://apis.openapi.sk.com/tmap${filename}?${commonParams}`;
}

const FrRestAPI = {
    /**
     * TMap->주소검색->Reverse Geocoding
     * 주어진 위치의 정보를 회신.
     * 사용자 위치의 도시이름 얻어오기(서울, 경기, 전국을 검사하기 위해 필요하다.)
     * addressType: A01=행정동, A02=법정동 
     */
    getCityName(lon, lat) {
        // let serverURL = `https://apis.openapi.sk.com/tmap/geo/reversegeocoding?version=1&format=json&callback=result&addressType=A10` +
        //        `&appKey=${AppSetting.TMAP.APP_KEY}&coordType=${AppSetting.TMAP.COORD_TYPE}` + 
        //        `&lon=${lon}&lat=${lat}`;
        let serverURL = baseUrl("/geo/reversegeocoding") +
                        `&coordType=${AppSetting.TMAP.COORD_TYPE}` +
                        `&addressType=A01` +
                        `&lon=${lon}&lat=${lat}`;
        log(`requesting getCityName()...`, serverURL);
        return (
            fetch(serverURL, { method: 'GET' })
                .then(
                    (response) => {
                        if (response.ok) {
                            if (response.status === 200) {
                                return response.json()
                            }
                            else {
                                return null
                            }
                        }
                        else {
                            throw "invalid http response status: " + response.status
                        }
                    }, 
                    (err) => {
                        error(`fail to request.`, err)
                        throw `fail to request.`
                    }
                )
                .then((json) => {
                    if (json && json.addressInfo) {
                        return json.addressInfo.city_do
                    }
                    else {
                        log(`no matched city name found.`, json)
                        return ""
                        
                    }                    
                })
                .catch((err) => {
                    error("unknown error. ", err)
                })
        )
    },

    /**
     * TMap->명칭검색->읍면동/도로명 조회
     * 명칭으로 주소 검색
     * area_si_do: 서울,경기,충북...
     * addressType: [all|addressName|roadName]
     */
    getAdminDongList(si_do, search) {
        let area_si_do = "";
        if (["서울", "경기"].includes(si_do)) {
            area_si_do = `&area_si_do=${si_do}`;
        }
        let serverURL = baseUrl("/poi/findPoiAreaDataByName") +
            `&resCoordType=${AppSetting.TMAP.COORD_TYPE}` +
            `&page=1&count=1` +
            `&addressType=addressName` +
            `&${area_si_do}` + 
            `&area_dong=${search}`;
        log(`requesting getAdminDongList()...`, serverURL);
        return (
            fetch(serverURL, { method: 'GET' })
                .then((response) => {
                    if (response.ok) {
                        if (response.status === 200) {
                            return response.json()
                        }
                        else {
                            return null
                        }
                    }
                    else {
                        throw "invalid http response status: " + response.status
                    }
                }, 
                (err) => {
                    error(`fail to request.`, err)
                    throw `fail to request.`
                })
                .then((json) => {
                    return json
                })
                .catch((err) => {
                    error("unknown error. ", err)
                })
        )
    },
    // 입력된 문자로 읍/면/동 검색하기 - 서울
    getPOISeoul(str) {
        return this.getAdminDongList("서울", str)
    },
    // 입력된 문자로 읍/면/동 검색하기 - 경기
    getPOIGyong(str) {
        return this.getAdminDongList("경기", str)
    },
    // 입력된 문자로 읍/면/동 검색하기 - 전국
    getPOIAll(str) {
        return this.getAdminDongList("", str)
    }
}

export default FrRestAPI