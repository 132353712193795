import React, { Component, useState, useEffect, useRef } from 'react';
import {
    StyleSheet, Text, View, TouchableOpacity, TextInput,
    TouchableWithoutFeedback, Keyboard, ScrollView, FlatList, Alert,
    SectionList
} from 'react-native';
import { NavigationContext } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import AlarmContext from '../../context/AlarmContext';

// config, lib
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as ArrayUtil from '../../utils/ArrayUtil';
import * as HistoryUtil from '../../utils/HistoryUtil';
import * as JsUtil from '../../utils/JsUtil';
import { Colors, Layouts } from '../../defines/Theme';
import Texts, { GetText } from '../../defines/Texts';
import AppSetting from '../../defines/AppSetting';
import * as GBus from '../../defines/GBus';
import * as GBusHelper from '../../defines/GBusHelper';
import * as FavorUtil from '../../utils/FavorUtil';
import * as NavDebug from '../../utils/NavUtil';
import GAPI, { TAPI } from '../../api/RestAPI';
// import RestAPI from '../../api/RestAPI';
// shared
import Marquee from '../components/common/Marquee';
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import TabSelectBar from '../components/common/TabSelectBar';
import StationInfoBottomTab from '../components/bottomTabs/StationInfoBottomTab';
import ListHeader from '../components/parts/ListHeader';
import ListEmpty from '../components/parts/ListEmpty';
import SpacerListBottom from '../components/common/SpacerListBottom';
import InputClear from '../components/common/InputClear';
import StationRouteArrivalRow from '../components/StationRouteArrivalRow';
import Refresh from '../components/common/Refresh';
import StationHeader from '../components/StationHeader';
import SectionHeader from '../components/parts/SectionHeader';

import RouteTypeAndNameBox from '../components/parts/RouteTypeAndNameBox';

let UserScriptApi = require('../../utils/UserScriptApi');
// local

const RenderSoonRoutes = ({ soonArrivals, soonRotateArrivals, rotate, onPressHeader }) => {

    let arr = rotate ? soonRotateArrivals : soonArrivals;
    return (
        <View>
            <SectionHeader text={GetText("station", "SOON_ARRIVAL")} textStyle={{ color: Colors.fontColor }}/>
            <View style={SectionStyle("rowForRow", { 
                flexWrap: "wrap", 
                justifyContent: "flex-start",
                paddingVertical: 7, 
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                backgroundColor: Colors.appBackgroundColor, 
            })}>
            {
                arr.map((ra, index) => {
                    let routeType = JsUtil.GText(ra, "routeTypeCd");
                    let routeName = JsUtil.GText(ra, "routeName");
                    let bcolor = Colors.red; // GBus.GetRouteTypeColor(routeType)
                    return (
                    <Text key={index} style={ TextStyle('rowDetail', 'largest', { 
                        width: "auto",
                        margin: 3, 
                        color: bcolor
                    }) }>
                        { routeName }{ index < arr.length - 1 ? ", " : "" }
                    </Text>
                    )
                })
            }
            </View>
        </View>
    )
}

export default class StationInfo extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)

        // 변수 초기화 부분
        this.state = {
            stationId: '',
            stationInFavor: false,
            stationDetail: null,
            stationRouteArrivals: null,
            sectionizedStationRouteArrivals: null,
            soonArrivals: null,
            soonRotate: true,
            //soonChangeCounter: 0,
            soonRotateIndex: 0,
            soonRotateArrivals: [],
        }

        this.refresh = this.refresh.bind(this);
        this.rotateArriveSoon = this.rotateArriveSoon.bind(this);
        this.refreshTimer = null;
        this.arriveSoonRotateTimer = null; 
    }

    componentDidMount() {

        UserScriptApi.apiConnect('stationInfo')
        console.log(`[StationInfo] mount`)
        //const navigation = this.context
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            console.log(`[StationInfo] focused`)
            if (!this.state.stationDetail || 
                JsUtil.GText(this.state.stationDetail, "stationId") !== JsUtil.GText(this.props.route.params.data, "stationId")) {
                this.setState({
                    stationId: JsUtil.GText(this.props.route.params.data, "stationId"),
                    stationInFavor: false,
                    stationDetail: null,
                    stationRouteArrivals: null,
                    soonArrivals: null,
                }, () => {
                    // route in favor ? 
                    this.syncGlobalToState();
                    this._initStationInfo(JsUtil.GText(this.props.route.params.data, "stationId"))    
                    this.refresh();
                })
            }
            else {
                // route in favor ? 
                this.syncGlobalToState();
                this.refresh();
            }
            this.refreshTimer = setInterval(this.refresh, AppSetting.GENERAL.AUTO_REFRESH_INTERVAL);
            this.arriveSoonRotateTimer = setInterval(this.rotateArriveSoon, AppSetting.STATION.ARRIVE_SOON_ROTATE_INTERVAL);
        })
        this._blur = this.props.navigation.addListener('blur', () => {
            console.log(`[StationInfo] blured`)
            if (this.refreshTimer) {
                clearInterval(this.refreshTimer);
            }
            if (this.arriveSoonRotateTimer) {
                 clearInterval(this.arriveSoonRotateTimer);
            }
        });
    }

    componentWillUnmount() {
        console.log("[StationInfo] unmount");
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
        if (this.arriveSoonRotateTimer) {
            clearInterval(this.arriveSoonRotateTimer);
        }
        this._unsubscribe?.();
        this._blur?.();
    }

    _initStationInfo(stationId, byUser, callback) {
        console.log(`[StationInfo] _initStationInfo(). R:${stationId}`)
        // get station details
        GAPI.doRequest('getStationDetail', stationId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    this.setState({
                        stationDetail: res.response.msgBody.busStationInfo
                    })
                    callback?.()
                }
                else {
                    console.log(`[StationInfo] fail to get station detail`)
                    this.setState({
                        stationDetail: null
                    })
                    this.context.msgHandler?.error("StationInfo", msg, code);
                }
            })
            .catch(err => {
                this.setState({
                    stationDetail: null
                })
                console.log("Unknown request error...");
                console.log(err);
                this.context.msgHandler?.error("StationInfo", GetText("error", "unknown"), err);
            })
    }

    rotateArriveSoon() {
        const showCount = 3;
        if (this.state.soonArrivals.length <= showCount) {
            this.setState({
                soonRotateArrivals: this.state.soonArrivals,
                soonRotateIndex: 0
            })
            console.log(`--- next soon arrivals. all<4`)
            return;
        }
        let idx = this.state.soonRotateIndex;
        let lastIdx = idx;
        let indexes = [];
        for (let i = 0; i < showCount; i++) {
            idx = (this.state.soonRotateIndex + i) % this.state.soonArrivals.length;
            lastIdx = idx;
            indexes.push(idx)
        }
        let arr = [];
        indexes.forEach(i => {
            arr.push(this.state.soonArrivals[i])
        })
        this.setState({
            soonRotateArrivals: arr,
            soonRotateIndex: lastIdx + 1
        })
        console.log(`--- next soon arrivals. ${indexes.join(",")}`)
    }

    _updateSoonArrivals(routeArrivals) {
        let soonArrivals = routeArrivals.filter(ra => {
            let found = false;
            if (ra.busStatusList) {
                found = ra.busStatusList.find(bs =>
                    (!bs.touble || bs.touble === GBusHelper.BUS_TROUBLES.NONE) &&
                    bs.runStatus === GBusHelper.BUS_RUNS.OK &&
                    bs.predictTime <= 3
                )
            }
            return found;
        })
        soonArrivals.sort((a, b) => JsUtil.GInt(a, "predictTime") - JsUtil.GInt(b, "predictTime"))
        this.setState({
            soonArrivals: soonArrivals,
            //soonChangeCounter: this.state.soonChangeCounter + 1,
        }, () => {
            // console.log(`_updateSoonArrivals() done. counter=${this.state.soonChangeCounter}`)
            this.rotateArriveSoon();
        })
    }

    _refreshStationRouteArrivals(stationId, byUser) {
        console.log(`[StationInfo] _refreshStationRouteArrivals() byUser: ${byUser}`);
        if (byUser) {
            showPageLoader(true)
        }
        // get running bus list
        GAPI.doRequest('getStationRouteArrivals', stationId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    if (Array.isArray(res.response.msgBody.busArrivalList)) {
                        // 도착 버스 상태 판독
                        let arr = GBusHelper.proveRouteBusArrivals(res.response.msgBody.busArrivalList,
                            this.state.stationRouteArrivals)
                        this._updateSoonArrivals(arr)
                        this.setState({
                            stationRouteArrivals: arr,
                            sectionizedStationRouteArrivals: GBusHelper.SectionizeRouteArrivals(arr),
                        })
                    }
                    else {
                        let arr = GBusHelper.proveRouteBusArrivals([res.response.msgBody.busArrivalList],
                            this.state.stationRouteArrivals)
                        this._updateSoonArrivals(arr)
                        this.setState({
                            stationRouteArrivals: arr,
                            sectionizedStationRouteArrivals: GBusHelper.SectionizeRouteArrivals(arr),
                        })
                    }
                }
                else {
                    console.log("getStationRouteArrivals() no arrival")
                    this._updateSoonArrivals([])
                    this.setState({
                        stationRouteArrivals: [],
                        sectionizedStationRouteArrivals: [],
                    })
                    console.log(`[StationInfo] fail to get routeArrivals`)
                    console.log(res)
                    if (byUser) {
                        this.context.msgHandler?.toast(msg);
                    }
                }
            })
            .catch(err => {
                this._updateSoonArrivals([])
                this.setState({
                    stationRouteArrivals: [],
                    sectionizedStationRouteArrivals: [],
                })
                console.log("Unknown request error...");
                console.log(err);
                if (byUser) {
                    this.context.msgHandler?.error("StationInfo", GetText("error", "unknown"), err);
                }
            })
            .finally(() => {
                showPageLoader(false)
            })
    }

    refresh(byUser) {
        console.log(`[StationInfo] refresh() byUser: ${byUser}`);
        if (byUser) {
            showPageLoader(true)
        }
        if (!this.state.stationDetail) {
            this._initStationInfo(
                JsUtil.GText(this.props.route.params.data, "stationId"),
                byUser,
                () => {
                    this._refreshStationRouteArrivals(JsUtil.GText(this.props.route.params.data, "stationId"), byUser)
                });
        }
        else {
            this._refreshStationRouteArrivals(JsUtil.GText(this.props.route.params.data, "stationId"), byUser)
        }
    }

    syncGlobalToState() {
        let { favorIndex } = FavorUtil.IsStationInFavor(this.state.stationId)
        this.setState({ stationInFavor: favorIndex >= 0 });
    }

    // renderNoContent = ({section}) => {
    //     if(section.data.length == 0){
    //        return NO_CONTENT_COMPONENT
    //     }
    //     return null
    //  }    
    // renderSoonRoutes(soonArrivals) {
    //     let t = "";
    //     console.log("===renderSoonRoutes", soonArrivals)
    //     soonArrivals.forEach(r => {
    //         t = t + ", " + JsUtil.GText(r, "routeId")
    //         console.log("===renderSoonRoutes(elem)", r)
    //     })
    //     return <View><Text>{t}</Text></View>
    // }

    render() {

        //console.log(JSON.stringify(this.props.route.params.data, null, 4))
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    title={GetText("pageTitle","STATION_INFO")}
                    // iconType={this.state.stationInFavor ? "fad" : "fal"}
                    // iconName="star"
                    // onPress={() => {
                    //     if (!this.state.stationDetail) {
                    //         return;
                    //     }
                    //     // setFavors(data)
                    //     if (!this.state.stationInFavor) {
                    //         FavorUtil.AddFavorStation(this.state.stationDetail,
                    //             (done) => { done && this.syncGlobalToState() }
                    //         )
                    //     }
                    //     else {
                    //         FavorUtil.RemoveFavorStation(this.props.route.params.data.stationId._text,
                    //             (done) => { done && this.syncGlobalToState() }
                    //         )
                    //     }
                    // }}
                    // onGetIconColor={() => this.state.stationInFavor ? Colors.yellow : Colors.ggBlue}
                />
                <View style={CommonStyle('body')}>
                    <StationHeader station={this.state.stationDetail} />
                    { this.state.soonArrivals ? 
                        <RenderSoonRoutes 
                            soonArrivals={this.state.soonArrivals} 
                            soonRotateArrivals={this.state.soonRotateArrivals} 
                            rotate={this.state.soonRotate}
                            onPressHeader={() => {
                                // this.setState({
                                //     soonRotate: !this.state.soonRotate
                                // })
                            }}
                            /> : null }
                    {/* <RenderSoonRoutes soonArrivals={this.state.soonCurrArrivals} changeCounter={this.state.soonChangeCounter} /> */}
                    <SectionList
                        //sections={GBus.GroupStations(this.state.stationRouteArrivals)}
                        sections={this.state.sectionizedStationRouteArrivals}
                        stickySectionHeadersEnabled={true}
                        renderItem={({ item }) =>
                            <StationRouteArrivalRow
                                leftSpaceWidth={20}
                                leftSpaceWidthOfBusRow={30 + Layouts.routeNameWidth - Layouts.rowLeftIconAreaWidth}
                                routeArrival={item}
                                station={this.state.stationDetail}
                                navigation={this.props.navigation}
                                // 노선행(버스2대 도착시간) 탭 => 버스행 토글
                                onPress={() => {
                                    this.props.navigation.navigate('routeInfo', {
                                        data: {
                                            stationId: item.stationId._text,
                                            staOrder: JsUtil.GText(item, "staOrder") || JsUtil.GText(item, "stationSeq"),
                                            turnSeq: JsUtil.GText(item, "turnSeq"),
                                            routeId: item.routeId._text
                                        }
                                    })
                                }}
                                // 버스행 탭 => 승차지원
                                onBusPress={() => {
                                    this.props.navigation.navigate('rideSupport', {
                                        stationId: JsUtil.GText(item, "stationId"),
                                        staOrder: JsUtil.GText(item, "staOrder") || JsUtil.GText(item, "stationSeq"),
                                        turnSeq: JsUtil.GText(item, "turnSeq"),
                                        routeId: JsUtil.GText(item, "routeId")
                                    })
                                }}
                            />
                        }
                        renderSectionHeader={({ section }) =>
                            <SectionHeader text={section.title} />}
                        ListFooterComponent={<SpacerListBottom />}
                        // renderSectionFooter={this.renderNoContent}
                        keyExtractor={(item, index) => index}
                    />
                </View>
                <Refresh onPress={() => { this.refresh(true) }} />
                <StationInfoBottomTab
                    navigation={this.props.navigation}
                    // onPressBack={()=>{
                    //     clearInterval(this.timer)
                    //     this.props.navigation.goBack();
                    // }}
                    onPressMapBtn={() => {
                        // clearInterval(this.timer)
                        this.props.navigation.navigate('stationMap', {
                            data: this.props.route.params.data
                        })
                    }}
                />
            </SafeAreaView>
        )
    }
}
