import React, { Component } from 'react';
import {
    Platform,
    ScrollView,
    Text,
    View,
    TouchableOpacity,
    NativeModules,
} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {
    getManufacturer,
    getManufacturerSync,
    syncUniqueId,
    useBatteryLevel,
    useBatteryLevelIsLow,
    usePowerState,
    useFirstInstallTime,
    useDeviceName,
    useManufacturer,
    useHasSystemFeature,
    useIsEmulator,
    useIsHeadphonesConnected,
} from 'react-native-device-info';


/*---------------------------------------------------------------------
  Web support
----------------------------------------------------------------------*/
// return full location object
export const getWebServiceLocation = () => {
    if (window && window.location) {
        return window.location
    }
    return null;
}

// return https://host:port
export const getWebServiceBaseUrl = () => {
    let loc = getWebServiceLocation();
    if (loc && loc.host) {
        return `${loc.protocol}//${loc.host}`
    }
    return "";
}

// return hostname
export const getWebServiceHostname = () => {
    let loc = getWebServiceLocation();
    if (loc) {
        return loc.hostname
    }
    return "";
}

export const getWebOSSync = () => {
    try {
        let agent = DeviceInfo.getUserAgentSync();
        let os = "web";
        if (agent.match(/Android/i)) {
            os = "android"
        }
        else if (agent.match(/iPhone|iPad|iPod/i)) {
            os = "iOS"
        }
        return os;
    }
    catch (e) { }
    return "web";
}


export const getWebOS = async (callback) => {
    try {
        let agent = await DeviceInfo.getUserAgent();
        let os = "web";
        if (agent.match(/Android/i)) {
            os = "android"
        }
        else if (agent.match(/iPhone|iPad|iPod/i)) {
            os = "iOS"
        }
        callback?.(os);
    }
    catch (e) { }
}

export const getWebInfo = (validItemOnly) => {
    let info = {};
    if (Platform.OS == "web") {
        info.location = getWebServiceLocation();
        info.baseUrl = getWebServiceBaseUrl();
        info.hostname = getWebServiceHostname();
        info.userAgent = DeviceInfo.getUserAgentSync();
        info.os = getWebOSSync();
    }
    return info;
}

/*---------------------------------------------------------------------
  Native support
----------------------------------------------------------------------*/
const getSyncItem = (func, param) => {
    if (DeviceInfo && typeof DeviceInfo[func] == "function") {
        try {
            return DeviceInfo[func](param);
        }
        catch (e) {
            return "failed"
        }
    }
    return "unknown";
}

const getAsyncItem = async (func, param) => {
    if (DeviceInfo && typeof DeviceInfo[func] == "function") {
        try {
            return await DeviceInfo[func](param);
        }
        catch (e) {
            return "failed"
        }
    }
    return "unknown";
}

export const getConstantDeviceInfo = (validItemOnly) => {
    let info = {};
    if (Platform.OS !== "web") {
        info.uniqueId = getSyncItem("getUniqueId");
        info.deviceId = getSyncItem("getDeviceId");
        info.bundleId = getSyncItem("getBundleId");
        info.systemName = getSyncItem("getSystemName");
        info.systemVersion = getSyncItem("getSystemVersion");
        info.version = getSyncItem("getVersion");
        info.readableVersion = getSyncItem("getReadableVersion");
        info.buildNumber = getSyncItem("getBuildNumber");
        info.isTablet = getSyncItem("isTablet");
        info.appName = getSyncItem("getApplicationName");
        info.brand = getSyncItem("getBrand");
        info.model = getSyncItem("getModel");
        info.deviceType = getSyncItem("getDeviceType");
        if (validItemOnly) {
            Object.keys(info).forEach(k => {
                if (info[k] == undefined || info[k] == "unknown" || info[k] == "failed" ||
                    (typeof info[k] == "string" && info[k].match(/unknown\.unknown/i))) {
                    delete info[k];
                }
            })
        }
    }
    return info;
}

export const getSyncDeviceInfo = (validItemOnly) => {
    let info = {};
    info.userAgent = getSyncItem("getUserAgentSync");
    if (Platform.OS !== "web") {
        info.manufacturer = getSyncItem("getManufacturerSync");
        info.buildId = getSyncItem("getBuildIdSync");
        info.isCameraPresent = getSyncItem("isCameraPresentSync");
        info.deviceName = getSyncItem("getDeviceNameSync");
        info.usedMemory = getSyncItem("getUsedMemorySync");
        info.instanceId = getSyncItem("getInstanceIdSync");
        info.installReferrer = getSyncItem("getInstallReferrerSync");
        info.installerPackageName = getSyncItem("getInstallerPackageNameSync");
        info.isEmulator = getSyncItem("isEmulatorSync");
        info.fontScale = getSyncItem("getFontScaleSync");
        info.hasNotch = getSyncItem("hasNotch");
        info.firstInstallTime = getSyncItem("getFirstInstallTimeSync");
        info.lastUpdateTime = getSyncItem("getLastUpdateTimeSync");
        info.serialNumber = getSyncItem("getSerialNumberSync");
        info.androidId = getSyncItem("getAndroidIdSync");
        info.IpAddress = getSyncItem("getIpAddressSync");
        info.MacAddress = getSyncItem("getMacAddressSync"); // needs android.permission.ACCESS_WIFI_STATE
        info.phoneNumber = getSyncItem("getPhoneNumberSync"); // needs android.permission.READ_PHONE_STATE
        info.ApiLevel = getSyncItem("getApiLevelSync");
        info.carrier = getSyncItem("getCarrierSync");
        info.totalMemory = getSyncItem("getTotalMemorySync");
        info.maxMemory = getSyncItem("getMaxMemorySync");
        info.totalDiskCapacity = getSyncItem("getTotalDiskCapacitySync");
        info.totalDiskCapacityOld = getSyncItem("getTotalDiskCapacityOldSync");
        info.freeDiskStorage = getSyncItem("getFreeDiskStorageSync");
        info.freeDiskStorageOld = getSyncItem("getFreeDiskStorageOldSync");
        info.batteryLevel = getSyncItem("getBatteryLevelSync");
        info.isLandscape = getSyncItem("isLandscapeSync");
        info.isAirplaneMode = getSyncItem("isAirplaneModeSync");
        info.isBatteryCharging = getSyncItem("isBatteryChargingSync");
        info.isPinOrFingerprintSet = getSyncItem("isPinOrFingerprintSetSync");
        info.supportedAbis = getSyncItem("supportedAbisSync");
        info.hasSystemFeature = getSyncItem("hasSystemFeatureSync", "android.software.webview");
        info.getSystemAvailableFeatures = getSyncItem("getSystemAvailableFeaturesSync");
        info.powerState = getSyncItem("getPowerStateSync");
        info.isLocationEnabled = getSyncItem("isLocationEnabledSync");
        info.headphones = getSyncItem("isHeadphonesConnectedSync");
        info.getAvailableLocationProviders = getSyncItem("getAvailableLocationProvidersSync");
        info.bootloader = getSyncItem("getBootloaderSync");
        info.device = getSyncItem("getDeviceSync");
        info.display = getSyncItem("getDisplaySync");
        info.fingerprint = getSyncItem("getFingerprintSync");
        info.hardware = getSyncItem("getHardwareSync");
        info.host = getSyncItem("getHostSync");
        info.product = getSyncItem("getProductSync");
        info.tags = getSyncItem("getTagsSync");
        info.type = getSyncItem("getTypeSync");
        info.baseOS = getSyncItem("getBaseOsSync");
        info.previewSdkInt = getSyncItem("getPreviewSdkIntSync");
        info.securityPatch = getSyncItem("getSecurityPatchSync");
        info.codename = getSyncItem("getCodenameSync");
        info.incremental = getSyncItem("getIncrementalSync");
        info.supported32BitAbis = getSyncItem("supported32BitAbisSync");
        info.supported64BitAbis = getSyncItem("supported64BitAbisSync");
        info.hasGms = getSyncItem("hasGmsSync");
        info.hasHms = getSyncItem("hasHmsSync");
        if (validItemOnly) {
            Object.keys(info).forEach(k => {
                if (info[k] == undefined || info[k] == "unknown" || info[k] == "failed" ||
                    (typeof info[k] == "string" && info[k].match(/unknown\.unknown/i))) {
                    delete info[k];
                }
            })
        }
    }
    return info;
}

export const getAsyncDeviceInfo = async (validItemOnly) => {
    let info = {};
    info.userAgent = await getAsyncItem("getUserAgent");
    if (Platform.OS !== "web") {
        info.manufacturer = await getAsyncItem("getManufacturer");
        info.buildId = await getAsyncItem("getBuildId");
        info.isCameraPresent = await getAsyncItem("isCameraPresent");
        info.deviceName = await getAsyncItem("getDeviceName");
        info.usedMemory = await getAsyncItem("getUsedMemory");
        info.userAgent = await getAsyncItem("getUserAgent");
        info.instanceId = await getAsyncItem("getInstanceId");
        info.installReferrer = await getAsyncItem("getInstallReferrer");
        info.installerPackageName = await getAsyncItem("getInstallerPackageName");
        info.isEmulator = await getAsyncItem("isEmulator");
        info.fontScale = await getAsyncItem("getFontScale");
        info.hasNotch = await getAsyncItem("hasNotch");
        info.firstInstallTime = await getAsyncItem("getFirstInstallTime");
        info.lastUpdateTime = await getAsyncItem("getLastUpdateTime");
        info.serialNumber = await getAsyncItem("getSerialNumber");
        info.androidId = await getAsyncItem("getAndroidId");
        info.IpAddress = await getAsyncItem("getIpAddress");
        info.MacAddress = await getAsyncItem("getMacAddress"); // needs android.permission.ACCESS_WIFI_STATE
        info.phoneNumber = await getAsyncItem("getPhoneNumber"); // needs android.permission.READ_PHONE_STATE
        info.ApiLevel = await getAsyncItem("getApiLevel");
        info.carrier = await getAsyncItem("getCarrier");
        info.totalMemory = await getAsyncItem("getTotalMemory");
        info.maxMemory = await getAsyncItem("getMaxMemory");
        info.totalDiskCapacity = await getAsyncItem("getTotalDiskCapacity");
        info.totalDiskCapacityOld = await getAsyncItem("getTotalDiskCapacityOld");
        info.freeDiskStorage = await getAsyncItem("getFreeDiskStorage");
        info.freeDiskStorageOld = await getAsyncItem("getFreeDiskStorageOld");
        info.batteryLevel = await getAsyncItem("getBatteryLevel");
        info.isLandscape = await getAsyncItem("isLandscape");
        info.isAirplaneMode = await getAsyncItem("isAirplaneMode");
        info.isBatteryCharging = await getAsyncItem("isBatteryCharging");
        info.isPinOrFingerprintSet = await getAsyncItem("isPinOrFingerprintSet");
        info.supportedAbis = await getAsyncItem("supportedAbis");
        info.hasSystemFeature = await getAsyncItem("hasSystemFeature", 'android.software.webview');
        info.getSystemAvailableFeatures = await getAsyncItem("getSystemAvailableFeatures");
        info.powerState = await getAsyncItem("getPowerState");
        info.isLocationEnabled = await getAsyncItem("isLocationEnabled");
        info.headphones = await getAsyncItem("isHeadphonesConnected");
        info.getAvailableLocationProviders = await getAsyncItem("getAvailableLocationProviders");
        info.bootloader = await getAsyncItem("getBootloader");
        info.device = await getAsyncItem("getDevice");
        info.display = await getAsyncItem("getDisplay");
        info.fingerprint = await getAsyncItem("getFingerprint");
        info.hardware = await getAsyncItem("getHardware");
        info.host = await getAsyncItem("getHost");
        info.product = await getAsyncItem("getProduct");
        info.tags = await getAsyncItem("getTags");
        info.type = await getAsyncItem("getType");
        info.baseOS = await getAsyncItem("getBaseOs");
        info.previewSdkInt = await getAsyncItem("getPreviewSdkInt");
        info.securityPatch = await getAsyncItem("getSecurityPatch");
        info.codename = await getAsyncItem("getCodename");
        info.incremental = await getAsyncItem("getIncremental");
        info.supported32BitAbis = await getAsyncItem("supported32BitAbis");
        info.supported64BitAbis = await getAsyncItem("supported64BitAbis");
        info.hasGms = await getAsyncItem("hasGms");
        info.hasHms = await getAsyncItem("hasHms");
        info.synchronizedUniqueId = await getAsyncItem("syncUniqueId");
        try {
            info.deviceToken = await getAsyncItem("getDeviceToken");
        } catch (e) {
            console.log(
                'Trouble getting device token, likely a simulator or not iOS11+',
            );
        }
        if (validItemOnly) {
            Object.keys(info).forEach(k => {
                if (info[k] == undefined || info[k] == "unknown" || info[k] == "failed" ||
                    (typeof info[k] == "string" && info[k].match(/unknown\.unknown/i))) {
                    delete info[k];
                }
            })
        }
    }
    return info;
}



const FunctionalComponent = () => {
    // Fired when the battery level changes; sent no more frequently than once per minute
    if (Platform.OS == "web") {
        return (
            <ScrollView>
                <Text style={styles.instructions}> Not available on web </Text>
            </ScrollView>
        );
    }
    const batteryLevel = useBatteryLevel();
    const batteryLevelIsLow = useBatteryLevelIsLow();
    const powerState = usePowerState();
    const firstInstallTime = useFirstInstallTime();
    const deviceName = useDeviceName();
    const manufacturer = useManufacturer();
    const hasSystemFeature = useHasSystemFeature('amazon.hardware.fire_tv');
    const isEmulator = useIsEmulator();
    const isHeadphonesConnected = useIsHeadphonesConnected();
    const deviceJSON = {
        batteryLevel,
        batteryLevelIsLow,
        powerState,
        firstInstallTime,
        deviceName,
        manufacturer,
        hasSystemFeature,
        isEmulator,
        isHeadphonesConnected,
    };

    return (
        <ScrollView>
            <Text style={styles.instructions} testID="hooks tab contents">
                {JSON.stringify(deviceJSON, null, '  ')}
            </Text>
        </ScrollView>
    );
};

export class DeviceInfoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'constant',
            showValidValueOnly: false,
            constantDeviceInfo: {},
            asyncDeviceInfo: {},
            syncDeviceInfo: {},
            webInfo: {},
        };
    }

    refreshConstantDeviceInfo() {
        this.setState({
            constantDeviceInfo: getConstantDeviceInfo()
        })
    }
    refreshSyncDeviceInfo() {
        this.setState({
            syncDeviceInfo: getSyncDeviceInfo()
        })
    }
    async refreshAsyncDeviceInfo() {
        let di = await getAsyncDeviceInfo();
        this.setState({
            asyncDeviceInfo: di
        })
    }
    async refreshWebInfo() {
        this.setState({
            webInfo: getWebInfo()
        })
    }    

    async componentDidMount() {
        this.refreshConstantDeviceInfo();
        this.refreshSyncDeviceInfo();
        this.refreshAsyncDeviceInfo();
        this.refreshWebInfo();
    }

    renderConstant = () => {
        if (this.state.activeTab !== 'constant') {
            return null;
        }
        return (<>
            <Text style={styles.welcome}>장치정보(상수)</Text>
            <ScrollView>
                <Text style={styles.instructions}>
                    {JSON.stringify(this.state.constantDeviceInfo, null, '  ')}
                </Text>
            </ScrollView>
        </>)
    }
    renderSync = () => {
        if (this.state.activeTab !== 'sync') {
            return null;
        }
        return (<>
            <Text style={styles.welcome}>동기방식 추출</Text>
            <ScrollView>
                <Text style={styles.instructions}>
                    {JSON.stringify(this.state.syncDeviceInfo, null, '  ')}
                </Text>
            </ScrollView>
        </>)
    }
    renderAsync = () => {
        if (this.state.activeTab !== 'async') {
            return null;
        }
        return (<>
            <Text style={styles.welcome}>비동기방식 추출</Text>
            <ScrollView>
                <Text style={styles.instructions} >
                    {JSON.stringify(this.state.asyncDeviceInfo, null, '  ')}
                </Text>
            </ScrollView>
        </>)
    }
    renderHooks = () => {
        if (this.state.activeTab !== 'hooks') {
            return null;
        }
        return (<>
            <Text style={styles.welcome}>상태정보</Text>
            <FunctionalComponent />
        </>)
    }
    renderWeb = () => {
        if (this.state.activeTab !== 'web') {
            return null;
        }
        return (<>
            <Text style={styles.welcome}>웹</Text>
            <ScrollView>
                <Text style={styles.instructions} >
                    {JSON.stringify(this.state.webInfo, null, '  ')}
                </Text>
            </ScrollView>
        </>)
    }    

    renderTab = (tabId) => {
        return (
            <TouchableOpacity
                style={styles.tab}
                onPress={() => this.setState({ activeTab: tabId })}>
                <Text
                    style={[
                        styles.tabText,
                        this.state.activeTab === tabId && styles.boldText,
                    ]}>
                    {tabId}
                </Text>
            </TouchableOpacity>
        )
    }

    render() {
        // return (
        //     <SafeAreaView style={styles.container}>
        //     </SafeAreaView >
        // )
        return (
            <View style={styles.container}>
                {this.renderConstant()}
                {this.renderSync()}
                {this.renderAsync()}
                {this.renderHooks()}
                {this.renderWeb()}
                <View style={styles.tabBar}>
                    {this.renderTab("constant")}
                    {this.renderTab("sync")}
                    {this.renderTab("async")}
                    {this.renderTab("hooks")}
                    {this.renderTab("web")}
                </View>
            </View >
        );
    }
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#F5FCFF',
    },
    welcome: {
        fontSize: 20,
        textAlign: 'center',
        margin: 10,
    },
    instructions: {
        textAlign: 'left',
        color: '#333333',
        margin: 5,
    },
    tabBar: {
        flexDirection: 'row',
        borderTopColor: '#333333',
        borderTopWidth: 1,
    },
    tab: {
        height: 50,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabText: {
        color: '#333333',
    },
    boldText: {
        fontWeight: '700',
    },
};