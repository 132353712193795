import * as ArrayUtil from './ArrayUtil';
import * as DateUtil from './DateUtil';
import * as StorUtil from './StorUtil';
import * as JsUtil from './JsUtil';
import AppSetting from '../defines/AppSetting';


const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_HISTORY_LOG) {
        console.log(`[HistoryUtil] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][HistoryUtil] ${m}`, e)
}

export const InsertSearchHistory = async (value, callback) => {
    if (!global.historySearchs) { 
        global.historySearchs = [];
    }
    if (value) {
        try {
            // insert into first
            global.historySearchs.unshift({
                title: value,
                time: DateUtil.format(DateUtil._D_FORMAT_DEF)
            });
            // remove duplicated items
            global.historySearchs = ArrayUtil.Uniquify(global.historySearchs, 'title');
            // keep max 30 items
            if (global.historySearchs.length > 30) {
                global.historySearchs.length = 30;
            }
            await StorUtil.Save('historySearchs', global.historySearchs)
            callback?.(true)
        }
        catch (e) {
            error(`fail to save search history.`, e)
            callback?.(false)
        }
    }
}

export const ClearSearchHistory = async (callback) => {
    global.historySearchs = [];
    await StorUtil.Save('historySearchs', global.historySearchs)        
    callback?.()
}

/**
    name: ...
    address: address,
    noorLon: x,
    noorLat: y
 */
export const InsertPoiHistory = async (poi, callback) => {
    if (!global.historyPois) {
        global.historyPois = [];
    }
    if (poi) {
        try {
            // insert into first
            global.historyPois.unshift({
                name: poi.name,
                address: poi.upperAddrName + ' ' + poi.middleAddrName + ' ' + poi.lowerAddrName,
                noorLon: poi.noorLon,
                noorLat: poi.noorLat,
                time: DateUtil.format(DateUtil._D_FORMAT_DEF)
            });
            // remove duplicated items
            global.historyPois = ArrayUtil.Uniquify(global.historyPois, 'name');
            // keep max 30 items
            if (global.historyPois.length > 30) {
                global.historyPois.length = 30;
            }
            await StorUtil.Save('historyPois', global.historyPois) 
            callback?.(true)
        }
        catch (e) {
            error(`fail to save poi history.`, e)
            callback?.(false)
        }
    }
}

export const ClearPoiHistory = async (callback) => {
    global.historyPois = [];
    await StorUtil.Save('historyPois', global.historyPois)        
    callback?.()
}

// export const IsInPathFindHistory = (s, e) => {
//     if (!global.historyRoads || global.historyRoads.length < 1)
//         return false;
//     return global.historyRoads.find(elem => elem.sTitle == s.Title && elem.eTitle == e.Title) 
// }

export const InsertPathFindHistory = async (s, e, callback) => {
    if (!global.historyRoads) {
        global.historyRoads = [];
    }
    if (s && e) {
        try {
            global.historyRoads.unshift({
                // start poi
                sTitle: s.title,
                sAddress: s.address,
                sX: s.x,
                sY: s.y,
                // end poi
                eTitle: e.title,
                eAddress: e.address,
                eX: e.x,
                eY: e.y,
                time: DateUtil.format(DateUtil._D_FORMAT_DEF)
            })
            // remove duplicated items by sTitle, eTitle
            global.historyRoads = ArrayUtil.UniquifyEx(global.historyRoads, 
                (accum, curr) => {
                    return accum.find(elem => elem.sTitle == curr.sTitle && elem.eTitle == curr.eTitle) 
                }
            );
            // keep max 30 items
            if (global.historyRoads.length > 30) {
                global.historyRoads.length = 30;
            }
            await StorUtil.Save('historyRoads', global.historyRoads)
            callback?.()
        }
        catch (e) {
            error(`fail to save pathFind history.`, e)
            callback?.()
        }
    }
}

export const ClearPathFindHistory = async (callback) => {
    global.historyRoads = [];
    await StorUtil.Save('historyRoads', global.historyRoads)        
    callback?.()
}
