/** Expected argument
 * - Count: 1
 * - First arg type: object
 * - First object arg properties
 *   { 
 *      w: where : ex) api.tmap 
 *      m: message,
 *      l: level[0...9], 0 = debug...9=critical
 */ 

const Bug = (w = '', m = '', l = 0, params = null) => {
    console.log(`Bug reported. ${m} - ${w}, level=${l}`, params);
}

export default Bug;
