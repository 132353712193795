import Bug from '../utils/Bug';
import * as DateUtil from '../utils/DateUtil';
import * as JsUtil from '../utils/JsUtil';
import { GetText, GetTextSido } from '../defines/Texts';
import AppSetting from './AppSetting';
import * as GBus from './GBus';

// 정류소를 경유하는 모든 노선 유형별 그룹함수
export function SectionizeRouteArrivals(stationRouteArrivals) {
    if (!stationRouteArrivals) {
        return []
    }

    let sectionArr = [];
    // 전체 목록을 로딩 sectionItem = { title:, _isDef:, data[] }
    GBus.GBusConst.ROUTE_GROUPS.forEach(rGroup => {
        sectionArr.push({
            title: GetText("pageTabLabel", rGroup.title),
            data: [],
            routeTypes: rGroup.routeTypes,
            order: rGroup.order,
            _isDef: rGroup._isDef,
        })
    })
    if (!sectionArr.find(elem => elem._isDef)) {
        sectionArr[0]._isDef = true;
    }
    stationRouteArrivals.forEach(elem => {
        let sItem = sectionArr.find(sElem => sElem.routeTypes.includes(JsUtil.getStringProp(elem.routeTypeCd, "_text")));
        if (sItem) {
            sItem.data.push(elem)
        }
        else {
            sectionArr.find(sElem => sElem._isDef).data.push(elem)
        }
    })
    sectionArr = sectionArr.filter(elem => elem.data.length > 0).sort((a, b) => a.order - b.order)
    // console.log("|||||||||||||||||||||", sectionArr[0])
    sectionArr.forEach(section => {
        section.data.sort((a, b) => {
            let lenCompare = JsUtil.GText(a, "routeName").length - JsUtil.GText(b, "routeName").length;
            return lenCompare === 0 ?
                JsUtil.GText(a, "routeName").localeCompare(JsUtil.GText(b, "routeName"))
                : lenCompare;
        })
    })
    // console.log(">>>>>>>>>>>>>>>>>>>>>", sectionArr[0])
    return sectionArr;
}





//--- 노선의 도착정보(근접 2대)를 기반으로 버스 도착
/**
 * 1. [노선] 운행상태인지 검사(+운행시간)
 *      1.1 그럼에도 운행버스가 있을 수 있음. 버스별 판독을 하되, Alert
 * 2. [버스] 차고지, 회차지 대기중 판단
 * 3. [버스] 지체, 노선이탈 판단
 * 4. [버스] n분전, n번째전 판단
 */


export function BuildBusArrival(routeArrival, busSeq) {
    // console.log("------------ BuildBusArrival: ")
    // console.log(routeArrival);
    let busArrival = {
        source: { _text: "routeArrival" },
        flag: { _text: routeArrival.flag._text },
        // 
        stationId: { _text: routeArrival.stationId._text },
        // stationName: { _text: routeArrival.stationName._text },
        routeTypeCd: { _text: routeArrival.routeTypeCd._text },
        routeId: { _text: routeArrival.routeId._text },
        routeName: { _text: routeArrival.routeName._text },
        staOrder: { _text: routeArrival.staOrder._text },
        stationSeq: { _text: routeArrival.staOrder._text },
        //
        locationNo: { _text: routeArrival["locationNo" + busSeq]._text },
        predictTime: { _text: routeArrival["predictTime" + busSeq]._text },
        delayYn: { _text: routeArrival["delayYn" + busSeq]._text },
        lowPlate: { _text: routeArrival["lowPlate" + busSeq]._text },
        plateNo: { _text: routeArrival["plateNo" + busSeq]._text },
        vehId: { _text: routeArrival["vehId" + busSeq]._text },
        remainSeatCnt: { _text: routeArrival["remainSeatCnt" + busSeq]._text },
        density: { _text: routeArrival["density" + busSeq]._text },

        taglessCd: { _text: routeArrival["taglessCd" + busSeq]._text }
    }
}

export function BuildBusArrivals(routeArrival) {
    let arr = []
    if (JsUtil.getStringProp(routeArrival.locationNo1, "_text") && JsUtil.getStringProp(routeArrival.vehId1, "_text")) {
        arr.push(BuildBusArrival(routeArrival, "1", routeRunStatus))
    }
    if (JsUtil.getStringProp(routeArrival.locationNo2, "_text") && JsUtil.getStringProp(routeArrival.vehId2, "_text")) {
        arr.push(BuildBusArrival(routeArrival, "2", routeRunStatus))
    }
    return arr;
}


// 지금이 버스 운행시간인지 판독 
export function isInBusRunTime(startTime, endTime) { // 시간 체크해서 운행시간이 아니면 true 운행중 시간이면 false;
    // DateUtil returns moment object
    if (startTime && endTime) {
        try {
            let s = DateUtil.toMoment(startTime).set({ year: 0, month: 0, date: 0 });
            let e = DateUtil.toMoment(endTime).set({ year: 0, month: 0, date: 0 });
            let n = DateUtil.now().set({ year: 0, month: 0, date: 0 });
            // console.log(`isInBusRunTime() ${startTime}, ${endTime} ${DateUtil.format(s)}, ${DateUtil.format(e)}, ${DateUtil.format(n)}`)
            // 운행종료시간이 시작시간보다 크면, start~end 사이에 있어야 함
            if (e.isAfter(s)) {
                //console.log(`isInBusRunTime() e > s, isBetween=${n.isBetween(s, e)}`)
                return n.isBetween(s, e)
            }
            else if (e.isSame(s)) {
                return true;
            }
            // 운행종료시간이 시작시간보다 작으면, end~start 사이에 있지 않아야 함
            else {
                // console.log(`isInBusRunTime() e < s, isBetween=${!n.isBetween(e, s)}`)
                return !n.isBetween(e, s);
            }
        }
        catch (e) {
            console.log("isInBusRunTime() error", e);
            return true;
        }
    }
    else {
        return true
    }
}

// 버스위치, 시간 유효여부
const isBusLocationValid = (busStatus) => {
    return busStatus.vehId && busStatus.predictTime >= 0 || busStatus.locationNo > 0
}
const isBusAtGarage = (routeStatus, busStatus) => {
    return routeStatus.staOrder - busStatus.locationNo === 1
}
const isBusAtGarageTurn = (routeStatus, busStatus) => {
    return routeStatus.staOrder - busStatus.locationNo === routeStatus.turnSeq;
}
const isStationAfterTurnSeq = (routeStatus) => {
    return routeStatus.staOrder > routeStatus.turnSeq;
}

//--- 버스 운행 상태
export const BUS_RUNS = {
    // TIME: "시간으로 표시",
    // NPREV: "N번째 전으로 표시",
    OK: "OK",
    // SOON: "잠시후 도착",
    W_GARAGE: "WAIT_GARAGE",
    W_GARAGE_TURN: "WAIT_GARAGE_TURN",
    NO_RUN_BUS: "NO_RUN_BUS",
    STOPPED: "STOPPED",
    PROVE_FAIL: "PROVE_FAIL"
}
export const VALID_BUS_RUNS = [BUS_RUNS.OK, BUS_RUNS.W_GARAGE, BUS_RUNS.W_GARAGE_TURN]
export const DRAW_BUS_RUNS = [BUS_RUNS.OK]

//--- 버스 운행 보조 상태. auxiliary
// 버스 운행인 진행중이므로, 보조 상태로 표시. 지체, 노선이탈 등
export const BUS_RUNS_AUX = {
    NONE: "",
    END_BUS: "END_BUS",
    LOCAL_DELAY: "LOCAL_DELAY",
    ROUTE_OUT: "ROUTE_OUT",
}

//--- Touble 이 존재하면, 도착시간, N번째 대신 보여줍니다. 따라서 지체는 여기에 정의하면 안됩니다.
// 버스운행이 비정상인 경우
export const BUS_TROUBLES = {
    NONE: "NONE",
}



// 지연, 차고지 대기, 회차지 대기
const proveBusGarageWait = (routeStatus, busStatus) => {
    // 노선운행상태 = 대기중
    if ("WAIT" === routeStatus.flag || routeStatus.delayYn === "Y") {
        busStatus.runStatus = isStationAfterTurnSeq(routeStatus) ? BUS_RUNS.W_GARAGE_TURN : BUS_RUNS.W_GARAGE;
        busStatus.runStatusReason = `노선운행상태:${routeStatus.flag}, 노선.delayYn:${routeStatus.delayYn}`;
    }
    // 운행시간내
    //     if (routeStatus.isInBusRunTime) {
    //         if (routeStatus.delayYn === "Y" || busStatus.waitFlag === "1") {
    //             busStatus.runStatus = isStationAfterTurnSeq(routeStatus) ? BUS_RUNS.W_GARAGE_TURN : BUS_RUNS.W_GARAGE;
    //             busStatus.runStatusReason = `운행시간내, 노선.delayYn:${routeStatus.delayYn}, 버스.waitFlag:${busStatus.waitFlag}`;
    //         }
    //         else if (!isBusLocationValid(busStatus)) {
    //             busStatus.runStatus = BUS_RUNS.PROVE_FAIL;
    //             busStatus.runStatusReason = `운행시간내, 버스시간-위치불량:${busStatus.predictTime}-${busStatus.locationNo}, 노선.delayYn:${routeStatus.delayYn}, 버스.waitFlag:${busStatus.waitFlag}`;
    //             console.log(`[버스.대기상태판독()] 운행시간내, 버스시간-위치불량:${busStatus.predictTime}-${busStatus.locationNo}, 노선.delayYn:${routeStatus.delayYn}, 버스.waitFlag:${busStatus.waitFlag}`);
    //         }
    //     } 
    //     else {
    //         busStatus.runStatus = BUS_RUNS.PROVE_FAIL;
    //         busStatus.runStatusReason = `운행시간외, 노선운행상태:${routeStatus.flag}`;
    //         console.log(`[버스.대기상태판독()] 운행시간외, 노선운행상태:${routeStatus.flag}`)
    //     }
    // }
    // 노선운행상태 = 운행중
    else if (["RUN", "PASS"].includes(routeStatus.flag)) {
        // 위치 유효함
        // 버스가 차고지에 위치하고 노선의 garageFlag=Y
        if (isBusAtGarage(routeStatus, busStatus) && busStatus.waitFlag === "1" && routeStatus.garageFlag === "Y") {
            busStatus.runStatus = BUS_RUNS.W_GARAGE;
            busStatus.runStatusReason = `노선운행상태:${routeStatus.flag}, 버스위치:차고지, 버스.garageFlag:Y, 버스.waitFlag:1`;
        }
        // 버스가 회차지에 위치하고 노선의 garageTurnFlag=Y
        else if (isBusAtGarageTurn(routeStatus, busStatus) && busStatus.waitFlag === "1" && routeStatus.garageTurnFlag === "Y") {
            busStatus.runStatus = BUS_RUNS.W_GARAGE_TURN;
            busStatus.runStatusReason = `노선운행상태:${routeStatus.flag}, 버스위치:회차지, 버스.garageTurnFlag:Y 버스.waitFlag:1`;
        }
        // 기타 정상
        else {

        }
    }
}

// 노선이탈등
const proveBusTrouble = (routeStatus, busStatus) => {


}

export const getRunBusAuxStatus = (routeOutFlag, endBus) => {
    // 노선 이탈
    if (routeOutFlag === "2") {
        return {aux: BUS_RUNS_AUX.ROUTE_OUT, reason: `노선이탈 감지. routeOutFlag: ${routeOutFlag}`}
    }
    else if (endBus === "1") {
        return {aux: BUS_RUNS_AUX.END_BUS, reason: `막차. Flag: ${endBus}`}
    }
    return null;
}

// 지연
const proveRunAuxiliary = (routeStatus, busStatus, prevRouteArrival) => {
    // 노선 이탈
    let auxStatus = getRunBusAuxStatus(JsUtil.GText(busStatus, "routeOutFlag"), JsUtil.GText(busStatus, "endBus"))
    if (auxStatus) {
         busStatus.runStatusAux = auxStatus.aux;
         busStatus.runStatusAuxReason = auxStatus.reason;
    }
    // 2대 도착정보에 한해서, 추가 상태 판독. 
    else {
        let prevBusStatusList = prevRouteArrival && prevRouteArrival.busStatusList;
        if (prevBusStatusList) {
            let prevBusStatus = prevBusStatusList.find(elem => elem.vehId === busStatus.vehId);
            if (prevBusStatus) {
                if (busStatus.predictTime > prevBusStatus.predictTime ||
                    busStatus.locationNo > prevBusStatus.locationNo) {
                    busStatus.runStatusAux = BUS_RUNS_AUX.LOCAL_DELAY;
                    busStatus.runStatusAuxReason = `버스시간/위치역전. 이전:${prevBusStatus.predictTime}/${prevBusStatus.locationNo}, 현재:${busStatus.predictTime}/${busStatus.locationNo}`;
                }
                else {
                    if (busStatus.locationNo === prevBusStatus.locationNo) {
                        try {
                            if (DateUtil.GetElapsedSecs(prevBusStatus.locationNoTime, busStatus.locationNoTime) > AppSetting.BUS_ARRIVAL.ALLOWED_SAME_LOCATION_SECS) {
                                busStatus.runStatusAux = BUS_RUNS_AUX.LOCAL_DELAY;
                                busStatus.runStatusAuxReason = `버스위치변화없음. 이전:${prevBusStatus.locationNo}/${DateUtil.format(prevBusStatus.locationNoTime)}, 현재:${busStatus.locationNo}/${DateUtil.format(busStatus.locationNoTime)}`;
                                console.log(`[proveRunAuxiliary()] ${AppSetting.BUS_ARRIVAL.ALLOWED_SAME_LOCATION_SECS} 초 이상 위치 변화 없음. 지체판단.`)
                            }
                            busStatus.locationNoTime = prevBusStatus.locationNoTime;
                        }
                        catch (e) {
                            console.log(`[proveRunAuxiliary()] fail to compare curr:prev.locationNoTime`)
                        }
                    }
                }
            }
        }
    }
}

const _buildNearBusStatus = (routeArrival, busSeq) => {
    return {
        source: "routeArrival",
        // 노선
        stationId: JsUtil.GText(routeArrival, "stationId"),
        // stationName: { _text: routeArrival.stationName._text },
        routeTypeCd: JsUtil.GText(routeArrival, "routeTypeCd"),
        routeId: JsUtil.GText(routeArrival, "routeId"),
        routeName: JsUtil.GText(routeArrival, "routeName"),
        staOrder: JsUtil.GText(routeArrival, "staOrder"),
        stationSeq: JsUtil.GText(routeArrival, "staOrder"),

        // 차량
        vehId: JsUtil.GText(routeArrival, "vehId" + busSeq),
        lowPlate: JsUtil.GText(routeArrival, "lowPlate" + busSeq),
        plateNo: JsUtil.GText(routeArrival, "plateNo" + busSeq),
        plateType: JsUtil.GText(routeArrival, "plateType" + busSeq),
        endBus: JsUtil.GText(routeArrival, "endBus" + busSeq),
        // 도착예상시간, 위치(N번재 전), 위치 감지 시각
        predictTime: JsUtil.GInt(routeArrival, "predictTime" + busSeq, -1),
        locationNo: JsUtil.GInt(routeArrival, "locationNo" + busSeq, -1),
        locationNoTime: DateUtil.now(),
        // 대기여부
        waitFlag: JsUtil.GText(routeArrival, "waitFlag" + busSeq),
        // 지연여부
        delayYn: JsUtil.GText(routeArrival, "delayYn" + busSeq),
        // 빈자리, 혼잡도
        remainSeatCnt: JsUtil.GInt(routeArrival, "remainSeatCnt" + busSeq, -1),
        density: JsUtil.GInt(routeArrival, "density" + busSeq, -1),
        // 노선이탈
        routeOutFlag: JsUtil.GText(routeArrival, "routeOutFlag" + busSeq, ""),
        // 조회시각
        time: DateUtil.now(),
        taglessCd: JsUtil.GInt(routeArrival, "taglessCd" + busSeq, -1),
    }
}

// route can be routeArrival 
const _buildFarBusStatus = (routeArrival, runningBus) => {

    return {
        source: "busLocation",
        // 노선
        stationId: JsUtil.GText(routeArrival, "stationId"),
        // stationName: { _text: routeArrival.stationName._text },
        routeTypeCd: JsUtil.GText(routeArrival, "routeTypeCd"),
        routeId: JsUtil.GText(routeArrival, "routeId"),
        routeName: JsUtil.GText(routeArrival, "routeName"),
        staOrder: JsUtil.GText(routeArrival, "staOrder"),
        stationSeq: JsUtil.GText(routeArrival, "staOrder"),

        // 차량
        vehId: JsUtil.GText(runningBus, "vehId"),
        lowPlate: JsUtil.GText(runningBus, "lowPlate"),
        plateNo: JsUtil.GText(runningBus, "plateNo"),
        plateType: JsUtil.GText(runningBus, "plateType"),
        endBus: JsUtil.GText(runningBus, "endBus"),
        // 도착예상시간, 위치(N번재 전), 위치 감지 시각
        predictTime: -1,
        locationNo: JsUtil.GInt(routeArrival, "staOrder", -1) - JsUtil.GInt(runningBus, "stationSeq", -1),
        locationNoTime: DateUtil.now(),
        // 대기여부. Not Exist
        waitFlag: JsUtil.GText(runningBus, "waitFlag"),
        // 지연여부. Not Exist
        delayYn: JsUtil.GText(runningBus, "delayYn"),
        // 빈자리, 혼잡도
        remainSeatCnt: JsUtil.GInt(routeArrival, "remainSeatCnt", -1),
        density: JsUtil.GInt(routeArrival, "density", -1),
        // 노선이탈. 실제 데이터 없음
        routeOutFlag: JsUtil.GText(runningBus, "routeOutFlag", ""),
        // 조회시각
        time: DateUtil.now(),
        taglessCd: JsUtil.GInt(routeArrival, "taglessCd" + busSeq, -1),
    }
}

//--- 버스운행상태. 차량존재여부(vehId) 및 운행종료는 노선에서 판독함
export function proveFarBus(routeArrival, runningBus, prevRouteArrival) {
    let routeStatus = routeArrival.routeStatus;
    //--- 운행종료
    let busStatus = _buildFarBusStatus(routeArrival, runningBus);
    console.log(">>>> proving far bus", busStatus)
    //--- 운행상태 기본값
    busStatus.runStatus = BUS_RUNS.OK;
    busStatus.runStatusReason = "";
    //--- 운행부가상태 기본값
    busStatus.runStatusAux = BUS_RUNS_AUX.NONE;
    busStatus.runStatusAuxReason = "";
    //--- 장애상태 기본값
    busStatus.trouble = BUS_TROUBLES.NONE;
    busStatus.troubleReason = "";

    //-- 정상운행. 판독 순서 중요함.
    if (isBusLocationValid(busStatus)) {
        //--- busStatus.touble 운행불가에 준하는 상태 판단
        proveBusTrouble(routeStatus, busStatus)
        //--- busStatus.runStatus 차고지, 회차지 대기중 판단
        proveBusGarageWait(routeStatus, busStatus)
        if (busStatus.runStatus === BUS_RUNS.OK) {
            //--- busStatus.runStatusAux 운행중이지만, 문제가 있는 부가상태 판단. 지체, 노선이탈 등
            proveRunAuxiliary(routeStatus, busStatus, prevRouteArrival)
        }
    }
    // 차량 위치가 판독되지 않았고, 운행중이고, 운행시간내이면 => 차고지 또는 회차지
    else {
        // 운행시간내 또는 노선운행상태 ON
        if (routeStatus.isInBusRunTime || ["WAIT", "RUN", "PASS"].includes(routeStatus.flag)) {
            busStatus.runStatus = isStationAfterTurnSeq(routeStatus) ? BUS_RUNS.W_GARAGE_TURN : BUS_RUNS.W_GARAGE;
            busStatus.runStatusReason = `버스위치불량, 운행시간내, 노선운행상태:${routeStatus.flag}`;
        }
        // 운행시간외, 사용자 표시목록에서 제거
        else {
            busStatus.runStatus = BUS_RUNS.PROVE_FAIL;
            busStatus.runStatusReason = `버스위치불량, 운행시간외, 노선운행상태:${routeStatus.flag}`;
            console.log(`[버스위치판독()] 운행시간외, 노선운행:${routeStatus.flag}, n번째전:${busStatus.locationNo}, n분전:${busStatus.predictTime}`)
        }
    }
    return busStatus;
}

//--- 버스운행상태. 차량존재여부(vehId) 및 운행종료는 노선에서 판독함
export function proveNearBus(routeArrival, busSeq, prevRouteArrival) {
    let routeStatus = routeArrival.routeStatus;
    //--- 운행종료
    let busStatus = _buildNearBusStatus(routeArrival, busSeq);
    //--- 운행상태 기본값
    busStatus.runStatus = BUS_RUNS.OK;
    busStatus.runStatusReason = "";
    //--- 운행부가상태 기본값
    busStatus.runStatusAux = BUS_RUNS_AUX.NONE;
    busStatus.runStatusAuxReason = "";
    //--- 장애상태 기본값
    busStatus.trouble = BUS_TROUBLES.NONE;
    busStatus.troubleReason = "";

    //-- 정상운행. 판독 순서 중요함.
    if (isBusLocationValid(busStatus)) {
        //--- busStatus.touble 노선이탈등 판단
        proveBusTrouble(routeStatus, busStatus)
        //--- busStatus.runStatus 차고지, 회차지 대기중 판단
        proveBusGarageWait(routeStatus, busStatus)
        //--- busStatus.runStatusAux local 에서 지체 판단
        if (busStatus.runStatus === BUS_RUNS.OK) {
            proveRunAuxiliary(routeStatus, busStatus, prevRouteArrival)
        }
    }
    // 차량 위치가 판독되지 않았고, 운행중이고, 운행시간내이면 => 차고지 또는 회차지
    else {
        // 운행시간내 또는 노선운행상태 ON
        if (routeStatus.isInBusRunTime || ["WAIT", "RUN", "PASS"].includes(routeStatus.flag)) {
            busStatus.runStatus = isStationAfterTurnSeq(routeStatus) ? BUS_RUNS.W_GARAGE_TURN : BUS_RUNS.W_GARAGE;
            busStatus.runStatusReason = `버스위치불량, 운행시간내, 노선운행상태:${routeStatus.flag}`;
        }
        // 운행시간외, 사용자 표시목록에서 제거
        else {
            busStatus.runStatus = BUS_RUNS.PROVE_FAIL;
            busStatus.runStatusReason = `버스위치불량, 운행시간외, 노선운행상태:${routeStatus.flag}`;
            console.log(`[버스위치판독()] 운행시간외, 노선운행:${routeStatus.flag}, n번째전:${busStatus.locationNo}, n분전:${busStatus.predictTime}`)
        }
    }
    return busStatus;
}

// 노선이탈중 코드값이 "3" = 노선사라짐 인 버스제거. set vehId{n} = ""
const removeRouteOut = (routeArrival) => {
    if (JsUtil.GText(routeArrival, "routeOutFlag1") === "3") {
        console.log(`[버스위치판독()] 노선이탈사라짐 버스 발견(routeOutFlat1)=>제거`, routeArrival)
        if (routeArrival.vehId1)
            routeArrival.vehId1._text = "";
    }
    if (JsUtil.GText(routeArrival, "routeOutFlag2") === "3") {
        console.log(`[버스위치판독()] 노선이탈사라짐 버스 발견(routeOutFlat2)=>제거`, routeArrival)
        if (routeArrival.vehId2)
            routeArrival.vehId2._text = "";
    }
}

export function proveBusRunStatusList(routeArrival, prevRouteArrival) {
    let routeStatus = routeArrival.routeStatus;
    let b1 = null;
    let b2 = null;
    // 최우선 순위 판단.
    if (routeStatus.isStopped) {
        b1 = {
            runStatus: BUS_RUNS.STOPPED,
            runStatusReason: "노선운행종료",
            time: DateUtil.now(),
        }
    }
    // "노선이탈사라짐" 버스 제거
    removeRouteOut(routeArrival);

    // 운행중인 차량 없음
    if (!JsUtil.GText(routeArrival, "vehId1") && !JsUtil.GText(routeArrival, "vehId2")) {
        if (routeStatus.isInBusRunTime) {
            b1 = {
                runStatus: isStationAfterTurnSeq(routeStatus) ? BUS_RUNS.W_GARAGE_TURN : BUS_RUNS.W_GARAGE,
                runStatusReason: "운행시간내, 버스ID(vehId) 없음",
                time: DateUtil.now(),
            }
        }
        else {
            b1 = {
                runStatus: BUS_RUNS.STOPPED,
                runStatusReason: `운행시간외(${JsUtil.GText(routeArrival, "firstTime")}~${JsUtil.GText(routeArrival, "lastTime")}), 버스ID(vehId) 없음`,
                time: DateUtil.now(),
            }
        }
    }
    else {
        if (JsUtil.GText(routeArrival, "vehId1")) {
            b1 = proveNearBus(routeArrival, "1", prevRouteArrival)
        }
        if (JsUtil.GText(routeArrival, "vehId2")) {
            b2 = proveNearBus(routeArrival, "2", prevRouteArrival)
        }
    }
    if (b1 || b2) {
        routeArrival.busStatusList = [b1, b2].filter(elem => elem)
    }
    else {
        routeArrival.busStatusList = [{
            runStatus: BUS_RUNS.NO_RUN_BUS,
            runStatusReason: "",
            time: DateUtil.now(),
        }]
    }
    return routeArrival.busStatusList;
}



export function proveRouteRunStatus(routeArrival) {
    routeArrival.routeStatus = {
        isStopped: JsUtil.GText(routeArrival, "flag") === "STOP",
        isInBusRunTime: isInBusRunTime(JsUtil.GText(routeArrival, "firstTime"), JsUtil.GText(routeArrival, "lastTime")),
        flag: JsUtil.GText(routeArrival, "flag"),
        staOrder: JsUtil.GInt(routeArrival, "staOrder"),
        turnSeq: JsUtil.GInt(routeArrival, "turnSeq"),
        garageFlag: JsUtil.GText(routeArrival, "garageFlag"),
        garageTurnFlag: JsUtil.GText(routeArrival, "garageTurnFlag"),
    }
    return routeArrival.routeStatus;
}

// 
export function proveRouteBusArrivals(routeArrivals, prevRouteArrivals) {
    // 1. 노선이 운행상태인지 검사
    routeArrivals.forEach(routeArrival => {
        proveRouteRunStatus(routeArrival);
        proveBusRunStatusList(routeArrival,
            prevRouteArrivals && prevRouteArrivals.find(elem =>
                JsUtil.GText(elem, "routeId") === JsUtil.GText(routeArrival, "routeId")))
    })
    return routeArrivals;
}



// 운행버스중에서 저상버스 추출
function _getPriorLowBusList(runnigBusList, routeArrival, wantedCount) {
    // 저상 + 이전 정류소 위치
    try {
        let arr = runnigBusList.filter(elem => {
            return elem.lowPlate._text === "1" &&
                parseInt(elem.stationSeq._text) < parseInt(routeArrival.staOrder._text) &&
                elem.vehId._text !== routeArrival.vehId1._text &&
                elem.vehId._text !== routeArrival.vehId2._text;
        });
        // 현재 정류소에 가까운 순으로 정렬
        arr.sort((a, b) => {
            let d_a = parseInt(routeArrival.staOrder._text) - parseInt(a.stationSeq._text);
            let d_b = parseInt(routeArrival.staOrder._text) - parseInt(b.stationSeq._text);
            return d_a - d_b
        });
        if (arr.length > wantedCount) {
            arr.length = wantedCount;
        }
        return arr;
    }
    catch (err) {
        console.log("버스위치정보 오류..");
        console.log(err);
        return [];
    }
}

export function getAvailableLowBusIndexes(routeArrival) {
    let lowBusIndexes = [];
    if (routeArrival.busStatusList) {
        for (let i = 0; i < routeArrival.busStatusList.length; i++) {
            if (VALID_BUS_RUNS.includes(routeArrival.busStatusList[i].runStatus) &&
                JsUtil.GText(routeArrival.busStatusList[i], "lowPlate") === "1") {
                lowBusIndexes.push(i)
            }
        }
    }
    return lowBusIndexes;
}

// return routeArrival. You must call this function after proveBusRunStatusList(routeArrival) !!!
export function getAvailableBusArrivals(routeArrival, runningBusList, prevRouteArrival) {
    //--- 근접 2대 버스 검지
    let nearLowBusIndexes = getAvailableLowBusIndexes(routeArrival);
    // 근거리 2대가 저상 || 운행버스 조회 안됨
    if (nearLowBusIndexes.length > 1 || !runningBusList || runningBusList.length < 1) {
        console.log("======(1111)")
        return routeArrival;
    }

    let arrivals = []
    // 원거리 운행중인 버스에서 앞선 정류소에 운행중인 저상버스 추출
    farLowBusList = _getPriorLowBusList(
        runningBusList,
        routeArrival,
        2 - nearLowBusIndexes.length);

    // 원거리 버스에 저상버스 없음
    if (farLowBusList.length < 1) {
        console.log("======(222222)")
        return routeArrival;
    }
    // 원거리 버스에 저상버스 2대 존재
    else if (farLowBusList.length > 1) {
        console.log("======(333333333)")
        routeArrival.busStatusList = [];
        farLowBusList.forEach(elem => {
            routeArrival.busStatusList.push(proveFarBus(routeArrival, elem, prevRouteArrival));
        })
        return routeArrival;
    }
    // 원거리 버스에 저상버스 1대 존재. 근거리 버스에는 존재하거나 안할 수 있음.
    else {
        console.log("======(44444444444)")
        let busStatusList = []
        // 근거리버스 중 저상이 있으면, 먼저 채움
        if (nearLowBusIndexes.length > 0) {
            busStatusList.push(routeArrival.busStatusList[nearLowBusIndexes[0]]);
        }
        // 근거리버스 중 일반버스를 먼저 채움. 먼저 도착예정이므로
        else {
            busStatusList.push(routeArrival.busStatusList[0]);
        }
        // 원거리 버스 채움
        busStatusList.push(proveFarBus(routeArrival, farLowBusList[0], prevRouteArrival))
        routeArrival.busStatusList = busStatusList;
        return routeArrival;
    }
}




export function getBusArrivalText(busStatus, wantTime = true) {
    switch (busStatus.runStatus) {
        case BUS_RUNS.OK:
            if (wantTime) {
                if (busStatus.predictTime <= 3) return GetText("arrival", "soon");
                return GetText("arrival", "time").replace("{n}", busStatus.predictTime)
            }
            else {
                return GetText("arrival", "nprev").replace("{n}", busStatus.locationNo)
            }
        // case BUS_RUNS.SOON: return "잠시후 도착";
        // case BUS_RUNS.TIME: return busStatus.predictTime + "분";
        // case BUS_RUNS.W_GARAGE: return "차고지 대기중";
        // case BUS_RUNS.W_GARAGE_TURN: return "차고지 대기중";\
        default:
            // console.log("------------------", busStatus)
            return GetText("arrival", busStatus.runStatus);
    }
}

export function canDrawBusRow(routeArrival, index) {
    return routeArrival && routeArrival.busStatusList && routeArrival.busStatusList.length > index &&
        DRAW_BUS_RUNS.includes(routeArrival.busStatusList[index].runStatus)
}

export function extractArriveSoonRouteBusArrivals(routeArrivals) {
    let arr = [];
    routeArrivals.forEach(routeArrival => {
        if (JsUtil.GText(routeArrival, "vehId1") && 
            JsUtil.GInt(routeArrival, "predictTime1", -1) >= 0 &&
            JsUtil.GInt(routeArrival, "predictTime1", -1) <= 3) {
            arr.push(routeArrival)
        }
    })
    return arr;
}