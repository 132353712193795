export const isBoolean = (value) => {
    return typeof value === "boolean"
}


export const isString = (value) => {
    return (typeof value === "string" || value instanceof String);
}

export const isNumeric = (value) => {
    if (Number.isInteger(value)) return true;
    if (value === +value && value !== (value|0)) return true;
    if (typeof value != "string") return false  // we only process strings!  
    return !isNaN(value) &&                     // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(value))            // ...and ensure strings of whitespace fail
}

export const isInteger = (value) => {
    return !isNaN(value) && 
           parseInt(Number(value)) == value && 
           !isNaN(parseInt(value, 10));
}

//-- property validation .. fixme propExist
export const isProp = (obj, prop) => {
    return obj && obj.hasOwnProperty(prop);
}

export const isIntegerProp = (obj, prop) => {
    return isProp(obj, prop) && isInteger(obj[prop]);
}

export const isNumericProp = (obj, prop) => {
    return isProp(obj, prop) && isNumeric(obj[prop]);
}

export const isStringProp = (obj, prop) => {
    return isProp(obj, prop) && isString(obj[prop]);
}

export const isObjectProp = (obj, prop) => {
    return isProp(obj, prop) && (typeof obj[prop] === "object");
}

export const isEmptyObject = (obj) => {
    return Object.entries(obj[prop]) === 0;
}

//-- get property value
export const getProp = (obj, prop, def) => {
    return isProp(obj, prop) ? obj[prop] : def 
}

export const getNumericProp = (obj, prop, def) => {
    return isNumericProp(obj, prop) ? obj[prop] : def 
}

export const getIntegerProp = (obj, prop, def) => {
    return isIntegerProp(obj, prop) ? parseInt(obj[prop]) : def 
}

export const getFloatProp = (obj, prop, def) => {
    return isNumericProp(obj, prop) ? parseFloat(obj[prop]) : def 
}

export const getStringProp = (obj, prop, def) => {
    return isStringProp(obj, prop) ? obj[prop] : def 
}
export const getObjectProp = (obj, prop, def) => {
    return isObjectProp(obj, prop) ? obj[prop] : def 
}

//-- for 경기버스데이터. 000.routeType => 
export const GText = (obj, prop, def) => {
    if (obj && !prop && !def) {
        return GText(obj, "_text")
    }
    if (isStringProp(obj, prop)) {
        return obj[prop]
    }
    if (isIntegerProp(obj, prop)) {
        return parseInt(obj[prop])
    }
    if (isNumericProp(obj, prop)) {
        return obj[prop]
    }
    if (isObjectProp(obj, prop)) {
        return GText(obj[prop], "_text", def)
    }
    return def;
}

export const GInt = (obj, prop, def) => {
    if (isIntegerProp(obj, prop)) {
        return parseInt(obj[prop])
    }
    if (isStringProp(obj, prop) && isInteger(getStringProp(obj, prop))) {
        return parseInt(obj[prop])
    }
    if (isObjectProp(obj, prop)) {
        return GInt(obj[prop], "_text", def)
    }
    return def;
}

//--- 한글
/** 첫번째 한글 */ 
export const getFirstHanCharIndex = (value) => { 
    for (var i=0; i<value.length; i++) { 
        if (value.charCodeAt(i) > 128)
            return i;
    } 
    return -1; 
}

export const getTextLength = function(value) {
    var len = 0;
    for (var i = 0; i < value.length; i++) {
        if (escape(value.charAt(i)).length == 6) {
            len++;
        }
        len++;
    }
    return len;
}

export const getMaxTextLengthOf = (arr) => {
    return Math.max(arr.map(elem => getTextLength(elem)));
}


export const getArrayElemType = (arr, def) => {
    if (arr && arr.length > 0) {
        if (isString(arr[0])) return "string";
        if (isInteger(arr[0])) return "integer";
        if (isNumeric(arr[0])) return "numeric";
        if (Array.isArray(arr[0])) return "array";
        if (isObject(arr[0])) return "object";
    }
    return "unknown"
}
