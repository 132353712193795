import React, { useContext, useEffect, useState } from 'react';
import { Linking, Image, Button, ImageBackground, View, Text, TouchableOpacity } from 'react-native';
//import { Button } from 'react-native-elements';
import AlarmContext from '../../../context/AlarmContext';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import LoadImage, { Images } from '../../../defines/Images';
import { Colors, Layouts } from '../../../defines/Theme';
import FIcon from '../../../defines/FIcon';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import * as DeviceUtil from '../../../utils/DeviceUtil';
import * as LinkUtil from '../../../utils/LinkUtil';

const EmptyAction = ({ label, onPress, iconType, iconName }) => {
    return (
        <TouchableOpacity style={{
            height: 70,
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: iconType && iconName ? "flex-start" : "center",
            marginVertical: 10,
            paddingHorizontal: 10,
            borderWidth: 0.5,
            borderColor: Colors.borderColor,
            borderRadius: 7,
            alignItems: "center",
            backgroundColor: Colors.ggBlue, //  "rgba(255,255,255,0.5)",  
        }}
            onPress={() => { onPress?.() }}
        >
            {
                iconType && iconName ?
                    <FIcon type={iconType} name={iconName} size={35} color={Colors.gsWhite} style={{ marginHorizontal: 10 }} /> : null
            }
            <Text style={{
                fontSize: 20,
                color: Colors.gsWhite
            }}> {label}</Text>
        </TouchableOpacity>
    )
}

const MobileAppAction = ({ label, onPress, iconType, iconName, os }) => {
    return (
        <TouchableOpacity style={{
            height: 70,
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: iconType && iconName ? "flex-start" : "center",
            marginVertical: 10,
            marginTop: 30,
            paddingHorizontal: 10,
            borderWidth: 0.5,
            // borderColor: Colors.borderColor,
            borderRadius: 7,
            alignItems: "center",
            // backgroundColor: Colors.ggBlue, //  "rgba(255,255,255,0.5)",  
            backgroundColor: Colors.appBackgroundColor, //  "rgba(255,255,255,0.5)",  
            borderColor: Colors.ggBlue,
            
        }}
            onPress={() => { onPress?.() }}
        >
            {
                os == "android"
                ? <LoadImage name="android" width={35} height={35} style={{ marginHorizontal: 10 }} /> 
                : (os == "iOS" ? <LoadImage name="appstore" width={35} height={35} style={{ marginHorizontal: 10 }} /> : null )
            }
            <Text style={{
                fontSize: 16,
                color: Colors.ggBlue,
                textAlign: iconName ? "left" : "center"
            }}> {label}</Text>
        </TouchableOpacity>
    )
}


export default function FavorEmpty(props) {
    const context = useContext(AlarmContext)
    const [os, setOs] = useState("web");
    useEffect(() => {
        setOs(DeviceUtil.getWebOSSync())
    }, [props])

    return (
        <View style={CommonStyle('bg')}>
            {/* Background image with absolute 100% */}
            {/* <Image source={Images.ggmap} style={CommonStyle('favorEmptyBgAbsoluteImage')} /> */}
            {/* Empty contents */}
            {/* <View style={CommonStyle('boxFill', {padding: 10})} >
                <WhatsNew />
            </View> */}

            <View style={CommonStyle('bgForCenter', {})} >
                {/* messages on upper  */}
                <View style={{}}>
                    <Text style={TextStyle('favorEmptyGuideText', "large")}>
                        {GetText("favor", "guide_when_no_item")}
                    </Text>
                </View>
                {/* actions on downside  */}
                <View style={CommonStyle('favorEmptyActionsBg', {})}>
                    <EmptyAction label={GetText("pageTabLabel", "FAV_EMPTY_AROUND")} iconType="fad" iconName="location" onPress={() => {
                        props.navigation.navigate("around");
                    }} />
                    <EmptyAction label={GetText("pageTabLabel", "FAV_EMPTY_SEARCH")} iconType="fad" iconName="search" onPress={() => {
                        props.navigation.navigate("searchMain");
                    }} />
                    <MobileAppAction label={GetText("MobileWebOnly","SEE_MOBILE_APP")}
                        os={os}
                        // iconType="fab"
                        // iconName="android"
                        onPress={() => {
                            LinkUtil.openMobileApp(os, () => {
                                context.msgHandler?.dlg(GetText("pageWord","ALERT"), GetText("MobileWebOnly","SUPPORT_ANDROID_AND_IPHONE"))
                            });
                        }} />
                </View>
            </View>
        </View>
    );
}
