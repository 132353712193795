import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import * as JsUtil from '../../../utils/JsUtil';

import * as GBus from '../../../defines/GBus';
import { GetText, GetTextSido } from '../../../defines/Texts';
import { Colors, Layouts } from '../../../defines/Theme';
import FIcon from '../../../defines/FIcon';
import SpacerH from '../common/SpacerH';

const RouteTypeAndNameBox = ({ bus, showShortLabel, isHeader, onPress, more }) => {
    if (isHeader) {
        return (
            <TouchableOpacity style={ SectionStyle('routeTypeBox', { 
                flexDirection: "row",
                marginHorizontal: 5, 
                marginVertical: 5, 
                marginTop: 0, 
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderWidth: 0,
                borderColor: Colors.ggBlue,
                backgroundColor: Colors.ggBlue,
                })} 
                onPress={() => { onPress?.() }}>
                { more ?
                    <FIcon type="fal" name="ellipsis-v" size={13} color={Colors.whiteLight} style={{margin: 0, padding: 0}}/> : null
                }
                {/* <FIcon type="fal" name="ellipsis-v" size={12} color={Colors.whiteLight} style={{margin: 0, padding: 0}}/> */}
                <Text style={ TextStyle('rowDetail', 'large', {color: Colors.whiteLight, marginLeft: 2}) }>
                    {GetText("station", "SOON_ARRIVAL")}</Text>
            </TouchableOpacity>
        )
    }
    if (!bus) {
        return (
            <View style={ SectionStyle('routeTypeBox', { 
                flexDirection: "row",
                alignItems: "center",
                marginHorizontal: 5, 
                marginVertical: 5, 
                marginTop: 0, 
                paddingVertical: 5,
                paddingHorizontal: 5,
                borderWidth: 0,
                borderColor: Colors.borderLightColor } ) }>
                <Text style={ TextStyle('rowDetail', 'large', {color: Colors.fontCommentColor}) }>
                    {GetText("station", "NO_SOON_ARRIVAL")}</Text>
            </View>
        )
    }
    let routeType = JsUtil.GText(bus, "routeTypeCd");
    let routeName = JsUtil.GText(bus, "routeName");
    let bcolor  = GBus.GetRouteTypeColor(routeType)
    return (
        <View style={ SectionStyle('routeTypeBox', {             
            flexDirection: "row",
            alignItems: "center",
            marginHorizontal: 5, 
            marginVertical: 5, 
            marginTop: 0, 
            paddingVertical: 5,
            paddingHorizontal: 5,
            borderWidth: 2,
            borderColor: bcolor } ) }>
            <Text style={ TextStyle('rowDetail', 'small', {color: bcolor}) }>
                { showShortLabel ? GBus.GetRouteTypeShortLabel(routeType) : GBus.GetRouteTypeLabel(routeType) }
            </Text>
            <Text style={ TextStyle('rowDetail', 'largest', {marginHorizontal: 3, color: bcolor}) }>
                { routeName }
            </Text>
        </View>
    )
}


RouteTypeAndNameBox.defaultProps = {
    showShortLabel: true,
}

export default RouteTypeAndNameBox