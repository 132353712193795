import React, { Component, useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

import LoadSvg from '../../../defines/Svgs';
import FIcon from '../../../defines/FIcon';

import * as GBus from '../../../defines/GBus';
import * as JsUtil from '../../../utils/JsUtil';
import {Colors, Layouts } from '../../../defines/Theme';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

export default function StationRow({ index, item, onPress, inFavor, onFavorite }) {
   return (
        <View style={ SectionStyle('columnBgBottomBorder', index ===0 ? { borderTopWidth: 1}: null) }>
            <View style={ SectionStyle('rowForHRow') }>
                <TouchableOpacity
                    style={ SectionStyle('rowFillStretchForRow', { 
                        backgroundColor: "transparent",
                        // backgroundColor: "#0ff",
                        // paddingTop: 0,
                        // paddingBottom: 0,
                    }) }
                    onPress={() => { onPress?.() }}
                >
                    <View style={ SectionStyle('rowLeftIconArea') }>
                        <LoadSvg name="station" width={30} height={30} />
                    </View>
                    <View style={ SectionStyle('rowFillStretch', { 
                        justifyContent: "space-around", 
                        alignItems: "stretch",
                        // backgroundColor: "#00f",
                    }) }>
                        <Text numberOfLines={1} style={ TextStyle('rowTitle', "largest", { 
                            color: Colors.fontColor, 
                            paddingTop: 5,
                            paddingBottom: 2,
                            paddingLeft: 0,
                            paddingRight: 0,
                            // backgroundColor: "#f00",
                            width: "100%" }) 
                            }>
                            {JsUtil.GText(item, "stationName")}
                        </Text>
                        <Text numberOfLines={1} style={ TextStyle('rowDetail', "small", { 
                            color: Colors.fontColor, 
                            marginHorizontal: 0, 
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            // backgroundColor: "#f0f",
                            width: "100%" 
                            }) }>
                            { GBus.GetAroundStationDetail(item) }
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    )
}
