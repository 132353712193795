import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TextInput, Image, TouchableWithoutFeedback, Keyboard, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
//context
import AlarmContext from '../../context/AlarmContext';
// lib, config
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import { GetText, GetTextSido } from '../../defines/Texts';
import { Colors, Layouts } from '../../defines/Theme';

import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as DateUtil from '../../utils/DateUtil';

import GAPI from '../../api/RestAPI';

//
import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ScreenHeader from '../components/common/ScreenHeader';
import SpacerMiniV from '../components/common/SpacerMiniV';
import ListEmpty from '../components/parts/ListEmpty';
import SpacerListBottom from '../components/common/SpacerListBottom';
import AppSetting from '../../defines/AppSetting';
let UserScriptApi = require('../../utils/UserScriptApi');
export default class Message extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            boards: []
        }
    }

    componentDidMount() {
        UserScriptApi.apiConnect('Message')
        this._focus = this.props.navigation.addListener('focus', () => {
            this.refresh()
        })
    }
    componentWillUnmount() {
        this._focus?.();
    }

    refresh(callback) {
        console.log(`[Messages] refresh().`)
        this.context.refreshBoards(() => {
            if (AppSetting.POP_NOTICE.ENTER_BOARDS_SAVE_AS_SHOWN_ALL) {
                this.context.saveAllPopBoards();
            }            
        });
        return;
        // get boards
        GAPI.doRequest('getBoards')
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCodeJson(res);
                if (code == 0) {
                    // console.log("00000000000000000000000000000000")
                    console.log(res.response.msgBody.noticeList)
                    if (Array.isArray(res.response.msgBody.noticeList)) {
                        this.setState({
                            boards: res.response.msgBody.noticeList
                        })
                    }
                    else {
                        this.setState({
                            boards: [res.response.msgBody.noticeList]
                        })
                    }
                    callback?.()
                }
                else {
                    console.log(`[Messages] fail to get boards. ${code}, ${msg}`)
                    this.setState({
                        boards: []
                    })
                    this.context.msgHandler?.toast(msg);
                }
            })
            .catch(err => {
                this.setState({
                    boards: []
                })
                console.log("Unknown request error...");
                console.log(err);
                this.context.msgHandler?.toast(GetText("error", "unknown"));
            })
    }

    renderItem = ({ index, item }) => {

        let icon = "info-circle"
        if (item.popTp == 1) icon = "file-alt";
        else if (item.popTp == 2) icon = "car-crash";

        return (
            <TouchableOpacity style={SectionStyle("rowBgBottomBorder", { paddingVertical: 10, paddingHorizontal: 10 })}
                onPress={() => {
                    this.props.navigation.navigate('messageDetail', { data: item })
                }}
            >
                <View style={CommonStyle("box", { paddingTop: 5 })}>
                    <FIcon type="fad" name={icon} size={35} color={Colors.ggBlue} />
                </View>
                <View style={CommonStyle("box", { flex: 1, marginLeft: 15 })}>
                    <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "large", { color: Colors.fontColor, marginVertical: 5 })}>
                        {item.popTitle}
                    </Text>
                    <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "smallest", { color: Colors.fontColor, marginVertical: 5 })}>
                        {DateUtil.format(item.startTime, DateUtil._DT_FORMAT_TO_HOUR)} ~ {DateUtil.format(item.endTime, DateUtil._DT_FORMAT_TO_HOUR)}
                    </Text>
                    <Text numberOfLines={5} style={TextStyle("rowDetail", "smallest", {
                        color: Colors.fontColor, marginVertical: 5, lineHeight: 23
                    })}>
                        {item.popContents.replace(/<br>/gi, '\n')}
                    </Text>
                </View> 
            </TouchableOpacity>
        )
    }

    render() { 
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    title={GetText("pageTitle","MESSAGE")}
                    showSlogan={false}
                />
                <View style={CommonStyle('body')}>
                    <View style={SectionStyle('rowForslogan',
                        {
                            flexDirection: "row", justifyContent: "center", paddingHorizontal: 5,
                            borderColor: Colors.borderColor,
                            borderBottomWidth: 1,
                        })}>
                        <LoadImage name="slogan" width={225} height={27} />
                    </View>
                    <SpacerMiniV />
                    {/* <TabSelectBar
                        tabs={{ board: "공지사항", event: "행사알림" }}
                        activeId={selectedTab}
                        onPress={(id) => { onSelectTab?.(id)
                    }}></TabSelectBar> */}

                    <View style={CommonStyle("listBg")}>
                        <FlatList
                            // data={this.state.boards}
                            data={this.context.boards}
                            ListEmptyComponent={<ListEmpty text={GetText("board", "empty")}/>}
                            renderItem={this.renderItem}
                            ListFooterComponent={<SpacerListBottom />}
                            refreshing={false}
                            keyExtractor={(item, index) => index.toString()}
                        />
                    </View>
                </View>
                <BottomRightTabs navigation={this.props.navigation} />
            </SafeAreaView>
        )
    }
}
