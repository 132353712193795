import React, { useEffect, useRef } from 'react';
import { Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import AlarmContext from '../context/AlarmContext';
import { GetText } from '../defines/Texts';
import { Colors } from '../defines/Theme'

const LoadingScreen = () => {
    return (
        <View style={{ flex: 1, backgroundColor: Colors.appBackgroundColor }}>
            <Text style={{ margin: 20, textAlign: "center" }}> {GetText("MOBILEWEBONLY", "LOADEING")} </Text>
        </View>
    )
}


export default LoadingScreen;