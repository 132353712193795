import React from 'react';
import Bug from '../utils/Bug';
import { Text, Image } from 'react-native';

import slogan from '../assets/home/slogan.png';
import slogan_large from '../assets/home/slogan_large.png';
import slogan_horz_small from '../assets/home/slogan_horz_small.png';
import slogan_vert_center_small from '../assets/home/slogan_vert_center_small.png';

import icon_help from '../assets/draw/draw_help.png';
import icon_phone from '../assets/draw/draw_phone.png';
import icon_setting from '../assets/draw/draw_setting.png';
import icon_toilet from'../assets/draw/draw_toilet.png';

import icon_android from '../assets/home/android.png';
import icon_appstore from '../assets/home/appstore.png';
// 인증마크
import wa_mark from '../assets/home/mark_wa.png';
import ma_mark from '../assets/home/mark_ma.png';
import ggmap from '../assets/home/ggmap.png';
// 
import intro1 from '../assets/intro/intro1.png';
import intro2 from '../assets/intro/intro2.png';
import intro3 from '../assets/intro/intro3.png';

// 정류소 지도화면용 아이콘
import map_station_blue from '../assets/global/marker.png';
import map_station_red from '../assets/global/marker_red.png';
// 
import kakaoVoiceApiLogo from '../assets/global/voice_logo.png';
//
import areaPathFind from '../assets/findRoad/area_search.png';
//
import sel_poi from '../assets/findRoad/sel_poi.png';

export const Images = {
    intro1: intro1,
    intro1: intro2,
    intro1: intro3
}

const LoadImage = ({ name, width, height, style = { resizeMode: 'contain' } }) => {
    switch (name) {
        case 'help':
            return <Image source={icon_help} style={{ ...style, width: width, height: height }} />;
        case 'phone':
            return <Image source={icon_phone} style={{ ...style, width: width, height: height }} />;
        case 'setting':
            return <Image source={icon_setting} style={{ ...style, width: width, height: height }} />;
        case 'slogan':
            return <Image source={slogan} style={{ ...style, width: width, height: height }} />;
        case 'slogan_large':
            return <Image source={slogan_large} style={{ ...style, width: width, height: height }} />;
        case 'slogan_horz_small':
            return <Image source={slogan_horz_small} style={{ ...style, width: width, height: height }} />;
        case 'slogan_vert_center_small':
            return <Image source={slogan_vert_center_small} style={{ ...style, width: width, height: height }} />;
        case 'ggmap':
            return <Image source={ggmap} style={{ ...style, width: width, height: height }} />;
        case 'intro1':
            return <Image source={intro1} style={{ ...style, width: width, height: height }} />;
        case 'intro2':
            return <Image source={intro2} style={{ ...style, width: width, height: height }} />;
        case 'intro3':
            return <Image source={intro3} style={{ ...style, width: width, height: height }} />;
        case 'kakaoVoiceApiLogo':
            return <Image source={kakaoVoiceApiLogo} style={{ ...style, width: width, height: height }} />;
        case 'wa_mark':
            return <Image source={wa_mark} style={{ ...style, width: width, height: height }} />;
        case 'ma_mark':
            return <Image source={ma_mark} style={{ ...style, width: width, height: height }} />;
        case 'map_station_blue':
            return <Image source={map_station_blue} style={{ ...style, width: width, height: height }} />;
        case 'map_station_red':
            return <Image source={map_station_red} style={{ ...style, width: width, height: height }} />;
        case 'area_pathfind':
            return <Image source={areaPathFind} style={{ ...style, width: width, height: height }} />;
        case 'sel_poi':
            return <Image source={sel_poi} style={{ ...style, width: width, height: height }} />;
        case 'android':
            return <Image source={icon_android} style={{ ...style, width: width, height: height }} />;
        case 'appstore':
            return <Image source={icon_appstore} style={{ ...style, width: width, height: height }} />;
        case 'icon_toilet': 
            return <Image source={icon_toilet} style={{ ...style, width: width, height: height }} />;                 
               
    }
    Bug('RenderImage()', `Image not defined. ${name}`, 9);
    return <Text>Not Defined</Text>
}

export default LoadImage;
