import React from 'react';
import { BottomRightTabs } from './BottomTab';

export default function StationInfoBottomTab({ navigation, onPressMapBtn }) {
    return (
        <BottomRightTabs 
            // onBack={onPressBack}
            // showHome={true}
            tabs={[
                { 
                    name: 'showMarkerMap', 
                    onPress: () => {
                        if(onPressMapBtn) {
                            onPressMapBtn()
                        }
                    },
                    label:"지도",
                }
            ]}
            navigation={navigation}
        />          
    )
}
