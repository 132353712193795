// 정류소에서 매 노선에 해당하여 2대 버스의 현재 상황을 알려주기 위한 Component
import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import FIcon from '../../../defines/FIcon';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import { GetText } from '../../../defines/Texts';
import { Colors, Layouts } from '../../../defines/Theme';

export default function PastArrivalsBtn({ onClick }) {
    return (
        <View
            style={SectionStyle('rowForHCol', {
                height: Layouts.rowHeight * 0.8,
                marginTop: 20,
                marginBottom: 5,
                paddingHorizontal: 15,
            })}>
            <TouchableOpacity
                style={SectionStyle('busArrivalBelowBtn')}
                onPress={() => {
                    onClick?.();
                }}>
                <FIcon type="fas" name="list-alt" size={22} color={Colors.ggBlueTrans7} />
                <Text numberOfLines={1} style={TextStyle('rowDetail', 'large', { marginLeft: 8 })}>
                    {GetText('PAGETABLABEL', 'SHOW_PAST_ARRIVALS')}
                </Text>
            </TouchableOpacity>
        </View>
    );
}
