// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Bug from '../utils/Bug';

const styles = {
    _def: {
        backgroundColor: '#f00',
    },

    frdBusRowBg: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: Colors.borderColor,
        borderBottomWidth: 0.7,
        paddingHorizontal: 10,
        paddingTop: 7,
        paddingBottom: 5,
        height: Layouts.frdBusRowHeight
    },
    frdSubwayRowBg: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: Colors.borderColor,
        borderBottomWidth: 0.7,
        paddingHorizontal: 10,
        height: Layouts.frdBusRowHeight
    },

    frdWalkRowBg: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: Colors.borderColor,
        borderBottomWidth: 0.7,
        paddingHorizontal: 10,
        height: Layouts.frdWalkRowHeight
    },
    frdEndRowBg: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: Colors.borderColor,
        borderBottomWidth: 0.7,
        paddingHorizontal: 10,
        height: Layouts.frdWalkRowHeight
    },
    // vertical path line
    frdWalkLine: {
        flexDirection: "column",
        position: 'absolute',
        top: 0,
        left: Layouts.frdPathLineLeft,
        width: 3,
        //height: Layouts.frdWalkRowHeight / 2,
        height: Layouts.frdWalkRowHeight,
    },
    // frdWalkLineDash: {
    // bottomLine: {
    //     position: 'absolute',
    //     bottom: 0,
    //     left: 44,
    // },

    // style={{ width: 1, height: 30, flexDirection: 'column', ...styles.bottomLine }

    frdBusLine: {
        position: 'absolute',
        top: Layouts.frdBusRowHeight / 2,
        left: Layouts.frdPathLineLeft,
        width: 3,
        height: Layouts.frdBusRowHeight,
    },
    frdBusSubwayLine: {
        position: 'absolute',
        top: Layouts.frdSubwayowHeight / 2,
        left: Layouts.frdPathLineLeft,
        width: 3,
        height: Layouts.frdSubwayowHeight,
    },

    frdWalkStartIconBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
        marginHorizontal: 20,
    },
    frdWalkEndIconBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 44,
        height: 30,
        borderRadius: 9,
        marginHorizontal: 13,
        backgroundColor: Colors.white,
        borderWidth: 2,
    },

    busIconViewBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        borderRadius: 16,
        marginHorizontal: 19,
        backgroundColor: Colors.white,
        // backgroundColor: "#f00",
        // borderWidth: 2,
    },

    subwayBoxIconViewBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        height: 30,
        borderRadius: 7,
        marginHorizontal: 10,
        backgroundColor: Colors.white,
        // backgroundColor: "#f00",
        // borderWidth: 2,
    },

    abbrLeftIconBg: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: Layouts.frAbbrLeftAreaWidth,
        alignSelf: "stretch",
        // /backgroundColor: "#f00",
    },
    abbrRightBg: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginHorizontal: 10,
    },
    abbrSubwayRowBg: {
        height: Layouts.frAbbrRowHeight,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 10,
        //paddingVertical: 10,
        borderColor: Colors.borderColor,
        borderBottomWidth: 1,
        backgroundColor: Colors.background
    },
    abbrBusRowBg: {
        height: Layouts.frAbbrRowHeight,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 10,
        //paddingVertical: 10,
        borderColor: Colors.borderColor,
        borderBottomWidth: 1,
        backgroundColor: Colors.background
    },
    abbrEndRowBg: {
        height: Layouts.frAbbrRowHeight,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 10,
        //paddingVertical: 10,
        // borderColor: Colors.borderColor,
        // borderBottomWidth: 1,
        backgroundColor: Colors.background,
        // backgroundColor: "#00F",

    },
    abbrTopLine: {
        position: 'absolute',
        top: 0,
        left: Layouts.frAbbrLeftAreaWidth / 2 - 1,
        width: 3,
        height: Layouts.frAbbrRowHeight / 2,
    },
    abbrBottomLine: {
        position: 'absolute',
        bottom: 0,
        left: Layouts.frAbbrLeftAreaWidth / 2 - 1,
        width: 3,
        height: Layouts.frAbbrRowHeight / 2,
    },
    abbrBusIconBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 30,
        height: 30,
        borderRadius: 15,
    },
    abbrEndIconBg: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 30,
        borderRadius: 15,
        marginHorizontal: 20,
        borderWidth: 2,
        backgroundColor: Colors.white,
        // backgroundColor: "#F00",
    },
};


const FrStyle = (name, customStyles = null) => {
    if (typeof customStyles === "string") {
        console.log("FrStyle.GetStyle() changed. font sized style should go to TextStyle()")
    }
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('FrStyle()', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default FrStyle;