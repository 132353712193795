//import DeviceInfo from 'react-native-device-info';
//import UUIDGenerator from 'react-native-uuid-generator';
import uuid from 'react-native-uuid';
import AppSetting from '../defines/AppSetting';
import Texts, { GetText, GetTextSido } from '../defines/Texts';
import * as FavorUtil from '../utils/FavorUtil';
import * as DateUtil from '../utils/DateUtil';
import { getCorsProxy } from './CorsProxy';
import { lockToLandscape } from 'react-native-orientation';


const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_API_LOG) {
        console.log(`[RestAPI] ${m}`, o)
    }
}

const warn = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_API_WARN) {
        console.log(`[WARN][RestAPI] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][RestAPI] ${m}`, e)
}



let convert = require('xml-js');

const uri = `https://api.gbis.go.kr/`;
// const uri_xml = `http://localhost/`;
// const uri_xml = `http://localhost/`;
const uri_xml = `https://m.gbis.go.kr/`;

const ResultCodes = new Map([
    [0, "정상적으로 처리되었습니다."],
    [1, "시스템 에러가 발생하였습니다."],
    [2, "필수 요청 Parameter 가 존재하지 않습니다."],
    [3, "필수 요청 Parameter 가 잘못되었습니다."],
    [4, "결과가 존재하지 않습니다."],
    [5, "필수 요청 Parameter(인증키) 가 존재하지 않습니다."],
    [6, "등록되지 않은 키입니다."],
    [7, "사용할 수 없는(등록은 되었으나, 일시적으로 사용 중지된) 키입니다."],
    [8, "요청 제한을 초과하였습니다."],
    [20, "잘못된 위치로 요청하였습니다. 위경도 좌표값이 정확한지 확인하십시오."],
    [21, "노선번호는 1자리 이상 입력하세요."],
    [22, "정류소명/번호는 1자리 이상 입력하세요."],
    [23, "버스 도착 정보가 존재하지 않습니다."],
    [31, "존재하지 않는 출발 정류소 아이디(ID)/번호입니다."],
    [32, "존재하지 않는 도착 정류소 아이디(ID)/번호입니다."],
    [50, "comMsgHeader의 에러 메시지를 참조하세요."],
    [99, "API 서비스 준비중입니다."],
    // mobile local code
    [600, "처리할 수 없는 응답 코드입니다."],  // xml format 오류
    [700, "응답메시지 포맷이 올바르지 않습니다."],  // xml format 오류
    [701, "서버가 정상적으로 응답하지 않습니다."],  // http statusCode 오류
    [800, "정의되지 않은 API를 호출했습니다."],
    [900, "네트워크를 사용할 수 없습니다."],
    [901, "서버가 요청을 수행할 수 없습니다."],     //
    [999, "알 수 없는 오류가 발생했습니다."]
])


const GAPI = {
    doRequest(apiName, ...args) {
        AppSetting.SERVICE_KEY = '1234567890';

        if(foreignLanguage=='E'){
            AppSetting.SERVICE_KEY = '1234567890_EN';
        }else if (foreignLanguage=='C'){
            AppSetting.SERVICE_KEY = '1234567890_CN';
        }else if (foreignLanguage=='J'){
            AppSetting.SERVICE_KEY = '1234567890_JP';
        }else if (foreignLanguage=='V'){
            AppSetting.SERVICE_KEY = '1234567890_VT';
        }

        if (RestAPI.hasOwnProperty(apiName)) {
            return RestAPI[apiName](...args)
                // first return text
                .then(response => {
                    if (response.ok) {
                        if (apiName === "getBoards") {
                            return response.json()
                        }
                        else {
                            return response.text();
                        }
                    }
                    else {
                        warn(`invalid http response:`, response)
                        return { errorCode: 701 }
                    }
                })
                // second text(xml) -> json
                .then(text => {
                    try {
                        if (typeof text === "string" || text instanceof String) {
                            let res = JSON.parse(convert.xml2json(text, { compact: true, spaces: 4 }))
                            return res;
                        }
                        else {
                            return text;
                        }
                    }
                    catch (err) {
                        warn(`invalid xml format:`, [err, text])
                        return { errorCode: 700 }
                    }
                })
                .catch((err) => {
                    error(`network fail:`, err)
                    return { errorCode: 900 }
                    // return { errorCode: 900, msg: JSON.stringify(err) }
                })
                .finally(() => {
                })
        }
        else {
            return new Promise(function (resolve, reject) {
                error(`not defined api called. ${apiName}`)
                reject({ errorCode: 800 });
            })
        }
    },

    parseResponseCodeJson: (res) => {
        // Check GG api header 
        if (res && res.response && res.response.msgHeader &&
            (Number.isInteger(res.response.msgHeader.resultCode) ||
                (typeof res.response.msgHeader.resultCode === "string"))) {
            let rcode = parseInt(res.response.msgHeader.resultCode);
            // check resultCode
            if (ResultCodes.has(rcode)) {
                if (rcode !== 0) {
                    warn(`non-success result. rcode=${rcode}`, res)
                }
                return { code: rcode, msg: ResultCodes.get(rcode) }
            }
            error(`strange response format`, res)
            return { code: 600, msg: "알 수 없는 응답코드: " + res.response.msgHeader.resultCode }
        }
        // if error catchced in local logic. like network fails
        // {errorCode, msg} expected
        else if (res.errorCode && Number.isInteger(res.errorCode) && ResultCodes.has(res.errorCode)) {
            if (!res.msg) {
                return { code: res.errorCode, msg: ResultCodes.get(res.errorCode) }
            }
            else {
                return { code: res.errorCode, msg: res.msg }
            }

        }
        // Unknown error
        else {
            error(`request failed by unknown reason.`, res)
            // return { code: 900, msg: JSON.stringify(res) }
            return { code: 900, msg: ResultCodes.get(900) }
        }
    },


    parseResponseCode: (res) => {
        if (res && res.response && res.response.msgHeader &&
            (Number.isInteger(res.response.msgHeader.resultCode) ||
                (typeof res.response.msgHeader.resultCode === "string"))) {
            return GAPI.parseResponseCodeJson(res);
        }
        // Check GG api header 
        if (res && res.response && res.response.msgHeader && res.response.msgHeader.resultCode && res.response.msgHeader.resultCode._text) {
            let rcode = parseInt(res.response.msgHeader.resultCode._text);
            // check resultCode
            if (ResultCodes.has(rcode)) {
                if (rcode !== 0) {
                    warn(`non-success result. rcode=${rcode}`, res)
                }
                return { code: rcode, msg: ResultCodes.get(rcode) }
            }
            error(`strange response format`, res)
            return { code: 600, msg: "알 수 없는 응답코드: " + res.response.msgHeader.resultCode._text }
        }
        // if error catchced in local logic. like network fails
        // {errorCode, msg} expected
        else if (res.errorCode && Number.isInteger(res.errorCode) && ResultCodes.has(res.errorCode)) {
            return { code: res.errorCode, msg: ResultCodes.get(res.errorCode) }
        }
        // Unknown error
        else {
            error(`request failed by unknown reason.`, res)
            return { code: 900, msg: ResultCodes.get(900) }
        }
    },
}

export const TAPI = {
    getPoiList: (pageSize, pageNo, keyword) => {
        let serverURL =
            `https://apis.openapi.sk.com/tmap/pois?version=1&format=json&resCoordType=WGS84GEO&reqCoordType=WGS84GEO` +
            `&page=${pageNo}&count=${pageSize}&appKey=${AppSetting.TMAP.APP_KEY}&searchKeyword=${encodeURIComponent(keyword)}`;
        console.log(`calling tmap poi ap...`, serverURL)
        return (
            fetch(serverURL, { method: 'GET' })
                .then((response) => {
                    if (response.ok) {
                        if (response.status === 204) {
                            return { code: 4, msg: "결과가 존재하지 않습니다." }
                        }
                        else {
                            return response.json()
                        }
                    }
                    else {
                        warn(`non-success http response`, response)
                        return { errorCode: 701 }
                    }
                })
                .catch((err) => {
                    error(`request failed by unknown reason.`, err)
                    return { errorCode: 900, msg: ResultCodes.get(900) }
                })
                .finally(() => {
                })
        )
    },
}

/* window.location structure
    location { 
        host: "localhost:3000"
        hostname: "localhost"
        href: "http://localhost/"
        origin: "http://localhost/:3000"
        pathname: "/"
        port: "3000"
        protocol: "http:"
    }
*/

const RestAPI = {

    getDeviceId: () => {
        if (global.deviceId && global.deviceId.length > 1) {
            return global.deviceId;
        }
        else {
            //global.deviceId = DeviceInfo.getUniqueId();
            global.deviceId = uuid.v1();
            FavorUtil.SaveDeviceId(global.deviceId);
            return global.deviceId;
        }
    },

    // 정류소, 노선
    getEvents: (stationId, routeId) => {
        let serverURL = uri + `ws/rest/eventservice?serviceKey=${AppSetting.SERVICE_KEY}` +
            `&version=2` +
            `&stationId=${stationId}` +
            `&routeId=${routeId}`;
        console.log(`requesting station/route events...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },


    ///ws/rest/busnotifyservice
    getBoards: () => {
        let serverURL = uri + `ws/rest/getnoticelist?serviceKey=${AppSetting.SERVICE_KEY}`;
        console.log(`requesting boards...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },


    declareNonStopRun: (stationId, staOrder, routeId, vehId, applicantTime) => {
        let serverURL = uri + `ws/rest/busnotifyservice/passReport?` +
            //let serverURL = declareUri + `passReport/passReportLogin.do?` +
            `serviceKey=${AppSetting.SERVICE_KEY}` +
            `&stationId=${stationId}` +
            `&staOrder=${staOrder}` +
            `&routeId=${routeId}` +
            `&vehId=${vehId}` +
            `&applicantTime=${applicantTime}`;
        console.log(`requesting declare non-stop...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    getBusNotify: (stationId, staOrder, routeId, vehId, handicapCd) => {
        let deviceId = RestAPI.getDeviceId();
        let regDate = DateUtil.format(DateUtil._DT_FORMAT_COMPACT);
        let serverURL = uri + `ws/rest/busnotifyservice?` +
            `serviceKey=${AppSetting.SERVICE_KEY}&deviceId=${deviceId}` +
            `&stationId=${stationId}&staOrder=${staOrder}&routeId=${routeId}&vehId=${vehId}&handicapCd=${handicapCd}&regDate=${regDate}`;
        console.log(`requesting bus notify...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    // rest/busnotifyservice/saerch
    getBusNotifyTransferdToBus: (notifyRegId, handicapCd) => {
        let serverURL = uri + `ws/rest/busnotifyservice/search?` +
            `serviceKey=${AppSetting.SERVICE_KEY}` +
            `&notifyRegId=${notifyRegId}&handicapCd=${handicapCd}`;
        console.log(`requesting query if bus notify transfered to bus...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },



    cancelBusNotify: (notifyRegId, handicapCd) => {
        let deviceId = RestAPI.getDeviceId();
        let serverURL = uri + `ws/rest/busnotifyservice/cancel?` +
            `serviceKey=${AppSetting.SERVICE_KEY}&deviceId=${deviceId}&notifyRegId=${notifyRegId}&handicapCd=${handicapCd ? handicapCd : "0"}`;
        console.log(`requesting cancel of bus notify...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    // 노선목록 조회(paged)
    getRouteList: (pageSize, pageNo, keyword) => {
        let serverURL = uri + `ws/rest/busrouteservice/page?serviceKey=${AppSetting.SERVICE_KEY}&pageSize=${pageSize}&pageNo=${pageNo}&keyword=${keyword}`;
        console.log(`[GAPI] requesting routes by keyword. ${keyword}`);
        console.log(`requesting paged route list...`, serverURL);
        return fetch(serverURL, { method: 'GET' })
    },
    // 정류소목록 조회(paged)
    getStationList: (pageSize, pageNo, keyword) => {
        let serverURL = uri + `ws/rest/busstationservice/page?serviceKey=${AppSetting.SERVICE_KEY}&pageSize=${pageSize}&pageNo=${pageNo}&keyword=${keyword}`;
        console.log(`requesting paged station list...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    // 해당 노선에 속하는 정류소 전체목록을 가져오는 API - 실시간 노선 정류소 목록을 얻기 위해 필요하다.
    getStationsOfRoute: (routeId) => {
        let serverURL = uri + `ws/rest/busrouteservice/station?serviceKey=${AppSetting.SERVICE_KEY}&routeId=${routeId}`;
        console.log(`requesting stations of route...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    // 해당 노선에 속하는 현재 운행중인 버스 목록을 가져오는 API - 실시간 노선 정류소 운행 버스목록을 위해 필요하다.
    getRunningBusOfRoute: (routeId) => {
        let serverURL = uri + `ws/rest/buslocationservice?serviceKey=${AppSetting.SERVICE_KEY}&routeId=${routeId}`;
        console.log(`requesting bus locations of route...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },
    // 노선아이디로 해당 노선의 상세정보 얻기 위한 API
    getRouteDetail: (routeId) => {
        let serverURL = uri + `ws/rest/busrouteservice/info?serviceKey=${AppSetting.SERVICE_KEY}&routeId=${routeId}`;
        console.log(`requesting route details...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    // 정류소아이디로 정류소 상세정보 얻기
    getStationDetail: (stationId) => {
        let serverURL = uri + `ws/rest/busstationservice/info?serviceKey=${AppSetting.SERVICE_KEY}&stationId=${stationId}`;
        console.log(`requesting station details...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    // 노선아이디와 정류소아이디로 버스도착정보 얻기
    getStationRouteArrival: (routeId, stationId, staOrder) => {
        let staOrderParam = staOrder ? `&staOrder=${staOrder}` : "";
        let serverURL = uri + `ws/rest/busarrivalservice/tv?serviceKey=${AppSetting.SERVICE_KEY}&stationId=${stationId}&routeId=${routeId}${staOrderParam}`;
        console.log(`requesting station+route arrival...`, serverURL);
        return fetch(serverURL, { method: 'GET' })
    },

    // 정류소 아이디로 정류소 도착정보 목록 얻기
    getStationRouteArrivals: (stationId) => {
        let serverURL = uri + `ws/rest/busarrivalservice/tvstation?serviceKey=${AppSetting.SERVICE_KEY}&stationId=${stationId}`;
        console.log(`requesting station arrivals...`, serverURL);
        return fetch(serverURL, { method: 'GET' })
    },

    // 위치값으로 주변 정류소 목록 얻기
    getAroundStations: (x, y) => {
        let serverURL = uri + `ws/rest/busstationservice/searcharound?serviceKey=${AppSetting.SERVICE_KEY}&x=${x}&y=${y}`;
        console.log(`requesting around station list...`, serverURL);
        return fetch(serverURL, { method: 'GET' })
    },

    // data: {
    //     serviceKey: mapConfig.serviceKey,
    //     x: centerX,
    //     y: centerY
    // },    

    // 버스유형별 (시외, 공항)
    getRoutesByBusType: (type) => {
        let serverURL = uri + `ws/rest/busrouteservice/routetype?serviceKey=${AppSetting.SERVICE_KEY}&areaId=02&routeType=` + type;
        console.log(`requesting routes by type...`, serverURL);
        return fetch(serverURL, { method: 'GET' })
    },

    // /array/night.xml 
    // /array/low.xml
    // WAS 서버는 Access-Control-Allow-Origin 헤더가 설정되어 있으나,
    // WEB 서버는 설정되지 않은 것으로 보임 CORS 문제 발생함.
    getRoutesFromXml: (type) => {
        let serverURL = getCorsProxy() + uri_xml + `array/${type}.xml`;
        console.log(`requesting routes(xml file) by type...`, serverURL);
        return fetch(serverURL, { method: 'GET' })
    },

    getAppSetting: (devel) => {
        let serverURL = "";
        if (devel) {
            serverURL = uri + `ws/rest/busnotifyservice/devappsetting?serviceKey=${AppSetting.SERVICE_KEY}`;
        }
        else {
            serverURL = uri + `ws/rest/busnotifyservice/setting?serviceKey=${AppSetting.SERVICE_KEY}`;
        }
        console.log(`requesting app setting...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },

    sendBusEval: (rating, comments, stationId, routeId) => {
        let t = DateUtil.format(DateUtil._DT_FORMAT_COMPACT);
        let serverURL = uri + `ws/rest/busnotifyservice/sendbuseval?serviceKey=${AppSetting.SERVICE_KEY}` +
            `&rating=${rating}` +
            `&comments=${comments}` +
            `&stationId=${stationId}` +
            `&routeId=${routeId}` +
            `&time=${t}`;
        console.log(`requesting bus eval...`, serverURL);
        return fetch(serverURL, { method: 'GET' });
    },
}

export default GAPI;