// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// // 추천: 개별 아이콘 import
// import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons';
// import { faCode, faHighlighter } from '@fortawesome/free-solid-svg-icons';

// library.add(
//   faMoneyBill,
//   faCode,
//   faHighlighter
//   // more icons go here
// );

// 비추/편리: 패키지별 전체 아이콘 import
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';

library.add(far);
library.add(fas);
library.add(fal);
library.add(fad);