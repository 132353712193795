import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Alert } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// import { WebView } from 'react-native-webview';
import WebView from 'react-native-web-webview';

// context 
import AlarmContext from '../../context/AlarmContext';

// config, lib
import MapStyle from '../../styles/MapStyle';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import { Colors, Layouts } from '../../defines/Theme';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import LoadSvg from '../../defines/Svgs';
import Texts, { GetText, GetTextSido } from '../../defines/Texts';
import AppSetting from '../../defines/AppSetting';
import * as GBus from '../../defines/GBus';
import GetLocation from '../../gps/GetLocation';

import MainBottomTab from '../components/bottomTabs/MainBottomTab';
import ScreenHeader from '../components/common/ScreenHeader';

// import Html from '../Tmap/TmapAround.html';
let UserScriptApi = require('../../utils/UserScriptApi');

function MyLocation({ selectedStation, onPress }) {
    return (
        <>
            {
                selectedStation
                    ? <TouchableOpacity style={MapStyle("myLocation")}
                        onPress={onPress}>
                        <FIcon type="fad" name="location" size={55} color={Colors.ggBlue} />
                    </TouchableOpacity>
                    : <TouchableOpacity style={MapStyle("myLocation", { bottom: Layouts.bottomBarHeight + 60 })}
                        onPress={onPress}>
                        <FIcon type="fad" name="location" size={55} color={Colors.ggBlue} />
                    </TouchableOpacity>
            }
        </>
    );
}
function AddZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("addZoom") }
            onPress={ onPress } accessibilityLabel="줌 확대" accessibilityRole="button">
            <FIcon type="fad"name="plus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}
function RemoveZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("removeZoom") }
            onPress={ onPress } accessibilityLabel="줌 축소" accessibilityRole="button">
            <FIcon type="fad" name="minus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}
export function RenderSelectedStation({ stationVisible, selectedStation, onPress, position }) {

    let stationDesc = "";
    if (stationVisible && selectedStation) {
        stationDesc = GBus.GetStationDistance(selectedStation, " | ") +
                    GBus.GetStationMobileNo(selectedStation, " | ") +
                    GBus.GetStationCenterName(selectedStation, " | ") +
                    GetTextSido(selectedStation.regionName);
    }
    return (
        <View style={MapStyle("selStationBoxBg")}>
            {
                stationVisible && selectedStation
                    ? <TouchableOpacity style={MapStyle("selStationBox")}
                        onPress={() => {
                            if (onPress) {
                                onPress()
                            }
                        }}
                    >
                        <View style={SectionStyle("rowLeftIconArea")}>
                            <LoadImage name="map_station_red" width={35} height={35} />
                        </View>
                        <View style={SectionStyle("rowFillStretch")}>
                            <View>
                                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "largest")}>
                                    {selectedStation.stationName}
                                </Text>
                            </View>
                            <View>
                                <Text numberOfLines={1} style={TextStyle("rowDetail", "large")}>
                                    {stationDesc}
                                </Text>
                            </View>
                        </View>
                        <View style={SectionStyle("rowRightButtonsArea")}>
                            <FIcon type="fad" name="chevron-right" size={20} color={Colors.ggBlue} />
                        </View>
                    </TouchableOpacity>
                    : <View style={MapStyle("selStationBoxEmpty")}>
                        <Text style={TextStyle("commentLabel")}>
                            {
                                stationVisible ? GetText("around", "guide") : GetText("around", "plz_zoom_in")
                            }
                        </Text>
                    </View>
            }
        </View>
    )
}

export default class Around extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            position: null,
            stationVisible: true,
            selectedStation: null,
            lastAroundStations: [],
            foreignLanguage: 'K',
        }
    }

    componentDidMount() {        
        UserScriptApi.apiConnect('around')
        GetLocation(this.context.msgHandler, (p) => {
            this.setState({ position: p })
        })
    }

    componentWillUnmount(){
        showPageLoader(false)
    }

    render() {
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader 
                    title={GetText("pageTitle","AROUND")}
                    iconType="fad" iconName="list-ul"
                    onPress={() => {
                        if (Array.isArray(this.state.lastAroundStations)) {
                            this.props.navigation.navigate('aroundList', { data: this.state.lastAroundStations });
                        }
                        else {
                            this.context.msgHandler?.toast(GetText("around", "NO_AROUND_STATION"));
                        }
                    }}
                />
                <View style={CommonStyle("body")}>
                    <WebView
                        ref={(ref) => this.webRef = ref}
                        originWhitelist={['*']}
                        style={MapStyle("mapView")}
                        // source={{ html: Html.content }}
                        // bug, method 를 주어야, injectedJavascript 가 동작함
                        source={{ uri: AppSetting.MOBILE_WEB.ROOT + "assets/Tmap/TmapAround.html", method: 'get' }}
                        // source={{ uri: "https://gbus.mutex.co.kr/assets/Tmap/TmapAround.html", method: 'get' }}
                        // source={{ uri: "http://localhost/assets/Tmap/TmapAround.html", method: 'get' }}
                        // source={{ uri: "http://localhost:3000/assets/Tmap/TmapAround.html", method: 'get' }}
                        
                        onLoadStart={event => {
                            showPageLoader(true)
                        }}
                        onLoadEnd={event => {
                            showPageLoader(false);
                            console.log("===> TMap loade end");
                        }}
                        onMessage={(event) => {
                            if (!event.nativeEvent.data || typeof event.nativeEvent.data != "string" || !event.nativeEvent.data.startsWith("{")) {
                                return;
                            }
                            let data = JSON.parse(event.nativeEvent.data.toString())
                            // console.log("===> webview event", data);
                            if (data.event == "stations") {
                                console.log('[TMap] stations retrieved.', data.data)
                                this.setState({
                                    lastAroundStations: data.data
                                })
                            }
                            else if (data.event == "state") {
                                console.log(`[TMap] state changed. stationVisible: ${data.stationVisible}, ` + data.data)
                                this.setState({ stationVisible: data.stationVisible, selectedStation: data.data })
                            }
                            else if (data.event == "msg") {
                                this.context.msgHandler?.toast(data.data);
                            }
                            else if (data.event == "log") {
                                console.log("[TMap] " + data.data);
                            }
                            else if (data.event == "error") {
                                this.context.msgHandler?.toast(GetText("pageComment", "ERR_LAUNCHING_MAP"))
                                console.log("[TMap] error:");
                                console.log(data.data);
                            }
                        }}
                        javaScriptEnabled={true}
                        domStorageEnabled={true}
                        startInLoadingState={true}
                        scalesPageToFit={true}
                        injectedJavaScript={
                            // `startMyAround(${AppSetting.GPS.MAP_DEF_X}, ${AppSetting.GPS.MAP_DEF_Y})`
                            this.state.position ?
                            `startMyAround(${this.state.position.coords.longitude}, ${this.state.position.coords.latitude}, '${global.foreignLanguage}')` :                            
                            `startMyAround(${AppSetting.GPS.MAP_DEF_X}, ${AppSetting.GPS.MAP_DEF_Y}, '${global.foreignLanguage}')`
                        }
                    />
                </View>
                <MyLocation
                    selectedStation={this.state.selectedStation}
                    onPress={() => {
                        let msg = {
                            _sender: "GBUS_RN",
                            cmd: "setMapCenter",
                            params: {
                                x: this.state.position ? this.state.position.coords.longitude : AppSetting.GPS.MAP_DEF_X,
                                y: this.state.position ? this.state.position.coords.latitude : AppSetting.GPS.MAP_DEF_Y,
                            }
                        }
                        this.webRef?.postMessage(msg, "*")
                    }}
                />                              
                <RenderSelectedStation
                    position={this.state.position}
                    stationVisible={this.state.stationVisible}
                    selectedStation={this.state.selectedStation}
                    onPress={() => {
                        if (this.state.selectedStation) {
                            this.props.navigation.navigate('stationInfo', {
                                data: {
                                    stationId: { _text: this.state.selectedStation.stationId },
                                    stationName: { _text: this.state.selectedStation.stationName },
                                    x: { _text: this.state.selectedStation.x },
                                    y: { _text: this.state.selectedStation.y }
                                }
                            })
                        }
                    }}
                />
                <MainBottomTab navigation={this.props.navigation} />
            </SafeAreaView>
        )
    }
}
