/* window.location structure
    location { 
        host: "localhost:3000"
        hostname: "localhost"
        href: "http://localhost/"
        origin: "http://localhost/:3000"
        pathname: "/"
        port: "3000"
        protocol: "http:"
    }
*/
export const getServiceUrl = () => {
    if (window && window.location) {
        return window.location
    }
    return null;
}

// CORS PROXY for development. 
// using cors-anywhere. support http only
export const getCorsProxy = () => {
    let loc = getServiceUrl();
    if (loc && loc.host && loc.protocol == "http:") {
        if (loc.host == "localhost:3000") {
            return `http://${loc.hostname}:7777/`
        }
    }
    return "";
}