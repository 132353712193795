// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Images from '../defines/Images';

const styles = {
    _def: {
        backgroundColor: Colors.appBackgroundColor,
        color: Colors.red,
    },

    dialogHeaderText: {
        fontFamily: Fonts.large.fontFamily,
        fontSize: Fonts.large.fontSize,
        color: Colors.gsWhite,
    },
    dialogBodyText: {
        fontFamily: Fonts.medium.fontFamily,
        fontSize: Fonts.medium.fontSize,
        color: Colors.fontColor,
        lineHeight: 25,
        textAlign: "center",
    },
    dialogRemarkText: {
        fontFamily: Fonts.small.fontFamily,
        fontSize: Fonts.small.fontSize,
        color: Colors.fontCommentColor,
        lineHeight: 25,
        textAlign: "center",
    },
    dialogButtonText: {
        fontFamily: Fonts.large.fontFamily,
        fontSize: Fonts.large.fontSize,
        color: Colors.ggBlue,
    },
    bottomTabText: {
        fontSize: Fonts.smallest.fontSize,
        fontFamily: Fonts.smallest.fontFamily,
        color: Colors.mainTabColor,
//        backgroundColor: "#00f"
        textAlign:"center",
    },

    headerTitle: {
        fontFamily: Fonts._def.large.fontFamily,
        fontSize: Fonts._def.large.fontSize,
        color: Colors.ggBlue,
    },
    headerButton: {
        fontFamily: Fonts._def.small.fontFamily,
        fontSize: Fonts._def.small.fontSize,
        color: Colors.ggBlue,
    },

    inputCustom: {
        fontSize: Fonts._def.small.fontSize,
        fontFamily: Fonts._def.small.fontFamily,
        color: Colors.dark,
        // borderColor: Colors.borderColor,
        // borderWidth: 1,
        borderRadius: Layouts.rowInputHeight / 2,
        paddingHorizontal: Layouts.rowInputHeight / 2,
        // paddingVertical: 10,
        // marginLeft: 10,
        //         height: Layouts.rowInputHeight,
        //backgroundColor: Colors.inputBackgroundColor,
    },
    input: {
        fontSize: Fonts._def.small.fontSize,
        fontFamily: Fonts._def.small.fontFamily,
        color: Colors.dark,
        // borderColor: Colors.borderColor,
        // borderWidth: 1,
        borderRadius: Layouts.rowInputHeight / 2,
        paddingHorizontal: Layouts.rowInputHeight / 2,
        paddingVertical: 10,
        marginLeft: 10,
        flex: 1,
        height: Layouts.rowInputHeight,
        backgroundColor: Colors.inputBackgroundColor,
    },

    frInput: {
        flex: 1,
        fontSize: Fonts._def.small.fontSize,
        fontFamily: Fonts._def.small.fontFamily,
        color: Colors.dark,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: Layouts.rowInputHeight / 2,
        paddingHorizontal: Layouts.rowInputHeight / 2,
        paddingVertical: 1,
        // marginLeft: 5,
        height: Layouts.frInputHeight,
        backgroundColor: Colors.inputBackgroundColor,
    },
    // searchBarInput: {
    //     fontSize: Fonts._def.input.fontSize,
    //     fontFamily: Fonts._def.input.fontFamily,
    //     paddingVertical: 5,
    //     marginLeft: 5,
    //     textDecorationLine: 'none',
    // },

    // 우측에 버튼 사용 안하고 전체 행을 사용하는 경우
    // inputFullRow: {
    //     fontSize: Fonts._def.input.fontSize,
    //     fontFamily: Fonts._def.input.fontFamily,
    //     color: Colors.dark,
    //     borderColor: Colors.borderColor,
    //     borderWidth: 1,
    //     borderRadius: 5,
    //     paddingHorizontal: 10,
    //     paddingVertical: 10,
    //     marginHorizontal: 10,
    //     flex: 1,
    //     height: Layouts.rowButtonHeight,
    // },



    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////
    //////////////////////////////////////////////////

    commentLabel: {
        fontSize: Fonts._def.smallest.fontSize,
        fontFamily: Fonts._def.smallest.fontFamily,
        color: Colors.fontCommentColor,
    },

    // arrival
    arrivalTimeTextBg: {
        backgroundColor: Colors.ggBlue,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 3,
        marginRight: 10
    },
    ////-----
    arrivalBusPosText: {
        color: Colors.ggBlue,
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
    },
    arrivalTimeText: {
        color: Colors.appBackgroundColor,
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
        textAlign: "center",
    },
    arrivalTimeText_garage: {
        color: Colors.whiteLight,
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
        textAlign: "center",
    },
    arrivalTimeText_noBus: {
        color: Colors.whiteLight,
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
        textAlign: "center",
    },

    // 지체등 부가상태
    busAuxStatusText: {
        color: Colors.red,
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
    },

    // general
    huge: {
        color: Colors.fontColor,
        fontSize: Fonts.huge.fontSize,
        fontFamily: Fonts.huge.fontFamily,
    },
    largest: {
        color: Colors.fontColor,
        fontSize: Fonts.largest.fontSize,
        fontFamily: Fonts.largest.fontFamily,
    },
    large: {
        color: Colors.fontColor,
        fontSize: Fonts.large.fontSize,
        fontFamily: Fonts.large.fontFamily,
    },
    medium: {
        color: Colors.fontColor,
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
    },
    small: {
        color: Colors.fontColor,
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
    },
    smallest: {
        color: Colors.fontColor,
        fontSize: Fonts.smallest.fontSize,
        fontFamily: Fonts.smallest.fontFamily,
    },
    tiny: {
        textAlign: "center",
        color: Colors.fontCommentColor,
        fontSize: Fonts.tiny.fontSize,
        fontFamily: Fonts.tiny.fontFamily,
    },

    favorEmptyGuideText: {
        textAlign: "center",
        fontSize: Fonts.large.fontSize,
        fontFamily: Fonts.large.fontFamily,
        lineHeight: 30,
        color: Colors.ggBlue,
    },

    drawMenuTitle: {
        fontFamily: Fonts._def.small.fontFamily,
        fontSize: Fonts._def.small.fontSize,
        color: Colors.ggBlue,
        paddingVertical: 15,
    },

    bsMsg: {
        color: Colors.fontLightColor,
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        textAlign: "center",
        lineHeight: 30,
        // backgroundColor: "#f0f"
    },
    bsMsgRemark: {
        // position: "absolute",
        // bottom: 0,
        // left: 0,
        // width: "100%",
        // height: 20,
        color: Colors.fontMiscColor,
        fontSize: Fonts.smallest.fontSize - 2,
        fontFamily: Fonts.smallest.fontFamily,
        textAlign: "center",
        // backgroundColor: "#f00"
    },

    tabSelectLabel: {
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        color: Colors.fontCommentColor,
    },

    stationHeaderText: {
        color: Colors.fontColor,
        fontSize: Fonts.largest.fontSize,
        fontFamily: Fonts.largest.fontFamily,
        textAlign: "center",
        maxWidth: 250,
    },
    stationHeaderDetailText: {
        color: Colors.fontCommentColor,
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        textAlign: "right"
    },


    // generic row contents
    rowTitle: {
        color: Colors.fontColor,
        fontSize: Fonts.largest.fontSize,
        fontFamily: Fonts.largest.fontFamily,
        alignItems:'center',
        // backgroundColor: Colors.gray,
    },
    rowTitleDesc: {
        color: Colors.fontColor,
        fontSize: Fonts.large.fontSize,
        fontFamily: Fonts.large.fontFamily,
        // backgroundColor: Colors.green,
    },
    rowDetail: {
        color: Colors.fontCommentColor,
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
    },

    // setting values
    settingValueLabel: {
        fontSize: Fonts.medium.fontSize,
        fontFamily: Fonts.medium.fontFamily,
        color: Colors.fontColor,
    },

    listGroupText: {
        fontSize: Fonts._def.small.fontSize,
        fontFamily: Fonts._def.small.fontFamily,
        color: Colors.fontMiscColor,
        //backgroundColor: "#f00"
    },
    listHeaderText: {
        fontSize: Fonts._def.small.fontSize,
        fontFamily: Fonts._def.small.fontFamily,
        color: Colors.fontCommentColor,
        //backgroundColor: "#f00"
    },
    sectionHeaderText: {
        fontSize: Fonts._def.small.fontSize,
        fontFamily: Fonts._def.small.fontFamily,
        color: Colors.fontCommentColor,
        //backgroundColor: "#f00"
    },

    boxHeaderText: {
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        color: Colors.fontCommentColor,
        marginHorizontal: 7,
    },
    boxContentText: {
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        color: Colors.fontColor,
        marginVertical: 5,
    },
    boxContentDetailText: {
        fontSize: Fonts.smallest.fontSize,
        fontFamily: Fonts.smallest.fontFamily,
        color: Colors.fontCommentColor,
        marginVertical: 3,
    },

    listEmptyText: {
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        color: Colors.fontMiscColor,
    },

    routeHeaderRouteNoText: {
        color: Colors.fontColor,
        fontSize: Fonts.huge.fontSize,
        fontFamily: Fonts.huge.fontFamily,
        textAlign: "center",
        maxWidth: 250,
        // textShadowColor: 'rgba(0, 0, 0, 0.75)',
        // textShadowOffset: {width: -1, height: 1},
        // textShadowRadius: 1        
        // backgroundColor: "#f0f"
    },

    routeHeaderDetailText: {
        color: Colors.fontCommentColor,
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        textAlign: "right"
    },

    introEmptyGuideText: {
        textAlign: "center",
        fontSize: Fonts.large.fontSize,
        fontFamily: Fonts.large.fontFamily,
        lineHeight: 30,
        color: Colors.ggBlue,
    },
};

const TextStyle = (name, sizeTypeOrStyle = null, customStyles = null) => {
    if (styles.hasOwnProperty(name)) {
        if (typeof sizeTypeOrStyle === "string" || sizeTypeOrStyle instanceof String) {
            if (customStyles) {
                return _.merge({...OverrideUserFont(styles[name], sizeTypeOrStyle)}, customStyles);
            }
            else {
                return {...OverrideUserFont(styles[name], sizeTypeOrStyle)};
            }
        }
        else if (typeof sizeTypeOrStyle === "object") {
            return _.merge({...styles[name]}, sizeTypeOrStyle);
        }
    }
    else {
        Bug('CommonStyle.GetStyle', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default TextStyle;