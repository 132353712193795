import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TextInput, Image, TouchableWithoutFeedback, Keyboard, FlatList, Alert } from 'react-native';
import { Header } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
//import Geolocation from '@react-native-community/geolocation';
import { StackActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-community/async-storage';

import AlarmContext from '../../context/AlarmContext';
// config, lib
import GetLocation from '../../gps/GetLocation';
import Constants, { ConvStyle, ConvStrToDate } from '../../settings/Constants';
import Colors from '../../settings/Colors';
import FrStartEndBottomTab from '../components/bottomTabs/FrStartEndBottomTab';
import FIcon from '../../defines/FIcon';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import * as HistoryUtil from '../../utils/HistoryUtil';
import AppSetting from '../../defines/AppSetting';

import ListEmpty from '../components/parts/ListEmpty';
import TabSelectBarEx from '../components/common/TabSelectBarEx';
import styles from './include/FrStyle';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';


import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';


let UserScriptApi = require('../../utils/UserScriptApi');
// 검색이력 아이템
const RenderItemHistory = ({ title, item, navigation }) => {
    if (!item.name || !item.address || !item.noorLon || !item.noorLat) {
        return null;
    }
    return (
        <View
            style={{
                paddingVertical: 5,
                borderWidth: 0.5,
                borderColor: Colors.borderColor,
                backgroundColor: Colors.white
            }}
        >
            <TouchableOpacity
                onPress={() => {
                    if (title == '출발지') {                        
                        global.startPOI = {
                            title: item.name,
                            address: item.address,
                            x: item.noorLon,
                            y: item.noorLat
                        }
                        console.log("global.startPOI update1", global.startPOI)
                    } else {
                        global.endPOI = {
                            title: item.name,
                            address: item.address,
                            x: item.noorLon,
                            y: item.noorLat
                        }
                    }
                    navigation.goBack()
                }}
                style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginHorizontal: 10,
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                    <FIcon type="fas" name="history" size={25} color={Colors.fontCommentColor} style={{
                        paddingHorizontal: 10,
                        marginRight: 10
                    }} />
                    <View style={{ justifyContent: 'center', alignItems: 'flex-start', flex: 1 }}>
                        <Text numberOfLines={1} style={{ ...ConvStyle(18, Colors.fontColor, 150), lineHeight: 25 }}> {item.name} </Text>
                        <Text numberOfLines={1} style={{ ...ConvStyle(14, Colors.fontColor, 150), lineHeight: 25 }}> {item.address} </Text>
                    </View>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        Keyboard.dismiss();
                        navigation.navigate('frSelMap', {
                            x: item.noorLon,
                            y: item.noorLat,
                            title: title
                        })
                    }}
                    style={{ justifyContent: 'center', alignItems: 'flex-end', paddingRight: 10, width: '20%', alignSelf: "stretch" }}
                >
                    <FIcon type="fad" name="map-marked-alt" size={25} color={Colors.fontColor} />
                </TouchableOpacity>
            </TouchableOpacity>
        </View>
    )
}

// 출, 도착지로 선택하였됬거나 선택할수 있는 POI아이템
const RenderItem = ({ title, item, navigation, onPress }) => {
    return (
        <View style={{
            paddingVertical: 10,
            borderWidth: 0.5,
            borderColor: Colors.borderColor,
            backgroundColor: Colors.white
        }}>
            <TouchableOpacity
                onPress={() => { onPress?.() }}
                style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}>
                    <FIcon tpye="fas" name="map-marker-alt" size={25} color={Colors.fontColor} style={{ paddingHorizontal: 15, marginHorizontal: 10 }} />
                    <View style={{ justifyContent: 'space-around', alignItems: 'flex-start', flex: 1 }}>
                        <Text numberOfLines={1} style={{ ...ConvStyle(global.fontSize == 0 ? 18 : global.fontSize == 1 ? 19 : 20, Colors.fontColor, 150), lineHeight: 25 }}>
                            {item.name}
                        </Text>
                        <Text numberOfLines={1} style={{ ...ConvStyle(global.fontSize == 0 ? 14 : global.fontSize == 1 ? 15 : 16, Colors.fontColor, 150), lineHeight: 25 }}>
                            {item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName + ' ' + item.detailAddrName}
                        </Text>
                    </View>
                </View>
                <TouchableOpacity
                    onPress={() => {
                        Keyboard.dismiss();
                        navigation.navigate('frSelMap', {
                            x: item.noorLon,
                            y: item.noorLat,
                            title: title
                        })
                    }}
                    style={{ justifyContent: 'center', alignItems: 'center', width: '20%' }}
                >
                    <FIcon type="fad" name="map-marked-alt" size={25} color={Colors.fontColor} />
                </TouchableOpacity>
            </TouchableOpacity>
        </View>
    )
}

export default class FrStartEnd extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            initialPosition: 'unknown',
            searchWord: '',
            pois: null,
            historyPois: global.historyPois
        }
        this.searchPOI = this.searchPOI.bind(this);
    }

    // 현재위치의 좌표를 얻어낸다.
    componentDidMount() {
        this.setState({
            position: null,
        })
        GetLocation(null, 
            (position) => {
                const initialPosition = position;
                this.setState({ initialPosition: initialPosition })
            },
            (error) => {
                console.log('Error: ', JSON.stringify(error))
            })

        // Geolocation.getCurrentPosition(
        //     position => {
        //         const initialPosition = position;
        //         this.setState({ initialPosition: initialPosition })
        //     },
        //     error => console.log('Error: ', JSON.stringify(error)),
        //     { enableHighAccuracy: false }
        // );
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            this.setState({ historyPois: global.historyPois })
        })
        this._blur = this.props.navigation.addListener('blur', () => {
            Keyboard.dismiss();
        })
    }
    componentWillUnmount() {
        this._unsubscribe();
        this._blur();
    }


    // Tmap POI범용 검색 API사용
    searchPOI() {
        if (this.state.searchWord == '') {
            this.context.msgHandler?.toast('검색어가 비어있습니다.')
            return
        }
        let serverURL =
            `https://apis.openapi.sk.com/tmap/pois?version=1&format=json&callback=result&resCoordType=WGS84GEO&reqCoordType=WGS84GEO&count=10` +
            `&appKey=` + Constants.APP_KEY + `&searchKeyword=` + encodeURIComponent(this.state.searchWord);
        showPageLoader(true)
        return (
            fetch(serverURL, { method: 'GET' })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        return null;
                    }
                })
                .then((json) => {
                    if (json) {
                        this.setState({ pois: json })
                    }
                    else {
                        this.setState({ pois: {} })
                    }
                })
                .catch((error) => {
                    this.setState({ pois: {} })
                    console.log("error: ", error)
                })
                .finally(() => {
                    showPageLoader(false)
                })
        )
    }
    render() {
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeaderEx
                    title={this.props.route.params.title}
                    left={{
                        iconType: "fad", iconName: "chevron-left", onPress: () => {
                            global.startPOI = null;
                            global.endPOI = null;
                            this.props.navigation.goBack();
                        }
                    }}
                />
                {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss} > */}
                    <View style={[styles.body, { flex: 1 }]}>
                        <View style={{ backgroundColor: Colors.white }}>
                            <TextInput
                                placeholder='장소 또는 주소를 입력하세요.'
                                autoFocus={true}
                                clearTextOnFocus={true}
                                style={{ ...ConvStyle(14, Colors.blue, 140), ...styles.inputFull, paddingVertical: 7 }}
                                onChangeText={text => this.setState({ searchWord: text })}
                                value={this.state.searchWord}
                                onSubmitEditing={this.searchPOI}
                                // onSubmitEditing={() => { this.secondTextInput.focus(); }}
                                // ref={(input) => { this.secondTextInput = input; }}
                            />
                        </View>
                        {/* <TabSelectBarEx
                            tabs={{ inci: "교통속보(돌발)", infoGraphic: "인포그래픽", board: "공지사항" }}
                            icons={{
                                inci: { type: "fad", name: "car-crash" },
                                infoGraphic: { type: "fad", name: "analytics" },
                                board: { type: "fad", name: "clipboard-list" },
                            }}
                            activeId={selectedTab}
                            useFullRow={true}
                            textStyle={TextStyle("small")}
                            onPress={(id) => {
                                setSelectedTab(id);
                                setShowInfoGraphicWhenNoInci(false);
                            }}
                        /> */}

                        <TabSelectBarEx
                            tabs={{ useMyPos: "현재위치", pickMap: "지도에서 선택" }} // recent: "최근검색", 
                            icons={{
                                useMyPos: { type: "fad", name: "location" },
                                pickMap: { type: "fad", name: "map-marked-alt" },
                            }}
                            activeId=""
                            useFullRow={false}
                            textStyle={TextStyle("small")}
                            onPress={(id) => {
                                if (id == "useMyPos") {
                                    Keyboard.dismiss();
                                    if (this.props.route.params.title == '출발지') {
                                        if (this.state.initialPosition == "unknown") {
                                            global.startPOI = {
                                                title: '현재위치',
                                                address: '현재위치',
                                                x: AppSetting.GPS.MAP_DEF_X,
                                                y: AppSetting.GPS.MAP_DEF_Y
                                            }
                                            console.log("global.startPOI update2", global.startPOI)
                                        } else {
                                            global.startPOI = {
                                                title: '현재위치',
                                                address: '현재위치',
                                                x: this.state.initialPosition.coords.longitude,
                                                y: this.state.initialPosition.coords.latitude
                                            }
                                            console.log("global.startPOI update3", global.startPOI)
                                        }
                                    } else {
                                        if (this.state.initialPosition == "unknown") {
                                            global.endPOI = {
                                                title: '현재위치',
                                                address: '현재위치',
                                                x: AppSetting.GPS.MAP_DEF_X,
                                                y: AppSetting.GPS.MAP_DEF_Y
                                            }
                                        } else {
                                            global.endPOI = {
                                                title: '현재위치',
                                                address: '현재위치',
                                                x: this.state.initialPosition.coords.longitude,
                                                y: this.state.initialPosition.coords.latitude
                                            }
                                        }
                                    }
                                    this.props.navigation.dispatch(StackActions.pop(1));
                                }
                                else {
                                    Keyboard.dismiss();
                                    if (this.state.initialPosition == "unknown") {
                                        this.props.navigation.navigate('frSelMap', {
                                            x: AppSetting.GPS.MAP_DEF_X,
                                            y: AppSetting.GPS.MAP_DEF_Y,
                                            title: this.props.route.params.title
                                        })
                                    } else {
                                        this.props.navigation.navigate('frSelMap', {
                                            x: this.state.initialPosition.coords.longitude,
                                            y: this.state.initialPosition.coords.latitude,
                                            title: this.props.route.params.title
                                        })
                                    }
                                }
                            }}
                            rightComponent={
                                this.state.pois 
                                ? <></>
                                : <TouchableOpacity
                                    style={{
                                        flexDirection: "row",
                                        justifyContent: 'flex-end',
                                        alignItems: "center",
                                        marginBottom: 0,
                                        marginRight: 10,
                                        padding: 0
                                    }}
                                   
                                    onPress={() => {
                                        this.context.msgHandler?.dialog({
                                            title: "삭제",
                                            message: "검색이력을 삭제하시겠습니까?",
                                            ok: () => {
                                                this.setState({ historyPois: [] }, async () => {
                                                    HistoryUtil.ClearPoiHistory();
                                                    this.context.msgHandler?.toast(GetText("pageComment", "COMPLETE_DEL"))
                                                })

                                            },
                                            cancel: () => { }
                                        })
                                }}
                                >
                                    <FIcon type="fas" name="history" size={20} color={Colors.fontCommentColor} />
                                    <Text style={TextStyle("tabSelectLabel", "small", { paddingLeft: 5, paddingRight: 10 })}>삭제</Text>
                                </TouchableOpacity>
                            }
                        />



                        {/* 검색결과 또는 검색이력을 보여주는 목록을 현시한다. */}

                        <View style={[styles.listView, { flex: 1 }]}>
                            {
                                this.state.pois ?
                                    <FlatList
                                        data={this.state.pois && this.state.pois.searchPoiInfo && this.state.pois.searchPoiInfo.pois ?
                                            this.state.pois.searchPoiInfo.pois.poi : []}
                                        ListEmptyComponent={<ListEmpty text="일치하는 검색결과가 없습니다." />}
                                        renderItem={({ item, index, separators }) => {
                                            return <RenderItem
                                                title={this.props.route.params.title}
                                                item={item}
                                                navigation={this.props.navigation}
                                                onPress={() => {
                                                    HistoryUtil.InsertPoiHistory(item)
                                                    if (this.props.route.params.title == '출발지') {
                                                        global.startPOI = {
                                                            title: item.name,
                                                            address: item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName,
                                                            x: item.noorLon,
                                                            y: item.noorLat
                                                        }
                                                        console.log("global.startPOI update4", global.startPOI)
                                                    }
                                                    else {
                                                        global.endPOI = {
                                                            title: item.name,
                                                            address: item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName,
                                                            x: item.noorLon,
                                                            y: item.noorLat
                                                        }
                                                    }
                                                    this.props.navigation.goBack()
                                                }}
                                            />
                                        }}
                                        ListHeaderComponent={null}
                                        ListFooterComponent={
                                            <View style={{ height: 15 }} />
                                        }
                                        refreshing={false}
                                        keyExtractor={(item, index) => index.toString()}
                                    />
                                    : <FlatList
                                        data={this.state.historyPois}
                                        ListEmptyComponent={<ListEmpty text="최근 검색 이력이 없습니다." />}
                                        renderItem={({ item, index, separators }) => {
                                            return <RenderItemHistory
                                                item={item}
                                                navigation={this.props.navigation}
                                                title={this.props.route.params.title}
                                            />
                                        }}
                                        ListHeaderComponent={null}
                                        ListFooterComponent={
                                            <View style={{ height: 15 }} />
                                        }
                                        refreshing={false}
                                        keyExtractor={(item, index) => index.toString()}
                                    />
                            }
                            {/* <FlatList
                                data={this.state.pois ? this.state.pois.searchPoiInfo.pois.poi : this.state.historyPois}
                                ListEmptyComponent={<ListEmpty text="검색어를 입력하세요"/>}
                                renderItem={({ item, index, separators }) => {
                                    if (this.state.pois) {
                                        return (
                                            <RenderItem
                                                title={this.props.route.params.title}
                                                item={item}
                                                navigation={this.props.navigation}
                                                onPress={() => {
                                                    HistoryUtil.InsertPoiHistory({
                                                        name: item.name,
                                                        address: item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName,
                                                        noorLon: item.noorLon,
                                                        noorLat: item.noorLat
                                                    })
                                                    if (this.props.route.params.title == '출발지') {
                                                        global.startPOI = {
                                                            title: item.name,
                                                            address: item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName,
                                                            x: item.noorLon,
                                                            y: item.noorLat
                                                        }
                                                    } 
                                                    else {
                                                        global.endPOI = {
                                                            title: item.name,
                                                            address: item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName,
                                                            x: item.noorLon,
                                                            y: item.noorLat
                                                        }
                                                    }
                                                    this.props.navigation.goBack()
                                                }}
                                            />
                                        )
                                    } else {
                                        return (
                                            <RenderItemHistory
                                                item={item}
                                                navigation={this.props.navigation}
                                                title={this.props.route.params.title}
                                            />
                                        )
                                    }
                                }}
                                ListHeaderComponent={
                                    <View style={{ height: 5 }} />
                                }
                                ListFooterComponent={
                                    <View style={{ height: 15 }} />
                                }
                                refreshing={false}
                                keyExtractor={(item, index) => index.toString()}
                            /> */}
                        </View>
                    </View>
                {/* </TouchableWithoutFeedback> */}
                <FrStartEndBottomTab
                    navigation={this.props.navigation}
                    setPoi={() => {
                        if (this.state.initialPosition == "unknown") {
                            this.props.navigation.navigate('frSelMap', {
                                x: AppSetting.GPS.MAP_DEF_X,
                                y: AppSetting.GPS.MAP_DEF_Y,
                                title: this.props.route.params.title
                            })
                        } else {
                            this.props.navigation.navigate('frSelMap', {
                                x: this.state.initialPosition.coords.longitude,
                                y: this.state.initialPosition.coords.latitude,
                                title: this.props.route.params.title
                            })
                        }
                    }}
                    setCurrent={() => {
                        if (this.props.route.params.title == '출발지') {
                            if (this.state.initialPosition == "unknown") {
                                global.startPOI = {
                                    title: '현재위치',
                                    x: AppSetting.GPS.MAP_DEF_X,
                                    y: AppSetting.GPS.MAP_DEF_Y
                                }
                                console.log("global.startPOI update5", global.startPOI)
                            } else {
                                global.startPOI = {
                                    title: '현재위치',
                                    x: this.state.initialPosition.coords.longitude,
                                    y: this.state.initialPosition.coords.latitude
                                }
                                console.log("global.startPOI update6", global.startPOI)
                            }
                        } else {
                            if (this.state.initialPosition == "unknown") {
                                global.endPOI = {
                                    title: '현재위치',
                                    x: AppSetting.GPS.MAP_DEF_X,
                                    y: AppSetting.GPS.MAP_DEF_Y
                                }
                            } else {
                                global.endPOI = {
                                    title: '현재위치',
                                    x: this.state.initialPosition.coords.longitude,
                                    y: this.state.initialPosition.coords.latitude
                                }
                            }
                        }
                        this.props.navigation.dispatch(StackActions.pop(1));
                    }}
                />
            </SafeAreaView>
        )
    }
}
