import React, { Component, useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Header } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import Slider from '@react-native-community/slider';
//import EIcons from 'react-native-vector-icons/Entypo';

import { Colors, Layouts } from '../../defines/Theme';
import { GenerateFontStyles } from '../../defines/Fonts';
import FIcon from '../../defines/FIcon';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import Texts, { GetText } from '../../defines/Texts';
import * as FavorUtil from '../../utils/FavorUtil';
import * as StorUtil from '../../utils/StorUtil';
import * as DateUtil from '../../utils/DateUtil';
import AlarmContext from '../../context/AlarmContext';
import ScreenHeader from '../components/common/ScreenHeader';


import { BottomRightTabs } from '../components/bottomTabs/BottomTab';
import ListHeader from '../components/parts/ListHeader';
import AppSetting from '../../defines/AppSetting';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';
let UserScriptApi = require('../../utils/UserScriptApi');

export function ForeignServiceSelectItem({id, onPress, selectedForeign}){
    // console.log(global.foreignLanguage);    
    return (<>
        <TouchableOpacity style={CommonStyle("box", {})}
            onPress={() => { onPress?.(id) }}
            accessibilityLabel={GetText("FOREIGN_LANGUAGE", id) + (global.foreignLanguage === id ? " 선택됨" : " 선택안됨")}
            accessible={true}
            accessibilityRole="radio"
        >
            <View style={SectionStyle("rowForRow", { alignItems: "center" })}>
                <Text style={TextStyle("rowTitle", "large", 
                    { color: Colors.ggBlue, marginHorizontal: 10 })}>
                    {GetText("FOREIGN_LANGUAGE", id)}
                </Text>
                <FIcon 
                    type={global.foreignLanguage === id ? "fas" : "fad"}
                    name="check-circle" 
                    color={global.foreignLanguage === id ? Colors.ggBlue : Colors.borderColor} size={27}
                />
            </View>
        </TouchableOpacity>
    </>);
}

export default class Foreigner extends Component {
    static contextType = AlarmContext;

    constructor(props) {
        super(props)
        this.state = {
            fontSize: 0,
            wantedNthPrev: [],
            devel: {
                tapTime: null,
                tapLeft: 0,
                tapRight: 0,
            },
            foreignLanguage: 'K',
        }
    }

    componentDidMount() {    
        UserScriptApi.apiConnect('foreigner')
        console.log("global.foreignLanguage", global.foreignLanguage)    
        this.setState({
            fontSize: this.context.initState.fontSize,
            wantedNthPrev: this.context.wantedNthPrev            
        })
    }

    render() {
        return (
            <SafeAreaView style={ CommonStyle('bg') }>
                <ScreenHeader title={GetText("pageTitle","CHOICE_LANGUAGE")} />
                <View style={CommonStyle('body')}>
                    <View style={CommonStyle("box")}>
                        <ListHeader text={GetText("pageTitle","CHOICE_LANGUAGE")} />                                            
                        <View style={CommonStyle("boxBottomBorder")}>
                            <View style={SectionStyle("rowForHRow", { 
                                height: Layouts.rowHeight * 1.2,
                                justifyContent: "space-around", 
                                paddingRight: 10, 
                                backgroundColor: Colors.ggBlueTrans1 })}>
                                    <ForeignServiceSelectItem id={'K'} onPress={()=>{
                                            if(this.state.foreignLanguage!=null){
                                                this.context.setForeignLanguage('K', () => {
                                                    AppSetting.SERVICE_KEY='1234567890';
                                                    this.setState({
                                                        foreignLanguage: 'K',
                                                    });
                                                })
                                            }
                                        }} selectedForeign={this.state.foreignLanguage}/>
                                    <ForeignServiceSelectItem id={'E'} onPress={()=>{
                                        if(this.state.foreignLanguage!=null){
                                            this.context.setForeignLanguage('E', () => {
                                                AppSetting.SERVICE_KEY='1234567890_EN';
                                                this.setState({
                                                    foreignLanguage: 'E',
                                                });
                                            })
                                        }}} selectedForeign={this.state.foreignLanguage}/>    
                                    <ForeignServiceSelectItem id={'C'} onPress={()=>{
                                        if(this.state.foreignLanguage!=null){
                                            this.context.setForeignLanguage('C', () => {
                                                AppSetting.SERVICE_KEY='1234567890_CN';
                                                this.setState({
                                                    foreignLanguage: 'C',
                                                });
                                            })
                                        }}} selectedForeign={this.state.foreignLanguage}/>                                     
                            </View>
                            <View style={SectionStyle("rowForHRow", { 
                                height: Layouts.rowHeight * 1.2,
                                justifyContent: "space-around", 
                                paddingRight: 10, 
                                backgroundColor: Colors.ggBlueTrans1 })}>
                                    <ForeignServiceSelectItem id={'J'} onPress={()=>{
                                        if(this.state.foreignLanguage!=null){
                                            this.context.setForeignLanguage('J', () => {
                                                AppSetting.SERVICE_KEY='1234567890_JP';
                                                this.setState({
                                                    foreignLanguage: 'J',
                                                });
                                            })
                                        }}} selectedForeign={this.state.foreignLanguage}/>
                                    <ForeignServiceSelectItem id={'V'} onPress={()=>{
                                        if(this.state.foreignLanguage!=null){
                                            this.context.setForeignLanguage('V', () => {
                                                AppSetting.SERVICE_KEY='1234567890_VT';
                                                this.setState({
                                                    foreignLanguage: 'V',
                                                });
                                            })
                                        }}} selectedForeign={this.state.foreignLanguage}/>    
                            </View>
                        </View>
                    </View>
                                       
                </View>
                <BottomRightTabs navigation={this.props.navigation} />
            </SafeAreaView>
        )
    }
}

