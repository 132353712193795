import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image, Alert } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { WebView } from 'react-native-webview';

import AlarmContext from '../../context/AlarmContext';
// config, lib
import MapStyle from '../../styles/MapStyle';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import { Colors, Layouts } from '../../defines/Theme';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import Texts, { GetText } from '../../defines/Texts';
import AppSetting from '../../defines/AppSetting';
import * as GBus from '../../defines/GBus';
import GetLocation from '../../gps/GetLocation';

import ScreenHeader from '../components/common/ScreenHeader';
import SearchPoiMapBottomTab from '../components/bottomTabs/SearchPoiMapBottomTab';

//import Html from '../Tmap/TmapSearchPoi';
let UserScriptApi = require('../../utils/UserScriptApi');

function MyLocation({ selectedPoi, onPress }) {
    return (
        <>
            {
                selectedPoi
                    ? <TouchableOpacity style={MapStyle("myLocation")}
                        onPress={onPress}>
                        <FIcon type="fad" name="location" size={55} color={Colors.ggBlue} />
                    </TouchableOpacity>
                    : <TouchableOpacity style={MapStyle("myLocation", { bottom: Layouts.bottomBarHeight + 60})}
                        onPress={onPress}>
                        <FIcon type="fad" name="location" size={55} color={Colors.ggBlue} />
                    </TouchableOpacity>
            }
        </>
    );
}

export function RenderSelectedPoi({ selectedPoi, onPress }) {
    return (
        <View style={MapStyle("selStationBoxBg")}>
            {
                selectedPoi
                    ? <TouchableOpacity style={MapStyle("selStationBox")}
                        onPress={() => {
                            onPress?.()
                        }}
                    >
                        <View style={SectionStyle("rowLeftIconArea")}>
                            <FIcon type="fad" name="map-marker-alt" size={28} />
                        </View>
                        <View style={SectionStyle("rowFillStretch")}>
                            <Text numberOfLines={1} style={TextStyle("rowTitleDesc", { paddingVertical: 10 })}>
                                {selectedPoi.poiName}
                            </Text>
                            <Text numberOfLines={1} style={TextStyle("rowDetail", { marginBottom: 5 })}>
                                {selectedPoi.poiAddress}
                            </Text>
                        </View>
                        {/* <View style={ SectionStyle("rowRightButtonsArea") }>
                            <FIcon type="fad" name="chevron-right" size={20} color={Colors.ggBlue} />
                        </View> */}
                    </TouchableOpacity>
                    : <View style={MapStyle("selStationBoxEmpty")}>
                    </View>
            }
        </View>
    )
}

export default class SearchPoiMap extends Component {

    static contextType = AlarmContext;
    constructor(props) {
        super(props)
        this.state = {
            position: null,
            selectedPoi: null,
            initialPoi: null,
        }
    }

    componentDidMount() {
        UserScriptApi.apiConnect('searchPoiMap')
        
        GetLocation(this.context?.msgHandler, (p) => {
            this.setState({ position: p })
        })
        this.setState({
            initialPoi: {
                x: this.props.route.params.positionInfo.noorLon,
                y: this.props.route.params.positionInfo.noorLat,
            }
        })
    }

    toWebView = (cmd, params) => {
        this.webRef?.postMessage({
            _sender: "GBUS_RN",
            cmd: cmd,
            params: params
        }, "*")
    }


    render() {
        // console.log("------------------ rendering:")
        // console.log(this.props.route.params.positionInfo)


        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    title="장소"
                />
                <View style={CommonStyle("body")}>
                    <WebView
                        ref={(ref) => this.webRef = ref}
                        originWhitelist={['*']}
                        style={MapStyle("mapView")}
                        source={{ uri: AppSetting.MOBILE_WEB.ROOT + "assets/Tmap/TmapSearchPoi.html", method: 'get' }}
                        // source={{ html: Html.content }}
                        onLoadStart={event => {
                            showPageLoader(true)
                        }}
                        onLoadEnd={event => {
                            showPageLoader(false)
                        }}
                        onMessage={(event) => {
                            if (!event.nativeEvent.data || typeof event.nativeEvent.data != "string" || !event.nativeEvent.data.startsWith("{")) {
                                return;
                            }
                            //                        console.log(event)
                            let data = JSON.parse(event.nativeEvent.data.toString())
                            if (data.event == "poi") {
                                console.log(`[TMap] poi changed:`)
                                console.log(data.data)
                                this.setState({ selectedPoi: data.data })
                            }
                            else if (data.event == "msg") {
                                this.context.msgHandler?.toast(data.data);
                            }
                            else if (data.event == "log") {
                                console.log("[TMap] " + data.data);
                            }
                            else if (data.event == "error") {
                                console.log("[TMap] error:");
                                console.log(data.data);
                            }
                        }}
                        javaScriptEnabled={true}
                        injectedJavaScript={
                            this.state.initialPoi ?
                                `startWithPoi(${this.state.initialPoi.x}, ${this.state.initialPoi.y})` :
                                `startWithPoi(${AppSetting.GPS.MAP_DEF_X}, ${AppSetting.GPS.MAP_DEF_Y})`
                        }
                    />
                </View>
                <MyLocation
                    selectedPoi={this.state.selectedPoi}
                    onPress={() => {
                        if (!this.state.position) {
                            this.toWebView("showMyPosition", { x: AppSetting.GPS.MAP_DEF_X, y: AppSetting.GPS.MAP_DEF_Y });
                            this.context.msgHandler?.toast(GetText("gps", "PLZ_ALLOW_PERMISSION"));
                        }
                        else {
                            this.toWebView("showMyPosition", { x: this.state.position.coords.longitude, y: this.state.position.coords.latitude });
                        }
                    }}
                />
                <RenderSelectedPoi
                    selectedPoi={this.state.selectedPoi}
                    onPress={() => {
                        this.toWebView("showMarkerToCenter", {});
                    }}
                />
                <SearchPoiMapBottomTab
                    navigation={this.props.navigation}
                    findRoad={() => {
                        if (this.state.selectedPoi) {
                            global.startPOI = {
                                title: '현재위치',
                                x: this.state.position.coords.longitude,
                                y: this.state.position.coords.latitude
                            }
                            global.endPOI = {
                                title: this.state.selectedPoi.poiN,
                                x: this.state.selectedPoi.x,
                                y: this.state.selectedPoi.y
                            }
                            this.props.navigation.navigate('findRoad')
                        }
                    }} />
            </SafeAreaView>
        )
    }
}
