import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import FrStyle from '../../../styles/FrStyle';
import * as JsUtil from '../../../utils/JsUtil';

const SubwayLaneBox = ({selfColor, laneAlias}) => {
    // console.log("SubwayLaneBox laneAlias", TMap.GetTMapSubwayShort(laneAlias))
    if (TMap.GetTMapSubwayIconType(laneAlias) === 'num') {
        return (
            <View style={FrStyle("busIconViewBg", { backgroundColor: selfColor})}>
                <Text numberOfLines={1} style={TextStyle("rowTitleDesc", "medium", { color: "#fff" })}>{TMap.GetTMapSubwayShort(laneAlias)}</Text>
            </View>
        )
    }
    else  {
        return (
            <View style={ FrStyle('subwayBoxIconViewBg', { backgroundColor: selfColor } ) }>
                <Text style={ TextStyle('rowDetail', 'small', { color: "#fff" }) }>
                    {TMap.GetTMapSubwayShort(laneAlias)}
                </Text>
            </View>
        )
    }
}

export default function FrSubwayRow({ beforeColor, selfColor, item}) {    
    return(
        <View style={FrStyle("abbrSubwayRowBg")}>
            <View style={FrStyle("abbrLeftIconBg")} accessibilityLabel={"지하철 " + JsUtil.GText(item.subway,"name")} accessible={true}>
                <View style={FrStyle("abbrTopLine", { backgroundColor: beforeColor})} />
                <View style={FrStyle("abbrBottomLine", { backgroundColor: selfColor})} /> 
                <SubwayLaneBox selfColor={selfColor} laneAlias={JsUtil.GText(item.subway,"name")} />
            </View>
            <View style={FrStyle("abbrRightBg")} accessible={true}>
                <Text numberOfLines={1} style={ TextStyle('rowDetail', 'medium', {color: selfColor}) }>
                    { item.startStationName }
                </Text>
                <Text numberOfLines={1} style={ TextStyle('rowDetail', 'small', {flex: 1, marginLeft: 10}) }>
                    {JsUtil.GText(item.subway,"headSign")}
                </Text>
            </View>
        </View>
    )    
}
