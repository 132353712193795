import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import * as TMap from '../../../defines/TMap';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import SectionStyle from '../../../styles/SectionStyle';
import FrStyle from '../../../styles/FrStyle';
import * as JsUtil from '../../../utils/JsUtil';

const BusLaneBox = ({selfColor, laneType}) => {
    return (
        <View style={ SectionStyle('routeTypeBox', { borderColor: selfColor } ) }>
            <Text style={ TextStyle('rowDetail', 'small') }>
                { TMap.GetTMapRouteLabel(laneType) }
            </Text>
        </View>
    )
}

export default function FrBusRow({ beforeColor, selfColor, item }) {
    return (
        
        <View style={FrStyle("abbrBusRowBg")}>
            <View style={FrStyle("abbrLeftIconBg")} accessibilityLabel="버스 " accessible={true}>
                <View style={FrStyle("abbrTopLine", { backgroundColor: beforeColor})} />
                <View style={FrStyle("abbrBottomLine", { backgroundColor: selfColor})} />
                <View style={FrStyle("abbrBusIconBg", { backgroundColor: selfColor})} >
                    <FIcon type="fas" name="bus" size={20} color={Colors.white} />
                </View>
            </View>
            <View style={FrStyle("abbrRightBg")} accessible={true}>
                <BusLaneBox selfColor={selfColor} laneType={JsUtil.GText(item.bus,"typeName")} />
                <Text style={ TextStyle('rowDetail', 'medium', {color: selfColor, marginLeft: 10}) }>
                    { JsUtil.GText(item.bus,"name") }
                </Text>
                <Text numberOfLines={1} style={ TextStyle('rowDetail', 'medium', {color: Colors.fontColor, flex: 1, marginLeft: 10}) }>
                    { item.startStationName }
                </Text>
            </View>
        </View>
    )
}
