// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Bug from '../utils/Bug';


export const styles = {
    _def: {
        backgroundColor: '#f00',
    },
    routeNodeMapRowBg: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        height: Layouts.routeNodeMapRowHeight,
        borderColor: Colors.borderColor,
        borderWidth: 0,
        borderBottomWidth: 0.7,
        // padding: 5,
        backgroundColor: Colors.appBackgroundColor,
        backgroundColor: "transparent",
    },

    // bus display column
    leftBusColBg: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        width: Layouts.routeNodeMapLeftColWidth,
        //margin: 5,
        //padding: 5,
        // backgroundColor: "rgba(0, 255, 0, 0.5)",
    },

    // bus display column
    leftBusNarrowColBg: {
        flexDirection: "row",
        justifyContent: "flex-end",
        width: Layouts.routeNodeMapLeftColWidth * 0.65,
        //margin: 5,
        //padding: 5,
        // backgroundColor: "rgba(0, 255, 0, 0.5)",
    },


    rightStationColBg: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        paddingLeft: 20,
        paddingRight: 10,
        paddingVertical: 5,
        height: Layouts.routeMapRowHeight,
    },

    rightStationColBg2: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        paddingLeft: 20,
        paddingRight: 10,
        paddingVertical: 5,
        height: Layouts.routeMapRowHeight,        
    },

    stationRightToilet: {        
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingLeft: 5,
        paddingRight: 0,
        paddingVertical: 5,
        height: Layouts.routeMapRowHeight,        
    },

    routeRunLineColBg: {
        alignSelf: "stretch",
        width: Layouts.routeNodeMapMarkSize,
        justifyContent: "center",
        alignItems: 'center',
        //position: 'absolute',
        //left: Layouts.windowWidth * Layouts.routeNodeMapLeftWidthRate - 120 + 14,
        // top: 0,
        // height: Layouts.routeNodeMapRowHeight,
        // width: Layouts.routeNodeMapLeftColWidth,
        // flexDirection: 'row',
        // justifyContent: 'flex-end',
        // backgroundColor: "rgba(0, 0, 255, 0.5)"
    },


    routeRunLineCol: {
        position: 'absolute',
        // left: Layouts.routeNodeMapLeftColWidth,
        left: 2,
        top: 0,
        width: Layouts.routeNodeMapMarkSize - 4,
        height: Layouts.routeNodeMapRowHeight,
        transform: [{
            translateY: Layouts.routeNodeMapRowHeight / 2
        }],
        // transform: [{ 
        //     translateY: Layouts.routeNodeMapRowHeight / 2
        // },{
        //     translateX: Layouts.routeNodeMapMarkSize * -1 + 2
        // }],
        // backgroundColor: "#f00",
    },

    routeRunLineNarrowCol: {
        position: 'absolute',
        left: Layouts.routeNodeMapLeftColWidth * 0.65,
        // /left: 0,
        top: 0,
        width: Layouts.routeNodeMapMarkSize - 4,
        height: Layouts.routeNodeMapRowHeight,
        transform: [{
            translateY: Layouts.routeNodeMapRowHeight / 2
        }, {
            translateX: Layouts.routeNodeMapMarkSize * -1 + 2
        }],
        // backgroundColor: "#f00",
    },


    stationMarkContainer: {
        //position: 'absolute',
        //left: Layouts.windowWidth * Layouts.routeNodeMapLeftWidthRate - 120 + 14,
        // top: 0,
        // height: Layouts.routeNodeMapRowHeight,
        // width: Layouts.routeNodeMapLeftColWidth,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // backgroundColor: "rgba(255, 255, 0, 0.5)"
    },
    stationMarkLabel: {
        fontSize: Fonts.small.fontSize,
        fontFamily: Fonts.small.fontFamily,
        color: Colors.fontColor,
        marginRight: 10,
    },
    stationMarkBg: {
        backgroundColor: Colors.white,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: Layouts.routeNodeMapMarkSize / 2,
        width: Layouts.routeNodeMapMarkSize,
        height: Layouts.routeNodeMapMarkSize,
        // marginLeft: 5,
        // flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',

    },


    // 실시간 버스 상태/위치
    busContainer: {
        position: 'absolute',
        left: Layouts.routeNodeMapBusIconSize / 2 - 8, // Layouts.windowWidth * Layouts.routeNodeMapLeftWidthRate - 120 + 20,
        height: Layouts.routeNodeMapRowHeight,
        width: Layouts.routeNodeMapLeftColWidth,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    // 실시간 버스 상태/위치
    busNarrowContainer: {
        position: 'absolute',
        left: Layouts.routeNodeMapBusIconSize / 2 - 8, // Layouts.windowWidth * Layouts.routeNodeMapLeftWidthRate - 120 + 20,
        height: Layouts.routeNodeMapRowHeight,
        width: Layouts.routeNodeMapLeftColWidth * 0.65,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    busStateInfoBg: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: 5,
        //        backgroundColor: "rgba(255, 0, 0, 0.5)"
    },
    busLeftColBg: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginRight: 5,
        padding: 3,
        //        zIndex: 2,
        backgroundColor: Colors.appBackgroundColor,
    },
    busRightIconCol: {
        padding: 0,
        borderRadius: Layouts.routeNodeMapBusIconSize / 2,
        backgroundColor: Colors.appBackgroundColor,
        //      backgroundColor: "#f00", 
    },

    busVehNoText: {
        fontSize: Fonts.smallest.fontSize,
        fontFamily: Fonts.smallest.fontFamily,
        color: Colors.fontColor,
        padding: 5,
        backgroundColor: Colors.appBackgroundColor,
        textAlign: 'right'
    },

    activeBusType: {
        borderWidth: 2,
        borderRadius: 8,
        paddingHorizontal: 5,
        paddingVertical: 2
    },
    stationLeftCol: {
        width: Layouts.routeNodeMapRowHeight,
        height: Layouts.routeNodeMapLeftColWidth, // 70,
    },

    stationCol: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'space-around',
        paddingLeft: 20,
        paddingRight: 10,
        paddingVertical: 5,

        height: Layouts.routeNodeMapRowHeight,
    }
};

const RouteNodeMapStyle = (name, customStyles = null) => {
    if (typeof customStyles === "string") {
        console.log("RouteNodeMapStyle.GetStyle() changed. font sized style should go to TextStyle()")
    }
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('RouteNodeMapStyle()', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default RouteNodeMapStyle;