import React from 'react';
import { View, Text } from 'react-native';
// config
import * as GBus from '../../../defines/GBus';
import * as JsUtil from '../../../utils/JsUtil';
import { Colors, Layouts } from '../../../defines/Theme';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import SpacerH from '../common/SpacerH'; 
import { GetText } from '../../../defines/Texts';

const RemainSeatCntBox = ({bus, style}) => {
    if (!bus || !GBus.IsRemainSeatSupportRoute(bus))  {
        return null;
    }
    let cnt = JsUtil.GInt(bus, "remainSeatCnt");
    return (<>
    {
        <View style={ SectionStyle("remainSeatCntBox", style) }>
            <Text style={ TextStyle('rowDetail', 'small') }>
                { (cnt >= 0 ? cnt : 0) + GetText('PAGEWORD', 'N_REMAIN_SEAT_CNT').replace("{n}", "")}
            </Text>
        </View>
    }
    </>)
}
export default RemainSeatCntBox