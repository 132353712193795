import React from 'react';
import { View, Text } from 'react-native';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';

const ListHeader = ({ text, style, textStyle }) => {
    return (
        <View style={CommonStyle('listHeaderBg', style)}>
            <Text style={ TextStyle('listHeaderText', textStyle) }>
                {text}
            </Text>
        </View>    
    );
}


export default ListHeader