import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, TextInput, Keyboard, Image } from 'react-native';

// context
import AlarmContext from '../../../context/AlarmContext';

//import Colors from '../../settings/Colors';
//  config, lib
import { Colors, Layouts } from '../../../defines/Theme';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import * as CommonStyle from '../../../styles/CommonStyle';
import * as SectionStyle from '../../../styles/SectionStyle';
import * as FavorUtil from '../../../utils/FavorUtil';
import * as HistoryUtil from '../../../utils/HistoryUtil';
import FIcon from '../../../defines/FIcon';
import LoadImage from '../../../defines/Images';

// deprecated
import styles from './FrStyle';
import Constants, { ConvStyle } from '../../../settings/Constants';


// 일반 및 지역길찾기 출, 도착지 정보입력창
export default function FrArea({ navigation, start, end, runFindPathArea }) {
    const context = useContext(AlarmContext)
    const [startArea, setStartArea] = useState("")
    const [endArea, setEndArea] = useState("")

    useEffect(() => {
        if (start && typeof start === "string")
            setStartArea(start)
        if (end && typeof end === "string")
            setEndArea(end)
    }, [start, end])

    return (
        <View style={{ ...styles.frAreaContainer, ...styles.shadow }}>
            <View style={{
                justifyContent: 'center',
                width: '80%'
            }}>
                <View style={styles.row}>
                    <TouchableOpacity
                        activeOpacity={1}
                        style={{ width: '70%', marginRight: 10 }}
                        onPress={() => { }}
                    >
                        <TextInput
                            // value="봉담읍"
                            placeholder={GetText("FINDPATH", "START_AREA_HOLDER")}
                            style={{ ...ConvStyle(14, Colors.fontColor, 140), ...styles.input, marginRight: 5 }}
                            onChangeText={text => setStartArea(text)}
                        />
                    </TouchableOpacity>
                    <Text style={ConvStyle(16, Colors.fontColor, 150)}>에서</Text>
                </View>
                <View style={styles.row}>
                    <TouchableOpacity
                        activeOpacity={1}
                        style={{ width: '70%', marginRight: 10 }}
                        onPress={() => { }}
                    >
                        <TextInput
                            // value="사당동"
                            placeholder={GetText("FINDPATH", "END_AREA_HOLDER")}
                            style={{ ...ConvStyle(14, Colors.fontColor, 140), ...styles.input, marginRight: 5 }}
                            onChangeText={text => setEndArea(text)}
                        />
                    </TouchableOpacity>
                    <Text style={ConvStyle(16, Colors.fontColor, 150)}>가는길</Text>
                </View>
            </View>
            <TouchableOpacity style={{ ...styles.areaSearchIcon, ...styles.shadow }}
                onPress={() => {
                    Keyboard.dismiss();
                    // setStartArea("봉담읍")
                    // setEndArea("사당동")
                    if (!startArea || !endArea) {
                        context.msgHandler?.toast('출발지와 도착지를 입력해주세요.')
                    }
                    else {
                        runFindPathArea?.(startArea, endArea)
                    }
                }}
            >
                <LoadImage name="area_pathfind" width={30} height={30} />
                {/* <Image source={require('../../../../assets/findRoad/area_search.png')} style={{ width: 30, height: 30, resizeMode: 'contain' }} /> */}
            </TouchableOpacity>
        </View>
    )
}
