import React from 'react';
import { View, Text } from 'react-native';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

import * as GBus from '../../../defines/GBus';
import { Colors, Layouts } from '../../../defines/Theme';
import SpacerH from '../common/SpacerH';

const RouteTypeBox = ({ routeType, showShortLabel }) => {
    return (
        <View style={ SectionStyle('routeTypeBox', { paddingTop: 3,
            borderColor: GBus.GetRouteTypeColor(routeType) } ) }>
            <Text style={ TextStyle('rowDetail', 'small') }>
                { showShortLabel ? GBus.GetRouteTypeShortLabel(routeType) : GBus.GetRouteTypeLabel(routeType) }
            </Text>
        </View>
    )
}

export default RouteTypeBox

RouteTypeBox.defaultProps = {
    showShortLabel: false,
}