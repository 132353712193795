import React from 'react';
import * as StorUtil from '../utils/StorUtil';
import * as JsUtil from '../utils/JsUtil';

const savedLanguage = 'K';

const Texts = {
    ERROR: {
        UNKNOWN: "알 수 없는 오류가 발생했습니다.",
        // 네트워크 오류
        NET_FAIL: "네트워크 오류가 발생했습니다. 통신상태를 확인해 주세요.",
        // 네트워크는 OK, 서버에서 오류 메시지를 받았지만, 사용자에게 그대로 보여줄 수 없는 경우.
        NET_SERVER_FAIL: "서버로부터 적절한 응답을 받지 못했습니다. 잠시 후 다시 시도해 주십시요.",
        // 검색어를 입력하지 않고 검색하는 경우
        WRITE_SEARCH_TEXT: "검색할 단어를 입력하세요.",
        // 
        PERMISSION_DENIED: "마이크 장치를 사용할 수 없습니다.\n권한을 확인해 주세요",
        CONFIRM_GOTO_GPS_PERMISSION_SETTING: '경기버스정보 앱은 승차알람, 하차알람 기능 사용 지원을 위해\n 앱이 사용되지 않을 때 \n현재 위치를 항상 사용할 수 있습니다.\n이를 위해 앱 위치정보를 "항상" 사용으로 설정해야 합니다.\n 앱 설정으로 이동하시겠습니까?',
        GPS_DENIED: "사용자 위치를 사용할 수 없습니다.\n권한을 확인해 주세요",
        //
        NO_LOCATION: "사용자 위치를 알 수 없습니다.",
        //
    },
    BOARD: {
        EMPTY: "공지/알림이 존재하지 않습니다",
        NO_MORE_SHOW: "오늘은 더 이상 보지 않기",
    },
    ARRIVAL: {
        // 정상 운행
        SOON: "잠시후 도착",
        TIME: "{n}분",
        NPREV: "{n}번째 전",
        // 
        WAIT_GARAGE: "차고지 대기중",
        WAIT_GARAGE_TURN: "회차지 대기중",
        NO_RUN_BUS: "운행버스 없음",
        // STOPPED: "운행중인 버스가 없습니다",
        STOPPED: "운행 종료",
        // aux
        END_BUS: "막차",
        LOCAL_DELAY: "지체",
        ROUTE_OUT: "노선이탈",
        // trouble    
    },
    STATION: {
        SOON_ARRIVAL: "잠시 후 도착",
        NO_SOON_ARRIVAL: "잠시 후 도착하는 버스가 없습니다.",

    },
    // MSG_NO_FAVOR_FOUND: "등록된 즐겨찾기가 없습니다.",
    FAVOR: {
        // 즐겨찾기가 없을때, 화면에 표시되는 메시지
        GUIDE_WHEN_NO_ITEM: "주변정류소를 찾아보시거나,\n노선 또는 정류소를 검색해서 시작하세요.",
        // 즐겨찾기가 없고, 편집등을 시도할때 팝업으로 보여주는 메시지
        NO_ITEM: "등록된 즐겨찾기가 없습니다.",
        // 추가, 삭제, 확인
        ADDED: "즐겨찾기에 추가되었습니다.",
        ADD_FAILED: "즐겨찾기를 추가할 수 없습니다.",
        REMOVE_CONFIRM: "선택한 항목을 삭제하시겠습니까?",
        REMOVED: "즐겨찾기가 삭제되었습니다.",
        REMOVE_FAILED: "즐겨찾기를 삭제할 수 없습니다.",
        ALREADY_EXIST: "이미 등록되어 있습니다.",
        MEMO_UPDATED: "즐겨찾기 메모가 변경되었습니다.",
        MEMO_UPDATE_FAILED: "즐겨찾기 메모를 변경할 수 없습니다.",
        ORDER_UPDATED: "즐겨찾기 순서가 변경되었습니다.",
        ORDER_UPDATE_FAILED: "즐겨찾기 순서를 변경할 수 없습니다.",
        // 즐겨찾기에 등록된 노선, 정류소가 조회되지 않는 경우
        NO_SERVICE_ENTRY: "등록된 즐겨찾기를 찾을 수 없습니다. 변경된 정보를 확인해 주세요.",
        // 즐겨찾기 편집시 상단에 표시되는 메시지
        EDIT_DRAG_GUIDE: "순서를 옮기려면 행을 길게 누른 후 옮기세요.",
        WIDGET_CONFIG_DONE: "위젯 표시여부가 설정되었습니다.",
        WIDGET_CONFIG_FAILED: "위젯 표시여부를 설정할 수 없습니다.",
        WIDGET_REFRESH_PERIOD_GUIDE: "홈화면의 위젯을 탭하면, {n}분 동안 도착시간을 갱신합니다.",
    },
    GENERAL: {
        // 일반: 검색결과가 없는 경우
        NO_ITEM: "요청하신 정보를 찾을 수 없습니다.",
        LOADING_DATA: "조회중입니다.",
    },
    SEARCH: {
        // 검색창 입력란에 회색으로 표시되는 안내메시지
        PLACEHOLDER: "정류소, 노선을 입력하세요",
        // 검색 결과가 없는 경우 팝업 메시지
        NO_MATCHED_RESULT: "일치하는 검색결과가 없습니다.",
        NO_INPUT: "검색어를 입력해 주십시오.",
    },
    // 인트로화면 나이 선택 항목
    USER_FONT_SIZE: {
        0: "작게",
        1: "중간",
        2: "크게",
    },
    FOREIGN_LANGUAGE: {
        K: '한국어',
        E: 'English',
        C: '中文',
        J: '日本語',
        V: 'Tiếng Việt',
    },
    VOICE: {
        IDLE: "시작하시려면, 마이크 버튼을 눌러주세요",
        // RECORDING: "듣고 있습니다.\n10초 이내 말씀하시고, 마이크 버튼을 눌러주세요.",
        RECORDING: "듣고 있습니다.\n노선번호나 정류소를 말씀하세요.",
        REQUESTING: "음성을 분석중입니다...",
        FAILED: "음성을 감지할 수 없습니다.\n다시 시도해 주세요",
        // 복수의 결과중에서 하나를 선택하는 창, 윗부분 안내 메시지
        SELECTONE: "보다 정확한 검색을 위해\n말씀하신 단어를 선택해 주십시오.",
        UNAVAILABLE: "마이크나 음성장치를 사용할 수 없습니다.",
    },
    BUS: {
        // 노선 운행 종료된 경우, 최근접 차량 표시되는 영역에 나오는 메시지
        RUN_ENDED: "운행이 종료되었습니다.",
        // 운행중이지만, 최근접 차량 2대가 없는 경우
        NO_NEAR_BUS: "도착예정 버스가 없습니다..",
        // 버스 실시간 위치 조회시 버스가 조회되지 않는 경우
        NO_RUN_BUS: "운행중인 버스가 없습니다..",
    },
    // 사용 안함
    OPEN_SOON: {
        OPEN_SOON: "곧 서비스 오픈 예정입니다.",
        PREPARE: "서비스 준비중입니다.",
    },
    FINDPATH: {
        // 출.도착지 없이 즐겨찾기를 시도한 경우
        FAVOR_NOT_READY: "길찾기 검색을 진행한후에 즐겨찾기를 할 수 있습니다.",
        // 출.도착지 없이 지역간 길찾기 누른 경우
        START_END_MISSED: "출발지와 도착지를 입력해 주십시오.",
        START_MISSED: "출발지를 입력해 주십시오.",
        END_MISSED: "도착지를 입력해 주십시오.",
        // 지역간 길찾기. 출.도착지 확정 못하는 경우
        NO_START_END_FOUND: "출발,도착지와 일치하는 지역을 찾을 수 없습니다.",
        // 일반 길찾기 입력란에 표시되는 안내 메시지
        START_POI_HOLDER: "출발지를 입력하세요.",
        END_POI_HOLDER: "도착지를 입력하세요.",     
        // 지역간 길찾기 입력란에 표시되는 안내 메시지   
        START_AREA_HOLDER: "읍/면/동까지 입력해 주세요",
        END_AREA_HOLDER: "예) 봉담읍, 사당동.",
        // 최근 검색한 이력이 없는 경우 목록란에 표시되는 메시지
        NO_RECENT_ITEMS: "최근 검색한 이력이 없습니다.",
        // 경로 검색 결과가 없는 경우
        NO_SEARCH_RESULTS: "출발, 도착지간 대중교통수단을 찾을 수 없습니다.",
    },
    // 주변 정류소, 맵
    AROUND: {
        // 멥에서 정류소가 선택되지 않은 경우 하단 표시 메시지
        GUIDE: "내 주변 500m 이내 정류소를 표시합니다.",
        // 줌레벨이 낮아 정류소가 표시되지 않는 경우 표시되는 메시지
        PLZ_ZOOM_IN: "적절히 지도를 확대하면 정류소가 표시됩니다.",
        // 주변 정류소를 찾을 수 없는 경우
        NO_AROUND_STATION: "주변 50m 이내 정류소를 찾을 수 없습니다.",
    },
    // 검색이력 공통
    HISTORY: {
        REMOVE_CONFIRM: "검색이력을 삭제하시겠습니까?",
        NO_ITEM: "검색이력이 없습니다."
    },
    // 승차벨
    RIDESUPPORT: {
        // 화면 제목
        // 교통약자, 저상버스 선택시 제목
        LOW_BUS_ONLY_TITLE: "교통약자 승차벨 요청",
        ON_ALARM_TITLE: "승차 알람",
        TITLE: "버스도착정보",
        HEADER: "교통약자 승차벨",
        // 하단 옵션 상자에서 저상버스 선택하는 레이블
        LOWBUS_ONLY_GUIDE: "저상버스 요청하기",
        // 화면이 열리고 필요한 정보 조회중에 사용자가 승차벨을 시도한 경우 
        LOCAL_BUSY:  "차량도착정보를 조회중입니다. 잠시후 시도해 주십시오.",
        // 승차벨 요청 완료
        DONE: "승차벨을 요청했습니다.",
        // (알림)하차알림. 시작 정류소에 근접한 정류소를 감지한 경우
        OFFALARM_STARTED: "사용자와 운행버스를 확인하여 하차 알림을 시작합니다.\n알림은 이용에 참고용으로만 사용하세요.",
        OFFALARM_STARTED_NEW: "{st} ~ {ed}",
        // 사용 안함
        NOTIFY_ACCEPTED: "승차벨 정보를 요청했습니다.\n승차 알림을 받으시겠습니까?",
        NOTIFY_ACCEPT_AND_TRANSFERED: "승차벨 정보를 버스에 알렸습니다.\n승차 알림을 받으시겠습니까?",
        // 승차벨 시작 메시지(실제 서버 통신 확인 후)
        NOTIFY_DONE: "승차벨을 요청했습니다.",
        // 승차알람(사용 안함)
        ONALARM_DONE: "승차알림을 설정했습니다.",
        // 하차알림 메시지
        OFFALARM_DONE: "하차알림을 설정했습니다.",
        // 승차벨 요청 실패
        FAILED: "차량단말기와의 통신이 원활하지 않습니다. 다시 시도해 주십시오",
        ERROR:  "승차벨/알림을 요청하는 도중 오류가 발생했습니다.",
        // 승차벨 취소
        CANCELLED:  "승차벨 요청을 취소했습니다.",
        OFFALARM_CANCELLED: "하차알림을 취소했습니다.",
        // 승차벨 하단 옵션창 메시지
        PLZ_SELECT_HANDI: "교통약자 유형을 선택해 주십시오.",
        // 승차벨 허용 노선이 아닌 경우
        NOT_ALLOWED_ROUTE: "시험운영중 일부 노선만 승차벨을 이용하실 수 있습니다.",
        // (사용 안함)
        WANT_ON_ALARM: "\"승차 버스 도착 알림\"을 받으시겠습니까?",
        // 저상버스 선택시
        LOW_BUS_NOT_FOUND: "운행중인 저상버스가 없어 일반버스로 안내합니다.",
        // 저상버스가 2대가 안되는 경우
        LOW_BUS_PARTIALLY: "운행중인 저상버스가 없어 일반버스로 안내합니다.",
        // 정류소에서 너무 먼 경우
        TOO_FAR_FROM_STATION: "승차하실 정류소에 가까이 가셔서 \n요청해 주십시오.",
        // 대상 버스가 너무 가까운 경우
        TOO_CLOSE_BUS: "정류소에 근접한 버스는 요청할 수 없습니다.",
        // 이미 승차벨/하차알림이 진행중인데 새로 요청하는 경우
        CONFIRM_CANCEL: "진행중인 알림을 취소하시고 진행하시겠습니까?",
        // 승차벨 이력화면에서 이력이 없는 경우
        NO_HISTORY: "사용하신 승차벨 이력이 없습니다.",
        // 하차알림 
        OFF_NO_RUNNING_BUS: "운행중인 버스가 없어 알림을 수행할 수 없습니다.",
        OFF_WARN_BUS_DUP: "사용자와 인접한 버스가 많아 탑승하신 버스를 감지하기 어렵습니다.\n알림은 이용에 참고용으로만 사용하세요.",
        // 팝업 메인탭 메뉴
        POPUP_ON_MAIN_BOTTOM_TAP: "시험 운영중입니다.\n시험운영 노선은 공지사항에서 내용 확인이 가능합니다. 참고해 주세요",
        POPUP_ON_ALARM_OFF_BTN: "서비스 시험운영 중입니다.\n시험운영 기간 도출된 문제점을 개선하여 정식 오픈 할 예정입니다.",        
        
    },

    NONSTOP_DECLARE: {
        TITLE: "무정차신고",
        MESSAGE: "해당 무정차 신고는 버스 품질 개선을 위해 버스 경영 및 서비스 평가 등에 적극 반영하겠습니다.",
        REMARK: "그외 버스 불편민원은 하단의 \"콜센터\"를 이용하세요.",
        NONSTOP_DECLARED: "무정차 신고를 완료했습니다.",
        NONSTOP_DECLARE_FAILED: "무정차 신고를 하지 못했습니다.",
        BEFORE_PASSED_STATION: "아직 차량이 정류소 통과 전입니다.",
    },
    GPS: {
        PLZ_ALLOW_PERMISSION: '앱이 위치정보를 사용할 수 있도록 설정해 주십시오.',
    },
    HANDI: {
        NONE: "선택하지 않음",
        HANDI: "장애인",
        OLD: "고령자",
        PREGNANT: "임산부",
        WITHBABY: "영유아동반",
        CHILD: "어린이",
    },
    // 알림 메시지
    BUSALARM: {
        GPS: "위치정보를 사용할 수 없어 알림을 종료합니다.",
        API: "버스도착정보를 조회할 수 없어 알림을 종료합니다.",
        // on alarm
        USER_ESCAPED_FROM_STATION: "승차정류소와 멀어져서 알림을 종료합니다.",
        BUS_NOT_FOUND: "알림 요청하신 버스의 운행이 임의로 중지되어 알림을 종료합니다.",  
        // 접수 -> 조회 -> 버스통보성공
        NOTIFY_TRANSFERED: "승차벨을 버스에 알렸습니다.",
        // 접수 -> 조회 -> 버스통보실패
        NOTIFY_TRANSFER_FAILED: "차량단말기와의 통신이 원활하지 않습니다. 다시 시도해 주십시오.",
        // OFF ALARM
        OFF_RIDE_BUS_NOT_DETECTED: "사용자의 위치와 일치하는 버스를 감지하지 못했습니다.",
        OFF_START_STATION_NOT_DETECTED: "사용자 위치와 일치하는 버스를 감지하지 못했습니다.",
        OFF_USER_ESCAPED_ROUTE_PATH: "사용자가 노선운행경로를 벗어나 알림을 종료합니다.", 
        //
        // 
        TOTAL_TIMEOUT: "시간 초과로 알림을 중지합니다.",
        INTERNAL_ERROR: "죄송합니다. 지금은 알림을 수행할 수 없습니다.",
        //
        ON_ALARM: "{route} 버스가 {n}번째 전 정류소에 왔습니다.",
        ON_ALARM_TIME: "{route} 버스가 {n}분 후 도착 예정입니다.",
        // OFF_ALARM: "\"{offStation}\"까지 {n} 정류소 남았습니다.",
        OFF_ALARM: "{n}번째 전 정류소 도착. 하차 정류소는 \"{offStation}\"입니다.",
        // NOTIFY_END: "승차대기 요청한 버스가 {n}번째 정류소 전입니다.",
        OFF_ALARM_FINISHED: "하차정류소:{offStation}\n하차 준비를 하시기 바랍니다.",
    },
    INTRO: {
        PLZ_SELECT_AGE: "글자크기를 선택하시면 앱의 글자크기가 조정됩니다.",
        REFER_HELP: "※ 자세한 이용방법은 이용안내를 참고하세요."
    },
    PAGETITLE: { // 다국어 서비스를 위한 한글 추출 작업
        AROUND: "주변정류소",
        MESSAGE: "공지사항",
        HOME_DRAW_NAV: "경기버스정보",
        HOME_FAVOR_EDIT: "편집",
        INTRO1: "새로운 경기버스정보",
        BUS_EVAL: "버스 리뷰",
        DEVICE_INFO: "장치정보",
        HELP: "이용안내",
        SETTINGS: "앱 설정",
        SHOW_ALL_ROUTES: "버스유형",
        WIDGET_SETTINGS: "위젯 설정",
        BUS_NOTIFY_HISTORY: "승차벨 요청이력",
        OFF_BUS_SUPPORT: "하차알림",
        RIDE_SUPPORT_STATION: "승차벨 가능 정류소",
        RUN_POP_EVENT: "행사/사고 알림",    
        ROUTE_INFO: "실시간버스위치",
        ROUTE_INFO_DETAIL: "노선운행정보",
        ROUTE_MAP: "노선도",
        SEARCH_MAIN: "검색",
        SEARCH_POI_MAP: "장소",
        STATION_INFO: "정류소",
        STATION_MAP: "정류소",
        FAVOR_TAGLESS_ROUTE: "간편예약",
        FR_MAIN: "길찾기",        
        CHOICE_LANGUAGE: "언어 선택",
        FAVOR_MODIFY: "즐겨찾기 수정",
        PAST_ARRIVALS_HISTORY: '과거 버스운행기록',
    },
    PAGECOMMENT: { // 다국어 서비스를 위한 한글 추출 작업 
        AROUND_EMPTY: "주변정류소가 존재하지 않습니다.",
        ERR_LAUNCHING_MAP: "지도를 불러오는 도중 오류가 발생했습니다.",        
        VOICE_SEARCH_PERMISSION_DENIED: "장치를 사용할 수 없습니다",
        WHATS_NEW_CHANGE: "이렇게 바뀌었습니다.",
        WHATS_NEW_RIDESUPPORT: "탑승할 버스에 승차벨을 전송하세요",
        WHATS_NEW_FAV: "자주 이용하시는 정류소, 노선을 쉽게 즐겨찾기에 등록하실 수 있어요",
        WHATS_NEW_PATHFIND: "정확한 목적지를 모를 때 읍/면/동 이름으로 길찾기를 하실 수 있어요",        
        WIDGET_DESC: '{routeDestName} 방면',
        BUS_EVAL_MSG: '버스평가에 응해주셔서 감사합니다.\n보내주신 소중한 의견은\n더 나은 버스 서비스를 위해 사용하겠습니다.',
        BUS_EVAL_COMMENT: '소중한 의견과 평가를 주시면\n버스 품질 개선에 반영하겠습니다',
        AT_LEAST_ONE: "최소 하나 이상을 선택해야 합니다.",
        CHOICE_ALARM_STATION: "알람을 수행할 정류소가 설정되었습니다.",
        ENABLE_SETTING_DEVELOPER: "개발자용 설정 활성화. 앱을 다시 시작하세요",
        DISABLE_SETTING_DEVELOPER: "개발자용 설정 비활성화. 앱을 다시 시작하세요",        
        WIDGET_MAX_CNT: "최대 {count}개를 위젯에 표시할 수 있습니다.",                
        USED_SERVICE_WITHIN_7DAYS: "최대 7일내 이용하신 승차벨 서비스입니다.",        
        START_DIALER_APP: '콜센터. 선택시 전화걸기 앱으로 전환됩니다.',
        SELECT_RIDING_STATION: "본인이 탑승할 정류소를 선택하세요.",
        N_AROUND_NO_STATION: "{n}m 이내 정류소를 찾을 수 없습니다.",
        NONE_AROUND_STATION: "주변정류소가 존재하지 않습니다.",
        DISABLE_DISTANCE_RIDE_SUPPORT: "승차벨 서비스는 사용자로부터 {n}m 이내 정류소만 가능합니다.",
        MAP_ERROR: "지도를 불러오는 도중 오류가 발생했습니다.",
        NOT_FOUND_LAST_STATION: "종점 정류소를 조회할 수 없습니다.",
        NOT_FOUND_START_STATION: "기점 정류소를 조회할 수 없습니다.",        
        PLEASE_REFER: "이용에 참고 바랍니다",        
        DISPLAY_AROUND_N: "내 주변 500m 이내 정류소를 표시합니다.",
        ZOOM_IN_SEE_STATION: "적절히 지도를 확대하면 정류소가 표시됩니다.",
        ADD_FAVOR_AFTER_FIND_ROAD: "길찾기 검색을 진행한후에 즐겨찾기를 할 수 있습니다!",
        QUESTION_DEL_SEARCH_HISTORY: "검색이력을 삭제하시겠습니까?",
        COMPLETE_DEL: "삭제되었습니다.",
        PLZ_COMMENT: "의견을 적어 주세요",
        PLZ_FAV_MEMO: "즐겨찾기에 기억하기 쉬운 이름을 메모하세요(최대 10자)",
        TEL_BUTTON_CALL: "전화버튼을 누르면 통화하실수 있습니다.",
        NO_ARRIVAL_BUS: "도착예정인 버스가 없습니다.",
        NO_FOREIGN_SERVICE: "해당 페이지는 지도 등 일부 기능이 외국어 지원이 되지 않을 수 있습니다.",
        NO_SEARCH_AROUND_STATION: "주변정류소를 조회할 수 없습니다.",
        APP_SETTING_PERMISSION_POSITION: "[앱설정-권한-위치]에서 설정할 수 있습니다.",
        CANCEL_RIDE_SUPPORT: "진행중인 승차벨을 취소하시겠습니까?",
        CANCEL_AND_NEW_RIDE_SUPPORT: "진행중인 승차벨을 취소하고, 새로 승차벨을 요청하시겠습니까?",
        CANCEL_AND_NEW_RIDE_SUPPORT_ALARM: "진행중인 승차벨/알림을 취소하고\n새로 하차알림을 설정하시겠습니까?",
        SEARCHING_BUS_ARRIVAL_INFO: "버스도착정보를 조회중입니다.",
        PAST_ARRIVALS_HISTORY_GUIDE: [
            {
                color: '#f00',
                msg: '참고로만 활용',
            },
            {
                color: '#000',
                msg: '바람.',
            },
            {
                color: '#000',
                msg: '교통상황에 따라',
            },
        ], // '현재 도착할 정보가 아닌,',
        PAST_ARRIVALS_HISTORY_GUIDE_WARN: [
            {
                color: '#f00',
                msg: '오늘 버스도착시간과',
            },
            {
                color: '#f00',
                msg: '다를 수 있음.',
            },
        ],        
    },
    PAGEWORD: {// 다국어 서비스를 위한 한글 추출 작업 
        // N_BEFORE_STATION: "{n}번째 전",
        ROUTE_DEST_NAME: "{routeDestName} 방면",
        ROUTE_ROW_FIRST_BUS: '첫차', 
        ROUTE_ROW_LAST_BUS: '막차', 
        ALLOCATION_TIME: '배차',
        FONT_SIZE: "글꼴 크기",
        FONT_NORMAL: "보통",
        FONT_LOUDER: "더 크게",
        RIDE_SUPPORT_ALARM_SERVICE: "승차벨 알림 서비스",
        // N_ST_AGO: "{n}}번째 전",
        N_REMAIN_SEAT_CNT: "{n}석",
        ROUTES_TYPE: "버스유형",
        NIGHT_BUS: "심야버스",
        LOW_BUS: "저상버스",
        OUT_BUS: "시외버스",
        AIR_BUS: "공항버스",
        ARRIVAL_INFO_ROUTE: "도착정보제공노선",
        WIDGET_DURATION_TIME: "위젯 도착시간 표시 지속 시간",
        WIDGET_FAVORITY: "위젯에 표시할 즐겨찾기",
        BUS: "버스",
        STATION_NM: "정류소 ",                                            
        ROUTE_NM: "노선",
        PLATE_NO: "차량번호",
        USED_TIME: "이용시각",
        LAST_STATION_NM: "종점정류소",
        TURN_SEQ_STATION_NM: "회차정류소",
        NONE_STOP: "경유",
        LAST_BUS_TIME: "막차시각",
        ALERT: "알림",
        USABLE_TOILET: "화장실 이용 가능",
        REG_FAVOR: "등록된 즐겨찾기",
        UNREG_FAVOR: "등록안된 즐겨찾기",
        MAIN_STOP_STATION: "주요정차정류소",
        WEEKDAY: "평일",        
        WEEKEND: "주말",
        OPERATION_TIME: "운행시간",
        OPERATION_AREA: "운행지역",
        ROUTE_COMPANY: "운수업체",
        ROUTE_GARAGE: "차고지",
        BUS_INTERVAL: "배차간격",
        SEARCH: "검색",
        CANCEL: "취소",
        DEL: "삭제",
        NOW_POS: "현재위치",
        TRAFFIC_ABBREVIATION_SUPPORT: "교통약자 승차 지원",
        ONLY_LOW_BUS: "저상버스만",
        SEARCHING: "탐색중...",
        RIDE: "승차",
        LAST_STATION: "종점",
        START_STATION: "기점",
        TURN_SEQ: "회차",
        DENSITY1: "여유",
        DENSITY2: "보통",
        DENSITY3: "혼잡",
        DENSITY4: "매우혼잡",
        OFF_BUS_BEFORE_STATION: "{n}정류소전",
        BUS_SATISFACTION: "버스 이용 만족도",    
        CALLCENTER: "고객센터",
        INSERT_MEMO: "메모 입력",
        MEMO_EX: "예) 출근버스 또는 퇴근버스",
        CENTER_NM: "경기도 교통정보센터",
        LOADING: "로딩...",
        RIDE_BUS_ALARM: "승차알림",
        FAIL_RIDE_BUS_ALARM: "승차알림실패",
        NEED_PERMISSION_SETTING: "권한설정필요",
        PERMISSION_SETTING: "권한설정",
        OFF_BUS_ALARM: "하차알림",
        FAILED_OFF_BUS_ALARM: "하차알림실패",
        COMPLETE_OFF_BUS_ALARM: "하차알림완료",
        COMPLETE_RIDE_SUPPORT_ALARM: "승차알림완료",
        RECENTLY_USE_RIDE_SUPPORT: "최근 이용하신 승차벨",
        N_MINUTE_LEFT: "{n}분전",
        BUSES: "노선/버스",
        RIGHT_BEFORE: "방금",        
        REPORT_NONSTOP_BUS: "무정차신고",                
        TODAY: "오늘",
        YESTERDAY: "어제",
        THIS_WEEK: "이번주",
        LAST_WEEK: "지난주",
        THIS_MONTH: "이번달",
        LAST_MONTH: "지난달",
        DAY2PREV: '이틀전',
        DAY7PREV: '7일전',
    },
    PAGETABLABEL: { // 다국어 서비스를 위한 한글 추출 작업
        INFO_CIRCLE: "공지사항",
        FILE_ALT: "외부알림",
        CAR_CRASH: "사고소식",        
        HOME: "홈",
        STAR: "즐겨찾기",
        STAR_RIDESUPPORT: "즐겨찾기",
        LOCATION: "주변정류소",
        DIRECTIONS: "길찾기",
        COMMENT: "공지",
        HELP_RIDE: "승차벨",
        MAP_MARKER: "출/도착지 지정",
        MAP_MARKER_MY: "현재위치",
        MAPM: "지도",
        INFO: "정보", 
        ALARM: "시간알람",
        ALARM_EXCLAMATION: "경고알람",
        EXCLAMATION: "경고",
        COG: "설정",
        CLIPBOARD_LIST_CHECK: "버스평가",
        PHONE: "전화",
        HISTORY: "이력",
        PAPER_PLANE: "전송",
        RESERVATION: "버스예약",
        PRINT_SEARCH: "예약확인",
        BACK: "뒤로",
        FAVOR: "즐겨찾기",
        MY_LOATION: "현재위치",
        SHOW_MARKER_MAP: "지도에서 선택",
        OFF_BUS_ALARM: "하차알림",
        OFF_BUS_ALARM_RIDESUPPORT: "하차알림",
        EVAL_BUS: "버스리뷰",
        CALL_CENTER: "콜센터",
        ROUTE_MAP: "노선도",
        ROUTE_INFO: "노선정보",
        FIND_ROAD: "바로길찾기",
        SHOW_PAST_ARRIVALS: '과거운행기록 보기',
        OK: "확인",
        CANCEL: "취소",
        CLOSE: "닫기",
        FAV_EMPTY_AROUND: "주변정류소 보기",
        FAV_EMPTY_SEARCH: "노선, 정류소 검색하기",  
        NORMAL: "일반",
        DIRECT: "직행좌석",
        SEAT: "좌석",
        TOWN: "마을",
        OUT: "시외",
        AIRPORT: "공항",
        NORMAL_ST: "일반",
        DIRECT_ST: "직좌",
        SEAT_ST: "좌석",
        TOWN_ST: "마을",
        OUT_ST: "시외",
        AIRPORT_ST: "공항",
        TROLY: "트롤리",
        TWO: "2층",
        LOW: "저상",
        JEONSE: "전세", 
        GAPYEONG_GUN: "가평군",
        GOYANG_SI: "고양시",
        GWACHEON_SI: "과천시",
        GWANGMYEONG_SI: "광명시",
        GWANGJU_SI: "광주시",
        GURI_SI: "구리시",
        GUNPO_SI: "군포시",
        GIMPO_SI: "김포시",
        NAMYANGJU_SI: "남양주시",
        DONGDUCHEON_SI: "동두천시",
        BUCHEON_SI: "부천시",
        SEONGNAM_SI: "성남시",
        SUWON_SI: "수원시",
        SIHEUNG_SI: "시흥시",
        ANSAN_SI: "안산시",
        ANSEONG_SI: "안성시",
        ANYANG_SI: "안양시",
        YANGJU_SI: "양주시",
        YANGPYEONG_GUN: "양평군",
        YEOJU_SI: "여주시",
        YEONCHEON_GUN: "연천군",
        OSAN_SI: "오산시",
        YONGIN_SI: "용인시",
        UIWANG_SI: "의왕시",
        UIJEONGBU_SI: "의정부시",
        ICHEON_SI: "이천시",
        PAJU_SI: "파주시",
        PYEONGTAEK_SI: "평택시",
        POCHEON_SI: "포천시",
        HANAM_SI: "하남시",
        HWASEONG_SI: "화성시",
        GAPYEONG: "가평",
        GOYANG: "고양",
        GWACHEON: "과천",
        GWANGMYEONG: "광명",
        GWANGJU: "광주",
        GURI: "구리",
        GUNPO: "군포",
        GIMPO: "김포",
        NAMYANGJU: "남양주",
        DONGDUCHEON: "동두천",
        BUCHEON: "부천",
        SEONGNAM: "성남",
        SUWON: "수원",
        SIHEUNG: "시흥",
        ANSAN: "안산",
        ANSEONG: "안성",
        ANYANG: "안양",
        YANGJU: "양주",
        YANGPYEONG: "양평",
        YEOJU: "여주",
        YEONCHEON: "연천",
        OSAN: "오산",
        YONGIN: "용인",
        UIWANG: "의왕",
        UIJEONGBU: "의정부",
        ICHEON: "이천",
        PAJU: "파주",
        PYEONGTAEK: "평택",
        POCHEON: "포천",
        HANAM: "하남",
        HWASEONG: "화성", 
        SEOUL: "서울",
        SEOUL_SI: "서울특별시",
        INCHEON: "인천",
        INCHEON_SI: "인천광역시",        
        ROUTE_TP_13: "일반형시내버스",
        ROUTE_TP_11: "직행좌석형시내버스",
        ROUTE_TP_14: "광역급행형시내버스",
        ROUTE_TP_43: "일반형시외버스",
        ROUTE_TP_51: "리무진형 공항버스",
        ROUTE_TP_30: "마을버스",
        ROUTE_TP_16: "경기외곽순환버스",
        ROUTE_TP_15: "따복형시내버스",
        ROUTE_TP_53: "일반형 공항버스",
        ROUTE_TP_23: "일반형농어촌버스",
        ROUTE_TP_12: "좌석형시내버스",
        ROUTE_TP_21: "직행좌석형농어촌버스",
        GYEONGGI_: "경기도 ",

        
    },
    MOBILEWEBONLY: {
        SEE_MOBILE_APP: "더 편리한 모바일 앱으로 보기",
        SUPPORT_ANDROID_AND_IPHONE: "안드로이드 또는 iOS 기기를 지원합니다.",
        FUNCTION_ONLY_MOBILE_APP: "모바일앱에서만 지원하는 기능입니다.",
        IS_GOTO_PLAYSTORE: "플레이스토어로 이동하시겠습니까?",
        IS_GOTO_APPSTORE: "앱스토어로 이동하시겠습니까?",
        LOADING: "로딩...",
    },
}





 const TextEN = {
// 영어 English
    ERROR: {
        UNKNOWN: "An unknown error has occurred.",
        // 네트워크 오류
        NET_FAIL: "Network error has occurred. Please check the communication state.",
        // 네트워크는 OK, 서버에서 오류 메시지를 받았지만, 사용자에게 그대로 보여줄 수 없는 경우.
        NET_SERVER_FAIL: "We haven't received appropriate response from the server. Please try again later.",
        // 검색어를 입력하지 않고 검색하는 경우
        WRITE_SEARCH_TEXT: "Please enter the word to be searched.",
        // 
        PERMISSION_DENIED: "The microphone device is not available.\n Please check the authority.",
        CONFIRM_GOTO_GPS_PERMISSION_SETTING: 'The Gyeonggi Bus Information app can always use the current location when the app is not used to support the use of ride and get off alarm functions. You must set the app location information as use "Always." Would you like to go to the app settings?',
        GPS_DENIED: "The user location is not available.\n Please check the authority.",
        //
        NO_LOCATION: "사용자 위치를 알 수 없습니다.",
        //
    },
    BOARD: {
        EMPTY: "No announcements/notifications available.",
        NO_MORE_SHOW: "I won't see it today anymore",
    },
    ARRIVAL: {
        // 정상 운행
        SOON: "Arrive soon",
        TIME: "{n} minute(s)",
        NPREV: "Before {n} stop(s)",
        // 
        WAIT_GARAGE: "Waiting in the garage",
        WAIT_GARAGE_TURN: "Waiting in the turning point",
        NO_RUN_BUS: "No operation bus available",
        // STOPPED: "운행중인 버스가 없습니다",
        STOPPED: "End of operation",
        // aux
        END_BUS: "Last bus",
        LOCAL_DELAY: "Delayed",
        ROUTE_OUT: "Out of route",
        // trouble    
    },
    STATION: {
        SOON_ARRIVAL: "Arrive soon",
        NO_SOON_ARRIVAL: "There is no bus which will be arriving soon.",

    },
    // MSG_NO_FAVOR_FOUND: "등록된 즐겨찾기가 없습니다.",
    FAVOR: {
        // 즐겨찾기가 없을때, 화면에 표시되는 메시지
        GUIDE_WHEN_NO_ITEM: "Please look for stops nearby or search for buses or stops to get started.",
        // 즐겨찾기가 없고, 편집등을 시도할때 팝업으로 보여주는 메시지
        NO_ITEM: "There is no registered favorites.",
        // 추가, 삭제, 확인
        ADDED: "It has been added to the favorites.",
        ADD_FAILED: "The addition of the favorites is not available.",
        REMOVE_CONFIRM: "Are you sure to delete the selected item(s)?",
        REMOVED: "It has been deleted from the favorites.",
        REMOVE_FAILED: "Your favorites cannot be deleted.",
        ALREADY_EXIST: "It is already registered.",
        MEMO_UPDATED: "The memo of the favorites has been changed.",
        MEMO_UPDATE_FAILED: "The memo of the favorites cannot be changed.",
        ORDER_UPDATED: "The order of the favorites has been changed.",
        ORDER_UPDATE_FAILED: "The order of the favorites cannot be changed.",
        // 즐겨찾기에 등록된 노선, 정류소가 조회되지 않는 경우
        NO_SERVICE_ENTRY: "The registered favorites cannot be found. Please check the changed information.",
        // 즐겨찾기 편집시 상단에 표시되는 메시지
        EDIT_DRAG_GUIDE: "To move the order, please press and hold the row and move it.",
        WIDGET_CONFIG_DONE: "Whether it is displayed on Widget or not has been set.",
        WIDGET_CONFIG_FAILED: "Whether it is displayed on Widget or not cannot be set.",
        WIDGET_REFRESH_PERIOD_GUIDE: "When you tap the widget on the home screen, the arrival time will be updated for {n} minutes.",
    },
    GENERAL: {
        // 일반: 검색결과가 없는 경우
        NO_ITEM: "Your requested information cannot be found.",
        LOADING_DATA: "Being inquired",
    },
    SEARCH: {
        // 검색창 입력란에 회색으로 표시되는 안내메시지
        PLACEHOLDER: "Please enter the stop and the bus.",
        // 검색 결과가 없는 경우 팝업 메시지
        NO_MATCHED_RESULT: "There is no corresponding result.",
        NO_INPUT: "Please enter a search word.",
    },
    // 인트로화면 나이 선택 항목
    USER_FONT_SIZE: {
        0: "Smaller",
        1: "Intermediate",
        2: "Larger",
    },
    FOREIGN_LANGUAGE: {
        K: '한국어',
        E: 'English',
        C: '中文',
        J: '日本語',
        V: 'Tiếng Việt',
    },
    VOICE: {
        IDLE: "Please press the microphone button to start.",
        // RECORDING: "듣고 있습니다.\n10초 이내 말씀하시고, 마이크 버튼을 눌러주세요.",
        RECORDING: "Listening Please tell the bus number or the stop.",
        REQUESTING: "Analyzing the voice...",
        FAILED: "Voice cannot be detected. Please try it again.",
        // 복수의 결과중에서 하나를 선택하는 창, 윗부분 안내 메시지
        SELECTONE: "Please select the word you mentioned for more accurate search.",
        UNAVAILABLE: "The microphone or the voice device is not available.",
    },
    BUS: {
        // 노선 운행 종료된 경우, 최근접 차량 표시되는 영역에 나오는 메시지
        RUN_ENDED: "The operation has been terminated.",
        // 운행중이지만, 최근접 차량 2대가 없는 경우
        NO_NEAR_BUS: "There is no bus to be arrived.",
        // 버스 실시간 위치 조회시 버스가 조회되지 않는 경우
        NO_RUN_BUS: "There is no bus in operation.",
    },
    // 사용 안함
    OPEN_SOON: {
        OPEN_SOON: "The service will open soon.",
        PREPARE: "Service is being prepared.",
    },
    FINDPATH: {
        // 출.도착지 없이 즐겨찾기를 시도한 경우
        FAVOR_NOT_READY: "You can add to the favorites after carrying out “finding routes”.",
        // 출.도착지 없이 지역간 길찾기 누른 경우
        START_END_MISSED: "Please enter the departure and the destination.",
        START_MISSED: "Please enter the departure.",
        END_MISSED: "Please enter the destination.",
        // 지역간 길찾기. 출.도착지 확정 못하는 경우
        NO_START_END_FOUND: "Regions corresponding to the departure and the destination cannot be found.",
        // 일반 길찾기 입력란에 표시되는 안내 메시지
        START_POI_HOLDER: "Please enter the departure.",
        END_POI_HOLDER: "Please enter the destination.",     
        // 지역간 길찾기 입력란에 표시되는 안내 메시지   
        START_AREA_HOLDER: "Please enter town/township/neighborhood.",
        END_AREA_HOLDER: "예) 봉담읍, 사당동.",
        // 최근 검색한 이력이 없는 경우 목록란에 표시되는 메시지
        NO_RECENT_ITEMS: "There is no recent search history.",
        // 경로 검색 결과가 없는 경우
        NO_SEARCH_RESULTS: "Public transportation between departure and destination cannot be found.",
    },
    // 주변 정류소, 맵
    AROUND: {
        // 멥에서 정류소가 선택되지 않은 경우 하단 표시 메시지
        GUIDE: "Stops within 500 m of your surroundings\n will be displayed.",
        // 줌레벨이 낮아 정류소가 표시되지 않는 경우 표시되는 메시지
        PLZ_ZOOM_IN: "If you enlarge the map appropriately, the stop is displayed.",
        // 주변 정류소를 찾을 수 없는 경우
        NO_AROUND_STATION: "Stops within 50 m of the surroundings cannot be found.",
    },
    // 검색이력 공통
    HISTORY: {
        REMOVE_CONFIRM: "Do you want to delete the search history?",
        NO_ITEM: "There is no search history."
    },
    // 승차벨
    RIDESUPPORT: {
        // 화면 제목
        // 교통약자, 저상버스 선택시 제목
        LOW_BUS_ONLY_TITLE: "Request boarding bell for the \ntransportation vulnerable",
        ON_ALARM_TITLE: "Boarding alarm",
        TITLE: "Bus arrival information",
        HEADER: "Boarding bell for \ntransportation vulnerable",
        // 하단 옵션 상자에서 저상버스 선택하는 레이블
        LOWBUS_ONLY_GUIDE: "Request kneeling bus",
        // 화면이 열리고 필요한 정보 조회중에 사용자가 승차벨을 시도한 경우 
        LOCAL_BUSY:  "The arrival information of the vehicle is being inquired. Please try it later.",
        // 승차벨 요청 완료
        DONE: "The boarding bell was requested.",
        // (알림)하차알림. 시작 정류소에 근접한 정류소를 감지한 경우
        OFFALARM_STARTED: "Check the user and the operation bus to start the get off notification. Please use the notification only for reference.",
        OFFALARM_STARTED_NEW: "{st} ~ {ed}",
        // 사용 안함
        NOTIFY_ACCEPTED: "The boarding bell information has been requested.\n Do you want to receive the boarding notification?",
        NOTIFY_ACCEPT_AND_TRANSFERED: "The information on the boarding bell has been notified to the bus.\n Do you want to receive the boarding notification?",
        // 승차벨 시작 메시지(실제 서버 통신 확인 후)
        NOTIFY_DONE: "The boarding bell was requested.",
        // 승차알람(사용 안함)
        ONALARM_DONE: "The notification to get on has been set.",
        // 하차알림 메시지
        OFFALARM_DONE: "The get off notification has been set.",
        // 승차벨 요청 실패
        FAILED: "Communication with the vehicle terminal is not smooth. Please try it again.",
        ERROR:  "An error occurred while requesting the boarding bell/notification.",
        // 승차벨 취소
        CANCELLED:  "The boarding bell request has been canceled.",
        OFFALARM_CANCELLED: "The get off notification has been canceled.",
        // 승차벨 하단 옵션창 메시지
        PLZ_SELECT_HANDI: "Please select the type of the transportation vulnerable.",
        // 승차벨 허용 노선이 아닌 경우
        NOT_ALLOWED_ROUTE: "The boarding bell is available only for some buses during test operation.",
        // (사용 안함)
        WANT_ON_ALARM: "Do you want to receive \"boarding bus arrival notification\"?",
        // 저상버스 선택시
        LOW_BUS_NOT_FOUND: "You are guided to the regular bus because there is no kneeling bus in operation.",
        // 저상버스가 2대가 안되는 경우
        LOW_BUS_PARTIALLY: "You are guided to the regular bus because there is no kneeling bus in operation.",
        // 정류소에서 너무 먼 경우
        TOO_FAR_FROM_STATION: "Please go close to the stop where you will get on and make a request.",
        // 대상 버스가 너무 가까운 경우
        TOO_CLOSE_BUS: "Buses which are close to the stop cannot be requested.",
        // 이미 승차벨/하차알림이 진행중인데 새로 요청하는 경우
        CONFIRM_CANCEL: "Do you want to cancel the ongoing notification?",
        // 승차벨 이력화면에서 이력이 없는 경우
        NO_HISTORY: "There is no history of the boarding bell used.",
        // 하차알림 
        OFF_NO_RUNNING_BUS: "The notification cannot be carried out because there is no bus in operation.",
        OFF_WARN_BUS_DUP: "There are many buses adjacent to the user, so it is difficult to detect the bus you are taking. Please use the notification only for reference.",
        // 팝업 메인탭 메뉴
        POPUP_ON_MAIN_BOTTOM_TAP: "The service is being tested.\n You can check the details of the buses which are in operation for test in the notice. Please keep it in mind.",
        POPUP_ON_ALARM_OFF_BTN: "The service is being tested. It is to be opened officially after improving the problems identified during the trial operation period ",        
        
    },

    NONSTOP_DECLARE: {
        TITLE: "Non-stop bus report",
        MESSAGE: "The relevant non-stop bus report will be actively reflected in bus management and service evaluation to improve bus quality. ",
        REMARK: "For other inconvenient bus complaints, please use the \"call center\" below.",
        NONSTOP_DECLARED: "The non-stop bus report has been done.",
        NONSTOP_DECLARE_FAILED: "The non-stop bus report has been failed.",
        BEFORE_PASSED_STATION: "The bus hasn't passed the stop yet.",
    },
    GPS: {
        PLZ_ALLOW_PERMISSION: 'Please set the app to use location information "at all times".',
    },
    HANDI: {
        NONE: "Not \nselected",
        HANDI: "Disabled \npeople",
        OLD: "Elderlies",
        PREGNANT: "Pregnant \nwomen",
        WITHBABY: "With infants \nand children",
        CHILD: "Children",
    },
    // 알림 메시지
    BUSALARM: {
        GPS: "The notification is terminated because the location information is not available.",
        API: "The notification is terminated because the bus arrival information cannot be inquired.",
        // on alarm
        USER_ESCAPED_FROM_STATION: "The notification is terminated because you move away from the stop to get on.",
        BUS_NOT_FOUND: "The operation of the bus you requested for notification is arbitrarily stopped and the notification is terminated.",  
        // 접수 -> 조회 -> 버스통보성공
        NOTIFY_TRANSFERED: "The boarding bell has been notified to the bus.",
        // 접수 -> 조회 -> 버스통보실패
        NOTIFY_TRANSFER_FAILED: "Communication with the vehicle terminal is not smooth. Please try it again.",
        // OFF ALARM
        OFF_RIDE_BUS_NOT_DETECTED: "The bus corresponding to the user location has not been detected.",
        OFF_START_STATION_NOT_DETECTED: "The bus corresponding to the user location has not been detected.",
        OFF_USER_ESCAPED_ROUTE_PATH: "The user gets out of path and the notification is terminated.", 
        //
        // 
        TOTAL_TIMEOUT: "Stop notification due to the timeout.",
        INTERNAL_ERROR: "Sorry for inconvenience. The notification is currently not available.",
        //
        ON_ALARM: "The bus {route} arrived at the stop before {n} stops.",
        ON_ALARM_TIME: "{route} bus is scheduled to arrive in {n} minutes.",        

        // OFF_ALARM: "\"{offStation}\"까지 {n} 정류소 남았습니다.",
        OFF_ALARM: "Arrived at the stop before {n} stop(s) The stop to get off is \"{offStation}\".",
        // NOTIFYD: "승차대기 요청한 버스가 {n}번째 정류소 전입니다.",
        OFF_ALARM_FINISHED: "Stop to get off: {offStation} Please be ready to get off.",
    },
    INTRO: {
        PLZ_SELECT_AGE: "If you select the font size, the font size of the app will be adjusted.",
        REFER_HELP: "※ Please see the usage information for the detailed method of use."
    },
    PAGETITLE: { // 다국어 서비스를 위한 한글 추출 작업
        AROUND: "Stops nearby",
        MESSAGE: "Notice",
        HOME_DRAW_NAV: "Gyeonggi bus information",
        HOME_FAVOR_EDIT: "Edit",
        INTRO1: "New Gyeonggi bus information",
        BUS_EVAL: "Bus review",
        DEVICE_INFO: "Device information",
        HELP: "Usage information",
        SETTINGS: "App settings",
        SHOW_ALL_ROUTES: "Type of bus",
        WIDGET_SETTINGS: "Widget settings",
        BUS_NOTIFY_HISTORY: "View histories\n  of the boarding bell request",
        // OFF_BUS_SUPPORT: "Get off notification",
        OFF_BUS_SUPPORT: "Get off notification",
        RIDE_SUPPORT_STATION: "Stops where the boarding bell is available",
        RUN_POP_EVENT: "Events/Accidents notification ",    
        ROUTE_INFO: "Real-time bus location",
        ROUTE_INFO_DETAIL: "Bus operation information",
        ROUTE_MAP: "Bus map",
        SEARCH_MAIN: "Search",
        SEARCH_POI_MAP: "Place",
        STATION_INFO: "Bus stops",
        STATION_MAP: "Bus stops",
        FAVOR_TAGLESS_ROUTE: "Simple reservation",
        FR_MAIN: "Find routes",
        CHOICE_LANGUAGE: "Language",
        FAVOR_MODIFY: "Change in favorites",
        PAST_ARRIVALS_HISTORY: 'Past bus arrivals',
    },
    PAGECOMMENT: { // 다국어 서비스를 위한 한글 추출 작업 
        AROUND_EMPTY: "There is no stops nearby.",
        ERR_LAUNCHING_MAP: "An error occurred while loading the map.",        
        VOICE_SEARCH_PERMISSION_DENIED: "The device is not available.",
        WHATS_NEW_CHANGE: "It has been changed like this.",
        WHATS_NEW_RIDESUPPORT: "Please send the boarding bell to the bus to get on.",
        WHATS_NEW_FAV: "You can easily register bus stops and buses where you frequently use on your favorites.",
        WHATS_NEW_PATHFIND: "If you don't know the exact destination, you can find routes with the name of town/township/neighborhood.",        
        WIDGET_DESC: 'Toward {routeDestName}',
        BUS_EVAL_MSG: 'Thank you for responding to the bus evaluation.\n Your opinions will be used for better bus service.',
        BUS_EVAL_COMMENT: 'Please give us your opinions and evaluations and we will reflect these for the improvement of the bus quality.',
        AT_LEAST_ONE: "You must select at least 1 item.",
        CHOICE_ALARM_STATION: "The stop has been set to carry out the alarm.",
        ENABLE_SETTING_DEVELOPER: "개발자용 설정 활성화. 앱을 다시 시작하세요",
        DISABLE_SETTING_DEVELOPER: "개발자용 설정 비활성화. 앱을 다시 시작하세요",        
        WIDGET_MAX_CNT: "Up to {count} can be displayed on the Widget.",                
        USED_SERVICE_WITHIN_7DAYS: "These are boarding bell services which you used for up to 7 days.",        
        START_DIALER_APP: 'Call center. If selected, it is switched to the dialing app.',
        SELECT_RIDING_STATION: "Please select the stop at \nwhich you want to get on.",
        N_AROUND_NO_STATION: " There are no stops within {n}m.",
        NONE_AROUND_STATION: "There is no stops nearby.",
        DISABLE_DISTANCE_RIDE_SUPPORT: "The boarding bell service is only available at stops within {n} m from the user.",
        MAP_ERROR: "An error occurred while loading the map.",
        NOT_FOUND_LAST_STATION: "The last stop cannot be inquired.",
        NOT_FOUND_START_STATION: "The starting stop cannot be inquired.",        
        PLEASE_REFER: "Please refer it for the use.",
        DISPLAY_AROUND_N: "Stops within 500 m of your surroundings will be displayed.",
        ZOOM_IN_SEE_STATION: "If you enlarge the map appropriately, the stop is displayed.",
        ADD_FAVOR_AFTER_FIND_ROAD: "You can add to the favorites after carrying out “finding routes”.",
        QUESTION_DEL_SEARCH_HISTORY: "Do you want to delete the search history?",
        COMPLETE_DEL: "It has been deleted.",
        PLZ_COMMENT: "Please write down your opinions.",
        PLZ_FAV_MEMO: "Please write down names that are easy to remember on your favorites.(Up to 10 characters)",
        TEL_BUTTON_CALL: "You can make a call by pressing the phone button.",
        NO_ARRIVAL_BUS: "There is no bus to be arrived.",
        NO_FOREIGN_SERVICE: "Some functions including maps, may be supported in foreign languages on this page.",
        NO_SEARCH_AROUND_STATION: "There is no stops nearby.",
        APP_SETTING_PERMISSION_POSITION: "[ App Settings - Authority - Location].",
        CANCEL_RIDE_SUPPORT: "Do you want to cancel the ongoing boarding bell?",
        CANCEL_AND_NEW_RIDE_SUPPORT: "Would you like to cancel the ongoing ride bell and request a new one?",
        CANCEL_AND_NEW_RIDE_SUPPORT_ALARM: "Do you want to cancel the ongoing boarding bell/notification and set a new get off notification?",
        SEARCHING_BUS_ARRIVAL_INFO: "Searching the bus arrival information",
        PAST_ARRIVALS_HISTORY_GUIDE: 'This is not the estimated time of arrival.',
        PAST_ARRIVALS_HISTORY_GUIDE_WARN: 'Please use it only as a reference.',
    },
    PAGEWORD: {// 다국어 서비스를 위한 한글 추출 작업 
        // N_BEFORE_STATION: "Before {n} stop(s)",
        ROUTE_DEST_NAME: "{routeDestName}(Toward)",
        ROUTE_ROW_FIRST_BUS: 'First bus', 
        ROUTE_ROW_LAST_BUS: 'Last bus', 
        ALLOCATION_TIME: 'Allocation',
        FONT_SIZE: "Font size",
        FONT_NORMAL: "Normal",
        FONT_LOUDER: "Larger",
        RIDE_SUPPORT_ALARM_SERVICE: "Boarding bell notification service",
        // N_ST_AGO: "Before {n} stop(s)",
        N_REMAIN_SEAT_CNT: "{n} seats",
        ROUTES_TYPE: "Type of bus",
        NIGHT_BUS: "Afterhour bus",
        LOW_BUS: "Kneeling bus",
        OUT_BUS: "Intercity bus",
        AIR_BUS: "Airport bus",
        ARRIVAL_INFO_ROUTE: "Buses providing arrival information",
        WIDGET_DURATION_TIME: "Duration for displaying arrival time on Widget",
        WIDGET_FAVORITY: "Favorites to be displayed on Widget",
        BUS: "Bus",
        STATION_NM: "Bus stops",
        ROUTE_NM: "Buses",
        PLATE_NO: "Bus number",
        USED_TIME: "Time to use",
        LAST_STATION_NM: "Last stop",
        TURN_SEQ_STATION_NM: "Turning stop",
        NONE_STOP: "Stopover",
        LAST_BUS_TIME: "Last bus time",
        ALERT: "Notification",
        USABLE_TOILET: "Restroom available",
        REG_FAVOR: "Registered favorites",
        UNREG_FAVOR: "Not registered favorites",
        MAIN_STOP_STATION: "Major stops",
        WEEKDAY: "Weekdays",        
        WEEKEND: "Weekends",
        OPERATION_TIME: "Operation time",
        OPERATION_AREA: "Major operation regions",
        ROUTE_COMPANY: "Bus company",
        ROUTE_GARAGE: "Bus garage",
        BUS_INTERVAL: "Interval between buses",
        SEARCH: "Search",
        CANCEL: "Cancel",
        DEL: "Delete",
        NOW_POS: "Current location",
        TRAFFIC_ABBREVIATION_SUPPORT: "Riding support for the transportation vulnerable",
        ONLY_LOW_BUS: "Kneeling bus only",
        SEARCHING: "Searching...",
        RIDE: "Boarding",
        LAST_STATION: "Last stop",
        START_STATION: "Starting stop",
        TURN_SEQ: "Turning",
        DENSITY1: "Many\nseats",
        DENSITY2: "Normal",
        DENSITY3: "Crowded",
        DENSITY4: "Very\ncrowded",
        OFF_BUS_BEFORE_STATION: "Before\n{n} stop(s)",
        BUS_SATISFACTION: "Satisfaction of bus",
        CALLCENTER: "Customer center",
        INSERT_MEMO: "Type memo",
        MEMO_EX: "Ex) Bus to work or bus to home",
        CENTER_NM: "Gyeonggi Traffic Information Center",
        LOADING: "Loading...",
        RIDE_BUS_ALARM: "Boarding alarm",
        FAIL_RIDE_BUS_ALARM: "Ride notification failed",
        NEED_PERMISSION_SETTING: "Need to set the authority.",
        PERMISSION_SETTING: "Authority settings",
        OFF_BUS_ALARM: "Get off notification",
        FAILED_OFF_BUS_ALARM: "Get off notification has been failed",
        COMPLETE_OFF_BUS_ALARM: "Get off notification has been done",
        COMPLETE_RIDE_SUPPORT_ALARM: "Ride notification completed",    
        N_MINUTE_LEFT: "{n} minutes left",
        RECENTLY_USE_RIDE_SUPPORT: "Boarding bell recently used (right before)",
        RIGHT_BEFORE: "right before",
        BUSES: "Buses",
        REPORT_NONSTOP_BUS: "Report non-stop bus",
        TODAY: "Today",
        YESTERDAY: "Yesterday",
        THIS_WEEK: "This week",
        LAST_WEEK: "Last week",
        THIS_MONTH: "This month",
        LAST_MONTH: "Last month",
        DAY2PREV: '2 days ago',
        DAY7PREV: '7 days ago',
    },
    PAGETABLABEL: { // 다국어 서비스를 위한 한글 추출 작업
        INFO_CIRCLE: "Notice",
        FILE_ALT: "External notification",
        CAR_CRASH: "Accident news",        
        HOME: "Home",
        STAR: "Favorites",
        STAR_RIDESUPPORT: "Favor",
        // STAR: "Favorites",
        LOCATION: "Stops nearby",
        DIRECTIONS: "Find routes",
        COMMENT: "Notice",
        HELP_RIDE: "Boarding bell",
        MAP_MARKER: "Designation of departure/destination",
        MAP_MARKER_MY: "Current location",
        MAPM: "Map",
        INFO: "Information", 
        ALARM: "Time alarm",
        ALARM_EXCLAMATION: "Warning alarm",
        EXCLAMATION: "Warning",
        COG: "Settings",
        CLIPBOARD_LIST_CHECK: "Bus evaluation",
        PHONE: "Phone",
        HISTORY: "Histories",
        PAPER_PLANE: "Send",
        RESERVATION: "Bus reservation",
        PRINT_SEARCH: "Confirm reservation",
        BACK: "Back",
        FAVOR: "Favorites",
        MY_LOATION: "Current location",
        SHOW_MARKER_MAP: "Select on map",
        OFF_BUS_ALARM: "Get off notification",
        OFF_BUS_ALARM_RIDESUPPORT: "Get off notific…",
        EVAL_BUS: "Bus review",
        CALL_CENTER: "Call center",
        ROUTE_MAP: "Bus map",
        ROUTE_INFO: "Bus information",
        FIND_ROAD: "Find routes immediately",
        OK: "Confirm",
        CANCEL: "Cancel",
        CLOSE: "Close",
        FAV_EMPTY_AROUND: "View stops nearby",
        FAV_EMPTY_SEARCH: "Search buses and stops",  
        NORMAL: "Regular",
        DIRECT: "Non-stop express city",
        SEAT: "Express city",
        // DIRECT: "Express",
        // SEAT: "Express",
        TOWN: "Town",
        OUT: "Intercity",
        AIRPORT: "Airport",
        NORMAL_ST: "Regular",
        DIRECT_ST: "Express",
        SEAT_ST: "Express",
        TOWN_ST: "Town",
        OUT_ST: "Intercity",
        AIRPORT_ST: "공항",
        TROLY: "Trolley",
        TWO: "Double decker",
        LOW: "Kneeling bus",
        JEONSE: "Chartered", 
        GAPYEONG_GUN: "Gapyeong-gun",
        GOYANG_SI: "Goyang-si",
        GWACHEON_SI: "Gwacheon-si",
        GWANGMYEONG_SI: "Gwangmyeong-si",
        GWANGJU_SI: "Gwangju-si",
        GURI_SI: "Guri-si",
        GUNPO_SI: "Gunpo-si",
        GIMPO_SI: "Gimpo-si",
        NAMYANGJU_SI: "Namyangju-si",
        DONGDUCHEON_SI: "Dongducheon-si",
        BUCHEON_SI: "Bucheon-si",
        SEONGNAM_SI: "Seongnam-si",
        SUWON_SI: "Suwon-si",
        SIHEUNG_SI: "Siheung-si",
        ANSAN_SI: "Ansan-si",
        ANSEONG_SI: "Anseong-si",
        ANYANG_SI: "Anyang-si",
        YANGJU_SI: "Yangju-si",
        YANGPYEONG_GUN: "Yangpyeong-gun",
        YEOJU_SI: "Yeoju-si",
        YEONCHEON_GUN: "Yeoncheon-gun",
        OSAN_SI: "Osan-si",
        YONGIN_SI: "Yongin-si",
        UIWANG_SI: "Uiwang-si",
        UIJEONGBU_SI: "Uijeongbu-si",
        ICHEON_SI: "Icheon-si",
        PAJU_SI: "Paju-si",
        PYEONGTAEK_SI: "Pyeongtaek-si",
        POCHEON_SI: "Pocheon-si",
        HANAM_SI: "Hanam-si",
        HWASEONG_SI: "Hwaseong-si",
        GAPYEONG: "Gapyeong",
        GOYANG: "Goyang",
        GWACHEON: "Gwacheon",
        GWANGMYEONG: "Gwangmyeong",
        GWANGJU: "Gwangju",
        GURI: "Guri",
        GUNPO: "Gunpo",
        GIMPO: "Gimpo",
        NAMYANGJU: "Namyangju",
        DONGDUCHEON: "Dongducheon",
        BUCHEON: "Bucheon",
        SEONGNAM: "Seongnam",
        SUWON: "Suwon",
        SIHEUNG: "Siheung",
        ANSAN: "Ansan",
        ANSEONG: "Anseong",
        ANYANG: "Anyang",
        YANGJU: "Yangju",
        YANGPYEONG: "Yangpyeong",
        YEOJU: "Yeoju",
        YEONCHEON: "Yeoncheon",
        OSAN: "Osan",
        YONGIN: "Yongin",
        UIWANG: "Uiwang",
        UIJEONGBU: "Uijeongbu",
        ICHEON: "Icheon",
        PAJU: "Paju",
        PYEONGTAEK: "Pyeongtaek",
        POCHEON: "Pocheon",
        HANAM: "Hanam",
        HWASEONG: "Hwaseong",
        SEOUL: "Seoul",
        SEOUL_SI: "Seoul-si",
        INCHEON: "Incheon",
        INCHEON_SI: "Incheon-si",
        ROUTE_TP_13: "Regular city bus",
        ROUTE_TP_11: "Non-stop express city bus",
        ROUTE_TP_14: "Metropolitan city bus",
        ROUTE_TP_43: "Regular intercity bus",
        ROUTE_TP_51: "Airport limousine bus",
        ROUTE_TP_30: "Shuttle bus",
        ROUTE_TP_16: "Outer circular bus in Gyeonggi Province",
        ROUTE_TP_15: "Customized city bus",
        ROUTE_TP_53: "General airport bus",
        ROUTE_TP_23: "General rural bus",
        ROUTE_TP_12: "Express city bus",
        ROUTE_TP_21: "Direct rural bus",
        GYEONGGI_: "Gyeonggi ",        
    },
    MOBILEWEBONLY: {
        SEE_MOBILE_APP: "View it with a mobile app in a more convenient way.",
        SUPPORT_ANDROID_AND_IPHONE: "Supported for Android or iOS devices.",
        FUNCTION_ONLY_MOBILE_APP: "This function is supported via mobile app only.",
        IS_GOTO_PLAYSTORE: "Would you like to move to the Play Store?",
        IS_GOTO_APPSTORE: "Would you like to move to the App Store?",
        LOADING: "Loading...",
    },
};

// 중국어 中文 China
const TextCN  = {
    ERROR: {
        UNKNOWN: "发生了未知错误。",	
        NET_FAIL: "发生网络错误。请确认通信状态。",	
        NET_SERVER_FAIL: "未能从服务器接收到适当响应。请稍后再试。",	
        WRITE_SEARCH_TEXT: "请输入搜索词。",	
        PERMISSION_DENIED: "无法使用麦克风装置。请确认权限",	
        CONFIRM_GOTO_GPS_PERMISSION_SETTING: '为提供乘车提醒、下车提醒功能，京畿巴士信息APP在不使用时也始终使用当前位置。因此需要将APP的位置信息设置为“始终”使用。是否移动至APP设置？',
        GPS_DENIED: "无法使用用户位置。请确认权限",	
        NO_LOCATION: "无法获知用户位置。",	
    },
    BOARD: {
        EMPTY: "不存在通知/提醒。",	
        NO_MORE_SHOW: "今日不再查看",	
    },
    ARRIVAL: {
        SOON: "稍后到达",	
        TIME: "{n}分钟",	
        NPREV: "{n}站前",	
        WAIT_GARAGE: "车库待发车",	
        WAIT_GARAGE_TURN: "折返点待发车",	
        NO_RUN_BUS: "无运行巴士",	
        STOPPED: "运行结束",	
        END_BUS: "末班车",	
        LOCAL_DELAY: "延迟",	
        ROUTE_OUT: "偏离路线",
        },
    STATION: {
        SOON_ARRIVAL: "稍后到达",	
        NO_SOON_ARRIVAL: "没有稍后到达的巴士。",
        },
    FAVOR: {
        GUIDE_WHEN_NO_ITEM: "请查看周边车站，或者搜索路线或车站后开始",	
        NO_ITEM: "没有已注册的收藏夹。",	
        ADDED: "已添加到收藏夹。",	
        ADD_FAILED: "无法添加收藏夹。",	
        REMOVE_CONFIRM: "是否删除已选项？",	
        REMOVED: "已删除收藏夹。",	
        REMOVE_FAILED: "无法删除收藏夹。",	
        ALREADY_EXIST: "已注册。",	
        MEMO_UPDATED: "收藏夹便签已更改。",	
        MEMO_UPDATE_FAILED: "无法更改收藏夹便签。",	
        ORDER_UPDATED: "收藏夹顺序已更改",	
        ORDER_UPDATE_FAILED: "无法更改收藏夹顺序。",	
        NO_SERVICE_ENTRY: "找不到已注册的收藏夹。请确认已变更的信息。",	
        EDIT_DRAG_GUIDE: "若要移动顺序，请长按行后移动。",	
        WIDGET_CONFIG_DONE: "已设置是否显示插件。",	
        WIDGET_CONFIG_FAILED: "无法设置插件显示与否。",	
        WIDGET_REFRESH_PERIOD_GUIDE: "点击主页上的插件，即可更新{n}分钟内的到达时间。",
        },
    GENERAL: {
        NO_ITEM: "无法找到请求的信息。",	
        LOADING_DATA: "正在查询。",
    },
    SEARCH: {
        PLACEHOLDER: "请输入车站、路线",	
        NO_MATCHED_RESULT: "没有匹配的搜索结果。",	
        NO_INPUT: "请输入搜索词。",
    },
    USER_FONT_SIZE: {   
        	0: "缩小",	
            1: "中间",	
            2: "放大",
    },    
    FOREIGN_LANGUAGE: {
        K: '한국어',
        E: 'English',
        C: '中文',
        J: '日本語',
        V: 'Tiếng Việt',
    },
    VOICE: {
        IDLE: "若需开始，请按麦克风按钮",	
        RECORDING: "在听。请告知路线编号或车站",	
        REQUESTING: "正在分析语音...",	
        FAILED: "无法感知语音。请再试一次",	
        SELECTONE: "请选择所说的词汇，以便进行更准确的搜索。",	
        UNAVAILABLE: "无法使用麦克风或语音装置。",
        },
    BUS: {
        RUN_ENDED: "已结束运行。",	
        NO_NEAR_BUS: "无预计到达巴士。",	
        NO_RUN_BUS: "无运行中巴士",
        },
    OPEN_SOON: {
        OPEN_SOON: "即将开放服务。",	
        PREPARE: "服务正在准备中。",
        },
    FINDPATH: {
        FAVOR_NOT_READY: "进行查找路线搜索后，可使用收藏夹。",	
        START_END_MISSED: "请输入出发地和到达地。",	
        START_MISSED: "请输入出发地。",	
        END_MISSED: "请输入到达地。",	
        NO_START_END_FOUND: "无法查询到与出发、到达地一致的地区。",	
        START_POI_HOLDER: "请输入出发地。",	
        END_POI_HOLDER: "请输入到达地。",	
        START_AREA_HOLDER: "请输入至邑/面/洞",	
        END_AREA_HOLDER: "예) 봉담읍, 사당동.",	
        NO_RECENT_ITEMS: "最近无搜索记录。",	
        NO_SEARCH_RESULTS: "无法查询到出发、到达地之间的公共交通工具。",
        },
    AROUND: {
        GUIDE: "显示我周围500米以内的车站。",	
        PLZ_ZOOM_IN: "适当将地图放大后可显示车站。",	
        NO_AROUND_STATION: "周边50米内找不到车站。",
        },
    HISTORY: {
        REMOVE_CONFIRM: "是否删除搜索记录？",	
        NO_ITEM: "无搜索记录。",
        },
    RIDESUPPORT: {
        LOW_BUS_ONLY_TITLE: "交通弱者乘车铃请求",	
        ON_ALARM_TITLE: "乘车提醒",	
        TITLE: "巴士到达信息",	
        HEADER: "交通弱者乘车铃",	
        LOWBUS_ONLY_GUIDE: "请求搭乘低地台巴士",	
        LOCAL_BUSY: "正在查询车辆到达信息。请稍后再试。",	
        DONE: "已请求乘车铃",	
        OFFALARM_STARTED: "确认用户和运行巴士后开启下车提醒。提醒请仅用于参考。",	
        OFFALARM_STARTED_NEW: "{st} ~ {ed}",	
        NOTIFY_ACCEPTED: "已请求乘车铃信息。是否接收乘车提醒？",	
        NOTIFY_ACCEPT_AND_TRANSFERED: "已将乘车铃信息通知给巴士。是否接收乘车提醒？",	
        NOTIFY_DONE: "已请求乘车铃",	
        ONALARM_DONE: "已设置乘车提醒。",	
        OFFALARM_DONE: "已设置下车提醒。",	
        FAILED: "与车辆终端通信不畅。请再试一次。",	
        ERROR: "请求乘车铃/提醒时发生错误。",	
        CANCELLED: "已取消乘车铃请求。",	
        OFFALARM_CANCELLED: "已取消下车提醒。",	
        PLZ_SELECT_HANDI: "请选择交通弱者类型",	
        NOT_ALLOWED_ROUTE: "试运行中仅部分路线可使用乘车铃。",	
        WANT_ON_ALARM: "是否接收“乘车巴士到达提醒”？",	
        LOW_BUS_NOT_FOUND: "无运行中低地台巴士，将显示普通巴士。",	
        LOW_BUS_PARTIALLY: "无运行中低地台巴士，将显示普通巴士。",	
        TOO_FAR_FROM_STATION: "请靠近要乘车的车站后进行请求。",	
        TOO_CLOSE_BUS: "无法请求靠近车站的巴士。",	
        CONFIRM_CANCEL: "是否要取消正在进行中的提醒？",	
        NO_HISTORY: "无乘车铃使用记录。",	
        OFF_NO_RUNNING_BUS: "无运行中巴士，无法进行提醒。",	
        OFF_WARN_BUS_DUP: "用户附近巴士较多，难以感知所乘巴士。提醒请仅用于参考。",	
        POPUP_ON_MAIN_BOTTOM_TAP: "试运行中。可在公告事项中确认试运行路线信息。敬请参考",	
        POPUP_ON_ALARM_OFF_BTN: "服务试运行中。计划改善试运行期间出现的问题后正式开放。 ",
        },
    NONSTOP_DECLARE: {
        TITLE: "不停车举报",	
        MESSAGE: "为改善巴士服务质量，该不停车举报将积极反映在巴士经营及服务评价等方面。 ",	
        REMARK: "其他巴士不便投诉请利用下端的“呼叫中心”。",	
        NONSTOP_DECLARED: "已完成不停车举报。",	
        NONSTOP_DECLARE_FAILED: "未能进行不停车举报。",	
        BEFORE_PASSED_STATION: "车辆尚未通过车站。",
        },
    GPS: {
        PLZ_ALLOW_PERMISSION: "请将APP设置为可以“经常”使用位置信息。",
        },
    HANDI: {
        NONE: "未选择",	
        HANDI: "残疾人",	
        OLD: "老年人",	
        PREGNANT: "孕妇",	
        WITHBABY: "婴幼儿同行",	
        CHILD: "儿童",
        },
    BUSALARM: {
        GPS: "无法使用位置信息，将结束提醒。",	
        API: "无法查询巴士到达信息，将结束提醒。",	
        USER_ESCAPED_FROM_STATION: "远离乘车站，将结束提醒。",	
        BUS_NOT_FOUND: "请求提醒的巴士已停止运行，将结束提醒。 ",	
        NOTIFY_TRANSFERED: "已将乘车铃通知给巴士。",	
        NOTIFY_TRANSFER_FAILED: "与车辆终端通信不畅。请再试一次。",	
        OFF_RIDE_BUS_NOT_DETECTED: "无法感知到与用户位置相符的巴士。",	
        OFF_START_STATION_NOT_DETECTED: "无法感知到与用户位置相符的巴士。",	
        OFF_USER_ESCAPED_ROUTE_PATH: "用户偏离路线运行路径，将结束提醒。",	
        TOTAL_TIMEOUT: "因超时停止提醒。",	
        INTERNAL_ERROR: "非常抱歉。现在无法进行提醒。",	
        ON_ALARM: "{route}巴士已到达{n}站前。",	
        ON_ALARM_TIME: "{n}分钟后，{route}巴士即将到站。",
        
	
        OFF_ALARM: "到达{n}站前。下车车站是“{offStation}”。",	
        OFF_ALARM_FINISHED: "下车站：请做好{offStation}下车准备。",
        },
    INTRO: {
        PLZ_SELECT_AGE: "选择字体大小时，将调整APP字体大小。",	
        REFER_HELP: "※ 具体使用方法请参考使用指南。",
        },
    PAGETITLE: { // 다국어 서비스를 위한 한글 추출 작업		        
        AROUND: "周边车站",	
        MESSAGE: "通知",	
        HOME_DRAW_NAV: "京畿巴士信息",	
        HOME_FAVOR_EDIT: "编辑",	
        INTRO1: "新京畿巴士信息",	
        BUS_EVAL: "巴士评论",	
        DEVICE_INFO: "停车信息",	
        HELP: "利用指南",	
        SETTINGS: "APP设置",	
        SHOW_ALL_ROUTES: "巴士类型",	
        WIDGET_SETTINGS: "插件设置",	
        BUS_NOTIFY_HISTORY: "查看乘车铃请求记录",	
        OFF_BUS_SUPPORT: "下车提醒",	
        RIDE_SUPPORT_STATION: "可按乘车铃的车站",	
        RUN_POP_EVENT: "活动/事故提醒 ",	
        ROUTE_INFO: "实时巴士位置",	
        ROUTE_INFO_DETAIL: "路线运行信息",	
        ROUTE_MAP: "路线图",	
        SEARCH_MAIN: "搜索",	
        SEARCH_POI_MAP: "场所",	
        STATION_INFO: "车站",	
        STATION_MAP: "车站",	
        FAVOR_TAGLESS_ROUTE: "便捷预约",	
        FR_MAIN: "查找路线",
        CHOICE_LANGUAGE: "语言",
        FAVOR_MODIFY: "修改收藏夹",
        PAST_ARRIVALS_HISTORY: '过去的巴士到达时间',
        },
    PAGECOMMENT: { // 다국어 서비스를 위한 한글 추출 작업 		        
        AROUND_EMPTY: "周边无车站。",	
        ERR_LAUNCHING_MAP: "导入地图时出现错误。  ",	
        VOICE_SEARCH_PERMISSION_DENIED: "无法使用装置",	
        WHATS_NEW_CHANGE: "已如此变更。",	
        WHATS_NEW_RIDESUPPORT: "请将乘车铃发送至要乘坐的巴士。",	
        WHATS_NEW_FAV: "可将经常使用的车站、路线方便地添加至收藏夹。",	
        WHATS_NEW_PATHFIND: "如果不知道准确的目的地，可以利用邑/面/洞的名称查找路线 ",	
        WIDGET_DESC: "{routeDestName}方向",	
        BUS_EVAL_MSG: "谢谢您对巴士做出评价。您给予的宝贵意见将被用于更好的巴士服务。",	
        BUS_EVAL_COMMENT: "您给予的宝贵意见和评价将会反映到巴士质量改善上。",	
        AT_LEAST_ONE: "应至少选择一个以上。",	
        CHOICE_ALARM_STATION: "已设定进行提醒的车站。",	
        ENABLE_SETTING_DEVELOPER: "개발자용 설정 활성화. 앱을 다시 시작하세요",
        DISABLE_SETTING_DEVELOPER: "개발자용 설정 비활성화. 앱을 다시 시작하세요",	
        WIDGET_MAX_CNT: "最多可在插件中显示{count}个。 ",	
        USED_SERVICE_WITHIN_7DAYS: "最长7日内使用的乘车铃服务。 ",	
        START_DIALER_APP: "呼叫中心选择时，将会切换到拨号APP。",	
        SELECT_RIDING_STATION: "请选择本人要乘车的车站。",	
        N_AROUND_NO_STATION: "{n}米以内找不到车站。",	
        NONE_AROUND_STATION: "周边无车站。",	
        DISABLE_DISTANCE_RIDE_SUPPORT: "乘车铃服务仅限离用户{n}m以内的车站。",	
        MAP_ERROR: "导入地图时出现错误。  ",	
        NOT_FOUND_LAST_STATION: "无法查询终点车站。",	
        NOT_FOUND_START_STATION: "无法查询始发站。 ",	
        PLEASE_REFER: "请在使用时用于参考 ",	
        DISPLAY_AROUND_N: "显示我周围500米以内的车站。",	
        ZOOM_IN_SEE_STATION: "适当将地图放大后可显示车站。",	
        ADD_FAVOR_AFTER_FIND_ROAD: "进行查找路线搜索后，可使用收藏夹。",	
        QUESTION_DEL_SEARCH_HISTORY: "是否删除搜索记录？",	
        COMPLETE_DEL: "已删除。",
        PLZ_COMMENT: "请写下意见。",
        PLZ_FAV_MEMO: "请在收藏夹中写下容易记住的名称(最多10个字符)",
        TEL_BUTTON_CALL: "按电话按钮可进行通话。",
        NO_ARRIVAL_BUS: "无预计到达巴士。",
        NO_FOREIGN_SERVICE: "该页面的部分功能，例如地图等，可能不支持外语。",
        NO_SEARCH_AROUND_STATION: "周边无车站。",
        APP_SETTING_PERMISSION_POSITION: "可在[应用设置-权限-位置]中设置。",
        CANCEL_RIDE_SUPPORT: "是否取消正在进行的乘车铃？",
        CANCEL_AND_NEW_RIDE_SUPPORT: "是否取消正在进行中的乘车铃，并重新申请乘车铃？",
        CANCEL_AND_NEW_RIDE_SUPPORT_ALARM: "是否取消正在进行中的乘车铃/提醒，并重新设置下车提醒？",
        SEARCHING_BUS_ARRIVAL_INFO: "正在查询巴士到达信息。",
        PAST_ARRIVALS_HISTORY_GUIDE: '这不是预计的到达时间。',
        PAST_ARRIVALS_HISTORY_GUIDE_WARN: '请仅作为参考使用',
        },
    PAGEWORD: {// 다국어 서비스를 위한 한글 추출 작업 		        
        // N_BEFORE_STATION: "{n}站前",	
        ROUTE_DEST_NAME: "{routeDestName}方向",	
        ROUTE_ROW_FIRST_BUS : "首班车",	
        ROUTE_ROW_LAST_BUS: "末班车",	
        ALLOCATION_TIME: "发车",	
        FONT_SIZE: "字体大小",	
        FONT_NORMAL: "一般",	
        FONT_LOUDER: "更大",	
        RIDE_SUPPORT_ALARM_SERVICE: "乘车铃提醒服务",	
        // N_ST_AGO: "{n}站前",	
        N_REMAIN_SEAT_CNT: "{n}席",
        ROUTES_TYPE: "巴士类型",	
        NIGHT_BUS: "夜间巴士",	
        LOW_BUS: "低地台巴士",	
        OUT_BUS: "市外巴士",	
        AIR_BUS: "机场巴士",	
        ARRIVAL_INFO_ROUTE: "到达信息提供路线",	
        WIDGET_DURATION_TIME: "插件到达时间显示持续时间",	
        WIDGET_FAVORITY: "插件中显示的收藏夹",	
        BUS: "巴士",	
        STATION_NM: "车站",	
        ROUTE_NM: "路线",	
        PLATE_NO: "车辆号码",	
        USED_TIME: "利用时间",	
        LAST_STATION_NM: "终点车站",	
        TURN_SEQ_STATION_NM: "折返车站",	
        NONE_STOP: "经由",	
        LAST_BUS_TIME: "末班车时间",	
        ALERT: "提醒",	
        USABLE_TOILET: "可利用洗手间",	
        REG_FAVOR: "注册的收藏夹",	
        UNREG_FAVOR: "未注册的收藏夹",	
        MAIN_STOP_STATION: "主要停车站",	
        WEEKDAY: "工作日",	
        WEEKEND: "周末",	
        OPERATION_TIME: "运行时间",	
        OPERATION_AREA: "主要运行地区",	
        ROUTE_COMPANY: "运输公司",	
        ROUTE_GARAGE: "车库",	
        BUS_INTERVAL: "发车间隔",	
        SEARCH: "搜索",	
        CANCEL: "取消",	
        DEL: "删除",	
        NOW_POS: "现位置",	
        TRAFFIC_ABBREVIATION_SUPPORT: "交通弱者乘车支援",	
        ONLY_LOW_BUS: "仅限低地台巴士",	
        SEARCHING: "搜索中...",	
        RIDE: "乘车",	
        LAST_STATION: "终点",	
        START_STATION: "始发站",	
        TURN_SEQ: "折返",
        DENSITY1: "宽松",
        DENSITY2: "一般",
        DENSITY3: "拥挤",
        DENSITY4: "非常拥挤",
        OFF_BUS_BEFORE_STATION: "{n}站前",
        BUS_SATISFACTION: "巴士利用满意度",
        CALLCENTER: "客服中心",
        INSERT_MEMO: "输入便签",
        MEMO_EX: "例)上班巴士或下班巴士",
        CENTER_NM: "京畿道交通信息中心",
        LOADING: "加载中...",
        RIDE_BUS_ALARM: "乘车提醒",
        FAIL_RIDE_BUS_ALARM: "乘车提醒失败",
        NEED_PERMISSION_SETTING: "需要设置权限",
        PERMISSION_SETTING: "权限设置",
        OFF_BUS_ALARM: "下车提醒",
        FAILED_OFF_BUS_ALARM: "下车提醒失败",
        COMPLETE_OFF_BUS_ALARM: "下车提醒结束",
        COMPLETE_RIDE_SUPPORT_ALARM: "已完成乘车提醒",
        N_MINUTE_LEFT: "{n}分钟前",
        RECENTLY_USE_RIDE_SUPPORT: "最近使用的乘车铃",
        RIGHT_BEFORE: "刚才",
        BUSES: "路线",
        REPORT_NONSTOP_BUS: "不停车举报",        
        TODAY: "今天",
        YESTERDAY: "昨天",
        THIS_WEEK: "本周",
        LAST_WEEK: "上周",
        THIS_MONTH: "本月",
        LAST_MONTH: "上个月",
        DAY2PREV: '两天前',
        DAY7PREV: '7天前',
        },
    PAGETABLABEL: { // 다국어 서비스를 위한 한글 추출 작업		        
        INFO_CIRCLE: "通知",	
        FILE_ALT: "外部提醒",	
        CAR_CRASH: "事故信息 ",	
        HOME: "主页",	
        STAR: "收藏夹",	
        STAR_RIDESUPPORT: "收藏夹",	
        LOCATION: "周边车站",	
        DIRECTIONS: "查找路线",	
        COMMENT: "通知",	
        HELP_RIDE: "乘车铃",	
        MAP_MARKER: "指定出发地/到达地",	
        MAP_MARKER_MY: "现位置",	
        MAPM: "地图",	
        INFO: "信息 ",	
        ALARM: "时间提醒",	
        ALARM_EXCLAMATION: "警告提醒",	
        EXCLAMATION: "警告",	
        COG: "设置",	
        CLIPBOARD_LIST_CHECK: "巴士评价",	
        PHONE: "电话",	
        HISTORY: "记录",	
        PAPER_PLANE: "传输",	
        RESERVATION: "巴士预约",	
        PRINT_SEARCH: "预约确认",	
        BACK: "返回",	
        FAVOR: "收藏夹",	
        MY_LOATION: "现位置",	
        SHOW_MARKER_MAP: "在地图上选择",	
        OFF_BUS_ALARM: "下车提醒",	
        OFF_BUS_ALARM_RIDESUPPORT: "下车提醒",	
        EVAL_BUS: "巴士评论",	
        CALL_CENTER: "呼叫中心",	
        ROUTE_MAP: "路线图",	
        ROUTE_INFO: "路线信息",	
        FIND_ROAD: "立即查找路线",	
        OK: "确认",	
        CANCEL: "取消",	
        CLOSE: "关闭",	
        FAV_EMPTY_AROUND: "查看周边车站",	
        FAV_EMPTY_SEARCH: "路线、车站搜索",	
        NORMAL: "普通",	
        DIRECT: "直达座席",	
        SEAT: "座席",	
        TOWN: "社区",	
        OUT: "市外",	
        AIRPORT: "机场",
        NORMAL_ST: "普通",
        DIRECT_ST: "直座",
        SEAT_ST: "座席",
        TOWN_ST: "社区",
        OUT_ST: "市外",
        AIRPORT_ST: "机场",	
        TROLY: "无轨电车",	
        TWO: "双层",	
        LOW: "低地台",	
        JEONSE: "包租",
        GAPYEONG_GUN: "加平郡",
        GOYANG_SI: "高阳市",
        GWACHEON_SI: "果川市",
        GWANGMYEONG_SI: "光明市",
        GWANGJU_SI: "广州市",
        GURI_SI: "九里市",
        GUNPO_SI: "军浦市",
        GIMPO_SI: "金浦市",
        NAMYANGJU_SI: "南杨州市",
        DONGDUCHEON_SI: "东豆川市",
        BUCHEON_SI: "富川市",
        SEONGNAM_SI: "城南市",
        SUWON_SI: "水原市",
        SIHEUNG_SI: "始兴市",
        ANSAN_SI: "安山市",
        ANSEONG_SI: "安城市",
        ANYANG_SI: "安养市",
        YANGJU_SI: "杨州市",
        YANGPYEONG_GUN: "杨平郡",
        YEOJU_SI: "骊州市",
        YEONCHEON_GUN: "涟川郡",
        OSAN_SI: "乌山市",
        YONGIN_SI: "龙仁市",
        UIWANG_SI: "义王市",
        UIJEONGBU_SI: "议政府市",
        ICHEON_SI: "利川市",
        PAJU_SI: "坡州市",
        PYEONGTAEK_SI: "平泽市",
        POCHEON_SI: "抱川市",
        HANAM_SI: "河南市",
        HWASEONG_SI: "华城市",
        GAPYEONG: "加平",
        GOYANG: "高阳",
        GWACHEON: "果川",
        GWANGMYEONG: "光明",
        GWANGJU: "광주_CN",
        GURI: "九里",
        GUNPO: "军浦",
        GIMPO: "金浦",
        NAMYANGJU: "南杨州",
        DONGDUCHEON: "东豆川",
        BUCHEON: "富川",
        SEONGNAM: "城南",
        SUWON: "水原",
        SIHEUNG: "始兴",
        ANSAN: "安山",
        ANSEONG: "安城",
        ANYANG: "安养",
        YANGJU: "杨州",
        YANGPYEONG: "杨平",
        YEOJU: "骊州",
        YEONCHEON: "涟川",
        OSAN: "乌山",
        YONGIN: "龙仁",
        UIWANG: "义王",
        UIJEONGBU: "议政府",
        ICHEON: "利川",
        PAJU: "坡州",
        PYEONGTAEK: "平泽",
        POCHEON: "抱川",
        HANAM: "河南",
        HWASEONG: "华城",
        SEOUL: "首尔",
        SEOUL_SI: "首尔特别市",
        INCHEON: "仁川",
        INCHEON_SI: "仁川市",
        ROUTE_TP_13: "普通型市内巴士",
        ROUTE_TP_11: "直达座席型市内巴士",
        ROUTE_TP_14: "广域快速型市内巴士",
        ROUTE_TP_43: "普通型市外巴士",
        ROUTE_TP_51: "豪华型机场巴士",
        ROUTE_TP_30: "社区巴士",
        ROUTE_TP_16: "京畿外廓循环巴士",
        ROUTE_TP_15: "定制型市内巴士",
        ROUTE_TP_53: "普通型机场巴士",
        ROUTE_TP_23: "普通型农渔村巴士",
        ROUTE_TP_12: "座席型市内巴士",
        ROUTE_TP_21: "直达座席型农渔村巴士",
        GYEONGGI_: "京畿道 ",

        },
        MOBILEWEBONLY: {
            SEE_MOBILE_APP: "使用更方便的移动APP查看",
            SUPPORT_ANDROID_AND_IPHONE: "支持安卓或iOS设备。",
            FUNCTION_ONLY_MOBILE_APP: "此功能只能在移动APP使用。",
            IS_GOTO_PLAYSTORE: "是否移动至Play Store？",
            IS_GOTO_APPSTORE: "是否移动至App Store",
            LOADING: "加载中...",
        },
};






// 일본어 日本語 Japanese
const TextJP = {
    ERROR: {
        UNKNOWN: "不明なエラーが発生しました。",
                NET_FAIL: "ネットワークエラーが発生しました。通信状態を確認してください。",
                NET_SERVER_FAIL: "サーバーから適切な応答を受けることができませんでした。しばらくしてからもう一度お試しください。",
                WRITE_SEARCH_TEXT: "検索する単語を入力してください。",
                PERMISSION_DENIED: "マイク装置が使用できません。権限を確認してください。",
                CONFIRM_GOTO_GPS_PERMISSION_SETTING: '京畿バス情報アプリは、乗車アラームと降車アラーム機能の使用に対応するために、アプリを使用しないときでも現在地を常に使用できるようになっています。そのためには、アプリの位置情報を『常に』使用に設定する必要があります。アプリ設定に移動しますか？',
                GPS_DENIED: "ユーザーの位置が使用できません。権限を確認してください。",
    NO_LOCATION: "ユーザーの位置が分かりません。",
        },
                BOARD: {
        EMPTY: "お知らせ/通知が存在しません。",
    NO_MORE_SHOW: "今日はもう表示しない",
        },
    ARRIVAL: {
        SOON: "間もなく到着",
                TIME: "{n}分",
                NPREV: "{n}つ前",
                WAIT_GARAGE: "車庫で待機中",
                WAIT_GARAGE_TURN: "起終点で待機中",
                NO_RUN_BUS: "運行バスなし",
                STOPPED: "運行終了",
                END_BUS: "終発",
                LOCAL_DELAY: "遅滞",
                ROUTE_OUT: "路線離脱",
        },
    STATION: {
        SOON_ARRIVAL: "間もなく到着",
        NO_SOON_ARRIVAL: "すぐに到着するバスがありません。",
        },
    FAVOR: {
        GUIDE_WHEN_NO_ITEM: "周辺の停留所を探すか、路線または停留所を検索して始めてください。",
                NO_ITEM: "登録されたお気に入りがありません。",
                ADDED: "お気に入りに追加されました。",
                ADD_FAILED: "お気に入りを追加できません。",
                REMOVE_CONFIRM: "選択した項目を削除しますか？",
                REMOVED: "お気に入りが削除されました。",
                REMOVE_FAILED: "お気に入りを削除できません。",
                ALREADY_EXIST: "既に登録されています。",
                MEMO_UPDATED: "お気に入りメモが変更されました。",
                MEMO_UPDATE_FAILED: "お気に入りメモを変更できません。",
                ORDER_UPDATED: "お気に入りの順番が変更されました。",
                ORDER_UPDATE_FAILED: "お気に入りの順番を変更できません。",
                NO_SERVICE_ENTRY: "登録されたお気に入りが見つかりません。変更された情報を確認してください。",
                EDIT_DRAG_GUIDE: "順番を移動する場合は、行を長押ししてから移動してください。",
                WIDGET_CONFIG_DONE: "ウィジェットを表示するかどうかが設定されました。",
                WIDGET_CONFIG_FAILED: "ウィジェットを表示するかどうかを設定できません。",
                WIDGET_REFRESH_PERIOD_GUIDE: "ホーム画面のウィジェットをタップすると、{n}分間到着時間を更新します。",
        },
    GENERAL: {
        NO_ITEM: "リクエストされた情報が見つかりません。",
                LOADING_DATA: "照会中です。",
        },
    SEARCH: {
        PLACEHOLDER: "停留所、路線を入力してください。",
    NO_MATCHED_RESULT: "一致する検索結果はありません。",
                NO_INPUT: "検索ワードを入力してください。",
        },
    USER_FONT_SIZE: {
        0: "小さく",
                        1: "中間",
                        2: "大きく",
        },
    FOREIGN_LANGUAGE: {
            K: '한국어',
            E: 'English',
            C: '中文',
            J: '日本語',
            V: 'Tiếng Việt',
        },    
    VOICE: {
        IDLE: "開始するには、マイクボタンを押してください。",
            RECORDING: "聞いています。路線番号や停留所を話してください。",
            REQUESTING: "音声を分析中です···",
            FAILED: "音声を感知できません。もう一度やり直してください。",
            SELECTONE: "より正確な検索のため、話された単語を選択してください。",
            UNAVAILABLE: "マイクや音声装置が使用できません。",
        },
    BUS: {
        RUN_ENDED: "運行が終了しました。",
            NO_NEAR_BUS: "到着予定のバスがありません。",
            NO_RUN_BUS: "運行中のバスがありません。",
        },
    OPEN_SOON: {
        OPEN_SOON: "間もなくサービスをオープンする予定です。",
            PREPARE: "サービス準備中です。",
        },
    FINDPATH: {
        FAVOR_NOT_READY: "経路検索を行った後に、お気に入りにすることができます。",
            START_END_MISSED: "出発地と到着地を入力してください。",
            START_MISSED: "出発地を入力してください。",
            END_MISSED: "到着地を入力してください。",
            NO_START_END_FOUND: "出発、到着地と一致する地域が見つかりません。",
            START_POI_HOLDER: "出発地を入力してください。",
            END_POI_HOLDER: "到着地を入力してください。",
            START_AREA_HOLDER: "邑/面/洞まで入力してください。",
            END_AREA_HOLDER: "예) 봉담읍, 사당동.",
            NO_RECENT_ITEMS: "最近検索した履歴がありません。",
            NO_SEARCH_RESULTS: "出発、到着地間の公共交通機関が見つかりません。",
        },
    AROUND: {
        GUIDE: "周辺500m以内の停留所を表示します。",
            PLZ_ZOOM_IN: "地図を適切に拡大すると、停留所が表示されます。",
            NO_AROUND_STATION: "周辺50m以内の停留所が見つかりません。",
        },
    HISTORY: {
        REMOVE_CONFIRM: "検索履歴を削除しますか?",
        NO_ITEM: "検索履歴がありません。",
        },
    RIDESUPPORT: {
        LOW_BUS_ONLY_TITLE: "交通弱者乗車ベルのリクエスト",
            ON_ALARM_TITLE: "乗車アラーム",
            TITLE: "バス到着情報",
            HEADER: "交通弱者乗車ベル",
            LOWBUS_ONLY_GUIDE: "低床バスをリクエストする",
            LOCAL_BUSY: "車両到着情報を照会中です。しばらくしてからお試しください。",
            DONE: "乗車ベルをリクエストしました。",
            OFFALARM_STARTED: "ユーザーと運行バスを確認し、降車通知を開始します。通知は参考用としてのみご使用ください。",
            OFFALARM_STARTED_NEW: "{st} ~ {ed}",
            NOTIFY_ACCEPTED: "乗車ベルの情報をリクエストしました。乗車通知を受けますか？",
            NOTIFY_ACCEPT_AND_TRANSFERED: "乗車ベル情報をバスに知らせました。乗車通知を受けますか？",
            NOTIFY_DONE: "乗車ベルをリクエストしました。",
            ONALARM_DONE: "乗車通知を設定しました。",
            OFFALARM_DONE: "降車通知を設定しました。",
            FAILED: "車両端末との通信が円滑ではありません。もう一度やり直してください。",
            ERROR: "乗車ベル/通知のリクエスト中にエラーが発生しました。",
            CANCELLED: "乗車ベルのリクエストをキャンセルしました。",
            OFFALARM_CANCELLED: "降車通知をキャンセルしました。",
            PLZ_SELECT_HANDI: "交通弱者のタイプを選択してください。",
            NOT_ALLOWED_ROUTE: "試験運用中は、一部路線のみ乗車ベルをご利用いただけます。",
            WANT_ON_ALARM: "『乗車バス到着通知』を受けますか？",
            LOW_BUS_NOT_FOUND: "運行中の低床バスがないため、一般バスでご案内します。",
            LOW_BUS_PARTIALLY: "運行中の低床バスがないため、一般バスでご案内します。",
            TOO_FAR_FROM_STATION: "乗車する停留所の近くでリクエストしてください。",
            TOO_CLOSE_BUS: "停留所近くのバスはリクエストができません。",
            CONFIRM_CANCEL: "実行中の通知をキャンセルして進行しますか？",
            NO_HISTORY: "使用された乗車ベル履歴がありません。",
            OFF_NO_RUNNING_BUS: "運行中のバスがないため、通知を実行できません。",
            OFF_WARN_BUS_DUP: "ユーザーの近くにバスが多いため、乗車されたバスを感知することができません。通知は参考用としてのみご使用ください。",
            POPUP_ON_MAIN_BOTTOM_TAP: "試験運用中です。試験運用路線は、お知らせで確認できます。ご参考ください。",
            POPUP_ON_ALARM_OFF_BTN: "サービスの試験運用中です。試験運用期間に導出された問題点を改善して、正式にオープンする予定です。 ",
        },
    NONSTOP_DECLARE: {
        TITLE: "無停車申告",
        MESSAGE: "この無停車申告は、バス品質改善のためにバス経営およびサービス評価などに積極的に反映します。 ",
            REMARK: "その他のバスに関する苦情は、下部の『コールセンター』をご利用ください。",
            NONSTOP_DECLARED: "無停車申告を完了しました。",
            NONSTOP_DECLARE_FAILED: "無停車申告ができませんでした。",
            BEFORE_PASSED_STATION: "まだ車両が停留所を通過する前です。",
        },
    GPS: {
        PLZ_ALLOW_PERMISSION: "アプリが位置情報を『常に』使用できるように設定してください。",
        },
    HANDI: {
        NONE: "選択しない",
            HANDI: "障害者",
            OLD: "高齢者",
            PREGNANT: "妊産婦",
            WITHBABY: "乳幼児同伴",
            CHILD: "子ども",
        },
    BUSALARM: {
        GPS: "位置情報を使用できないため、通知を終了します。",
            API: "バス到着情報が照会できないため、通知を終了します。",
            USER_ESCAPED_FROM_STATION: "乗車停留所から離れたため、通知を終了します。",
            BUS_NOT_FOUND: "通知をリクエストされたバスの運行が任意で中止されたため、通知を終了します。 ",
            NOTIFY_TRANSFERED: "乗車ベルをバスに知らせました。",
            NOTIFY_TRANSFER_FAILED: "車両端末との通信が円滑ではありません。もう一度やり直してください。",
            OFF_RIDE_BUS_NOT_DETECTED: "ユーザーの位置と一致するバスを感知できませんでした。",
            OFF_START_STATION_NOT_DETECTED: "ユーザーの位置と一致するバスを感知できませんでした。",
            OFF_USER_ESCAPED_ROUTE_PATH: "ユーザーが路線運行経路を外れたため、通知を終了します。",
            TOTAL_TIMEOUT: "時間超過のため通知を中止します。",
            INTERNAL_ERROR: "申し訳ありません。今は通知を実行できません。",
            ON_ALARM: "{route}バスが{n}つ前の停留所に来ました。",
            ON_ALARM_TIME: "{route}バスが{n}分後に到着予定です。",
            OFF_ALARM: "{n}つ前の停留所到着。降車停留所は『 {offStation}』です。",
            OFF_ALARM_FINISHED: "降車停留所：{offStation}降車の準備をしてください。",
        },
    INTRO: {
        PLZ_SELECT_AGE: "文字サイズを選択すると、アプリの文字サイズが調整されます。",
        REFER_HELP: "※詳しい利用方法はご利用案内をご参照ください。",
        },
    PAGETITLE: { // 다국어 서비스를 위한 한글 추출 작업		        
            AROUND: "周辺の停留所",
            MESSAGE: "お知らせ",
            HOME_DRAW_NAV: "京畿バス情報",
            HOME_FAVOR_EDIT: "編集",
            INTRO1: "新しい京畿バス情報",
            BUS_EVAL: "バスレビュー",
            DEVICE_INFO: "装置情報",
            HELP: "ご利用案内",
            SETTINGS: "アプリ設定",
            SHOW_ALL_ROUTES: "バスタイプ",
            WIDGET_SETTINGS: "ウィジェット設定",
            BUS_NOTIFY_HISTORY: "乗車ベルリクエスト履歴を見る",
            OFF_BUS_SUPPORT: "降車通知",
            RIDE_SUPPORT_STATION: "乗車ベル可能停留所",
            RUN_POP_EVENT: "イベント/事故通知 ",
            ROUTE_INFO: "リアルタイムバス位置情報",
            ROUTE_INFO_DETAIL: "路線運行情報",
            ROUTE_MAP: "路線図",
            SEARCH_MAIN: "検索",
            SEARCH_POI_MAP: "場所",
            STATION_INFO: "停留所",
            STATION_MAP: "停留所",
            FAVOR_TAGLESS_ROUTE: "簡単予約",
            FR_MAIN: "経路検索",
            CHOICE_LANGUAGE: "言語",
            FAVOR_MODIFY: "お気に入りを修正",
            PAST_ARRIVALS_HISTORY: '過去のバス到着時刻',
        },
    PAGECOMMENT: { // 다국어 서비스를 위한 한글 추출 작업 		        
            AROUND_EMPTY: "周辺に停留所が存在しません。",
            ERR_LAUNCHING_MAP: "地図を読み込む際にエラーが発生しました。 ",
            VOICE_SEARCH_PERMISSION_DENIED: "装置が使用できません。",
            WHATS_NEW_CHANGE: "このように変わりました。",
            WHATS_NEW_RIDESUPPORT: "乗車するバスに乗車ベルを送信してください。",
            WHATS_NEW_FAV: "よく利用される停留所、路線を簡単にお気に入り登録することができます。",
            WHATS_NEW_PATHFIND: "正確な目的地が分からない場合は、邑/面/洞名で経路検索を行うことができます。 ",
            WIDGET_DESC: "{routeDestName}方面",
            BUS_EVAL_MSG: "バスの評価にご協力いただきありがとうございます。いただいたご意見は、より良いバスのサービスのために使用いたします。",
            BUS_EVAL_COMMENT: "いただいた貴重なご意見や評価は、バスの品質改善に反映いたします。",
            AT_LEAST_ONE: "少なくとも一つ以上を選択してください。",
            CHOICE_ALARM_STATION: "アラームを実行する停留所が設定されました。",
            ENABLE_SETTING_DEVELOPER: "개발자용 설정 활성화. 앱을 다시 시작하세요",
            DISABLE_SETTING_DEVELOPER: "개발자용 설정 비활성화. 앱을 다시 시작하세요        ",
            WIDGET_MAX_CNT: "最大{count}個をウィジェットに表示できます。 ",
            USED_SERVICE_WITHIN_7DAYS: "最大7日以内に利用された乗車ベルサービスです。 ",
            START_DIALER_APP: "コールセンター。選択すると、ダイヤルアプリに切り替わります。",
            SELECT_RIDING_STATION: "本人が乗車する停留所を選択してください。",
            N_AROUND_NO_STATION: "{n}m以内の停留所が見つかりません。",
            NONE_AROUND_STATION: "周辺に停留所が存在しません。",
            DISABLE_DISTANCE_RIDE_SUPPORT: "乗車ベルサービスは、ユーザーから{n}m以内の停留所のみ可能です。",
            MAP_ERROR: "地図を読み込む際にエラーが発生しました。 ",
            NOT_FOUND_LAST_STATION: "終点停留所が照会できません。",
            NOT_FOUND_START_STATION: "起点停留所が照会できません。 ",
            PLEASE_REFER: "ご利用の参考にしてください。 ",
            DISPLAY_AROUND_N: "周辺500m以内の停留所を表示します。",
            ZOOM_IN_SEE_STATION: "地図を適切に拡大すると、停留所が表示されます。",
            ADD_FAVOR_AFTER_FIND_ROAD: "経路検索を行った後に、お気に入りにすることができます。",
            QUESTION_DEL_SEARCH_HISTORY: "検索履歴を削除しますか?",
            COMPLETE_DEL: "削除されました。",
            PLZ_COMMENT: "ご意見をご記入ください。",
            PLZ_FAV_MEMO: "お気に入りに覚えやすい名前をメモしてください。(最大10文字)",
            TEL_BUTTON_CALL: "電話ボタンを押すと、通話ができます。",
            NO_ARRIVAL_BUS: "到着予定のバスがありません。",
            NO_FOREIGN_SERVICE: "このページは、地図などの一部機能が外国語に対応していないことがあります。",
            NO_SEARCH_AROUND_STATION: "周辺に停留所が存在しません。",
            APP_SETTING_PERMISSION_POSITION: "[アプリ設定-権限-位置]で設定できます。",
            CANCEL_RIDE_SUPPORT: "進行中の乗車ベルをキャンセルしますか？",
            CANCEL_AND_NEW_RIDE_SUPPORT: "進行中の乗車ベルをキャンセルし、新しく乗車ベルをリクエストしますか？",
            CANCEL_AND_NEW_RIDE_SUPPORT_ALARM: "進行中の乗車ベル/通知をキャンセルし、新しく降車通知を設定しますか？",
            SEARCHING_BUS_ARRIVAL_INFO: "バス到着情報を照会中です。",
            PAST_ARRIVALS_HISTORY_GUIDE: 'これは到着予定時刻ではありません。',
            PAST_ARRIVALS_HISTORY_GUIDE_WARN: '参考までにご利用ください',

        },
    PAGEWORD: {// 다국어 서비스를 위한 한글 추출 작업 		        
            // N_BEFORE_STATION: "{n}つ前",
            ROUTE_DEST_NAME: "{routeDestName}方面",
            ROUTE_ROW_FIRST_BUS : "始発",
            ROUTE_ROW_LAST_BUS: "終発",
            ALLOCATION_TIME: "配車",
            FONT_SIZE: "フォントサイズ",
            FONT_NORMAL: "普通",
            FONT_LOUDER: "もっと大きく",
            RIDE_SUPPORT_ALARM_SERVICE: "乗車ベル通知サービス",
            // N_ST_AGO: "{n}つ前",
            N_REMAIN_SEAT_CNT: "{n}席",
            ROUTES_TYPE: "バスタイプ",
            NIGHT_BUS: "深夜バス",
            LOW_BUS: "低床バス",
            OUT_BUS: "市外バス",
            AIR_BUS: "空港バス",
            ARRIVAL_INFO_ROUTE: "到着情報提供路線",
            WIDGET_DURATION_TIME: "ウィジェット到着時間表示継続時間",
            WIDGET_FAVORITY: "ウィジェットに表示するお気に入り",
            BUS: "バス",
            STATION_NM: "停留所",
            ROUTE_NM: "路線",
            PLATE_NO: "車両番号",
            USED_TIME: "利用時刻",
            LAST_STATION_NM: "終点停留所",
            TURN_SEQ_STATION_NM: "起終停留所",
            NONE_STOP: "経由",
            LAST_BUS_TIME: "終発時刻",
            ALERT: "通知",
            USABLE_TOILET: "トイレ利用可能",
            REG_FAVOR: "登録されたお気に入り",
            UNREG_FAVOR: "登録されていないお気に入り",
            MAIN_STOP_STATION: "主要停車停留所",
            WEEKDAY: "平日",
            WEEKEND: "週末",
            OPERATION_TIME: "運行時間",
            OPERATION_AREA: "主要運行地域",
            ROUTE_COMPANY: "運輸会社",
            ROUTE_GARAGE: "車庫",
            BUS_INTERVAL: "配車間隔",
            SEARCH: "検索",
            CANCEL: "キャンセル",
            DEL: "削除",
            NOW_POS: "現在地",
            TRAFFIC_ABBREVIATION_SUPPORT: "交通弱者乗車支援",
            ONLY_LOW_BUS: "低床バスのみ",
            SEARCHING: "探索中···",
            RIDE: "乗車",
            LAST_STATION: "終点",
            START_STATION: "起点",
            TURN_SEQ: "起終",
            DENSITY1: "余裕",
            DENSITY2: "普通",
            DENSITY3: "混雑",
            DENSITY4: "非常に混雑",
            OFF_BUS_BEFORE_STATION: "{n}つ前",
            BUS_SATISFACTION: "バス利用満足度",
            CALLCENTER: "お客様センター",
            INSERT_MEMO: "メモ入力",
            MEMO_EX: "例)出勤バスまたは退勤バス",
            CENTER_NM: "京畿道交通情報センター",
            LOADING: "ローディング···",
            RIDE_BUS_ALARM: "乗車アラーム",
            FAIL_RIDE_BUS_ALARM: "乗車通知失敗",
            NEED_PERMISSION_SETTING: "権限設定必要",
            PERMISSION_SETTING: "権限設定",
            OFF_BUS_ALARM: "降車通知",
            FAILED_OFF_BUS_ALARM: "降車通知失敗",
            COMPLETE_OFF_BUS_ALARM: "降車通知完了",
            COMPLETE_RIDE_SUPPORT_ALARM: "乗車通知完了",   
            N_MINUTE_LEFT: "{n}分前",
            RECENTLY_USE_RIDE_SUPPORT: "最近利用された乗車ベル",
            RIGHT_BEFORE: "今",
            BUSES: "路線",
            REPORT_NONSTOP_BUS: "無停車申告",            
            TODAY: "今日",
            YESTERDAY: "昨日",
            THIS_WEEK: "今週",
            LAST_WEEK: "先週",
            THIS_MONTH: "今月",
        LAST_MONTH: "先月",
        DAY2PREV: '2日前',
        DAY7PREV: '7日前',
        },
    PAGETABLABEL: { // 다국어 서비스를 위한 한글 추출 작업		        
            INFO_CIRCLE: "お知らせ",
            FILE_ALT: "外部通知",
            CAR_CRASH: "事故ニュース ",
            HOME: "ホーム",
            STAR: "お気に入り",
            STAR_RIDESUPPORT: "お気に入り",
            LOCATION: "周辺の停留所",
            DIRECTIONS: "経路検索",
            COMMENT: "お知らせ",
            HELP_RIDE: "乗車ベル",
            MAP_MARKER: "出発地/到着地指定",
            MAP_MARKER_MY: "現在地",
            MAPM: "地図",
            INFO: "情報 ",
            ALARM: "時間アラーム",
            ALARM_EXCLAMATION: "警告アラーム",
            EXCLAMATION: "警告",
            COG: "設定",
            CLIPBOARD_LIST_CHECK: "バス評価",
            PHONE: "電話",
            HISTORY: "履歴",
            PAPER_PLANE: "送信",
            RESERVATION: "バス予約",
            PRINT_SEARCH: "予約確認",
            BACK: "戻る",
            FAVOR: "お気に入り",
            MY_LOATION: "現在地",
            SHOW_MARKER_MAP: "地図から選択",
            OFF_BUS_ALARM: "降車通知",
            OFF_BUS_ALARM_RIDESUPPORT: "降車通知",
            EVAL_BUS: "バスレビュー",
            CALL_CENTER: "コールセンター",
            ROUTE_MAP: "路線図",
            ROUTE_INFO: "路線情報",
            FIND_ROAD: "すぐに経路検索",
            OK: "確認",
            CANCEL: "キャンセル",
            CLOSE: "閉じる",
            FAV_EMPTY_AROUND: "周辺の停留所を見る",
            FAV_EMPTY_SEARCH: "路線、停留所を検索する",
            NORMAL: "一般",
            DIRECT: "直行座席",
            SEAT: "座席",
            TOWN: "コミュニティバス",
            OUT: "市外",
            AIRPORT: "空港",
            NORMAL_ST: "一般",
            DIRECT_ST: "直座",
            SEAT_ST: "座席",
            TOWN_ST: "コミバス",
            OUT_ST: "市外",
            AIRPORT_ST: "空港",
            TROLY: "トロリーバス",
            TWO: "二階建て",
            LOW: "低床",
            JEONSE: "貸切",
            GAPYEONG_GUN: "加平郡",
            GOYANG_SI: "高陽市",
            GWACHEON_SI: "果川市",
            GWANGMYEONG_SI: "光明市",
            GWANGJU_SI: "広州市",
            GURI_SI: "九里市",
            GUNPO_SI: "軍浦市",
            GIMPO_SI: "金浦市",
            NAMYANGJU_SI: "南楊州市",
            DONGDUCHEON_SI: "東豆川市",
            BUCHEON_SI: "富川市",
            SEONGNAM_SI: "城南市",
            SUWON_SI: "水原市",
            SIHEUNG_SI: "始興市",
            ANSAN_SI: "安山市",
            ANSEONG_SI: "安城市",
            ANYANG_SI: "安養市",
            YANGJU_SI: "楊州市",
            YANGPYEONG_GUN: "楊平郡",
            YEOJU_SI: "驪州市",
            YEONCHEON_GUN: "漣川郡",
            OSAN_SI: "烏山市",
            YONGIN_SI: "龍仁市",
            UIWANG_SI: "義王市",
            UIJEONGBU_SI: "議政府市",
            ICHEON_SI: "利川市",
            PAJU_SI: "坡州市",
            PYEONGTAEK_SI: "平沢市",
            POCHEON_SI: "抱川市",
            HANAM_SI: "河南市",
            HWASEONG_SI: "華城市",
            GAPYEONG: "加平",
            GOYANG: "高陽",
            GWACHEON: "果川",
            GWANGMYEONG: "光明",
            GWANGJU: "광주_JP",
            GURI: "九里",
            GUNPO: "軍浦",
            GIMPO: "金浦",
            NAMYANGJU: "南楊州",
            DONGDUCHEON: "東豆川",
            BUCHEON: "富川",
            SEONGNAM: "城南",
            SUWON: "水原",
            SIHEUNG: "始興",
            ANSAN: "安山",
            ANSEONG: "安城",
            ANYANG: "安養",
            YANGJU: "楊州",
            YANGPYEONG: "楊平",
            YEOJU: "驪州",
            YEONCHEON: "漣川",
            OSAN: "烏山",
            YONGIN: "龍仁",
            UIWANG: "義王",
            UIJEONGBU: "議政府",
            ICHEON: "利川",
            PAJU: "坡州",
            PYEONGTAEK: "平沢",
            POCHEON: "抱川",
            HANAM: "河南",
            HWASEONG: "華城",
            SEOUL: "ソウル",
            SEOUL_SI: "ソウル特別市",
            INCHEON: "仁川",
            INCHEON_SI: "仁川市",
            ROUTE_TP_13: "一般型市内バス",
            ROUTE_TP_11: "直行座席型市内バス",
            ROUTE_TP_14: "広域急行型市内バス",
            ROUTE_TP_43: "一般型市外バス",
            ROUTE_TP_51: "リムジン型空港バス",
            ROUTE_TP_30: "コミュニティバス",
            ROUTE_TP_16: "京畿外郭循環バス",
            ROUTE_TP_15: "タボク(温かく幸せな)型市内バス",
            ROUTE_TP_53: "一般型空港バス",
            ROUTE_TP_23: "一般型農漁村バス",
            ROUTE_TP_12: "座席型市内バス",
            ROUTE_TP_21: "直行座席型農漁村バス",
            GYEONGGI_: "京畿道 ",

        },
        MOBILEWEBONLY: {
            SEE_MOBILE_APP: "もっと便利なモバイルアプリで見る",
            SUPPORT_ANDROID_AND_IPHONE: "AndroidまたはiOS端末に対応しています。",
            FUNCTION_ONLY_MOBILE_APP: "モバイルアプリにのみ対応している機能です。",
            IS_GOTO_PLAYSTORE: "Playストアに移動しますか？",
            IS_GOTO_APPSTORE: "App Storeに移動しますか？",
            LOADING: "ローディング···",
        },
}







// 베트남어 Tiếng Việt
const TextVT  = {
    ERROR: {
        UNKNOWN: "Phát sinh lỗi không xác định.",
		        NET_FAIL: "Đã xảy ra lỗi mạng. Vui lòng kiểm tra trạng thái kết nối mạng.",
		        NET_SERVER_FAIL: "Chưa nhận được phản hồi thích hợp từ máy chủ. Vui lòng thử lại sau ít phút.",
		        WRITE_SEARCH_TEXT: "Vui lòng nhập từ cần tìm.",
		        PERMISSION_DENIED: "Không thể sử dụng thiết bị micro. Vui lòng kiểm tra quyền hạn.",
		        CONFIRM_GOTO_GPS_PERMISSION_SETTING: 'Để hỗ trợ sử dụng chức năng thông báo lên xe, xuống xe, ứng dụng Thông tin xe bus tỉnh Gyeonggi cần truy cập vị trí hiện tại thường xuyên ngay cả khi không mở ứng dụng. Do đó, bạn cần cài đặt Thông tin vị trí của ứng dụng ở mục “Thường xuyên”. Bạn có muốn chuyển đến mục cài đặt ứng dụng không?',
		        GPS_DENIED: "Không thể sử dụng vị trí người dùng. Vui lòng kiểm tra quyền hạn.",
		        NO_LOCATION: "Không thể xác định vị trí người dùng.",
        },
    BOARD: {
        EMPTY: "Không có thông báo/lời nhắc",
		        NO_MORE_SHOW: "Không hiện lại trong ngày hôm nay",
        },
    ARRIVAL: {
        SOON: "Xe đến sau ít phút",
		        TIME: "{n} phút",
		        NPREV: "Trước {n} điểm",
		        WAIT_GARAGE: "Đang chờ ở điểm đỗ xe",
		        WAIT_GARAGE_TURN: "Xe đang chờ ở điểm quay đầu",
		        NO_RUN_BUS: "Không có xe bus hoạt động",
		        STOPPED: "Ngừng hoạt động",
		        END_BUS: "Chuyến cuối",
		        LOCAL_DELAY: "Đến chậm",
		        ROUTE_OUT: "Thoát tuyến bus",
        },
    STATION: {
        SOON_ARRIVAL: "Xe đến sau ít phút",
		NO_SOON_ARRIVAL: "Không có xe bus sắp đến.",
        },
    FAVOR: {
        GUIDE_WHEN_NO_ITEM: "Vui lòng tìm kiếm điểm bus xung quanh hoặc tìm tuyến bus, điểm bus để bắt đầu",
		        NO_ITEM: "Không có mục tìm kiếm nhanh đã cài đặt.",
		        ADDED: "Đã thêm vào mục tìm kiếm nhanh.",
		        ADD_FAILED: "Không thể bổ sung mục tìm kiếm nhanh.",
		        REMOVE_CONFIRM: "Bạn có muốn xóa mục đã chọn không?",
		        REMOVED: "Đã xóa mục tìm kiếm nhanh.",
		        REMOVE_FAILED: "Bạn không thể xóa mục Tìm kiếm nhanh.",
		        ALREADY_EXIST: "Đã được thêm từ trước.",
		        MEMO_UPDATED: "Ghi chú mục tìm kiếm nhanh đã được thay đổi.",
		        MEMO_UPDATE_FAILED: "Không thể thay đổi ghi chú mục tìm kiếm nhanh.",
		        ORDER_UPDATED: "Thứ tự tìm kiếm nhanh đã được thay đổi",
		        ORDER_UPDATE_FAILED: "Không thể thay đổi thứ tự mục tìm kiếm nhanh.",
		        NO_SERVICE_ENTRY: "Không tìm thấy mục tìm kiếm nhanh đã đặt. Vui lòng kiểm tra thông tin đã thay đổi.",
		        EDIT_DRAG_GUIDE: "Vui lòng nhấn và giữ dòng để thay đổi thứ tự.",
		        WIDGET_CONFIG_DONE: "Trạng thái hiển thị trên tiện ích đã được cài đặt.",
		        WIDGET_CONFIG_FAILED: "Không thể thiết lập trạng thái hiển thị trên tiện ích.",
		        WIDGET_REFRESH_PERIOD_GUIDE: "Khi chạm vào phần tiện ích trên màn hình chính, thời gian xe đến sẽ được cập nhật trong vòng {n} phút.",
        },
    GENERAL: {
        NO_ITEM: "Không tìm thấy thông tin yêu cầu.",
		        LOADING_DATA: "Đang kiểm tra.",
        },
    SEARCH: {
        PLACEHOLDER: "Vui lòng nhập điểm bus và tuyến bus.",
		        NO_MATCHED_RESULT: "Không có kết quả tìm kiếm trùng khớp.",
		        NO_INPUT: "Vui lòng nhập từ tìm kiếm.",
        },
    USER_FONT_SIZE: {
        	0: "Nhỏ hơn",
		1: "Ở giữa",
		2: "Lớn hơn",
        },
    FOREIGN_LANGUAGE: {
        K: '한국어',
        E: 'English',
        C: '中文',
        J: '日本語',
        V: 'Tiếng Việt',
    },
    VOICE: {
        IDLE: "Hãy nhấn nút micro để bắt đầu.",
		        RECORDING: "Đang nghe. Vui lòng đọc số tuyến bus hoặc bến xe bus.",
		        REQUESTING: "Đang phân tích âm thanh...",
		        FAILED: "Không thể nhận biết âm thanh. Vui lòng thử lại",
		        SELECTONE: "Vui lòng chọn từ đã đọc để tìm kiếm chính xác hơn.",
		        UNAVAILABLE: "Không thể sử dụng micro hoặc thiết bị âm thanh.",
        },
    BUS: {
        RUN_ENDED: "Xe bus đã ngừng hoạt động.",
		        NO_NEAR_BUS: "Không có xe bus dự kiến sắp đến.",
		        NO_RUN_BUS: "Không có xe bus đang hoạt động",
        },
    OPEN_SOON: {
        OPEN_SOON: "Dịch vụ đang dự kiến triển khai.",
		        PREPARE: "Dịch vụ đang được chuẩn bị.",
        },
    FINDPATH: {
        FAVOR_NOT_READY: "Bạn có thể tiến hành tìm đường, sau đó tìm kiếm nhanh.",
		        START_END_MISSED: "Vui lòng nhập điểm xuất phát và điểm đến.",
		        START_MISSED: "Vui lòng nhập điểm xuất phát.",
		        END_MISSED: "Vui lòng nhập điểm đến.",
		        NO_START_END_FOUND: "Không tìm được khu vực tương ứng với điểm xuất phát và điểm đến.",
		        START_POI_HOLDER: "Vui lòng nhập điểm xuất phát.",
		        END_POI_HOLDER: "Vui lòng nhập điểm đến.",
		        START_AREA_HOLDER: "Vui lòng nhập cả tên ấp/xã/phường",
		        END_AREA_HOLDER: "예) 봉담읍, 사당동.",
		        NO_RECENT_ITEMS: "Không có lịch sử tìm kiếm gần đây.",
		        NO_SEARCH_RESULTS: "Không tìm được phương tiện giao thông công cộng giữa điểm xuất phát và điểm đến.",
        },
    AROUND: {
        GUIDE: "Đánh dấu các bến bus trong vòng 500m xung quanh tôi.",
		        PLZ_ZOOM_IN: "Điểm bus hiển thị khi phóng to bản đồ đến kích thước nhất định.",
		        NO_AROUND_STATION: "Không tìm được điểm bus trong vòng 50m xung quanh.",
        },
    HISTORY: {
        REMOVE_CONFIRM: "Bạn có muốn xóa lịch sử tìm kiếm không?",
		        NO_ITEM: "Không có lịch sử tìm kiếm.",
        },
    RIDESUPPORT: {
        LOW_BUS_ONLY_TITLE: "Yêu cầu chuông báo lên xe cho người ưu tiên",
		        ON_ALARM_TITLE: "Thông báo lên xe",
		        TITLE: "Thông tin xe bus sắp đến",
		        HEADER: "Chuông báo lên xe cho người ưu tiên",
		        LOWBUS_ONLY_GUIDE: "Yêu cầu xe bus gầm thấp",
		        LOCAL_BUSY: "Đang tra cứu thông tin xe sắp đến. Vui lòng thử lại sau ít phút.",
		        DONE: "Đã yêu cầu chuông báo lên xe.",
		        OFFALARM_STARTED: "Kiểm tra người dùng và xe bus hoạt động để bắt đầu thông báo xuống xe. Vui lòng chỉ sử dụng thông báo để tham khảo.",
		        OFFALARM_STARTED_NEW: "{st} ~ {ed}",
		        NOTIFY_ACCEPTED: "Bạn đã yêu cầu thông tin chuông báo lên xe. Bạn có muốn nhận thông báo lên xe không?",
		        NOTIFY_ACCEPT_AND_TRANSFERED: "Đã thông báo cho xe bus về thông tin chuông báo lên xe. Bạn có muốn nhận thông báo lên xe không?",
		        NOTIFY_DONE: "Đã yêu cầu chuông báo lên xe.",
		        ONALARM_DONE: "Bạn đã cài đặt thông báo lên xe.",
		        OFFALARM_DONE: "Bạn đã cài đặt thông báo xuống xe.",
		        FAILED: "Mạng thông tin kết nối với máy đọc thẻ trên xe không tốt. Vui lòng thử lại.",
		        ERROR: "Đã xảy ra lỗi khi yêu cầu chuông báo/thông báo lên xe.",
		        CANCELLED: "Hủy yêu cầu chuông báo lên xe.",
		        OFFALARM_CANCELLED: "Thông báo xuống xe đã được hủy.",
		        PLZ_SELECT_HANDI: "Vui lòng chọn loại hình ưu tiên.",
		        NOT_ALLOWED_ROUTE: "Chỉ một phần tuyến đường vận hành thử nghiệm mới có thể sử dụng chức năng chuông báo lên xe.",
		        WANT_ON_ALARM: "Bạn có muốn nhận “thông báo đến của xe bus cần lên” không?",
		        LOW_BUS_NOT_FOUND: "Không có xe bus gầm thấp nào đang hoạt động, chuyển sang hướng dẫn về xe bus thông thường.",
		        LOW_BUS_PARTIALLY: "Không có xe bus gầm thấp nào đang hoạt động, chuyển sang hướng dẫn về xe bus thông thường.",
		        TOO_FAR_FROM_STATION: "Vui lòng đến gần điểm bus cần lên và tiến hành yêu cầu.",
		        TOO_CLOSE_BUS: "Không thể yêu cầu xe bus đã ở gần điểm dừng.",
		        CONFIRM_CANCEL: "Bạn có muốn hủy thông báo hiện tại và tiến hành không?",
		        NO_HISTORY: "Không có lịch sử sử dụng chuông báo lên xe.",
		        OFF_NO_RUNNING_BUS: "Không thể kích hoạt thông báo do không có xe bus nào đang hoạt động.",
		        OFF_WARN_BUS_DUP: "Xung quanh có quá nhiều xe bus, rất khó để nhận biết xe bus người dùng đang đi. Vui lòng chỉ sử dụng thông báo để tham khảo.",
		        POPUP_ON_MAIN_BOTTOM_TAP: "Đang vận hành thử nghiệm. Bạn có thể xem nội dung tuyến đường vận hành thử nghiệm tại mục Thông báo chung. Vui lòng tham khảo",
		        POPUP_ON_ALARM_OFF_BTN: "Dịch vụ đang được vận hành thử nghiệm. Sau khi cải thiện các vấn đề phát sinh trong quá trình vận hành thử nghiệm, dự kiến dịch vụ sẽ chính thức đưa vào hoạt động. ",
        },
    NONSTOP_DECLARE: {
        TITLE: "Báo cáo không dừng xe",
		        MESSAGE: "Chúng tôi sẽ tích cực phản ảnh báo cáo không dừng xe này trên đánh giá hoạt động kinh doanh và dịch vụ xe bus để cải thiện chất lượng xe bus tốt hơn. ",
		        REMARK: "Các phàn nàn về bất tiện trên xe bus vui lòng sử dụng “Tổng đài” phía dưới.",
		        NONSTOP_DECLARED: "Đã hoàn tất gửi báo cáo không dừng xe.",
		        NONSTOP_DECLARE_FAILED: "Không thể gửi báo cáo không dừng xe.",
		        BEFORE_PASSED_STATION: "Xe chưa đi qua điểm bus.",
        },
    GPS: {
        PLZ_ALLOW_PERMISSION: "Vui lòng cài đặt để ứng dụng “luôn luôn” sử dụng được thông tin vị trí.",
        },
    HANDI: {
        NONE: "Không\n lựa chọn",
		        HANDI: "Người\n khuyết tật",
		        OLD: "Người\n già",
		        PREGNANT: "Phụ nữ\n mang thai",
		        WITHBABY: "Đi kèm\n trẻ sơ sinh",
		        CHILD: "Trẻ em",
        },
    BUSALARM: {
        GPS: "Thông báo kết thúc do không thể sử dụng thông tin vị trí.",
		        API: "Kết thúc thông báo do không thể kiểm tra thông tin xe bus sắp đến.",
		        USER_ESCAPED_FROM_STATION: "Thông báo kết thúc do bạn đã đi xa điểm bus cần lên.",
		        BUS_NOT_FOUND: "Thông báo kết thúc do xe bus mà bạn đăng ký thông báo đã tùy tiện ngừng hoạt động. ",
		        NOTIFY_TRANSFERED: "Chuông báo lên xe đã được thông báo cho xe bus.",
		        NOTIFY_TRANSFER_FAILED: "Mạng thông tin kết nối với máy đọc thẻ trên xe không tốt. Vui lòng thử lại.",
		        OFF_RIDE_BUS_NOT_DETECTED: "Không xác định được xe bus ở gần vị trí người dùng.",
		        OFF_START_STATION_NOT_DETECTED: "Không xác định được xe bus ở gần vị trí người dùng.",
		        OFF_USER_ESCAPED_ROUTE_PATH: "Người dùng rời khỏi tuyến đường hoạt động của xe bus và tắt thông báo.",
		        TOTAL_TIMEOUT: "Tạm ngừng thông báo do vượt quá thời gian.",
		        INTERNAL_ERROR: "Rất tiếc. Hiện tại không thể kích hoạt thông báo.",
		        ON_ALARM: "Xe bus {route} đã đến điểm bus thứ {n} phía trước.",
		        ON_ALARM_TIME: "Xe bus {route} sẽ đến sau {n} phút nữa.",
		        OFF_ALARM: "Xe đã đến điểm bus thứ {n} phía trước. Điểm bus xuống xe là “{offStation}“.",
		        OFF_ALARM_FINISHED: "Điểm bus cần xuống:{offStation} Vui lòng chuẩn bị xuống xe.",
        },
    INTRO: {
        PLZ_SELECT_AGE: "Bạn có thể chọn cỡ chữ để điều chỉnh cỡ chữ trên ứng dụng.",
		        REFER_HELP: "※ Chi tiết về cách sử dụng vui lòng tham khảo hưởng dẫn sử dụng.",
        },
    PAGETITLE: { // 다국어 서비스를 위한 한글 추출 작업		        
                AROUND: "Điểm bus xung quanh",
		        MESSAGE: "Thông báo",
		        HOME_DRAW_NAV: "Thông tin xe bus tỉnh Gyeonggi",
		        HOME_FAVOR_EDIT: "Chỉnh sửa",
		        INTRO1: "Thông tin xe bus mới của tỉnh Gyeonggi",
		        BUS_EVAL: "Đánh giá về xe bus",
		        DEVICE_INFO: "Thông tin thiết bị",
		        HELP: "Hướng dẫn sử dụng",
		        SETTINGS: "Cài đặt ứng dụng",
		        SHOW_ALL_ROUTES: "Loại xe bus",
		        WIDGET_SETTINGS: "Cài đặt tiện ích",
		        BUS_NOTIFY_HISTORY: "Xem lịch\n   sử yêu cầu chuông báo lên xe",
		        OFF_BUS_SUPPORT: "Thông báo xuống xe",
		        RIDE_SUPPORT_STATION: "Điểm bus có chuông báo lên xe",
		        RUN_POP_EVENT: "Thông báo về sự kiện/sự cố ",
		        ROUTE_INFO: "Vị trí xe bus theo thời gian thực",
		        ROUTE_INFO_DETAIL: "Thông tin tuyến xe hoạt động",
		        ROUTE_MAP: "Bản đồ tuyến xe",
		        SEARCH_MAIN: "Tìm kiếm",
		        SEARCH_POI_MAP: "Địa điểm",
		        STATION_INFO: "Bến xe bus",
		        STATION_MAP: "Bến xe bus",
		        FAVOR_TAGLESS_ROUTE: "Đặt chỗ nhanh",
		        FR_MAIN: "Tìm đường",
                CHOICE_LANGUAGE: "Ngôn ngữ",
        FAVOR_MODIFY: "Chỉnh sửa tìm kiếm nhanh",
        PAST_ARRIVALS_HISTORY: 'quá khứ thời gian đến của xe buýt',
        },
    PAGECOMMENT: { // 다국어 서비스를 위한 한글 추출 작업 		        
                AROUND_EMPTY: "Không có điểm bus nào gần đây.",
		        ERR_LAUNCHING_MAP: "Phát sinh lỗi trong quá trình tải bản đồ. ",
		        VOICE_SEARCH_PERMISSION_DENIED: "Không thể sử dụng thiết bị",
		        WHATS_NEW_CHANGE: "Đã thay đổi như vậy.",
		        WHATS_NEW_RIDESUPPORT: "Hãy gửi chuông báo lên xe cho xe bus mà bạn muốn lên",
		        WHATS_NEW_FAV: "Bạn có thể dễ dàng thêm điểm bus, tuyến bus thường xuyên sử dụng vào mục tìm kiếm nhanh",
		        WHATS_NEW_PATHFIND: "Khi không biết chính xác điểm đến, bạn có thể tìm đường bằng tên ấp/xã/phường ",
		        WIDGET_DESC: "Hướng đi {routeDestName}",
		        BUS_EVAL_MSG: "Cảm ơn bạn đã trả lời đánh giá về xe bus. Những ý kiến đóng góp quý báu của bạn sẽ được sử dụng để cải thiện dịch vụ xe bus ngày một tốt hơn.",
		        BUS_EVAL_COMMENT: "Các ý kiến và đánh giá quan trọng của bạn sẽ được tiếp thu để cải thiện chất lượng xe bus.",
		        AT_LEAST_ONE: "Bạn phải chọn tối thiểu từ 1 trở lên.",
		        CHOICE_ALARM_STATION: "Bến xe bus cần kích hoạt thông báo đã được cài đặt.",
		        ENABLE_SETTING_DEVELOPER: "개발자용 설정 활성화. 앱을 다시 시작하세요",
		        DISABLE_SETTING_DEVELOPER: "개발자용 설정 비활성화. 앱을 다시 시작하세요        ",
		        WIDGET_MAX_CNT: "Có thể hiển thị tối đa {count} mục trên tiện ích. ",
		        USED_SERVICE_WITHIN_7DAYS: "Dịch vụ chuông báo lên xe sử dụng tối đa trong 7 ngày. ",
		        START_DIALER_APP: "Tổng đài. Khi lựa chọn, hệ thống sẽ chuyển đến ứng dụng quay số.",
		        SELECT_RIDING_STATION: "Vui lòng chọn bến bus mà bạn muốn lên.",
		        N_AROUND_NO_STATION: "Không tìm thấy điểm bus trong vòng {n}m.",
		        NONE_AROUND_STATION: "Không có điểm bus nào gần đây.",
		        DISABLE_DISTANCE_RIDE_SUPPORT: "Dịch vụ chuông báo lên xe chỉ có thể sử dụng ở điểm bus trong vòng {n}m từ vị trí người dùng.",
		        MAP_ERROR: "Phát sinh lỗi trong quá trình tải bản đồ. ",
		        NOT_FOUND_LAST_STATION: "Không thể tra cứu điểm bus kết thúc.",
		        NOT_FOUND_START_STATION: "Không kiểm tra được thông tin điểm bus xuất phát. ",
		        PLEASE_REFER: "Vui lòng tham khảo khi sử dụng ",
		        DISPLAY_AROUND_N: "Đánh dấu các bến bus trong vòng 500m xung quanh tôi.",
		        ZOOM_IN_SEE_STATION: "Điểm bus hiển thị khi phóng to bản đồ đến kích thước nhất định.",
		        ADD_FAVOR_AFTER_FIND_ROAD: "Bạn có thể tiến hành tìm đường, sau đó tìm kiếm nhanh.",
		        QUESTION_DEL_SEARCH_HISTORY: "Bạn có muốn xóa lịch sử tìm kiếm không?",
		        COMPLETE_DEL: "Đã xóa.",
                PLZ_COMMENT: "Vui lòng để lại ý kiến.",
                PLZ_FAV_MEMO: "Hãy đặt tên dễ nhớ trong mục tìm kiếm nhanh(Tối đa 10 chữ)",
                TEL_BUTTON_CALL: "Bạn có thể nhấn vào số điện thoại để gọi điện.",
                NO_ARRIVAL_BUS: "Không có xe bus dự kiến sắp đến.",
                NO_FOREIGN_SERVICE: "Trang web này không hỗ trợ tiếng nước ngoài ở một số chức năng như bản đồ.",
                NO_SEARCH_AROUND_STATION: "Không có điểm bus nào gần đây.",
                APP_SETTING_PERMISSION_POSITION: "[ Cài đặt - Quyền hạn - Vị trí ]",
                CANCEL_RIDE_SUPPORT: "Bạn có muốn hủy chuông báo lên xe đang tiến hành không?",
                CANCEL_AND_NEW_RIDE_SUPPORT: "Bạn muốn hủy chuông báo lên xe đang tiến hành để yêu cầu thông báo lên xe mới?",
                CANCEL_AND_NEW_RIDE_SUPPORT_ALARM: "Bạn muốn hủy chuông báo lên xe/thông báo đang tiến hành để cài đặt thông báo xuống xe mới?",
        SEARCHING_BUS_ARRIVAL_INFO: "Đang tra cứu thông tin xe bus đến.",
        PAST_ARRIVALS_HISTORY_GUIDE: 'Đây không phải là thời gian đến dự kiến.',
        PAST_ARRIVALS_HISTORY_GUIDE_WARN: 'Vui lòng chỉ sử dụng nó như một tài liệu tham khảo',

        },
    PAGEWORD: {// 다국어 서비스를 위한 한글 추출 작업 		        
        // N_BEFORE_STATION: "Trước {n} điểm",
		        ROUTE_DEST_NAME: "Hướng đi {routeDestName}",
		        ROUTE_ROW_FIRST_BUS : "Chuyến đầu",
		        ROUTE_ROW_LAST_BUS: "Chuyến cuối",
		        ALLOCATION_TIME: "Bố trí xe",
		        FONT_SIZE: "Kích thước kiểu chữ",
		        FONT_NORMAL: "Bình thường",
		        FONT_LOUDER: "Lớn hơn",
		        RIDE_SUPPORT_ALARM_SERVICE: "Dịch vụ nhắc có chuông báo lên xe",
		        // N_ST_AGO: "Trước {n} điểm",
                N_REMAIN_SEAT_CNT: "{n} ghế",
		        ROUTES_TYPE: "Loại xe bus",
		        NIGHT_BUS: "Xe bus đêm",
		        LOW_BUS: "Xe bus sàn thấp",
		        OUT_BUS: "Xe bus ngoại thành",
		        AIR_BUS: "Xe bus sân bay",
		        ARRIVAL_INFO_ROUTE: "Tuyến bus cung cấp thông tin thời gian đến",
		        WIDGET_DURATION_TIME: "Đồng hồ hiển thị thời gian đến trong tiện ích",
		        WIDGET_FAVORITY: "Mục tìm kiếm nhanh cần hiển thị trên tiện ích",
		        BUS: "Xe bus",
		        STATION_NM: "Bến xe bus",
		        ROUTE_NM: "Tuyến bus",
		        PLATE_NO: "Biển số xe",
		        USED_TIME: "Thời gian sử dụng",
		        LAST_STATION_NM: "Điểm bus cuối",
		        TURN_SEQ_STATION_NM: "Điểm bus quay đầu",
		        NONE_STOP: "Điểm dừng",
		        LAST_BUS_TIME: "Thời gian chuyến cuối",
		        ALERT: "Thông báo",
		        USABLE_TOILET: "Có thể sử dụng nhà vệ sinh",
		        REG_FAVOR: "Mục tìm kiếm nhanh đã cài đặt",
		        UNREG_FAVOR: "Mục tìm kiếm nhanh chưa đặt",
		        MAIN_STOP_STATION: "Các điểm dừng chính",
		        WEEKDAY: "Ngày thường",
		        WEEKEND: "Cuối tuần",
		        OPERATION_TIME: "Thời gian hoạt động",
		        OPERATION_AREA: "Khu vực hoạt động chính",
		        ROUTE_COMPANY: "Công ty vận tải",
		        ROUTE_GARAGE: "Điểm đỗ xe",
		        BUS_INTERVAL: "Thời gian giữa các chuyến",
		        SEARCH: "Tìm kiếm",
		        CANCEL: "Hủy",
		        DEL: "Xóa",
		        NOW_POS: "Vị trí hiện tại",
		        TRAFFIC_ABBREVIATION_SUPPORT: "Hỗ trợ lên xe cho người ưu tiên",
		        ONLY_LOW_BUS: "Chỉ xem xe bus sàn thấp",
		        SEARCHING: "Đang tìm kiếm...",
		        RIDE: "Lên xe",
		        LAST_STATION: "Điểm cuối",
		        START_STATION: "Điểm đầu",
		        TURN_SEQ: "Quay xe",
                DENSITY1: "Vắng",
                DENSITY2: "Bình\n thường",
                DENSITY3: "Đông",
                DENSITY4: "Rất\n đông",
                OFF_BUS_BEFORE_STATION: "Trước\n{n} điểm",
                BUS_SATISFACTION: "Mức độ hài lòng khi sử dụng xe bus",
                CALLCENTER: "Trung tâm khách hàng",
                INSERT_MEMO: "Nhập ghi chú",
                MEMO_EX: "Ví dụ: Xe bus đi làm hoặc Xe bus tan làm",
                CENTER_NM: "Trung tâm thông tin giao thông tỉnh Gyeonggi",
                LOADING: "Đang tải...",
                RIDE_BUS_ALARM: "Thông báo lên xe",
                FAIL_RIDE_BUS_ALARM: "Thông báo lên xe không thành công",
                NEED_PERMISSION_SETTING: "Yêu cầu thiết lập quyền hạn",
                PERMISSION_SETTING: "Thiết lập quyền hạn",
                OFF_BUS_ALARM: "Thông báo xuống xe",
                FAILED_OFF_BUS_ALARM: "Thông báo xuống xe không thành công",
                COMPLETE_OFF_BUS_ALARM: "Thông báo xuống xe thành công",
                COMPLETE_RIDE_SUPPORT_ALARM: "Thông báo lên xe thành công",
                N_MINUTE_LEFT: "Còn {n} phút",
                RECENTLY_USE_RIDE_SUPPORT: "Chuông báo lên xe đã sử dụng gần đây",
                RIGHT_BEFORE: "vừa xong",
                BUSES: "Tuyến bus",
                REPORT_NONSTOP_BUS: "Báo cáo không dừng xe",                
                TODAY: "Hôm nay",
                YESTERDAY: "Hôm qua",
                THIS_WEEK: "Tuần này",
                LAST_WEEK: "Tuần trước",
                THIS_MONTH: "Tháng này",
        LAST_MONTH: "Tháng trước",
        DAY2PREV: 'hai ngày trước',
        DAY7PREV: '7 ngày trước',

        },
    PAGETABLABEL: { // 다국어 서비스를 위한 한글 추출 작업		        
                INFO_CIRCLE: "Thông báo",
		        FILE_ALT: "Thông báo ngoài",
		        CAR_CRASH: "Thông tin về sự cố ",
		        HOME: "Trang chính",
		        STAR: "Tìm kiếm nhanh",
		        LOCATION: "Điểm bus xung quanh",
		        DIRECTIONS: "Tìm đường",
		        COMMENT: "Thông báo",
		        HELP_RIDE: "Chuông \nbáo lên xe",
		        MAP_MARKER: "Chỉ định điểm xuất phát/điểm đến",
		        MAP_MARKER_MY: "Vị trí hiện tại",
		        MAPM: "Bản đồ",
		        INFO: "Thông tin ",
		        ALARM: "Thông báo thời gian",
		        ALARM_EXCLAMATION: "Chuông cảnh báo",
		        EXCLAMATION: "Cảnh báo",
		        COG: "Cài đặt",
		        CLIPBOARD_LIST_CHECK: "Đánh giá xe bus",
		        PHONE: "Điện thoại",
		        HISTORY: "Lịch sử",
		        PAPER_PLANE: "Gửi",
		        RESERVATION: "Hẹn trước xe bus",
		        PRINT_SEARCH: "Xác nhận đặt trước",
		        BACK: "Sau",
		        FAVOR: "Tìm kiếm nhanh",
		        MY_LOATION: "Vị trí hiện tại",
		        SHOW_MARKER_MAP: "Chọn trên bản đồ",
		        OFF_BUS_ALARM: "Thông báo xuống xe",
                OFF_BUS_ALARM_RIDESUPPORT: "Thông báo xuống xe",
		        EVAL_BUS: "Phản hồi về xe bus",
		        CALL_CENTER: "Tổng đài",
		        ROUTE_MAP: "Bản đồ tuyến xe",
		        ROUTE_INFO: "Thông tin tuyến xe",
		        FIND_ROAD: "Tìm đường nhanh",
		        OK: "Xác nhận",
		        CANCEL: "Hủy",
		        CLOSE: "Đóng",
		        FAV_EMPTY_AROUND: "Xem điểm bus xung quanh",
		        FAV_EMPTY_SEARCH: "Tìm kiếm tuyến bus, điểm \nbus",
		        NORMAL: "Thông thường",
		        DIRECT: "Tốc hành ",
		        SEAT: "Có ghế ngồi",
		        TOWN: "Tuyến ngắn",
		        OUT: "Ngoại thành",
		        AIRPORT: "Sân bay",
                NORMAL_ST: "Thông thường",
		        DIRECT_ST: "Tốc hành ",
		        SEAT_ST: "Có ghế ngồi",
		        TOWN_ST: "Tuyến ngắn",
		        OUT_ST: "Ngoại thành",
		        AIRPORT_ST: "Sân bay",
                TROLY: "Xe đẩy tay",
		        TWO: "2 tầng",
		        LOW: "Sàn thấp",
		        JEONSE: "Thuê trọn gói",
                GAPYEONG_GUN: "Huyện Gapyeong",
                GOYANG_SI: "Thành phố Goyang",
                GWACHEON_SI: "Thành phố Gwacheon",
                GWANGMYEONG_SI: "Thành phố Gwangmyeong",
                GWANGJU_SI: "Thành phố Gwangju",
                GURI_SI: "Thành phố Guri",
                GUNPO_SI: "Thành phố Gunpo",
                GIMPO_SI: "Thành phố Gimpo",
                NAMYANGJU_SI: "Thành phố Namyangju",
                DONGDUCHEON_SI: "Thành phố Dongducheon",
                BUCHEON_SI: "Thành phố Bucheon",
                SEONGNAM_SI: "Thành phố Seongnam",
                SUWON_SI: "Thành phố Suwon",
                SIHEUNG_SI: "Thành phố Siheung",
                ANSAN_SI: "Thành phố Ansan",
                ANSEONG_SI: "Thành phố Anseong",
                ANYANG_SI: "Thành phố Anyang",
                YANGJU_SI: "Thành phố Yangju",
                YANGPYEONG_GUN: "Huyện Yangpyeong",
                YEOJU_SI: "Thành phố Yeoju",
                YEONCHEON_GUN: "Huyện Yeoncheon",
                OSAN_SI: "Thành phố Osan",
                YONGIN_SI: "Thành phố Yongin",
                UIWANG_SI: "Thành phố Uiwang",
                UIJEONGBU_SI: "Thành phố Uijeongbu",
                ICHEON_SI: "Thành phố Icheon",
                PAJU_SI: "Thành phố Paju",
                PYEONGTAEK_SI: "Thành phố Pyeongtaek",
                POCHEON_SI: "Thành phố Pocheon",
                HANAM_SI: "Thành phố Hanam",
                HWASEONG_SI: "Thành phố Hwaseong",
                GAPYEONG: "Gapyeong",
                GOYANG: "Goyang",
                GWACHEON: "Gwacheon",
                GWANGMYEONG: "Gwangmyeong",
                GWANGJU: "Gwangju",
                GURI: "Guri",
                GUNPO: "Gunpo",
                GIMPO: "Gimpo",
                NAMYANGJU: "Namyangju",
                DONGDUCHEON: "Dongducheon",
                BUCHEON: "Bucheon",
                SEONGNAM: "Seongnam",
                SUWON: "Suwon",
                SIHEUNG: "Siheung",
                ANSAN: "Ansan",
                ANSEONG: "Anseong",
                ANYANG: "Anyang",
                YANGJU: "Yangju",
                YANGPYEONG: "Yangpyeong",
                YEOJU: "Yeoju",
                YEONCHEON: "Yeoncheon",
                OSAN: "Osan",
                YONGIN: "Yongin",
                UIWANG: "Uiwang",
                UIJEONGBU: "Uijeongbu",
                ICHEON: "Icheon",
                PAJU: "Paju",
                PYEONGTAEK: "Pyeongtaek",
                POCHEON: "Pocheon",
                HANAM: "Hanam",
                HWASEONG: "Hwaseong",
                SEOUL: "Seoul",
                SEOUL_SI: "Thành phố Seoul",
                INCHEON: "Incheon",
                INCHEON_SI: "Thành phố Incheon",
                ROUTE_TP_13: "Xe bus nội thành tuyến thường",
                ROUTE_TP_11: "Xe bus nội thành chạy thẳng",
                ROUTE_TP_14: "Xe bus tốc hành khu đô thị lớn",
                ROUTE_TP_43: "Xe bus ngoại thành tuyến thường",
                ROUTE_TP_51: "Xe bus limousine sân bay",
                ROUTE_TP_30: "Xe bus tuyến ngắn",
                ROUTE_TP_16: "Xe bus lưu thông ngoài tỉnh Gyeonggi",
                ROUTE_TP_15: "Xe bus Tabok nội thành",
                ROUTE_TP_53: "Xe bus sân bay",
                ROUTE_TP_23: "Xe bus khu vực nông thôn",
                ROUTE_TP_12: "Xe có ghế ngồi",
                ROUTE_TP_21: "Xe bus khu vực nông thôn chạy đường dài",
                GYEONGGI_: "Gyeonggi ",
        },
        MOBILEWEBONLY: {
            SEE_MOBILE_APP: "Thuận tiện hơn khi xem ứng dụng trên di động",
            SUPPORT_ANDROID_AND_IPHONE: "Hỗ trợ các thiết bị Android hoặc iOS.",
            FUNCTION_ONLY_MOBILE_APP: "Chức năng chỉ hỗ trợ trên ứng dụng di động.",
            IS_GOTO_PLAYSTORE: "Bạn có muốn chuyển đến Play Store?",
            IS_GOTO_APPSTORE: "Bạn có muốn chuyển đến App Store?",
            LOADING: "Đang tải...",
        },
}



export const GetText = (type, id, def) => {
    
    if(def==="test"){
        console.log("GetText home_draw_nav wow 어쩌라구 ",id)
    }
    let language= global.foreignLanguage ? global.foreignLanguage : 'K';
    
    
    let textLanguage = Texts;

    if(language == 'E'){
        textLanguage = TextEN;
    } else if(language == 'C'){
        textLanguage = TextCN
    } else if(language == 'J'){
        textLanguage = TextJP
    } else if(language == 'V'){
        textLanguage = TextVT
    }
    
    if (typeof id === "string" || id instanceof String) {
        return textLanguage[type?.toUpperCase()] && textLanguage[type.toUpperCase()][id?.toUpperCase()] ? 
        textLanguage[type.toUpperCase()][id.toUpperCase()] : (def ? def : undefined);
    }
    else {
        return textLanguage[type?.toUpperCase()] && textLanguage[type.toUpperCase()][id] ? 
        textLanguage[type.toUpperCase()][id] : (def ? def : undefined);
    }
    
}

export const GetTextSido = (sido) => {
    let sidoBak = sido;    
    let isGyeonggi = false;
    let tmpArr = "";
    if(sido.indexOf(Texts["PAGETABLABEL"]["GYEONGGI_"])==0){
        tmpArr = sido.split(" ");
        isGyeonggi = true;
        sido = tmpArr[1];
    }

    let sidoArr = sido.split(",");
    
    let texts_ids = ["GAPYEONG_GUN", "GOYANG_SI", "GWACHEON_SI", "GWANGMYEONG_SI", "GWANGJU_SI", "GURI_SI", "GUNPO_SI", "GIMPO_SI", 
                   "NAMYANGJU_SI", "DONGDUCHEON_SI", "BUCHEON_SI", "SEONGNAM_SI", "SUWON_SI", "SIHEUNG_SI", "ANSAN_SI", 
                   "ANSEONG_SI", "ANYANG_SI", "YANGJU_SI", "YANGPYEONG_GUN", "YEOJU_SI", "YEONCHEON_GUN", "OSAN_SI", "YONGIN_SI", 
                   "UIWANG_SI", "UIJEONGBU_SI", "ICHEON_SI", "PAJU_SI", "PYEONGTAEK_SI", "POCHEON_SI", "HANAM_SI", "HWASEONG_SI", "SEOUL_SI", "INCHEON_SI",
                   "GAPYEONG", "GOYANG", "GWACHEON", "GWANGMYEONG", "GWANGJU", "GURI", "GUNPO", "GIMPO", 
                   "NAMYANGJU", "DONGDUCHEON", "BUCHEON", "SEONGNAM", "SUWON", "SIHEUNG", "ANSAN", 
                   "ANSEONG", "ANYANG", "YANGJU", "YANGPYEONG", "YEOJU", "YEONCHEON", "OSAN", "YONGIN", 
                   "UIWANG", "UIJEONGBU", "ICHEON", "PAJU", "PYEONGTAEK", "POCHEON", "HANAM", "HWASEONG", "SEOUL", "INCHEON",
                   "ROUTE_TP_13","ROUTE_TP_11","ROUTE_TP_14","ROUTE_TP_43","ROUTE_TP_51","ROUTE_TP_30",
                   "ROUTE_TP_16","ROUTE_TP_15","ROUTE_TP_53","ROUTE_TP_23","ROUTE_TP_12","ROUTE_TP_21",
                    ]

    let language= global.foreignLanguage;
    
    let textLanguage = Texts;

    if(language == 'E'){
        textLanguage = TextEN;
    } else if(language == 'C'){
        textLanguage = TextCN
    } else if(language == 'J'){
        textLanguage = TextJP
    } else if(language == 'V'){
        textLanguage = TextVT
    } else {
        textLanguage = Texts
    }
    // console.log("pageTabLabel",sido)
    let text_id = "";
    let arr = "";
    sidoArr.forEach((v1, index1) => {

        texts_ids.forEach((v, index) => {       
            
            if(Texts["PAGETABLABEL"][v] == v1){
                text_id = textLanguage["PAGETABLABEL"][v]
                
                arr = arr + text_id + (index1<sidoArr.length-1 ? ',' : '');
                
            }
        });        
    });
    if(isGyeonggi){
        arr = textLanguage["PAGETABLABEL"]["GYEONGGI_"] + ' ' + arr;
    }
    // console.log("arr",arr)
    // console.log("text_ID", arr)
    return arr == "" ? sido : arr;
}

export default Texts;
