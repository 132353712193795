import React from 'react';
import { View, Text } from 'react-native';
// config
import * as GBus from '../../../defines/GBus';
import * as JsUtil from '../../../utils/JsUtil';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import { Colors, Layouts } from '../../../defines/Theme';

// shared
import SpacerH from '../common/SpacerH';

const DensityBox = ({bus, style}) => {
    if (bus && !GBus.IsDensitySupportRoute(bus))  {
        return null;
    }
    if (JsUtil.isNumeric(JsUtil.GText(bus, "density")) && JsUtil.GInt(bus, "density") <= 0) {
        return null;
    }
    return (<>
        {
            <View style={ SectionStyle("densityBoxBg", {...style, borderColor: GBus.GetDensityColor(JsUtil.GText(bus, "density")) }) }>
                <Text style={TextStyle('rowDetail', (global.foreignLanguage=='E' || global.foreignLanguage=='V' ? "smallest" :  "small"))}>
                    {GBus.GetDensityLabel(JsUtil.GText(bus, "density"))}
                </Text>
            </View>
        }
    </>)
}

export default DensityBox