// 버스도착페이지 바
import React, { useState, useEffect } from 'react';
import { BottomRightTabs } from './BottomTab';

export default function RideSupportBottomTab({ navigation, onBackPress, onOnAlarmPress, onReviewPress, onOffAlarmPress, onDeclarePress, onHistoryPress }) {
    let tabs = [];
    if (onOffAlarmPress) {
        tabs.push({
            name: 'alarmClock',
            title: 'OFF_BUS_ALARM_RIDESUPPORT',
            onPress: () => { onOffAlarmPress?.(); },   
            label: '하차알림',
        })
    }
    // tabs.push({
    //     name: 'evalBus',
    //     title: '버스리뷰',
    //     onPress: () => { 
    //         if(onReviewPress) {
    //             onReviewPress();
    //         }
    //         else {
    //             navigation.navigate("busEval"); 
    //         }
    //     }                        
    // })
    tabs.push({ 
        name: 'phone', 
        onPress: () => { onDeclarePress?.(); },
        title: 'CALL_CENTER',
        label: '콜센터', 
        hint: '선택시 전화 앱 전환.',
    })
    return (
        <BottomRightTabs 
            onBack={onBackPress}
            tabs={tabs}
            navigation={navigation}
        /> 
    )
}