// 정류소에서 매 노선에 해당하여 2대 버스의 현재 상황을 알려주기 위한 Component
import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';

import LoadSvg from '../../../defines/Svgs';
import FIcon from '../../../defines/FIcon';
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import SectionStyle from '../../../styles/SectionStyle';
import CommonStyle from '../../../styles/CommonStyle';
import TextStyle from '../../../styles/TextStyle';
import * as JsUtil from '../../../utils/JsUtil';
import { Colors, Layouts } from '../../../defines/Theme';

import SpacerH from '../../components/common/SpacerH';
import DensityBox from '../../components/parts/DensityBox';
import RemainSeatCntBox from '../../components/parts/RemainSeatCntBox';
import BusArrivalTimeBox, { BusArrivalTimeBoxFixedWidth } from '../../components/parts/BusArrivalTimeBox';
import BusArrivalNthBox from '../../components/parts/BusArrivalNthBox';
import PlateTypeBox from '../../components/parts/PlateTypeBox';
import PlateNoBox from '../../components/parts/PlateNoBox';
import BusAuxStatusBox from '../../components/parts/BusAuxStatusBox';


import BusRowRideSupportButton from '../../components/parts/BusRowRideSupportButton';

export const EmptyBusArrivalRow = ({ text }) => { 
    return (
        <View style={ SectionStyle('busArrivalRowBg', { backgroundColor: Colors.appBackgroundColor }) }>
            <View style={ SectionStyle('rowForHRow', { flex: 1, height: Layouts.rowHeight * 0.75, backgroundColor: 'transparent' }) } >
                <View style={ SectionStyle('rowFillStretchForRow', { justifyContent: "center" }) }>  
                    <View style={ SectionStyle('arrivalBusPosTextBg') }>
                        <Text numberOfLines={1} style={ TextStyle('rowDetail', 'smallest', { color: Colors.fontCommentColor }) }>
                            {text}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default function BusRow({ busStatus, routeArrival, onPressBusNotify, taglessCd, activeAlarm }) {

    // console.log("routeArrivel",routeArrival);
    if (!routeArrival || !busStatus) {
        return null
    }
    let taglessCdText = taglessCd ? taglessCd._text : '0';
    let svgName = GBus.GetBusSvgName(JsUtil.GText(busStatus, 'routeTypeCd'), "0", null, taglessCdText)
    if (!JsUtil.GText(busStatus, "vehId")) {
        return (
            <View style={ SectionStyle('busArrivalRowBg', { backgroundColor: Colors.routeBusArrivalRow}) }>
                <View style={ SectionStyle('rowForHRow', { 
                    flex: 1, 
                    backgroundColor: "transparent"
                })} >
                    <View style={ SectionStyle('rowFillStretchForRow', { 
                        justifyContent: "center",
                        backgroundColor: "transparent"
                    }) }>  
                        <BusArrivalTimeBox bus={busStatus} />
                    </View>
                </View>
            </View>
        )
    }
    return (
        <View style={ SectionStyle('busArrivalRowBg', { 
            backgroundColor: Colors.routeBusArrivalRow
        }) }>
            <View style={ SectionStyle('rowForHRow', { 
                flex: 1, 
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
                justifyContent: "flex-start",
                backgroundColor: "transparent"
            })} >
                <View style={ SectionStyle('rowLeftIconArea', {  }) }>
                {/* width: 35 */}
                {taglessCdText === "1" ?
                <LoadSvg name={svgName} width={Layouts.routeNodeMapBusIconSizeUp-14} height={Layouts.routeNodeMapBusIconSize-14} />
                 : 
                <FIcon type="fas" name="bus" size={22} color={ GBus.GetRouteTypeColor(JsUtil.GText(busStatus, "routeTypeCd")) } />
                }
                    
                </View>
 
                {/* 도착예정시간 */} 
                <View style={ SectionStyle('rowFill', { 
                    justifyContent: "center", maxWidth: Layouts.busArrivalTimeBoxWidth + 10, }) }>
                    <BusArrivalTimeBox bus={busStatus} width={Layouts.busArrivalTimeBoxWidth}/>
                </View>

                <View style={ SectionStyle('rowForHCol', { 
                    flex: 1, 
                    paddingTop: 5,
                    paddingBottom: 5,
                    backgroundColor: 'transparent', 
                    justifyContent: "space-around", 
                    alignItems: "stretch" 
                }) } >

                    <View style={ SectionStyle('rowFillForRow', { 
                        flex: 0,
                        justifyContent: "flex-start",
                        alignItems: "center" 
                    }) }>  
                        <BusArrivalNthBox bus={busStatus} />
                        <RemainSeatCntBox bus={busStatus} /> 
                        <DensityBox bus={busStatus} />
                        <BusAuxStatusBox bus={busStatus} />
                    </View>
                    <View style={ SectionStyle('rowFillForRow', {
                        flex: 0,
                        justifyContent: "flex-start",
                        alignItems: "center" 
                    }) }>  
                        <PlateTypeBox bus={busStatus} style={{ marginLeft: 0, width: "auto" }} />
                        <Text style={TextStyle('rowDetail', 'small', { marginHorizontal: 5, width: "auto"}) }> | </Text>
                        <PlateNoBox bus={busStatus} style={{ marginLeft: 0 }} />
                    </View>
                </View>
                {/* <BusRowRideSupportButton bus={busStatus} onPress={onPressBusNotify} bottomLabel="승차벨" />  */}
                
            </View>
        </View>
    )    
}    

