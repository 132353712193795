import React, { Component, useState, useEffect, useRef } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

// config
import LoadSvg from '../../../defines/Svgs';
import FIcon from '../../../defines/FIcon';
import { Colors, Theme } from '../../../defines/Theme';
import * as GBus from '../../../defines/GBus';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

const RenderDetailLine = ({ text }) => {
    return (
        <View style={{ paddingVertical: 0 }}>
            <Text numberOfLines={1} style={TextStyle('rowDetail', 'smallest', {
                lineHeight: 24,
                padding: 0, 
                paddingLeft: 0, 
                marginHorizontal: 0, 
                width: "100%", 
                color: Colors.fontCommentColor,
                textAlign: "left"
            })}>
                {text}
            </Text>
        </View>
    )
}

export default function StationRow({ item, navigation }) {
    return (
        <TouchableOpacity style={ SectionStyle('columnBgBottomBorder') }
            onPress={() => {
                navigation.navigate('searchPoiMap', { positionInfo: item });
            }}
        >
            <View style={ SectionStyle('rowForRow', {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: "transparent"
            }) }>
                {/* 아이콘 */}
                <View style={ SectionStyle('rowLeftIconArea') }>
                    <FIcon type="fad" name="map-marker-alt" size={30} />
                </View>
                <View style={ SectionStyle('rowFillStretchAround', { 
                    justifyContent: "space-between", 
                    paddingVertical: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    marginLeft: 0,
                    marginTop: 5,
                    marginBottom: 0,
                    backgroundColor: "transparent",
                }) }>
                    <Text numberOfLines={1} style={ TextStyle('rowTitle', 'huge', { 
                        color: Colors.fontColor,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginBottom: 5,
                        width: "100%",
                    }) }>
                    {item.name}
                    </Text>
                    <RenderDetailLine text={item.upperAddrName + ' ' + item.middleAddrName + ' ' + item.lowerAddrName + ' ' + item.detailAddrName} />
                </View>
            </View>
        </TouchableOpacity>
    )
}
