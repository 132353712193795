import React from 'react';
import {  Text, View, TouchableOpacity  } from 'react-native';
import { Colors, Layouts } from '../../../defines/Theme';
import FIcon from '../../../defines/FIcon';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import * as DateUtil from '../../../utils/DateUtil';

export default function RecentRow({ item, onPress }) {
    return (
        <View style={ SectionStyle('columnBgBottomBorder') }>
            {/* 행 */}
            <TouchableOpacity style={ SectionStyle('rowForRow', {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: "transparent"
            }) }
                onPress={() => { onPress?.() }}
            >
                {/* 아이콘 */}
                <View style={ SectionStyle('rowLeftIconArea') }>
                    <FIcon type="fad" name="history" size={26} color={Colors.fontCommentColor}/>
                </View>
                {/*  */}
                <View style={{ 
                    flex: 1,
                    alignSelf: 'stretch',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: "space-between", 
                    paddingVertical: 15,
                    paddingLeft: 5,
                    paddingRight: 5,
                    backgroundColor: "transparent"
                    }}>
                    <Text numberOfLines={1} style={ TextStyle('rowTitle', 'large', {
                        flex: 1,
                        color: Colors.fontColor,
                        marginTop: 0,
                        marginBottom: 0,
                    }) }>
                    {item.title}
                    </Text>
                    <Text numberOfLines={1} style={ TextStyle('rowDetail', 'smallest', { width: "auto"}) }>
                        { DateUtil.GetNthPrevName(item.time) }
                    </Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}


