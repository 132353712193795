import React, { useContext } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
//
import AlarmContext from '../../../context/AlarmContext';
// config
import AppSetting from '../../../defines/AppSetting';
import LoadSvg from '../../../defines/Svgs';
import * as GBus from '../../../defines/GBus';
import { GetText, GetTextSido } from '../../../defines/Texts';
import { Colors, Layouts } from '../../../defines/Theme';
import * as AlarmUtil from '../../../utils/AlarmUtil';
import * as JsUtil from '../../../utils/JsUtil';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';

const BusRowRideSupportButton = ({ bus, bottomLabel, onPress }) => {
    const context = useContext(AlarmContext)
    let activeAlarm = context.getActiveAlarm();
    let iconColor = Colors.gsYellow;
    if (AlarmUtil.HasBusActiveAlarm(bus, activeAlarm)) {
        iconColor = Colors.red;
    }
    if (!GBus.showBusNotifyFunction(JsUtil.GText(bus, "routeId"))) {
        return null;
    }
    return (<>
        {
            bottomLabel ?
                <View style={SectionStyle('rowRightButtonsAreaWithBottomLabel')} >
                    <TouchableOpacity 
                        style={SectionStyle('rowCircleButtonLarge', { backgroundColor: iconColor })}
                        onPress={() => { onPress?.(bus) }}
                    >
                        <LoadSvg name="helpRide" width={23} height={23} />
                    </TouchableOpacity> 
                    <Text style={TextStyle("tiny", { marginTop: 3 })}>{GetText("pageTabLabel", bottomLabel)}</Text>
                </View>
                : <View style={SectionStyle('rowRightButtonsArea')}>
                    <TouchableOpacity 
                        style={SectionStyle('rowCircleButtonLarge', { backgroundColor: iconColor })}
                        onPress={() => { onPress?.(bus) }}
                    >
                        <LoadSvg name="helpRide" width={23} height={23} />
                    </TouchableOpacity>
                </View>
        } 
    </>)
}


export default BusRowRideSupportButton