// Framework library
import { StyleSheet } from 'react-native';
import _ from 'lodash';
// Project styles
import { Fonts, OverrideUserFont } from '../defines/Fonts';
import { Colors, Layouts } from '../defines/Theme';
import Bug from '../utils/Bug';


export const styles = {
    _def: {
        backgroundColor: '#f00',
    },
    mapView: {
        flex: 1,
        // marginBottom: Layouts.bottomBarHeight,
        backgroundColor: Colors.appBackgroundColor,
    },
    // 지도에서 선택된 정류소 정보를 보여주는 Box
    selStationBoxBg: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: Layouts.bottomBarHeight,
        backgroundColor: 'transparent'
    },
    selStationBox: {
        borderColor: Colors.ggBlue,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: Colors.appBackgroundColor,
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 10,
        marginHorizontal: 10,
        paddingVertical: 5,
    },
    selStationBoxEmpty: {
        borderColor: Colors.borderColor,
        borderWidth: 0.5,
        borderRadius: 10,
        backgroundColor: Colors.appBackgroundColor,
        flexDirection: 'column',
        alignItems: 'center',
        marginVertical: 10,
        marginHorizontal: 10,
        paddingVertical: 15,
        height: Layouts.bottomBarHeight - 20,
    },
    myLocation: {
        position: 'absolute',
        right: 0,
        bottom: Layouts.bottomBarHeight + 90,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    addZoom: {
        position: 'absolute',
        left: 0,
        bottom: Layouts.bottomBarHeight + 135,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 10,
    },
    removeZoom: {
        position: 'absolute',
        left: 0,
        bottom: Layouts.bottomBarHeight + 90,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 10,
    },

};

const MapStyle = (name, customStyles = null) => {
    if (typeof customStyles === "string") {
        console.log("MapStyle.GetStyle() changed. font sized style should go to TextStyle()")
    }
    if (styles.hasOwnProperty(name)) {
        return _.merge({...styles[name]}, customStyles);
    }
    else {
        Bug('MapStyle()', `Undefined style name. '${name}'`, 9);
        return styles._def;
    }
}

export default MapStyle;