// 버스도착정보 페이지 
import React, { Component } from 'react';
import { Switch, Dimensions, StyleSheet, Text, View, TouchableOpacity, Image, Alert, Linking, NativeModules, Animated, TextBase } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NavigationContext } from '@react-navigation/native';
import SlidingUpPanel from 'rn-sliding-up-panel'
import Modal from 'react-native-modal';

import AlarmContext from '../../context/AlarmContext';

import AppSetting from '../../defines/AppSetting';
import FIcon from '../../defines/FIcon';
import Texts, { GetText } from '../../defines/Texts';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import DlgStyle from '../../styles/DlgStyle';
import { Colors, Layouts } from '../../defines/Theme';
import * as FavorUtil from '../../utils/FavorUtil';
import * as DateUtil from '../../utils/DateUtil';
import * as AlarmUtil from '../../utils/AlarmUtil';
import * as JsUtil from '../../utils/JsUtil';
import LoadSvg from '../../defines/Svgs'; 
import * as GBus from '../../defines/GBus';
import * as GBusHelper from '../../defines/GBusHelper';
import GetLocation from '../../gps/GetLocation';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';


import TabSelectBar from '../components/common/TabSelectBar';
import Refresh from '../components/common/Refresh';
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import RideSupportBottomTab from "../components/bottomTabs/RideSupportBottomTab";
import StationSimpleHeader from '../components/StationSimpleHeader';
import RouteHeader from '../components/RouteHeader';
import SpacerMiniV from '../components/common/SpacerMiniV';
import PastArrivalsShowBtn from './include/PastArrivalsShowBtn';
import PastArrivalsContents from './include/PastArrivalsContents';


//import RestAPI from '../../../api/RestAPI';
import GAPI from '../../api/RestAPI';

import BusRows from './include/BusRows';


let UserScriptApi = require('../../utils/UserScriptApi');

// const SharedStorage = NativeModules.SharedStorage;

// alarm mode
export const MODE = {
    NOTIFY: 0,  // 승차대기 요청 후 알람(optional)
    ALARM: 1,   // 승차 알람
}

// notify step
export const STEP = {
    IDLE: 0,
    REQ: 1,
    CANCEL: 2,
    DONE: 3,
    FAILED: 4
}

const HANDI_TYPES = {
    none: 0,
    handi: 1,
    old: 2,
    pregnant: 3,
//    withbaby: 4,
//    child: 5,
}
//const busNotifyState = ["idle", "requesting", "cancelling", "returned"]

// 정류소+노선+버스 로 승차지원
export default class RideSupport extends Component {

    
    //export default class StationRouteInfo extends Component {    
    // static contextType = NavigationContext;
    static contextType = AlarmContext;
 
    constructor(props) {
        super(props)

        // 변수초기화부분
        this.state = {
            // GPS
            userPosition: null,
            // 즐겨찾기에 등록여부
            stationRouteInFavor: false,
            stationRouteInFavorStaOrder: "",
            // 전달된 Parameter
            stationId: "",
            staOrder: "",
            routeId: "",
            turnSeq: "",
            // 조회된 정보들
            stationDetail: null,
            routeDetail: null,
            routeArrival: null,
            busArrivals: null,
            // 사용자 선택 모드 (승차대기, 승차알림)
            mode: MODE.NOTIFY,
            // 승차대기 진행 상태
            step: STEP.IDLE,
            // support options
            soHandi: 0,
            soLowBusOnly: false,
            soWantOnAlarm: false,
            // 
            bsOpened: false,
            //
            pastArrivalModalVisible: false
        }
        this.refresh = this.refresh.bind(this);
        this.refreshTimer = null;
        this.alarmSyncTimer = null,
        this.optionBS = React.createRef();

        this.bsOptionAnimateValue = new Animated.Value(Layouts.rowHeight);
        this.bsOptionHeight = Layouts.rowHeight * 2 + Layouts.rowHeight * 2;
        this.bsOptionDraggableRange = {
            top: Layouts.rowHeight * 2 + Layouts.rowHeight * 2,
            bottom: Layouts.rowHeight
        };
        this._onAnimatedValueChange = this._onAnimatedValueChange.bind(this) 
    }

    getTitle() {
        if (this.state.mode === MODE.NOTIFY && (this.state.soLowBusOnly || this.state.soHandi !== 0)) 
            return GetText("ridesupport", "LOW_BUS_ONLY_TITLE");
        if (this.state.mode === MODE.ALARM) 
            return GetText("ridesupport", "ON_ALARM_TITLE");
        return GetText("ridesupport", "TITLE");
    }

    componentDidMount() {
        // UserScriptApi.apiConnect('rideSupport')
        console.log(`[rideSupport] mount`)
        // const navigation = this.context;

        // activate refresh timer
        this._focus = this.props.navigation.addListener('focus', () => {
            console.log("[RideSupport] focused");
            let stationIdParam = JsUtil.GText(this.props.route.params, "stationId");
            let staOrderParam = JsUtil.GText(this.props.route.params, "staOrder") || JsUtil.GText(this.props.route.params, "stationSeq");
            let turnSeqParam = JsUtil.GText(this.props.route.params, "turnSeq");
            let routeIdParam = JsUtil.GText(this.props.route.params, "routeId");
            if (this.state.routeId !== routeIdParam ||
                this.state.stationId !== stationIdParam || 
                this.state.staOrder !== staOrderParam ||
                this.state.turnSeq !== turnSeqParam) {
                this.setState({
                    stationId: stationIdParam,
                    staOrder: staOrderParam,
                    routeId: routeIdParam,
                    turnSeq: turnSeqParam,
                    stationDetail: null,
                    routeDetail: null,
                    routeArrival: null,
                    busArrivals: null,
                }, () => {
                    this.syncGlobalToState();
                    this.refresh();
                    this.runPopupEvents();
                })
            }
            else {
                this.setState({ busArrivals: null }, () => {
                    this.refresh();
                })
            }
//             this.syncGlobalToState();
//            this.syncGlobalAlarm();
//            this.refresh();
            this.refreshTimer = setInterval(() => {
                this.refresh()
            }, AppSetting.GENERAL.AUTO_REFRESH_INTERVAL);            
            // 승차대기등 알람을 global 변수와 동기화
            // this.alarmSyncTimer = setInterval(() => {
            //     this.syncGlobalAlarm();
            // }, AppSetting.ALARM_SYNC_INTERVAL);
        })
        this._blur = this.props.navigation.addListener('blur', () => {
            console.log("[RideSupport] blured");
            if (this.refreshTimer) {
                clearInterval(this.refreshTimer);
            }
            // if (this.alarmSyncTimer) {
            //     clearInterval(this.alarmSyncTimer);
            // }
        });

        this.bsOptionAnimateValue.addListener(this._onAnimatedValueChange);
    }

    componentWillUnmount() {
        console.log("[rideSupport] unmount");
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
        if (this.alarmSyncTimer) {
            clearInterval(this.alarmSyncTimer);
        }
        this._focus?.();
        this._blur?.();
        this.bsOptionAnimateValue.removeListener(this._onAnimatedValueChange);
    }

    runPopupEvents() {
        if (this.state.stationId && this.state.routeId) {
            this.context.refreshRouteEvents(this.state.stationId, this.state.routeId, () => {
                if (this.context.routeEvents && this.context.routeEvents.length > 0) {
                    // subject, content, startDt, endDt, routeId
                    console.log(`[RideSupport] ${this.context.routeEvents.length} new route events found`)
                    this.context.msgHandler.popup({
                        title: GetText("pageTitle", "RUN_POP_EVENT"),
                        contentType: "event",
                        contents: this.context.routeEvents,
                        closeCallback: (shownIndexes) => {
                            // 
                        }
                    })
                }
            })
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        // if (this.props.content !== nextProps.content) {
        //   this.setState({changed: true});
        // }
        
        return null;
    }    

    syncGlobalToState(forcely) {
        // 정확히 일치하는 건 탐색 favorState = { favorIndex, favorChildRouteIndex, ... }
        let staOrderInFavor = this.state.staOrder;
        let favorState = FavorUtil.IsStationRouteInFavor(
            this.state.stationId,
            this.state.routeId,
            this.state.staOrder)
        console.log("=========================#1", favorState)
        // 하위호환성 확보. 회차점 이전 정류소면, stationId, routeId 만으로 탐색
        if (staOrderInFavor && 
            favorState && favorState.favorIndex >= 0 && favorState.favorChildRouteIndex < 0 && 
            JsUtil.isNumeric(this.state.staOrder) && JsUtil.isNumeric(this.state.turnSeq) &&
            parseInt(this.state.staOrder) < parseInt(this.state.turnSeq)) {
            favorState = FavorUtil.IsStationRouteInFavor(
                this.state.stationId,
                this.state.routeId)
            if (favorState.favorChildRouteIndex >= 0) {
                staOrderInFavor = "";
            }
            console.log("=========================#2", favorState)
        }
        // console.log("=========== debug here. favorIndex=" + favorIndex)
        this.setState({
            stationRouteInFavor: favorState.favorIndex >= 0 && favorState.favorChildRouteIndex >= 0,
            stationRouteInFavorStaOrder: staOrderInFavor
            // ride support options
            // soHandi: global.rsuser && global.rsuser ? global.rsuser.handi : false,
            // soLowBusOnly: global.rsuser && global.rsuser ? global.rsuser.lowbusonly : false,
        })
    }

    updateUserLocation() {
        GetLocation(this.context.msgHandler, (p) => { this.setState({ userPosition: p }) })
    }

    refresh(byUser) {
        console.log(`[rideSupport] refresh() byUser: ${byUser}`);
        this.updateUserLocation();
        if (!this.state.stationDetail || !this.state.routeDetail) {
            this._initStationRouteInfo(
                byUser,
                () => {
                    this._refreshRouteArrival(byUser)
                });
        }
        else {
            this._refreshRouteArrival(byUser);
        }
    }

    _initStationRouteInfo(byUser, callback) {
        console.log(`[RideSupport] _initStationRouteInfo(). S:${this.state.stationId}/${this.state.staOrder}, R:${this.state.routeId}, `)
        // Get station details
        GAPI.doRequest('getStationDetail', this.state.stationId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    this.setState({
                        stationDetail: res.response.msgBody.busStationInfo
                    })
                }
                else {
                    console.log(`[RideSupport] fail to get station detail`)
                    this.setState({
                        stationDetail: null
                    })
                    this.context?.msgHandler?.toast(GetText("error", "unknown"))
                }
            })
            .catch(err => {
                console.log(`[RideSupport] error to get station detail`)
                console.log(err);
                this.context?.msgHandler?.toast(GetText("error", "unknown"))
            })
        // Get route details
        GAPI.doRequest('getRouteDetail', this.state.routeId)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    this.setState({
                        routeDetail: res.response.msgBody.busRouteInfoItem
                    })
                    this.popupLastBusAlert(res.response.msgBody.busRouteInfoItem)
                    callback?.();
                }
                else {
                    console.log(`[RideSupport] fail to get route detail`)
                    this.setState({
                        routeDetail: null
                    })
                    this.context?.msgHandler?.toast(GetText("error", "unknown"))
                }
            })
            .catch(err => {
                this.setState({
                    routeDetail: null
                })
                console.log(`[RideSupport] error to get route detail`)
                console.log(err);
                this.context?.msgHandler?.toast(GetText("error", "unknown"))
            })
    }

    // 막차시간: 기점(00:00), 종점(00:00) \n이용에 참고 바랍니다.
    popupLastBusAlert(routeDetail) {
        if (routeDetail) {
            let isWeekend = [5, 6].includes(DateUtil.GetWeekday("INDEX"));
            let ups = []
            let dns = []
            if (isWeekend) {
                ups = [JsUtil.GText(routeDetail.weUpFristTime), JsUtil.GText(routeDetail.weUpLastTime)]
                dns = [JsUtil.GText(routeDetail.weDownFristTime), JsUtil.GText(routeDetail.weDownLastTime)]
            }
            else {
                ups = [JsUtil.GText(routeDetail.upFristTime), JsUtil.GText(routeDetail.upLastTime)]
                dns = [JsUtil.GText(routeDetail.downFristTime), JsUtil.GText(routeDetail.downLastTime)]
            }
            if (!GBusHelper.isInBusRunTime(ups[0], ups[1]) ||
                !GBusHelper.isInBusRunTime(dns[0], dns[1])) {
                let arr = []
                arr.push(ups[1] ? `기점(${ups[1]})` : "")
                arr.push(dns[1] ? `종점(${dns[1]})` : "")
                let m = `막차시각(${isWeekend ? "주말" : "평일"}): ` + arr.filter(elem => elem).join(", ") + `\n이용에 참고 바랍니다.`;
                this.context.msgHandler?.dlg(GetText("pageWord","ALERT"), m, Layouts.rowHeight * 1.3);
            }
        }
    }


    _refreshRouteArrival(byUser) {
        console.log(`[rideSupport] _refreshRouteArrival() byUser: ${byUser}`);
        if (byUser) showPageLoader(true)

        GAPI.doRequest('getStationRouteArrival', this.state.routeId, this.state.stationId, this.state.staOrder)
            .then((res) => {
                let { code, msg } = GAPI.parseResponseCode(res);
                if (code == 0) {
                    if (this.state.soLowBusOnly) {
                        this._refreshWithLowBus(res.response.msgBody.busArrivalItem, byUser);
                    }
                    else {
                        let routeArrival = res.response.msgBody.busArrivalItem;
                        GBusHelper.proveRouteRunStatus(routeArrival);
                        GBusHelper.proveBusRunStatusList(routeArrival, this.state.routeArrival);
//                        console.log("!!!!!!!!!!!!!!!----", routeArrival)
                        this.setState({
                            routeArrival: routeArrival,
                            busArrivals: routeArrival.busStatusList 
                        })
                    }
                }
                else {
                    console.log(`[rideSupport] fail to get runningBusList. response code: ${code}, msg: ${msg}`)
                    console.log(res)
                    this.setState({
                        routeArrival: null,
                        busArrivals: []
                    })
                    if (byUser) {
                        this.context?.msgHandler?.toast(GetText("error", "unknown"))
                    }
                }
            })
            .catch(err => {
                this.setState({ 
                    routeArrival: null,
                    busArrivals: []
                })
                console.log("Unknown request error...");
                console.log(err);
                console.log("[rideSupport] _refreshRouteArrival() -> err -> setState()")
                if (byUser) {
                    this.context?.msgHandler?.toast(GetText("error", "unknown"))
                }
            })
            .finally(() => {
                showPageLoader(false)
            })
    }

    // 
    _refreshWithLowBus(routeArrival, byUser) {
        GBusHelper.proveRouteRunStatus(routeArrival);
        GBusHelper.proveBusRunStatusList(routeArrival, this.state.routeArrival);
        console.log(`[rideSupport] _refreshWithLowBus() byUser: ${byUser}`);
        // 근거리 저상버스 2대
        if (routeArrival.routeStatus.isStopped || GBusHelper.getAvailableLowBusIndexes(routeArrival).length > 1) {
            this.setState({
                routeArrival: routeArrival,
                busArrivals: routeArrival.busStatusList
            })
        }
        else {
            // 노선의 운행 버스 전체 조회
            GAPI.doRequest('getRunningBusOfRoute', JsUtil.GText(routeArrival, "routeId"))
                .then((res) => {
                    let arrivals = []
                    let { code, msg } = GAPI.parseResponseCode(res);
                    if (code == 0) {
                        let runningBusList = null;
                        if (Array.isArray(res.response.msgBody.busLocationList)) {
                            runningBusList = res.response.msgBody.busLocationList;
                        }
                        else {
                            runningBusList = [res.response.msgBody.busLocationList];
                        }
                        GBusHelper.getAvailableBusArrivals(routeArrival, runningBusList, this.state.routeArrival)
                    }
                    let lowBusIndexes = GBusHelper.getAvailableLowBusIndexes(routeArrival);
                    if (lowBusIndexes.length < 1 && byUser) {
                        this.context?.msgHandler?.dlg(GetText("pageTabLabel","OK"), GetText("rideSupport", "low_bus_not_found"))
                        this.setState({
                            soLowBusOnly: false,
                        })
                    }
                    else if (lowBusIndexes.length < 2 && byUser) {
                        this.context?.msgHandler?.toast(GetText("rideSupport", "low_bus_partially"), byUser);
                    }
                    this.setState({
                        routeArrival: routeArrival,
                        busArrivals: routeArrival.busStatusList
                    })
                })
                .catch(err => {
                    this.setState({
                        routeArrival: routeArrival,
                        busArrivals: routeArrival.busStatusList
                    })
                    console.log("Unknown request error...");
                    console.log(err);
                    console.log("[rideSupport] _refreshWithLowBus() -> err -> setState()")
                    if (byUser) {
                        this.context?.msgHandler?.toast(GetText("error", "unknown"));
                    }
                })

        }
    }


    reqBusNotify(busArrival) {
        // this.postActionBS?.hide();
        // this.optionBS?.show(Layouts.rowHeight * 2);
        // return;

        let { can, msg } = GBus.canRequestBusNotify(
            this.state.userPosition,
            this.state.stationDetail,
            this.state.routeDetail,
            JsUtil.GText(busArrival, "vehId"), 
            JsUtil.GText(busArrival, "plateNo"), 
            JsUtil.GText(busArrival, "locationNo"));
        if (!can) {
            this.context?.msgHandler?.dlg(GetText("pageWord","ALERT"), msg)
            return;
        }

        if (this.state.routeDetail && this.state.routeArrival) {
            console.log(`[RideSupport] request bus notify... `)
            this.setState({ step: STEP.REQ }, () => {
                GAPI.doRequest('getBusNotify',
                    this.state.stationDetail.stationId._text,
                    this.state.routeArrival.staOrder._text,
                    this.state.routeDetail.routeId._text,
                    JsUtil.GText(busArrival, "vehId"),
                    this.state.soHandi) // handi flag
                    .then((res) => {
                        let { code, msg } = GAPI.parseResponseCode(res);
                        if (code == 0) {
                            console.log(`[RideSupport] bus notify done`, res.response.msgBody.busWaitingItem)
                            // save to global var
                            this.context.setBusNotify({
//                            AlarmUtil.SaveBusNotify({
                                reqId: res.response.msgBody.busWaitingItem.notifyRegId._text,
                                sendTerminalYn: res.response.msgBody.busWaitingItem.sendTerminalYn._text,
                                reqTime: DateUtil.format(DateUtil._DT_FORMAT_COMPACT),
                                stationId: this.state.stationDetail.stationId._text,
                                stationSeq: this.state.routeArrival.staOrder._text,
                                stationName: this.state.stationDetail.stationName._text,
                                staOrder: this.state.routeArrival.staOrder._text,
                                x: this.state.stationDetail.x._text,
                                y: this.state.stationDetail.y._text,
                                routeTypeCd: this.state.routeDetail.routeTypeCd._text,
                                routeId: this.state.routeDetail.routeId._text,
                                routeName: this.state.routeDetail.routeName._text,
                                vehId: JsUtil.GText(busArrival, "vehId"),
                                plateNo: JsUtil.GText(busArrival, "plateNo"),
                                prevNth: JsUtil.GText(busArrival, "locationNo"),
                                handiCd: this.state.soHandi
                            });
                            // if (this.optionBS) {
                            //     this.optionBS.show(0);
                            // }
                            // this.syncGlobalAlarm(true)
                            // 상태값을 업데이트하면, BottomSheet 열림 
                            this.setState({
                                step: STEP.DONE,
                                // bsOpened: true,
                                soWantOnAlarm: false,
                            }, () => {

                            })

                            if (JsUtil.GText(res.response.msgBody.busWaitingItem, "sendTerminalYn") === "Y") {
                                this.context.msgHandler?.dialog({
                                    title: GetText("pageTabLabel", "OK"), 
                                    message: GetText("ridesupport", "NOTIFY_ACCEPT_AND_TRANSFERED"),
                                    messageHeight: Layouts.rowHeight * 1.3,
                                    ok: () => {
                                        this.context.linkBusNotifyToOnAlarm();                                    
                                    },
                                    cancel: () => {}
                                })                                        
                            }
                            else {
                                this.context.msgHandler?.dialog({
                                    title: GetText("pageTabLabel", "OK"), 
                                    message: GetText("ridesupport", "NOTIFY_ACCEPTED"),
                                    messageHeight: Layouts.rowHeight * 1.3,
                                    ok: () => {
                                        this.context.linkBusNotifyToOnAlarm();                                    
                                    },
                                    cancel: () => {}
                                })                                 
                                //this.context.msgHandler?.toast(GetText("ridesupport", "NOTIFY_ACCEPTED"))
                            }
                        }
                        else {
                            console.log(`[RideSupport] fail to request bus notify. ${code}, ${msg}`)
                            this.context?.msgHandler?.dlg("ERROR", GetText("ridesupport", "failed"))
                            this.setState({ step: STEP.IDLE }, () => {
                                
                            })
                            // this.handleError?.(msg, code);
                        }
                    })
                    .catch(err => {
                        console.log(`[RideSupport] fail to request bus notify:`)
                        console.log(err);
                        this.context?.msgHandler?.toast(GetText("ridesupport", "error"), true)
                        this.setState({ step: STEP.IDLE })
                        // this.handleError?.(GetText("error", "unknown"));
                    })
                    .finally(() => {
                        // this.setState({ busNotifyBusy: false }, () => {
                        //     this.syncGlobalAlarm(true);
                        // })
                    })

            })
        }
        else {
            this.context?.msgHandler?.toast(GetText("ridesupport", "local_busy"), true)
        }
    }


    setOnAlarm(busArrival) {
        
        let { can, msg } = GBus.canSetOnAlarm(
            this.state.userPosition,
            this.state.stationDetail,
            busArrival.vehId._text,
            busArrival.locationNo._text);
        if (!can) {
            this.context?.msgHandler?.toast(msg, true)
            return;
        }
        if (this.state.routeDetail && this.state.routeArrival) {
            this.context.setOnAlarm({
                stationId: this.state.stationDetail.stationId._text,
                stationName: this.state.stationDetail.stationName._text,
                stationSeq: this.state.routeArrival.staOrder._text,
                x: this.state.stationDetail.x._text,
                y: this.state.stationDetail.y._text,
                routeTypeCd: this.state.routeDetail.routeTypeCd._text,
                routeId: this.state.routeDetail.routeId._text,
                routeName: this.state.routeDetail.routeName._text,
                vehId: busArrival.vehId._text,
                plateNo: busArrival.plateNo._text,
                prevNth: busArrival.locationNo._text,
            });
            this.context?.msgHandler?.toast(GetText("ridesupport", "onalarm_done"), true)
        }
        else {
            this.context?.msgHandler?.toast(GetText("ridesupport", "local_busy"), true)
        }
    }

    _onAnimatedValueChange({ value }) {
        const {top, bottom} = this.bsOptionDraggableRange
        if (value === top) {
          this.setState({ bsOpened: true })
        }
        if (value === bottom) {
          this.setState({ bsOpened: false})
        }
      }    

    renderOptionDragHeader = () => {
        return (<>
            <TouchableOpacity style={CommonStyle("boxFill")}
                onPress={() => {
                    if (this.state.bsOpened) {
                        this.optionBS?.hide();
                    }
                    else {
                        this.optionBS?.show(this.bsOptionDraggableRange.top)
                    }
                    // console.log("------------------------------------");
                    // console.log(this.optionBS);
                }}
            >
                <View style={DlgStyle("rideSupportDlgDraggerBg")}>
                    <View style={DlgStyle("rideSupportDlgDragger")} />
                </View>
                <View style={SectionStyle("rowForRow", { justifyContent: "space-between", flex: 1, paddingTop: 5})}>
                    <View style={{width: 20, marginLeft: 20}}/>
                    <Text style={TextStyle("rowDetail", "medium", {
                        color: Colors.whiteLight,
                        // color: Colors.ggBlue,
                        textAlign: "center"
                    })}>{GetText("ridesupport", "header")}</Text>
                    <FIcon 
                        type="fad"
                        name={this.state.bsOpened ? "chevron-double-down"  : "chevron-double-up" }
                        color={ Colors.whiteLight } 
                        size={20}
                        style={{ marginRight: 20 }}
                    />
                </View>
            </TouchableOpacity>
        </>)
    }

    renderHandiOption = (id) => {
        return (<>
            <TouchableOpacity style={CommonStyle("box", {  })}
                onPress={() => {
                    console.log("==========set handi==============")
                    if (this.state.soHandi === HANDI_TYPES[id]) {
                        console.log("==========set handi: 0")
                        this.setState({ soHandi: HANDI_TYPES["none"] })
                    }
                    else {
                        console.log("==========set handi: " +HANDI_TYPES[id])
                        this.setState({ soHandi: HANDI_TYPES[id] })
                    }                    
                }}
            >
                <View style={SectionStyle("rowForRow", { alignItems: "center" })}>
                    <Text style={TextStyle("rowDetail", "medium", { color: Colors.ggBlue, marginHorizontal: 10 })}>
                        {GetText("handi", id)}
                    </Text>
                    <FIcon 
                        type={this.state.soHandi === HANDI_TYPES[id] ? "fas" : "fad"}
                        name="check-circle" 
                        color={this.state.soHandi === HANDI_TYPES[id] ? Colors.ggBlue : Colors.borderColor} size={30}
                    />
                </View>
            </TouchableOpacity>
        </>)
    }


    renderOptionRows = () => {
        return (
        <View style={CommonStyle("box", {
                padding: 5, 
                height: Layouts.rowHeight * 3,
                backgroundColor: Colors.appBackgroundColor})}>
            <View style={SectionStyle("columnBgNoBorder", { 
                flex: 1,
                justifyContent: "flex-end", 
                alignItems: "center",
                paddingVertical: 0,
                //backgroundColor: "#f00"
                })}>
                <View style={CommonStyle("box", {
                    borderRadius: 15,
                    backgroundColor: Colors.silver,
                    padding: 7,
                    paddingHorizontal: 15
                })}>
                    <Text style={TextStyle("rowDetail", {
                        color: Colors.ggBlue,
                        textAlign: "center"
                    })}>{GetText("ridesupport", "plz_select_handi")}</Text>                                
                </View>  
            </View>
            <View style={SectionStyle("rowForHRow", { justifyContent: "space-around", paddingRight: 10 })}>
                { this.renderHandiOption("handi")}
                { this.renderHandiOption("old")}
                { this.renderHandiOption("pregnant")}
                {/* { this.renderHandiOption("withbaby")}
                { this.renderHandiOption("child")} */}
            </View>
            <View style={{height: Layouts.rowHeight * 0.2}}></View>
            <View style={SectionStyle("rowForHRow", { 
                backgroundColor: Colors.ggLightBlue,
                borderRadius: 10,
             })}
                // onPress={() => {
                //     this.setState({ soLowBusOnly: !this.state.soLowBusOnly }, () => {
                //         this.refresh(true);
                //     });
                //     this.optionBS.hide();
                // }}
            >
                <View style={{ width: 35, marginHorizontal: 10, marginLeft: 40 }}>
                    <FIcon type="fad" name="wheelchair" color={Colors.ggBlue} size={30} />
                </View>
                <Text style={TextStyle("rowDetail", "medium", { flex: 1, color: Colors.ggBlue })}>
                    {GetText("ridesupport", "LOWBUS_ONLY_GUIDE")}</Text>
                <View style={{ justifyContent: "center", alignItems: "center", width: 50, marginRight: 30 }}>
                    <Switch
                        trackColor={{ false: Colors.silver, true: Colors.appBackgroundColor }}
                        thumbColor={ this.state.soLowBusOnly ? Colors.ggBlue : Colors.borderLightColor}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={
                            () => {
                                this.setState({ soLowBusOnly: !this.state.soLowBusOnly }, () => {
                                    this.refresh(true);
                                });
                            }}
                        value={this.state.soLowBusOnly}
                    />
                    {/* <FIcon type="fas" name="check" color={this.state.soLowBusOnly ? Colors.ggBlue : Colors.borderLightColor} size={30} /> */}
                </View>
            </View>
        </View>)
    }

    render() {
        // let activeAlarm = alarmContext.getActiveAlarm();
        // console.log(JSON.stringify(this.props.route.params.data, null, 4))
        return (
            
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeaderEx
                    title={{
                        iconType: "fad",
                        iconName: this.state.soLowBusOnly || this.state.soHandi !== HANDI_TYPES.none
                                     ? "wheelchair" : (this.state.mode === MODE.ALARM ? "alarm-clock" : ""),
                        label: this.getTitle(),
                        iconColor: Colors.ggBlue
                    }}
                />

                <View style={CommonStyle("body")}>
                    {/* <View style={SectionStyle("stationRouteHeaderBg")} >  */}
                    <View style={CommonStyle("box", {
                        flexDirection: "column",
                        alignItems: "stretch",
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 0,
                        marginRight: 0,
                    })} >
                        {/* Station :: go to StationInfo */}
                        <StationSimpleHeader
                            station={this.state.stationDetail}
                            onPress={() => {
                                if (this.state.stationDetail) {
                                    this.props.navigation.navigate('stationInfo', {
                                        data: (({ stationId, stationName, x, y }) => ({ stationId, stationName, x, y }))(this.state.stationDetail)
                                    })
                                }
                            }} />
                        <RouteHeader
                            station={this.state.stationDetail}
                            route={this.state.routeDetail}
                            routeArrival={this.state.routeArrival}
                            onPress={() => {
                                if (this.state.routeDetail) {
                                    this.props.navigation.navigate('routeInfo', {
                                        data: {
                                            stationId: this.state.stationId,
                                            staOrder: this.state.staOrder,
                                            routeId: this.state.routeId
                                        }
                                    })
                                }
                            }} />
                    </View>
                    <SpacerMiniV />
                    <View style={CommonStyle("body")}>
                        <BusRows
                            routeArrival={this.state.routeArrival}
                            activeAlarm={this.context.getActiveAlarm()}
                            onPressBusNotify={null}
                        />
                        {this.state.stationDetail && this.state.routeDetail &&
                            JsUtil.GText(this.state.routeDetail, 'pastFlag') === 'Y' &&
                            (
                            <PastArrivalsShowBtn
                                onClick={() => {
                                    this.setState({
                                        pastArrivalModalVisible: true
                                    })
                                }}
                            />
                        )}
                    </View>
                </View>
                <Refresh onPress={() => { this.refresh(true) }} 
                    bottom={0} 
                />
                <RideSupportBottomTab
                    navigation={this.props.navigation}
                    // onOnAlarmPress={() => {
                    //     this.setState({ mode: this.state.mode === MODE.ALARM ? MODE.NOTIFY : MODE.ALARM })
                    // }}
                    onBackPress={() => {
                        const { navigation } = this.props;
                        navigation.goBack();
                        // this.props.route.params
                        this.props.route.params.navCallback?.({ 
                            stationId: this.state.stationId,
                            staOrder: this.state.staOrder,
                            routeId: this.state.routeId,
                        });
                    }}
                    onReviewPress={() => {
                        // this.context.msgHandler?.dlg(GetText("pageWord","ALERT"), GetText("OPEN_SOON", "OPEN_SOON"))
                        // return;
                        if (this.state.stationDetail && this.state.routeDetail) {
                            this.props.navigation.navigate('busEval', {
                                station: this.state.stationDetail,
                                stationId: JsUtil.GText(this.state.stationDetail, "stationId"),
                                route: this.state.routeDetail,
                                routeId: JsUtil.GText(this.state.routeDetail, "routeId"),
                            })
                        }
                    }}
                    onDeclarePress={() => { Linking.openURL('tel:031-120') }}
                />
                <Modal
                    deviceWidth={Dimensions.get('window').width}
                    deviceHeight={Dimensions.get('window').height}
                    //isVisible Props에 State 값을 물려주어 On/off control
                    isVisible={this.state.pastArrivalModalVisible}
                    useNativeDriver={true}
                    hideModalContentWhileAnimating={true}
                    style={DlgStyle('dialogModal')}
                    // open={open}
                    onModalHide={() => {
                        this.setState({
                            pastArrivalModalVisible: false
                        })
                    }}>
                    <PastArrivalsContents
                        station={this.state.stationDetail}
                        staOrder={this.state.staOrder}
                        route={this.state.routeDetail}
                        onClose={() => {
                            this.setState({
                                pastArrivalModalVisible: false
                            })
                        }}
                    />
                </Modal>
            </SafeAreaView>
        )
    }
}

