import React, { Component, useState, useEffect, useRef } from 'react';
import { Platform, StyleSheet, Text, View, TouchableOpacity, TextInput, Image, TouchableWithoutFeedback, Keyboard, FlatList, ScrollView, Alert } from 'react-native';
import { Header } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { NavigationContext } from '@react-navigation/native';
// import Geolocation from '@react-native-community/geolocation';
import AsyncStorage from '@react-native-community/async-storage';

// deprecated
import Constants, { ConvStyle, getPrevColorNew, getColorFromSubway, getColorFromBusNew } from '../../settings/Constants';
//import Colors from '../../settings/Colors';
// context
import AlarmContext from '../../context/AlarmContext';

//  config, lib
import GetLocation from '../../gps/GetLocation';
import { Colors, Layouts } from '../../defines/Theme';
import Texts, { GetText, GetTextSido } from '../../defines/Texts';
import FrRestAPI from '../../api/FrRestAPI';
import FIcon from '../../defines/FIcon';
import CommonStyle from '../../styles/CommonStyle';
import TextStyle from '../../styles/TextStyle';
import SectionStyle from '../../styles/SectionStyle';
import * as HistoryUtil from '../../utils/HistoryUtil';
import * as JsUtil from '../../utils/JsUtil';
import * as TMap from '../../defines/TMap';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';


// shared
import MainBottomTab from '../components/bottomTabs/MainBottomTab';
import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';
import TabSelectBar from '../components/common/TabSelectBar';
import ListEmpty from '../components/parts/ListEmpty';
import SpacerListBottom from '../components/common/SpacerListBottom';

// local
import styles from './include/FrStyle';
import FrBusRow from './abbrRows/FrBusRow';
import FrSubwayRow from './abbrRows/FrSubwayRow';
import FrEndRow from './abbrRows/FrEndRow';
import FrGeneral from './include/FrGeneral';
import FrArea from './include/FrArea';
import FrHistory from './include/FrHistory';

let UserScriptApi = require('../../utils/UserScriptApi');
let convert = require('xml-js');

const mainStyle = {
    body: {
        flex: 1,
        marginBottom: Layouts.bottomBarHeight,
        // backgroundColor: "#00f",
        alignItems: 'unset'
    },

    listView: {
        flex: 1,
        backgroundColor: Colors.appBackgroundColor,
        zIndex: 999999
    }
}

// 길찾기 결과 현시 목록뷰
const RenderPathTypeTab = ({ activeTab, data, onTabSelect }) => {
    
    let busCount = 0;
    let subwayCount = 0;
    let transferCount = 0;
    let allCount = 0;

    if(data.routes){        
        data.routes.forEach((elem) => {
            allCount = allCount + 1;
            if(elem.transitModes=='BUS'){
                busCount = busCount + 1;
            } else if(elem.transitModes=='SUBWAY'){
                subwayCount = subwayCount + 1;
            } else if(elem.transitModes.length==2){
                transferCount = transferCount + 1;
            }
        })
    }
    return (
        <TabSelectBar tabs={{
            all: "전체",
            bus: "버스",
            subway: "지하철",
            transfer: "버스+지하철",
        }}
            suffixes={{
                all: `(${allCount})`,
                bus: `(${busCount})`,
                subway: `(${subwayCount})`,
                transfer: `(${transferCount})`,
            }}
            activeId={activeTab}
            onPress={(id) => { onTabSelect?.(id) }}
            //useFullRow={true}
            // style={{backgroundColor: Colors.whiteLight }}
            textStyle={{ color: Colors.fontColor }}
        />
    )
}

const getPathArray = (type, paths) => { // check point - type...???
    let arr = [];    
    
    if(paths && paths.routes){
        
        paths.routes.forEach((elem) => {            
            if(type === "all"){
                arr = [...arr, elem];
            } else if (type === "bus") {
                if(elem.transitModes.length==1 && elem.transitModes=='BUS'){
                    arr = [...arr, elem];
                }
            } else if (type === "subway") {
                if(elem.transitModes.length==1 && elem.transitModes=='SUBWAY'){
                    arr = [...arr, elem];
                }
            } else if (type === "transfer") {
                if(elem.transitModes.length==2){
                    arr = [...arr, elem];
                }
            }      
        })
    }

    return arr;
}

// export function getTimeHMS(time){
//     let seconds = time * 1;        
//     let str = "";
//     if(parseInt(seconds/3600)>0){
//         str = parseInt(seconds/3600) + '시간' 
//         if(parseInt((seconds%3600)/60)>0) {
//             str = str + ' '    + parseInt((seconds%3600)/60) + '분'
//         }
//     } else {
//         str = parseInt((seconds%3600)/60) + '분'
//     }
//     return str
// }

export function RenderFoundPaths(props) {
    const flatRef = useRef(null);
    const [type, setType] = useState('all');
    // const [pathArr, setPathArr] = useState(getPathArray("all", props.data))
    // [paths, setPaths] = useState(getPathArray("all", props.data))
    // console.log("RenderFoundPaths props", useState(getPathArray("all", props.data)))
    // console.log("getPathArray", getPathArray("all",props.data))
    const paths = getPathArray(type, props.data);    
    useEffect(() => {
        // setPathArr(getPathArray(type, props.data))        
        try {
            flatRef.current?.scrollToOffset({
                animated: false,
                offseet: 0
            })
        } catch (ex) { }
    }, [type])    
    useEffect(() => {
        setType("all")
    }, [props?.data])            

    return (        
        // <TouchableWithoutFeedback onPress={Keyboard.dismiss} >
        // marginBottom: Constants.BOTTOM_BAR_HEIGHT 
        <View style={{ flex: 1, alignItems: 'unset' }}>
            <RenderPathTypeTab
                activeTab={type}
                onTabSelect={(type) => {
                    setType(type)                    
                }}
                data={props.data}
            />
            {/* <View style={styles.listView}> */}
            <View style={CommonStyle("listBg", { 
                    flex: 1
                })}>
                <FlatList
                    style={{ flex: 1 }}
                    ref={flatRef}
                    removeClippedSubviews={true}
                    legacyImplementation={true}
                    windowSize={50}
                    initialNumToRender={5}
                    data={paths}
                    ListHeaderComponent={
                        <View style={{ height: 10, backgroundColor: Colors.background }} />
                    }
                    ListEmptyComponent={<ListEmpty text={GetText("findpath", "NO_SEARCH_RESULTS")} />}
                    renderItem={({ item, index, separators }) => {
                        return (
                            <RenderPathAbbrBox item={item} navigation={props.navigation} start={props.start} end={props.end}  />
                        )
                    }}
                    refreshing={false}
                    keyExtractor={(item, index) => {return'key' + index.toString()}}
                />
            </View>
        </View>
        // </TouchableWithoutFeedback>
    )
    
}


export class RenderPathAbbrBox extends Component {
    
    
    render() {       

        
        const { key, item, navigation, start, end } = this.props
        const itemArray = [];
        
        if(item.steps){                        
            item.steps.forEach((pathDetail) => {
                if(pathDetail.transitMode != "WALKING"){
                    itemArray.push(pathDetail)
                    // console.log("pathDetail", pathDetail)
                }
            })
            if(item.steps[item.steps.length-1].transitMode == 'WALKING'){
                itemArray.push(item.steps[item.steps.length-1])
            }
        }



        return (            
            <TouchableOpacity onPress={() => {
                console.log("item",item)
                navigation.navigate('frDetailPage', { data: item, start: start , end: end, info: end, }) 
                }} 
            style={{
                flexDirection: 'column',
                padding: 10,
                borderColor: Colors.borderColor,
                borderWidth: 1,
                backgroundColor: Colors.white,
                justifyContent: 'center',
                marginBottom: 15,
                ...styles.shadow
            }}>                
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    padding: 10,
                }}                
                accessibilityLabel="지도에서 보기"
                accessibilityRole="button">
                    
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                    }}>
                        <Text style={TextStyle("large")}>
                            약 {TMap.getTimeHMS(item.duration)} | {TMap.GetFriendlyDistance(item.distance)} | {item.fare==='요금정보 미표기' ? "-" : '약 ' + item.fare}
                        </Text>
                    </View>
                    <View >
                        
                        <FIcon type="fad" name="map" size={25} color={Colors.fontColor} />
                    </View>
               
                </View>

                <View style={{
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    borderWidth: 0.7,
                    borderColor: Colors.borderColor
                }}>
                    
                    {
                        itemArray ? 
                            Array.isArray(itemArray) ?
                                itemArray.map((item, index) => {
                                    return (
                                        index > 0 ?
                                            index !==itemArray.length-1 ?
                                                item.bus ? 
                                                    <FrBusRow 
                                                        key={'innerIndex' + index}
                                                        beforeColor={getPrevColorNew(index, itemArray)}
                                                        selfColor={getColorFromBusNew(JsUtil.GText(item.bus, "typeName"))}
                                                        item={item} />
                                                    :
                                                    <FrSubwayRow 
                                                        key={'innerIndex' + index}
                                                        beforeColor={getPrevColorNew(index, itemArray)}
                                                        selfColor={getColorFromSubway(JsUtil.GText(item.subway, "name"))}
                                                        item={item} />
                                                
                                                :
                                                <FrEndRow
                                                    key={'innerIndex' + index}
                                                    beforeColor={
                                                        itemArray[index-1].bus ? 
                                                            getColorFromBusNew(JsUtil.GText(itemArray[index-1].bus, "typeName")) :
                                                            getColorFromSubway(JsUtil.GText(itemArray[index-1].subway, "name"))
                                                    }
                                                    // item={itemArray[index-1].bus ? 
                                                    //         itemArray[index-1].endStationName: 
                                                    //         JsUtil.GText(itemArray[index-1].subway,"headSign")} />
                                                    item={ itemArray[index-1].endStationName } />
                                            : 
                                            item.bus ? 
                                                <FrBusRow key={'innerIndex0'} beforeColor={'transparent'} selfColor={getColorFromBusNew(JsUtil.GText(item.bus, "typeName"))} item={item} /> :                                                
                                                item.subway ?              
                                                    <FrSubwayRow key={'innerIndex0'} beforeColor={'transparent'} selfColor={getColorFromSubway(JsUtil.GText(item.subway, "name"))} item={item} /> :
                                                    null
                                        )
                                }) 
                            : null
                        : null
                    }
                  
                </View>
            </TouchableOpacity>
        )
    }
}

// 메인 뷰
export default class FrMain extends Component {
    static contextType = AlarmContext
    constructor(props) {
        super(props)

        this.scrollRef = React.createRef();
        this.state = {
            getCityDo: null,
            initialPosition: 'unknown',
            title: '길찾기',
            startPOI: null,
            endPOI: null,
            paths: null,    
            // 지역간 길찾기
            areaStartDong: null,
            areaEndDong: null,
            // areaStartArr: null,
            // areaEndArr: null,
            searchState: {},
        }
        this.searchRoad = this.searchRoad.bind(this);
    }

    // 일반길찾기에서 지역간 길찾기 스크롤 제목변경
    handleScroll = (event) => {
        if (event.nativeEvent.contentOffset.x < Constants.WINDOW_WIDTH / 2) {
            this.setState({ title: '길찾기' })
        } else {
            this.setState({ title: '지역(읍/면/동)간 길찾기' })
        }
    }

    // 출, 도착지점이 결정되면 자동으로 두 지점사이에 길찾기 API를 호출한다.
    searchRoad() {
        console.log("출도착지??", global)
        let startTitle = global.startPOI.title ?? '출발지';
        let endTitle = global.endPOI.title ?? '도착지';

        let reqHeader = new Headers({
        "x-api-key":Constants.X_API_KEY,
        'Content-Type': 'application/json',
        'Access-Control-Request-Method':'GET',
        });        
        let serverURL = `https://gg-bis-open.tmobiapi.com/pubtrans/routeplan?`
         + `origin=`
         + startTitle + `,` + this.state.startPOI.y + `,` + this.state.startPOI.x 
         + `&destination=` 
         + endTitle +   `,` + this.state.endPOI.y + `,` + this.state.endPOI.x
         + `&ap-code=` + Constants.AP_CODE
         + `&x-api-key=` + Constants.X_API_KEY;
         console.log(serverURL)
        showPageLoader(true);
        return (            
            fetch(serverURL, { method: 'GET', headers: reqHeader })  
                .then(response => response.text().then(text => {
                    if (text == null){
                        console.log("text", text);
                    }
                    
                    let res = JSON.parse(text, { compact: true, spaces: 4 });
                    
                    // console.log("this is the test result", JSON.stringify(res))                    
                    
                    if(res){
                        this.setState({
                            paths: res, // check Point - 검색결과는 routes 인데 그냥 res 통으로 입력하는게 좋음
                        })
                    }
                }))
                .catch((error) => {
                    console.log(error)
                    // console.log(error)
                    // console.log(error)
                    this.setState({
                        paths: null,
                    })
                })
                .finally(() => {
                    console.log("finally>>>>>>>> ")
                    // global.startPOI = null;
                    // global.endPOI = null;
                    HistoryUtil.InsertPathFindHistory(this.state.startPOI, this.state.endPOI, () => {
                        this.setState({
                            searchState: { count: this.state.searchState.count ? this.state.searchState.count + 1 : 1 }
                        })
                    });
                    showPageLoader(false)                    
                })            
        )
    }

    // 출, 도착지점이 결정되면 자동으로 두 지점사이에 길찾기 API를 호출한다.
    searchRoadFromArea(sX, sY, eX, eY) {        
        
        let startTitle = this.state.areaStartDong.address ?? '출발지역';
        let endTitle = this.state.areaEndDong.address ?? '도착지역';

        console.log('startTitle', startTitle)
        let reqHeader = new Headers({
            "x-api-key":Constants.X_API_KEY,
            'Content-Type': 'application/json',
            'Access-Control-Request-Method':'GET',
            });        
        let serverURL = `https://gg-bis-open.tmobiapi.com/pubtrans/routeplan?`
         + `origin=`
         + startTitle + `,` + sY + `,` + sX
         + `&destination=` 
         + endTitle +   `,` + eY + `,` + eX
         + `&ap-code=` + Constants.AP_CODE
         + `&x-api-key=` + Constants.X_API_KEY;
         console.log(serverURL)
        showPageLoader(true);
        return (            
            fetch(serverURL, { method: 'GET', headers: reqHeader })  
                .then(response => response.text().then(text => {
                    if (text == null){
                        console.log("text", text);
                    }
                    
                    let res = JSON.parse(text, { compact: true, spaces: 4 });
                    console.log("=asdfl;lfdassfda;ksd;lkasfd;klsfd;")
                    // console.log("this is the test result", JSON.stringify(res))
                    console.log("res from area >>>>>>>> ",res)
                    
                    if(res){
                        this.setState({
                            paths: res, // check Point - 검색결과는 routes 인데 그냥 res 통으로 입력하는게 좋음
                        })
                    }

                    console.log("searchRoadFromArea states paths is ", this.state.paths)
                }))
                .catch((error) => {
                    console.log(error)
                    // console.log(error)
                    // console.log(error)
                    this.setState({
                        paths: null,
                    })
                })
                .finally(() => {
                    console.log("finally from are>>>>>>>> ")
                    showPageLoader(false)                    
                })            
        )
    }

    componentDidMount() {
        UserScriptApi.apiConnect('frMain')
        
        this.setState({
            position: null,
        })
        GetLocation(null, 
            (position) => {
                const initialPosition = position;
                this.setState({ initialPosition: initialPosition })
            },
            (error) => {
                console.log('Error: ', JSON.stringify(error))
            })
        // Geolocation.getCurrentPosition(
        //     position => {
        //         const initialPosition = position;
        //         this.setState({ initialPosition: initialPosition })
        //     },
        //     error => console.log('Error: ', JSON.stringify(error)),
        //     { enableHighAccuracy: false }
        // )
        this._unsubscribe = this.props.navigation.addListener('focus', () => {
            console.log("_unsubscribe global.startPOI,", global.startPOI)
            console.log("_unsubscribe global.endPOI,", global.endPOI)
            if (global.endPOI) {
                if (global.startPOI) {
                    this.setState({
                        startPOI: global.startPOI,
                        endPOI: global.endPOI
                    }, () => {
                        this.searchRoad()
                    })
                }
                else if (this.state.startPOI) {
                    this.setState({ endPOI: global.endPOI }, () => {
                        this.searchRoad()
                    })
                }
                else {
                    this.setState({ endPOI: global.endPOI }, () => {
                    });
                }
            }
            else if (global.startPOI) {
                if (this.state.endPOI) {
                    this.setState({ startPOI: global.startPOI }, () => {
                        this.searchRoad()
                    })
                }
                else {
                    this.setState({ startPOI: global.startPOI }, () => {
                    });
                }
            }
        })
    }
    componentWillUnmount() {
        this._unsubscribe();
        // this.searchRoad(
        global.startPOI = null;
        global.endPOI = null;
    }

    async getCityName() {
        try {
            let res = await FrRestAPI.getCityName(
                this.state.initialPosition == 'unknown' ? 126.97357177734416 : this.state.initialPosition.coords.longitude,
                this.state.initialPosition == 'unknown' ? 37.45051365242391 : this.state.initialPosition.coords.latitude
            );
            return res;
        }
        catch (e) {
            console.log("[FrMain] error while getCityName.", e)
            return "";
        }
    }

    async getMatchedDongList(isStart, seoulFirst, searchText) {
        console.log(isStart + ", " + seoulFirst + ", " + searchText)
        let cityOrders = seoulFirst ? ["서울", "경기", ""] : ["경기", "서울", ""]
        let res = null;
        try {
            for (let i = 0; i < 3; i++) {
                res = await FrRestAPI.getAdminDongList(cityOrders[i], searchText);
                if (res && res.findPoiAreaDataByNameInfo && res.findPoiAreaDataByNameInfo.dongInfo && res.findPoiAreaDataByNameInfo.dongInfo.length > 0) {
                    console.log(`[FrMain] 지역간 길찾기. "${cityOrders[i]}"에서 ${isStart ? "출발지" : "도착지"} 발견. ${i + 1}번째 탐색. 검색어: ${searchText}`, res.findPoiAreaDataByNameInfo.dongInfo[0])
                    break;
                }
                else {
                    res = null;
                }
            }
            if (res) {
                return res.findPoiAreaDataByNameInfo.dongInfo[0];
            }
            else {
                console.log(`[FrMain] 지역간 길찾기. ${isStart ? "출발지" : "도착지"}를 찾을 수 없음. 검색어: ${searchText}`)
                this.context.msgHandler?.dialog({
                    title: "알림",
                    message: `${isStart ? "출발" : "도착"}지점과 일치하는 읍/면/동을 찾을 수 없습니다.`,
                    remark: "읍/면/동 까지 입력하면 보다 정확하게 찾을 수 있습니다.",
                })
                return null;
            }            
        }
        catch (e) {
            console.log("[FrMain] error while getMatchedDongList.", e)
            return null;
        }
    }

    render() {
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeader
                    title={this.state.title}
                />
                <View style={CommonStyle('body', null, { backgroundColor: "#00f" })}>

                    <View style={{
                        width: Constants.WINDOW_WIDTH
                    }}>
                        <ScrollView
                            style={{
                                // flex: 1,
                                backgroundColor: Colors.listBackgroundColor,
                            }}
                            ref={this.scrollRef}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            decelerationRate={0}
                            snapToAlignment={"start"}
                            snapToEnd={true}
                            snapToInterval={Constants.WINDOW_WIDTH}
                            keyboardShouldPersistTaps='handled'
                            onScroll={this.handleScroll}
                            scrollEventThrottle={10}
                        >
                            <FrGeneral
                                navigation={this.props.navigation}
                                start={this.state.startPOI}
                                end={this.state.endPOI}
                                onPressStart={() => {
                                    this.setState({
                                        startPOI: null,
                                        endPOI: null,
                                        paths: null
                                    }, () => {
                                        this.props.navigation.navigate('frStartEnd', { title: '출발지' })
                                    })
                                }}
                                onPressEnd={() => {
                                    console.log('global.startPOI is ????', global.startPOI)
                                    if (!global.startPOI) {
                                        this.setState({
                                            startPOI: null,
                                            endPOI: null,
                                            paths: null
                                        }, () => {
                                            this.context.msgHandler?.dlg(GetText("pageWord","ALERT"), '출발지를 먼저 입력해 주세요.')
                                        })
                                    } else {
                                        this.setState({
                                            paths: null
                                        }, () => {
                                            this.props.navigation.navigate('frStartEnd', { title: '도착지' })
                                        })
                                    }
                                }}
                                exchangeStartEnd={() => {
                                    console.log("ss", this.state.startPOI)
                                    console.log("se", this.state.endPOI)
                                    if (this.state.startPOI == null && this.state.endPOI == null) {
                                        // Alert.alert('알림', '출, 도착점을 입력해주세요.', [{ text: '확인' }])
                                    }
                                    else if (this.state.startPOI == null && this.state.endPOI) {
                                        global.startPOI = global.endPOI;
                                        global.endPOI = null;
                                        this.setState({
                                            startPOI: this.state.endPOI,
                                            endPOI: null,
                                            paths: null
                                        })
                                        this.context.msgHandler?.toast('도착지를 입력해주세요.')
                                    } else if (this.state.endPOI == null && this.state.startPOI) {
                                        global.endPOI = global.startPOI;
                                        global.startPOI = null
                                        this.setState({
                                            startPOI: null,
                                            endPOI: this.state.startPOI,
                                            paths: null
                                        })
                                        this.context.msgHandler?.toast('출발지를 입력해주세요.')
                                    } else {
                                        global.startPOI = this.state.endPOI;
                                        global.endPOI = this.state.startPOI;                                        

                                        this.setState({
                                            startPOI: global.startPOI ,
                                            endPOI: global.endPOI
                                        }, () => {
                                            this.searchRoad()
                                        })
                                    }
                                }}
                            />
                            {/* Web does not support drag scrolling */}
                            {
                                Platform.OS !== "web"
                                    ? null
                                    : <View style={{ width: 50, justifyContent: "center", alignItems: "center", paddingRight: 10 }}>
                                        <TouchableOpacity
                                            style={{ justifyContent: "center", alignItems: "center" }}
                                            onPress={() => {
                                                try {
                                                    if (this.state.title == "길찾기") {
                                                        console.log("========= manuall scrollToEnd", this.scrollRef)
                                                        this.scrollRef.current?.scrollToEnd({
                                                            animated: true
                                                        })
                                                    }
                                                    else {
                                                        console.log("========= manuall scrollToTop", this.scrollRef)
                                                        this.scrollRef.current?.scrollTo({
                                                            animated: true,
                                                            offseet: 0
                                                        })
                                                    }
                                                } catch (ex) { }
                                            }}>
                                            <FIcon type="fad" name="grip-vertical" size={20} color={Colors.fontColor} />
                                        </TouchableOpacity>
                                    </View>
                            }
                            <FrArea
                                // start={this.state.areaStartDong ? this.state.areaStartDong.address : ""}
                                // end={this.state.areaEndDong ? this.state.areaEndDong.address : ""}
                                runFindPathArea={async (start, end) => {
                                    // get user city name
                                    let cityName = await this.getCityName();
                                    let startDong = await this.getMatchedDongList(true, cityName === "서울특별시", start);
                                    if (!startDong) {
                                        this.setState({                                                                                      
                                            paths: null
                                        })
                                        return;
                                    }
                                    let endDong = await this.getMatchedDongList(false, cityName === "서울특별시", end);
                                    if (!endDong) {
                                        this.setState({                                            
                                            paths: null
                                        })
                                        return;
                                    }
                                    this.setState({
                                        areaStartDong: startDong,
                                        areaEndDong: endDong
                                    }, () => {
                                        this.searchRoadFromArea(
                                            this.state.areaStartDong.resLon,
                                            this.state.areaStartDong.resLat,
                                            this.state.areaEndDong.resLon,
                                            this.state.areaEndDong.resLat,
                                        )
                                    })
                                }}
                            />
                        </ScrollView>
                    </View>

                    {/* 최근 검색 현시목록 및 결과현시 목록 */}
                    {
                        this.state.paths == null ?
                            <FrHistory
                                navigation={this.props.navigation}
                                onPressHistory={() => {
                                    this.setState({
                                        startPOI: global.startPOI,
                                        endPOI: global.endPOI
                                    }, () => {
                                        this.searchRoad()
                                    })
                                }}
                                searchState={this.state.searchState}
                            /> :
                            <RenderFoundPaths
                                data={this.state.paths}
                                navigation={this.props.navigation}
                                start={this.state.startPOI}
                                end={this.state.endPOI}
                            />
                    }
                </View>

                {/* 길찾기 결과 현시목록 */}
                <MainBottomTab selTab="findRoad" navigation={this.props.navigation} />
            </SafeAreaView>
        )
    }
}
