import React from 'react';
import { View, Text } from 'react-native';
import CommonStyle from '../../../styles/CommonStyle';
import SectionStyle from '../../../styles/SectionStyle';
import TextStyle from '../../../styles/TextStyle';
import * as JsUtil from '../../../utils/JsUtil';

import SpacerH from '../common/SpacerH';
import * as GBus from '../../../defines/GBus';
import * as GBusHelper from '../../../defines/GBusHelper';
import FIcon from '../../../defines/FIcon';
import { Colors, Layouts } from '../../../defines/Theme';
import BusTroubleBox from './BusTroubleBox';
import BusAuxStatusBox, { BusAuxStatusMark } from './BusAuxStatusBox';

// busStatus
// //--- 운행상태 기본값
// busStatus.runStatus = BUS_RUNS.OK; 
// busStatus.runStatusReason = "";
// //--- 운행부가상태 기본값
// busStatus.runStatusAux = BUS_RUNS_AUX.NONE;
// busStatus.runStatusAuxReason = "";
// //--- 장애상태 기본값
// busStatus.trouble = BUS_TROUBLES.NONE;
// busStatus.troubleReason = "";

const BusArrivalTimeBox = ({ bus, width }) => {

    if (bus.touble && bus.touble !== GBusHelper.BUS_TROUBLES.NONE) {
        console.log(`==== bus truouble. ${bus.trouble}, ${GBusHelper.BUS_TROUBLES.NONE}`) 
        return (
            <BusTroubleBox bus={bus} />
        )
    }
    // 시간정보가 없는 경우
    if (bus.source == "busLocation" || (JsUtil.isInteger(bus.predictTime) && bus.predictTime < 0)) {
        return (<>
            <View style={SectionStyle("rowForRow")}>
                <View style={SectionStyle("arrivalTimeTextBg", width ? { width: width } : null )}>
                    <FIcon type="fas" name="hourglass" color={Colors.whiteLight} size={20} />
                </View>
            </View>

            {/* <View style={ SectionStyle('arrivalTimeTextBg', { alignItems: "center", paddingVertical: 5} ) }>
                <FIcon type="fas" name="hourglass" color={Colors.whiteLight} size={20} />
            </View>  */}
        </>)
    }

    let containerStyle = "arrivalTimeTextBg";
    let textStyle = "arrivalTimeText";
    if (bus.runStatus === GBusHelper.BUS_RUNS.W_GARAGE || bus.runStatus === GBusHelper.BUS_RUNS.W_GARAGE_TURN) {
        containerStyle = "arrivalTimeTextBg_garage";
        textStyle = "arrivalTimeText_garage";
    }
    else if (bus.runStatus !== GBusHelper.BUS_RUNS.OK) {
        containerStyle = "arrivalTimeTextBg_noBus";
        textStyle = "arrivalTimeText_noBus";
    }

    return (<>
        <View style={SectionStyle("rowForRow")}>
            <View style={SectionStyle(containerStyle, width ? { width: width } : null )}>
                <Text numberOfLines={ width ? 2 : 1} style={TextStyle(textStyle, (global.foreignLanguage=='E' || global.foreignLanguage=='V' ? 'smallest' : 'medium'), width ? { flex: 1}: { marginHorizontal: 5 })}>
                    {GBusHelper.getBusArrivalText(bus, true)}
                </Text>
                <BusAuxStatusMark bus={bus} />
            </View>
        </View>
    </>)
}



export default BusArrivalTimeBox