import React, { Component, useState, useEffect, useContext, useRef } from 'react';
import { Text, View, TouchableOpacity, TextInput } from 'react-native';

// context
import AlarmContext from '../../../context/AlarmContext';

//import Colors from '../../settings/Colors';
//  config, lib
import { Colors, Layouts } from '../../../defines/Theme';
import Texts, { GetText, GetTextSido } from '../../../defines/Texts';
import * as CommonStyle from '../../../styles/CommonStyle';
import * as SectionStyle from '../../../styles/SectionStyle';
import * as FavorUtil from '../../../utils/FavorUtil';
import * as HistoryUtil from '../../../utils/HistoryUtil';
import FIcon from '../../../defines/FIcon';

// deprecated
import styles from './FrStyle';
import Constants, { ConvStyle } from '../../../settings/Constants';


// 일반 및 지역길찾기 출, 도착지 정보입력창
export default function FrGeneral({ navigation, start, end, onSetStartInputRef, onSetEndInputRef, exchangeStartEnd, onPressStart, onPressEnd }) {
    const context = useContext(AlarmContext)
    const [startPOI, setStartPOI] = useState(start)
    const [endPOI, setEndPOI] = useState(end)
    const [review, setReview] = useState(false)

    useEffect(() => {
        setStartPOI(start)
        setEndPOI(end)
    }, [start, end])

    useEffect(() => {
        let { favorIndex } = FavorUtil.IsPathFindInFavor(startPOI, endPOI)
        setReview(favorIndex >= 0)
    }, [startPOI, endPOI])

    return (
        <View style={{ ...styles.frGeneralContainer, ...styles.shadow }}>
            <View style={{ ...styles.row, }}>
                <Text style={ConvStyle(15, Colors.fontColor, 150)}>출발: </Text>
                <TouchableOpacity
                    activeOpacity={1}
                    style={{ width: '75%', }}
                    onPress={() => { onPressStart?.() }} >
                    <TextInput
                        placeholder={GetText("FINDPATH", "START_POI_HOLDER")}
                        numberOfLines={1}
                        style={{ ...ConvStyle(14, Colors.fontColor, 140), ...styles.input }}
                        value={startPOI ? startPOI.title : ''}
                        editable={false}
                        ref={(input) => { onSetStartInputRef?.(input) }}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    style={{ width: 30, height: "100%", marginLeft: 5, marginTop: 10, paddingTop: 5 }}
                    onPress={() => { exchangeStartEnd?.() }} >
                    <View style={{ margin: 0, transform: [{ rotate: '90deg' }] }} >
                        <FIcon type="fad" name="exchange" size={20} color={Colors.fontColor} />
                        {/* style={{ transform: 'rotate(90deg)' }} /> */}
                        {/* transform: rotate(180deg)
                        style={{ transform: [{ rotate: '90deg' }] }} /> */}
                    </View>
                </TouchableOpacity>
            </View>
            <View style={styles.row}>
                <Text style={ConvStyle(15, Colors.fontColor, 150)}>도착: </Text>
                <TouchableOpacity
                    activeOpacity={1}
                    style={{ width: '75%' }}
                    onPress={() => { onPressEnd?.() }} >
                    <TextInput
                        placeholder={GetText("FINDPATH", "END_POI_HOLDER")}
                        numberOfLines={1}
                        style={{ ...ConvStyle(14, Colors.fontColor, 140), ...styles.input }}
                        value={endPOI ? endPOI.title : ''}
                        editable={false}
                        ref={(input) => { onSetEndInputRef?.(input) }}
                    />
                </TouchableOpacity>
                {/* <TouchableOpacity
                    style={{ width: 30, height: "100%", marginLeft: 7, marginTop: 0 }}
                    onPress={async () => {
                        if (startPOI && endPOI) {
                            if (!review) {
                                FavorUtil.AddFavorPathFind(startPOI, endPOI)
                                setReview(true)
                            } else {
                                FavorUtil.RemoveFavorPathFind(startPOI, endPOI)
                                setReview(false)
                            }
                        }
                        else {
                            context.msgHandler?.toast('길찾기 검색을 진행한후에 즐겨찾기를 할 수 있습니다!')
                        }
                    }}
                >
                    {
                        review ?
                            <FIcon type="fad" name="star" size={28} color={Colors.yellow} /> :
                            <FIcon type="fal" name="star" size={28} color={Colors.borderColor} />
                    }
                </TouchableOpacity> */}
            </View>
        </View>
    )

}