import React, { Component } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, TextInput, Image, TouchableWithoutFeedback, Keyboard, FlatList, Alert } from 'react-native';
import { Header } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
// import { WebView } from 'react-native-webview'; 
import WebView from 'react-native-web-webview';
//import Geolocation from '@react-native-community/geolocation';
import { StackActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-community/async-storage';

// config, lib
import GetLocation from '../../gps/GetLocation';
import AppSetting from '../../defines/AppSetting';
import Constants, { ConvStyle, ConvStrToDate } from '../../settings/Constants';
import Colors from '../../settings/Colors';
import FrSelMapBottomTab from '../components/bottomTabs/FrSelMapBottomTab';
import FIcon from '../../defines/FIcon';
import LoadImage from '../../defines/Images';
import CommonStyle from '../../styles/CommonStyle';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';
import MapStyle from '../../styles/MapStyle';

// import UserScriptConf from '../../utils/UserScriptConf';
// import UserScriptApi from '../../utils/UserScriptApi';

import ScreenHeader, { ScreenHeaderEx } from '../components/common/ScreenHeader';

//import Html from '../Tmap/TmapSelectPosition';

let UserScriptApi = require('../../utils/UserScriptApi');

export function RenderItem({ isVisible, selStationInfo }) {
    if (!isVisible || selStationInfo == null) {
        return null
    }
    return (
        <View style={styles.container}>
            <View style={styles.detailBody}>
                <View style={styles.detailBodyLeft}>
                    <View style={styles.busIconView}>
                        {/* <Image source={require('../../../assets/findRoad/sel_poi.png')} style={{ width: 30, height: 30, resizeMode: 'contain' }} /> */}
                        <LoadImage name="sel_poi" width={30} height={30} />
                    </View>
                    <View style={styles.detailBusInfoView}>
                        <Text numberOfLines={1} style={{ ...ConvStyle(18, Colors.fontColor, 160), lineHeight: 30 }}>
                            {selStationInfo.poiName}
                        </Text>
                        <Text numberOfLines={1} style={{ ...ConvStyle(14, Colors.fontColor, 140), lineHeight: 20 }}>
                            {selStationInfo.poiAddress}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}
function AddZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("addZoom") }
            onPress={ onPress } accessibilityLabel="줌 확대" accessibilityRole="button">
            <FIcon type="fad"name="plus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}
function RemoveZoom({ onPress }) {
    return (
        <>
        <TouchableOpacity style={ MapStyle("removeZoom") }
            onPress={ onPress } accessibilityLabel="줌 축소" accessibilityRole="button">
            <FIcon type="fad"name="minus-square" size={40} color={Colors.ggBlue} />
        </TouchableOpacity>
        </>
    );
}


export default class FrSelMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialPosition: 'unknown',
            isVisible: false,
            selStationInfo: null,
        }
    }

    componentDidMount() {
        this.setState({
            position: null,
        })
        GetLocation(null, 
            (position) => {
                const initialPosition = position;
                this.setState({ initialPosition: initialPosition })
            },
            (error) => {
                console.log('Error: ', JSON.stringify(error))
            })        
        // Geolocation.getCurrentPosition(
        //     position => {
        //         const initialPosition = position;
        //         this.setState({ initialPosition: initialPosition })
        //     },
        //     error => console.log('Error: ', JSON.stringify(error)),
        //     { enableHighAccuracy: false }
        // );
    }

    insertHistoryPoi = async (item) => {
        if (!global.historyPois)
            global.historyPois = []

        global.historyPois.unshift({
            name: item.poiBuildingName != '' ?  item.poiBuildingName :  item.poiNameDemo,
            address: item.poiAddress,
            noorLon: item.x,
            noorLat: item.y
        })
        await AsyncStorage.setItem('historyPois', JSON.stringify(global.historyPois))
    }

    toWebView = (cmd, params) => {
        this.webRef?.postMessage({
            _sender: "GBUS_RN",
            cmd: cmd,
            params: params
        }, "*")
    }


    render() {
        const {navigation} = this.props;
        return (
            <SafeAreaView style={CommonStyle('bg')}>
                <ScreenHeaderEx
                    title={this.props.route.params.title}
                    left={{ iconType: "fad", iconName: "chevron-left", onPress: () => { 
                        global.startPOI = null;
                        global.endPOI = null;
                        this.props.navigation.goBack();
                    }}}
                />
                <View style={CommonStyle("body")}>
                    <WebView
                        ref={(ref) => this.webRef = ref}
                        originWhitelist={['*']}
                        style={MapStyle("mapView")}
                        // source={{ html: Html.content }}
                        // style={MapStyle("mapView")}
                        source={{ uri: AppSetting.MOBILE_WEB.ROOT + "assets/Tmap/TmapSelectPosition.html", method: 'get' }}
                        onLoadStart={event => {
                            showPageLoader(true)
                        }}
                        onLoadEnd={event => {
                            showPageLoader(false)
                        }}
                        onMessage={(event) => {
                            if (!event.nativeEvent.data || typeof event.nativeEvent.data != "string" || !event.nativeEvent.data.startsWith("{")) {
                                return;
                            }
                            let data = JSON.parse(event.nativeEvent.data.toString())
                            // console.log("===> webview event", data);
                            if (data.event == "position") {
                                console.log('[TMap] user selected a position.', data.data);
                                let positionData = data.data;
                                this.setState({ isVisible: true, selStationInfo: positionData.data });
                            }
                            else if (data.event == "msg") {
                                this.context.msgHandler?.toast(data.data);
                            }
                            else if (data.event == "log") {
                                console.log("[TMap] " + data.data);
                            }
                            else if (data.event == "error") {
                                this.context.msgHandler?.toast(GetText("pageComment", "ERR_LAUNCHING_MAP"))
                                console.log("[TMap] error:");
                                console.log(data.data);
                            }
                            // let data = JSON.parse(event.nativeEvent.data.toString())
                            // //console.log("data from map: ", data)
                            // if (data.isSel) {
                            //     this.setState({ isVisible: true, selStationInfo: data.data })
                            // } else {
                            //     let m = JSON.parse(data.msg)
                            //     console.log(`[FrSelMap] map event: ${data.type}`, m)
                            //     // Alert.alert('경고', data.data, [{
                            //     //     text: '확인',
                            //     //     onPress: ()=>{
                            //     //         navigation.goBack();
                            //     //     }
                            //     // }])
                            // }
                        }}
                        javaScriptEnabled={true}
                        domStorageEnabled={true}
                        startInLoadingState={true}
                        scalesPageToFit={true}
                        injectedJavaScript={`initMap(${this.props.route.params.x},${this.props.route.params.y})`}
                    />
                </View>
                <TouchableOpacity style={styles.currentLocation}
                    onPress={() => {
                        if (this.state.initialPosition && this.state.initialPosition.coords) {
                            this.toWebView("setMyLocation", { x: this.state.initialPosition.coords.longitude, y: this.state.initialPosition.coords.latitude });
                        }                        
                    }}
                >
                    <FIcon type="fad" name="location" size={55} color={Colors.blue} />
                    {/* <MIcon name="my-location" size={60} color={Colors.blue} /> */}
                </TouchableOpacity>
               
                <RenderItem isVisible={this.state.isVisible} selStationInfo={this.state.selStationInfo} />
                <FrSelMapBottomTab
                    navigation={this.props.navigation}
                    title={this.props.route.params.title}
                    setStartEnd={() => {
                        if (this.state.selStationInfo) {
                            console.log("the data from map: ==========", JSON.stringify(this.state.selStationInfo, null, 4))
                            if (this.props.route.params.title == '출발지') {
                                global.startPOI = {
                                    title: this.state.selStationInfo.poiBuildingName != '' ? 
                                            this.state.selStationInfo.poiBuildingName : 
                                            this.state.selStationInfo.poiNameDemo,
                                    address: this.state.selStationInfo.poiAddress,
                                    x: this.state.selStationInfo.x,
                                    y: this.state.selStationInfo.y
                                }
                                this.insertHistoryPoi(this.state.selStationInfo)
                            } else {
                                global.endPOI = {
                                    title: this.state.selStationInfo.poiBuildingName != '' ? 
                                            this.state.selStationInfo.poiBuildingName : 
                                            this.state.selStationInfo.poiNameDemo,
                                    address: this.state.selStationInfo.poiAddress,
                                    x: this.state.selStationInfo.x,
                                    y: this.state.selStationInfo.y
                                }
                                this.insertHistoryPoi(this.state.selStationInfo)
                            }

                            this.props.navigation.dispatch(StackActions.pop(2));
                        } else {console.log('Please wait a mement!')}
                    }} />
            </SafeAreaView>
        )
    }
}

const styles = {
    header: {
        height: Constants.HEADER_BAR_HEIGHT,
        backgroundColor: Colors.white
    },
    body: {
        flex: 1,
        marginBottom: Constants.BOTTOM_BAR_HEIGHT,
        backgroundColor: Colors.background,
    },
    currentLocation: {
        position: 'absolute',
        right: 0,
        bottom: Constants.BOTTOM_BAR_HEIGHT + 70,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 10,
    },

    container: {
        position: 'absolute',
        flexDirection: 'column',
        bottom: Constants.BOTTOM_BAR_HEIGHT, left: 0, right: 0,
        backgroundColor: 'transparent'
    },
    detailBody: {
        marginBottom: 10,
        marginHorizontal: 10,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: Colors.white,
        paddingVertical: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    detailBodyLeft: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    busIconView: {
        paddingHorizontal: 10,
        justifyContent: 'center'
    },
    detailBusInfoView: {
        flex: 1,
        paddingRight: 10,
        justifyContent: 'center'
    },
    detailBodyRight: {
        justifyContent: 'flex-end',
        paddingHorizontal: 10
    },
    detailBodyEmpty: {
        marginBottom: 10,
        marginHorizontal: 10,
        borderColor: Colors.borderColor,
        borderWidth: 1,
        borderRadius: 10,
        backgroundColor: Colors.white,
        paddingVertical: 10,
        paddingHorizontal: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
}