import React from 'react';
import { NativeModules } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import Texts, { GetText } from '../defines/Texts'
import * as DateUtil from './DateUtil'
import * as StorUtil from './StorUtil'
import * as JsUtil from './JsUtil'
import AppSetting from '../defines/AppSetting'

const log = (m, o) => {
    if (AppSetting.DEBUGS.SHOW_ALARM_LOG) {
        console.log(`[AlarmUtil] ${m}`, o)
    }
}

const error = (m, e) => {
    console.log(`[ERROR][AlarmUtil] ${m}`, e)
}


// state must be [active|dne|cancel|timeout] 
const KeepBusNotifyHistory = async (busNotify, callback) => {
    let alarms = await StorUtil.GetJSON("busNotifyHistory");
    if (!alarms || !Array.isArray(alarms)) {
        log("saved busNotify history is empty")
        alarms = []; 
    }
    if (busNotify) {
        alarms.unshift(busNotify);
        alarms = alarms.filter(elem => elem.finishTime && DateUtil.GetElapsedDays(elem.finishTime) < 7)
    }
    else {
        alarms = []; 
    }
    await StorUtil.Save("busNotifyHistory", alarms);
    log(`busNotify history saved.`, alarms)
    callback?.();
} 

export const SaveRecentBusNotify = async (busNotify, callback) => {
    if (busNotify) {
        await KeepBusNotifyHistory(busNotify);
        await StorUtil.Save("recentBusNotify", busNotify);
        log(`recent busNotify saved.`, busNotify)
        callback?.();
    }
    else {
        await KeepBusNotifyHistory();
        callback?.()
    }
}

export const HasBusActiveAlarm = (bus, alarm) => {
    return alarm && bus && 
           alarm.stationId === JsUtil.GText(bus, "stationId") &&
           alarm.routeId === JsUtil.GText(bus, "routeId") &&
           alarm.vehId === JsUtil.GText(bus, "vehId");
}
