// 정류소 경유노선의 행을 보여주기 위한 헤더부분 Component
import React, { useContext, useState } from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
// context
import AlarmContext from '../../context/AlarmContext';

// config, lib
import LoadSvg from '../../defines/Svgs';
import LoadImage from '../../defines/Images';
import FIcon from '../../defines/FIcon';
import * as GBus from '../../defines/GBus';
import Texts, { GetText } from '../../defines/Texts';
import * as GBusHelper from '../../defines/GBusHelper';
import * as JsUtil from '../../utils/JsUtil';
import { Colors, Layouts } from '../../defines/Theme';
import SectionStyle from '../../styles/SectionStyle';
import TextStyle from '../../styles/TextStyle';

// shared
import RouteNameBox from '../components/parts/RouteNameBox';
import BusArrivalTimeBox from '../components/parts/BusArrivalTimeBox';
import SimpleBusRow from '../components/SimpleBusRow'; 


/**
 *  
 * 


/**
 * 
 * @param {*} param0 
 * 노선의 근접버스 2대를 요약해서 표시하는 행
 */
export default function StationRouteArrivalRow({ station, routeArrival, navigation, onPress, onBusPress, 
    leftSpaceWidth, leftSpaceWidthOfBusRow }) {
    const context = useContext(AlarmContext);
    const [isOpened, setIsOpened] = useState(false);

    if (!routeArrival) {
        return null;
    }
    // // 노선도착정보 없음.
    // if (!routeArrival.hasOwnProperty("routeName")) {
    //     return (
    //         <View style={SectionStyle('rowBg')}>
    //             <TouchableOpacity
    //                 style={SectionStyle('rowForHRow', { flex: 1, })}
    //                 onPress={() => { onPress?.() }}
    //             >
    //                 <View style={SectionStyle('rowLeftIconArea', {})} />
    //                 <View style={SectionStyle('rowFillStretchForRow', {})}>
    //                     <Text numberOfLines={2} style={TextStyle('rowTitle', 'largest',
    //                         {
    //                             color: Colors.fontCommentColor,
    //                             width: Layouts.routeNameWidth
    //                         })}>
    //                         {routeArrival.routeId._text}
    //                     </Text>
    //                 </View>
    //             </TouchableOpacity>
    //         </View>
    //     )
    // }

    // 노선도착정보 조회됨
    return (<>
        <View style={SectionStyle('rowBgBottomBorder')}>
            {/* Route Name, bus arrival abbr */}
            <TouchableOpacity
                style={SectionStyle('rowForHRow', { flex: 1, })}
                accessibilityRole="button"
                // accessibilityLabel={
                //     JsUtil.GText(routeArrival, "routeName") + "번 노선, "
                //     + routeArrival.busStatusList[0]
                //     + routeArrival.busStatusList[1]
                //     + routeArrival.routeDestName._text + "방면"
                // }
                onPress={onPress ? onPress :
                    () => {
                        console.log("Opened toggle");
                        setIsOpened(!isOpened);
                    }}
            >   
                <View >
                    <RouteNameBox
                        routeName={JsUtil.GText(routeArrival, "routeName")}
                        routeTypeCd={JsUtil.GText(routeArrival, "routeTypeCd")}
                        leftSpaceWidth={leftSpaceWidth}
                    />
                </View>
                <View style={SectionStyle('rowFillStretchAround', {})}>
                    <View style={SectionStyle('rowForRow', {})}>
                        {
                            routeArrival.busStatusList && routeArrival.busStatusList.length > 0 ?
                            <BusArrivalTimeBox bus={routeArrival.busStatusList[0]} /> : null
                        }
                        {
                            routeArrival.busStatusList && routeArrival.busStatusList.length > 1 ?
                            <BusArrivalTimeBox bus={routeArrival.busStatusList[1]} /> : null
                        }
                    </View>
                    <Text numberOfLines={1} style={TextStyle('rowDetail', 'small')}>
                    {GetText("pageWord","ROUTE_DEST_NAME").replace("{routeDestName}",routeArrival.routeDestName._text)}
                    </Text>
                </View>
            </TouchableOpacity>
            {/* Collapse icon */}
            <View style={SectionStyle('rowRightButtonsArea')}>
                <TouchableOpacity style={SectionStyle('rowButton')}
                    onPress={() => { 
                        setIsOpened(!isOpened) 
                        return;
                        if (GBusHelper.canDrawBusRow(routeArrival, 0) || GBusHelper.canDrawBusRow(routeArrival, 1)) {
                            setIsOpened(!isOpened) 
                        }
                        else {
                            context.msgHandler?.toast(GetText("pageComment","NO_ARRIVA_BUS"), true)
                            // context.msgHandler?.toast("도착예정인 버스가 없습니다.", true)
                        }                        
                    }} 
                    accessibilityLabel={"노선 " + JsUtil.GText(routeArrival, "routeName") + "번 도착예정버스 보기, " + (isOpened ? " 확장됨 " : " 축소됨 ")}
                    accessibilityRole="button"
                    >
                    {
                        isOpened
                            // ? <FIcon type="fal" name="ellipsis-v-alt" size={30} color={Colors.borderColor} />
                            // : <FIcon type="fas" name="ellipsis-v" size={30} color={Colors.borderColor} />
                            ? <FIcon type="fad" name="chevron-double-up" size={30} color={Colors.borderColor} />
                            : <FIcon type="fad" name="chevron-double-down" size={30} color={Colors.borderColor} />
                    }
                </TouchableOpacity>
            </View>
        </View>
        {
            isOpened && GBusHelper.canDrawBusRow(routeArrival, 0) ?
            <SimpleBusRow route={routeArrival} busStatus={routeArrival.busStatusList[0]} 
                taglessCd={routeArrival.taglessCd1}
                onPress={onBusPress} 
                onRightIconPress={onBusPress} 
                leftSpaceWidth={!isNaN(leftSpaceWidthOfBusRow) ? leftSpaceWidthOfBusRow : leftSpaceWidth} /> : null
        }
        {
            isOpened && GBusHelper.canDrawBusRow(routeArrival, 1) ?
            <SimpleBusRow route={routeArrival} busStatus={routeArrival.busStatusList[1]} 
                taglessCd={routeArrival.taglessCd2}
                onPress={onBusPress} 
                onRightIconPress={onBusPress} 
                leftSpaceWidth={!isNaN(leftSpaceWidthOfBusRow) ? leftSpaceWidthOfBusRow : leftSpaceWidth} /> : null
        }
    </>)
}


StationRouteArrivalRow.defaultProps = {
    wantLeftSpace: true,
}